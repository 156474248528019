import React from "react";
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

//*****************************************/

const Panel = styled.div`
    position: absolute;
    top: ${props => props.top + 'px'};
    left: ${props => props.left + 'px'};
    width: ${props => props.width + 'px'};
    height: ${props => props.height + 'px'};
    background-color: white;
    overflow: hidden;
    border: solid 1px #CCCCCC;
    border-radius: 8px;
    cursor: ${props => props.cursor};
    `

const GrabBar = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 10px;
    height: 24px;
    line-height: 24px;
    background-color: #E0E0E0;    
    cursor: move;
`

const Content = styled.div`
    position: absolute;
    top: 17px;
    bottom: 0px;
    left: 0;
    right: 0;
    padding: 16px;
`

const Resizer = styled.div`
    position: absolute;
    bottom: 0px;    
    right: 0;
    width: 16px;
    height: 16px;
    background-color: #EEEEEE;
    cursor: nwse-resize;;
    `

//*****************************************/

const DashPanel = ({ properties, children }) => {
    const navigate = useNavigate();

    properties = properties || {};

    const columnGap = properties?.columnGap || 32;
    const rowGap = properties?.rowGap || 32;
    const column = properties?.column || 0;
    const row = properties?.row || 0;
    const columns = properties?.columns || 1
    const rows = properties?.rows || 1;
    const columnWidth = properties?.columnWidth || 200;
    const rowHeight = properties?.rowHeight || 200;

    const gotoLink = () => {
        if(properties.link)
            navigate(`/${properties.link}`)
    }

    return (
        <Panel
            top={row * rowHeight}
            left={column * columnWidth}
            width={(columns * columnWidth) - columnGap}
            height={(rows * rowHeight) - rowGap}
            cursor={properties.link?'pointer':'auto'}
            onClick={gotoLink}
        >
            <GrabBar>
                { properties.title }&nbsp;
            </GrabBar>
            <Content>
                {children}
            </Content>
            <Resizer>&nbsp;</Resizer>
        </Panel>
    )
}

export default DashPanel;