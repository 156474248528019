import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { setState } from '../../../../redux/actions/loginActions';
import { loadConfigs } from '../../../../redux/actions/editorActions';
import { useNavigate } from 'react-router-dom';
import incubatorLogo from '../../../../assets/incubator-logo.png';
import DropDownAppId from "./DropDownAppId";
import zippyIcon from '../../../../assets/icon.svg';
import MountSVG from '../../../../assets/Mount.svg';
import PublishLoading from '../../../../assets/toolbar/publish_loading.svg';
import PublishDone from '../../../../assets/toolbar/publish_done.svg';
import ArrowTop from '../../../../assets/toolbar/arrow-top.svg';

//*************************************** */

const Bar = styled.div`
    position: relative;
    height: 60px;
    border-bottom: solid 1px #F1F2F2;
    `

const Logos = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 24px;
    `

const Logo = styled.img`
    height: 25px;
    `

const Incubator = styled.span`
    font-weight: 700;
    padding-left: .25em;
    font-size: 18px;
    color: #323334;
    `

const Editor = styled.span`
    font-weight: 400;
    padding-left: 2px;
    font-size: 18px;
    color: #323334;
    `

const Controls = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%; 
    right: 24px;
    `

const ToolButton = styled.button`    
    height: 30px;
    line-height: 30px;    
    padding: 0px;
    margin-right: 24px;
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    background-repeat: no-repeat;
    background-position: center;   
    background-color: white;
    border: none; 
    cursor: pointer;

    &.selected:hover,
    &:hover {
        border-bottom: solid 2px #002677;
    }

    &.selected {
        border-bottom: solid 2px #0C55B8;
    }
    &.last {
        margin-right: 32px;
    }
    `

const Line = styled.div`
    height: 32px;
    border-right: solid 1px rgba(90, 90, 90, .5);
    color: #CBCCCD;
    border-radius: 1px;
    `

//multiple user dropdown

const DropButton = styled.div`
    display: flex;
    flex-direction: row;
`

const Text = styled.div`
    display: flex;
    flex-direction: column; 
    align-items: flex-start; 
    padding: 8px;
`

const CdoName = styled.div`
    font-weight: 700;
    font-size: 14px;
`

const SympleNote = styled.div`
    font-weight: 400;
    color: #505050;
    font-size: 13px;
    `

const ZippieIcon = styled.img`
    margin-left: 8px;
    `

const DropDown = styled.div`
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 8px 0px;
    box-shadow: rgb(25 25 26 / 16%) 0px 4px 16px 0px;
    transition: ease-out 0.5s;
`

const Mount = styled.img`
    position: relative;
    left: 26px;
    top: 4px;
    z-index: 1;
    flex: none;
    order: 0;
    flex-grow: 0;
    box-shadow: rgb(25 25 26 / 16%) 0px 4px 16px 0px;
`

//publish button dropdown

const PublishButton = styled.button`
    padding: 0px 32px;
    height: 100%;
    font-size: 18px;
    color: white;
    background: #002677;
    border: none;
    cursor: pointer;
    font-weight: 700;
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    display: flex;
    flex-direction: row;
    padding-top: 20px;

    &:hover{
        background: rgb(12, 85, 184);
        color: #FFFFFF;
    }

    &:disabled {
        color: #6D6F70;
        background: #F3F3F3;
        opacity: 0.5;
        cursor: not-allowed;
    }
`

const PublishArrow = styled.div`
    width: 23px;
    height: 11px;
    right: 53px;
    z-index: 2;
    background-image: url(${ArrowTop});
    background-repeat: no-repeat;
    background-position: center;  
    background-color: #f8f8f8;
    color: #ffffff;
    position: relative;
    flex: none;
    order: 0;
    flex-grow: 0;
`

const PopUp = styled.div`
    padding: 24px;
    position: absolute;
    top: 10px;
    right: 0px;
    background: #ffffff;
    box-shadow: rgb(25 25 26 / 16%) 0px 4px 16px 0px;
    border-radius: 8px;
    z-index: 1;
    transition: ease-out 0.5s;
    box-sizing: border-box;
    `

const Heading = styled.div`
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    justify-content: center;
    color: #000000;
    margin-bottom: 24px;
    line-height: 22px;
    `

const Markdown = styled.div`
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 40px;
    width: 100%;
`

const PublishPopUpButton = styled.div`
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #002677;
    border-radius: 8px;
    background: #ffffff;
    border: 1.5px solid #002677;
    padding: 12.5px 74.5px;
    line-height: 22px;
    cursor: pointer;

    &:hover{
        color: #ffffff;
        background: #002677;
    }
`

const PublishLoadingButton = styled.div`
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #002677;
    border-radius: 8px;
    background: #ffffff;
    border: 1.5px solid #002677;
    width: 20px;
    height: 19.55px;
    padding: 14.23px 92.5px;
    line-height: 22px; 
    cursor: pointer;
    background-image: url(${PublishLoading});
    background-repeat: no-repeat;
    background-position: center; 
`

const PublishDoneButton = styled.div`
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px; 
    color: #002677;
    border-radius: 8px;
    background: #ffffff;
    border: 1.5px solid #002677;
    width: 15.43px;
    height: 15.58px;
    padding: 16.21px 94.78px;
    line-height: 22px;
    cursor: pointer;
    background-image: url(${PublishDone});
    background-repeat: no-repeat;
    background-position: center; 
`

//*****************************************/

const Toolbar = ({ save, isDisabled, appId, appIdList, cdoFullName, setState, loadConfigs, lastappId, showReportingTab }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [selectedButton, setSelectedButton] = useState(window.location.pathname.split('/')?.[1]?.toLowerCase() || '');
    const [showpublishpopup, setShowpublishpopup] = useState(false);
    const [ShowpublishButton, setShowpublishButton] = useState(true);
    const [ShowpublishLoadingButton, setShowpublishLoadingButton] = useState(false);
    const [ShowPublishDone, setShowPublishDone] = useState(false);
    const [showDropDown, setShowDropDown] = useState(false);

    const saveConfigs = () => {
        if (save instanceof Function) save();
    }

    useEffect(() => {
        if (appId !== lastappId){
            loadConfigs(appId);
            setState('lastappId', appId);
        }
    }, [appId]);

    const handlePublishButton = () => {
        saveConfigs();
        setShowpublishButton(false);
        setShowpublishLoadingButton(true);

        setTimeout(() => {
            setShowpublishLoadingButton(false);
            setShowPublishDone(true);
        }, 400);

        setTimeout(() => {
            setShowpublishButton(true);
            setShowpublishpopup(false);
            setShowpublishLoadingButton(false);
            setShowPublishDone(false);
        }, 800);
    }

    const toolButtonClicked = (button, location) => {
        if (button)
            setSelectedButton(button);

        if (location)
            navigate(location);
    }

    return (
        <Bar>
            <Logos>
                <Logo src={incubatorLogo} alt='Incubator Logo' />
                <Incubator>
                    {t("toolbar.incubator")}
                </Incubator>
                <Editor>
                    {t("toolbar.editor")}
                </Editor>
                <Line style={{ 'margin': '0px 8px 0px 16px' }} />

                <DropButton onMouseOver={() => setShowDropDown(true)} onMouseLeave={() => setShowDropDown(false)} >
                    <Text>
                        <CdoName> {cdoFullName} </CdoName>
                        <SympleNote>{t('toolbar.symplenote')}</SympleNote>
                    </Text>
                    {appIdList.length > 1 ? <ZippieIcon src={zippyIcon} alt="tick Icon" /> : <></>}


                    <div style={{ 'zIndex': 1, position: 'absolute', left: '190px', top: '50px' }} >
                        {
                            showDropDown && appIdList.length > 1
                                ?
                                <>
                                    <Mount src={MountSVG} />
                                    <DropDown>
                                        {
                                            appIdList.map((appId_value, index) => {
                                                return (
                                                    <DropDownAppId key={index} appId={appId} appId_value={appId_value} setState={setState} />
                                                )
                                            })}
                                    </DropDown>
                                </>
                                :
                                <></>
                        }
                    </div>
                </DropButton>
            </Logos>
            <Controls>
                <ToolButton
                    className={selectedButton === 'editor' ? 'selected' : ''}
                    onClick={() => toolButtonClicked('editor', '/editor')}
                >
                    {t("toolbar.symplenote")}
                </ToolButton>
                {
                showReportingTab ?
                    <ToolButton
                        className={selectedButton === 'reporting' ? 'selected' : ''}
                        onClick={() => toolButtonClicked('reporting', '/reporting')}
                    >
                        {t("toolbar.reporting")}
                    </ToolButton>
                :
                    <></>
                }
                <ToolButton
                    className={selectedButton === 'support' ? 'selected last' : 'last'}
                    onClick={() => toolButtonClicked('support', '/support')}
                >
                    {t("toolbar.support")}
                </ToolButton>                
                <PublishButton
                    id="publishButton"
                    onMouseOver={() => setShowpublishpopup(true)}
                    onMouseLeave={() => setShowpublishpopup(false)}
                    disabled={!isDisabled} >
                    {t("toolbar.publish.label")}
                    <div style={{ 'zIndex': 1, position: 'absolute', right: '0px', top: '60px' }} >
                        {
                            showpublishpopup
                                ?
                                    <>
                                        <PublishArrow />
                                        <PopUp>
                                            <Heading> {t("toolbar.publish.heading")}</Heading>
                                            <Markdown style={{ color: '#505050', textAlign: 'left' }}> {t("toolbar.publish.markdown")} </Markdown>
                                            {
                                                ShowpublishButton ?
                                                    <PublishPopUpButton onClick={() => handlePublishButton()}>
                                                        {t("toolbar.publish.publishButton")}
                                                    </PublishPopUpButton >
                                                    :
                                                    ShowpublishLoadingButton
                                                        ?
                                                        <PublishLoadingButton />
                                                        :
                                                        ShowPublishDone
                                                            ?
                                                            <PublishDoneButton />
                                                            :
                                                            <></>
                                            }
                                        </PopUp>
                                    </>
                                :
                                    <></>
                        }
                    </div>
                </PublishButton>
            </Controls>
        </Bar >
    );
}

const mapStateToProps = (state) => {
    const appId = state.loginReducer.appIdValue;
    const appIdList = state.loginReducer.appIdList;
    const cdoFullName = state.editorReducer.cdoFullName;
    const lastappId = state.loginReducer.lastappId;
    const isDisabled = state.editorReducer.stateChange;
    const showReportingTab = state.editorReducer.showReportingTab;

    return {
        appId,
        appIdList,
        cdoFullName,
        lastappId,
        isDisabled,
        showReportingTab
    }
}

export default connect(mapStateToProps, { setState, loadConfigs })(Toolbar);