import axios from "axios";

import {
    CUSTOMER_HELP_MESSAGE_SAVE_STATE,
    CUSTOMER_HELP_MESSAGE_SAVE_STATE_SUCCESS,
    CUSTOMER_HELP_MESSAGE_SAVE_STATE_FAIL,
    PRIVACY_FILE_UPLOAD_SAVE_STATE,
    PRIVACY_FILE_UPLOAD_SAVE_STATE_SUCCESS,
    PRIVACY_FILE_UPLOAD_SAVE_STATE_FAIL,
    TERMS_OF_USE_UPLOAD_SAVE_STATE,
    TERMS_OF_USE_UPLOAD_SAVE_STATE_SUCCESS,
    TERMS_OF_USE_UPLOAD_SAVE_STATE_FAIL
 } from '../types';

export const saveCustomerHelpMessage = (state, emailId, appId, topic, description, cdoFullName) => {
    return dispatch => {
        if(!emailId) return;
        if(!appId) return;
        if(!topic) return;
        if(!description) return;
        if(!cdoFullName) return;

        if(!(typeof emailId === 'string' || emailId instanceof String)) return;
        if(!(typeof appId === 'string' || appId instanceof String)) return;
        if(!(typeof topic === 'string' || topic instanceof String)) return;
        if(!(typeof description === 'string' || description instanceof String)) return;
        if(!(typeof cdoFullName === 'string' || cdoFullName instanceof String)) return;
        
        dispatch({ type: CUSTOMER_HELP_MESSAGE_SAVE_STATE, payload: "SAVING" });

        console.log("EmailId :", emailId)
        console.log("appID : ", appId)
        console.log("topic : ", topic)
        console.log("description : ", description)
        console.log("cdoFullName : ", cdoFullName)
        axios
            .post('https://incubator-customer-api-dev.optum.com/api/SaveSympleNoteCustomerHelpMessage', mapStateToData(emailId, appId, topic, description, cdoFullName))
            .then((response) => {
                dispatch({ type: CUSTOMER_HELP_MESSAGE_SAVE_STATE_SUCCESS, payload: response.data });
            })
            .catch(function (error) {
                dispatch({ type: CUSTOMER_HELP_MESSAGE_SAVE_STATE_FAIL, payload: error });
            });
    };
}

function mapStateToData(emailId, appId, topic, description, cdoFullName) {
    return {
        "userEmail": emailId,
        "appId": appId,        
        "topic": topic,
        "description": description,
        "cdoName": cdoFullName
    }
}

export const savePrivacyPolicy = (setState, formData) => {
    return dispatch => {
        
        dispatch({ type: PRIVACY_FILE_UPLOAD_SAVE_STATE, payload: "SAVING" });

        axios
            .post('https://incubator-customer-api-dev.optum.com/api/SavePrivacyPolicy', formData)
            .then((response) => {
                setState('privacyUrl', response.data);
                dispatch({ type:  PRIVACY_FILE_UPLOAD_SAVE_STATE_SUCCESS, payload: response.data });
            })
            .catch(function (error) {
                dispatch({ type: PRIVACY_FILE_UPLOAD_SAVE_STATE_FAIL, payload: error });
            });
    };
}

export const saveTermsOfUse = (setState, formData) => {
    return dispatch => {
        
        dispatch({ type: TERMS_OF_USE_UPLOAD_SAVE_STATE, payload: "SAVING" });

        axios
            .post('https://incubator-customer-api-dev.optum.com/api/SaveTermsOfUse', formData)
            .then((response) => {
                setState('termsUrl', response.data);
                dispatch({ type:  TERMS_OF_USE_UPLOAD_SAVE_STATE_SUCCESS, payload: response.data });
            })
            .catch(function (error) {
                dispatch({ type: TERMS_OF_USE_UPLOAD_SAVE_STATE_FAIL, payload: error });
            });
    };
}