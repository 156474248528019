import React from "react";
import DashCard from './DashCard';
import usageimage from '../../../assets/dashboard/usage.png';
// import styled from 'styled-components';

//*****************************************/


//*****************************************/

const StatsCard = ({properties}) => {

    return (
        <DashCard properties={properties}>
            <img src={usageimage} style={{ width: '100%'}}/>
        </DashCard>
    )
}

export default StatsCard;