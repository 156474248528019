
import React from "react";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { connect } from 'react-redux';
import { setState } from '../../../../../redux/actions/adminActions'

//************************************* */

const PopUp = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 495px;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border-radius: 8px;
    z-index: 1;
`

const Heading = styled.div`
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    justify-content: center;
    color: #000000;
    margin-bottom: 24px;
    position: relative;
`

const MarkDown= styled.div`
    width: 516.88px;
    height: 22px;   
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    position: relative;
    text-align: center;
    margin-bottom: 40px;
`

const CloseButton = styled.button`
    padding: 14.5px 60px;
    height: 48px;
    width: 204px;
    cursor: pointer;
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #002677;
    background: #FFFFFF;
    border: 1.5px solid #002677;
    border-radius: 8px;

    &:hover{
        background: #002677;
        color: #FFFFFF;
    }
`


//********************************* */

const ThatsItPopUp = ( { save, setThatsItPopUp, state, setState } ) =>{
    const {t} = useTranslation();

    const saveAdminConfig = () => {
        if(save instanceof Function) save();        
    }

    return(
        <>
            <PopUp>
                <Heading> {t("PopUp.ThatsItPopUp.header")} </Heading>
                <MarkDown style={{'marginBottom': '16px'}}> {t("PopUp.ThatsItPopUp.markdown1")} </MarkDown>
                <MarkDown> {t("PopUp.ThatsItPopUp.markdown2")} </MarkDown>
                <CloseButton onClick={ () => { setThatsItPopUp(false); setState('showSympleNoteHelpAtStartup', false); saveAdminConfig()  } }> {t("PopUp.ThatsItPopUp.close")} </CloseButton>
            </PopUp>
        </>
    )
}



const mapStateToProps = (state) => {
    state = state.adminReducer;

    return {
        state
    };
};

const mapDispatchToProps = {
    setState
};

export default connect(mapStateToProps, mapDispatchToProps)(ThatsItPopUp);
