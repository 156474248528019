import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import controlsIcon from '../../../../../assets/Browser/controls.svg';
import navigationIcon from '../../../../../assets/Browser/navigation.svg';
import closeTabIcon from '../../../../../assets/Browser/close-tab.svg';
import addTabIcon from '../../../../../assets/Browser/add-tab.svg';
import moreIcon from '../../../../../assets/Browser/more.svg';
import favoriteIcon from '../../../../../assets/Browser/favorite.svg';

//******************************************/

const Root = styled.div`
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: solid 1px #D8DADE;
    /* z-index: 1; */
    `

const BTabBar = styled.div`
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;   
    height: 40px;
    `

const BAddressBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 10px;
    background-color: white;    
    height: 40px;
    `

const Tab = styled.div`    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 200px;
    height: 32px;
    line-height: 32px;
    margin: 8px 0 0 14px;
    padding: 0 10px;
    background-color: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 14px;

    & div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-family: 'Roboto';
        font-size: 12px;
        color: #494C4F;
    }
    `

const Controls = styled.img`

    `

const Navigation = styled.img`

    `

const Close = styled.img`

    `

const Favorite = styled.img`

    `

const More = styled.img`
    padding-left: 16px;
    `

const Add = styled.img`
    position: relative;
    top: 5px;
    left: 10px;
    `

const Address = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    line-height: 30px;
    margin-left: 10px;
    padding: 0 20px;
    background-color: #f1f3f4;
    font-size: 14px;
    border-radius: 15px;
    flex: 1;
    font-family: 'Roboto';
    color: #202124;
    `

const Favicon = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 8px;
    `

//******************************************/

const BrowserBar = ({ favicon, urlFragment }) => {

    const URL = urlFragment.toLowerCase();

    const Dev_URL = `https://${URL}-dev.optum.com`;

    return (
        <Root>
            <BTabBar>
                <Controls src={controlsIcon} alt='Browser controls' />
                <Tab>
                    <div>
                        <Favicon src={favicon} />
                    Symptom Checker
                    </div>
                    <Close src={closeTabIcon} alt='Close tab' />
                </Tab>
                <Add src={addTabIcon} alt='Add Tab' />
            </BTabBar>
            <BAddressBar>
                <Navigation src={navigationIcon} alt='Navigation' />
                <Address>
                    {Dev_URL}
                    <Favorite src={favoriteIcon} alt='Favorite this page' />
                </Address>
                <More src={moreIcon} alt='Menu' />
            </BAddressBar>
        </Root>
    );
}

const mapStateToProps = (state) => {
    const favicon = state.editorReducer.faviconData;
    const urlFragment = state.editorReducer.urlFragment;

    return { favicon, urlFragment };
}

export default connect(mapStateToProps)(BrowserBar);