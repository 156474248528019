import React, { useState } from "react";
import styled from 'styled-components';
import DropDownArrow from '../../../assets/toolbar/DropDownArrow.svg';
import DropUpArrow from '../../../assets/toolbar/UpArrow.svg';
import YesTick from '../../../assets/toolbar/YesTick.svg';
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { saveCustomerHelpMessage } from '../../../redux/actions/helpActions';
import { connect } from "react-redux";
import ThankYou from "./ThankYou";

//************************************* */

const useStyles = makeStyles({
    TextInput: {
        background: 'white',
        width: '652px',
        position: 'relative',
        fontFamily: '"Optum Sans", "Helvetica", "Arial", sans-serif',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#4B4D4F',
        boxSizing: 'border-box',
        height: '160px',
        border: '1px solid #4B4D4F',
        borderRadius: '4px',
        alignSelf: 'stretch',
        padding: '8px 12px',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        '&:hover': {
            border: '1px solid #0C55B8'
        },

        '&:focus-visible': {
            outline: 'none',
            border: '1px solid #0C55B8'
        },
    },
});


const Guide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 32px 24px 32px;
    background: #FBF9F4;
    border-radius: 8px;
    margin-bottom: 32px;
`

const Heading = styled.div`
    font-family: "Optum Sans", "Helvetica", "Arial", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 16px;
`

const Markdown = styled.div`
    font-family: "Optum Sans", "Helvetica", "Arial", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #4B4D4F;
    width: 652px;
`

const ContactBox = styled.div`
    box-sizing: border-box;
    height: 514px;
    border: 1px solid #E5E5E6;
    border-radius: 8px;
    padding: 32px;
`

const Column = styled.div`
    width: 100%;
`

const MarkDown1 = styled.div`
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 4px;
`

const Input = styled.div`
    width: 652px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row; 
    align-items: flex-start;
    height: 40px;
    background: #FFFFFF;
    border-radius: 4px;
    margin-bottom : 40px;
    border : 1px solid #4B4D4F;

    &:hover{
        border: 1px solid #0C55B8;
    }

`

const DropDown = styled.div`
    box-sizing: border-box;
    width: 652px;
    background: #FFFFFF;
    z-index: 1;
    top: 281px;
    position: absolute;
    border: 1px solid #0C55B8;
    border-top: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
`

const Input1 = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row; 
    align-items: flex-start;
    height: 38px;
    background: #FFFFFF;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    cursor: pointer;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    &:hover{
        background: rgba(243, 243, 243,1);
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
`

const Text = styled.div`
    width: 606px; 
    left: 12px;
    position: relative;
    padding: 10px 0px;
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
`

const DownArrow = styled.div`
    background-image: url(${DropDownArrow});
    background-repeat: no-repeat;
    background-position: center;
    width: 10px;
    height: 6.17px;
    padding: 16px 12px;
    position: relative;
`

const UpArrow = styled.div`
    background-image: url(${DropUpArrow});
    background-repeat: no-repeat;
    background-position: center;
    width: 10px;
    height: 6.17px;
    padding: 16px 12px;
    position: relative;
`

const Tick = styled.div`
    background-image: url(${YesTick});
    background-repeat: no-repeat;
    background-position: center;
    width: 10px;
    height: 6.17px;
    padding: 16.91px 17px 16.91px 16.91px;
    position: relative;
`

const Counter = styled.div`
    text-align: right;
    margin-top: 4px;
    width: 643px;
    font-size: 14px;
    color: #4B4D4F;
`

const SubmitButton = styled.button`
    padding: 8px 24px;
    height: 40px;
    color: white;
    border: 1.5px solid #002677;
    background: #002677;
    border-radius: 999px;
    cursor: pointer;
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin-top: 48px;
    margin-left: 483px;

    &:disabled{
        color: #6D6F70;
        background: #F3F3F3;
        border: 1.5px solid #F3F3F3;
        cursor: default;
    }

    &:hover:enabled{
        background: #0C55B8;
        border: 1.5px solid #0C55B8;
        color: white;
    }
`

//************************************************ */


const Contact = ({ appId, adminState, username, saveCustomerHelpMessage, cdoFullName }) => {

    const {t} = useTranslation();

    const classes = useStyles();

    const Dropdown_default = t("PopUp.ContactPopUp.DropDown_Default");
    const DropDown_val1 = t("PopUp.ContactPopUp.DropDown_val1");
    const DropDown_val2 = t("PopUp.ContactPopUp.DropDown_val2");
    const DropDown_val3 = t("PopUp.ContactPopUp.DropDown_val3");
    const DropDown_val4 = t("PopUp.ContactPopUp.DropDown_val4");
    const DropDown_val5 = t("PopUp.ContactPopUp.DropDown_val5");
    const DropDown_val6 = t("PopUp.ContactPopUp.DropDown_val6");

    const [showDropDown, setShowDropDown] = useState(false)
    const [showContact, setShowContact] = useState( Dropdown_default );

    const [showThankCard, setShowThankCard] = useState(false);

    const [input, setInput] = useState("");
    const [characterCount, setCharacterCount] = useState(0);
    const MAX_CHAR = 500;

    const inputChanged = (e) => {
        setCharacterCount(e.target.value.length)
        setInput(e.target.value);
    }

    const handleThankPopUp = () => {
        setShowThankCard(true);
        setCharacterCount(0);
        setShowContact(Dropdown_default);
    }

    const clearContent = () => {
        document.getElementById("output").value = '';
        setInput("");
    }

    return (
        <>
            <Guide> 
                <Heading style={{fontSize: '23px', lineHeight : '28px' }}>Send us a message</Heading>
                <Markdown>Fields marked with <span style={{color: '#C40000'}}>*</span> are required. </Markdown>
            </Guide>

            <ContactBox>
                <Column>
                    <MarkDown1> {t("PopUp.ContactPopUp.col1_markdown1")} <span style={{color: ' #C40000', verticalAlign: 'top'}}>*</span> </MarkDown1>
                    <Input 
                        onClick={ () => (setShowDropDown(!showDropDown)) } 
                        style={ showDropDown ? { borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px', border: '1px solid #0C55B8', borderBottom: '1px solid rbga(203, 204, 205, 1)' } : {} }
                    >
                        <Text style={ (showContact !== Dropdown_default) ? {color: '#000000', padding: '11px 0px'} :  {color : 'rgba(75,77,79)', padding: '11px 0px'} } > { showContact } </Text>
                        { !showDropDown ? <DownArrow /> : <UpArrow/> }
                    </Input>

                    {
                        showDropDown ?
                            <DropDown>
                                <Input1>
                                    <Text onClick={ () => { setShowContact(DropDown_val1); setShowDropDown(!showDropDown) } } >
                                        {DropDown_val1}
                                    </Text>
                                    { showContact === DropDown_val1 ? <Tick /> : <></> }
                                </Input1>
                                <Input1>
                                    <Text onClick={ () => {setShowContact(DropDown_val2); setShowDropDown(!showDropDown) } }>
                                        {DropDown_val2}
                                    </Text>
                                    { showContact === DropDown_val2 ? <Tick /> : <></> }
                                </Input1>
                                <Input1>
                                    <Text onClick={ () => {setShowContact(DropDown_val3); setShowDropDown(!showDropDown) } }>
                                        {DropDown_val3} 
                                    </Text>
                                        { showContact === DropDown_val3 ? <Tick /> : <></> }
                                    </Input1>
                                    <Input1>
                                    <Text onClick={ () => {setShowContact(DropDown_val4); setShowDropDown(!showDropDown) } }>
                                            {DropDown_val4}
                                    </Text>
                                    { showContact === DropDown_val4 ? <Tick /> : <></> }
                                </Input1>
                                <Input1>
                                    <Text onClick={ () => {setShowContact( DropDown_val5 ); setShowDropDown(!showDropDown) } }> 
                                        {DropDown_val5} 
                                    </Text>
                                    { showContact === DropDown_val5 ? <Tick /> : <></> }
                                </Input1>
                                <Input1>
                                    <Text onClick={ () => {setShowContact( DropDown_val6 ); setShowDropDown(!showDropDown) } }> 
                                        {DropDown_val6} 
                                    </Text>
                                    { showContact === DropDown_val6 ? <Tick /> : <></> }
                                </Input1>
                            </DropDown>
                        :
                            <></>
                    }
                </Column>

                <Column>
                    <MarkDown1> {t("PopUp.ContactPopUp.col2_markdown1")} <span style={{color: ' #C40000', verticalAlign: 'top'}}>*</span> </MarkDown1>
                    <textarea onChange={inputChanged} maxLength="500" className={classes.TextInput} id="output" /> 
                </Column>

                <Counter>
                        {MAX_CHAR - characterCount} characters remaining
                </Counter>

                <SubmitButton 
                    disabled={(showContact !== Dropdown_default && input !== '') ? false : true} 
                    onClick={ ()=> {saveCustomerHelpMessage( adminState, username, appId, showContact, input, cdoFullName ); handleThankPopUp(); clearContent() } }>  
                    Send message 
                </SubmitButton>
            </ContactBox>

            {
                showThankCard
                ?
                    <ThankYou setShowThankCard={setShowThankCard} />
                :
                    <></>
            }
        </> 
    )
}

const mapStateToProps = (state) => {
    const adminState = state.adminReducer;
    const appId = state.loginReducer.appIdValue;
    const username = state.editorReducer.username;
    const cdoFullName = state.editorReducer.cdoFullName;

    return {
        adminState, 
        appId, 
        username,
        cdoFullName
    };
};

export default connect(mapStateToProps, {saveCustomerHelpMessage})(Contact);

