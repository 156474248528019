import React from "react";
import { connect } from 'react-redux';
import styled from 'styled-components';

//******************************************/

const Root = styled.div`
    position: absolute;
    top: ${props=>props.top};
    bottom: 0;
    left: 0;
    right: 0;    
    margin: auto;
    max-width: 780px;
    background: #FFFFFF;
    overflow-x: none;
    overflow-y: auto;
    font-family: ${props=>props.font};
    font-size: 16px;    
    `

const Sizer = styled.div`
    padding: 0 64px;  
    `

//******************************************/

const Bot = ({ header, context, fontFamily, children }) => {
    const top =
        ((header === 'none') ? 0 : 80) +
        ((context === 'FavIcon') ? 82 : 0) +
        'px';
   
    return (
        <Root top={top} font={fontFamily} >
            <Sizer> {children} </Sizer>
        </Root>
    )
}

const mapStateToProps = (state) => {
    const header = state.editorReducer.header;
    const context = state.editorReducer.context;
    const fontFamily = state.editorReducer.fontFamily;
    
    return { header, context, fontFamily };
}

export default connect(mapStateToProps)(Bot);
