import axios from "axios";

import {
    PROPERTY_CHANGED,
    CONFIG_LOAD_STATE,
    CONFIG_LOAD_STATE_SUCCESS,
    CONFIG_LOAD_STATE_FAIL,
    CONFIG_SAVE_STATE,
    CONFIG_SAVE_STATE_SUCCESS,
    CONFIG_SAVE_STATE_FAIL,
} from "../types";

export const setState = (property, value) => {
    return dispatch => {
        dispatch({
            type: PROPERTY_CHANGED,
            payload: { property, value }
        })
    };
};

export const loadConfigs = (appId) => {
    return dispatch => {
        if(!appId) return;

        if(!(typeof appId === 'string' || appId instanceof String)) return;
        
        dispatch({ type: CONFIG_LOAD_STATE, payload: "LOADING" });

        console.log("APPID: ", appId)
        axios
            .get(`https://incubator-customer-api-dev.optum.com/api/GetSympleNoteSettings?appId=${appId}`)
            .then((response) => {
                dispatch({ type: CONFIG_LOAD_STATE_SUCCESS, payload: mapResponseToState(response.data) });
            })
            .catch(function (error) {
                dispatch({ type: CONFIG_LOAD_STATE_FAIL, payload: error });
            });
    };
}

export const saveConfigs = (state, appId) => {
    return dispatch => {
        if(!appId) return;

        if(!(typeof appId === 'string' || appId instanceof String)) return;

        dispatch({ type: CONFIG_SAVE_STATE, payload: "SAVING" });

        axios.put("https://incubator-customer-api-dev.optum.com/api/SaveSympleNoteSettings", mapStateToData(state, appId))
            .then(response => {
                dispatch({ type: CONFIG_SAVE_STATE_SUCCESS, payload: response.data });
            })
            .catch(function (error) {
                dispatch({ type: CONFIG_SAVE_STATE_FAIL, payload: error });
            });
    };
}

function mapResponseToState(response) {
    return {
        urlFragment: response["urlFragment"],
        cdoFullName: response["cdoFullName"],
        header: response["header-option"],
        faviconName: response["favIcon-filename"],
        faviconData: response["favIcon"],
        logoName: response["logo-filename"],
        logoData: response["logo"],
        textColor: response["body-text-color"],
        fontFamily: response["font-family"],
        buttonColor: response["button-color"],
        trimColor: response["card-trim-color"],
        showWelcomeImage: response["display-welcome-illustration"],
        hasAlternatives: response["displayCareAlternativesCard"],
        careAlternatives: response["careAlternativeOptions"],
        hasPlans: response["displayPatientPlannedCareCard"],
        patientPlans: response["patientPlannedCareOptions"],
        hasNPS: response["collectNpsScore"],
        hasFeedback: response["collectNpsFeedback"],
        termsUrl: response["termsOfUseUrl"],
        termsFileName: response["termsOfUseFileName"],
        privacyUrl: response["privacyPolicyUrl"],
        privacyFileName : response["privacyPolicyFileName"],
        showReportingTab : response["showReportingTab"],
        buttonColorArray : response["buttonColorArray"],
        trimColorArray : response["trimColorArray"]
    }
}

function mapStateToData(state, appId) {
    return {
        "appId": appId,
        "header-option": state.header,
        "logo-filename": state.logoName,
        "logo": state.logoData,
        "favicon-filename": state.faviconName,
        "favIcon": state.faviconData,
        "body-text-color": state.textColor,
        "header-text-color": state.textColor,
        "text-bubbles": "filled",
        "card-trim-color": state.trimColor,
        "font-family": state.fontFamily,
        "button-color": state.buttonColor,
        "display-welcome-illustration": state.showWelcomeImage,
        "displayCareAlternativesCard": state.hasAlternatives,
        "careTypesToDisplay": state.careAlternatives,
        "displayPatientPlannedCareCard": state.hasPlans,
        "patientPlannedCareTypesToDisplay": state.patientPlans,
        "displayTermsOfUse": true,
        "termsOfUseUrl": state.termsUrl,
        "termsOfUseFileName" : state.termsFileName,
        "displayPrivacyPolicy": true,
        "privacyPolicyUrl": state.privacyUrl,
        "privacyPolicyFileName": state.privacyFileName,
        "collectNpsScore": state.hasNPS,
        "collectNpsFeedback": state.hasFeedback,
        "usePatientData": false,
        "buttonColorArray": state.buttonColorArray,
        "trimColorArray": state.trimColorArray
    }
}
