import CareAlternativePreview from "./CareAlternativePreview";
import PatientPlanPreview from './PatientPlansPreview';
import React from 'react';
import { connect } from 'react-redux';
import Bot from "../cards/widgets/Bot";
import styled from 'styled-components';
import BotHeader from "../cards/widgets/BotHeader";

//****************************************** */

const Root = styled.div`
    `

//****************************************** */

const PatientNextStepPreview = ({ hasPlans, hasAlternatives }) =>{

    return (
        <Root>
            <Bot>
            {
                hasAlternatives 
                ?
                    <CareAlternativePreview />
                :
                    null
            }
            <PatientPlanPreview />
            </Bot>
            <BotHeader progressName="welcome" />
        </Root>
    )
}

const mapStateToProps = (state) => {
    const hasPlans = state.editorReducer.hasPlans;
    const hasAlternatives = state.editorReducer.hasAlternatives;

    return { hasPlans, hasAlternatives };
}

export default connect(mapStateToProps)(PatientNextStepPreview);