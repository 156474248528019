import React from "react";
import DashCard from './DashCard';
import styled from 'styled-components';

//*****************************************/

const Category = styled.div`
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    line-height: 32px;
    `

const Cost = styled.div`
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    line-height: 48px;
    `

//*****************************************/

const CostCard = ({properties}) => {

    return (
        <DashCard properties={properties}>
            <Category>Actual Cost</Category>
            <Cost>$507.78</Cost>
            <br />
            <Category>Forecasted</Category>
            <Cost>$1815.64</Cost>            
        </DashCard>
    )
}

export default CostCard;