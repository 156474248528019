import React from "react";
import { connect } from 'react-redux';
import SummaryCard from '../cards/SummaryCard';
import styled from 'styled-components';
import BotHeader from "../cards/widgets/BotHeader";
import Bot from "../cards/widgets/Bot";
import TextBubble from "../cards/TextBubble";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";

//******************************************/

const Root = styled.div`
    `

//******************************************/

const TextPreview = ({ hasPlans, patientPlans }) => {
    const { t } = useTranslation();

    const buttons = patientPlans
        .filter(plan => plan.isDisplayed)
        .map(plan => {
            return plan.displayText || '';
        })

    return (
        <Root>
            <Bot>
                <TextBubble>
                    <ReactMarkdown>
                        {t("preview.textBubble.analyzing")}
                    </ReactMarkdown>
                </TextBubble>
                <SummaryCard
                    hasPlans={hasPlans}
                    plans={buttons}
                />
            </Bot>
            <BotHeader progressName='welcome'/>
        </Root>
    )
}

const mapStateToProps = (state) => {
    const hasPlans = state.editorReducer.hasPlans;
    const patientPlans = state.editorReducer.patientPlans;

    return { hasPlans, patientPlans };
}

export default connect(mapStateToProps)(TextPreview);
