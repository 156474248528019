import React from "react";
import { connect } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import { setState } from "../../../../redux/actions/editorActions";

//******************************************/

const Root = styled.div`
    margin-bottom: 40px;
    `

const Description = styled.p`
    font-size: 16px;
    font-weight: 400;
    margin-top: 8px; 
    color: #505050;   
    `

const OnOff = styled.span`
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    `

//******************************************/

const WelcomeEditor = ({ setState, showWelcomeImage }) => {
    const { t } = useTranslation();

    return (
        <Root>
            <Switch
                checked={showWelcomeImage}
                onChange={(e) => setState('showWelcomeImage', e.target.checked)}
                color="primary"
                label="On"
                className='toggle'
            />
            <OnOff>
                {
                    showWelcomeImage ? t("sidebar.widgets.switch.on") : t("sidebar.widgets.switch.off")}
            </OnOff>
            <Description>
                {t("sidebar.welcome.description")}
            </Description>
        </Root >
    )
}

const mapStateToProps = (state) => {
    const showWelcomeImage = state.editorReducer.showWelcomeImage;

    return { showWelcomeImage };
}

export default connect(mapStateToProps, { setState })(WelcomeEditor);
