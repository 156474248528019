import React from "react";
import SummaryCard from "../cards/SummaryCard";
import { connect } from 'react-redux';

//******************************************/

const PlansPreview = ({ hasPlans, patientPlans }) => {

    patientPlans = Array.isArray(patientPlans)
        ? patientPlans
        : []

    const buttons = patientPlans
        .filter(plan => plan.isDisplayed)
        .map(plan => {
            return plan.displayText || '';
        })

    return (
        <>
            <SummaryCard
                key={buttons}
                hasPlans={hasPlans}
                plans={buttons}
            />
        </>
    )
}
const mapStateToProps = (state) => {
    const hasPlans = state.editorReducer.hasPlans;
    const patientPlans = state.editorReducer.patientPlans;

    return { hasPlans,patientPlans };
}

export default connect(mapStateToProps)(PlansPreview);
