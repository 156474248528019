import React from "react";
import WelcomeCard from '../cards/WelcomeCard';
import styled from 'styled-components';
import BrowserBar from '../cards/widgets/BrowserBar'
import BotHeader from "../cards/widgets/BotHeader";
import Bot from "../cards/widgets/Bot";

//******************************************/

const Root = styled.div`

    `

//******************************************/

const FavIconPreview = () => {

    return (
        <Root>
            <BrowserBar />
            <Bot>
                <WelcomeCard />
            </Bot>
            <BotHeader />
        </Root>
    )
}

export default FavIconPreview;