import React from "react";
import styled from 'styled-components';
import check from '../../../../assets/check.svg';
import { useTranslation } from "react-i18next";

/********************************** */

const PopUp = styled.div`
    position: relative;
`

const CheckIcon = styled.img`
    position: absolute;
    top: 13.5px;
    margin-left: 8px;
`

const Input1 = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column; 
    align-items: flex-start; 
    background: #FFFFFF;
    order: 1;
    align-self: stretch;
    cursor: pointer;
    padding-left: 32px;

    &:hover{
        background: #F2F2F2;
    }
`

const Text = styled.div`
    padding: 8px 0px 0px 0px;
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
`

const SympleNote = styled.div`
    font-weight: 400;
    color: #505050;
    font-size: 13px;
    padding-bottom: 8px;
    `

//************************* */

const DropDownAppId = ({ appId_value, appId, setState })=>{

    const {t} = useTranslation();

    return (
        <PopUp>
            {appId===appId_value.id ?  <CheckIcon src={check} /> : <CheckIcon />}
            <Input1 onClick={ () => { setState('appIdValue', appId_value.id) } }>
                <Text> {appId_value.cdoName} </Text>
                <SympleNote>{t('toolbar.symplenote')}</SympleNote>
            </Input1>
        </PopUp>
    );
}

export default DropDownAppId;