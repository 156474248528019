import React from "react";
import Toolbar from '../sn-editor/sidebar/widgets/Toolbar';
import WelcomeButton from './WelcomeButton';
import WelcomeArticle from './WelcomeArticle';
import styled from 'styled-components';

//*****************************************/

const Page = styled.div`
    position: absolute;
    top: 0px;
    bottom: 5%;
    left: 5%;
    right: 5%;
    background-color: white;
    min-width: 650px;
    min-height: 550px;
    `

const ButtonBar = styled.div`
    position: absolute;
    bottom: 0;
    left: 10%;
    right: 10%;
    height: 40%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 110px;
    `

//*****************************************/


const Welcome = () => {
    const article = {
        greeting: 'Welcome to your Virtual Assistant editor',
        description: 'Here you can customize your company’s Virtual Assistant and change elements like color, logo, content, and more. Your changes will be automatically saved as you work. When you are finished, click on the Publish button, located at the top of the screen, to publish your changes.',
        instruct: 'Select a category below to get started.'
    }

    const designButton = {
        title: 'Design',
        description: 'Customize  the look and feel of your healthbot.',
        route: 'editor'
    }

    const featureButton = {
        title: 'Feature',
        description: 'Manage what features are in your experience by toggling them on/off.',
        route: 'editor'
    }

    const contentButton = {
        title: 'Content',
        description: 'Edit language and content used within the healthbot for each of the sections below.',
        route: 'editor'
    }

    return (
        <Page>
            <Toolbar showsettings={true} />
            <WelcomeArticle {...article} />
            <ButtonBar>
                <WelcomeButton {...designButton} />
                <WelcomeButton {...featureButton} />
                <WelcomeButton {...contentButton} />
            </ButtonBar>
        </Page>
    )
}

export default Welcome;