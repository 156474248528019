import React from "react";
import Reference from './Cards/Reference.js';
import FAQ from './Cards/FAQ';
import ChangeLog from './Cards/ChangeLog';
import Release from './Cards/Release';
import Contact from './Cards/Contact';
import styled from "styled-components";

//************************************** */

const Page = styled.div`
    inset: 0px 0px 0px 372px;
    padding: 40px 84px 0px 0px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 372px;
    overflow-x: auto;
`

//************************************** */

const WorkSpace = ({ showRef, showFAQ, showRelease, showContact, showChange, setShowRef, setShowContact, setShowFAQ }) => {
    return (
        <Page>
            {
                showRef ?
                    <Reference setShowContact={setShowContact} setShowRef={setShowRef} />
                :
                    <></>
            }
            {
                showFAQ ?
                    <FAQ setShowContact={setShowContact} setShowRef={setShowRef} setShowFAQ={setShowFAQ} />
                :
                    <></>
            }
            {
                showChange ?
                    <ChangeLog />
                :
                    <></>
            }
            {
                showRelease ?
                    <Release />
                :
                    <></>
            }
            {
                showContact ?
                    <Contact />
                :
                    <></>
            }
        </ Page>
    )
}

export default WorkSpace;