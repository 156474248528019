import React from "react";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

//******************************************/

const PButton = styled.div`
    position: relative;
    width: 320px;
    height: 320px;
    `

const Button = styled.button`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: none;
    border-radius: 10px;
    background-color: white;
    padding: 0;
    cursor: pointer;
    `

const Logo = styled.img`
    max-width: 100%;
    `

const Description = styled.span`
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 10%;
    `

//*****************************************/

const ProductButton = ({ icon, description, route }) => {
    const navigate = useNavigate();

    return (
        <PButton>
            <Button
                onClick={() => navigate(`/${route}`)}
            >
                <Logo src={icon} alt='logo' />
            </Button>
            <Description>{description}</Description>
        </PButton>
    );
}

export default ProductButton;