import React from "react";
import ProductButton from './ProductButton';
import styled from 'styled-components';
import incubatorLogo from '../../assets/incubator-logo.png';
import SympleNoteLogo from '../../assets/symple-note-logo.svg';
import EasyCheckLogo from '../../assets/easy-check-logo.svg';
import { useTranslation } from "react-i18next";

//*****************************************/

const Page = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #F8F8F8;
    min-width: 650px;
    min-height: 550px;
    `

const Toolbar = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: white;
    height: 60px;
    padding: 0 38px;
    font-size: 18px;
    `

const Logo = styled.img`
    height: 25px;
    `

const Incubator = styled.span`
    font-weight: 700;
    padding-left: .25em;
    `

const Editor = styled.span`
    font-weight: 400;
    padding-left: .25em;
    `

const Instructions = styled.div`
    position: absolute;
    top: 15%;
    left: 0;
    right: 0;
    text-align: center;
    background: transparent;
    font-size: 21px;
    font-weight: 500;
    `

const ButtonBar = styled.div`
    position: absolute;
    top: 25%;
    bottom: 0;
    left: 20%;
    right: 15%;
    height: 33%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 110px;
    `

//*****************************************/


const ProductSelect = () => {
    const sympleNoteButton = {
        icon: SympleNoteLogo,
        description: 'SympleNote',
        route: 'editor'
    }

    const easyCheckButton = {
        icon: EasyCheckLogo,
        description: 'EasyCheck',
        route: 'editor'
    }

    const { t } = useTranslation();

    return (
        <Page>
            <Toolbar>
                <Logo src={incubatorLogo} alt='Incubator Logo' />
                <Incubator>
                    {t("product-select.incubator")}
                </Incubator>
                <Editor>
                    {t("product-select.editor")}
                </Editor>
            </Toolbar>
            <Instructions>
                {t("product-select.instruction")}
            </Instructions>
            <ButtonBar>
                <ProductButton {...sympleNoteButton} />
                <ProductButton {...easyCheckButton} />
            </ButtonBar>
        </Page>
    )
}

export default ProductSelect;