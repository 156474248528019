import React, { useEffect } from "react";
import DashCard from './DashCard';
import { VictoryChart, VictoryBar, Bar } from "victory";
import styled from 'styled-components';

//*****************************************/

const Page = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;    
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    font-size: 12px;
    height: ${prop => prop.height}
    `

const PRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    font-size: 24px;
    height: ${prop => prop.height}
    `

const Label = styled.div`
    font-weight: bold;    
    text-align: center;
    `

const Data = styled.div`
    font-weight: 400;
    text-align: center;
    line-height: 1em;
    `

const Side = styled.div`
    border: solid 2px red;
    `

//*****************************************/

const Nps = ({ properties, data }) => {
    data = Array.isArray(data)
        ? data
        : []

    let npsScores = [
        { x: '0', y: 0 },
        { x: '0', y: 0 },
        { x: '1', y: 0 },
        { x: '2', y: 0 },
        { x: '3', y: 0 },
        { x: '4', y: 0 },
        { x: '5', y: 0 },
        { x: '6', y: 0 },
        { x: '7', y: 0 },
        { x: '8', y: 0 },
        { x: '9', y: 0 },
        { x: '10', y: 0 }
    ];

    let promoters = 0,
        detractors = 0,
        passives = 0;

    data.forEach(element => {
        const nps = element.npsScore;
        const npsGroup = element.npsGroup;

        if (npsGroup) {
            if (nps >= 0 && nps <= 6) detractors++;
            if (nps >= 7 && nps <= 8) passives++;
            if (nps >= 9 && nps <= 10) promoters++;

            if (nps >= 0 && nps <= 10) npsScores[nps + 1].y++;
        }
    });

    const total = promoters + detractors + passives;
    const promote = parseInt((promoters / total) * 100);
    const detract = parseInt((detractors / total) * 100);
    const pass = parseInt((passives / total) * 100);
    const nps = promote - detract;

    useEffect(() => {

    }, [data])

    return (
        <DashCard properties={properties}>
            <Row>
                <PRow>
                    <Label>NPS:&nbsp;</Label>
                    <Data>{nps}</Data>
                </PRow>
            </Row>
            <Page>
                <Row>
                    <VictoryChart height={225} width={800}
                        domain={{ x: [0, 11] }}
                        domainPadding={{ x: [5, 5], y: [0, 10] }}
                    >
                        <VictoryBar
                            dataComponent={
                                <Bar />
                            }
                            style={{
                                data: { fill: "tomato" }
                            }}
                            data={npsScores}
                        />
                    </VictoryChart>
                </Row>
                <Row style={{ fontSize: '10px !important' }}>
                    <Row>
                        <Label>Detractors:&nbsp;</Label>
                        <Data>{detractors}</Data>
                    </Row>
                    <Row>
                        <Label>Passive:&nbsp;</Label>
                        <Data>{passives}</Data>
                    </Row>
                    <Row>
                        <Label>Promoters:&nbsp;</Label>
                        <Data>{promoters}</Data>
                    </Row>
                </Row>
            </Page>
        </DashCard >
    )
}

export default Nps;