import React from "react";
import DashCard from './DashCard';
import styled from 'styled-components';
// import availimage from '../../../assets/dashboard/availability.png';

//*****************************************/

const Percent = styled.div`
    font-size: 48px;
    font-weight: 600;
    text-align: center;
    line-height: 64px;
    `

//*****************************************/

const HealthCard = ({ properties }) => {

    return (
        <DashCard properties={properties}>
            <Percent>99%</Percent>
            {/* <img src={availimage} style={{ height: '75%'}}/> */}
            <br />
            <table style={{ width: '100%' }}>
                <thead>
                    <tr style={{ background: '#CCCCCC' }}>
                        <th style={{ textAlign: 'left' }}>Test</th>
                        <th style={{ textAlign: 'center' }}>Availability</th>
                        <th style={{ textAlign: 'right' }}>Latency</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Client</td>
                        <td style={{ textAlign: 'center' }}>100%</td>
                        <td style={{ textAlign: 'right' }}>777 ms</td>
                    </tr>
                    <tr>
                        <td>Server</td>
                        <td style={{ textAlign: 'center' }}>98%</td>
                        <td style={{ textAlign: 'right' }}>567 ms</td>
                    </tr>

                    <tr>
                        <td>Infermedica?</td>
                        <td style={{ textAlign: 'center' }}></td>
                        <td style={{ textAlign: 'right' }}></td>
                    </tr>
                    <tr>
                        <td>EHR/Portal?</td>
                        <td style={{ textAlign: 'center' }}></td>
                        <td style={{ textAlign: 'right' }}></td>
                    </tr>
                </tbody>

            </table>

        </DashCard>
    )
}

export default HealthCard;