import React from "react";
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Card from './widgets/Card';
import { useTranslation } from "react-i18next";
import TermsImage from '../../../../assets/workspace/Terms.svg';

//******************************************/

const Note = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em 0;
    padding-top: 8px;

    & p {
        font-size: 14px;
        margin-bottom: 8px;
    }
    `

const Text = styled.div`
    margin-top: -8px;

    & a, a:link, a:visited, a:hover  {
        color: #005FBE;
    }
    `

const Logo = styled.img`
    width: 228px;  
    `

//******************************************/

const DisclaimerCard = ({termsUrl, privacyUrl}) => {
    const { t } = useTranslation();

    const header = '';
    const text = t("preview.disclaimerCard.text");
    const markdown = "\n\nBy continuing, you are agreeing to our "; 
    const footer = [t("preview.disclaimerCard.agree")];

    return (
        <Card header={header} body='' footer={footer}>
            <Text>
                <ReactMarkdown>
                    {text}
                </ReactMarkdown>
            </Text>
            <Text>
                {markdown}
                <span> <a href={termsUrl} target="_blank" rel="noopener noreferrer" style={termsUrl ? {pointerEvents : 'visible'} : {pointerEvents : "none"} }>Terms of Use </a></span>
                and our 
                <span> <a href={privacyUrl} target="_blank" rel="noopener noreferrer" style={privacyUrl ? {pointerEvents : "visible"}: {pointerEvents : "none"} }>Privacy Policy.</a></span>
            </Text>
            <Note>
                <Logo className='logo' src={TermsImage} alt='Terms logo' />
                <p>This symptom checker is powered by Infermedica</p>
            </Note>
        </Card>
    )
}

const mapStateToProps = (state) => {
    const termsUrl =  state.editorReducer.termsUrl;
    const privacyUrl =  state.editorReducer.privacyUrl;

    return {
        termsUrl,
        privacyUrl
    };
}

export default connect(mapStateToProps)(DisclaimerCard);