export default
    [

        {
            "id": "501358bb8cc7f15fdef772791145694bb337f6c01fd116eed256607b3da8208b",
            "conversationId": "501358bb8cc7f15fdef772791145694bb337f6c01fd116eed256607b3da8208b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-06-25T15:03:26.182Z",
            "endTime": "2021-06-25T15:33:43.499Z",
            "timeSpent": 1817,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgVAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgVAAAAAAAAAA==/",
            "_etag": "\"23008d0c-0000-0100-0000-611e39e60000\"",
            "_attachments": "attachments/",
            "_ts": 1629370854
        },
        {
            "id": "a6c32b33f92b85d968bd7c876aa041404984e6ab9087d7d6b8cba173c6f9f1b3",
            "conversationId": "a6c32b33f92b85d968bd7c876aa041404984e6ab9087d7d6b8cba173c6f9f1b3",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "black stools"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-06-25T15:05:42.207Z",
            "endTime": "2021-06-25T15:36:03.104Z",
            "timeSpent": 1821,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgWAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgWAAAAAAAAAA==/",
            "_etag": "\"c8004fe6-0000-0100-0000-60d5fd810000\"",
            "_attachments": "attachments/",
            "_ts": 1624636801
        },
        {
            "id": "30f44f2ab2a8e3a5d8308bedf4062ef825cf5ef4e91135989f12b4b88a55109c",
            "conversationId": "30f44f2ab2a8e3a5d8308bedf4062ef825cf5ef4e91135989f12b4b88a55109c",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "lower back pain spreading through the back of the thigh to the knee"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-06-25T15:09:45.834Z",
            "endTime": "2021-06-25T15:40:23.067Z",
            "timeSpent": 1837,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgXAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgXAAAAAAAAAA==/",
            "_etag": "\"c80050e6-0000-0100-0000-60d5fd810000\"",
            "_attachments": "attachments/",
            "_ts": 1624636801
        },
        {
            "id": "d81887032220e7cd33b877c6242bfadecb1dd220635f65ba40abb2e5e751be7c",
            "conversationId": "d81887032220e7cd33b877c6242bfadecb1dd220635f65ba40abb2e5e751be7c",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "headache"
            ],
            "riskFactors": [
                "Diagnosed chronic obstructive pulmonary disease",
                "Diagnosed diabetes",
                "High BMI",
                "Smoking cigarettes",
                "History of chickenpox or VZV vaccination",
                "Last tetanus immunization over 5 years ago",
                "Woman"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-06-28T09:40:28.987Z",
            "endTime": "2021-06-28T09:42:41.642Z",
            "timeSpent": 133,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgYAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgYAAAAAAAAAA==/",
            "_etag": "\"cc0093ee-0000-0100-0000-60d99da10000\"",
            "_attachments": "attachments/",
            "_ts": 1624874401
        },
        {
            "id": "b4374ac2b9133be20e0caec646cf49c55ec976eee4659632aafb0bc0d3636297",
            "conversationId": "b4374ac2b9133be20e0caec646cf49c55ec976eee4659632aafb0bc0d3636297",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "chest pain"
            ],
            "riskFactors": [
                "Diagnosed chronic obstructive pulmonary disease",
                "Diagnosed diabetes",
                "High BMI",
                "Smoking cigarettes",
                "History of chickenpox or VZV vaccination",
                "Last tetanus immunization over 5 years ago",
                "Woman"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-06-28T09:54:20.088Z",
            "endTime": "2021-06-28T09:55:28.843Z",
            "timeSpent": 69,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgZAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgZAAAAAAAAAA==/",
            "_etag": "\"cc0094ee-0000-0100-0000-60d99da10000\"",
            "_attachments": "attachments/",
            "_ts": 1624874401
        },
        {
            "id": "0ae011290e4431d98da13f1004fa7c4b3747f4bafe9c77d30bbf023a99e26a7c",
            "conversationId": "0ae011290e4431d98da13f1004fa7c4b3747f4bafe9c77d30bbf023a99e26a7c",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-06-28T10:02:29.505Z",
            "endTime": "2021-06-28T10:32:29.175Z",
            "timeSpent": 1800,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgaAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgaAAAAAAAAAA==/",
            "_etag": "\"cc0099ff-0000-0100-0000-60d9abb10000\"",
            "_attachments": "attachments/",
            "_ts": 1624878001
        },
        {
            "id": "36831c520910a50a2c4791db411c5f9497b0b4ef1467b30f323ed79563f528aa",
            "conversationId": "36831c520910a50a2c4791db411c5f9497b0b4ef1467b30f323ed79563f528aa",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-06-28T14:54:11.386Z",
            "endTime": "2021-06-28T15:24:12.405Z",
            "timeSpent": 1801,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgbAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgbAAAAAAAAAA==/",
            "_etag": "\"cd00e6fa-0000-0100-0000-60d9eaf90000\"",
            "_attachments": "attachments/",
            "_ts": 1624894201
        },
        {
            "id": "e3f8b53f1bc328d54883f16aa7e201881e07653bc6d77b392809eb9d89ef50c8",
            "conversationId": "e3f8b53f1bc328d54883f16aa7e201881e07653bc6d77b392809eb9d89ef50c8",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-06-28T17:26:52.118Z",
            "endTime": "2021-06-28T17:57:37.694Z",
            "timeSpent": 1846,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgcAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgcAAAAAAAAAA==/",
            "_etag": "\"ce002398-0000-0100-0000-60da0e210000\"",
            "_attachments": "attachments/",
            "_ts": 1624903201
        },
        {
            "id": "1203370034234f6b4a7b0da7653091e2d5c8af37405f6ce2b2716977657d2e21",
            "conversationId": "1203370034234f6b4a7b0da7653091e2d5c8af37405f6ce2b2716977657d2e21",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-06-28T17:38:43.818Z",
            "endTime": "2021-06-28T18:08:43.443Z",
            "timeSpent": 1800,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgdAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgdAAAAAAAAAA==/",
            "_etag": "\"ce00f5b3-0000-0100-0000-60da15290000\"",
            "_attachments": "attachments/",
            "_ts": 1624905001
        },
        {
            "id": "58a75fb640110b8a74a35579a3f4a1141ad44dba7b31cc34dbdbc0343780848c",
            "conversationId": "58a75fb640110b8a74a35579a3f4a1141ad44dba7b31cc34dbdbc0343780848c",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-06-28T17:40:44.707Z",
            "endTime": "2021-06-28T18:10:44.743Z",
            "timeSpent": 1800,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgeAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgeAAAAAAAAAA==/",
            "_etag": "\"ce00f6b3-0000-0100-0000-60da15290000\"",
            "_attachments": "attachments/",
            "_ts": 1624905001
        },
        {
            "id": "2ceac1e5dd192c4edd3be095b52a2050b19cd26638c9bd66b04a28161b592efe",
            "conversationId": "2ceac1e5dd192c4edd3be095b52a2050b19cd26638c9bd66b04a28161b592efe",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-06-28T17:44:07.879Z",
            "endTime": "2021-06-28T18:14:07.5Z",
            "timeSpent": 1800,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgfAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgfAAAAAAAAAA==/",
            "_etag": "\"ce00f7b3-0000-0100-0000-60da15290000\"",
            "_attachments": "attachments/",
            "_ts": 1624905001
        },
        {
            "id": "a578e84c758652808688ec9c21a980ce7be7f8cb2a9d28b44478ebc255b570c7",
            "conversationId": "a578e84c758652808688ec9c21a980ce7be7f8cb2a9d28b44478ebc255b570c7",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-06-28T18:01:11.589Z",
            "endTime": "2021-06-28T18:31:12.193Z",
            "timeSpent": 1801,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIggAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIggAAAAAAAAAA==/",
            "_etag": "\"ce00cece-0000-0100-0000-60da1c310000\"",
            "_attachments": "attachments/",
            "_ts": 1624906801
        },
        {
            "id": "4ab5cd88ad7a7d24cfc643bfe6af1bc8289bd38897f69c1958ae810617fe3553",
            "conversationId": "4ab5cd88ad7a7d24cfc643bfe6af1bc8289bd38897f69c1958ae810617fe3553",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-06-28T18:02:48.749Z",
            "endTime": "2021-06-28T18:32:48.948Z",
            "timeSpent": 1800,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIghAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIghAAAAAAAAAA==/",
            "_etag": "\"ce00ccce-0000-0100-0000-60da1c310000\"",
            "_attachments": "attachments/",
            "_ts": 1624906801
        },
        {
            "id": "9df4b34c2e14292d1176069221af790f57ea64ae06c96d77cece61c7e6dbb739",
            "conversationId": "9df4b34c2e14292d1176069221af790f57ea64ae06c96d77cece61c7e6dbb739",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-06-28T18:02:59.041Z",
            "endTime": "2021-06-28T18:32:59.455Z",
            "timeSpent": 1800,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgiAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgiAAAAAAAAAA==/",
            "_etag": "\"ce00cdce-0000-0100-0000-60da1c310000\"",
            "_attachments": "attachments/",
            "_ts": 1624906801
        },
        {
            "id": "7c85c517fde8e9b60166aceaa15f1505cfde94ccdb90b8d3acb2c7e480d50ede",
            "conversationId": "7c85c517fde8e9b60166aceaa15f1505cfde94ccdb90b8d3acb2c7e480d50ede",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-06-29T08:46:23.37Z",
            "endTime": "2021-06-29T08:46:23.37Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgjAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgjAAAAAAAAAA==/",
            "_etag": "\"d30031d7-0000-0100-0000-60dbde3e0000\"",
            "_attachments": "attachments/",
            "_ts": 1625022014
        },
        {
            "id": "0d056a6ddd7951e830b3c2f05a5b878ea748de83e2acdc9d1561bb0dd65ce754",
            "conversationId": "0d056a6ddd7951e830b3c2f05a5b878ea748de83e2acdc9d1561bb0dd65ce754",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "chest pain"
            ],
            "riskFactors": [
                "Diagnosed chronic obstructive pulmonary disease",
                "Diagnosed diabetes",
                "High BMI",
                "Smoking cigarettes",
                "History of chickenpox or VZV vaccination",
                "Last tetanus immunization over 5 years ago",
                "Woman"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-06-29T09:09:12.574Z",
            "endTime": "2021-06-29T09:10:06.75Z",
            "timeSpent": 54,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgkAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgkAAAAAAAAAA==/",
            "_etag": "\"d30032d7-0000-0100-0000-60dbde3e0000\"",
            "_attachments": "attachments/",
            "_ts": 1625022014
        },
        {
            "id": "dc93fd6ed3dc04e13356cec60ccd40aaff55e2b03215a109f4ec2b580beaef0b",
            "conversationId": "dc93fd6ed3dc04e13356cec60ccd40aaff55e2b03215a109f4ec2b580beaef0b",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-06-29T09:10:49.942Z",
            "endTime": "2021-06-29T09:10:49.942Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIglAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIglAAAAAAAAAA==/",
            "_etag": "\"d30033d7-0000-0100-0000-60dbde3e0000\"",
            "_attachments": "attachments/",
            "_ts": 1625022014
        },
        {
            "id": "be6df034680b601b3e9a5c7c24054086715d62eafb0ed94c78d6d8685bd1d501",
            "conversationId": "be6df034680b601b3e9a5c7c24054086715d62eafb0ed94c78d6d8685bd1d501",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [
                "Diagnosed chronic obstructive pulmonary disease",
                "Diagnosed diabetes",
                "High BMI",
                "Smoking cigarettes",
                "History of chickenpox or VZV vaccination",
                "Last tetanus immunization over 5 years ago",
                "Woman"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-06-29T10:05:59.343Z",
            "endTime": "2021-06-29T10:10:40.955Z",
            "timeSpent": 282,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgmAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgmAAAAAAAAAA==/",
            "_etag": "\"d3002fd7-0000-0100-0000-60dbde3e0000\"",
            "_attachments": "attachments/",
            "_ts": 1625022014
        },
        {
            "id": "9f35b364f29f32462def9212ea716a4e5160830d646f33fb39a7d195d0801ab0",
            "conversationId": "9f35b364f29f32462def9212ea716a4e5160830d646f33fb39a7d195d0801ab0",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "chest pain"
            ],
            "riskFactors": [
                "Diagnosed chronic obstructive pulmonary disease",
                "Diagnosed diabetes",
                "High BMI",
                "Smoking cigarettes",
                "History of chickenpox or VZV vaccination",
                "Last tetanus immunization over 5 years ago",
                "Woman"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-06-29T09:30:10.176Z",
            "endTime": "2021-06-29T09:37:11.472Z",
            "timeSpent": 421,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgnAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgnAAAAAAAAAA==/",
            "_etag": "\"d30034d7-0000-0100-0000-60dbde3e0000\"",
            "_attachments": "attachments/",
            "_ts": 1625022014
        },
        {
            "id": "9faacd51596ceb471ee33900aa6432dfb85be58c2752c795cffbf12ff8a754ce",
            "conversationId": "9faacd51596ceb471ee33900aa6432dfb85be58c2752c795cffbf12ff8a754ce",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "chest pain"
            ],
            "riskFactors": [
                "Diagnosed chronic obstructive pulmonary disease",
                "Diagnosed diabetes",
                "High BMI",
                "Smoking cigarettes",
                "History of chickenpox or VZV vaccination",
                "Last tetanus immunization over 5 years ago",
                "Woman"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-06-29T08:28:44.804Z",
            "endTime": "2021-06-29T08:29:41.204Z",
            "timeSpent": 56,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgoAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgoAAAAAAAAAA==/",
            "_etag": "\"d30030d7-0000-0100-0000-60dbde3e0000\"",
            "_attachments": "attachments/",
            "_ts": 1625022014
        },
        {
            "id": "2e390db5a8ec77ec47211d77b2c5b6bc54be4286382bbe7b7c7ed0c418ae6b9e",
            "conversationId": "2e390db5a8ec77ec47211d77b2c5b6bc54be4286382bbe7b7c7ed0c418ae6b9e",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-06-30T17:49:00.929Z",
            "endTime": "2021-06-30T17:49:06.37Z",
            "timeSpent": 5,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgpAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgpAAAAAAAAAA==/",
            "_etag": "\"d9002516-0000-0100-0000-60dd2fbe0000\"",
            "_attachments": "attachments/",
            "_ts": 1625108414
        },
        {
            "id": "3b1f0f08ea7f8ffc6e3cf9ec38738df80416d42f4c0db4f5472956881ba097e2",
            "conversationId": "3b1f0f08ea7f8ffc6e3cf9ec38738df80416d42f4c0db4f5472956881ba097e2",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-08T22:01:00.073Z",
            "endTime": "2021-07-08T22:01:00.073Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgqAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgqAAAAAAAAAA==/",
            "_etag": "\"1100fc7a-0000-0100-0000-60e7bbbe0000\"",
            "_attachments": "attachments/",
            "_ts": 1625799614
        },
        {
            "id": "f91b6c4dd6c1cb2f39088bd201d7855f6f9059ae5c3b7f9438a0ec116d558205",
            "conversationId": "f91b6c4dd6c1cb2f39088bd201d7855f6f9059ae5c3b7f9438a0ec116d558205",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Creaking joints during movement"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T20:48:04.207Z",
            "endTime": "2021-07-09T20:48:48.065Z",
            "timeSpent": 44,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgrAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgrAAAAAAAAAA==/",
            "_etag": "\"14003fb2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "724b331c831b149890217f3496285a57176bf187635cbf6c7356eb905aaab8ff",
            "conversationId": "724b331c831b149890217f3496285a57176bf187635cbf6c7356eb905aaab8ff",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T20:01:08.765Z",
            "endTime": "2021-07-09T20:01:08.765Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgsAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgsAAAAAAAAAA==/",
            "_etag": "\"140040b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "846f3651764346fbcb3356fb3e6b0c6265a8f2919dd42bc25b665aa8f2cc9a0f",
            "conversationId": "846f3651764346fbcb3356fb3e6b0c6265a8f2919dd42bc25b665aa8f2cc9a0f",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T20:47:39.278Z",
            "endTime": "2021-07-09T20:47:39.278Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgtAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgtAAAAAAAAAA==/",
            "_etag": "\"140041b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "4ae3dff60ab6006384162a6a58a0227103d61f20e95927477563f86ed248dde6",
            "conversationId": "4ae3dff60ab6006384162a6a58a0227103d61f20e95927477563f86ed248dde6",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Rash"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T20:05:31.666Z",
            "endTime": "2021-07-09T20:12:10.695Z",
            "timeSpent": 399,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIguAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIguAAAAAAAAAA==/",
            "_etag": "\"140042b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "b2e84b4396d5499f5d7b1d897e686685b30884d95cf4db12fb32edd900a7853a",
            "conversationId": "b2e84b4396d5499f5d7b1d897e686685b30884d95cf4db12fb32edd900a7853a",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Heartburn"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T20:12:24.305Z",
            "endTime": "2021-07-09T20:19:12.882Z",
            "timeSpent": 409,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgvAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgvAAAAAAAAAA==/",
            "_etag": "\"140043b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "03a6c9884cbe980a8ffd497b0455333a567ab436d2404748716db87afcf89368",
            "conversationId": "03a6c9884cbe980a8ffd497b0455333a567ab436d2404748716db87afcf89368",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T21:33:54.805Z",
            "endTime": "2021-07-09T21:34:16.247Z",
            "timeSpent": 21,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgwAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgwAAAAAAAAAA==/",
            "_etag": "\"140044b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "1b8d3a726d7dd606b68f805f5ea189fe6f8c25dbfdb7666c3b5e233fc3501e4a",
            "conversationId": "1b8d3a726d7dd606b68f805f5ea189fe6f8c25dbfdb7666c3b5e233fc3501e4a",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T19:57:26.115Z",
            "endTime": "2021-07-09T19:57:26.115Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgxAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgxAAAAAAAAAA==/",
            "_etag": "\"140045b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "ffb4b6585a119418f265433fdeecb71bea6031b9a08ebb802f003e8bf68b802b",
            "conversationId": "ffb4b6585a119418f265433fdeecb71bea6031b9a08ebb802f003e8bf68b802b",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T19:55:09.645Z",
            "endTime": "2021-07-09T19:55:09.645Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgyAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgyAAAAAAAAAA==/",
            "_etag": "\"140046b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "147ac4bda03b54bcaf8afe05b30c0ec9b7762b8d4a74322a99a4b1cd35dd0a28",
            "conversationId": "147ac4bda03b54bcaf8afe05b30c0ec9b7762b8d4a74322a99a4b1cd35dd0a28",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T19:50:34.954Z",
            "endTime": "2021-07-09T19:50:34.954Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgzAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgzAAAAAAAAAA==/",
            "_etag": "\"140047b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "8fbac9e825dd997b15e620c96a4ea59de2b58c1665ac39c031685872cd80627e",
            "conversationId": "8fbac9e825dd997b15e620c96a4ea59de2b58c1665ac39c031685872cd80627e",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T19:55:16.375Z",
            "endTime": "2021-07-09T19:55:16.375Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg0AAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg0AAAAAAAAAA==/",
            "_etag": "\"140048b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "060412beed8d720343f8c01a959bca90a1d5b9f1fd4225105a9199e92f0752e0",
            "conversationId": "060412beed8d720343f8c01a959bca90a1d5b9f1fd4225105a9199e92f0752e0",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T19:56:08.671Z",
            "endTime": "2021-07-09T19:56:08.671Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg1AAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg1AAAAAAAAAA==/",
            "_etag": "\"140049b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "93be483daf7d91ea29da47de0a50e1fba48af80dc49512fade81aab9f81797d0",
            "conversationId": "93be483daf7d91ea29da47de0a50e1fba48af80dc49512fade81aab9f81797d0",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T20:47:52.424Z",
            "endTime": "2021-07-09T20:47:52.424Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg2AAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg2AAAAAAAAAA==/",
            "_etag": "\"14004ab2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "e1cede23859fa2485ffe93c0fd646d950a14157816665c63bff7c7475566b630",
            "conversationId": "e1cede23859fa2485ffe93c0fd646d950a14157816665c63bff7c7475566b630",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T14:03:16.165Z",
            "endTime": "2021-07-09T14:03:58.165Z",
            "timeSpent": 42,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg3AAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg3AAAAAAAAAA==/",
            "_etag": "\"14004bb2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "1560dd9e03d95090ce801b3e464b71678494bb01839f5b6dae7fec790b6d2d56",
            "conversationId": "1560dd9e03d95090ce801b3e464b71678494bb01839f5b6dae7fec790b6d2d56",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T15:22:26.161Z",
            "endTime": "2021-07-09T15:22:26.161Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg4AAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg4AAAAAAAAAA==/",
            "_etag": "\"14004cb2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "873c82839e98119510bb4124f0ee2d590fec9d6f6279846e9c8a227acdf70f91",
            "conversationId": "873c82839e98119510bb4124f0ee2d590fec9d6f6279846e9c8a227acdf70f91",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T19:52:05.03Z",
            "endTime": "2021-07-09T19:52:05.03Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg5AAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg5AAAAAAAAAA==/",
            "_etag": "\"14004db2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "8c66cf7e0955d6024936445439f6f88da9b7c0d11c3c31efa2e26d784abc520b",
            "conversationId": "8c66cf7e0955d6024936445439f6f88da9b7c0d11c3c31efa2e26d784abc520b",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T14:26:19.881Z",
            "endTime": "2021-07-09T14:29:41.079Z",
            "timeSpent": 201,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg6AAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg6AAAAAAAAAA==/",
            "_etag": "\"14004eb2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "ee081753258577851e326763bc858e7b877879b00ab77db85f80ef8303317a40",
            "conversationId": "ee081753258577851e326763bc858e7b877879b00ab77db85f80ef8303317a40",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T14:07:52.762Z",
            "endTime": "2021-07-09T14:07:52.762Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg7AAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg7AAAAAAAAAA==/",
            "_etag": "\"14004fb2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "2595d5c265f701a85b9c1c79e3eb33f46316686c5c7e0a1e79700ccce9a9d27e",
            "conversationId": "2595d5c265f701a85b9c1c79e3eb33f46316686c5c7e0a1e79700ccce9a9d27e",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T14:42:13.306Z",
            "endTime": "2021-07-09T14:42:13.306Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg8AAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg8AAAAAAAAAA==/",
            "_etag": "\"140050b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "3cd9e9e93c530336c0e0298f41af5201a59007a017e22aae335654a23ec895f4",
            "conversationId": "3cd9e9e93c530336c0e0298f41af5201a59007a017e22aae335654a23ec895f4",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T14:22:25.183Z",
            "endTime": "2021-07-09T14:25:23.856Z",
            "timeSpent": 179,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg9AAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg9AAAAAAAAAA==/",
            "_etag": "\"140051b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "192435c7340bd96333342987eead7c6361a2f6dc5d63ab34fab1b618d2da5321",
            "conversationId": "192435c7340bd96333342987eead7c6361a2f6dc5d63ab34fab1b618d2da5321",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T14:21:37.826Z",
            "endTime": "2021-07-09T14:21:48.841Z",
            "timeSpent": 11,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg+AAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg+AAAAAAAAAA==/",
            "_etag": "\"140052b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "2964d6d474f4c6f4ee0de60a5053a883fbb47c8186c47f3457b919c6bfc17b3e",
            "conversationId": "2964d6d474f4c6f4ee0de60a5053a883fbb47c8186c47f3457b919c6bfc17b3e",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T14:07:48.285Z",
            "endTime": "2021-07-09T14:07:48.285Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg-AAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg-AAAAAAAAAA==/",
            "_etag": "\"140053b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "80fd3090995e458bf938b3dfc39e53dadf631f5717350057984932882952dba1",
            "conversationId": "80fd3090995e458bf938b3dfc39e53dadf631f5717350057984932882952dba1",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T14:43:43.783Z",
            "endTime": "2021-07-09T14:43:43.783Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhAAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhAAAAAAAAAAA==/",
            "_etag": "\"140054b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "ecc03665f6997ca82f734c7cc52a77357171a8b19eaa64232c6db559f2626a80",
            "conversationId": "ecc03665f6997ca82f734c7cc52a77357171a8b19eaa64232c6db559f2626a80",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T14:13:10.283Z",
            "endTime": "2021-07-09T14:13:10.283Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhBAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhBAAAAAAAAAA==/",
            "_etag": "\"140055b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "368e59833060eeda7aaa53a1de550caf98f9166d403a61f0b066e10155efc6ae",
            "conversationId": "368e59833060eeda7aaa53a1de550caf98f9166d403a61f0b066e10155efc6ae",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T14:14:02.346Z",
            "endTime": "2021-07-09T14:14:02.346Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhCAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhCAAAAAAAAAA==/",
            "_etag": "\"140056b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "bc513b14d13ca85d4e186fd15264144ecb0e1a68f52872e961f4256d1aa4e031",
            "conversationId": "bc513b14d13ca85d4e186fd15264144ecb0e1a68f52872e961f4256d1aa4e031",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T14:40:16.092Z",
            "endTime": "2021-07-09T14:40:16.092Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhDAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhDAAAAAAAAAA==/",
            "_etag": "\"140057b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "3b5dee4a61fe18722119c66c3c4a0ad357fac8897c2c32cbe86445fc99f8252d",
            "conversationId": "3b5dee4a61fe18722119c66c3c4a0ad357fac8897c2c32cbe86445fc99f8252d",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T14:40:27.272Z",
            "endTime": "2021-07-09T14:40:27.272Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhEAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhEAAAAAAAAAA==/",
            "_etag": "\"140058b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "469fba4f4b178f88a0dfc15d88fa960274fed0a4e0dfe84344f04855f45c2a5d",
            "conversationId": "469fba4f4b178f88a0dfc15d88fa960274fed0a4e0dfe84344f04855f45c2a5d",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T14:55:37.636Z",
            "endTime": "2021-07-09T14:55:45.196Z",
            "timeSpent": 8,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhFAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhFAAAAAAAAAA==/",
            "_etag": "\"140059b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "9fe8cd7c1a22e034b6b7e9ef1476c2bfd849df1de4750f2430ba24c241bc7d3d",
            "conversationId": "9fe8cd7c1a22e034b6b7e9ef1476c2bfd849df1de4750f2430ba24c241bc7d3d",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T14:56:09.007Z",
            "endTime": "2021-07-09T14:56:09.007Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhGAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhGAAAAAAAAAA==/",
            "_etag": "\"14005ab2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "9ba42d17a9dd20dcd3f38ce61d01812a8166e8a1e5eebf5109f41898de513967",
            "conversationId": "9ba42d17a9dd20dcd3f38ce61d01812a8166e8a1e5eebf5109f41898de513967",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "life-threatening situation"
            ],
            "riskFactors": [
                "Diagnosed chronic obstructive pulmonary disease",
                "Diagnosed diabetes",
                "High BMI",
                "Smoking cigarettes",
                "History of anaphylactic reactions",
                "History of chickenpox or VZV vaccination",
                "Last tetanus immunization over 5 years ago",
                "Woman"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-07-09T19:53:09.112Z",
            "endTime": "2021-07-09T19:54:09.973Z",
            "timeSpent": 61,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhHAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhHAAAAAAAAAA==/",
            "_etag": "\"14005bb2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "93603d8f6596c24e082088e9635a06858cff5d3aff5ab706eb1f74ac5846605c",
            "conversationId": "93603d8f6596c24e082088e9635a06858cff5d3aff5ab706eb1f74ac5846605c",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T19:51:45.099Z",
            "endTime": "2021-07-09T19:51:45.099Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhIAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhIAAAAAAAAAA==/",
            "_etag": "\"14005cb2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "97f48b4519c13dc40f0cbc22a2c18bbe3a5ec8f1c8b2a17ff282fd247871c925",
            "conversationId": "97f48b4519c13dc40f0cbc22a2c18bbe3a5ec8f1c8b2a17ff282fd247871c925",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T14:37:27.755Z",
            "endTime": "2021-07-09T14:40:00.68Z",
            "timeSpent": 153,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhJAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhJAAAAAAAAAA==/",
            "_etag": "\"14005db2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "164d319f953a926cccf8b9928a36f00dfffa3926374411b5c37e6a1f759219cf",
            "conversationId": "164d319f953a926cccf8b9928a36f00dfffa3926374411b5c37e6a1f759219cf",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Wrist pain"
            ],
            "riskFactors": [
                "Diagnosed chronic obstructive pulmonary disease",
                "Diagnosed diabetes",
                "High BMI",
                "Smoking cigarettes",
                "History of anaphylactic reactions",
                "History of chickenpox or VZV vaccination",
                "Last tetanus immunization over 5 years ago",
                "Woman"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-07-09T14:31:31.711Z",
            "endTime": "2021-07-09T14:35:01.423Z",
            "timeSpent": 210,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhKAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhKAAAAAAAAAA==/",
            "_etag": "\"14005eb2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "2e81e53211cdd58f60874a48e7e680a1f9dfa29f24f6e1b2059965cb5e4ca299",
            "conversationId": "2e81e53211cdd58f60874a48e7e680a1f9dfa29f24f6e1b2059965cb5e4ca299",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T14:18:14.928Z",
            "endTime": "2021-07-09T14:18:19.765Z",
            "timeSpent": 5,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhLAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhLAAAAAAAAAA==/",
            "_etag": "\"14005fb2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "5bf2dd26d3c791762b304f299c29d0261ec3b4d9600a24c4a37238a8bd158100",
            "conversationId": "5bf2dd26d3c791762b304f299c29d0261ec3b4d9600a24c4a37238a8bd158100",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T14:09:29.105Z",
            "endTime": "2021-07-09T14:09:29.105Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhMAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhMAAAAAAAAAA==/",
            "_etag": "\"140060b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "bfca203c2c3a25916fc9f6db03214b87833dedda940c123c6c8a74797a495252",
            "conversationId": "bfca203c2c3a25916fc9f6db03214b87833dedda940c123c6c8a74797a495252",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T14:12:32.144Z",
            "endTime": "2021-07-09T14:12:55.641Z",
            "timeSpent": 23,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhNAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhNAAAAAAAAAA==/",
            "_etag": "\"140061b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "79cacaaa3a8052303a6c3274235441ad430aea60fbd981f7edaaeb75505afee8",
            "conversationId": "79cacaaa3a8052303a6c3274235441ad430aea60fbd981f7edaaeb75505afee8",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-09T14:11:43.375Z",
            "endTime": "2021-07-09T14:12:03.827Z",
            "timeSpent": 20,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhOAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhOAAAAAAAAAA==/",
            "_etag": "\"140062b2-0000-0100-0000-60e90d370000\"",
            "_attachments": "attachments/",
            "_ts": 1625886007
        },
        {
            "id": "8d45a655c66fc4a7ffca28391dcf6869faa88bc269e85af1168459b3b4f28eb4",
            "conversationId": "8d45a655c66fc4a7ffca28391dcf6869faa88bc269e85af1168459b3b4f28eb4",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-12T19:12:09.881Z",
            "endTime": "2021-07-12T19:12:09.881Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhPAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhPAAAAAAAAAA==/",
            "_etag": "\"1d005079-0000-0100-0000-60ed01ba0000\"",
            "_attachments": "attachments/",
            "_ts": 1626145210
        },
        {
            "id": "e86fe12a07e47db14770467a66343901d8eab07d629beb3db1dea116d6bfbd4a",
            "conversationId": "e86fe12a07e47db14770467a66343901d8eab07d629beb3db1dea116d6bfbd4a",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-12T16:27:49.869Z",
            "endTime": "2021-07-12T16:33:02.411Z",
            "timeSpent": 313,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhQAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhQAAAAAAAAAA==/",
            "_etag": "\"1d005179-0000-0100-0000-60ed01ba0000\"",
            "_attachments": "attachments/",
            "_ts": 1626145210
        },
        {
            "id": "b395b65cd22338798b1c40f13939bd2f1cbc14a75b9e3be19a1400e2450dfc9c",
            "conversationId": "b395b65cd22338798b1c40f13939bd2f1cbc14a75b9e3be19a1400e2450dfc9c",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-12T15:23:29.719Z",
            "endTime": "2021-07-12T15:23:29.719Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhRAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhRAAAAAAAAAA==/",
            "_etag": "\"1d005279-0000-0100-0000-60ed01ba0000\"",
            "_attachments": "attachments/",
            "_ts": 1626145210
        },
        {
            "id": "d4cf8aabb6de82f9e4c687db1e9e79dd09bad05e1fce367b6a95c21e0c55ab6f",
            "conversationId": "d4cf8aabb6de82f9e4c687db1e9e79dd09bad05e1fce367b6a95c21e0c55ab6f",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Crampy stomach pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-12T13:17:59.398Z",
            "endTime": "2021-07-12T13:20:50.424Z",
            "timeSpent": 171,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhSAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhSAAAAAAAAAA==/",
            "_etag": "\"1d005379-0000-0100-0000-60ed01ba0000\"",
            "_attachments": "attachments/",
            "_ts": 1626145210
        },
        {
            "id": "01c117b21725bcc9fb0bc87c9a37d21568043afc21149ab9b85fadf024edbc22",
            "conversationId": "01c117b21725bcc9fb0bc87c9a37d21568043afc21149ab9b85fadf024edbc22",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "life-threatening situation"
            ],
            "riskFactors": [
                "Diagnosed chronic obstructive pulmonary disease",
                "Diagnosed diabetes",
                "High BMI",
                "Smoking cigarettes",
                "History of anaphylactic reactions",
                "History of chickenpox or VZV vaccination",
                "Last tetanus immunization over 5 years ago",
                "Woman"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-07-12T15:22:58.869Z",
            "endTime": "2021-07-12T15:23:22.394Z",
            "timeSpent": 24,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhTAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhTAAAAAAAAAA==/",
            "_etag": "\"1d005479-0000-0100-0000-60ed01ba0000\"",
            "_attachments": "attachments/",
            "_ts": 1626145210
        },
        {
            "id": "a27e645ee59517b8bd2c7e23de91749712c7bd813baa3f49b06ad15acafad343",
            "conversationId": "a27e645ee59517b8bd2c7e23de91749712c7bd813baa3f49b06ad15acafad343",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-12T15:21:56.285Z",
            "endTime": "2021-07-12T15:21:56.285Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhUAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhUAAAAAAAAAA==/",
            "_etag": "\"1d005579-0000-0100-0000-60ed01ba0000\"",
            "_attachments": "attachments/",
            "_ts": 1626145210
        },
        {
            "id": "e6313acc10f11a9514fc94daa9a763b365f986e442069bb3f559d2fef6aa287e",
            "conversationId": "e6313acc10f11a9514fc94daa9a763b365f986e442069bb3f559d2fef6aa287e",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Crampy stomach pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-12T07:56:24.575Z",
            "endTime": "2021-07-12T07:57:11.842Z",
            "timeSpent": 47,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhVAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhVAAAAAAAAAA==/",
            "_etag": "\"1d005679-0000-0100-0000-60ed01ba0000\"",
            "_attachments": "attachments/",
            "_ts": 1626145210
        },
        {
            "id": "6f0539d4984ff4bc7089e70b2cb080a8232e47e40f23d7dab5bdd6b2d3582c5b",
            "conversationId": "6f0539d4984ff4bc7089e70b2cb080a8232e47e40f23d7dab5bdd6b2d3582c5b",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "life-threatening situation"
            ],
            "riskFactors": [
                "Diagnosed chronic obstructive pulmonary disease",
                "Diagnosed diabetes",
                "High BMI",
                "Smoking cigarettes",
                "History of anaphylactic reactions",
                "History of chickenpox or VZV vaccination",
                "Last tetanus immunization over 5 years ago",
                "Woman"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-07-12T13:15:02.623Z",
            "endTime": "2021-07-12T13:15:23.953Z",
            "timeSpent": 21,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhWAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhWAAAAAAAAAA==/",
            "_etag": "\"1d005779-0000-0100-0000-60ed01ba0000\"",
            "_attachments": "attachments/",
            "_ts": 1626145210
        },
        {
            "id": "572a0f3edb9e20fd56c20aa0d45c9e52dac44c1a683de6dfae23804313a2129a",
            "conversationId": "572a0f3edb9e20fd56c20aa0d45c9e52dac44c1a683de6dfae23804313a2129a",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-13T10:51:59.678Z",
            "endTime": "2021-07-13T10:57:53.311Z",
            "timeSpent": 354,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhXAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhXAAAAAAAAAA==/",
            "_etag": "\"01000870-0000-0100-0000-60ee53340000\"",
            "_attachments": "attachments/",
            "_ts": 1626231604
        },
        {
            "id": "1e2b3fdb0d81e843c5b46a65f6c675f2553c72e7a64d8b37eaf607ffec13365b",
            "conversationId": "1e2b3fdb0d81e843c5b46a65f6c675f2553c72e7a64d8b37eaf607ffec13365b",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Dizzy"
            ],
            "riskFactors": [
                "High BMI",
                "Smoking cigarettes",
                "History of chickenpox or VZV vaccination",
                "Diagnosed chronic obstructive pulmonary disease",
                "Diagnosed diabetes",
                "History of anaphylactic reactions",
                "Last tetanus immunization over 5 years ago",
                "Woman"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-07-16T17:41:04.21Z",
            "endTime": "2021-07-16T17:42:19.184Z",
            "timeSpent": 75,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhYAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhYAAAAAAAAAA==/",
            "_etag": "\"030094ee-0000-0100-0000-60f247b40000\"",
            "_attachments": "attachments/",
            "_ts": 1626490804
        },
        {
            "id": "a79fb5c5b65bee50b0e639c04e235d716067a18e0c8f6d14d8fa3977efd3b574",
            "conversationId": "a79fb5c5b65bee50b0e639c04e235d716067a18e0c8f6d14d8fa3977efd3b574",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-19T15:17:12.241Z",
            "endTime": "2021-07-19T15:17:12.241Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhZAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhZAAAAAAAAAA==/",
            "_etag": "\"2e00aeaa-0000-0100-0000-60f63c390000\"",
            "_attachments": "attachments/",
            "_ts": 1626750009
        },
        {
            "id": "8d9daddba406acfd739c7eea5ae6c62cc3084ad1fe1c03289a010f67edeebc20",
            "conversationId": "8d9daddba406acfd739c7eea5ae6c62cc3084ad1fe1c03289a010f67edeebc20",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-19T15:19:30.824Z",
            "endTime": "2021-07-19T15:19:30.824Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhaAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhaAAAAAAAAAA==/",
            "_etag": "\"2e00afaa-0000-0100-0000-60f63c390000\"",
            "_attachments": "attachments/",
            "_ts": 1626750009
        },
        {
            "id": "09f672adb044b8371f02ff5a6a8d129f82625b10ac5de62fba336a4c608a14c6",
            "conversationId": "09f672adb044b8371f02ff5a6a8d129f82625b10ac5de62fba336a4c608a14c6",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-19T15:19:40.844Z",
            "endTime": "2021-07-19T15:19:40.844Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhbAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhbAAAAAAAAAA==/",
            "_etag": "\"2e00b0aa-0000-0100-0000-60f63c390000\"",
            "_attachments": "attachments/",
            "_ts": 1626750009
        },
        {
            "id": "4b54a47d02847851f4f750370cc4fec057a62c935f5a8f5ab17c4d4be0a595de",
            "conversationId": "4b54a47d02847851f4f750370cc4fec057a62c935f5a8f5ab17c4d4be0a595de",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sinus pain or pressure",
                "Stuffy Nose"
            ],
            "riskFactors": [
                "Diagnosed chronic obstructive pulmonary disease",
                "Diagnosed diabetes",
                "High BMI",
                "Smoking cigarettes",
                "History of anaphylactic reactions",
                "History of chickenpox or VZV vaccination",
                "Last tetanus immunization over 5 years ago",
                "Woman"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-07-19T16:34:30.784Z",
            "endTime": "2021-07-19T16:39:36.818Z",
            "timeSpent": 306,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhcAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhcAAAAAAAAAA==/",
            "_etag": "\"2e00b1aa-0000-0100-0000-60f63c390000\"",
            "_attachments": "attachments/",
            "_ts": 1626750009
        },
        {
            "id": "58728f286289bc52288747f21a162475247a9864f5f78568c3ddb3a7b0172f5a",
            "conversationId": "58728f286289bc52288747f21a162475247a9864f5f78568c3ddb3a7b0172f5a",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-19T14:20:28.205Z",
            "endTime": "2021-07-19T14:20:28.205Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhdAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhdAAAAAAAAAA==/",
            "_etag": "\"2e00b2aa-0000-0100-0000-60f63c390000\"",
            "_attachments": "attachments/",
            "_ts": 1626750009
        },
        {
            "id": "018ae970bd993aa8440a7c49cf7afcdd785d11cce24f482337c2d4d007d8ef10",
            "conversationId": "018ae970bd993aa8440a7c49cf7afcdd785d11cce24f482337c2d4d007d8ef10",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-19T12:36:02.936Z",
            "endTime": "2021-07-19T12:48:30.144Z",
            "timeSpent": 747,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIheAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIheAAAAAAAAAA==/",
            "_etag": "\"2e00b3aa-0000-0100-0000-60f63c390000\"",
            "_attachments": "attachments/",
            "_ts": 1626750009
        },
        {
            "id": "efa80ad685ede1164c5488191187c8ccea7e30912fb3d4fd9dda6938521d71ca",
            "conversationId": "efa80ad685ede1164c5488191187c8ccea7e30912fb3d4fd9dda6938521d71ca",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Neck pain"
            ],
            "riskFactors": [
                "Diagnosed chronic obstructive pulmonary disease",
                "Diagnosed diabetes",
                "High BMI",
                "Smoking cigarettes",
                "History of anaphylactic reactions",
                "History of chickenpox or VZV vaccination",
                "Last tetanus immunization over 5 years ago"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-07-21T01:21:18.756Z",
            "endTime": "2021-07-21T01:23:13.088Z",
            "timeSpent": 114,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhfAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhfAAAAAAAAAA==/",
            "_etag": "\"400021ff-0000-0100-0000-60f78dbc0000\"",
            "_attachments": "attachments/",
            "_ts": 1626836412
        },
        {
            "id": "c48c1cb7df3f0ee34366695bdd85939606ead44eebe31877ab46424d7c7224b0",
            "conversationId": "c48c1cb7df3f0ee34366695bdd85939606ead44eebe31877ab46424d7c7224b0",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Neck pain"
            ],
            "riskFactors": [
                "Diagnosed chronic obstructive pulmonary disease",
                "Diagnosed diabetes",
                "High BMI",
                "Smoking cigarettes",
                "History of anaphylactic reactions",
                "History of chickenpox or VZV vaccination",
                "Last tetanus immunization over 5 years ago"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-07-21T01:10:53.812Z",
            "endTime": "2021-07-21T01:13:41.735Z",
            "timeSpent": 168,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhgAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhgAAAAAAAAAA==/",
            "_etag": "\"400022ff-0000-0100-0000-60f78dbc0000\"",
            "_attachments": "attachments/",
            "_ts": 1626836412
        },
        {
            "id": "99eed2ae45d903fb4dbcced3b20a3aa660d01c67c946e834eafa1b19090c9817",
            "conversationId": "99eed2ae45d903fb4dbcced3b20a3aa660d01c67c946e834eafa1b19090c9817",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Stuffy Nose",
                "Sinus pain or pressure"
            ],
            "riskFactors": [
                "Diagnosed chronic obstructive pulmonary disease",
                "Diagnosed diabetes",
                "High BMI",
                "Smoking cigarettes",
                "History of anaphylactic reactions",
                "History of chickenpox or VZV vaccination",
                "Last tetanus immunization over 5 years ago"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-07-20T15:22:58.856Z",
            "endTime": "2021-07-20T15:26:26.36Z",
            "timeSpent": 208,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhhAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhhAAAAAAAAAA==/",
            "_etag": "\"400023ff-0000-0100-0000-60f78dbc0000\"",
            "_attachments": "attachments/",
            "_ts": 1626836412
        },
        {
            "id": "ea32fe883c25a161531d6c4843811b1bb05822f653cac5bd42b2400708189041",
            "conversationId": "ea32fe883c25a161531d6c4843811b1bb05822f653cac5bd42b2400708189041",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-23T18:59:08.246Z",
            "endTime": "2021-07-23T18:59:08.246Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhiAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhiAAAAAAAAAA==/",
            "_etag": "\"49007c1c-0000-0100-0000-60fb82440000\"",
            "_attachments": "attachments/",
            "_ts": 1627095620
        },
        {
            "id": "2e23d3825e326edabdd41281df97db68f24c05cc649df24d1d80e7d0ff6b1284",
            "conversationId": "2e23d3825e326edabdd41281df97db68f24c05cc649df24d1d80e7d0ff6b1284",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-23T18:59:39.243Z",
            "endTime": "2021-07-23T18:59:39.243Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhjAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhjAAAAAAAAAA==/",
            "_etag": "\"49007d1c-0000-0100-0000-60fb82440000\"",
            "_attachments": "attachments/",
            "_ts": 1627095620
        },
        {
            "id": "9c3662541595fc368a021d3616740544f9d24fc58a762e657df19e212e9eeb40",
            "conversationId": "9c3662541595fc368a021d3616740544f9d24fc58a762e657df19e212e9eeb40",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-26T13:55:02.223Z",
            "endTime": "2021-07-26T13:55:02.223Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhkAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhkAAAAAAAAAA==/",
            "_etag": "\"95005013-0000-0100-0000-60ff77110000\"",
            "_attachments": "attachments/",
            "_ts": 1627354897
        },
        {
            "id": "be6fc5f98ba35ba55bddfef2d7e9277eba8d1b1a750f0a1b58f9acce7a74a1f2",
            "conversationId": "be6fc5f98ba35ba55bddfef2d7e9277eba8d1b1a750f0a1b58f9acce7a74a1f2",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-26T15:23:23.201Z",
            "endTime": "2021-07-26T15:23:23.201Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhlAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhlAAAAAAAAAA==/",
            "_etag": "\"95005113-0000-0100-0000-60ff77110000\"",
            "_attachments": "attachments/",
            "_ts": 1627354897
        },
        {
            "id": "57608440cf8fb5fa6d79122a9f0eb6934257d01cebc93edd84c671bfbf4b2f02",
            "conversationId": "57608440cf8fb5fa6d79122a9f0eb6934257d01cebc93edd84c671bfbf4b2f02",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Stuffed nose",
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-29T00:44:15.975Z",
            "endTime": "2021-07-29T00:50:21.402Z",
            "timeSpent": 365,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhmAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhmAAAAAAAAAA==/",
            "_etag": "\"0d005acb-0000-0100-0000-610219b30000\"",
            "_attachments": "attachments/",
            "_ts": 1627527603
        },
        {
            "id": "d7685a3ca5f3503e4e1af76bd2c37ac894c2bdbfa9856c7cb4b67ba8fee3ff52",
            "conversationId": "d7685a3ca5f3503e4e1af76bd2c37ac894c2bdbfa9856c7cb4b67ba8fee3ff52",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-29T19:11:11.542Z",
            "endTime": "2021-07-29T19:11:47.349Z",
            "timeSpent": 36,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhnAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhnAAAAAAAAAA==/",
            "_etag": "\"1a00596b-0000-0100-0000-61036b3a0000\"",
            "_attachments": "attachments/",
            "_ts": 1627614010
        },
        {
            "id": "0601c09dd5c0a16387af34fb79a28a04bfb951442ce121d9af5115b4a45aa2da",
            "conversationId": "0601c09dd5c0a16387af34fb79a28a04bfb951442ce121d9af5115b4a45aa2da",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 88,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [
                "Hypertension",
                "Diagnosed diabetes",
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation",
            "startTime": "2021-07-29T20:44:54.531Z",
            "endTime": "2021-07-29T20:46:56.614Z",
            "timeSpent": 122,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhoAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhoAAAAAAAAAA==/",
            "_etag": "\"1a005a6b-0000-0100-0000-61036b3a0000\"",
            "_attachments": "attachments/",
            "_ts": 1627614010
        },
        {
            "id": "951a80ba805322cdb5b87bfff9b495fab11dd74a4af96f267730f0ce5344442d",
            "conversationId": "951a80ba805322cdb5b87bfff9b495fab11dd74a4af96f267730f0ce5344442d",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-29T18:34:51.823Z",
            "endTime": "2021-07-29T18:35:26.678Z",
            "timeSpent": 35,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhpAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhpAAAAAAAAAA==/",
            "_etag": "\"1a005b6b-0000-0100-0000-61036b3a0000\"",
            "_attachments": "attachments/",
            "_ts": 1627614010
        },
        {
            "id": "1ed547b48a99f727a2be498120e330166d2c6fa6752174bf21350a5cf436adad",
            "conversationId": "1ed547b48a99f727a2be498120e330166d2c6fa6752174bf21350a5cf436adad",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-30T20:15:33.932Z",
            "endTime": "2021-07-30T20:16:09.519Z",
            "timeSpent": 36,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhqAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhqAAAAAAAAAA==/",
            "_etag": "\"2600d635-0000-0100-0000-6104bcb60000\"",
            "_attachments": "attachments/",
            "_ts": 1627700406
        },
        {
            "id": "9f9ab84c705b29cb4cdd17b1b339163c99596f0997d28c718edd94b3de99d27c",
            "conversationId": "9f9ab84c705b29cb4cdd17b1b339163c99596f0997d28c718edd94b3de99d27c",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Heartburn"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-30T20:16:51.601Z",
            "endTime": "2021-07-30T20:18:25.775Z",
            "timeSpent": 94,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhrAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhrAAAAAAAAAA==/",
            "_etag": "\"2600d735-0000-0100-0000-6104bcb60000\"",
            "_attachments": "attachments/",
            "_ts": 1627700406
        },
        {
            "id": "f3511d530fc0c77a8617c94ea682fa1ad430a354c5db2a07dc58ffe4ad3a6c29",
            "conversationId": "f3511d530fc0c77a8617c94ea682fa1ad430a354c5db2a07dc58ffe4ad3a6c29",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 88,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [
                "High cholesterol",
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-07-30T17:04:07.345Z",
            "endTime": "2021-07-30T17:06:10.371Z",
            "timeSpent": 123,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhsAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhsAAAAAAAAAA==/",
            "_etag": "\"2600d835-0000-0100-0000-6104bcb60000\"",
            "_attachments": "attachments/",
            "_ts": 1627700406
        },
        {
            "id": "ff8b0ef633d063cf759fbe2ae772fc155cd8f778c990d5f948e220c02b888ac8",
            "conversationId": "ff8b0ef633d063cf759fbe2ae772fc155cd8f778c990d5f948e220c02b888ac8",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-30T16:04:57.877Z",
            "endTime": "2021-07-30T16:04:57.877Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhtAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhtAAAAAAAAAA==/",
            "_etag": "\"2600d935-0000-0100-0000-6104bcb60000\"",
            "_attachments": "attachments/",
            "_ts": 1627700406
        },
        {
            "id": "655526a4ce4ebee2cfc9a083b6541ae0dedb74d3ec8a199be8eea5b355ca08ef",
            "conversationId": "655526a4ce4ebee2cfc9a083b6541ae0dedb74d3ec8a199be8eea5b355ca08ef",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-30T17:30:05.726Z",
            "endTime": "2021-07-30T17:30:05.726Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhuAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhuAAAAAAAAAA==/",
            "_etag": "\"2600da35-0000-0100-0000-6104bcb60000\"",
            "_attachments": "attachments/",
            "_ts": 1627700406
        },
        {
            "id": "9ea629e7d0b666461a1b4f0cba3acd4fd8bb47114f61872da455838c76396238",
            "conversationId": "9ea629e7d0b666461a1b4f0cba3acd4fd8bb47114f61872da455838c76396238",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-30T20:14:13.339Z",
            "endTime": "2021-07-30T20:14:13.339Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhvAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhvAAAAAAAAAA==/",
            "_etag": "\"2600db35-0000-0100-0000-6104bcb60000\"",
            "_attachments": "attachments/",
            "_ts": 1627700406
        },
        {
            "id": "0342e92d3d01c2d770f6dcded3c1f0516da48409f235a8c60a52b4aad13d3403",
            "conversationId": "0342e92d3d01c2d770f6dcded3c1f0516da48409f235a8c60a52b4aad13d3403",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-30T20:14:57.214Z",
            "endTime": "2021-07-30T20:14:57.214Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhwAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhwAAAAAAAAAA==/",
            "_etag": "\"2600dc35-0000-0100-0000-6104bcb60000\"",
            "_attachments": "attachments/",
            "_ts": 1627700406
        },
        {
            "id": "faa2d9286de538f3d67a487ad5b34e2398f91635b893088dd0ebbc55a996a5cd",
            "conversationId": "faa2d9286de538f3d67a487ad5b34e2398f91635b893088dd0ebbc55a996a5cd",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-07-30T16:51:47.407Z",
            "endTime": "2021-07-30T16:53:00.98Z",
            "timeSpent": 74,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhxAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhxAAAAAAAAAA==/",
            "_etag": "\"2600dd35-0000-0100-0000-6104bcb60000\"",
            "_attachments": "attachments/",
            "_ts": 1627700406
        },
        {
            "id": "021f9ed085a22bfb6e03ec14bf1a522e99671833beb1bbbfce7102c5bd2f552e",
            "conversationId": "021f9ed085a22bfb6e03ec14bf1a522e99671833beb1bbbfce7102c5bd2f552e",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Runny nose"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-30T16:10:52.299Z",
            "endTime": "2021-07-30T16:15:08.502Z",
            "timeSpent": 256,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhyAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhyAAAAAAAAAA==/",
            "_etag": "\"2600de35-0000-0100-0000-6104bcb60000\"",
            "_attachments": "attachments/",
            "_ts": 1627700406
        },
        {
            "id": "a2ec5c6150996a9ad9c1b84f40f9bd3cbff53eae8fa5bb1396cb795fc0a70d71",
            "conversationId": "a2ec5c6150996a9ad9c1b84f40f9bd3cbff53eae8fa5bb1396cb795fc0a70d71",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-07-30T16:06:49.847Z",
            "endTime": "2021-07-30T16:09:02.867Z",
            "timeSpent": 133,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhzAAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhzAAAAAAAAAA==/",
            "_etag": "\"2600df35-0000-0100-0000-6104bcb60000\"",
            "_attachments": "attachments/",
            "_ts": 1627700406
        },
        {
            "id": "fbe8c18c23961539c78c308e9736b19e2bcd6d941cf6766b2665dfc725bb95d3",
            "conversationId": "fbe8c18c23961539c78c308e9736b19e2bcd6d941cf6766b2665dfc725bb95d3",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-07-30T16:05:29.664Z",
            "endTime": "2021-07-30T16:05:29.664Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh0AAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh0AAAAAAAAAA==/",
            "_etag": "\"2600e035-0000-0100-0000-6104bcb60000\"",
            "_attachments": "attachments/",
            "_ts": 1627700406
        },
        {
            "id": "a4fccae13642eb5b434aba5e5b080cb3647f07b22242f815bc66c7502ebf2796",
            "conversationId": "a4fccae13642eb5b434aba5e5b080cb3647f07b22242f815bc66c7502ebf2796",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "life-threatening situation"
            ],
            "riskFactors": [
                "Atherosclerosis",
                "Coronary disease",
                "Diagnosed chronic obstructive pulmonary disease",
                "Diagnosed diabetes",
                "High BMI",
                "Smoking cigarettes",
                "History of anaphylactic reactions",
                "Woman"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-07-30T16:09:49.428Z",
            "endTime": "2021-07-30T16:10:16.863Z",
            "timeSpent": 27,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh1AAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh1AAAAAAAAAA==/",
            "_etag": "\"2600e135-0000-0100-0000-6104bcb60000\"",
            "_attachments": "attachments/",
            "_ts": 1627700406
        },
        {
            "id": "fa76a58eadbac49ba45d20bad1da839e9c7c766c88f59a8565d9cd4718d001c0",
            "conversationId": "fa76a58eadbac49ba45d20bad1da839e9c7c766c88f59a8565d9cd4718d001c0",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 88,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain",
                "Headache",
                "Hair loss"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-08-01T23:27:01.922Z",
            "endTime": "2021-08-01T23:28:28.538Z",
            "timeSpent": 87,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh2AAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh2AAAAAAAAAA==/",
            "_etag": "\"34003e6c-0000-0100-0000-61075fb20000\"",
            "_attachments": "attachments/",
            "_ts": 1627873202
        },
        {
            "id": "9ce23c6602ac500260616282de7a59f555d0635344c2958f2673dd395c77ee45",
            "conversationId": "9ce23c6602ac500260616282de7a59f555d0635344c2958f2673dd395c77ee45",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-08-02T01:29:21.351Z",
            "endTime": "2021-08-02T01:29:21.351Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh3AAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh3AAAAAAAAAA==/",
            "_etag": "\"34003f6c-0000-0100-0000-61075fb20000\"",
            "_attachments": "attachments/",
            "_ts": 1627873202
        },
        {
            "id": "008269ca837c932e8f4cc2bc25e1a883460c98b31ecb52725183462e1091610c",
            "conversationId": "008269ca837c932e8f4cc2bc25e1a883460c98b31ecb52725183462e1091610c",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-08-02T00:33:32.061Z",
            "endTime": "2021-08-02T00:33:32.061Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh4AAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh4AAAAAAAAAA==/",
            "_etag": "\"3400406c-0000-0100-0000-61075fb20000\"",
            "_attachments": "attachments/",
            "_ts": 1627873202
        },
    

    
        {
            "id": "862f29c741fd47bc24f4a733f8cc311d0615d00977701299103697335bd931fb",
            "conversationId": "862f29c741fd47bc24f4a733f8cc311d0615d00977701299103697335bd931fb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-13T20:45:48.52Z",
            "endTime": "2021-09-13T20:49:44.915Z",
            "timeSpent": 236,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhAAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhAAQAAAAAAAA==/",
            "_etag": "\"14003b62-0000-0100-0000-613fbbd50000\"",
            "_attachments": "attachments/",
            "_ts": 1631566805
        },
        {
            "id": "4897e5bc616dc3ebcd83a4d8339146e2e361ae1f855e778732b77efc463688e0",
            "conversationId": "4897e5bc616dc3ebcd83a4d8339146e2e361ae1f855e778732b77efc463688e0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-13T20:56:28.246Z",
            "endTime": "2021-09-13T20:56:28.246Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhBAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhBAQAAAAAAAA==/",
            "_etag": "\"14007597-0000-0100-0000-613fc9e20000\"",
            "_attachments": "attachments/",
            "_ts": 1631570402
        },
        {
            "id": "7dfad0538cdff86f115d48b4f92fe1e688110ec52f0a2268eab4692d7abdb163",
            "conversationId": "7dfad0538cdff86f115d48b4f92fe1e688110ec52f0a2268eab4692d7abdb163",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 88,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-13T21:00:16.853Z",
            "endTime": "2021-09-13T21:00:24.438Z",
            "timeSpent": 8,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhCAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhCAQAAAAAAAA==/",
            "_etag": "\"14007297-0000-0100-0000-613fc9e20000\"",
            "_attachments": "attachments/",
            "_ts": 1631570402
        },
        {
            "id": "20ed429bdc41868b09ff1cc4ac035594ee61fd78fb93e11fd3685e6147b40195",
            "conversationId": "20ed429bdc41868b09ff1cc4ac035594ee61fd78fb93e11fd3685e6147b40195",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 88,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-13T21:04:44.896Z",
            "endTime": "2021-09-13T21:04:55.238Z",
            "timeSpent": 10,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhDAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhDAQAAAAAAAA==/",
            "_etag": "\"14007397-0000-0100-0000-613fc9e20000\"",
            "_attachments": "attachments/",
            "_ts": 1631570402
        },
        {
            "id": "1985025eaab876d9a9af067d1784ed97a743680dde831001edbec1861e13dd1a",
            "conversationId": "1985025eaab876d9a9af067d1784ed97a743680dde831001edbec1861e13dd1a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-13T21:05:30.231Z",
            "endTime": "2021-09-13T21:05:39.235Z",
            "timeSpent": 9,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhEAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhEAQAAAAAAAA==/",
            "_etag": "\"14007497-0000-0100-0000-613fc9e20000\"",
            "_attachments": "attachments/",
            "_ts": 1631570402
        },
        {
            "id": "92446a2f512f9d0548ef70180935ca646c62eb020dbebca34a257ccb6bf74814",
            "conversationId": "92446a2f512f9d0548ef70180935ca646c62eb020dbebca34a257ccb6bf74814",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-14T03:21:34.873Z",
            "endTime": "2021-09-14T03:29:56.121Z",
            "timeSpent": 501,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhFAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhFAQAAAAAAAA==/",
            "_etag": "\"1500f225-0000-0100-0000-61401e420000\"",
            "_attachments": "attachments/",
            "_ts": 1631592002
        },
        {
            "id": "feec032ab082c4096a10503ff1ddb133a3b2cdbb3bfe33066a3676492aaaeb86",
            "conversationId": "feec032ab082c4096a10503ff1ddb133a3b2cdbb3bfe33066a3676492aaaeb86",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-14T03:32:41.646Z",
            "endTime": "2021-09-14T03:42:43.854Z",
            "timeSpent": 602,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhGAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhGAQAAAAAAAA==/",
            "_etag": "\"1500f325-0000-0100-0000-61401e420000\"",
            "_attachments": "attachments/",
            "_ts": 1631592002
        },
        {
            "id": "a5674cca5ba8fe029557291d836fe6bfc15a2c07ee15f09e54e076fd71d34ca1",
            "conversationId": "a5674cca5ba8fe029557291d836fe6bfc15a2c07ee15f09e54e076fd71d34ca1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-14T03:18:18.915Z",
            "endTime": "2021-09-14T03:20:25.298Z",
            "timeSpent": 126,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhHAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhHAQAAAAAAAA==/",
            "_etag": "\"1500f425-0000-0100-0000-61401e420000\"",
            "_attachments": "attachments/",
            "_ts": 1631592002
        },
        {
            "id": "557613f44331b96f9285346da6203d25f7524e2c08c0d2d37c4bbe5a0aa3ce45",
            "conversationId": "557613f44331b96f9285346da6203d25f7524e2c08c0d2d37c4bbe5a0aa3ce45",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-14T03:53:31.181Z",
            "endTime": "2021-09-14T03:54:46.503Z",
            "timeSpent": 75,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhIAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhIAQAAAAAAAA==/",
            "_etag": "\"1500f525-0000-0100-0000-61401e420000\"",
            "_attachments": "attachments/",
            "_ts": 1631592002
        },
        {
            "id": "8c99812ce250255f88aaeb963b2847dcbacc3d5c25588a207ced43636623e084",
            "conversationId": "8c99812ce250255f88aaeb963b2847dcbacc3d5c25588a207ced43636623e084",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-14T03:49:24.283Z",
            "endTime": "2021-09-14T03:51:00.433Z",
            "timeSpent": 96,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhJAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhJAQAAAAAAAA==/",
            "_etag": "\"1500f625-0000-0100-0000-61401e420000\"",
            "_attachments": "attachments/",
            "_ts": 1631592002
        },
        {
            "id": "2c2e74b87c4edbc5a85c29c0109e644b58e49e70207d4011341ab180edbcad62",
            "conversationId": "2c2e74b87c4edbc5a85c29c0109e644b58e49e70207d4011341ab180edbcad62",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Feeling sick"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-14T04:02:56.436Z",
            "endTime": "2021-09-14T04:05:21.796Z",
            "timeSpent": 145,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhKAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhKAQAAAAAAAA==/",
            "_etag": "\"15009732-0000-0100-0000-61402c520000\"",
            "_attachments": "attachments/",
            "_ts": 1631595602
        },
        {
            "id": "028c9db0b21ea29caf326ca4d76a3bb9acfdb15d14565e6cdca77f79609d5bcb",
            "conversationId": "028c9db0b21ea29caf326ca4d76a3bb9acfdb15d14565e6cdca77f79609d5bcb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-14T09:37:08.793Z",
            "endTime": "2021-09-14T09:39:03.964Z",
            "timeSpent": 115,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhLAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhLAQAAAAAAAA==/",
            "_etag": "\"160069e8-0000-0100-0000-614072a50000\"",
            "_attachments": "attachments/",
            "_ts": 1631613605
        },
        {
            "id": "b50f7ea18fa52060812a10dfa7bd76084a11ed0449c77c0581ee10f4c37ddcd9",
            "conversationId": "b50f7ea18fa52060812a10dfa7bd76084a11ed0449c77c0581ee10f4c37ddcd9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-14T11:46:37.736Z",
            "endTime": "2021-09-14T11:47:48.132Z",
            "timeSpent": 70,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhMAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhMAQAAAAAAAA==/",
            "_etag": "\"17000631-0000-0100-0000-61408ec20000\"",
            "_attachments": "attachments/",
            "_ts": 1631620802
        },
        {
            "id": "ac6baa38f037717d0ce13ec86e8ef3830832bc362d61d10d4b928dc9f82642b3",
            "conversationId": "ac6baa38f037717d0ce13ec86e8ef3830832bc362d61d10d4b928dc9f82642b3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-14T11:39:53.941Z",
            "endTime": "2021-09-14T11:39:53.941Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhNAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhNAQAAAAAAAA==/",
            "_etag": "\"17000731-0000-0100-0000-61408ec20000\"",
            "_attachments": "attachments/",
            "_ts": 1631620802
        },
        {
            "id": "e7a38815f6be5470eba41eb2e252777bda27a958e0a9f57c75da44dca8ca8c90",
            "conversationId": "e7a38815f6be5470eba41eb2e252777bda27a958e0a9f57c75da44dca8ca8c90",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-14T11:54:09.69Z",
            "endTime": "2021-09-14T11:54:09.69Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhOAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhOAQAAAAAAAA==/",
            "_etag": "\"17000831-0000-0100-0000-61408ec20000\"",
            "_attachments": "attachments/",
            "_ts": 1631620802
        },
        {
            "id": "b7f281d8b06a3e01d8d6a587172fa1556986ba3acfa7615678200c60f2c74219",
            "conversationId": "b7f281d8b06a3e01d8d6a587172fa1556986ba3acfa7615678200c60f2c74219",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-14T11:41:24.118Z",
            "endTime": "2021-09-14T11:41:24.118Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhPAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhPAQAAAAAAAA==/",
            "_etag": "\"17000931-0000-0100-0000-61408ec20000\"",
            "_attachments": "attachments/",
            "_ts": 1631620802
        },
        {
            "id": "6c902d34dd58f29abc1c331253b0c6b635cdc8ef9814cfc69020da9e321e7af0",
            "conversationId": "6c902d34dd58f29abc1c331253b0c6b635cdc8ef9814cfc69020da9e321e7af0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-14T11:41:55.426Z",
            "endTime": "2021-09-14T11:41:55.426Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhQAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhQAQAAAAAAAA==/",
            "_etag": "\"17000a31-0000-0100-0000-61408ec20000\"",
            "_attachments": "attachments/",
            "_ts": 1631620802
        },
        {
            "id": "56a870e53a6c36353ed1854d645e0167fd910e4dd968849321c141268404bd3a",
            "conversationId": "56a870e53a6c36353ed1854d645e0167fd910e4dd968849321c141268404bd3a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-14T11:45:44.427Z",
            "endTime": "2021-09-14T11:45:44.427Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhRAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhRAQAAAAAAAA==/",
            "_etag": "\"17000b31-0000-0100-0000-61408ec20000\"",
            "_attachments": "attachments/",
            "_ts": 1631620802
        },
        {
            "id": "4b168542451a5909d19c7f2714dd88561d5bb7d2b0ee01c2c24232e78c9656e7",
            "conversationId": "4b168542451a5909d19c7f2714dd88561d5bb7d2b0ee01c2c24232e78c9656e7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe joint pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-14T12:37:44.468Z",
            "endTime": "2021-09-14T12:43:16.989Z",
            "timeSpent": 333,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhSAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhSAQAAAAAAAA==/",
            "_etag": "\"1700d38a-0000-0100-0000-61409cd20000\"",
            "_attachments": "attachments/",
            "_ts": 1631624402
        },
        {
            "id": "b5027595da927de73ca1d81f9cc150b142a51f774d37fe0fc041f22f4e3d5302",
            "conversationId": "b5027595da927de73ca1d81f9cc150b142a51f774d37fe0fc041f22f4e3d5302",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-14T12:48:35.97Z",
            "endTime": "2021-09-14T12:56:29.837Z",
            "timeSpent": 474,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhTAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhTAQAAAAAAAA==/",
            "_etag": "\"1700d48a-0000-0100-0000-61409cd20000\"",
            "_attachments": "attachments/",
            "_ts": 1631624402
        },
        {
            "id": "f3d9255aaca823ce561ca94298f74b864c3aea69ef725d72980d47fca8619f31",
            "conversationId": "f3d9255aaca823ce561ca94298f74b864c3aea69ef725d72980d47fca8619f31",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Severe joint pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-14T12:04:05.294Z",
            "endTime": "2021-09-14T12:04:05.294Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhUAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhUAQAAAAAAAA==/",
            "_etag": "\"1700d58a-0000-0100-0000-61409cd20000\"",
            "_attachments": "attachments/",
            "_ts": 1631624402
        },
        {
            "id": "b26efeef7bcc4afcacf41bb3e70cbca54de319ac0fce3e9cec62f9e36eea9c0b",
            "conversationId": "b26efeef7bcc4afcacf41bb3e70cbca54de319ac0fce3e9cec62f9e36eea9c0b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-14T12:28:50.536Z",
            "endTime": "2021-09-14T12:37:12.9Z",
            "timeSpent": 502,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhVAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhVAQAAAAAAAA==/",
            "_etag": "\"1700d68a-0000-0100-0000-61409cd20000\"",
            "_attachments": "attachments/",
            "_ts": 1631624402
        },
        {
            "id": "7633a5e2db4cd41f778bc66247726e9083a2e473ea79e13c016c30fb47af80e8",
            "conversationId": "7633a5e2db4cd41f778bc66247726e9083a2e473ea79e13c016c30fb47af80e8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-14T12:44:19.851Z",
            "endTime": "2021-09-14T12:47:02.026Z",
            "timeSpent": 162,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhWAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhWAQAAAAAAAA==/",
            "_etag": "\"1800f319-0000-0100-0000-6140aae30000\"",
            "_attachments": "attachments/",
            "_ts": 1631628003
        },
        {
            "id": "bfd4e6498f4db9e6f993ef11fecba0ae8681a36ec63dfdf5efcd74c502cf9618",
            "conversationId": "bfd4e6498f4db9e6f993ef11fecba0ae8681a36ec63dfdf5efcd74c502cf9618",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-14T12:10:57.488Z",
            "endTime": "2021-09-14T12:10:57.488Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhXAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhXAQAAAAAAAA==/",
            "_etag": "\"1700d88a-0000-0100-0000-61409cd20000\"",
            "_attachments": "attachments/",
            "_ts": 1631624402
        },
        {
            "id": "d9091515196862b4140c71f79b773f760f43d2ec92bb014e5ea726e90a0fac24",
            "conversationId": "d9091515196862b4140c71f79b773f760f43d2ec92bb014e5ea726e90a0fac24",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-14T12:56:45.541Z",
            "endTime": "2021-09-14T13:12:25.113Z",
            "timeSpent": 940,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhYAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhYAQAAAAAAAA==/",
            "_etag": "\"1800f419-0000-0100-0000-6140aae30000\"",
            "_attachments": "attachments/",
            "_ts": 1631628003
        },
        {
            "id": "f36d8619dd58f5bf12de6d85434b1b4e309f24ca2c736364a929f0a7bf9db561",
            "conversationId": "f36d8619dd58f5bf12de6d85434b1b4e309f24ca2c736364a929f0a7bf9db561",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe pain after an injury"
            ],
            "riskFactors": [
                "Recent physical injury"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-09-14T13:42:18.191Z",
            "endTime": "2021-09-14T13:46:23.667Z",
            "timeSpent": 245,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhZAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhZAQAAAAAAAA==/",
            "_etag": "\"18000274-0000-0100-0000-6140b8f70000\"",
            "_attachments": "attachments/",
            "_ts": 1631631607
        },
        {
            "id": "5d9bab04382f1ea340178e8d7d4c8a21fb7a9f60b72c88227828507c12872936",
            "conversationId": "5d9bab04382f1ea340178e8d7d4c8a21fb7a9f60b72c88227828507c12872936",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-14T13:10:55.593Z",
            "endTime": "2021-09-14T13:11:05.994Z",
            "timeSpent": 10,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhaAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhaAQAAAAAAAA==/",
            "_etag": "\"1800f619-0000-0100-0000-6140aae30000\"",
            "_attachments": "attachments/",
            "_ts": 1631628003
        },
        {
            "id": "136f15aa5e1e51bf51573d77991f70718254eb4cdaaef3e80a497b236ab055ab",
            "conversationId": "136f15aa5e1e51bf51573d77991f70718254eb4cdaaef3e80a497b236ab055ab",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-09-14T14:18:32.518Z",
            "endTime": "2021-09-14T14:26:39.651Z",
            "timeSpent": 487,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 2,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhbAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhbAQAAAAAAAA==/",
            "_etag": "\"18000374-0000-0100-0000-6140b8f70000\"",
            "_attachments": "attachments/",
            "_ts": 1631631607
        },
        {
            "id": "05f9292027630269ee30c4aeee7165d3506e9a273bad12f8a8f272afd3c5f4f1",
            "conversationId": "05f9292027630269ee30c4aeee7165d3506e9a273bad12f8a8f272afd3c5f4f1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-14T14:08:39.956Z",
            "endTime": "2021-09-14T14:08:45.55Z",
            "timeSpent": 6,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhcAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhcAQAAAAAAAA==/",
            "_etag": "\"18000474-0000-0100-0000-6140b8f70000\"",
            "_attachments": "attachments/",
            "_ts": 1631631607
        },
        {
            "id": "c20988f9c9858e8acb8f886f21f15a704d9321fd18cd1902f64349d2ac9ece1b",
            "conversationId": "c20988f9c9858e8acb8f886f21f15a704d9321fd18cd1902f64349d2ac9ece1b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Feeling sick"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-14T15:13:22.539Z",
            "endTime": "2021-09-14T15:22:07.304Z",
            "timeSpent": 525,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhdAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhdAQAAAAAAAA==/",
            "_etag": "\"1a00eb13-0000-0100-0000-6140c7010000\"",
            "_attachments": "attachments/",
            "_ts": 1631635201
        },
        {
            "id": "a72b0efdf358426d020e2f8eb3b135878af3704bd63f225e84bef199e4c0b86b",
            "conversationId": "a72b0efdf358426d020e2f8eb3b135878af3704bd63f225e84bef199e4c0b86b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-14T15:25:00.435Z",
            "endTime": "2021-09-14T15:25:00.435Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIheAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIheAQAAAAAAAA==/",
            "_etag": "\"1a00ec13-0000-0100-0000-6140c7010000\"",
            "_attachments": "attachments/",
            "_ts": 1631635201
        },
        {
            "id": "5104ef827513a67b90c3964992e66f7b9e80d41f170550d6a76a361be5bb4dd5",
            "conversationId": "5104ef827513a67b90c3964992e66f7b9e80d41f170550d6a76a361be5bb4dd5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Redness around eyes"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation",
            "startTime": "2021-09-14T15:46:16.174Z",
            "endTime": "2021-09-14T16:02:28.888Z",
            "timeSpent": 973,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhfAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhfAQAAAAAAAA==/",
            "_etag": "\"34002029-0000-0100-0000-6140d5130000\"",
            "_attachments": "attachments/",
            "_ts": 1631638803
        },
        {
            "id": "738212ad64c13169cd4402d6441af3de57cf7905a1ce8d468f2e4c4f6af0d5d3",
            "conversationId": "738212ad64c13169cd4402d6441af3de57cf7905a1ce8d468f2e4c4f6af0d5d3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-14T15:30:33.652Z",
            "endTime": "2021-09-14T15:37:44.707Z",
            "timeSpent": 431,
            "isNoteGenerated": "no",
            "npsGroup": "Passive",
            "npsScore": 7,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhgAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhgAQAAAAAAAA==/",
            "_etag": "\"1a00ee13-0000-0100-0000-6140c7010000\"",
            "_attachments": "attachments/",
            "_ts": 1631635201
        },
        {
            "id": "5d3c146df76414cc07a1d581a9b80b1ad57d8b80abad5d7b5ef7ad0b8eab0759",
            "conversationId": "5d3c146df76414cc07a1d581a9b80b1ad57d8b80abad5d7b5ef7ad0b8eab0759",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-14T15:03:11.975Z",
            "endTime": "2021-09-14T15:03:11.975Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhhAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhhAQAAAAAAAA==/",
            "_etag": "\"1a00ef13-0000-0100-0000-6140c7010000\"",
            "_attachments": "attachments/",
            "_ts": 1631635201
        },
        {
            "id": "21de16d3160fb8e88534f6d77ca0641df544878e73ada746911c3c16861c0ab0",
            "conversationId": "21de16d3160fb8e88534f6d77ca0641df544878e73ada746911c3c16861c0ab0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Redness around eyes"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation",
            "startTime": "2021-09-14T16:03:23.069Z",
            "endTime": "2021-09-14T16:08:57.546Z",
            "timeSpent": 334,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhiAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhiAQAAAAAAAA==/",
            "_etag": "\"34001c29-0000-0100-0000-6140d5130000\"",
            "_attachments": "attachments/",
            "_ts": 1631638803
        },
        {
            "id": "25ead4e139b1d5a95a3c71c5e22a208642b9204088f5a12453ad4ca92c5e0175",
            "conversationId": "25ead4e139b1d5a95a3c71c5e22a208642b9204088f5a12453ad4ca92c5e0175",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation",
            "startTime": "2021-09-14T16:45:30.931Z",
            "endTime": "2021-09-14T16:48:30.207Z",
            "timeSpent": 179,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhjAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhjAQAAAAAAAA==/",
            "_etag": "\"4c00b9d2-0000-0100-0000-6140e3230000\"",
            "_attachments": "attachments/",
            "_ts": 1631642403
        },
        {
            "id": "2bb69e17e2115d166c50d69b3f003a41392de890d87e4740fe4978c771a046bb",
            "conversationId": "2bb69e17e2115d166c50d69b3f003a41392de890d87e4740fe4978c771a046bb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe joint pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-14T16:38:57.724Z",
            "endTime": "2021-09-14T16:43:11.463Z",
            "timeSpent": 254,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhkAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhkAQAAAAAAAA==/",
            "_etag": "\"34001e29-0000-0100-0000-6140d5130000\"",
            "_attachments": "attachments/",
            "_ts": 1631638803
        },
        {
            "id": "d11ed12b787cdfbf6a08f7dbb1b1e2dc081e11df87ffb976e2d714666a966ae0",
            "conversationId": "d11ed12b787cdfbf6a08f7dbb1b1e2dc081e11df87ffb976e2d714666a966ae0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-14T16:02:51.191Z",
            "endTime": "2021-09-14T16:03:10.162Z",
            "timeSpent": 19,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhlAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhlAQAAAAAAAA==/",
            "_etag": "\"34001f29-0000-0100-0000-6140d5130000\"",
            "_attachments": "attachments/",
            "_ts": 1631638803
        },
        {
            "id": "4572d5a269e12023d820a4fa91d165fc3c2fb9ddc6dd051cd0f821051bbaceef",
            "conversationId": "4572d5a269e12023d820a4fa91d165fc3c2fb9ddc6dd051cd0f821051bbaceef",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-14T17:39:24.511Z",
            "endTime": "2021-09-14T17:39:24.511Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhmAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhmAQAAAAAAAA==/",
            "_etag": "\"64003ae4-0000-0100-0000-6140f1320000\"",
            "_attachments": "attachments/",
            "_ts": 1631646002
        },
        {
            "id": "4f40923c6300ae12bfa77ee466a511c596005d2f78dedb5d157798b5022e1786",
            "conversationId": "4f40923c6300ae12bfa77ee466a511c596005d2f78dedb5d157798b5022e1786",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-14T18:12:08.798Z",
            "endTime": "2021-09-14T18:12:19.174Z",
            "timeSpent": 10,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhnAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhnAQAAAAAAAA==/",
            "_etag": "\"640037e4-0000-0100-0000-6140f1320000\"",
            "_attachments": "attachments/",
            "_ts": 1631646002
        },
        {
            "id": "fa66160e238029595240ffc74684a472e5b5ef935441756eaaf4dfda5f877888",
            "conversationId": "fa66160e238029595240ffc74684a472e5b5ef935441756eaaf4dfda5f877888",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-14T18:10:13.283Z",
            "endTime": "2021-09-14T18:11:56.117Z",
            "timeSpent": 103,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhoAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhoAQAAAAAAAA==/",
            "_etag": "\"640038e4-0000-0100-0000-6140f1320000\"",
            "_attachments": "attachments/",
            "_ts": 1631646002
        },
        {
            "id": "a08be458c4d285f91b9c6bc70de5f9e05be2d61d8b525337faeb90ac322096f8",
            "conversationId": "a08be458c4d285f91b9c6bc70de5f9e05be2d61d8b525337faeb90ac322096f8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation",
            "startTime": "2021-09-14T18:52:28.647Z",
            "endTime": "2021-09-14T18:53:56.059Z",
            "timeSpent": 87,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhpAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhpAQAAAAAAAA==/",
            "_etag": "\"7c0027ad-0000-0100-0000-6140ff420000\"",
            "_attachments": "attachments/",
            "_ts": 1631649602
        },
        {
            "id": "d407c88d3d17f5c3608e5ac7ed71e68b1f4fb47f679179b07a6b0355585d81da",
            "conversationId": "d407c88d3d17f5c3608e5ac7ed71e68b1f4fb47f679179b07a6b0355585d81da",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-14T19:06:36.217Z",
            "endTime": "2021-09-14T19:06:36.217Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhqAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhqAQAAAAAAAA==/",
            "_etag": "\"7c0026ad-0000-0100-0000-6140ff420000\"",
            "_attachments": "attachments/",
            "_ts": 1631649602
        },
        {
            "id": "a3c28a8d911632e174a2636b64c668400210e494bd81895ccde896c08705ed4d",
            "conversationId": "a3c28a8d911632e174a2636b64c668400210e494bd81895ccde896c08705ed4d",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-09-15T10:51:04.491Z",
            "endTime": "2021-09-15T10:53:25.226Z",
            "timeSpent": 141,
            "isNoteGenerated": "yes",
            "npsGroup": "Passive",
            "npsScore": 8,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhrAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhrAQAAAAAAAA==/",
            "_etag": "\"c00132a5-0000-0100-0000-6141d6010000\"",
            "_attachments": "attachments/",
            "_ts": 1631704577
        },
        {
            "id": "c4cd49ffc4bf37a645c550c52e72c83396d0269d9d13b06080a79222b47c4ad8",
            "conversationId": "c4cd49ffc4bf37a645c550c52e72c83396d0269d9d13b06080a79222b47c4ad8",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-15T10:46:23.032Z",
            "endTime": "2021-09-15T10:47:34.002Z",
            "timeSpent": 71,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhsAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhsAQAAAAAAAA==/",
            "_etag": "\"cf01ab8f-0000-0100-0000-6141e0410000\"",
            "_attachments": "attachments/",
            "_ts": 1631707201
        },
        {
            "id": "43115ba51154415bce9ec248d5d8a200b18d8e3059e8ba4f243d9f680cad3029",
            "conversationId": "43115ba51154415bce9ec248d5d8a200b18d8e3059e8ba4f243d9f680cad3029",
            "cdoId": null,
            "cdoName": "reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-15T10:48:07.57Z",
            "endTime": "2021-09-15T10:48:07.57Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhtAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhtAQAAAAAAAA==/",
            "_etag": "\"cf01ac8f-0000-0100-0000-6141e0410000\"",
            "_attachments": "attachments/",
            "_ts": 1631707201
        },
        {
            "id": "1a961d2a7a7625321ab8399af87b8208dc62aa39aa1873bb155f6a68bd64ca36",
            "conversationId": "1a961d2a7a7625321ab8399af87b8208dc62aa39aa1873bb155f6a68bd64ca36",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Fast heartbeat"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-15T11:52:30.329Z",
            "endTime": "2021-09-15T11:53:22.884Z",
            "timeSpent": 53,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhuAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhuAQAAAAAAAA==/",
            "_etag": "\"cf01ad8f-0000-0100-0000-6141e0410000\"",
            "_attachments": "attachments/",
            "_ts": 1631707201
        },
        {
            "id": "6ad88854148d954d7e046633293032847d08395ce03c67b6651cfee2dc36c8d3",
            "conversationId": "6ad88854148d954d7e046633293032847d08395ce03c67b6651cfee2dc36c8d3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-15T11:57:51.479Z",
            "endTime": "2021-09-15T12:00:07.629Z",
            "timeSpent": 136,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhvAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhvAQAAAAAAAA==/",
            "_etag": "\"e3016e92-0000-0100-0000-6141ee520000\"",
            "_attachments": "attachments/",
            "_ts": 1631710802
        },
        {
            "id": "6d525be2f98a3a1c1658f9ea3c679a5011593e9216b13bf0f2a9f5b223725f31",
            "conversationId": "6d525be2f98a3a1c1658f9ea3c679a5011593e9216b13bf0f2a9f5b223725f31",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-15T11:32:30.118Z",
            "endTime": "2021-09-15T11:32:30.118Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhwAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhwAQAAAAAAAA==/",
            "_etag": "\"cf01af8f-0000-0100-0000-6141e0410000\"",
            "_attachments": "attachments/",
            "_ts": 1631707201
        },
        {
            "id": "bb48e88462c863f6a84f42803119929ab7009a0fb72f4fa8af954d17d4c2c2d2",
            "conversationId": "bb48e88462c863f6a84f42803119929ab7009a0fb72f4fa8af954d17d4c2c2d2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Stomach pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-15T11:32:49.841Z",
            "endTime": "2021-09-15T11:35:55.413Z",
            "timeSpent": 186,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhxAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhxAQAAAAAAAA==/",
            "_etag": "\"cf01b08f-0000-0100-0000-6141e0410000\"",
            "_attachments": "attachments/",
            "_ts": 1631707201
        },
        {
            "id": "46787ae77ce1e1e9ccc43f89d039af9724887ef2bd5119521c7dc713d53649f3",
            "conversationId": "46787ae77ce1e1e9ccc43f89d039af9724887ef2bd5119521c7dc713d53649f3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Stomach pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-15T11:36:50.318Z",
            "endTime": "2021-09-15T11:43:19.282Z",
            "timeSpent": 389,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhyAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhyAQAAAAAAAA==/",
            "_etag": "\"e3016f92-0000-0100-0000-6141ee520000\"",
            "_attachments": "attachments/",
            "_ts": 1631710802
        },
        {
            "id": "41cf4855ff3b3e4cc28879b04aabedbf60637ca7b044e918e73cd60c75795133",
            "conversationId": "41cf4855ff3b3e4cc28879b04aabedbf60637ca7b044e918e73cd60c75795133",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-15T12:32:50.079Z",
            "endTime": "2021-09-15T12:33:51.876Z",
            "timeSpent": 62,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhzAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhzAQAAAAAAAA==/",
            "_etag": "\"f7014b48-0000-0100-0000-6141fc610000\"",
            "_attachments": "attachments/",
            "_ts": 1631714401
        },
        {
            "id": "d71c1a2996f102ea10f7585f916a2d568e3a1d01a7198e6030ba4f433b0b258e",
            "conversationId": "d71c1a2996f102ea10f7585f916a2d568e3a1d01a7198e6030ba4f433b0b258e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-15T12:34:24.556Z",
            "endTime": "2021-09-15T12:35:06.057Z",
            "timeSpent": 42,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh0AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh0AQAAAAAAAA==/",
            "_etag": "\"f7014d48-0000-0100-0000-6141fc610000\"",
            "_attachments": "attachments/",
            "_ts": 1631714401
        },
        {
            "id": "d96e104c7d8ad421b55ce51a081bda5bb14837b4a6037cb6be6c24b7ff616355",
            "conversationId": "d96e104c7d8ad421b55ce51a081bda5bb14837b4a6037cb6be6c24b7ff616355",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-15T12:36:02.762Z",
            "endTime": "2021-09-15T12:36:15.39Z",
            "timeSpent": 13,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh1AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh1AQAAAAAAAA==/",
            "_etag": "\"f7014f48-0000-0100-0000-6141fc610000\"",
            "_attachments": "attachments/",
            "_ts": 1631714401
        },
        {
            "id": "d3a30040154e523ccd49333bf91248d8964a838e5d9148eb3270183cef047d7a",
            "conversationId": "d3a30040154e523ccd49333bf91248d8964a838e5d9148eb3270183cef047d7a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-15T12:06:31.49Z",
            "endTime": "2021-09-15T12:06:31.49Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh2AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh2AQAAAAAAAA==/",
            "_etag": "\"e3016c92-0000-0100-0000-6141ee520000\"",
            "_attachments": "attachments/",
            "_ts": 1631710802
        },
        {
            "id": "a7f8c16a8f359b0be80b9229acd8e3a1b7f9a487c9b9ecc8e5ab160bb9090d5e",
            "conversationId": "a7f8c16a8f359b0be80b9229acd8e3a1b7f9a487c9b9ecc8e5ab160bb9090d5e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-15T12:36:36.11Z",
            "endTime": "2021-09-15T12:36:50.271Z",
            "timeSpent": 14,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh3AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh3AQAAAAAAAA==/",
            "_etag": "\"f7015048-0000-0100-0000-6141fc610000\"",
            "_attachments": "attachments/",
            "_ts": 1631714401
        },
        {
            "id": "9dc75fa1bb6eedab2d877d4e8856323f4f4cde4c866b91562819f36cb4979857",
            "conversationId": "9dc75fa1bb6eedab2d877d4e8856323f4f4cde4c866b91562819f36cb4979857",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-15T12:05:38.692Z",
            "endTime": "2021-09-15T12:05:38.692Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh4AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh4AQAAAAAAAA==/",
            "_etag": "\"e3017092-0000-0100-0000-6141ee520000\"",
            "_attachments": "attachments/",
            "_ts": 1631710802
        },
        {
            "id": "07b902cc208054882ac24acf4479b10e53efba67902d3813eb150d46637b665a",
            "conversationId": "07b902cc208054882ac24acf4479b10e53efba67902d3813eb150d46637b665a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-15T12:07:00.113Z",
            "endTime": "2021-09-15T12:15:54.295Z",
            "timeSpent": 534,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh5AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh5AQAAAAAAAA==/",
            "_etag": "\"e3017192-0000-0100-0000-6141ee520000\"",
            "_attachments": "attachments/",
            "_ts": 1631710802
        },
        {
            "id": "9a632dcc33775e430e492b91e7c46bcf53ee2b35e2fb2084882ef6479c216d99",
            "conversationId": "9a632dcc33775e430e492b91e7c46bcf53ee2b35e2fb2084882ef6479c216d99",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-15T13:27:04.716Z",
            "endTime": "2021-09-15T13:27:04.716Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh6AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh6AQAAAAAAAA==/",
            "_etag": "\"f7014e48-0000-0100-0000-6141fc610000\"",
            "_attachments": "attachments/",
            "_ts": 1631714401
        },
        {
            "id": "ebe75cf33cafdba1ae5c69d363cc237bea8d2c4f239ccad444ea7e9afe2c531e",
            "conversationId": "ebe75cf33cafdba1ae5c69d363cc237bea8d2c4f239ccad444ea7e9afe2c531e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-15T13:18:20.731Z",
            "endTime": "2021-09-15T13:19:24.923Z",
            "timeSpent": 64,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh7AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh7AQAAAAAAAA==/",
            "_etag": "\"f7015248-0000-0100-0000-6141fc610000\"",
            "_attachments": "attachments/",
            "_ts": 1631714401
        },
        {
            "id": "afd6d57cc561019788e4e80835baa3d50ddc470cb4b100828ceb7d9fd7e2e7eb",
            "conversationId": "afd6d57cc561019788e4e80835baa3d50ddc470cb4b100828ceb7d9fd7e2e7eb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-15T17:01:51.354Z",
            "endTime": "2021-09-15T17:01:51.354Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh8AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh8AQAAAAAAAA==/",
            "_etag": "\"450229b8-0000-0100-0000-614234a60000\"",
            "_attachments": "attachments/",
            "_ts": 1631728806
        },
        {
            "id": "5c7e8ee92441e6f22a466230c9108b2aa108a33c23b6827fc6d8ae19a8dff39f",
            "conversationId": "5c7e8ee92441e6f22a466230c9108b2aa108a33c23b6827fc6d8ae19a8dff39f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-15T17:02:29.031Z",
            "endTime": "2021-09-15T17:07:26.817Z",
            "timeSpent": 298,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 3,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh9AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh9AQAAAAAAAA==/",
            "_etag": "\"45022ab8-0000-0100-0000-614234a60000\"",
            "_attachments": "attachments/",
            "_ts": 1631728806
        },
        {
            "id": "9db4aaedff81e9f24a1dde51bf84cf8e0eeb9125154634b1d059ffb4f5d66d56",
            "conversationId": "9db4aaedff81e9f24a1dde51bf84cf8e0eeb9125154634b1d059ffb4f5d66d56",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-15T17:05:26.656Z",
            "endTime": "2021-09-15T17:08:15.113Z",
            "timeSpent": 168,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh+AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh+AQAAAAAAAA==/",
            "_etag": "\"45022bb8-0000-0100-0000-614234a60000\"",
            "_attachments": "attachments/",
            "_ts": 1631728806
        },
        {
            "id": "2b8771010eb322f335e92cf596d73d74d94d789c917e078484d3c58ec5124947",
            "conversationId": "2b8771010eb322f335e92cf596d73d74d94d789c917e078484d3c58ec5124947",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-15T17:10:47.107Z",
            "endTime": "2021-09-15T17:11:19.847Z",
            "timeSpent": 33,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh-AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh-AQAAAAAAAA==/",
            "_etag": "\"45022cb8-0000-0100-0000-614234a60000\"",
            "_attachments": "attachments/",
            "_ts": 1631728806
        },
        {
            "id": "4ce7def40c16ab4cf4809d5731565b7a8cfd12ec9af678636011be90e422505b",
            "conversationId": "4ce7def40c16ab4cf4809d5731565b7a8cfd12ec9af678636011be90e422505b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-15T17:11:04.057Z",
            "endTime": "2021-09-15T17:13:40.843Z",
            "timeSpent": 157,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiAAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiAAQAAAAAAAA==/",
            "_etag": "\"45022db8-0000-0100-0000-614234a60000\"",
            "_attachments": "attachments/",
            "_ts": 1631728806
        },
        {
            "id": "069384be17c3de5cae8d3d6d883de5914a7b15801c5a0f8356db50ed447801ef",
            "conversationId": "069384be17c3de5cae8d3d6d883de5914a7b15801c5a0f8356db50ed447801ef",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-09-15T17:12:03.216Z",
            "endTime": "2021-09-15T17:14:00.478Z",
            "timeSpent": 117,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 1,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiBAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiBAQAAAAAAAA==/",
            "_etag": "\"45022eb8-0000-0100-0000-614234a60000\"",
            "_attachments": "attachments/",
            "_ts": 1631728806
        },
        {
            "id": "779b9dc708d8f115cd7ff73a0f5a4014da1c7a658f46a13aecb535be033542f5",
            "conversationId": "779b9dc708d8f115cd7ff73a0f5a4014da1c7a658f46a13aecb535be033542f5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Lower back pain"
            ],
            "riskFactors": [
                "History of tonsillectomy",
                "Smoking",
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-09-15T17:20:32.443Z",
            "endTime": "2021-09-15T17:22:16.775Z",
            "timeSpent": 104,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 5,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiCAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiCAQAAAAAAAA==/",
            "_etag": "\"45022fb8-0000-0100-0000-614234a60000\"",
            "_attachments": "attachments/",
            "_ts": 1631728806
        },
        {
            "id": "27b1825874340f12c469760b0c5630e834c461235d885311c8a3f82f6cae0ba5",
            "conversationId": "27b1825874340f12c469760b0c5630e834c461235d885311c8a3f82f6cae0ba5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [
                "History of tonsillectomy",
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation",
            "startTime": "2021-09-15T17:20:31.116Z",
            "endTime": "2021-09-15T17:22:25.547Z",
            "timeSpent": 114,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiDAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiDAQAAAAAAAA==/",
            "_etag": "\"450230b8-0000-0100-0000-614234a60000\"",
            "_attachments": "attachments/",
            "_ts": 1631728806
        },
        {
            "id": "98331cfbfee586cb9645f5d4a53ca8e0308918a62aaca7dbd82546748f4d2302",
            "conversationId": "98331cfbfee586cb9645f5d4a53ca8e0308918a62aaca7dbd82546748f4d2302",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation",
            "startTime": "2021-09-15T18:29:16.551Z",
            "endTime": "2021-09-15T18:33:03.664Z",
            "timeSpent": 227,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiEAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiEAQAAAAAAAA==/",
            "_etag": "\"58025cdd-0000-0100-0000-614242b20000\"",
            "_attachments": "attachments/",
            "_ts": 1631732402
        },
        {
            "id": "4e6f18e6c68e0650e4f45e69043b972ecb07076bed7af7eb19e04129299563ad",
            "conversationId": "4e6f18e6c68e0650e4f45e69043b972ecb07076bed7af7eb19e04129299563ad",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-15T18:32:28.263Z",
            "endTime": "2021-09-15T18:33:36.809Z",
            "timeSpent": 69,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiFAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiFAQAAAAAAAA==/",
            "_etag": "\"58025ddd-0000-0100-0000-614242b20000\"",
            "_attachments": "attachments/",
            "_ts": 1631732402
        },
        {
            "id": "c701decccd2b9b49a6d539eaa99e10eecb86d4ec10378832d9093361ee67d241",
            "conversationId": "c701decccd2b9b49a6d539eaa99e10eecb86d4ec10378832d9093361ee67d241",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-15T18:33:57.974Z",
            "endTime": "2021-09-15T18:33:57.974Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiGAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiGAQAAAAAAAA==/",
            "_etag": "\"58025edd-0000-0100-0000-614242b20000\"",
            "_attachments": "attachments/",
            "_ts": 1631732402
        },
        {
            "id": "9be9153a8bd1786963106bcce10bf15166b6798e186cb1ea99633c6b3f332a37",
            "conversationId": "9be9153a8bd1786963106bcce10bf15166b6798e186cb1ea99633c6b3f332a37",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-09-15T18:34:06.7Z",
            "endTime": "2021-09-15T18:36:18.293Z",
            "timeSpent": 132,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiHAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiHAQAAAAAAAA==/",
            "_etag": "\"58025fdd-0000-0100-0000-614242b20000\"",
            "_attachments": "attachments/",
            "_ts": 1631732402
        },
        {
            "id": "2de3988dd71bd8a072e9704fc9817214def907400f14e5f2e7979df4da755de8",
            "conversationId": "2de3988dd71bd8a072e9704fc9817214def907400f14e5f2e7979df4da755de8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-15T18:58:35.945Z",
            "endTime": "2021-09-15T18:58:48.052Z",
            "timeSpent": 12,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiIAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiIAQAAAAAAAA==/",
            "_etag": "\"580260dd-0000-0100-0000-614242b20000\"",
            "_attachments": "attachments/",
            "_ts": 1631732402
        },
        {
            "id": "ec8942a094de49dd15ff3812570731f266f5c6d72e9c89561292f9fe484b71b1",
            "conversationId": "ec8942a094de49dd15ff3812570731f266f5c6d72e9c89561292f9fe484b71b1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-09-15T18:58:54.982Z",
            "endTime": "2021-09-15T19:04:51.798Z",
            "timeSpent": 357,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiJAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiJAQAAAAAAAA==/",
            "_etag": "\"6b0200d4-0000-0100-0000-614250c40000\"",
            "_attachments": "attachments/",
            "_ts": 1631736004
        },
        {
            "id": "ac26ac965bde111d6b7825d54218c9bf4eb60ca533fcb85037a232f609ae7d4f",
            "conversationId": "ac26ac965bde111d6b7825d54218c9bf4eb60ca533fcb85037a232f609ae7d4f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 51,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-15T18:08:57.486Z",
            "endTime": "2021-09-15T18:09:09.632Z",
            "timeSpent": 12,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiKAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiKAQAAAAAAAA==/",
            "_etag": "\"580262dd-0000-0100-0000-614242b20000\"",
            "_attachments": "attachments/",
            "_ts": 1631732402
        },
        {
            "id": "790bc1776c6f15fe1f4baade942e325648982f1476214b56aaba31f052aeeff2",
            "conversationId": "790bc1776c6f15fe1f4baade942e325648982f1476214b56aaba31f052aeeff2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 51,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-16T13:48:25.804Z",
            "endTime": "2021-09-16T13:48:25.804Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiLAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiLAQAAAAAAAA==/",
            "_etag": "\"7e028f1d-0000-0100-0000-61435bf20000\"",
            "_attachments": "attachments/",
            "_ts": 1631804402
        },
        {
            "id": "585bf3a075179df44ba9eb3dd8290c890b09a25cfc4dcbaf83ba76878c21b96f",
            "conversationId": "585bf3a075179df44ba9eb3dd8290c890b09a25cfc4dcbaf83ba76878c21b96f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-16T15:57:30.5Z",
            "endTime": "2021-09-16T15:57:30.5Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiMAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiMAQAAAAAAAA==/",
            "_etag": "\"7f02533d-0000-0100-0000-6143784d0000\"",
            "_attachments": "attachments/",
            "_ts": 1631811661
        },
        {
            "id": "3cc27e5474f153934288010cbd124e79a6726ab8a170bb6583d3e9055f130281",
            "conversationId": "3cc27e5474f153934288010cbd124e79a6726ab8a170bb6583d3e9055f130281",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-16T15:36:23.93Z",
            "endTime": "2021-09-16T15:38:02.379Z",
            "timeSpent": 98,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiNAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiNAQAAAAAAAA==/",
            "_etag": "\"7e02fcb1-0000-0100-0000-61436a020000\"",
            "_attachments": "attachments/",
            "_ts": 1631808002
        },
        {
            "id": "4cab93a99da63a51cf179ba3ec75eb0ec37988596287c753da484ddd23d14e2c",
            "conversationId": "4cab93a99da63a51cf179ba3ec75eb0ec37988596287c753da484ddd23d14e2c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation",
            "startTime": "2021-09-16T15:36:40.949Z",
            "endTime": "2021-09-16T15:38:15.88Z",
            "timeSpent": 95,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiOAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiOAQAAAAAAAA==/",
            "_etag": "\"7e02fdb1-0000-0100-0000-61436a020000\"",
            "_attachments": "attachments/",
            "_ts": 1631808002
        },
        {
            "id": "3e0a86741f2880251195d35762f7f000bfbdbf76fbfcde528a4adbc495964650",
            "conversationId": "3e0a86741f2880251195d35762f7f000bfbdbf76fbfcde528a4adbc495964650",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-16T15:37:33.131Z",
            "endTime": "2021-09-16T15:38:52.672Z",
            "timeSpent": 80,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiPAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiPAQAAAAAAAA==/",
            "_etag": "\"7e02feb1-0000-0100-0000-61436a020000\"",
            "_attachments": "attachments/",
            "_ts": 1631808002
        },
        {
            "id": "1caa86d5944bce986b2115a63f6370d012fd6fd8bb2f9426f7679868154535a8",
            "conversationId": "1caa86d5944bce986b2115a63f6370d012fd6fd8bb2f9426f7679868154535a8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Lower back pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-09-16T15:39:09.125Z",
            "endTime": "2021-09-16T15:40:34.148Z",
            "timeSpent": 85,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 2,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiQAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiQAQAAAAAAAA==/",
            "_etag": "\"7e02ffb1-0000-0100-0000-61436a020000\"",
            "_attachments": "attachments/",
            "_ts": 1631808002
        },
        {
            "id": "61772ff5e7ad870e0e5feef62037d025864be796e13d916cdec6b390011d15f1",
            "conversationId": "61772ff5e7ad870e0e5feef62037d025864be796e13d916cdec6b390011d15f1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-16T15:39:56.6Z",
            "endTime": "2021-09-16T15:40:04.83Z",
            "timeSpent": 8,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiRAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiRAQAAAAAAAA==/",
            "_etag": "\"7e0200b2-0000-0100-0000-61436a020000\"",
            "_attachments": "attachments/",
            "_ts": 1631808002
        },
        {
            "id": "4eb3fe155de182ad5ae550277ddb71f6e1a4d484b071f60eddc1d1d702fac9b2",
            "conversationId": "4eb3fe155de182ad5ae550277ddb71f6e1a4d484b071f60eddc1d1d702fac9b2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Sinuses tender to palpation"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation",
            "startTime": "2021-09-16T15:57:46.204Z",
            "endTime": "2021-09-16T15:59:10.021Z",
            "timeSpent": 84,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiSAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiSAQAAAAAAAA==/",
            "_etag": "\"7e0201b2-0000-0100-0000-61436a020000\"",
            "_attachments": "attachments/",
            "_ts": 1631808002
        },
        {
            "id": "7015c05eaab2f1b0c1a66684301844d49e1dbb533e7c68eedb9f4eea748fcbbf",
            "conversationId": "7015c05eaab2f1b0c1a66684301844d49e1dbb533e7c68eedb9f4eea748fcbbf",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing fits"
            ],
            "riskFactors": [
                "History of tonsillectomy",
                "Smoking",
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-09-16T17:42:56.474Z",
            "endTime": "2021-09-16T17:45:30.91Z",
            "timeSpent": 154,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiTAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiTAQAAAAAAAA==/",
            "_etag": "\"80024237-0000-0100-0000-614394320000\"",
            "_attachments": "attachments/",
            "_ts": 1631818802
        },
        {
            "id": "d3c0d1fea80d5a805192bf14135f7bf59581bc39d831f6fbe4dc80b72e058795",
            "conversationId": "d3c0d1fea80d5a805192bf14135f7bf59581bc39d831f6fbe4dc80b72e058795",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-17T08:44:14.298Z",
            "endTime": "2021-09-17T08:46:04.085Z",
            "timeSpent": 110,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiUAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiUAQAAAAAAAA==/",
            "_etag": "\"83022c90-0000-0100-0000-614459140000\"",
            "_attachments": "attachments/",
            "_ts": 1631869204
        },
        {
            "id": "f02c5746a51f9f88bc178d731564796c4cca9ac50071b5104e6927e7cacaf0e5",
            "conversationId": "f02c5746a51f9f88bc178d731564796c4cca9ac50071b5104e6927e7cacaf0e5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-17T08:46:57.325Z",
            "endTime": "2021-09-17T08:47:18.655Z",
            "timeSpent": 21,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiVAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiVAQAAAAAAAA==/",
            "_etag": "\"83022d90-0000-0100-0000-614459140000\"",
            "_attachments": "attachments/",
            "_ts": 1631869204
        },
        {
            "id": "c66dea67fd8cbc4ff0f0bebb690fee0890f87ef404536ea71816946b314c0d23",
            "conversationId": "c66dea67fd8cbc4ff0f0bebb690fee0890f87ef404536ea71816946b314c0d23",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation",
            "startTime": "2021-09-17T08:48:10.781Z",
            "endTime": "2021-09-17T08:50:13.996Z",
            "timeSpent": 123,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiWAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiWAQAAAAAAAA==/",
            "_etag": "\"83022e90-0000-0100-0000-614459140000\"",
            "_attachments": "attachments/",
            "_ts": 1631869204
        },
        {
            "id": "a933530077b677308463339990730e78643fad51b5dd16208c4ca31a8710a32a",
            "conversationId": "a933530077b677308463339990730e78643fad51b5dd16208c4ca31a8710a32a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-09-17T08:51:35.136Z",
            "endTime": "2021-09-17T08:53:54.21Z",
            "timeSpent": 139,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiXAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiXAQAAAAAAAA==/",
            "_etag": "\"83022f90-0000-0100-0000-614459140000\"",
            "_attachments": "attachments/",
            "_ts": 1631869204
        },
        {
            "id": "05fda5c51af175cd002c0f889ff4798574ef32134548232e58531a7c238040c6",
            "conversationId": "05fda5c51af175cd002c0f889ff4798574ef32134548232e58531a7c238040c6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Knee injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-17T09:21:05.702Z",
            "endTime": "2021-09-17T09:21:45.121Z",
            "timeSpent": 39,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiYAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiYAQAAAAAAAA==/",
            "_etag": "\"830277bb-0000-0100-0000-614467230000\"",
            "_attachments": "attachments/",
            "_ts": 1631872803
        },
        {
            "id": "4e29e44b6097292483e39248287a2d91bda3f416490de54443e035c35c9c5129",
            "conversationId": "4e29e44b6097292483e39248287a2d91bda3f416490de54443e035c35c9c5129",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Arm injury"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation",
            "startTime": "2021-09-17T09:35:43.453Z",
            "endTime": "2021-09-17T09:37:28.312Z",
            "timeSpent": 105,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiZAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiZAQAAAAAAAA==/",
            "_etag": "\"830278bb-0000-0100-0000-614467230000\"",
            "_attachments": "attachments/",
            "_ts": 1631872803
        },
        {
            "id": "7064aa86c16a9174a64250e9bb9f184029b695e16cbf3e4d8597192a4dde7fa5",
            "conversationId": "7064aa86c16a9174a64250e9bb9f184029b695e16cbf3e4d8597192a4dde7fa5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-17T09:22:38.781Z",
            "endTime": "2021-09-17T09:22:48.006Z",
            "timeSpent": 9,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiaAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiaAQAAAAAAAA==/",
            "_etag": "\"830279bb-0000-0100-0000-614467230000\"",
            "_attachments": "attachments/",
            "_ts": 1631872803
        },
        {
            "id": "a81640804ef3ddd17e93cc45419085b59df0bce6bc23967504d55948c0099881",
            "conversationId": "a81640804ef3ddd17e93cc45419085b59df0bce6bc23967504d55948c0099881",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-17T09:23:21.812Z",
            "endTime": "2021-09-17T09:30:44.619Z",
            "timeSpent": 443,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIibAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIibAQAAAAAAAA==/",
            "_etag": "\"83027abb-0000-0100-0000-614467230000\"",
            "_attachments": "attachments/",
            "_ts": 1631872803
        },
        {
            "id": "1e45964fac45dc0bf7797090ca55cf9dbd90aa11caa33455180fd92ef2ad8b13",
            "conversationId": "1e45964fac45dc0bf7797090ca55cf9dbd90aa11caa33455180fd92ef2ad8b13",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-17T11:57:35.804Z",
            "endTime": "2021-09-17T11:59:14.008Z",
            "timeSpent": 98,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIicAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIicAQAAAAAAAA==/",
            "_etag": "\"8402d278-0000-0100-0000-6144918f0000\"",
            "_attachments": "attachments/",
            "_ts": 1631883663
        },
        {
            "id": "d7a1cce3fdaf5cbab5c022ca89d7b054d3ef01cb56c077d132d1dfdf23da41e0",
            "conversationId": "d7a1cce3fdaf5cbab5c022ca89d7b054d3ef01cb56c077d132d1dfdf23da41e0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-17T11:52:04.748Z",
            "endTime": "2021-09-17T11:52:04.748Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIidAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIidAQAAAAAAAA==/",
            "_etag": "\"8402d178-0000-0100-0000-6144918f0000\"",
            "_attachments": "attachments/",
            "_ts": 1631883663
        },
        {
            "id": "90630a209338df989842494db9efa9219d6c149a2016045ff07a2ec5aee57baa",
            "conversationId": "90630a209338df989842494db9efa9219d6c149a2016045ff07a2ec5aee57baa",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-20T08:31:26.452Z",
            "endTime": "2021-09-20T08:33:00.244Z",
            "timeSpent": 94,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIieAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIieAQAAAAAAAA==/",
            "_etag": "\"9102c1c7-0000-0100-0000-61484d930000\"",
            "_attachments": "attachments/",
            "_ts": 1632128403
        },
        {
            "id": "7aaf626c7e592903050f23a6d532d286e3f6ba948913e9cb947cad517553a330",
            "conversationId": "7aaf626c7e592903050f23a6d532d286e3f6ba948913e9cb947cad517553a330",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [
                "History of tonsillectomy",
                "Smoking",
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-09-20T12:11:28.57Z",
            "endTime": "2021-09-20T12:17:02.048Z",
            "timeSpent": 333,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIifAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIifAQAAAAAAAA==/",
            "_etag": "\"9202730c-0000-0100-0000-614885d20000\"",
            "_attachments": "attachments/",
            "_ts": 1632142802
        },
        {
            "id": "bd318c3ba36813ab42bd3ab5efedaa8bdaa0313a541613219aee498c4f6803c0",
            "conversationId": "bd318c3ba36813ab42bd3ab5efedaa8bdaa0313a541613219aee498c4f6803c0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-21T12:24:19.23Z",
            "endTime": "2021-09-21T12:24:40.681Z",
            "timeSpent": 21,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIigAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIigAQAAAAAAAA==/",
            "_etag": "\"95028bae-0000-0100-0000-6149d7510000\"",
            "_attachments": "attachments/",
            "_ts": 1632229201
        },
        {
            "id": "dce2ff540b0859e1e625c240e8cf84a1b07ccc8371a5909f04f45c8164c525bf",
            "conversationId": "dce2ff540b0859e1e625c240e8cf84a1b07ccc8371a5909f04f45c8164c525bf",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-21T12:57:25.903Z",
            "endTime": "2021-09-21T12:57:33.467Z",
            "timeSpent": 8,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIihAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIihAQAAAAAAAA==/",
            "_etag": "\"950245e6-0000-0100-0000-6149e5610000\"",
            "_attachments": "attachments/",
            "_ts": 1632232801
        },
        {
            "id": "6f65047d1847a7f52f41773b29e957517fb2af6347f36c6eec8fa907d9ea0fef",
            "conversationId": "6f65047d1847a7f52f41773b29e957517fb2af6347f36c6eec8fa907d9ea0fef",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-21T12:23:26.904Z",
            "endTime": "2021-09-21T12:23:26.904Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiiAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiiAQAAAAAAAA==/",
            "_etag": "\"95028dae-0000-0100-0000-6149d7510000\"",
            "_attachments": "attachments/",
            "_ts": 1632229201
        },
        {
            "id": "ad04dc1f8444f3f0e0cb2dde6209347dcc1bf9b6e6553e0a386e7a3f64f68d48",
            "conversationId": "ad04dc1f8444f3f0e0cb2dde6209347dcc1bf9b6e6553e0a386e7a3f64f68d48",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-21T12:23:45.497Z",
            "endTime": "2021-09-21T12:23:45.497Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIijAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIijAQAAAAAAAA==/",
            "_etag": "\"95028eae-0000-0100-0000-6149d7510000\"",
            "_attachments": "attachments/",
            "_ts": 1632229201
        },
    

    
        {
            "id": "0a9df56f232b4a87f9b826ee4bb332cd7d7a950b62633568deb2ea0accc3366a",
            "conversationId": "0a9df56f232b4a87f9b826ee4bb332cd7d7a950b62633568deb2ea0accc3366a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-22T11:49:41.253Z",
            "endTime": "2021-09-22T11:50:23.813Z",
            "timeSpent": 43,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIikAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIikAQAAAAAAAA==/",
            "_etag": "\"9902c90e-0000-0100-0000-614b1ac20000\"",
            "_attachments": "attachments/",
            "_ts": 1632312002
        },
        {
            "id": "8f3d53289a864c847e031b84a0f362033a8045df3ab9f3c4952f43a4ac2c5cf0",
            "conversationId": "8f3d53289a864c847e031b84a0f362033a8045df3ab9f3c4952f43a4ac2c5cf0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-22T12:13:14.486Z",
            "endTime": "2021-09-22T12:13:55.782Z",
            "timeSpent": 41,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIilAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIilAQAAAAAAAA==/",
            "_etag": "\"99025833-0000-0100-0000-614b28d10000\"",
            "_attachments": "attachments/",
            "_ts": 1632315601
        },
        {
            "id": "2443c6f713656287bb38c37de4df600b0d6c8e3a428d6de75df2892497c9169e",
            "conversationId": "2443c6f713656287bb38c37de4df600b0d6c8e3a428d6de75df2892497c9169e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-22T12:14:57.665Z",
            "endTime": "2021-09-22T12:16:42.183Z",
            "timeSpent": 105,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIimAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIimAQAAAAAAAA==/",
            "_etag": "\"99025933-0000-0100-0000-614b28d10000\"",
            "_attachments": "attachments/",
            "_ts": 1632315601
        },
        {
            "id": "1faa3f5de2a0def49e0a457b09578aab4a7c60598ef825aeda176b881246cab1",
            "conversationId": "1faa3f5de2a0def49e0a457b09578aab4a7c60598ef825aeda176b881246cab1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-22T12:28:07.041Z",
            "endTime": "2021-09-22T12:28:07.041Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIinAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIinAQAAAAAAAA==/",
            "_etag": "\"99025a33-0000-0100-0000-614b28d10000\"",
            "_attachments": "attachments/",
            "_ts": 1632315601
        },
        {
            "id": "024c4a1f366b73d8d04f42c734aaa1ada37c06213dcef27a1682bd3a8680a2ee",
            "conversationId": "024c4a1f366b73d8d04f42c734aaa1ada37c06213dcef27a1682bd3a8680a2ee",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-22T12:25:15.547Z",
            "endTime": "2021-09-22T12:26:35.101Z",
            "timeSpent": 80,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIioAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIioAQAAAAAAAA==/",
            "_etag": "\"99025b33-0000-0100-0000-614b28d10000\"",
            "_attachments": "attachments/",
            "_ts": 1632315601
        },
        {
            "id": "7a84ce573dedfbe7cfc7319a9c8e6483f77d6f80ad9d27a8e36fdd0421f46313",
            "conversationId": "7a84ce573dedfbe7cfc7319a9c8e6483f77d6f80ad9d27a8e36fdd0421f46313",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-22T12:17:42.062Z",
            "endTime": "2021-09-22T12:21:55.197Z",
            "timeSpent": 253,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIipAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIipAQAAAAAAAA==/",
            "_etag": "\"99025c33-0000-0100-0000-614b28d10000\"",
            "_attachments": "attachments/",
            "_ts": 1632315601
        },
        {
            "id": "7315a32abcc51440964de926b32b8b5ffb6a09f36fd97d5fca6de1365b8710fa",
            "conversationId": "7315a32abcc51440964de926b32b8b5ffb6a09f36fd97d5fca6de1365b8710fa",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-22T14:54:40.134Z",
            "endTime": "2021-09-22T14:54:51.816Z",
            "timeSpent": 12,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiqAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiqAQAAAAAAAA==/",
            "_etag": "\"9902decb-0000-0100-0000-614b53020000\"",
            "_attachments": "attachments/",
            "_ts": 1632326402
        },
        {
            "id": "aef0ab704e315be9f3d1c352d11c887f35f197b8620208d033cff35a605ff687",
            "conversationId": "aef0ab704e315be9f3d1c352d11c887f35f197b8620208d033cff35a605ff687",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-22T14:55:27.097Z",
            "endTime": "2021-09-22T14:55:58.793Z",
            "timeSpent": 32,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIirAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIirAQAAAAAAAA==/",
            "_etag": "\"9902dfcb-0000-0100-0000-614b53020000\"",
            "_attachments": "attachments/",
            "_ts": 1632326402
        },
        {
            "id": "22d07a94acf92abc852be5066dcc4386aa2b344e9ffe97bff530cc7ba891c637",
            "conversationId": "22d07a94acf92abc852be5066dcc4386aa2b344e9ffe97bff530cc7ba891c637",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-22T14:56:11.2Z",
            "endTime": "2021-09-22T14:56:11.2Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIisAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIisAQAAAAAAAA==/",
            "_etag": "\"99028899-0000-0100-0000-614b44f10000\"",
            "_attachments": "attachments/",
            "_ts": 1632322801
        },
        {
            "id": "f67e2d974b7b7abc405f49175e41631cb76157466c7fe9db404b5b5ba6e9f7c2",
            "conversationId": "f67e2d974b7b7abc405f49175e41631cb76157466c7fe9db404b5b5ba6e9f7c2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-22T14:56:25.472Z",
            "endTime": "2021-09-22T14:57:01.909Z",
            "timeSpent": 36,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIitAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIitAQAAAAAAAA==/",
            "_etag": "\"99028999-0000-0100-0000-614b44f10000\"",
            "_attachments": "attachments/",
            "_ts": 1632322801
        },
        {
            "id": "b32e376abbcab17c9e243b1f216d620c7132e7afdfb63c5161cb77f1538e29ec",
            "conversationId": "b32e376abbcab17c9e243b1f216d620c7132e7afdfb63c5161cb77f1538e29ec",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-22T14:55:06.784Z",
            "endTime": "2021-09-22T14:55:15.38Z",
            "timeSpent": 9,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiuAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiuAQAAAAAAAA==/",
            "_etag": "\"99028a99-0000-0100-0000-614b44f10000\"",
            "_attachments": "attachments/",
            "_ts": 1632322801
        },
        {
            "id": "413a9e7bcd33ec9f66336a703313838b9fbdb35fcc1ab97b7ae72b14f49cae2c",
            "conversationId": "413a9e7bcd33ec9f66336a703313838b9fbdb35fcc1ab97b7ae72b14f49cae2c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-22T16:26:22.219Z",
            "endTime": "2021-09-22T16:26:22.219Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIivAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIivAQAAAAAAAA==/",
            "_etag": "\"99029dfc-0000-0100-0000-614b61110000\"",
            "_attachments": "attachments/",
            "_ts": 1632330001
        },
        {
            "id": "6b93cef38e58ad8d70a63653b4131ac915c8bb8821fb486f1b11d7fc80ebabcd",
            "conversationId": "6b93cef38e58ad8d70a63653b4131ac915c8bb8821fb486f1b11d7fc80ebabcd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-22T16:26:21.259Z",
            "endTime": "2021-09-22T16:26:33.439Z",
            "timeSpent": 12,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiwAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiwAQAAAAAAAA==/",
            "_etag": "\"99029efc-0000-0100-0000-614b61110000\"",
            "_attachments": "attachments/",
            "_ts": 1632330001
        },
        {
            "id": "d5bbfc08a05ed48e62eee1bdad61c61f93027363af1edfb7dc4c927ad9518d63",
            "conversationId": "d5bbfc08a05ed48e62eee1bdad61c61f93027363af1edfb7dc4c927ad9518d63",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 51,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-22T18:41:06.559Z",
            "endTime": "2021-09-22T18:41:11.632Z",
            "timeSpent": 5,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIixAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIixAQAAAAAAAA==/",
            "_etag": "\"9a02fc84-0000-0100-0000-614b8b420000\"",
            "_attachments": "attachments/",
            "_ts": 1632340802
        },
        {
            "id": "557efc6883a3ac1d38888c39b02bc29a636182dbcd7a05b8f40e80a11ba88cfd",
            "conversationId": "557efc6883a3ac1d38888c39b02bc29a636182dbcd7a05b8f40e80a11ba88cfd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-23T19:58:20.626Z",
            "endTime": "2021-09-23T20:04:53.096Z",
            "timeSpent": 392,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiyAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiyAQAAAAAAAA==/",
            "_etag": "\"9e023625-0000-0100-0000-614cead30000\"",
            "_attachments": "attachments/",
            "_ts": 1632430803
        },
        {
            "id": "8653b02feacf76a5c7c413759f3af53f49446016cad548f5314abc3258582a4d",
            "conversationId": "8653b02feacf76a5c7c413759f3af53f49446016cad548f5314abc3258582a4d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-23T19:57:33.067Z",
            "endTime": "2021-09-23T19:58:12.003Z",
            "timeSpent": 39,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIizAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIizAQAAAAAAAA==/",
            "_etag": "\"9e022203-0000-0100-0000-614cdcc60000\"",
            "_attachments": "attachments/",
            "_ts": 1632427206
        },
        {
            "id": "9d5bc20c010e6bb8bec2347807f61fd406a8c1017abcac76e6cda46c117a6bad",
            "conversationId": "9d5bc20c010e6bb8bec2347807f61fd406a8c1017abcac76e6cda46c117a6bad",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-23T19:52:55.455Z",
            "endTime": "2021-09-23T19:53:55.729Z",
            "timeSpent": 60,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi0AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi0AQAAAAAAAA==/",
            "_etag": "\"9e023725-0000-0100-0000-614cead30000\"",
            "_attachments": "attachments/",
            "_ts": 1632430803
        },
        {
            "id": "1416c6cd12533d7cc341290face05ee4720e1e9adc24cc7b0f745b8bdc5ec6bc",
            "conversationId": "1416c6cd12533d7cc341290face05ee4720e1e9adc24cc7b0f745b8bdc5ec6bc",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation",
            "startTime": "2021-09-23T20:15:51.432Z",
            "endTime": "2021-09-23T20:17:10.121Z",
            "timeSpent": 79,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 6,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi1AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi1AQAAAAAAAA==/",
            "_etag": "\"9e023525-0000-0100-0000-614cead30000\"",
            "_attachments": "attachments/",
            "_ts": 1632430803
        },
        {
            "id": "1afb7d2442c8798760d05b331996cfccdae62c92184e49ac6d61967dd5b077fa",
            "conversationId": "1afb7d2442c8798760d05b331996cfccdae62c92184e49ac6d61967dd5b077fa",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation",
            "startTime": "2021-09-23T21:33:23.044Z",
            "endTime": "2021-09-23T21:35:12.318Z",
            "timeSpent": 109,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 6,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi2AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi2AQAAAAAAAA==/",
            "_etag": "\"9e028241-0000-0100-0000-614cf8e20000\"",
            "_attachments": "attachments/",
            "_ts": 1632434402
        },
        {
            "id": "5352bf329a977cdaccc33babbc3464d6b5b08f06cff67c2d6bcc845a6af981f1",
            "conversationId": "5352bf329a977cdaccc33babbc3464d6b5b08f06cff67c2d6bcc845a6af981f1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [
                "History of tonsillectomy",
                "Smoking",
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-09-24T11:33:17.683Z",
            "endTime": "2021-09-24T11:35:35.744Z",
            "timeSpent": 138,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi3AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi3AQAAAAAAAA==/",
            "_etag": "\"9f0243e4-0000-0100-0000-614dcbd10000\"",
            "_attachments": "attachments/",
            "_ts": 1632488401
        },
        {
            "id": "0e06d836d428a19097f7480fbb42c16331bd5c555a4f2ef9024b0200edac6b60",
            "conversationId": "0e06d836d428a19097f7480fbb42c16331bd5c555a4f2ef9024b0200edac6b60",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Bleeding gums"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation",
            "startTime": "2021-09-24T11:36:08.028Z",
            "endTime": "2021-09-24T11:37:45.253Z",
            "timeSpent": 97,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi4AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi4AQAAAAAAAA==/",
            "_etag": "\"9f0244e4-0000-0100-0000-614dcbd10000\"",
            "_attachments": "attachments/",
            "_ts": 1632488401
        },
        {
            "id": "301a413c31d42137ee75b9b689e0722811abf272acc016179339941f52bd7dd2",
            "conversationId": "301a413c31d42137ee75b9b689e0722811abf272acc016179339941f52bd7dd2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-24T14:09:59.393Z",
            "endTime": "2021-09-24T14:10:05.43Z",
            "timeSpent": 6,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi5AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi5AQAAAAAAAA==/",
            "_etag": "\"a002c142-0000-0100-0000-614de7f30000\"",
            "_attachments": "attachments/",
            "_ts": 1632495603
        },
        {
            "id": "008158d060bd366c2cc8cb664cdaec11f9083de82f21f5ac1cb442dca7f3dcd9",
            "conversationId": "008158d060bd366c2cc8cb664cdaec11f9083de82f21f5ac1cb442dca7f3dcd9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T12:33:40.361Z",
            "endTime": "2021-09-27T12:33:40.361Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi6AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi6AQAAAAAAAA==/",
            "_etag": "\"3b013706-0000-0100-0000-6151ce670000\"",
            "_attachments": "attachments/",
            "_ts": 1632751207
        },
        {
            "id": "7f9cf70d7d3b64e40f9952097d8d33f95c537e458549b3265725d32eccaeb286",
            "conversationId": "7f9cf70d7d3b64e40f9952097d8d33f95c537e458549b3265725d32eccaeb286",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T13:49:06.085Z",
            "endTime": "2021-09-27T13:49:06.085Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi7AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi7AQAAAAAAAA==/",
            "_etag": "\"3b013806-0000-0100-0000-6151ce670000\"",
            "_attachments": "attachments/",
            "_ts": 1632751207
        },
        {
            "id": "e993d59984c65265df8a7e78d8e7704a6c6416df819ea45f458436b006cf1ee2",
            "conversationId": "e993d59984c65265df8a7e78d8e7704a6c6416df819ea45f458436b006cf1ee2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T14:19:59.419Z",
            "endTime": "2021-09-27T14:23:31.357Z",
            "timeSpent": 212,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi8AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi8AQAAAAAAAA==/",
            "_etag": "\"3f016123-0000-0100-0000-6151dc720000\"",
            "_attachments": "attachments/",
            "_ts": 1632754802
        },
        {
            "id": "fa602d07429cc6994c6b40288c95c18d69678ebfe56276cd6d4c3dbdd85c4bb3",
            "conversationId": "fa602d07429cc6994c6b40288c95c18d69678ebfe56276cd6d4c3dbdd85c4bb3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Nose pain",
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-27T14:35:17.192Z",
            "endTime": "2021-09-27T14:39:55.031Z",
            "timeSpent": 278,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi9AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi9AQAAAAAAAA==/",
            "_etag": "\"4301cb85-0000-0100-0000-6151ea820000\"",
            "_attachments": "attachments/",
            "_ts": 1632758402
        },
        {
            "id": "3f990416525e25857c1cda1d85d688fb2d1200dbd5439872be5186fd11176215",
            "conversationId": "3f990416525e25857c1cda1d85d688fb2d1200dbd5439872be5186fd11176215",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Nose pain",
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T14:07:46.826Z",
            "endTime": "2021-09-27T14:09:52.635Z",
            "timeSpent": 126,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi+AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi+AQAAAAAAAA==/",
            "_etag": "\"3f016323-0000-0100-0000-6151dc720000\"",
            "_attachments": "attachments/",
            "_ts": 1632754802
        },
        {
            "id": "7e0a34945fcaa9729f86629d5618c90adb57fa654588d6b5f66c97f3b72a0824",
            "conversationId": "7e0a34945fcaa9729f86629d5618c90adb57fa654588d6b5f66c97f3b72a0824",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-09-27T14:17:37.333Z",
            "endTime": "2021-09-27T14:19:44.184Z",
            "timeSpent": 127,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi-AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi-AQAAAAAAAA==/",
            "_etag": "\"3f016423-0000-0100-0000-6151dc720000\"",
            "_attachments": "attachments/",
            "_ts": 1632754802
        },
        {
            "id": "7ff86be544b0c07076986e4cfeff65e29b2dcb40b2b0ef6b71dfcc5aeebadd3a",
            "conversationId": "7ff86be544b0c07076986e4cfeff65e29b2dcb40b2b0ef6b71dfcc5aeebadd3a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Nose pain",
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-27T14:29:16.678Z",
            "endTime": "2021-09-27T14:33:40.821Z",
            "timeSpent": 264,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjAAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjAAQAAAAAAAA==/",
            "_etag": "\"4301ca85-0000-0100-0000-6151ea820000\"",
            "_attachments": "attachments/",
            "_ts": 1632758402
        },
        {
            "id": "0afe1a35272d8ab68988202ac1f7202cf67cb269109acf4aa9adca13ccf3b617",
            "conversationId": "0afe1a35272d8ab68988202ac1f7202cf67cb269109acf4aa9adca13ccf3b617",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-27T14:26:38.082Z",
            "endTime": "2021-09-27T14:28:04.768Z",
            "timeSpent": 87,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjBAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjBAQAAAAAAAA==/",
            "_etag": "\"3f016623-0000-0100-0000-6151dc720000\"",
            "_attachments": "attachments/",
            "_ts": 1632754802
        },
        {
            "id": "c97fee9615e362763abe86ed94e709641b8ef27c96f095fd4340797e9af4ad43",
            "conversationId": "c97fee9615e362763abe86ed94e709641b8ef27c96f095fd4340797e9af4ad43",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Nose pain",
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T14:28:27.354Z",
            "endTime": "2021-09-27T14:29:05.811Z",
            "timeSpent": 38,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjCAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjCAQAAAAAAAA==/",
            "_etag": "\"3f016723-0000-0100-0000-6151dc720000\"",
            "_attachments": "attachments/",
            "_ts": 1632754802
        },
        {
            "id": "59ec53fa1d9a37448f23758e8d0a73e91c646810248fd3cec41cf6778da6f346",
            "conversationId": "59ec53fa1d9a37448f23758e8d0a73e91c646810248fd3cec41cf6778da6f346",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T14:21:09.814Z",
            "endTime": "2021-09-27T14:23:11.91Z",
            "timeSpent": 122,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjDAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjDAQAAAAAAAA==/",
            "_etag": "\"3f016823-0000-0100-0000-6151dc720000\"",
            "_attachments": "attachments/",
            "_ts": 1632754802
        },
        {
            "id": "83f461212be725554eadecbad73fc5f91a9c8d87fde0f6396a2b7651b8c2b082",
            "conversationId": "83f461212be725554eadecbad73fc5f91a9c8d87fde0f6396a2b7651b8c2b082",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation",
            "startTime": "2021-09-27T14:17:18.34Z",
            "endTime": "2021-09-27T14:20:40.279Z",
            "timeSpent": 202,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 2,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjEAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjEAQAAAAAAAA==/",
            "_etag": "\"3f016923-0000-0100-0000-6151dc720000\"",
            "_attachments": "attachments/",
            "_ts": 1632754802
        },
        {
            "id": "4d673b97fd4ded6b565202790ef52af5689ef8850027943d6f86e8f690c41384",
            "conversationId": "4d673b97fd4ded6b565202790ef52af5689ef8850027943d6f86e8f690c41384",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-27T14:07:35.852Z",
            "endTime": "2021-09-27T14:08:31.861Z",
            "timeSpent": 56,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjFAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjFAQAAAAAAAA==/",
            "_etag": "\"3f016a23-0000-0100-0000-6151dc720000\"",
            "_attachments": "attachments/",
            "_ts": 1632754802
        },
        {
            "id": "4e935e3bacd83a2934d58bd91d9688a975902b51298dcdeb3d5ec851801634a9",
            "conversationId": "4e935e3bacd83a2934d58bd91d9688a975902b51298dcdeb3d5ec851801634a9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-27T16:24:43.5Z",
            "endTime": "2021-09-27T16:31:09.535Z",
            "timeSpent": 386,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjGAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjGAQAAAAAAAA==/",
            "_etag": "\"4701e1d1-0000-0100-0000-6151f8920000\"",
            "_attachments": "attachments/",
            "_ts": 1632762002
        },
        {
            "id": "9af2de3a1cb22c14a0532739b0e20b00086fae0f9509fe8e7fe0237554d67b04",
            "conversationId": "9af2de3a1cb22c14a0532739b0e20b00086fae0f9509fe8e7fe0237554d67b04",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T16:31:31.064Z",
            "endTime": "2021-09-27T16:31:31.064Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjHAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjHAQAAAAAAAA==/",
            "_etag": "\"4701e2d1-0000-0100-0000-6151f8920000\"",
            "_attachments": "attachments/",
            "_ts": 1632762002
        },
        {
            "id": "f9a3241871d9cafa742baa76461d9271cdca3fe258ed181a4bcb7d62c00f95d6",
            "conversationId": "f9a3241871d9cafa742baa76461d9271cdca3fe258ed181a4bcb7d62c00f95d6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T16:31:32.018Z",
            "endTime": "2021-09-27T16:33:09.74Z",
            "timeSpent": 98,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjIAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjIAQAAAAAAAA==/",
            "_etag": "\"4c01e142-0000-0100-0000-615206a70000\"",
            "_attachments": "attachments/",
            "_ts": 1632765607
        },
        {
            "id": "65d9ee6bc35861e6d2ac5b5d02bbb254e025f9fe724d4a92b980e912ada6e597",
            "conversationId": "65d9ee6bc35861e6d2ac5b5d02bbb254e025f9fe724d4a92b980e912ada6e597",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-27T16:48:45.378Z",
            "endTime": "2021-09-27T16:54:22.712Z",
            "timeSpent": 337,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjJAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjJAQAAAAAAAA==/",
            "_etag": "\"4701e4d1-0000-0100-0000-6151f8920000\"",
            "_attachments": "attachments/",
            "_ts": 1632762002
        },
        {
            "id": "cb1b8ad3bd0886a5c63679ca1f9a4e2b9f91aff37b4f9fa59c51372445fab11c",
            "conversationId": "cb1b8ad3bd0886a5c63679ca1f9a4e2b9f91aff37b4f9fa59c51372445fab11c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T16:58:25.933Z",
            "endTime": "2021-09-27T16:58:25.933Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjKAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjKAQAAAAAAAA==/",
            "_etag": "\"4c01e742-0000-0100-0000-615206a70000\"",
            "_attachments": "attachments/",
            "_ts": 1632765607
        },
        {
            "id": "c99203d9634e7f6c4798dad449ddfda87f5827cc40f0180ba456b6f0fb0a4bb9",
            "conversationId": "c99203d9634e7f6c4798dad449ddfda87f5827cc40f0180ba456b6f0fb0a4bb9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-27T16:58:27.786Z",
            "endTime": "2021-09-27T17:04:41.976Z",
            "timeSpent": 374,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjLAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjLAQAAAAAAAA==/",
            "_etag": "\"4c01e542-0000-0100-0000-615206a70000\"",
            "_attachments": "attachments/",
            "_ts": 1632765607
        },
        {
            "id": "19398c6441875925a1ab73fbb0a7d2775fa40e403da6065092cb7cbea826ec0e",
            "conversationId": "19398c6441875925a1ab73fbb0a7d2775fa40e403da6065092cb7cbea826ec0e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T16:34:18.902Z",
            "endTime": "2021-09-27T16:34:18.902Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjMAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjMAQAAAAAAAA==/",
            "_etag": "\"4701e7d1-0000-0100-0000-6151f8920000\"",
            "_attachments": "attachments/",
            "_ts": 1632762002
        },
        {
            "id": "859e4572cced18ca73a85200b7502d598d55802be92db43160cd57147e953c55",
            "conversationId": "859e4572cced18ca73a85200b7502d598d55802be92db43160cd57147e953c55",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-27T16:34:20.18Z",
            "endTime": "2021-09-27T16:45:40.398Z",
            "timeSpent": 680,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjNAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjNAQAAAAAAAA==/",
            "_etag": "\"4701e8d1-0000-0100-0000-6151f8920000\"",
            "_attachments": "attachments/",
            "_ts": 1632762002
        },
        {
            "id": "703f93cfd62e57620a9dadd5019735707376e048ed0147ee70096f8470ae54b9",
            "conversationId": "703f93cfd62e57620a9dadd5019735707376e048ed0147ee70096f8470ae54b9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T17:34:31.78Z",
            "endTime": "2021-09-27T17:34:38.525Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjOAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjOAQAAAAAAAA==/",
            "_etag": "\"50012694-0000-0100-0000-615214b30000\"",
            "_attachments": "attachments/",
            "_ts": 1632769203
        },
        {
            "id": "915d861f905f9d88184a47e5a6647216ae37a8a6a1bf4f4c640f7aaa6e0a78ff",
            "conversationId": "915d861f905f9d88184a47e5a6647216ae37a8a6a1bf4f4c640f7aaa6e0a78ff",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe joint pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-27T17:35:12.396Z",
            "endTime": "2021-09-27T17:53:57.935Z",
            "timeSpent": 1126,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjPAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjPAQAAAAAAAA==/",
            "_etag": "\"4c01e042-0000-0100-0000-615206a70000\"",
            "_attachments": "attachments/",
            "_ts": 1632765607
        },
        {
            "id": "69895c13891e4a826c06c44ab523ff7101b3915f17827026d50c89bc18b07dd2",
            "conversationId": "69895c13891e4a826c06c44ab523ff7101b3915f17827026d50c89bc18b07dd2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T17:29:47.94Z",
            "endTime": "2021-09-27T17:29:47.94Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjQAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjQAQAAAAAAAA==/",
            "_etag": "\"4c01e242-0000-0100-0000-615206a70000\"",
            "_attachments": "attachments/",
            "_ts": 1632765607
        },
        {
            "id": "16958f2f8bed1213f041e0d6cff1c4ce0ee12ca19a22ff263c677a4f8ab3b53f",
            "conversationId": "16958f2f8bed1213f041e0d6cff1c4ce0ee12ca19a22ff263c677a4f8ab3b53f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T17:30:38.322Z",
            "endTime": "2021-09-27T17:30:43.954Z",
            "timeSpent": 6,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjRAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjRAQAAAAAAAA==/",
            "_etag": "\"50012494-0000-0100-0000-615214b30000\"",
            "_attachments": "attachments/",
            "_ts": 1632769203
        },
        {
            "id": "c03f405a72f18ae6d1d32eb983cacf7ff0325826e3599e0ecbd8b65ea1464afb",
            "conversationId": "c03f405a72f18ae6d1d32eb983cacf7ff0325826e3599e0ecbd8b65ea1464afb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T17:32:37.522Z",
            "endTime": "2021-09-27T17:32:43.102Z",
            "timeSpent": 6,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjSAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjSAQAAAAAAAA==/",
            "_etag": "\"50012594-0000-0100-0000-615214b30000\"",
            "_attachments": "attachments/",
            "_ts": 1632769203
        },
        {
            "id": "aa444ccd5fe32ee161f73824adbce653074848c57756d5b32860ddb8203733fc",
            "conversationId": "aa444ccd5fe32ee161f73824adbce653074848c57756d5b32860ddb8203733fc",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T17:30:37.366Z",
            "endTime": "2021-09-27T17:30:37.366Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjTAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjTAQAAAAAAAA==/",
            "_etag": "\"50012394-0000-0100-0000-615214b30000\"",
            "_attachments": "attachments/",
            "_ts": 1632769203
        },
        {
            "id": "a67cf7dd813fa913a4d441c0c68abafb7bf25c700abc0f67a4dd41bd4cfaca8a",
            "conversationId": "a67cf7dd813fa913a4d441c0c68abafb7bf25c700abc0f67a4dd41bd4cfaca8a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T17:29:29.882Z",
            "endTime": "2021-09-27T17:29:29.882Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjUAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjUAQAAAAAAAA==/",
            "_etag": "\"4c01e842-0000-0100-0000-615206a70000\"",
            "_attachments": "attachments/",
            "_ts": 1632765607
        },
        {
            "id": "5e3901a6dd3633ce71ad7f00002f4bf633a896c5614bd7829eea71466855570c",
            "conversationId": "5e3901a6dd3633ce71ad7f00002f4bf633a896c5614bd7829eea71466855570c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-27T18:11:22.453Z",
            "endTime": "2021-09-27T18:17:17.01Z",
            "timeSpent": 355,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjVAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjVAQAAAAAAAA==/",
            "_etag": "\"50012194-0000-0100-0000-615214b30000\"",
            "_attachments": "attachments/",
            "_ts": 1632769203
        },
        {
            "id": "6235e7b908da9abb686a562a0a045cf80ace8b8e3a9d01955d755a4a225de41d",
            "conversationId": "6235e7b908da9abb686a562a0a045cf80ace8b8e3a9d01955d755a4a225de41d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-27T18:17:38.979Z",
            "endTime": "2021-09-27T18:22:35.496Z",
            "timeSpent": 297,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjWAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjWAQAAAAAAAA==/",
            "_etag": "\"50012294-0000-0100-0000-615214b30000\"",
            "_attachments": "attachments/",
            "_ts": 1632769203
        },
        {
            "id": "f4b660f73795992a5990bcb397eb302b46761fe92b143a1722893761614b9b1e",
            "conversationId": "f4b660f73795992a5990bcb397eb302b46761fe92b143a1722893761614b9b1e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe joint pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T18:36:41.654Z",
            "endTime": "2021-09-27T18:37:28.251Z",
            "timeSpent": 47,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjXAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjXAQAAAAAAAA==/",
            "_etag": "\"5401cee3-0000-0100-0000-615222c50000\"",
            "_attachments": "attachments/",
            "_ts": 1632772805
        },
        {
            "id": "a119ffb3e54112d7ceda4454561d2663b93f4edcb4c1966080e5e1f819323fd3",
            "conversationId": "a119ffb3e54112d7ceda4454561d2663b93f4edcb4c1966080e5e1f819323fd3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe joint pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-27T18:38:31.405Z",
            "endTime": "2021-09-27T18:41:37.526Z",
            "timeSpent": 186,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjYAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjYAQAAAAAAAA==/",
            "_etag": "\"5401d1e3-0000-0100-0000-615222c50000\"",
            "_attachments": "attachments/",
            "_ts": 1632772805
        },
        {
            "id": "e4869d1001e9caee0577733c805ad9f162c3642ab7446effe64d8a95bcf2b63b",
            "conversationId": "e4869d1001e9caee0577733c805ad9f162c3642ab7446effe64d8a95bcf2b63b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe joint pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-09-27T18:44:15.054Z",
            "endTime": "2021-09-27T19:04:46.134Z",
            "timeSpent": 1231,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 4,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjZAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjZAQAAAAAAAA==/",
            "_etag": "\"5401cde3-0000-0100-0000-615222c50000\"",
            "_attachments": "attachments/",
            "_ts": 1632772805
        },
        {
            "id": "41c700ad4032caf07249ea972c7854a8f5b53b2af241f34b5a28a8e649d4b27a",
            "conversationId": "41c700ad4032caf07249ea972c7854a8f5b53b2af241f34b5a28a8e649d4b27a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T18:17:53.056Z",
            "endTime": "2021-09-27T18:17:53.056Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjaAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjaAQAAAAAAAA==/",
            "_etag": "\"50012a94-0000-0100-0000-615214b30000\"",
            "_attachments": "attachments/",
            "_ts": 1632769203
        },
        {
            "id": "4482df580477ec26b19bd8ccd3dacf5f008b79c347876e3ab29a1f9d17f6344e",
            "conversationId": "4482df580477ec26b19bd8ccd3dacf5f008b79c347876e3ab29a1f9d17f6344e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T19:08:18.876Z",
            "endTime": "2021-09-27T19:08:18.876Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjbAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjbAQAAAAAAAA==/",
            "_etag": "\"5401c8e3-0000-0100-0000-615222c50000\"",
            "_attachments": "attachments/",
            "_ts": 1632772805
        },
        {
            "id": "879eaacbc35ba638a456553df22180a98576e23dfd2abb41f3293feb6e9e2d55",
            "conversationId": "879eaacbc35ba638a456553df22180a98576e23dfd2abb41f3293feb6e9e2d55",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Yes"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-27T19:10:01.714Z",
            "endTime": "2021-09-27T19:10:27.776Z",
            "timeSpent": 26,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjcAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjcAQAAAAAAAA==/",
            "_etag": "\"5401c9e3-0000-0100-0000-615222c50000\"",
            "_attachments": "attachments/",
            "_ts": 1632772805
        },
        {
            "id": "b8c82f7cd42267679b39cece4fb54fb2b22543412abba7d099a0d77e1acea889",
            "conversationId": "b8c82f7cd42267679b39cece4fb54fb2b22543412abba7d099a0d77e1acea889",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T19:12:29.364Z",
            "endTime": "2021-09-27T19:12:36.004Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjdAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjdAQAAAAAAAA==/",
            "_etag": "\"5401cae3-0000-0100-0000-615222c50000\"",
            "_attachments": "attachments/",
            "_ts": 1632772805
        },
        {
            "id": "dad5dd72c8d339196ef73bc447b406645aaba1a26ef8b567ea1748299867a08d",
            "conversationId": "dad5dd72c8d339196ef73bc447b406645aaba1a26ef8b567ea1748299867a08d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T19:48:30.798Z",
            "endTime": "2021-09-27T19:48:30.798Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjeAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjeAQAAAAAAAA==/",
            "_etag": "\"57014cd7-0000-0100-0000-615230d20000\"",
            "_attachments": "attachments/",
            "_ts": 1632776402
        },
        {
            "id": "4402e554c8b7a96bfce8f9288718f247bab841cda80368008a99ff866071823f",
            "conversationId": "4402e554c8b7a96bfce8f9288718f247bab841cda80368008a99ff866071823f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 88,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation",
            "startTime": "2021-09-27T19:49:17.729Z",
            "endTime": "2021-09-27T20:13:55.171Z",
            "timeSpent": 1477,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjfAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjfAQAAAAAAAA==/",
            "_etag": "\"570149d7-0000-0100-0000-615230d20000\"",
            "_attachments": "attachments/",
            "_ts": 1632776402
        },
        {
            "id": "e58733ae02b3b5c0a0eb81cc2d2b11752bbedb44c8154c55a70594704264171e",
            "conversationId": "e58733ae02b3b5c0a0eb81cc2d2b11752bbedb44c8154c55a70594704264171e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Vaginal bleeding during pregnancy"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-27T19:08:19.851Z",
            "endTime": "2021-09-27T19:09:05.046Z",
            "timeSpent": 45,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjgAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjgAQAAAAAAAA==/",
            "_etag": "\"5401cfe3-0000-0100-0000-615222c50000\"",
            "_attachments": "attachments/",
            "_ts": 1632772805
        },
        {
            "id": "126121fb4f02e9d57e2e41cf1572c25231cb3d30ec5134df9c4677f44002693c",
            "conversationId": "126121fb4f02e9d57e2e41cf1572c25231cb3d30ec5134df9c4677f44002693c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T19:10:01.831Z",
            "endTime": "2021-09-27T19:10:01.831Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjhAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjhAQAAAAAAAA==/",
            "_etag": "\"5401d0e3-0000-0100-0000-615222c50000\"",
            "_attachments": "attachments/",
            "_ts": 1632772805
        },
        {
            "id": "c32914a01fb151b6a5c77ebcc840d080cde6702daa8990591e370e453be34739",
            "conversationId": "c32914a01fb151b6a5c77ebcc840d080cde6702daa8990591e370e453be34739",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 88,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T20:10:55.773Z",
            "endTime": "2021-09-27T20:10:55.773Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjiAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjiAQAAAAAAAA==/",
            "_etag": "\"57014ad7-0000-0100-0000-615230d20000\"",
            "_attachments": "attachments/",
            "_ts": 1632776402
        },
        {
            "id": "2273aabc8b9abfdf421a367fd168906728b007c699405c0e88867e9c220a6eef",
            "conversationId": "2273aabc8b9abfdf421a367fd168906728b007c699405c0e88867e9c220a6eef",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 88,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-27T20:35:38.912Z",
            "endTime": "2021-09-27T20:37:27.423Z",
            "timeSpent": 109,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjjAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjjAQAAAAAAAA==/",
            "_etag": "\"57014bd7-0000-0100-0000-615230d20000\"",
            "_attachments": "attachments/",
            "_ts": 1632776402
        },
        {
            "id": "fbdd4cefe0a025d00e5620e701e3df7c7606d0690674073dfb741c36e8a39f59",
            "conversationId": "fbdd4cefe0a025d00e5620e701e3df7c7606d0690674073dfb741c36e8a39f59",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation",
            "startTime": "2021-09-27T21:16:46.643Z",
            "endTime": "2021-09-27T21:28:53.79Z",
            "timeSpent": 727,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjkAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjkAQAAAAAAAA==/",
            "_etag": "\"5c015c22-0000-0100-0000-61523ee20000\"",
            "_attachments": "attachments/",
            "_ts": 1632780002
        },
        {
            "id": "31a4921aa3935af065bc1104874a85346850a7cc9900c9782eb96bc079006e0d",
            "conversationId": "31a4921aa3935af065bc1104874a85346850a7cc9900c9782eb96bc079006e0d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 88,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-28T03:50:48.43Z",
            "endTime": "2021-09-28T03:55:37.47Z",
            "timeSpent": 289,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjlAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjlAQAAAAAAAA==/",
            "_etag": "\"7901ee4d-0000-0100-0000-6152a1510000\"",
            "_attachments": "attachments/",
            "_ts": 1632805201
        },
        {
            "id": "bb200007e797d779629ef8e79591c173c0e202bb451601724e426d039fe38f0e",
            "conversationId": "bb200007e797d779629ef8e79591c173c0e202bb451601724e426d039fe38f0e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T03:37:05.076Z",
            "endTime": "2021-09-28T03:37:34.608Z",
            "timeSpent": 30,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjmAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjmAQAAAAAAAA==/",
            "_etag": "\"7901eb4d-0000-0100-0000-6152a1510000\"",
            "_attachments": "attachments/",
            "_ts": 1632805201
        },
        {
            "id": "a138c387cf3db36a3e0d485c5ff3ff65c971bbbc0105ce61a028f28778b17209",
            "conversationId": "a138c387cf3db36a3e0d485c5ff3ff65c971bbbc0105ce61a028f28778b17209",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 88,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-28T04:11:32.477Z",
            "endTime": "2021-09-28T04:17:17.171Z",
            "timeSpent": 345,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjnAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjnAQAAAAAAAA==/",
            "_etag": "\"7901ec4d-0000-0100-0000-6152a1510000\"",
            "_attachments": "attachments/",
            "_ts": 1632805201
        },
        {
            "id": "077a08ab805b29651e95ab2183381c0e76806b1c179531519392cffacc348aa1",
            "conversationId": "077a08ab805b29651e95ab2183381c0e76806b1c179531519392cffacc348aa1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T04:11:28.626Z",
            "endTime": "2021-09-28T04:11:28.626Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjoAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjoAQAAAAAAAA==/",
            "_etag": "\"7901ed4d-0000-0100-0000-6152a1510000\"",
            "_attachments": "attachments/",
            "_ts": 1632805201
        },
        {
            "id": "59dd64f945ff4382c777fed850acb522df8d01b381c1d23762e3e97432eabe82",
            "conversationId": "59dd64f945ff4382c777fed850acb522df8d01b381c1d23762e3e97432eabe82",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain",
                "Headache"
            ],
            "riskFactors": [
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation",
            "startTime": "2021-09-28T08:56:57.957Z",
            "endTime": "2021-09-28T09:00:45.279Z",
            "timeSpent": 227,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjpAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjpAQAAAAAAAA==/",
            "_etag": "\"8d010c46-0000-0100-0000-615306910000\"",
            "_attachments": "attachments/",
            "_ts": 1632831121
        },
        {
            "id": "f3519f7afbcf65059949a8c551a0c49f2eeb9e662735b4a93070778ba5c97d7e",
            "conversationId": "f3519f7afbcf65059949a8c551a0c49f2eeb9e662735b4a93070778ba5c97d7e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-28T09:01:30.389Z",
            "endTime": "2021-09-28T09:02:45.731Z",
            "timeSpent": 75,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjqAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjqAQAAAAAAAA==/",
            "_etag": "\"8d010d46-0000-0100-0000-615306910000\"",
            "_attachments": "attachments/",
            "_ts": 1632831121
        },
        {
            "id": "0819bee09bb15c50baa927a2166fe3fd97dc4c51b8bd088e2e40a078dd9cb727",
            "conversationId": "0819bee09bb15c50baa927a2166fe3fd97dc4c51b8bd088e2e40a078dd9cb727",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Knee injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-28T09:04:28.372Z",
            "endTime": "2021-09-28T09:05:13.548Z",
            "timeSpent": 45,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjrAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjrAQAAAAAAAA==/",
            "_etag": "\"8d010e46-0000-0100-0000-615306910000\"",
            "_attachments": "attachments/",
            "_ts": 1632831121
        },
        {
            "id": "f45831d3263a2bc68ae80177255599a84554926f3acdc086c377c0f2a68e3b41",
            "conversationId": "f45831d3263a2bc68ae80177255599a84554926f3acdc086c377c0f2a68e3b41",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-28T11:54:34.64Z",
            "endTime": "2021-09-28T12:00:57.936Z",
            "timeSpent": 383,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjsAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjsAQAAAAAAAA==/",
            "_etag": "\"8d01e959-0000-0100-0000-615311d70000\"",
            "_attachments": "attachments/",
            "_ts": 1632834007
        },
        {
            "id": "d60d460814196e61a3206104a25ddf41888951a1f4e2153d8ba7929c16d63321",
            "conversationId": "d60d460814196e61a3206104a25ddf41888951a1f4e2153d8ba7929c16d63321",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-28T11:56:44.036Z",
            "endTime": "2021-09-28T12:08:48.912Z",
            "timeSpent": 725,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjtAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjtAQAAAAAAAA==/",
            "_etag": "\"8d01eb59-0000-0100-0000-615311d70000\"",
            "_attachments": "attachments/",
            "_ts": 1632834007
        },
        {
            "id": "0d65ce0d959e405ce60b84e09a908640f35bd07fba37ada28ed5b16921239d3d",
            "conversationId": "0d65ce0d959e405ce60b84e09a908640f35bd07fba37ada28ed5b16921239d3d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T11:40:06.412Z",
            "endTime": "2021-09-28T11:47:48.755Z",
            "timeSpent": 462,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjuAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjuAQAAAAAAAA==/",
            "_etag": "\"8d01e359-0000-0100-0000-615311d70000\"",
            "_attachments": "attachments/",
            "_ts": 1632834007
        },
        {
            "id": "048c5a6d73b02d58775b65ce5801ea53c126b67818d831b270b5fb6b816e9479",
            "conversationId": "048c5a6d73b02d58775b65ce5801ea53c126b67818d831b270b5fb6b816e9479",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation",
            "startTime": "2021-09-28T11:58:44.773Z",
            "endTime": "2021-09-28T12:02:04.204Z",
            "timeSpent": 199,
            "isNoteGenerated": "no",
            "npsGroup": "Passive",
            "npsScore": 8,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjvAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjvAQAAAAAAAA==/",
            "_etag": "\"8d01ea59-0000-0100-0000-615311d70000\"",
            "_attachments": "attachments/",
            "_ts": 1632834007
        },
        {
            "id": "08a03e3bd2dfe8abb76e0bda78f0475e968b715ad83f72c0e6f7743b13500978",
            "conversationId": "08a03e3bd2dfe8abb76e0bda78f0475e968b715ad83f72c0e6f7743b13500978",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T11:52:34.807Z",
            "endTime": "2021-09-28T11:53:45.461Z",
            "timeSpent": 71,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjwAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjwAQAAAAAAAA==/",
            "_etag": "\"8d01e459-0000-0100-0000-615311d70000\"",
            "_attachments": "attachments/",
            "_ts": 1632834007
        },
        {
            "id": "4b3240408dbe100b0f36b66b3d6c2b5831aac2a568550764ce2c15d14cfea794",
            "conversationId": "4b3240408dbe100b0f36b66b3d6c2b5831aac2a568550764ce2c15d14cfea794",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-28T12:02:18.901Z",
            "endTime": "2021-09-28T12:04:51.826Z",
            "timeSpent": 153,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjxAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjxAQAAAAAAAA==/",
            "_etag": "\"8d01ec59-0000-0100-0000-615311d70000\"",
            "_attachments": "attachments/",
            "_ts": 1632834007
        },
        {
            "id": "9f59c3ef555b6183f52904b78c224368ba66db8242d6fdbd2bc2570bca2ce572",
            "conversationId": "9f59c3ef555b6183f52904b78c224368ba66db8242d6fdbd2bc2570bca2ce572",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Mild headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-09-28T12:35:17.337Z",
            "endTime": "2021-09-28T12:40:24.64Z",
            "timeSpent": 307,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjyAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjyAQAAAAAAAA==/",
            "_etag": "\"8d01e073-0000-0100-0000-61531fe20000\"",
            "_attachments": "attachments/",
            "_ts": 1632837602
        },
        {
            "id": "ba77893894b19de1269ec8aa0dde7be171f17ab1b7b91afccf0b25c769ed3d1d",
            "conversationId": "ba77893894b19de1269ec8aa0dde7be171f17ab1b7b91afccf0b25c769ed3d1d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Mild headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-09-28T12:37:19.568Z",
            "endTime": "2021-09-28T12:43:04.929Z",
            "timeSpent": 345,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjzAQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjzAQAAAAAAAA==/",
            "_etag": "\"8d01e873-0000-0100-0000-61531fe20000\"",
            "_attachments": "attachments/",
            "_ts": 1632837602
        },
        {
            "id": "8f81ffc4484c0531f49078a5e328f15dca566d146db1387ec2ac6c929bf17450",
            "conversationId": "8f81ffc4484c0531f49078a5e328f15dca566d146db1387ec2ac6c929bf17450",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation",
            "startTime": "2021-09-28T12:48:21.615Z",
            "endTime": "2021-09-28T12:50:42.957Z",
            "timeSpent": 141,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj0AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj0AQAAAAAAAA==/",
            "_etag": "\"8d01e759-0000-0100-0000-615311d70000\"",
            "_attachments": "attachments/",
            "_ts": 1632834007
        },
        {
            "id": "7342ec1124ae035fbba7718c3b1a063c2c5b4ec6f8508763f80869cb7e0c9871",
            "conversationId": "7342ec1124ae035fbba7718c3b1a063c2c5b4ec6f8508763f80869cb7e0c9871",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T12:54:17.673Z",
            "endTime": "2021-09-28T12:54:31.667Z",
            "timeSpent": 14,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj1AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj1AQAAAAAAAA==/",
            "_etag": "\"8d01ea73-0000-0100-0000-61531fe20000\"",
            "_attachments": "attachments/",
            "_ts": 1632837602
        },
        {
            "id": "eb8eafe00ba5efaad4c5c6e81c1a2289ebd60f12ecf728a3135555a5806b735f",
            "conversationId": "eb8eafe00ba5efaad4c5c6e81c1a2289ebd60f12ecf728a3135555a5806b735f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T12:32:34.903Z",
            "endTime": "2021-09-28T12:33:17.366Z",
            "timeSpent": 42,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj2AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj2AQAAAAAAAA==/",
            "_etag": "\"8d01df73-0000-0100-0000-61531fe20000\"",
            "_attachments": "attachments/",
            "_ts": 1632837602
        },
        {
            "id": "90066c60f0d20981b5fde0379d6f7db16c255cfa463ac9b71c24b9af52e36f10",
            "conversationId": "90066c60f0d20981b5fde0379d6f7db16c255cfa463ac9b71c24b9af52e36f10",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T12:44:29.901Z",
            "endTime": "2021-09-28T12:44:29.901Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj3AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj3AQAAAAAAAA==/",
            "_etag": "\"8d01ee59-0000-0100-0000-615311d70000\"",
            "_attachments": "attachments/",
            "_ts": 1632834007
        },
        {
            "id": "f7ae07941a41978d432d565d9a125f44f51c39b4acb0a44178c4b277a611c0e8",
            "conversationId": "f7ae07941a41978d432d565d9a125f44f51c39b4acb0a44178c4b277a611c0e8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T12:44:32.464Z",
            "endTime": "2021-09-28T12:44:32.464Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj4AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj4AQAAAAAAAA==/",
            "_etag": "\"8d01ef59-0000-0100-0000-615311d70000\"",
            "_attachments": "attachments/",
            "_ts": 1632834007
        },
        {
            "id": "ee9fceb04891b9ef154c57703d66292c21e47ea2493422275fbd613ebc0b7577",
            "conversationId": "ee9fceb04891b9ef154c57703d66292c21e47ea2493422275fbd613ebc0b7577",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T13:37:25.213Z",
            "endTime": "2021-09-28T13:37:25.213Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj5AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj5AQAAAAAAAA==/",
            "_etag": "\"8d01e173-0000-0100-0000-61531fe20000\"",
            "_attachments": "attachments/",
            "_ts": 1632837602
        },
        {
            "id": "0484592b02c1f27a3a015f8ef403c9f1920b9fdf1c167bcc484aec2f13fcc8c1",
            "conversationId": "0484592b02c1f27a3a015f8ef403c9f1920b9fdf1c167bcc484aec2f13fcc8c1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-28T13:38:14.584Z",
            "endTime": "2021-09-28T13:39:17.595Z",
            "timeSpent": 63,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj6AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj6AQAAAAAAAA==/",
            "_etag": "\"8d01e273-0000-0100-0000-61531fe20000\"",
            "_attachments": "attachments/",
            "_ts": 1632837602
        },
        {
            "id": "df4f828cfc9011dbb25746661550168ea2d1053d85cc3eafcb6a39df8a8f40bb",
            "conversationId": "df4f828cfc9011dbb25746661550168ea2d1053d85cc3eafcb6a39df8a8f40bb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Sinus pain or pressure"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-28T13:42:06.677Z",
            "endTime": "2021-09-28T13:45:25.67Z",
            "timeSpent": 199,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj7AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj7AQAAAAAAAA==/",
            "_etag": "\"8d0190a3-0000-0100-0000-61532df20000\"",
            "_attachments": "attachments/",
            "_ts": 1632841202
        },
        {
            "id": "54d90995ea15189e6de60a0407e737b182649569c170780e88ae285d22215dda",
            "conversationId": "54d90995ea15189e6de60a0407e737b182649569c170780e88ae285d22215dda",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T13:43:55.839Z",
            "endTime": "2021-09-28T14:02:56.733Z",
            "timeSpent": 1141,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj8AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj8AQAAAAAAAA==/",
            "_etag": "\"8d018fa3-0000-0100-0000-61532df20000\"",
            "_attachments": "attachments/",
            "_ts": 1632841202
        },
        {
            "id": "58b09ac5a614546fe469c6fa1dfb85762e7d9cc815a8ce8e1c4ed30eb825c9d8",
            "conversationId": "58b09ac5a614546fe469c6fa1dfb85762e7d9cc815a8ce8e1c4ed30eb825c9d8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Toe pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-28T13:56:44.799Z",
            "endTime": "2021-09-28T13:58:46.604Z",
            "timeSpent": 122,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj9AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj9AQAAAAAAAA==/",
            "_etag": "\"8d0192a3-0000-0100-0000-61532df20000\"",
            "_attachments": "attachments/",
            "_ts": 1632841202
        },
        {
            "id": "aa8eac3cd77a3d632e5e2263c5330940d0512e35f3ee06bbff49ddc03e8ba04c",
            "conversationId": "aa8eac3cd77a3d632e5e2263c5330940d0512e35f3ee06bbff49ddc03e8ba04c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Toe pain",
                "Foot tingling or numbness",
                "Foot pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-28T13:53:03.639Z",
            "endTime": "2021-09-28T13:54:43.653Z",
            "timeSpent": 100,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj+AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj+AQAAAAAAAA==/",
            "_etag": "\"8d018ea3-0000-0100-0000-61532df20000\"",
            "_attachments": "attachments/",
            "_ts": 1632841202
        },
        {
            "id": "aead8ed73ff23b7dedaf9805f900504bd2f559584d7f0d7f30b46853873b9710",
            "conversationId": "aead8ed73ff23b7dedaf9805f900504bd2f559584d7f0d7f30b46853873b9710",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Toe pain",
                "Foot tingling or numbness",
                "Foot pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-28T13:54:52.468Z",
            "endTime": "2021-09-28T13:55:34.686Z",
            "timeSpent": 42,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj-AQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj-AQAAAAAAAA==/",
            "_etag": "\"8d0191a3-0000-0100-0000-61532df20000\"",
            "_attachments": "attachments/",
            "_ts": 1632841202
        },
        {
            "id": "80345e5ae775a074aae677aff41bc7c82acdd858692a0a6ce9e6b8b029cbd7c5",
            "conversationId": "80345e5ae775a074aae677aff41bc7c82acdd858692a0a6ce9e6b8b029cbd7c5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-28T13:20:43.474Z",
            "endTime": "2021-09-28T13:21:59.781Z",
            "timeSpent": 76,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgAAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgAAgAAAAAAAA==/",
            "_etag": "\"8d01e973-0000-0100-0000-61531fe20000\"",
            "_attachments": "attachments/",
            "_ts": 1632837602
        },
        {
            "id": "87b1b842a081b84039c6e93491222d37abd9ef023da489bdb211924b46fa2665",
            "conversationId": "87b1b842a081b84039c6e93491222d37abd9ef023da489bdb211924b46fa2665",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T13:43:51.882Z",
            "endTime": "2021-09-28T13:43:51.882Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgBAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgBAgAAAAAAAA==/",
            "_etag": "\"8d018da3-0000-0100-0000-61532df20000\"",
            "_attachments": "attachments/",
            "_ts": 1632841202
        },
        {
            "id": "ffd4be56b81523e1b6855edf678fb825e30ca3963130d4cb4509d3a6fb3cb383",
            "conversationId": "ffd4be56b81523e1b6855edf678fb825e30ca3963130d4cb4509d3a6fb3cb383",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Sinus pain or pressure"
            ],
            "riskFactors": [
                "Hypertension",
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-09-28T14:11:32.17Z",
            "endTime": "2021-09-28T14:20:43.497Z",
            "timeSpent": 551,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgCAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgCAgAAAAAAAA==/",
            "_etag": "\"8d018ba3-0000-0100-0000-61532df20000\"",
            "_attachments": "attachments/",
            "_ts": 1632841202
        },
        {
            "id": "c4a858280efb6e7a5b4b8b254ebcd90890a73d7b3dcc00a9736cfa3e0b06ce75",
            "conversationId": "c4a858280efb6e7a5b4b8b254ebcd90890a73d7b3dcc00a9736cfa3e0b06ce75",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-09-28T14:05:48.3Z",
            "endTime": "2021-09-28T14:23:06.187Z",
            "timeSpent": 1038,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgDAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgDAgAAAAAAAA==/",
            "_etag": "\"8d018ca3-0000-0100-0000-61532df20000\"",
            "_attachments": "attachments/",
            "_ts": 1632841202
        },
        {
            "id": "4447467f942ff480477752f6ca4855a2a6baeeab37d8a3c2c8ff0156a3505d9e",
            "conversationId": "4447467f942ff480477752f6ca4855a2a6baeeab37d8a3c2c8ff0156a3505d9e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sinus pain or pressure"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T15:57:36.079Z",
            "endTime": "2021-09-28T15:58:20.848Z",
            "timeSpent": 45,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgEAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgEAgAAAAAAAA==/",
            "_etag": "\"8d01bfb4-0000-0100-0000-61534a120000\"",
            "_attachments": "attachments/",
            "_ts": 1632848402
        },
        {
            "id": "bda354d95576388efdd5c3d6a35cbf65695bd1ebe030477bc353f57be19c4c08",
            "conversationId": "bda354d95576388efdd5c3d6a35cbf65695bd1ebe030477bc353f57be19c4c08",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T15:56:56.761Z",
            "endTime": "2021-09-28T15:56:56.761Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgFAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgFAgAAAAAAAA==/",
            "_etag": "\"8d010aaf-0000-0100-0000-61533c020000\"",
            "_attachments": "attachments/",
            "_ts": 1632844802
        },
        {
            "id": "5e99efb480255af38d0361b41d389851a99ba20a0d4947b1d667a1ce0f34450b",
            "conversationId": "5e99efb480255af38d0361b41d389851a99ba20a0d4947b1d667a1ce0f34450b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T15:47:33.67Z",
            "endTime": "2021-09-28T15:47:36.324Z",
            "timeSpent": 3,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgGAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgGAgAAAAAAAA==/",
            "_etag": "\"8d01beb4-0000-0100-0000-61534a120000\"",
            "_attachments": "attachments/",
            "_ts": 1632848402
        },
        {
            "id": "f40860afcd622a6c8e3d1592d07c1c386b2e7aab226727216adac056faaff6b8",
            "conversationId": "f40860afcd622a6c8e3d1592d07c1c386b2e7aab226727216adac056faaff6b8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Sinus pain or pressure"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T16:02:22.956Z",
            "endTime": "2021-09-28T16:15:03.599Z",
            "timeSpent": 761,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgHAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgHAgAAAAAAAA==/",
            "_etag": "\"8d01bcb4-0000-0100-0000-61534a120000\"",
            "_attachments": "attachments/",
            "_ts": 1632848402
        },
    

    
        {
            "id": "f4c9c2f9021b50842b9e35763e29b55aa89e673db6aa4319bb0d61beaa7a9475",
            "conversationId": "f4c9c2f9021b50842b9e35763e29b55aa89e673db6aa4319bb0d61beaa7a9475",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-09-28T16:05:01.554Z",
            "endTime": "2021-09-28T16:13:06.578Z",
            "timeSpent": 485,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgIAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgIAgAAAAAAAA==/",
            "_etag": "\"8d01bdb4-0000-0100-0000-61534a120000\"",
            "_attachments": "attachments/",
            "_ts": 1632848402
        },
        {
            "id": "66db28249856501c62fc7a82b5c425c5f7b80d2448698b82eacac08217840925",
            "conversationId": "66db28249856501c62fc7a82b5c425c5f7b80d2448698b82eacac08217840925",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T16:42:27.236Z",
            "endTime": "2021-09-28T16:42:27.236Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgJAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgJAgAAAAAAAA==/",
            "_etag": "\"8d011bfc-0000-0100-0000-615358250000\"",
            "_attachments": "attachments/",
            "_ts": 1632852005
        },
        {
            "id": "22c5844f0ef9dec5466943a6ffa2aa7f48dc61e7bceea3a183a694f141c85062",
            "conversationId": "22c5844f0ef9dec5466943a6ffa2aa7f48dc61e7bceea3a183a694f141c85062",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T16:42:28.151Z",
            "endTime": "2021-09-28T16:42:28.151Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgKAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgKAgAAAAAAAA==/",
            "_etag": "\"8d011cfc-0000-0100-0000-615358250000\"",
            "_attachments": "attachments/",
            "_ts": 1632852005
        },
        {
            "id": "1862b1e2c90bbfbd317098eefe93a5d60df8f38b40bd136307d089154dd173b5",
            "conversationId": "1862b1e2c90bbfbd317098eefe93a5d60df8f38b40bd136307d089154dd173b5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-28T16:42:30.359Z",
            "endTime": "2021-09-28T16:42:37.907Z",
            "timeSpent": 8,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgLAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgLAgAAAAAAAA==/",
            "_etag": "\"8d01c2b4-0000-0100-0000-61534a120000\"",
            "_attachments": "attachments/",
            "_ts": 1632848402
        },
        {
            "id": "d2153ad9ae154481490cc99bbd808c43374520e416f363fb1900c9fa7792155d",
            "conversationId": "d2153ad9ae154481490cc99bbd808c43374520e416f363fb1900c9fa7792155d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sinus pain or pressure"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation",
            "startTime": "2021-09-28T17:04:23.537Z",
            "endTime": "2021-09-28T17:15:27.539Z",
            "timeSpent": 664,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgMAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgMAgAAAAAAAA==/",
            "_etag": "\"8d0118fc-0000-0100-0000-615358250000\"",
            "_attachments": "attachments/",
            "_ts": 1632852005
        },
        {
            "id": "19b189dc1b850ef72fc26e84f9ed5c7216c8b2e5fb0e6513941572580297261d",
            "conversationId": "19b189dc1b850ef72fc26e84f9ed5c7216c8b2e5fb0e6513941572580297261d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Sinus pain or pressure"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T17:05:27.19Z",
            "endTime": "2021-09-28T17:07:42.712Z",
            "timeSpent": 136,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgNAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgNAgAAAAAAAA==/",
            "_etag": "\"8d0119fc-0000-0100-0000-615358250000\"",
            "_attachments": "attachments/",
            "_ts": 1632852005
        },
        {
            "id": "bf9142fcaa96e4c9276d9d5ff0c8355443dc9d370e5ade0d85838adff0b4cc5b",
            "conversationId": "bf9142fcaa96e4c9276d9d5ff0c8355443dc9d370e5ade0d85838adff0b4cc5b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sinus pain or pressure",
                "Mucus dripping at the back of the throat"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-09-28T17:17:06.286Z",
            "endTime": "2021-09-28T17:27:57.99Z",
            "timeSpent": 652,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgOAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgOAgAAAAAAAA==/",
            "_etag": "\"8d011afc-0000-0100-0000-615358250000\"",
            "_attachments": "attachments/",
            "_ts": 1632852005
        },
        {
            "id": "f7fe10fe3d7456eeb31737a0539db6730550e53adb2b1803ed88264da64c950c",
            "conversationId": "f7fe10fe3d7456eeb31737a0539db6730550e53adb2b1803ed88264da64c950c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sinus pain or pressure",
                "Mucus dripping at the back of the throat"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-09-28T17:39:48.222Z",
            "endTime": "2021-09-28T17:44:51.725Z",
            "timeSpent": 304,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgPAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgPAgAAAAAAAA==/",
            "_etag": "\"8d011dfc-0000-0100-0000-615358250000\"",
            "_attachments": "attachments/",
            "_ts": 1632852005
        },
        {
            "id": "c99daaef23814af9308f2f6aa6d6f261d91514b68494a93e247c42d043552f58",
            "conversationId": "c99daaef23814af9308f2f6aa6d6f261d91514b68494a93e247c42d043552f58",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T18:04:51.12Z",
            "endTime": "2021-09-28T18:04:51.12Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgQAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgQAgAAAAAAAA==/",
            "_etag": "\"8e018641-0000-0100-0000-615366330000\"",
            "_attachments": "attachments/",
            "_ts": 1632855603
        },
        {
            "id": "51c6e2c5fcbc76e4f402fdf4b160205ebde84f07b7bb5c859ed5265d0a6aa9b3",
            "conversationId": "51c6e2c5fcbc76e4f402fdf4b160205ebde84f07b7bb5c859ed5265d0a6aa9b3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-09-28T18:04:52.697Z",
            "endTime": "2021-09-28T18:05:38.182Z",
            "timeSpent": 45,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgRAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgRAgAAAAAAAA==/",
            "_etag": "\"8e018741-0000-0100-0000-615366330000\"",
            "_attachments": "attachments/",
            "_ts": 1632855603
        },
        {
            "id": "de8835d76ed1936967abe57147896ef6265544d5f855d2450d4f03da0fa9cbed",
            "conversationId": "de8835d76ed1936967abe57147896ef6265544d5f855d2450d4f03da0fa9cbed",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T18:31:09.013Z",
            "endTime": "2021-09-28T18:31:09.013Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgSAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgSAgAAAAAAAA==/",
            "_etag": "\"8e018841-0000-0100-0000-615366330000\"",
            "_attachments": "attachments/",
            "_ts": 1632855603
        },
        {
            "id": "48d4f0fabec7d1b339a11db68e2f6911361b01dbedaf7e6ddecf4dba81904fa4",
            "conversationId": "48d4f0fabec7d1b339a11db68e2f6911361b01dbedaf7e6ddecf4dba81904fa4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-28T19:02:19.726Z",
            "endTime": "2021-09-28T19:06:00.586Z",
            "timeSpent": 221,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgTAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgTAgAAAAAAAA==/",
            "_etag": "\"8e01d05a-0000-0100-0000-615374420000\"",
            "_attachments": "attachments/",
            "_ts": 1632859202
        },
        {
            "id": "9b7869d5f42309dce637806777c9e8f75f2b8cb3a07080011f566371d227fdec",
            "conversationId": "9b7869d5f42309dce637806777c9e8f75f2b8cb3a07080011f566371d227fdec",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T19:27:58.294Z",
            "endTime": "2021-09-28T19:27:58.294Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgUAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgUAgAAAAAAAA==/",
            "_etag": "\"8e01d15a-0000-0100-0000-615374420000\"",
            "_attachments": "attachments/",
            "_ts": 1632859202
        },
        {
            "id": "d0facc95539c9fab2ff3a89618ea4dc285e4da60516b162cb462e60f74934b52",
            "conversationId": "d0facc95539c9fab2ff3a89618ea4dc285e4da60516b162cb462e60f74934b52",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T19:27:59.873Z",
            "endTime": "2021-09-28T19:27:59.873Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgVAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgVAgAAAAAAAA==/",
            "_etag": "\"8e01d25a-0000-0100-0000-615374420000\"",
            "_attachments": "attachments/",
            "_ts": 1632859202
        },
        {
            "id": "13d1e5a0f1edaaf56259d36eb8c628df66683a7b45d6b021a229d91ad73c7c17",
            "conversationId": "13d1e5a0f1edaaf56259d36eb8c628df66683a7b45d6b021a229d91ad73c7c17",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-28T19:03:13.147Z",
            "endTime": "2021-09-28T19:06:04.035Z",
            "timeSpent": 171,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgWAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgWAgAAAAAAAA==/",
            "_etag": "\"8e01d35a-0000-0100-0000-615374420000\"",
            "_attachments": "attachments/",
            "_ts": 1632859202
        },
        {
            "id": "d889da40495cfbc682e38e7303effc58f1287dbd3290dd39869f91e4bc167fef",
            "conversationId": "d889da40495cfbc682e38e7303effc58f1287dbd3290dd39869f91e4bc167fef",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-28T19:28:02.326Z",
            "endTime": "2021-09-28T19:28:23.882Z",
            "timeSpent": 22,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgXAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgXAgAAAAAAAA==/",
            "_etag": "\"8e01d45a-0000-0100-0000-615374420000\"",
            "_attachments": "attachments/",
            "_ts": 1632859202
        },
        {
            "id": "3d5b33ca453a247ceeec042d646318bb64e10f6ee62b69a505d4faaaa80cb87a",
            "conversationId": "3d5b33ca453a247ceeec042d646318bb64e10f6ee62b69a505d4faaaa80cb87a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-09-29T12:40:34.614Z",
            "endTime": "2021-09-29T12:43:33.508Z",
            "timeSpent": 179,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 6,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgYAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgYAgAAAAAAAA==/",
            "_etag": "\"9101a576-0000-0100-0000-615463530000\"",
            "_attachments": "attachments/",
            "_ts": 1632920403
        },
        {
            "id": "9db1d7eac6a193786da30c65539779ded999710f22087e3fd75ae09bb21ad32d",
            "conversationId": "9db1d7eac6a193786da30c65539779ded999710f22087e3fd75ae09bb21ad32d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-09-29T18:54:32.444Z",
            "endTime": "2021-09-29T18:54:32.444Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgZAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgZAgAAAAAAAA==/",
            "_etag": "\"92013136-0000-0100-0000-6154b7b20000\"",
            "_attachments": "attachments/",
            "_ts": 1632942002
        },
        {
            "id": "1323c41f72c6c6dc405c5c9adb667dea4d4e50198ffc5b0b444b23e4f761c79e",
            "conversationId": "1323c41f72c6c6dc405c5c9adb667dea4d4e50198ffc5b0b444b23e4f761c79e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Eye pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-04T17:24:40.638Z",
            "endTime": "2021-10-04T17:29:19.534Z",
            "timeSpent": 279,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgaAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgaAgAAAAAAAA==/",
            "_etag": "\"c5026454-0000-0100-0000-615b41230000\"",
            "_attachments": "attachments/",
            "_ts": 1633370403
        },
        {
            "id": "a20ad56c504de16c541253134f6372c678dcb91ef72061632bec6f1569e35000",
            "conversationId": "a20ad56c504de16c541253134f6372c678dcb91ef72061632bec6f1569e35000",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-04T19:44:36.857Z",
            "endTime": "2021-10-04T19:49:04.931Z",
            "timeSpent": 268,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgbAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgbAgAAAAAAAA==/",
            "_etag": "\"c602101a-0000-0100-0000-615b6b510000\"",
            "_attachments": "attachments/",
            "_ts": 1633381201
        },
        {
            "id": "c62390e051d55eadcf33e8fe2c213ee3be5fee55d3dba1c115ab31cc8e7cee63",
            "conversationId": "c62390e051d55eadcf33e8fe2c213ee3be5fee55d3dba1c115ab31cc8e7cee63",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-04T19:44:36.687Z",
            "endTime": "2021-10-04T19:44:36.687Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgcAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgcAgAAAAAAAA==/",
            "_etag": "\"c6020f1a-0000-0100-0000-615b6b510000\"",
            "_attachments": "attachments/",
            "_ts": 1633381201
        },
        {
            "id": "1af660c842cd6bbaefcb36aa6cc0947ecce851ff741cc4c0589ca0c02b20ae2d",
            "conversationId": "1af660c842cd6bbaefcb36aa6cc0947ecce851ff741cc4c0589ca0c02b20ae2d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-05T15:42:04.451Z",
            "endTime": "2021-10-05T15:43:39.927Z",
            "timeSpent": 95,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgdAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgdAgAAAAAAAA==/",
            "_etag": "\"c90209f8-0000-0100-0000-615c76830000\"",
            "_attachments": "attachments/",
            "_ts": 1633449603
        },
        {
            "id": "7303a83a9f0ce729ef2114ee8d9313d3760a8c9a32e84fc316043a2c825cc705",
            "conversationId": "7303a83a9f0ce729ef2114ee8d9313d3760a8c9a32e84fc316043a2c825cc705",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-05T15:43:48.158Z",
            "endTime": "2021-10-05T15:44:16.202Z",
            "timeSpent": 28,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgeAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgeAgAAAAAAAA==/",
            "_etag": "\"c9020af8-0000-0100-0000-615c76830000\"",
            "_attachments": "attachments/",
            "_ts": 1633449603
        },
        {
            "id": "2b59a0eb6d7396f845753ca689d624cd6dcc9bdabf57cbf02c75ec80ef629ac1",
            "conversationId": "2b59a0eb6d7396f845753ca689d624cd6dcc9bdabf57cbf02c75ec80ef629ac1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-10-05T15:44:30.279Z",
            "endTime": "2021-10-05T15:48:27.483Z",
            "timeSpent": 237,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgfAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgfAgAAAAAAAA==/",
            "_etag": "\"c9020bf8-0000-0100-0000-615c76830000\"",
            "_attachments": "attachments/",
            "_ts": 1633449603
        },
        {
            "id": "a24091aa2c669855a92be605fbe8e2eb1c7dd4f62fc72e9d4e1e1f72d32eb05f",
            "conversationId": "a24091aa2c669855a92be605fbe8e2eb1c7dd4f62fc72e9d4e1e1f72d32eb05f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-05T15:50:55.42Z",
            "endTime": "2021-10-05T15:51:03.544Z",
            "timeSpent": 8,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIggAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIggAgAAAAAAAA==/",
            "_etag": "\"c9020cf8-0000-0100-0000-615c76830000\"",
            "_attachments": "attachments/",
            "_ts": 1633449603
        },
        {
            "id": "3bdcfb7154642a9d12923ce1e7bc5f585b350d7f00047e47ccabbbd05286b924",
            "conversationId": "3bdcfb7154642a9d12923ce1e7bc5f585b350d7f00047e47ccabbbd05286b924",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-05T16:22:59.698Z",
            "endTime": "2021-10-05T16:25:42.59Z",
            "timeSpent": 163,
            "isNoteGenerated": "no",
            "npsGroup": "Passive",
            "npsScore": 7,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIghAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIghAgAAAAAAAA==/",
            "_etag": "\"ca028556-0000-0100-0000-615c84cf0000\"",
            "_attachments": "attachments/",
            "_ts": 1633453263
        },
        {
            "id": "39f03537d0e44f531dfbed5a79eb5a4be8ce9780545a088324d8de980560ecfb",
            "conversationId": "39f03537d0e44f531dfbed5a79eb5a4be8ce9780545a088324d8de980560ecfb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-05T16:26:43.722Z",
            "endTime": "2021-10-05T16:28:19.559Z",
            "timeSpent": 96,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgiAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgiAgAAAAAAAA==/",
            "_etag": "\"ca028656-0000-0100-0000-615c84cf0000\"",
            "_attachments": "attachments/",
            "_ts": 1633453263
        },
        {
            "id": "900da1d966c6e52a8db098a3fde112c16ba62791beaf431e664863968bdf0480",
            "conversationId": "900da1d966c6e52a8db098a3fde112c16ba62791beaf431e664863968bdf0480",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 25,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-08T11:02:08.719Z",
            "endTime": "2021-10-08T11:11:51.941Z",
            "timeSpent": 583,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgjAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgjAgAAAAAAAA==/",
            "_etag": "\"e102988e-0000-0100-0000-616032c40000\"",
            "_attachments": "attachments/",
            "_ts": 1633694404
        },
        {
            "id": "aac96a464af6140247b49734d3362813022ce7984174931a58ad28ba470050fa",
            "conversationId": "aac96a464af6140247b49734d3362813022ce7984174931a58ad28ba470050fa",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-13T12:22:43.382Z",
            "endTime": "2021-10-13T12:24:30.326Z",
            "timeSpent": 107,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgkAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgkAgAAAAAAAA==/",
            "_etag": "\"1a034687-0000-0100-0000-6166d8510000\"",
            "_attachments": "attachments/",
            "_ts": 1634130001
        },
        {
            "id": "88dffa3621c409ec8f130aa19147dd795a7a4d890710dee0c3a7cbfdace80a73",
            "conversationId": "88dffa3621c409ec8f130aa19147dd795a7a4d890710dee0c3a7cbfdace80a73",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-13T12:14:59.379Z",
            "endTime": "2021-10-13T12:15:04.36Z",
            "timeSpent": 5,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIglAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIglAgAAAAAAAA==/",
            "_etag": "\"1a034787-0000-0100-0000-6166d8510000\"",
            "_attachments": "attachments/",
            "_ts": 1634130001
        },
        {
            "id": "4351697b903fa865245383b3682e05c35f7ca1683928ecbd3bf51c1d04fa84b9",
            "conversationId": "4351697b903fa865245383b3682e05c35f7ca1683928ecbd3bf51c1d04fa84b9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-13T13:52:43.255Z",
            "endTime": "2021-10-13T13:54:16.711Z",
            "timeSpent": 93,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgmAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgmAgAAAAAAAA==/",
            "_etag": "\"1b03a02e-0000-0100-0000-6166f4720000\"",
            "_attachments": "attachments/",
            "_ts": 1634137202
        },
        {
            "id": "10f27ba6848b658359df73b9cd332ddb2e5871e89368e289abb13a829fb0570c",
            "conversationId": "10f27ba6848b658359df73b9cd332ddb2e5871e89368e289abb13a829fb0570c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-13T13:25:21.067Z",
            "endTime": "2021-10-13T13:25:21.067Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgnAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgnAgAAAAAAAA==/",
            "_etag": "\"1a03b2bd-0000-0100-0000-6166e6620000\"",
            "_attachments": "attachments/",
            "_ts": 1634133602
        },
        {
            "id": "88f0749963e8e7dad7b4ab51668cdbc1875c07d3a5b7f2e3305e97cffd887f18",
            "conversationId": "88f0749963e8e7dad7b4ab51668cdbc1875c07d3a5b7f2e3305e97cffd887f18",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-15T15:45:08.306Z",
            "endTime": "2021-10-15T15:53:37.141Z",
            "timeSpent": 509,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 4,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgoAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgoAgAAAAAAAA==/",
            "_etag": "\"0c000a08-0000-0100-0000-6169a5820000\"",
            "_attachments": "attachments/",
            "_ts": 1634313602
        },
        {
            "id": "cd411c9b6250723f1195465105d2f51a681184e2b38cc162e13d7bda11026ed0",
            "conversationId": "cd411c9b6250723f1195465105d2f51a681184e2b38cc162e13d7bda11026ed0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-10-15T15:54:48.223Z",
            "endTime": "2021-10-15T16:00:05.942Z",
            "timeSpent": 318,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgpAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgpAgAAAAAAAA==/",
            "_etag": "\"0c00b81a-0000-0100-0000-6169b3920000\"",
            "_attachments": "attachments/",
            "_ts": 1634317202
        },
        {
            "id": "462275a8de1216353e3258a5cf3e75d8ffbb31a7670048d6d49e8b9b50f9652a",
            "conversationId": "462275a8de1216353e3258a5cf3e75d8ffbb31a7670048d6d49e8b9b50f9652a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-10-15T16:56:16.237Z",
            "endTime": "2021-10-15T17:06:21.531Z",
            "timeSpent": 605,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 4,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgqAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgqAgAAAAAAAA==/",
            "_etag": "\"0c007537-0000-0100-0000-6169c1a80000\"",
            "_attachments": "attachments/",
            "_ts": 1634320808
        },
        {
            "id": "074df88ada9748ed15595121c4f0743866d0804c65bf167341e8c2e3c08c7e8a",
            "conversationId": "074df88ada9748ed15595121c4f0743866d0804c65bf167341e8c2e3c08c7e8a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-15T16:32:37.656Z",
            "endTime": "2021-10-15T16:35:05.835Z",
            "timeSpent": 148,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgrAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgrAgAAAAAAAA==/",
            "_etag": "\"0c007637-0000-0100-0000-6169c1a80000\"",
            "_attachments": "attachments/",
            "_ts": 1634320808
        },
        {
            "id": "06976526138b69bb73e5f4664d9645e10b1589298a8719629bba6d030f6d12c0",
            "conversationId": "06976526138b69bb73e5f4664d9645e10b1589298a8719629bba6d030f6d12c0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-15T16:46:44.987Z",
            "endTime": "2021-10-15T16:46:44.987Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgsAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgsAgAAAAAAAA==/",
            "_etag": "\"0c007837-0000-0100-0000-6169c1a80000\"",
            "_attachments": "attachments/",
            "_ts": 1634320808
        },
        {
            "id": "41a27c7ad25f5a7811527aee8cf6d24bda7cc0474c33db32657518b10bbfb5d3",
            "conversationId": "41a27c7ad25f5a7811527aee8cf6d24bda7cc0474c33db32657518b10bbfb5d3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-15T17:10:49.361Z",
            "endTime": "2021-10-15T17:15:20.707Z",
            "timeSpent": 271,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 3,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgtAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgtAgAAAAAAAA==/",
            "_etag": "\"0c007237-0000-0100-0000-6169c1a80000\"",
            "_attachments": "attachments/",
            "_ts": 1634320808
        },
        {
            "id": "be52b71adbef9c3430c56c280e66f73ab17882d22bdcfa97db9e456a55d81a58",
            "conversationId": "be52b71adbef9c3430c56c280e66f73ab17882d22bdcfa97db9e456a55d81a58",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-15T17:21:55.857Z",
            "endTime": "2021-10-15T17:25:45.424Z",
            "timeSpent": 230,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 4,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIguAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIguAgAAAAAAAA==/",
            "_etag": "\"0c007337-0000-0100-0000-6169c1a80000\"",
            "_attachments": "attachments/",
            "_ts": 1634320808
        },
        {
            "id": "512ef37b296337877df5c6953ddd3c7444115a50e5b2cb20837d24691d83b39b",
            "conversationId": "512ef37b296337877df5c6953ddd3c7444115a50e5b2cb20837d24691d83b39b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-15T17:52:02.863Z",
            "endTime": "2021-10-15T17:52:02.863Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgvAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgvAgAAAAAAAA==/",
            "_etag": "\"0c00574b-0000-0100-0000-6169cfb20000\"",
            "_attachments": "attachments/",
            "_ts": 1634324402
        },
        {
            "id": "d6f1dddb2500231554a9e7d6e8b9e6011bde20909d2517ad8870d22b07431aaa",
            "conversationId": "d6f1dddb2500231554a9e7d6e8b9e6011bde20909d2517ad8870d22b07431aaa",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-15T17:09:41.815Z",
            "endTime": "2021-10-15T17:10:22.599Z",
            "timeSpent": 41,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgwAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgwAgAAAAAAAA==/",
            "_etag": "\"0c007737-0000-0100-0000-6169c1a80000\"",
            "_attachments": "attachments/",
            "_ts": 1634320808
        },
        {
            "id": "277c84006bdb590a5b79c030825763a6ab30dd702c7a6b644eb5d094ffb085bd",
            "conversationId": "277c84006bdb590a5b79c030825763a6ab30dd702c7a6b644eb5d094ffb085bd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-15T18:57:01.176Z",
            "endTime": "2021-10-15T18:58:38.491Z",
            "timeSpent": 97,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgxAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgxAgAAAAAAAA==/",
            "_etag": "\"0c00bc62-0000-0100-0000-6169ddc20000\"",
            "_attachments": "attachments/",
            "_ts": 1634328002
        },
        {
            "id": "e6d8378bc2d1d33e94f085e7d32ba1a6df5389a0d74ea29027c23e1c2dbd4b2a",
            "conversationId": "e6d8378bc2d1d33e94f085e7d32ba1a6df5389a0d74ea29027c23e1c2dbd4b2a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-15T19:53:54.716Z",
            "endTime": "2021-10-15T19:55:20.269Z",
            "timeSpent": 86,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgyAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgyAgAAAAAAAA==/",
            "_etag": "\"0c00417a-0000-0100-0000-6169ebd10000\"",
            "_attachments": "attachments/",
            "_ts": 1634331601
        },
        {
            "id": "5b6ed29f4b71f995ec9276da15728889d302cb76f7a836bd0e45bc1680fa891a",
            "conversationId": "5b6ed29f4b71f995ec9276da15728889d302cb76f7a836bd0e45bc1680fa891a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-15T19:58:10.954Z",
            "endTime": "2021-10-15T20:01:13.756Z",
            "timeSpent": 183,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgzAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgzAgAAAAAAAA==/",
            "_etag": "\"0c003e7a-0000-0100-0000-6169ebd10000\"",
            "_attachments": "attachments/",
            "_ts": 1634331601
        },
        {
            "id": "6a173590c6715df7aa14b41560fba5265f7df130944e3632c164a2459e16b81b",
            "conversationId": "6a173590c6715df7aa14b41560fba5265f7df130944e3632c164a2459e16b81b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-15T19:30:52.176Z",
            "endTime": "2021-10-15T19:30:52.176Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg0AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg0AgAAAAAAAA==/",
            "_etag": "\"0c00437a-0000-0100-0000-6169ebd10000\"",
            "_attachments": "attachments/",
            "_ts": 1634331601
        },
        {
            "id": "11803f8487311e9af7b4e51538540d0167511ce56e2533b8b465aefef8d18c1b",
            "conversationId": "11803f8487311e9af7b4e51538540d0167511ce56e2533b8b465aefef8d18c1b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-15T19:31:52.842Z",
            "endTime": "2021-10-15T19:32:26.112Z",
            "timeSpent": 33,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg1AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg1AgAAAAAAAA==/",
            "_etag": "\"0c003f7a-0000-0100-0000-6169ebd10000\"",
            "_attachments": "attachments/",
            "_ts": 1634331601
        },
        {
            "id": "4362b86c5f1810a78e36ac72c180f77a6081699369e48ffdca30d77d819a3326",
            "conversationId": "4362b86c5f1810a78e36ac72c180f77a6081699369e48ffdca30d77d819a3326",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-15T19:32:36.979Z",
            "endTime": "2021-10-15T19:32:36.979Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg2AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg2AgAAAAAAAA==/",
            "_etag": "\"0c00407a-0000-0100-0000-6169ebd10000\"",
            "_attachments": "attachments/",
            "_ts": 1634331601
        },
        {
            "id": "63cf414d0341685c25dfef41c5213981733fd827f5d1ce36e909bf79ec7b1d63",
            "conversationId": "63cf414d0341685c25dfef41c5213981733fd827f5d1ce36e909bf79ec7b1d63",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-15T19:40:29.374Z",
            "endTime": "2021-10-15T19:42:47.369Z",
            "timeSpent": 138,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg3AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg3AgAAAAAAAA==/",
            "_etag": "\"0c00427a-0000-0100-0000-6169ebd10000\"",
            "_attachments": "attachments/",
            "_ts": 1634331601
        },
        {
            "id": "c74313bdbc7f7ccb97adc7ac603be0d54558ee39be9757e8ee6be26b69f8dec0",
            "conversationId": "c74313bdbc7f7ccb97adc7ac603be0d54558ee39be9757e8ee6be26b69f8dec0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Black stools",
                "Shoulder pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-19T13:27:05.558Z",
            "endTime": "2021-10-19T13:28:28.791Z",
            "timeSpent": 83,
            "isNoteGenerated": "no",
            "npsGroup": "Passive",
            "npsScore": 8,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg4AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg4AgAAAAAAAA==/",
            "_etag": "\"2c002600-0000-0100-0000-616edd720000\"",
            "_attachments": "attachments/",
            "_ts": 1634655602
        },
        {
            "id": "f1671a46aab821da89c49e7bf6d77a43832def86c0e637067a9b0bcb529b16e7",
            "conversationId": "f1671a46aab821da89c49e7bf6d77a43832def86c0e637067a9b0bcb529b16e7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-19T15:00:00Z",
            "endTime": "2021-10-19T15:03:03.679Z",
            "timeSpent": 184,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg5AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg5AgAAAAAAAA==/",
            "_etag": "\"2c00e168-0000-0100-0000-616ef9930000\"",
            "_attachments": "attachments/",
            "_ts": 1634662803
        },
        {
            "id": "fbfc445277d9aa41f8c99e70c9744b9867c84338fc894095d4fa76c837876578",
            "conversationId": "fbfc445277d9aa41f8c99e70c9744b9867c84338fc894095d4fa76c837876578",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-20T00:57:26.729Z",
            "endTime": "2021-10-20T00:57:44.25Z",
            "timeSpent": 18,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg6AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg6AgAAAAAAAA==/",
            "_etag": "\"2c00f8eb-0000-0100-0000-616f78250000\"",
            "_attachments": "attachments/",
            "_ts": 1634695205
        },
        {
            "id": "c86cc8ed385d925a288316d427bb4beba4f2c418b07c7b1749cec68926b671c2",
            "conversationId": "c86cc8ed385d925a288316d427bb4beba4f2c418b07c7b1749cec68926b671c2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Palpitations",
                "Thigh injury"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "consultation_24",
            "startTime": "2021-10-20T06:06:23.798Z",
            "endTime": "2021-10-20T06:09:24.304Z",
            "timeSpent": 181,
            "isNoteGenerated": "no",
            "npsGroup": "Passive",
            "npsScore": 7,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg7AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg7AgAAAAAAAA==/",
            "_etag": "\"2d007ed9-0000-0100-0000-616fcc810000\"",
            "_attachments": "attachments/",
            "_ts": 1634716801
        },
        {
            "id": "acb29056bd7444415504d0a8e8a2182591977596930402bc0046414362db47e5",
            "conversationId": "acb29056bd7444415504d0a8e8a2182591977596930402bc0046414362db47e5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Palpitations",
                "Thigh injury"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-10-25T09:26:50.283Z",
            "endTime": "2021-10-25T09:30:40.819Z",
            "timeSpent": 231,
            "isNoteGenerated": "yes",
            "npsGroup": "Passive",
            "npsScore": 8,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg8AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg8AgAAAAAAAA==/",
            "_etag": "\"38007bdc-0000-0100-0000-61768e310000\"",
            "_attachments": "attachments/",
            "_ts": 1635159601
        },
        {
            "id": "8be3a47f1676667f05bd52f7ae1389fbf5000bf3ab30325f20216f60826da141",
            "conversationId": "8be3a47f1676667f05bd52f7ae1389fbf5000bf3ab30325f20216f60826da141",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Redness around eyes"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2021-10-25T12:32:00.093Z",
            "endTime": "2021-10-25T12:34:26.633Z",
            "timeSpent": 147,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg9AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg9AgAAAAAAAA==/",
            "_etag": "\"39007f0d-0000-0100-0000-6176c6730000\"",
            "_attachments": "attachments/",
            "_ts": 1635174003
        },
        {
            "id": "140e011e9e1d515958ae72a6c6ad136540de47ea2e06aa6d1405b706a4f52c74",
            "conversationId": "140e011e9e1d515958ae72a6c6ad136540de47ea2e06aa6d1405b706a4f52c74",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-25T12:46:52.108Z",
            "endTime": "2021-10-25T12:47:05.458Z",
            "timeSpent": 13,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg+AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg+AgAAAAAAAA==/",
            "_etag": "\"3800c1f8-0000-0100-0000-6176b8610000\"",
            "_attachments": "attachments/",
            "_ts": 1635170401
        },
        {
            "id": "f6c7468491da0b82d2c9f972ea7fc96721f6dc2cb177ac86400f74c8b785ecdf",
            "conversationId": "f6c7468491da0b82d2c9f972ea7fc96721f6dc2cb177ac86400f74c8b785ecdf",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-25T12:54:07.631Z",
            "endTime": "2021-10-25T12:54:18.095Z",
            "timeSpent": 10,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg-AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg-AgAAAAAAAA==/",
            "_etag": "\"3800c0f8-0000-0100-0000-6176b8610000\"",
            "_attachments": "attachments/",
            "_ts": 1635170401
        },
        {
            "id": "1a3f1545e0926efad5f18e9aa8cad8dfbfd18e18f5a060da90801eb058e2cd89",
            "conversationId": "1a3f1545e0926efad5f18e9aa8cad8dfbfd18e18f5a060da90801eb058e2cd89",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-10-25T13:05:54.787Z",
            "endTime": "2021-10-25T13:07:48.406Z",
            "timeSpent": 114,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhAAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhAAgAAAAAAAA==/",
            "_etag": "\"3900840d-0000-0100-0000-6176c6730000\"",
            "_attachments": "attachments/",
            "_ts": 1635174003
        },
        {
            "id": "0636e1d9d38972b3303d32009feac52c019737c4a97849848d8595a6e46be869",
            "conversationId": "0636e1d9d38972b3303d32009feac52c019737c4a97849848d8595a6e46be869",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T13:08:06.427Z",
            "endTime": "2021-10-25T13:08:14.894Z",
            "timeSpent": 8,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhBAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhBAgAAAAAAAA==/",
            "_etag": "\"3900850d-0000-0100-0000-6176c6730000\"",
            "_attachments": "attachments/",
            "_ts": 1635174003
        },
        {
            "id": "2b3f22c5c936a7322b5945b18d48f0e6f9188494dc6651c0fd6a66832e867c36",
            "conversationId": "2b3f22c5c936a7322b5945b18d48f0e6f9188494dc6651c0fd6a66832e867c36",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2021-10-25T13:33:51.518Z",
            "endTime": "2021-10-25T13:36:16.132Z",
            "timeSpent": 145,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhCAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhCAgAAAAAAAA==/",
            "_etag": "\"39008719-0000-0100-0000-6176d4820000\"",
            "_attachments": "attachments/",
            "_ts": 1635177602
        },
        {
            "id": "b2170de1b3913af3df4f705c627cf24ec4b43dfda6b09fb4ae9ae406a648e90b",
            "conversationId": "b2170de1b3913af3df4f705c627cf24ec4b43dfda6b09fb4ae9ae406a648e90b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2021-10-25T13:36:09.774Z",
            "endTime": "2021-10-25T13:40:23.66Z",
            "timeSpent": 254,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhDAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhDAgAAAAAAAA==/",
            "_etag": "\"39008919-0000-0100-0000-6176d4820000\"",
            "_attachments": "attachments/",
            "_ts": 1635177602
        },
        {
            "id": "114e8ee9ff87070cac3e873a8aa9de5ad7c023124a5b0257562355a616835f40",
            "conversationId": "114e8ee9ff87070cac3e873a8aa9de5ad7c023124a5b0257562355a616835f40",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2021-10-25T13:43:41.538Z",
            "endTime": "2021-10-25T13:46:05.293Z",
            "timeSpent": 144,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhEAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhEAgAAAAAAAA==/",
            "_etag": "\"39007d0d-0000-0100-0000-6176c6730000\"",
            "_attachments": "attachments/",
            "_ts": 1635174003
        },
        {
            "id": "22da3236d0ce9631987b21c022c8a59b8802236aaa07e7292913181869429e1a",
            "conversationId": "22da3236d0ce9631987b21c022c8a59b8802236aaa07e7292913181869429e1a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T13:36:09.564Z",
            "endTime": "2021-10-25T13:36:09.564Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhFAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhFAgAAAAAAAA==/",
            "_etag": "\"39008619-0000-0100-0000-6176d4820000\"",
            "_attachments": "attachments/",
            "_ts": 1635177602
        },
        {
            "id": "a154bd11037a17ac712f51b279db90682e962d2ad44d850091381938b2faf89a",
            "conversationId": "a154bd11037a17ac712f51b279db90682e962d2ad44d850091381938b2faf89a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T13:56:21.421Z",
            "endTime": "2021-10-25T13:56:21.421Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhGAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhGAgAAAAAAAA==/",
            "_etag": "\"39008219-0000-0100-0000-6176d4820000\"",
            "_attachments": "attachments/",
            "_ts": 1635177602
        },
        {
            "id": "ea2fee0a4c8d92585444a27f5a902964dd8396c235f25102079fa3519a0f8c1c",
            "conversationId": "ea2fee0a4c8d92585444a27f5a902964dd8396c235f25102079fa3519a0f8c1c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Redness around eyes"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2021-10-25T13:59:02.628Z",
            "endTime": "2021-10-25T14:00:11.499Z",
            "timeSpent": 69,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhHAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhHAgAAAAAAAA==/",
            "_etag": "\"39008319-0000-0100-0000-6176d4820000\"",
            "_attachments": "attachments/",
            "_ts": 1635177602
        },
        {
            "id": "5900788e8c3f188454ce7b3750cc8c4468abf67706e1b1c9b023e69e2031576a",
            "conversationId": "5900788e8c3f188454ce7b3750cc8c4468abf67706e1b1c9b023e69e2031576a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Redness around eyes"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2021-10-25T14:44:16.767Z",
            "endTime": "2021-10-25T14:46:12.423Z",
            "timeSpent": 116,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhIAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhIAgAAAAAAAA==/",
            "_etag": "\"3900d526-0000-0100-0000-6176e2920000\"",
            "_attachments": "attachments/",
            "_ts": 1635181202
        },
        {
            "id": "3da59e8414c8c522a44799e554ae5a27a7caf32d636bc1ea36bdf8353458990a",
            "conversationId": "3da59e8414c8c522a44799e554ae5a27a7caf32d636bc1ea36bdf8353458990a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T15:08:19.416Z",
            "endTime": "2021-10-25T15:08:26.719Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhJAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhJAgAAAAAAAA==/",
            "_etag": "\"3900ca26-0000-0100-0000-6176e2920000\"",
            "_attachments": "attachments/",
            "_ts": 1635181202
        },
        {
            "id": "02b40e418be93bef51644ecd8610ab9d221740613ae182d24389f36ade95c05e",
            "conversationId": "02b40e418be93bef51644ecd8610ab9d221740613ae182d24389f36ade95c05e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T15:58:54.622Z",
            "endTime": "2021-10-25T15:58:57.75Z",
            "timeSpent": 3,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhKAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhKAgAAAAAAAA==/",
            "_etag": "\"39004531-0000-0100-0000-6176f0a60000\"",
            "_attachments": "attachments/",
            "_ts": 1635184806
        },
        {
            "id": "04faa3260b398252da41079afd3db75d8d93a6020d01ea52e7970bfad24c5843",
            "conversationId": "04faa3260b398252da41079afd3db75d8d93a6020d01ea52e7970bfad24c5843",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T15:58:04.577Z",
            "endTime": "2021-10-25T15:58:04.577Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhLAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhLAgAAAAAAAA==/",
            "_etag": "\"39004131-0000-0100-0000-6176f0a60000\"",
            "_attachments": "attachments/",
            "_ts": 1635184806
        },
        {
            "id": "0157ba93748394b25ac1cba2705a30855af360e2db16aff4ea6c662e1d46e5f3",
            "conversationId": "0157ba93748394b25ac1cba2705a30855af360e2db16aff4ea6c662e1d46e5f3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T15:58:37.21Z",
            "endTime": "2021-10-25T15:58:37.21Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhMAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhMAgAAAAAAAA==/",
            "_etag": "\"39004231-0000-0100-0000-6176f0a60000\"",
            "_attachments": "attachments/",
            "_ts": 1635184806
        },
        {
            "id": "ae8b1a250dc2c760656640cfed6f8b73f7e93b37464868b47eb33baa2cb9d398",
            "conversationId": "ae8b1a250dc2c760656640cfed6f8b73f7e93b37464868b47eb33baa2cb9d398",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T16:02:11.911Z",
            "endTime": "2021-10-25T16:04:47.142Z",
            "timeSpent": 155,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhNAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhNAgAAAAAAAA==/",
            "_etag": "\"39003631-0000-0100-0000-6176f0a60000\"",
            "_attachments": "attachments/",
            "_ts": 1635184806
        },
        {
            "id": "d5322d5a757b6a3107db95b441fdf9a71fbe0896b2a4f4c67527eaa22feb8c60",
            "conversationId": "d5322d5a757b6a3107db95b441fdf9a71fbe0896b2a4f4c67527eaa22feb8c60",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T16:11:35.888Z",
            "endTime": "2021-10-25T16:11:35.888Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhOAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhOAgAAAAAAAA==/",
            "_etag": "\"39003f31-0000-0100-0000-6176f0a60000\"",
            "_attachments": "attachments/",
            "_ts": 1635184806
        },
        {
            "id": "13609824771c4df2a0146aadd7c57b581250bb253670eb7c890fa024767ef188",
            "conversationId": "13609824771c4df2a0146aadd7c57b581250bb253670eb7c890fa024767ef188",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T16:11:44.033Z",
            "endTime": "2021-10-25T16:13:14.731Z",
            "timeSpent": 91,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhPAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhPAgAAAAAAAA==/",
            "_etag": "\"39004031-0000-0100-0000-6176f0a60000\"",
            "_attachments": "attachments/",
            "_ts": 1635184806
        },
        {
            "id": "9f64593e59d0a1993902b08690411c274f2ee111415dc7b4b8d5836b475fc240",
            "conversationId": "9f64593e59d0a1993902b08690411c274f2ee111415dc7b4b8d5836b475fc240",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T16:02:00.791Z",
            "endTime": "2021-10-25T16:02:00.791Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhQAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhQAgAAAAAAAA==/",
            "_etag": "\"39003531-0000-0100-0000-6176f0a60000\"",
            "_attachments": "attachments/",
            "_ts": 1635184806
        },
        {
            "id": "f5dfccc7ce4b0d59b49cb8e9f647e46b6a300f65cfc6ef89e3566c2efe6dcbb2",
            "conversationId": "f5dfccc7ce4b0d59b49cb8e9f647e46b6a300f65cfc6ef89e3566c2efe6dcbb2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Redness around eyes"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2021-10-25T16:44:28.566Z",
            "endTime": "2021-10-25T16:46:32.063Z",
            "timeSpent": 123,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhRAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhRAgAAAAAAAA==/",
            "_etag": "\"39003731-0000-0100-0000-6176f0a60000\"",
            "_attachments": "attachments/",
            "_ts": 1635184806
        },
        {
            "id": "c07c8e01aed3b67f302ad25152365d0530f2fbb753d55b80fd4fa318080bc12c",
            "conversationId": "c07c8e01aed3b67f302ad25152365d0530f2fbb753d55b80fd4fa318080bc12c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-25T16:50:30.103Z",
            "endTime": "2021-10-25T16:50:40.613Z",
            "timeSpent": 11,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhSAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhSAgAAAAAAAA==/",
            "_etag": "\"39003831-0000-0100-0000-6176f0a60000\"",
            "_attachments": "attachments/",
            "_ts": 1635184806
        },
        {
            "id": "11ef652b8d4323688c168d72b3f2d696de0b324308dc5ab6750b982a79cda318",
            "conversationId": "11ef652b8d4323688c168d72b3f2d696de0b324308dc5ab6750b982a79cda318",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T16:00:00Z",
            "endTime": "2021-10-25T16:51:01.092Z",
            "timeSpent": 3061,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhTAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhTAgAAAAAAAA==/",
            "_etag": "\"39003931-0000-0100-0000-6176f0a60000\"",
            "_attachments": "attachments/",
            "_ts": 1635184806
        },
        {
            "id": "66b1962959c9a147ce83de82a03cd08dd2cffa53ce0943a2b28a259afc83c088",
            "conversationId": "66b1962959c9a147ce83de82a03cd08dd2cffa53ce0943a2b28a259afc83c088",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Redness around eyes"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2021-10-25T16:51:20.89Z",
            "endTime": "2021-10-25T16:52:28.856Z",
            "timeSpent": 68,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhUAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhUAgAAAAAAAA==/",
            "_etag": "\"3900713e-0000-0100-0000-6176feb30000\"",
            "_attachments": "attachments/",
            "_ts": 1635188403
        },
        {
            "id": "45c19943feed97780e0dc431cc87485731bac47482e2af9f124d2e0ab8f35c64",
            "conversationId": "45c19943feed97780e0dc431cc87485731bac47482e2af9f124d2e0ab8f35c64",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2021-10-25T16:55:24.364Z",
            "endTime": "2021-10-25T16:55:50.781Z",
            "timeSpent": 26,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhVAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhVAgAAAAAAAA==/",
            "_etag": "\"3900723e-0000-0100-0000-6176feb30000\"",
            "_attachments": "attachments/",
            "_ts": 1635188403
        },
        {
            "id": "191591e4d7c0f04b654f97b64702ff5128ee6af7b08102e742feac65cabe1b88",
            "conversationId": "191591e4d7c0f04b654f97b64702ff5128ee6af7b08102e742feac65cabe1b88",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T16:17:04.463Z",
            "endTime": "2021-10-25T16:17:04.463Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhWAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhWAgAAAAAAAA==/",
            "_etag": "\"39004431-0000-0100-0000-6176f0a60000\"",
            "_attachments": "attachments/",
            "_ts": 1635184806
        },
        {
            "id": "a590665667ee570a8e152932adb8b2d718912b267f4aed0757a745d3795b3d3f",
            "conversationId": "a590665667ee570a8e152932adb8b2d718912b267f4aed0757a745d3795b3d3f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Redness around eyes"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2021-10-25T16:22:03.32Z",
            "endTime": "2021-10-25T16:23:07.606Z",
            "timeSpent": 64,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhXAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhXAgAAAAAAAA==/",
            "_etag": "\"39003b31-0000-0100-0000-6176f0a60000\"",
            "_attachments": "attachments/",
            "_ts": 1635184806
        },
        {
            "id": "834c45ace035844b16a21d45423787ebdb9426076dfa6ff55df668ba3e4265d8",
            "conversationId": "834c45ace035844b16a21d45423787ebdb9426076dfa6ff55df668ba3e4265d8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-25T16:42:54.192Z",
            "endTime": "2021-10-25T16:43:04.513Z",
            "timeSpent": 10,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhYAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhYAgAAAAAAAA==/",
            "_etag": "\"39004631-0000-0100-0000-6176f0a60000\"",
            "_attachments": "attachments/",
            "_ts": 1635184806
        },
        {
            "id": "a5a9f5df2c5fc213572878f668348a1d4e5e662dd5efdbfd969a6ee28a134ddd",
            "conversationId": "a5a9f5df2c5fc213572878f668348a1d4e5e662dd5efdbfd969a6ee28a134ddd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T16:43:49.363Z",
            "endTime": "2021-10-25T16:43:57.612Z",
            "timeSpent": 8,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhZAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhZAgAAAAAAAA==/",
            "_etag": "\"3900693e-0000-0100-0000-6176feb30000\"",
            "_attachments": "attachments/",
            "_ts": 1635188403
        },
        {
            "id": "05f001b64999cf076eb7997d95854b0d476062692b7c17aecb98827bead5c7b0",
            "conversationId": "05f001b64999cf076eb7997d95854b0d476062692b7c17aecb98827bead5c7b0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Redness around eyes"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2021-10-25T17:34:50.745Z",
            "endTime": "2021-10-25T17:36:06.247Z",
            "timeSpent": 76,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhaAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhaAgAAAAAAAA==/",
            "_etag": "\"39003348-0000-0100-0000-61770cc30000\"",
            "_attachments": "attachments/",
            "_ts": 1635192003
        },
        {
            "id": "970804d5fd7a87535a65efbb01ed59cea9222b168d7d2833a806a5ed8884c80d",
            "conversationId": "970804d5fd7a87535a65efbb01ed59cea9222b168d7d2833a806a5ed8884c80d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T17:37:47.028Z",
            "endTime": "2021-10-25T17:38:15.945Z",
            "timeSpent": 29,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhbAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhbAgAAAAAAAA==/",
            "_etag": "\"39003d48-0000-0100-0000-61770cc30000\"",
            "_attachments": "attachments/",
            "_ts": 1635192003
        },
        {
            "id": "6e5c6a1a73f8e6c231e2da19ce5130ed5f2061a6d319f9eed822958089d88974",
            "conversationId": "6e5c6a1a73f8e6c231e2da19ce5130ed5f2061a6d319f9eed822958089d88974",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-10-25T17:38:31.059Z",
            "endTime": "2021-10-25T17:41:29.519Z",
            "timeSpent": 178,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 5,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhcAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhcAgAAAAAAAA==/",
            "_etag": "\"3900653e-0000-0100-0000-6176feb30000\"",
            "_attachments": "attachments/",
            "_ts": 1635188403
        },
        {
            "id": "62ffae7bc1a272d4481474422d90591939fcb0a84eb179529c00ebdd49794fe6",
            "conversationId": "62ffae7bc1a272d4481474422d90591939fcb0a84eb179529c00ebdd49794fe6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T17:07:36.056Z",
            "endTime": "2021-10-25T17:07:43.198Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhdAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhdAgAAAAAAAA==/",
            "_etag": "\"3900683e-0000-0100-0000-6176feb30000\"",
            "_attachments": "attachments/",
            "_ts": 1635188403
        },
        {
            "id": "9b012f09df0db00a36378238ff5c824766c7fc72744b96120ae59d9f24db12c8",
            "conversationId": "9b012f09df0db00a36378238ff5c824766c7fc72744b96120ae59d9f24db12c8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-25T17:14:04.227Z",
            "endTime": "2021-10-25T17:14:38.577Z",
            "timeSpent": 34,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIheAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIheAgAAAAAAAA==/",
            "_etag": "\"39006a3e-0000-0100-0000-6176feb30000\"",
            "_attachments": "attachments/",
            "_ts": 1635188403
        },
        {
            "id": "a92627c1397a302d50b69264759336ee33370e8204c114d32f50dc9703f1d6d9",
            "conversationId": "a92627c1397a302d50b69264759336ee33370e8204c114d32f50dc9703f1d6d9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Redness around eyes"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2021-10-25T17:15:04.616Z",
            "endTime": "2021-10-25T17:16:11.826Z",
            "timeSpent": 67,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhfAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhfAgAAAAAAAA==/",
            "_etag": "\"3900673e-0000-0100-0000-6176feb30000\"",
            "_attachments": "attachments/",
            "_ts": 1635188403
        },
        {
            "id": "24399dcd21163f7ce5e09804197adcbc495846f4d47de3fe8f6f5a31dad74fbb",
            "conversationId": "24399dcd21163f7ce5e09804197adcbc495846f4d47de3fe8f6f5a31dad74fbb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Diarrhea"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-10-25T18:48:18.727Z",
            "endTime": "2021-10-25T19:06:12.158Z",
            "timeSpent": 1073,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 4,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhgAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhgAgAAAAAAAA==/",
            "_etag": "\"3900cb50-0000-0100-0000-61771ad20000\"",
            "_attachments": "attachments/",
            "_ts": 1635195602
        },
        {
            "id": "7b2e7817ef89f5aee38a829d8184a0a2153106e4c49bbdd5fde10b003101e913",
            "conversationId": "7b2e7817ef89f5aee38a829d8184a0a2153106e4c49bbdd5fde10b003101e913",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T18:16:43.326Z",
            "endTime": "2021-10-25T18:16:53.501Z",
            "timeSpent": 10,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhhAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhhAgAAAAAAAA==/",
            "_etag": "\"39003548-0000-0100-0000-61770cc30000\"",
            "_attachments": "attachments/",
            "_ts": 1635192003
        },
        {
            "id": "b3347d48046897bd1ebfdd9691a7ebfad59a257db1362abf10f7177f230fc716",
            "conversationId": "b3347d48046897bd1ebfdd9691a7ebfad59a257db1362abf10f7177f230fc716",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T18:17:02.977Z",
            "endTime": "2021-10-25T18:17:30.93Z",
            "timeSpent": 28,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhiAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhiAgAAAAAAAA==/",
            "_etag": "\"39003648-0000-0100-0000-61770cc30000\"",
            "_attachments": "attachments/",
            "_ts": 1635192003
        },
        {
            "id": "f32eeefaa6170141ef93a9debe2f12af0a40cd636df542da629512f0ebc4042b",
            "conversationId": "f32eeefaa6170141ef93a9debe2f12af0a40cd636df542da629512f0ebc4042b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-25T18:17:37.081Z",
            "endTime": "2021-10-25T18:18:41.39Z",
            "timeSpent": 64,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhjAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhjAgAAAAAAAA==/",
            "_etag": "\"39003748-0000-0100-0000-61770cc30000\"",
            "_attachments": "attachments/",
            "_ts": 1635192003
        },
        {
            "id": "40fe85e734a3ab8a8ff4e60b621fb7e6f9dba3bebcad7cbd7bb2af5db64812f1",
            "conversationId": "40fe85e734a3ab8a8ff4e60b621fb7e6f9dba3bebcad7cbd7bb2af5db64812f1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T18:18:56.207Z",
            "endTime": "2021-10-25T18:18:56.207Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhkAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhkAgAAAAAAAA==/",
            "_etag": "\"39003848-0000-0100-0000-61770cc30000\"",
            "_attachments": "attachments/",
            "_ts": 1635192003
        },
        {
            "id": "2add736c2120467d916d6d5fe0f2db73a904a04dad452cce61fa049d4fc782c5",
            "conversationId": "2add736c2120467d916d6d5fe0f2db73a904a04dad452cce61fa049d4fc782c5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T18:23:18.338Z",
            "endTime": "2021-10-25T18:23:18.338Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhlAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhlAgAAAAAAAA==/",
            "_etag": "\"39003948-0000-0100-0000-61770cc30000\"",
            "_attachments": "attachments/",
            "_ts": 1635192003
        },
        {
            "id": "74d5aaa9d043fd5102b71fc7eba39251c6eb6c0c886360f19269ce49f61f1731",
            "conversationId": "74d5aaa9d043fd5102b71fc7eba39251c6eb6c0c886360f19269ce49f61f1731",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-25T18:23:35.11Z",
            "endTime": "2021-10-25T18:47:37.164Z",
            "timeSpent": 1442,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhmAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhmAgAAAAAAAA==/",
            "_etag": "\"39003a48-0000-0100-0000-61770cc30000\"",
            "_attachments": "attachments/",
            "_ts": 1635192003
        },
        {
            "id": "b07412a12f71aa8f3dd266468ec8f7e4e509bbd5293ba4294e2523657536ea97",
            "conversationId": "b07412a12f71aa8f3dd266468ec8f7e4e509bbd5293ba4294e2523657536ea97",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Bleeding after intercourse"
            ],
            "riskFactors": [
                "Diabetes",
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2021-10-25T19:19:06.752Z",
            "endTime": "2021-10-25T19:32:00.107Z",
            "timeSpent": 773,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhnAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhnAgAAAAAAAA==/",
            "_etag": "\"3900c850-0000-0100-0000-61771ad20000\"",
            "_attachments": "attachments/",
            "_ts": 1635195602
        },
        {
            "id": "7a56b24344052eaef05d69a5f3f6f43d561491f9684f02ee00bb55bf1354c2eb",
            "conversationId": "7a56b24344052eaef05d69a5f3f6f43d561491f9684f02ee00bb55bf1354c2eb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T19:43:10.227Z",
            "endTime": "2021-10-25T19:43:17.794Z",
            "timeSpent": 8,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhoAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhoAgAAAAAAAA==/",
            "_etag": "\"3900f25b-0000-0100-0000-617728e20000\"",
            "_attachments": "attachments/",
            "_ts": 1635199202
        },
        {
            "id": "ae41879779815c198f15b131d0b810ed5d7d3b918ec3cda5d21a5f2386a225c7",
            "conversationId": "ae41879779815c198f15b131d0b810ed5d7d3b918ec3cda5d21a5f2386a225c7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2021-10-25T19:54:00.473Z",
            "endTime": "2021-10-25T19:57:56.869Z",
            "timeSpent": 236,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhpAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhpAgAAAAAAAA==/",
            "_etag": "\"3900d050-0000-0100-0000-61771ad20000\"",
            "_attachments": "attachments/",
            "_ts": 1635195602
        },
        {
            "id": "95802bfff2655a5933feb077e14ef572f1d352c3edbf96b78885daf105321dcb",
            "conversationId": "95802bfff2655a5933feb077e14ef572f1d352c3edbf96b78885daf105321dcb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T19:52:50.274Z",
            "endTime": "2021-10-25T19:52:55.606Z",
            "timeSpent": 5,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhqAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhqAgAAAAAAAA==/",
            "_etag": "\"3900f55b-0000-0100-0000-617728e20000\"",
            "_attachments": "attachments/",
            "_ts": 1635199202
        },
        {
            "id": "04bf85f7c7ae74c1e7bfef9386da07e4bee80e03543ff36cdb4deefb15e506b9",
            "conversationId": "04bf85f7c7ae74c1e7bfef9386da07e4bee80e03543ff36cdb4deefb15e506b9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-10-25T20:15:21.572Z",
            "endTime": "2021-10-25T20:21:41.546Z",
            "timeSpent": 380,
            "isNoteGenerated": "yes",
            "npsGroup": "Passive",
            "npsScore": 7,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhrAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhrAgAAAAAAAA==/",
            "_etag": "\"3900f45b-0000-0100-0000-617728e20000\"",
            "_attachments": "attachments/",
            "_ts": 1635199202
        },
    

    
        {
            "id": "59d9be9677ae6c4ead093bd940ba65f72273e834a683567a3662831bd6cdad69",
            "conversationId": "59d9be9677ae6c4ead093bd940ba65f72273e834a683567a3662831bd6cdad69",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T20:14:36.982Z",
            "endTime": "2021-10-25T20:14:36.982Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhsAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhsAgAAAAAAAA==/",
            "_etag": "\"3900f35b-0000-0100-0000-617728e20000\"",
            "_attachments": "attachments/",
            "_ts": 1635199202
        },
        {
            "id": "5b6b4b2a5206f30bb277c647070b1ab6f4b0bdfd5d014766c92d5991ac59c5eb",
            "conversationId": "5b6b4b2a5206f30bb277c647070b1ab6f4b0bdfd5d014766c92d5991ac59c5eb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T20:24:33.284Z",
            "endTime": "2021-10-25T20:24:43.592Z",
            "timeSpent": 10,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhtAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhtAgAAAAAAAA==/",
            "_etag": "\"3900ec5b-0000-0100-0000-617728e20000\"",
            "_attachments": "attachments/",
            "_ts": 1635199202
        },
        {
            "id": "52e1cfbf1a61457960d33457345e29e5bf431221c27168e520a4cbfda2372b1a",
            "conversationId": "52e1cfbf1a61457960d33457345e29e5bf431221c27168e520a4cbfda2372b1a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-25T20:25:52.444Z",
            "endTime": "2021-10-25T20:27:58.56Z",
            "timeSpent": 126,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhuAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhuAgAAAAAAAA==/",
            "_etag": "\"3900ef5b-0000-0100-0000-617728e20000\"",
            "_attachments": "attachments/",
            "_ts": 1635199202
        },
        {
            "id": "526527c94840de3b4c86cfd342463113d9a4accfaa4418ab234b19eab4f4121f",
            "conversationId": "526527c94840de3b4c86cfd342463113d9a4accfaa4418ab234b19eab4f4121f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-10-25T20:28:13.233Z",
            "endTime": "2021-10-25T20:35:01.918Z",
            "timeSpent": 409,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhvAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhvAgAAAAAAAA==/",
            "_etag": "\"3900b866-0000-0100-0000-617736f10000\"",
            "_attachments": "attachments/",
            "_ts": 1635202801
        },
        {
            "id": "d17148f43de0995c52cc64dd8ee5a9a68e86033b3ba33266dd5120ef25a12fdf",
            "conversationId": "d17148f43de0995c52cc64dd8ee5a9a68e86033b3ba33266dd5120ef25a12fdf",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-10-25T20:50:20.283Z",
            "endTime": "2021-10-25T20:56:44.232Z",
            "timeSpent": 384,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhwAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhwAgAAAAAAAA==/",
            "_etag": "\"3900b666-0000-0100-0000-617736f10000\"",
            "_attachments": "attachments/",
            "_ts": 1635202801
        },
        {
            "id": "2eb15b161fda1d7484b017486a11f6c30b593f26d92d4402c77e4d5f650bd7cd",
            "conversationId": "2eb15b161fda1d7484b017486a11f6c30b593f26d92d4402c77e4d5f650bd7cd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T20:57:35.627Z",
            "endTime": "2021-10-25T20:57:35.627Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhxAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhxAgAAAAAAAA==/",
            "_etag": "\"3900b766-0000-0100-0000-617736f10000\"",
            "_attachments": "attachments/",
            "_ts": 1635202801
        },
        {
            "id": "b07dde6148a3804df926250c9c81d913b4f6602014ec08b7e0a005da001b6af4",
            "conversationId": "b07dde6148a3804df926250c9c81d913b4f6602014ec08b7e0a005da001b6af4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache",
                "Nausea",
                "Red eye"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-25T20:57:38.539Z",
            "endTime": "2021-10-25T20:59:53.975Z",
            "timeSpent": 135,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhyAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhyAgAAAAAAAA==/",
            "_etag": "\"3900ee5b-0000-0100-0000-617728e20000\"",
            "_attachments": "attachments/",
            "_ts": 1635199202
        },
        {
            "id": "850cd61d1dd012b867631d2c33a982819a180520a3951eda3c5247622c533ae2",
            "conversationId": "850cd61d1dd012b867631d2c33a982819a180520a3951eda3c5247622c533ae2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Other type of contact with COVID-19 case"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2021-10-25T20:37:39.648Z",
            "endTime": "2021-10-25T20:45:50.023Z",
            "timeSpent": 490,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhzAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhzAgAAAAAAAA==/",
            "_etag": "\"3900b566-0000-0100-0000-617736f10000\"",
            "_attachments": "attachments/",
            "_ts": 1635202801
        },
        {
            "id": "55e20abc5b6aca5fff936ec4eddeee94d48b1d8b44b662e6983568f3eb58ae9e",
            "conversationId": "55e20abc5b6aca5fff936ec4eddeee94d48b1d8b44b662e6983568f3eb58ae9e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-25T21:00:30.689Z",
            "endTime": "2021-10-25T21:04:51.699Z",
            "timeSpent": 261,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh0AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh0AgAAAAAAAA==/",
            "_etag": "\"3900b366-0000-0100-0000-617736f10000\"",
            "_attachments": "attachments/",
            "_ts": 1635202801
        },
        {
            "id": "f7eb9df65bae34c1b222c661cc7e6da5f060233a34eb45c413c401a74bbfd227",
            "conversationId": "f7eb9df65bae34c1b222c661cc7e6da5f060233a34eb45c413c401a74bbfd227",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-25T21:32:45.645Z",
            "endTime": "2021-10-25T21:35:37.397Z",
            "timeSpent": 172,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh1AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh1AgAAAAAAAA==/",
            "_etag": "\"3900106f-0000-0100-0000-617745010000\"",
            "_attachments": "attachments/",
            "_ts": 1635206401
        },
        {
            "id": "8f766b4e96e1b9c96362857c50c632e867dfdc35b09980b0d8eed0b4df003960",
            "conversationId": "8f766b4e96e1b9c96362857c50c632e867dfdc35b09980b0d8eed0b4df003960",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T21:32:00.743Z",
            "endTime": "2021-10-25T21:32:00.743Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh2AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh2AgAAAAAAAA==/",
            "_etag": "\"39000e6f-0000-0100-0000-617745010000\"",
            "_attachments": "attachments/",
            "_ts": 1635206401
        },
        {
            "id": "363ff6ded6d8f6cf1b93852d9e79139f7d5cb97aefb8f6d787c86dd50673442d",
            "conversationId": "363ff6ded6d8f6cf1b93852d9e79139f7d5cb97aefb8f6d787c86dd50673442d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-25T21:32:01.842Z",
            "endTime": "2021-10-25T21:34:01.895Z",
            "timeSpent": 120,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh3AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh3AgAAAAAAAA==/",
            "_etag": "\"39000f6f-0000-0100-0000-617745010000\"",
            "_attachments": "attachments/",
            "_ts": 1635206401
        },
        {
            "id": "90aa4cd199e203347ef7189b082697f1516690d2fe4d21a73e2d8a819c26603a",
            "conversationId": "90aa4cd199e203347ef7189b082697f1516690d2fe4d21a73e2d8a819c26603a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T21:00:00Z",
            "endTime": "2021-10-25T21:36:57.604Z",
            "timeSpent": 2218,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh4AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh4AgAAAAAAAA==/",
            "_etag": "\"3900bb66-0000-0100-0000-617736f10000\"",
            "_attachments": "attachments/",
            "_ts": 1635202801
        },
        {
            "id": "ec52583afde322b2deb4f35a390e38c7a635a686ca5fef55150e511d74d1fc30",
            "conversationId": "ec52583afde322b2deb4f35a390e38c7a635a686ca5fef55150e511d74d1fc30",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T21:00:00Z",
            "endTime": "2021-10-25T21:42:14.231Z",
            "timeSpent": 2534,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh5AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh5AgAAAAAAAA==/",
            "_etag": "\"3900bc66-0000-0100-0000-617736f10000\"",
            "_attachments": "attachments/",
            "_ts": 1635202801
        },
        {
            "id": "05920e85520123033c7006478ada7aaa5015bba4156e1133be434911e944a3be",
            "conversationId": "05920e85520123033c7006478ada7aaa5015bba4156e1133be434911e944a3be",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-25T21:41:42.596Z",
            "endTime": "2021-10-25T21:41:42.596Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh6AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh6AgAAAAAAAA==/",
            "_etag": "\"3900116f-0000-0100-0000-617745010000\"",
            "_attachments": "attachments/",
            "_ts": 1635206401
        },
        {
            "id": "f6954c6193774d8cd784871c997068a43e0eace127f91ec43dc0aee924cd804b",
            "conversationId": "f6954c6193774d8cd784871c997068a43e0eace127f91ec43dc0aee924cd804b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-26T02:30:43.711Z",
            "endTime": "2021-10-26T02:34:19.156Z",
            "timeSpent": 215,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh7AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh7AgAAAAAAAA==/",
            "_etag": "\"390013e5-0000-0100-0000-61778b510000\"",
            "_attachments": "attachments/",
            "_ts": 1635224401
        },
        {
            "id": "cb82bdd2e6e4cf2d611bac2bcfb042d9d6726c0acb6bc9fe36088fd784431ce6",
            "conversationId": "cb82bdd2e6e4cf2d611bac2bcfb042d9d6726c0acb6bc9fe36088fd784431ce6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T02:00:00Z",
            "endTime": "2021-10-26T02:31:40.105Z",
            "timeSpent": 1900,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh8AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh8AgAAAAAAAA==/",
            "_etag": "\"3900c3da-0000-0100-0000-61777d420000\"",
            "_attachments": "attachments/",
            "_ts": 1635220802
        },
        {
            "id": "ae28f6fd1dc20cfe2caeec9df31a7aaa45f7fe82e00d20cb96411bd2ddc9a788",
            "conversationId": "ae28f6fd1dc20cfe2caeec9df31a7aaa45f7fe82e00d20cb96411bd2ddc9a788",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T02:00:00Z",
            "endTime": "2021-10-26T02:31:37.669Z",
            "timeSpent": 1898,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh9AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh9AgAAAAAAAA==/",
            "_etag": "\"3900c4da-0000-0100-0000-61777d420000\"",
            "_attachments": "attachments/",
            "_ts": 1635220802
        },
        {
            "id": "2480a785217872578a66567310c5b1f6e0ba4ad47fe7460c27b2a084ccd877a8",
            "conversationId": "2480a785217872578a66567310c5b1f6e0ba4ad47fe7460c27b2a084ccd877a8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-26T02:32:46.56Z",
            "endTime": "2021-10-26T02:35:27.041Z",
            "timeSpent": 160,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh+AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh+AgAAAAAAAA==/",
            "_etag": "\"390012e5-0000-0100-0000-61778b510000\"",
            "_attachments": "attachments/",
            "_ts": 1635224401
        },
        {
            "id": "dc6b0493dc2c69cc08b049639e7b69d1ebf965f3b745483d421ed87ee5b4bc97",
            "conversationId": "dc6b0493dc2c69cc08b049639e7b69d1ebf965f3b745483d421ed87ee5b4bc97",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T02:00:00Z",
            "endTime": "2021-10-26T02:35:45.383Z",
            "timeSpent": 2145,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh-AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh-AgAAAAAAAA==/",
            "_etag": "\"3900c6da-0000-0100-0000-61777d420000\"",
            "_attachments": "attachments/",
            "_ts": 1635220802
        },
        {
            "id": "cbb672a134579a69b1a72cb8b0629941ce62b3db582b6466f8caa80719509ffc",
            "conversationId": "cbb672a134579a69b1a72cb8b0629941ce62b3db582b6466f8caa80719509ffc",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T02:00:00Z",
            "endTime": "2021-10-26T02:36:30.007Z",
            "timeSpent": 2190,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiAAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiAAgAAAAAAAA==/",
            "_etag": "\"3900c7da-0000-0100-0000-61777d420000\"",
            "_attachments": "attachments/",
            "_ts": 1635220802
        },
        {
            "id": "b42741c3e47611eea3b864a6191d4e6055494d5b0679e072ae2847be32b62378",
            "conversationId": "b42741c3e47611eea3b864a6191d4e6055494d5b0679e072ae2847be32b62378",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T02:00:00Z",
            "endTime": "2021-10-26T02:36:45.985Z",
            "timeSpent": 2206,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiBAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiBAgAAAAAAAA==/",
            "_etag": "\"3900c8da-0000-0100-0000-61777d420000\"",
            "_attachments": "attachments/",
            "_ts": 1635220802
        },
        {
            "id": "dac3da61808b6edb985006b7b9b52f4863b4132160c990c7cd626fe9a58fc121",
            "conversationId": "dac3da61808b6edb985006b7b9b52f4863b4132160c990c7cd626fe9a58fc121",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T02:00:00Z",
            "endTime": "2021-10-26T02:36:55.228Z",
            "timeSpent": 2215,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiCAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiCAgAAAAAAAA==/",
            "_etag": "\"3900c9da-0000-0100-0000-61777d420000\"",
            "_attachments": "attachments/",
            "_ts": 1635220802
        },
        {
            "id": "9fa0d52576d8d44da0d4eb174786f4844c47f3238c0a89458bc3c77a11d626cf",
            "conversationId": "9fa0d52576d8d44da0d4eb174786f4844c47f3238c0a89458bc3c77a11d626cf",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Heartburn",
                "Arm injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T10:40:28.261Z",
            "endTime": "2021-10-26T10:43:30.302Z",
            "timeSpent": 182,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiDAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiDAgAAAAAAAA==/",
            "_etag": "\"3b00eb0b-0000-0100-0000-6177edc20000\"",
            "_attachments": "attachments/",
            "_ts": 1635249602
        },
        {
            "id": "8340fe949fe804da63c2174b7732f6590a54cc7de92e4caaddb77ba45ee4d423",
            "conversationId": "8340fe949fe804da63c2174b7732f6590a54cc7de92e4caaddb77ba45ee4d423",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T14:34:28.551Z",
            "endTime": "2021-10-26T14:34:33.029Z",
            "timeSpent": 4,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiEAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiEAgAAAAAAAA==/",
            "_etag": "\"00007a00-0000-0100-0000-617826030000\"",
            "_attachments": "attachments/",
            "_ts": 1635264003
        },
        {
            "id": "8bc842b1dff19e215b6112fbe16e55257a71ca9c019efb702e2f8456efb28d96",
            "conversationId": "8bc842b1dff19e215b6112fbe16e55257a71ca9c019efb702e2f8456efb28d96",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T14:35:25.16Z",
            "endTime": "2021-10-26T14:39:54.952Z",
            "timeSpent": 270,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiFAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiFAgAAAAAAAA==/",
            "_etag": "\"00007b00-0000-0100-0000-617826030000\"",
            "_attachments": "attachments/",
            "_ts": 1635264003
        },
        {
            "id": "8a43dd9f00a41ca13e60f4475c0fd8b1fb9e63c7210e4358ac001ff827401be6",
            "conversationId": "8a43dd9f00a41ca13e60f4475c0fd8b1fb9e63c7210e4358ac001ff827401be6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T14:41:55.24Z",
            "endTime": "2021-10-26T14:42:18Z",
            "timeSpent": 23,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiGAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiGAgAAAAAAAA==/",
            "_etag": "\"0000d75e-0000-0100-0000-617834120000\"",
            "_attachments": "attachments/",
            "_ts": 1635267602
        },
        {
            "id": "1627e3169501dd3a6ad7924a4c52e260d163bbf19620c568cc740111d9b7058b",
            "conversationId": "1627e3169501dd3a6ad7924a4c52e260d163bbf19620c568cc740111d9b7058b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Slow heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-26T14:48:22.142Z",
            "endTime": "2021-10-26T14:53:42.152Z",
            "timeSpent": 320,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiHAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiHAgAAAAAAAA==/",
            "_etag": "\"0000da5e-0000-0100-0000-617834120000\"",
            "_attachments": "attachments/",
            "_ts": 1635267602
        },
        {
            "id": "698eaf67ad3db35287f392fe25708854dcdd64e65fee527c26c2e8e9d9a9a29d",
            "conversationId": "698eaf67ad3db35287f392fe25708854dcdd64e65fee527c26c2e8e9d9a9a29d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-26T14:19:45.29Z",
            "endTime": "2021-10-26T14:30:07.875Z",
            "timeSpent": 623,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 6,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiIAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiIAgAAAAAAAA==/",
            "_etag": "\"00008200-0000-0100-0000-617826030000\"",
            "_attachments": "attachments/",
            "_ts": 1635264003
        },
        {
            "id": "04637c11e526d0f1e026942a146a199bf1406648a8b59e25779d98fff8d98204",
            "conversationId": "04637c11e526d0f1e026942a146a199bf1406648a8b59e25779d98fff8d98204",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T14:32:54.149Z",
            "endTime": "2021-10-26T14:33:03.135Z",
            "timeSpent": 9,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiJAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiJAgAAAAAAAA==/",
            "_etag": "\"0000d35e-0000-0100-0000-617834120000\"",
            "_attachments": "attachments/",
            "_ts": 1635267602
        },
        {
            "id": "f92970fd87426bf2112779fc7decd96a95d0a6b366bb03f8f86baf8553129794",
            "conversationId": "f92970fd87426bf2112779fc7decd96a95d0a6b366bb03f8f86baf8553129794",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T14:00:00Z",
            "endTime": "2021-10-26T14:42:25.952Z",
            "timeSpent": 2546,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiKAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiKAgAAAAAAAA==/",
            "_etag": "\"00009100-0000-0100-0000-617826030000\"",
            "_attachments": "attachments/",
            "_ts": 1635264003
        },
        {
            "id": "d97eb39a14908e2f992a157f19cc2dfe1a58c22a37df472178a92360fb2f7e79",
            "conversationId": "d97eb39a14908e2f992a157f19cc2dfe1a58c22a37df472178a92360fb2f7e79",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-26T14:44:49.292Z",
            "endTime": "2021-10-26T14:45:28.469Z",
            "timeSpent": 39,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiLAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiLAgAAAAAAAA==/",
            "_etag": "\"00008a00-0000-0100-0000-617826030000\"",
            "_attachments": "attachments/",
            "_ts": 1635264003
        },
        {
            "id": "14679ca738a8e5012a6a3a2750027dbd6d7180f00baac816b480f332195ef3fa",
            "conversationId": "14679ca738a8e5012a6a3a2750027dbd6d7180f00baac816b480f332195ef3fa",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T14:34:20.46Z",
            "endTime": "2021-10-26T14:34:20.46Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiMAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiMAgAAAAAAAA==/",
            "_etag": "\"0000d45e-0000-0100-0000-617834120000\"",
            "_attachments": "attachments/",
            "_ts": 1635267602
        },
        {
            "id": "71ceda154668ce8dce4459cccc4feb26274085220248dc51e2b88f289bb45dc1",
            "conversationId": "71ceda154668ce8dce4459cccc4feb26274085220248dc51e2b88f289bb45dc1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T14:35:22.345Z",
            "endTime": "2021-10-26T14:35:22.345Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiNAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiNAgAAAAAAAA==/",
            "_etag": "\"0000d55e-0000-0100-0000-617834120000\"",
            "_attachments": "attachments/",
            "_ts": 1635267602
        },
        {
            "id": "8b0e3458d65b26fb9f33e3d2810a02c5105a2f36a1a7e097166413e8cac04f2c",
            "conversationId": "8b0e3458d65b26fb9f33e3d2810a02c5105a2f36a1a7e097166413e8cac04f2c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T14:41:17.515Z",
            "endTime": "2021-10-26T14:41:47.313Z",
            "timeSpent": 30,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiOAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiOAgAAAAAAAA==/",
            "_etag": "\"0000d65e-0000-0100-0000-617834120000\"",
            "_attachments": "attachments/",
            "_ts": 1635267602
        },
        {
            "id": "f73f167909ecd2859385c98d80b2e5695d7632b81e11ccc385e43339cda061f8",
            "conversationId": "f73f167909ecd2859385c98d80b2e5695d7632b81e11ccc385e43339cda061f8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T14:00:00Z",
            "endTime": "2021-10-26T14:42:37.565Z",
            "timeSpent": 2558,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiPAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiPAgAAAAAAAA==/",
            "_etag": "\"00008500-0000-0100-0000-617826030000\"",
            "_attachments": "attachments/",
            "_ts": 1635264003
        },
        {
            "id": "82e8571c76250fb61fb6c845efab2600c1d894f291464ff3b0ca544b661976c8",
            "conversationId": "82e8571c76250fb61fb6c845efab2600c1d894f291464ff3b0ca544b661976c8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T14:42:52.828Z",
            "endTime": "2021-10-26T14:43:01.414Z",
            "timeSpent": 9,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiQAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiQAgAAAAAAAA==/",
            "_etag": "\"0000d85e-0000-0100-0000-617834120000\"",
            "_attachments": "attachments/",
            "_ts": 1635267602
        },
        {
            "id": "626fe3bc5869f4a20da3ebea0976be3b5ddc91b97ff1fb5a2e342032e6738004",
            "conversationId": "626fe3bc5869f4a20da3ebea0976be3b5ddc91b97ff1fb5a2e342032e6738004",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T14:00:00Z",
            "endTime": "2021-10-26T14:43:12.816Z",
            "timeSpent": 2593,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiRAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiRAgAAAAAAAA==/",
            "_etag": "\"00008700-0000-0100-0000-617826030000\"",
            "_attachments": "attachments/",
            "_ts": 1635264003
        },
        {
            "id": "df42ffc72b1aea5aad71371ad34065ad4d97189f3ddd5c15b0aa5f7da648d242",
            "conversationId": "df42ffc72b1aea5aad71371ad34065ad4d97189f3ddd5c15b0aa5f7da648d242",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T14:44:01.643Z",
            "endTime": "2021-10-26T14:44:23.631Z",
            "timeSpent": 22,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiSAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiSAgAAAAAAAA==/",
            "_etag": "\"0000d95e-0000-0100-0000-617834120000\"",
            "_attachments": "attachments/",
            "_ts": 1635267602
        },
        {
            "id": "1ad4e8e7b90041292314f8c767ffe91a22ba0210b49fc9ae8b31d05696925b7f",
            "conversationId": "1ad4e8e7b90041292314f8c767ffe91a22ba0210b49fc9ae8b31d05696925b7f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T14:00:00Z",
            "endTime": "2021-10-26T14:44:33.322Z",
            "timeSpent": 2673,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiTAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiTAgAAAAAAAA==/",
            "_etag": "\"00008900-0000-0100-0000-617826030000\"",
            "_attachments": "attachments/",
            "_ts": 1635264003
        },
        {
            "id": "c47dd1066c91bd666e5d436a025c4a829b3455ffd844cd0fa8b266587aba0e3f",
            "conversationId": "c47dd1066c91bd666e5d436a025c4a829b3455ffd844cd0fa8b266587aba0e3f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T14:00:00Z",
            "endTime": "2021-10-26T14:45:34.289Z",
            "timeSpent": 2734,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiUAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiUAgAAAAAAAA==/",
            "_etag": "\"00008b00-0000-0100-0000-617826030000\"",
            "_attachments": "attachments/",
            "_ts": 1635264003
        },
        {
            "id": "58b1d6ef555a160665854c82c8f0590ff9b71f92248334a4a24ae83ed0e295aa",
            "conversationId": "58b1d6ef555a160665854c82c8f0590ff9b71f92248334a4a24ae83ed0e295aa",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-26T15:16:59.454Z",
            "endTime": "2021-10-26T15:20:52.531Z",
            "timeSpent": 233,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 4,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiVAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiVAgAAAAAAAA==/",
            "_etag": "\"0000ce5e-0000-0100-0000-617834120000\"",
            "_attachments": "attachments/",
            "_ts": 1635267602
        },
        {
            "id": "a06060fc26358ea9216491ff95ce7cb501099a1d97f2537093f15f5edda5ec37",
            "conversationId": "a06060fc26358ea9216491ff95ce7cb501099a1d97f2537093f15f5edda5ec37",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-10-26T15:15:33.18Z",
            "endTime": "2021-10-26T15:21:01.406Z",
            "timeSpent": 328,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 6,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiWAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiWAgAAAAAAAA==/",
            "_etag": "\"0000cf5e-0000-0100-0000-617834120000\"",
            "_attachments": "attachments/",
            "_ts": 1635267602
        },
        {
            "id": "0194ae3a42dcddf003e1fcd2b76c859779c92b9e5bde298a47d11053e3f400bd",
            "conversationId": "0194ae3a42dcddf003e1fcd2b76c859779c92b9e5bde298a47d11053e3f400bd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T15:16:54.036Z",
            "endTime": "2021-10-26T15:16:54.036Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiXAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiXAgAAAAAAAA==/",
            "_etag": "\"0000cd5e-0000-0100-0000-617834120000\"",
            "_attachments": "attachments/",
            "_ts": 1635267602
        },
        {
            "id": "6228f75cb4dfa72848d4a014a4487cbbaea12b542d0b5668d247066959130741",
            "conversationId": "6228f75cb4dfa72848d4a014a4487cbbaea12b542d0b5668d247066959130741",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-26T15:07:03.647Z",
            "endTime": "2021-10-26T15:07:11.915Z",
            "timeSpent": 8,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiYAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiYAgAAAAAAAA==/",
            "_etag": "\"0000d05e-0000-0100-0000-617834120000\"",
            "_attachments": "attachments/",
            "_ts": 1635267602
        },
        {
            "id": "17612bd10c2cf3da6d36fa7eff7bd37935333fefa72ae4670d75ffb99798dc3b",
            "conversationId": "17612bd10c2cf3da6d36fa7eff7bd37935333fefa72ae4670d75ffb99798dc3b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-26T15:08:55.616Z",
            "endTime": "2021-10-26T15:10:42.381Z",
            "timeSpent": 107,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiZAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiZAgAAAAAAAA==/",
            "_etag": "\"0000cb5e-0000-0100-0000-617834120000\"",
            "_attachments": "attachments/",
            "_ts": 1635267602
        },
        {
            "id": "c54248e29ed823aa493a588287e7fa597bb53a0c87cebeedf2cef9764c3ecbe3",
            "conversationId": "c54248e29ed823aa493a588287e7fa597bb53a0c87cebeedf2cef9764c3ecbe3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T15:12:41.215Z",
            "endTime": "2021-10-26T15:12:49.762Z",
            "timeSpent": 9,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiaAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiaAgAAAAAAAA==/",
            "_etag": "\"0000d25e-0000-0100-0000-617834120000\"",
            "_attachments": "attachments/",
            "_ts": 1635267602
        },
        {
            "id": "098582d70124f740027018bdf5ddf18a90280dc00c85250fe848c88fe78dab28",
            "conversationId": "098582d70124f740027018bdf5ddf18a90280dc00c85250fe848c88fe78dab28",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T15:13:04.06Z",
            "endTime": "2021-10-26T15:13:26.157Z",
            "timeSpent": 22,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIibAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIibAgAAAAAAAA==/",
            "_etag": "\"0000d15e-0000-0100-0000-617834120000\"",
            "_attachments": "attachments/",
            "_ts": 1635267602
        },
        {
            "id": "960ea3285a15f22ab834d007e11433af07e05a98a564e983278def5c301ee1fb",
            "conversationId": "960ea3285a15f22ab834d007e11433af07e05a98a564e983278def5c301ee1fb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T16:19:49.96Z",
            "endTime": "2021-10-26T16:19:49.96Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIicAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIicAgAAAAAAAA==/",
            "_etag": "\"01003b0d-0000-0100-0000-617842260000\"",
            "_attachments": "attachments/",
            "_ts": 1635271206
        },
        {
            "id": "45be8662a271b2d9aee80b53c78b81a088806bf5b6c200e79480193f8dabad06",
            "conversationId": "45be8662a271b2d9aee80b53c78b81a088806bf5b6c200e79480193f8dabad06",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-26T16:20:31.326Z",
            "endTime": "2021-10-26T16:28:26.547Z",
            "timeSpent": 475,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIidAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIidAgAAAAAAAA==/",
            "_etag": "\"01003c0d-0000-0100-0000-617842260000\"",
            "_attachments": "attachments/",
            "_ts": 1635271206
        },
        {
            "id": "9411a40346b3ff6c74ed12a6e5e7b2ba602f0e1012d4378e60f3a672f6f278b6",
            "conversationId": "9411a40346b3ff6c74ed12a6e5e7b2ba602f0e1012d4378e60f3a672f6f278b6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [
                "Diabetes",
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-10-26T16:21:27.798Z",
            "endTime": "2021-10-26T16:28:18.928Z",
            "timeSpent": 411,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIieAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIieAgAAAAAAAA==/",
            "_etag": "\"01003d0d-0000-0100-0000-617842260000\"",
            "_attachments": "attachments/",
            "_ts": 1635271206
        },
        {
            "id": "2e610223f1999a92fe62b4fd557d31e86556e7f8f3cd6b0b867039f438c0690d",
            "conversationId": "2e610223f1999a92fe62b4fd557d31e86556e7f8f3cd6b0b867039f438c0690d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-26T16:33:44.009Z",
            "endTime": "2021-10-26T16:37:49.266Z",
            "timeSpent": 245,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 4,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIifAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIifAgAAAAAAAA==/",
            "_etag": "\"01003e0d-0000-0100-0000-617842260000\"",
            "_attachments": "attachments/",
            "_ts": 1635271206
        },
        {
            "id": "45c55a0ef179153f2eea084326faf919c36581b10a207e15faaf062de4c6f757",
            "conversationId": "45c55a0ef179153f2eea084326faf919c36581b10a207e15faaf062de4c6f757",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-26T16:34:32.128Z",
            "endTime": "2021-10-26T16:38:02.981Z",
            "timeSpent": 211,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIigAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIigAgAAAAAAAA==/",
            "_etag": "\"01003f0d-0000-0100-0000-617842260000\"",
            "_attachments": "attachments/",
            "_ts": 1635271206
        },
        {
            "id": "4961eae319467fcaa6e26a1ce8d8d17f2c9e52b83b58dd9bf0f617b1219dcafa",
            "conversationId": "4961eae319467fcaa6e26a1ce8d8d17f2c9e52b83b58dd9bf0f617b1219dcafa",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-26T16:45:42.924Z",
            "endTime": "2021-10-26T16:48:45.336Z",
            "timeSpent": 182,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 5,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIihAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIihAgAAAAAAAA==/",
            "_etag": "\"0100420d-0000-0100-0000-617842260000\"",
            "_attachments": "attachments/",
            "_ts": 1635271206
        },
        {
            "id": "e0db75276ea1d77927a97a2deb05f1d3aff1d210a22c34d4769eb7b6b62fd503",
            "conversationId": "e0db75276ea1d77927a97a2deb05f1d3aff1d210a22c34d4769eb7b6b62fd503",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-10-26T16:01:49.178Z",
            "endTime": "2021-10-26T16:08:10.374Z",
            "timeSpent": 381,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiiAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiiAgAAAAAAAA==/",
            "_etag": "\"0100400d-0000-0100-0000-617842260000\"",
            "_attachments": "attachments/",
            "_ts": 1635271206
        },
        {
            "id": "ba1b7d9cb147899a61107766d9b61843cd1ad58ac7969d519dd1c94a505a4ace",
            "conversationId": "ba1b7d9cb147899a61107766d9b61843cd1ad58ac7969d519dd1c94a505a4ace",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe joint pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-10-26T16:45:06.896Z",
            "endTime": "2021-10-26T16:48:48.952Z",
            "timeSpent": 222,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIijAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIijAgAAAAAAAA==/",
            "_etag": "\"0100410d-0000-0100-0000-617842260000\"",
            "_attachments": "attachments/",
            "_ts": 1635271206
        },
        {
            "id": "5dc204f4cb08779aba47cef59ca4a1798120e4553eea9606b0d28d90fb093d0d",
            "conversationId": "5dc204f4cb08779aba47cef59ca4a1798120e4553eea9606b0d28d90fb093d0d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T16:14:36.153Z",
            "endTime": "2021-10-26T16:14:36.153Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIikAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIikAgAAAAAAAA==/",
            "_etag": "\"0100490d-0000-0100-0000-617842260000\"",
            "_attachments": "attachments/",
            "_ts": 1635271206
        },
        {
            "id": "9d3ee2b0f1c6db5f4000aea48060184dd052e38968ee98cdacc71e2471fb924e",
            "conversationId": "9d3ee2b0f1c6db5f4000aea48060184dd052e38968ee98cdacc71e2471fb924e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T16:15:47.63Z",
            "endTime": "2021-10-26T16:15:47.63Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIilAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIilAgAAAAAAAA==/",
            "_etag": "\"0100470d-0000-0100-0000-617842260000\"",
            "_attachments": "attachments/",
            "_ts": 1635271206
        },
        {
            "id": "5f4a991ad42a3f37a22618c6610722aad3e4e919e83c12b5eea84eaa93643294",
            "conversationId": "5f4a991ad42a3f37a22618c6610722aad3e4e919e83c12b5eea84eaa93643294",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T16:18:46.349Z",
            "endTime": "2021-10-26T16:18:46.349Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIimAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIimAgAAAAAAAA==/",
            "_etag": "\"0100480d-0000-0100-0000-617842260000\"",
            "_attachments": "attachments/",
            "_ts": 1635271206
        },
        {
            "id": "bd3d97c0c28f82745c2be56c29c7443c16de091330d3c189f872a4776843145a",
            "conversationId": "bd3d97c0c28f82745c2be56c29c7443c16de091330d3c189f872a4776843145a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-26T15:59:36.851Z",
            "endTime": "2021-10-26T16:08:30.492Z",
            "timeSpent": 534,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 5,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIinAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIinAgAAAAAAAA==/",
            "_etag": "\"0100460d-0000-0100-0000-617842260000\"",
            "_attachments": "attachments/",
            "_ts": 1635271206
        },
        {
            "id": "62d3df328d09240a660960cd84ac3c43057f7a59e966ba0a6770ef7cedf68193",
            "conversationId": "62d3df328d09240a660960cd84ac3c43057f7a59e966ba0a6770ef7cedf68193",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Fever between 98 and 100.4�F (37 and 38�C)",
                "Fever between 100.4 and 104�F (38 and 40�C)"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-10-26T17:37:37.197Z",
            "endTime": "2021-10-26T17:51:20.621Z",
            "timeSpent": 823,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIioAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIioAgAAAAAAAA==/",
            "_etag": "\"01004c98-0000-0100-0000-617850330000\"",
            "_attachments": "attachments/",
            "_ts": 1635274803
        },
        {
            "id": "39d179059b992261cdc97e68b8bb4311f1046bc5d4c67d60f01c03c732b54db0",
            "conversationId": "39d179059b992261cdc97e68b8bb4311f1046bc5d4c67d60f01c03c732b54db0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T17:56:47.099Z",
            "endTime": "2021-10-26T17:56:47.099Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIipAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIipAgAAAAAAAA==/",
            "_etag": "\"0200661e-0000-0100-0000-61785e420000\"",
            "_attachments": "attachments/",
            "_ts": 1635278402
        },
        {
            "id": "2bb93e4a4b198a0e7440de129aab86583b48b1de56b2d4e062c8cde13a949a20",
            "conversationId": "2bb93e4a4b198a0e7440de129aab86583b48b1de56b2d4e062c8cde13a949a20",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T17:56:50.992Z",
            "endTime": "2021-10-26T17:56:50.992Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiqAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiqAgAAAAAAAA==/",
            "_etag": "\"0200671e-0000-0100-0000-61785e420000\"",
            "_attachments": "attachments/",
            "_ts": 1635278402
        },
        {
            "id": "216cee7ff1533f99ddb869dbf6812c6ef55966ee761a6441a85a0e587b3d7c5d",
            "conversationId": "216cee7ff1533f99ddb869dbf6812c6ef55966ee761a6441a85a0e587b3d7c5d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Papule or nodule smaller than 1 cm in diameter",
                "Fever between 100.4 and 104�F (38 and 40�C)",
                "Symmetrical flaccid paresis in upper limbs (mainly hands)",
                "Difficulty completely closing one eye",
                "Neurological deficit",
                " since less than 24 hours",
                "Fetal membranes rupture",
                " purulent or malodorous amniotic fluid"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-26T17:59:08.183Z",
            "endTime": "2021-10-26T18:07:48.936Z",
            "timeSpent": 521,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 6,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIirAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIirAgAAAAAAAA==/",
            "_etag": "\"02006a1e-0000-0100-0000-61785e420000\"",
            "_attachments": "attachments/",
            "_ts": 1635278402
        },
        {
            "id": "ee538be6f23eab54329d1a60e788177e0d221598304de4d4155e317022361c0d",
            "conversationId": "ee538be6f23eab54329d1a60e788177e0d221598304de4d4155e317022361c0d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T18:19:22.573Z",
            "endTime": "2021-10-26T18:19:22.573Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIisAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIisAgAAAAAAAA==/",
            "_etag": "\"0200641e-0000-0100-0000-61785e420000\"",
            "_attachments": "attachments/",
            "_ts": 1635278402
        },
        {
            "id": "e4441fcda3024a2c6675540bca62b46d54f3a0996b8c5c2f5e2513c06d54beab",
            "conversationId": "e4441fcda3024a2c6675540bca62b46d54f3a0996b8c5c2f5e2513c06d54beab",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T18:19:22.814Z",
            "endTime": "2021-10-26T18:52:12.773Z",
            "timeSpent": 1970,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIitAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIitAgAAAAAAAA==/",
            "_etag": "\"0200651e-0000-0100-0000-61785e420000\"",
            "_attachments": "attachments/",
            "_ts": 1635278402
        },
        {
            "id": "f83cb506d45afe37bb27b5059f66fa510602fbd8cfdfeaf21b537d7cb5679fd9",
            "conversationId": "f83cb506d45afe37bb27b5059f66fa510602fbd8cfdfeaf21b537d7cb5679fd9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [
                "Chronic NSAIDs use",
                "Opioid use",
                "History of tonsillectomy",
                "Smoking",
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-10-26T18:52:25.364Z",
            "endTime": "2021-10-26T18:53:06.223Z",
            "timeSpent": 41,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiuAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiuAgAAAAAAAA==/",
            "_etag": "\"0200055e-0000-0100-0000-61786c550000\"",
            "_attachments": "attachments/",
            "_ts": 1635282005
        },
        {
            "id": "4467d46c71dba94f7ea515cfdfc4e6c760738942565c9d146d934efaa58f30dd",
            "conversationId": "4467d46c71dba94f7ea515cfdfc4e6c760738942565c9d146d934efaa58f30dd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Redness around eyes"
            ],
            "riskFactors": [
                "Diabetes",
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2021-10-26T19:37:22.363Z",
            "endTime": "2021-10-26T19:59:14.942Z",
            "timeSpent": 1313,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIivAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIivAgAAAAAAAA==/",
            "_etag": "\"0200025e-0000-0100-0000-61786c550000\"",
            "_attachments": "attachments/",
            "_ts": 1635282005
        },
        {
            "id": "2965427dd1846e19ffc168181f145005b84d8c48730b81f6be687d89022571e6",
            "conversationId": "2965427dd1846e19ffc168181f145005b84d8c48730b81f6be687d89022571e6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2021-10-26T20:17:48.396Z",
            "endTime": "2021-10-26T20:19:50.133Z",
            "timeSpent": 122,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiwAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiwAgAAAAAAAA==/",
            "_etag": "\"02001694-0000-0100-0000-61787a640000\"",
            "_attachments": "attachments/",
            "_ts": 1635285604
        },
        {
            "id": "8beba5c7961c7a5055a36f3001fb24903fde8dfb3fb7c1e3e7ee6834bc11be3a",
            "conversationId": "8beba5c7961c7a5055a36f3001fb24903fde8dfb3fb7c1e3e7ee6834bc11be3a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-26T20:32:04.23Z",
            "endTime": "2021-10-26T20:35:33.135Z",
            "timeSpent": 209,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIixAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIixAgAAAAAAAA==/",
            "_etag": "\"02000abc-0000-0100-0000-617888710000\"",
            "_attachments": "attachments/",
            "_ts": 1635289201
        },
        {
            "id": "fc37200b64f383ff3a769aaab1eda2a90a6952a71359dad2db903adbff559926",
            "conversationId": "fc37200b64f383ff3a769aaab1eda2a90a6952a71359dad2db903adbff559926",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-10-26T20:35:53.898Z",
            "endTime": "2021-10-26T20:38:40.759Z",
            "timeSpent": 167,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiyAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiyAgAAAAAAAA==/",
            "_etag": "\"02000dbc-0000-0100-0000-617888710000\"",
            "_attachments": "attachments/",
            "_ts": 1635289201
        },
        {
            "id": "9f6db2db1edaf44795d8d0810b399e7af5fe6e97fccb9230037a74a75b83555d",
            "conversationId": "9f6db2db1edaf44795d8d0810b399e7af5fe6e97fccb9230037a74a75b83555d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T20:39:42.942Z",
            "endTime": "2021-10-26T20:39:59.304Z",
            "timeSpent": 16,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIizAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIizAgAAAAAAAA==/",
            "_etag": "\"02000ebc-0000-0100-0000-617888710000\"",
            "_attachments": "attachments/",
            "_ts": 1635289201
        },
        {
            "id": "75954ac458f7182d1d7162fdc98fe2fc732bcbc357ce510d60f9601904ed0b1e",
            "conversationId": "75954ac458f7182d1d7162fdc98fe2fc732bcbc357ce510d60f9601904ed0b1e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T20:39:42.812Z",
            "endTime": "2021-10-26T20:39:42.812Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi0AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi0AgAAAAAAAA==/",
            "_etag": "\"020010bc-0000-0100-0000-617888710000\"",
            "_attachments": "attachments/",
            "_ts": 1635289201
        },
        {
            "id": "95428210c73740d237519111724c838aa83d0afc99e7900687524eec89d97aaa",
            "conversationId": "95428210c73740d237519111724c838aa83d0afc99e7900687524eec89d97aaa",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T20:40:31.654Z",
            "endTime": "2021-10-26T20:40:31.654Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi1AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi1AgAAAAAAAA==/",
            "_etag": "\"02000fbc-0000-0100-0000-617888710000\"",
            "_attachments": "attachments/",
            "_ts": 1635289201
        },
        {
            "id": "12575bb75ec96ced0ee9203cce94308d49412fdb76e333d1a12fc5218fd6de14",
            "conversationId": "12575bb75ec96ced0ee9203cce94308d49412fdb76e333d1a12fc5218fd6de14",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-10-26T20:44:20.699Z",
            "endTime": "2021-10-26T20:47:34.558Z",
            "timeSpent": 194,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi2AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi2AgAAAAAAAA==/",
            "_etag": "\"02000cbc-0000-0100-0000-617888710000\"",
            "_attachments": "attachments/",
            "_ts": 1635289201
        },
        {
            "id": "2bdd5b17d547d27f0096b7edbaaa278dcedec5e32e83abb7ba385043587e17af",
            "conversationId": "2bdd5b17d547d27f0096b7edbaaa278dcedec5e32e83abb7ba385043587e17af",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-26T20:50:28.261Z",
            "endTime": "2021-10-26T20:50:40.329Z",
            "timeSpent": 12,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi3AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi3AgAAAAAAAA==/",
            "_etag": "\"02001d94-0000-0100-0000-61787a640000\"",
            "_attachments": "attachments/",
            "_ts": 1635285604
        },
        {
            "id": "36320732f7e50656a2a810aaf3e34dad664b00c84d7e7f138fcc87d3d79352e7",
            "conversationId": "36320732f7e50656a2a810aaf3e34dad664b00c84d7e7f138fcc87d3d79352e7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-26T20:52:00.743Z",
            "endTime": "2021-10-26T20:53:06.808Z",
            "timeSpent": 66,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi4AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi4AgAAAAAAAA==/",
            "_etag": "\"02000bbc-0000-0100-0000-617888710000\"",
            "_attachments": "attachments/",
            "_ts": 1635289201
        },
        {
            "id": "29abf5ac62cbd8ab95de4c5dfada461aa937848bd7df268a006dea0a0c3477cf",
            "conversationId": "29abf5ac62cbd8ab95de4c5dfada461aa937848bd7df268a006dea0a0c3477cf",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T20:17:45.168Z",
            "endTime": "2021-10-26T20:17:45.168Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi5AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi5AgAAAAAAAA==/",
            "_etag": "\"02002094-0000-0100-0000-61787a640000\"",
            "_attachments": "attachments/",
            "_ts": 1635285604
        },
        {
            "id": "9cf75d92018aad935a20bed38dec79a91f7282a8f4544a3848490cd2f0d8914c",
            "conversationId": "9cf75d92018aad935a20bed38dec79a91f7282a8f4544a3848490cd2f0d8914c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Coma"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-26T19:59:50.762Z",
            "endTime": "2021-10-26T20:01:08.044Z",
            "timeSpent": 77,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 1,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi6AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi6AgAAAAAAAA==/",
            "_etag": "\"02001f94-0000-0100-0000-61787a640000\"",
            "_attachments": "attachments/",
            "_ts": 1635285604
        },
        {
            "id": "9763730297caa3930db04e4c3bc6ce4f9b47285accc75a92a8a602229c7cada8",
            "conversationId": "9763730297caa3930db04e4c3bc6ce4f9b47285accc75a92a8a602229c7cada8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-26T20:00:00Z",
            "endTime": "2021-10-26T20:28:20.522Z",
            "timeSpent": 1701,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi7AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi7AgAAAAAAAA==/",
            "_etag": "\"02002194-0000-0100-0000-61787a640000\"",
            "_attachments": "attachments/",
            "_ts": 1635285604
        },
        {
            "id": "d5aca6ba2eacd80bb5dc16c189aa4a141b7b4b50299c3e182f4a50510d0c4d11",
            "conversationId": "d5aca6ba2eacd80bb5dc16c189aa4a141b7b4b50299c3e182f4a50510d0c4d11",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-26T20:40:33.745Z",
            "endTime": "2021-10-26T20:41:03.645Z",
            "timeSpent": 30,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi8AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi8AgAAAAAAAA==/",
            "_etag": "\"020011bc-0000-0100-0000-617888710000\"",
            "_attachments": "attachments/",
            "_ts": 1635289201
        },
        {
            "id": "7ebbafa894ec35b59eccd3cb34fca60ea698d74e3902693efee0c3fa3f402b94",
            "conversationId": "7ebbafa894ec35b59eccd3cb34fca60ea698d74e3902693efee0c3fa3f402b94",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-27T01:50:37.851Z",
            "endTime": "2021-10-27T01:57:05.227Z",
            "timeSpent": 387,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi9AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi9AgAAAAAAAA==/",
            "_etag": "\"03005549-0000-0100-0000-6178cec20000\"",
            "_attachments": "attachments/",
            "_ts": 1635307202
        },
        {
            "id": "e7f45102925bfb5b9099360c7ad2ed554b4072f6fae9b90fab5d20406a7cc920",
            "conversationId": "e7f45102925bfb5b9099360c7ad2ed554b4072f6fae9b90fab5d20406a7cc920",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-27T01:53:31.141Z",
            "endTime": "2021-10-27T01:53:31.141Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi+AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi+AgAAAAAAAA==/",
            "_etag": "\"03005249-0000-0100-0000-6178cec20000\"",
            "_attachments": "attachments/",
            "_ts": 1635307202
        },
        {
            "id": "51f68dee5a3d1d91af16c7bc40c1ead634097c2d6fcf134415ecb1a3d2f16e64",
            "conversationId": "51f68dee5a3d1d91af16c7bc40c1ead634097c2d6fcf134415ecb1a3d2f16e64",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-27T01:53:32.234Z",
            "endTime": "2021-10-27T01:53:40.853Z",
            "timeSpent": 9,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi-AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi-AgAAAAAAAA==/",
            "_etag": "\"03005349-0000-0100-0000-6178cec20000\"",
            "_attachments": "attachments/",
            "_ts": 1635307202
        },
        {
            "id": "ec23896243947cfa8ef3689a61b13a6d61ee40247ed0cc1bd9a056eb43792fb6",
            "conversationId": "ec23896243947cfa8ef3689a61b13a6d61ee40247ed0cc1bd9a056eb43792fb6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-27T01:54:48.514Z",
            "endTime": "2021-10-27T01:57:07.174Z",
            "timeSpent": 139,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjAAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjAAgAAAAAAAA==/",
            "_etag": "\"03005449-0000-0100-0000-6178cec20000\"",
            "_attachments": "attachments/",
            "_ts": 1635307202
        },
        {
            "id": "b8ca8bff378d0a59c137931a58d9a81236b4bbb61346429b6e621cf8d7411243",
            "conversationId": "b8ca8bff378d0a59c137931a58d9a81236b4bbb61346429b6e621cf8d7411243",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-27T02:41:44.651Z",
            "endTime": "2021-10-27T02:43:23.172Z",
            "timeSpent": 99,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjBAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjBAgAAAAAAAA==/",
            "_etag": "\"03004349-0000-0100-0000-6178cec20000\"",
            "_attachments": "attachments/",
            "_ts": 1635307202
        },
        {
            "id": "3ee4c62b8c188b71970f38d6611a5c4dd9ab821345e0a4f33d8c4b9d7b4ebcaa",
            "conversationId": "3ee4c62b8c188b71970f38d6611a5c4dd9ab821345e0a4f33d8c4b9d7b4ebcaa",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-27T02:42:35.355Z",
            "endTime": "2021-10-27T02:43:24.148Z",
            "timeSpent": 49,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjCAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjCAgAAAAAAAA==/",
            "_etag": "\"03004449-0000-0100-0000-6178cec20000\"",
            "_attachments": "attachments/",
            "_ts": 1635307202
        },
        {
            "id": "16f108ac858c1e833c89bf78cb254a0be54693775212b7498e0c17f887826c58",
            "conversationId": "16f108ac858c1e833c89bf78cb254a0be54693775212b7498e0c17f887826c58",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-27T02:00:00Z",
            "endTime": "2021-10-27T02:46:12.845Z",
            "timeSpent": 2773,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjDAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjDAgAAAAAAAA==/",
            "_etag": "\"03004549-0000-0100-0000-6178cec20000\"",
            "_attachments": "attachments/",
            "_ts": 1635307202
        },
        {
            "id": "2ed4861a4a52ace4d09a0e3da63e65ee339f0bdde469481c61ac633893ff9927",
            "conversationId": "2ed4861a4a52ace4d09a0e3da63e65ee339f0bdde469481c61ac633893ff9927",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-27T02:00:00Z",
            "endTime": "2021-10-27T02:47:05.158Z",
            "timeSpent": 2825,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjEAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjEAgAAAAAAAA==/",
            "_etag": "\"03004649-0000-0100-0000-6178cec20000\"",
            "_attachments": "attachments/",
            "_ts": 1635307202
        },
        {
            "id": "c3f8afbab395e859fae874e2161ef78b10df337051875d7f51c97b87ed17eec1",
            "conversationId": "c3f8afbab395e859fae874e2161ef78b10df337051875d7f51c97b87ed17eec1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-27T02:50:11.228Z",
            "endTime": "2021-10-27T02:51:56.149Z",
            "timeSpent": 105,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjFAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjFAgAAAAAAAA==/",
            "_etag": "\"0300ed6f-0000-0100-0000-6178dcd10000\"",
            "_attachments": "attachments/",
            "_ts": 1635310801
        },
        {
            "id": "887c6fe5023c215f1c76170ee29d675bb359e202c3eb72eff0ed6c8fb0d1ceef",
            "conversationId": "887c6fe5023c215f1c76170ee29d675bb359e202c3eb72eff0ed6c8fb0d1ceef",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-27T02:24:59.835Z",
            "endTime": "2021-10-27T02:29:52.743Z",
            "timeSpent": 293,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjGAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjGAgAAAAAAAA==/",
            "_etag": "\"03004849-0000-0100-0000-6178cec20000\"",
            "_attachments": "attachments/",
            "_ts": 1635307202
        },
        {
            "id": "04dd9da0cfcaa03f68fa9b7e89acc6584c492c4b0c435212fb079ec97ddf0e6c",
            "conversationId": "04dd9da0cfcaa03f68fa9b7e89acc6584c492c4b0c435212fb079ec97ddf0e6c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-27T02:00:00Z",
            "endTime": "2021-10-27T02:36:08.266Z",
            "timeSpent": 2168,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjHAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjHAgAAAAAAAA==/",
            "_etag": "\"03004949-0000-0100-0000-6178cec20000\"",
            "_attachments": "attachments/",
            "_ts": 1635307202
        },
        {
            "id": "59814213a2a36f82c7da7b69dc5ab777ccdbec1824c888f3a3752929707774bd",
            "conversationId": "59814213a2a36f82c7da7b69dc5ab777ccdbec1824c888f3a3752929707774bd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-27T02:51:26.764Z",
            "endTime": "2021-10-27T02:51:36.896Z",
            "timeSpent": 10,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjIAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjIAgAAAAAAAA==/",
            "_etag": "\"0300ec6f-0000-0100-0000-6178dcd10000\"",
            "_attachments": "attachments/",
            "_ts": 1635310801
        },
        {
            "id": "6ecce63d621dee75edea62bf018d158fa153c13b7f26a7dcc51fd6f4aba358df",
            "conversationId": "6ecce63d621dee75edea62bf018d158fa153c13b7f26a7dcc51fd6f4aba358df",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-27T02:52:42.192Z",
            "endTime": "2021-10-27T02:56:09.965Z",
            "timeSpent": 208,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjJAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjJAgAAAAAAAA==/",
            "_etag": "\"0300ee6f-0000-0100-0000-6178dcd10000\"",
            "_attachments": "attachments/",
            "_ts": 1635310801
        },
        {
            "id": "9939bcb6a1ca75ef0445e015ddeef4b81a033309a595c8a108a3466232816e35",
            "conversationId": "9939bcb6a1ca75ef0445e015ddeef4b81a033309a595c8a108a3466232816e35",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Runny nose"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-27T02:53:21.242Z",
            "endTime": "2021-10-27T02:56:12.633Z",
            "timeSpent": 171,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjKAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjKAgAAAAAAAA==/",
            "_etag": "\"0300ef6f-0000-0100-0000-6178dcd10000\"",
            "_attachments": "attachments/",
            "_ts": 1635310801
        },
        {
            "id": "0cef7b79b2a33048bf996fe275e347dce27faa1dea9eea926156f69f74aa8330",
            "conversationId": "0cef7b79b2a33048bf996fe275e347dce27faa1dea9eea926156f69f74aa8330",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-27T02:25:40.12Z",
            "endTime": "2021-10-27T02:29:49.432Z",
            "timeSpent": 249,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjLAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjLAgAAAAAAAA==/",
            "_etag": "\"03004d49-0000-0100-0000-6178cec20000\"",
            "_attachments": "attachments/",
            "_ts": 1635307202
        },
        {
            "id": "c0849b5545c69d7c16073ebde30373159457fc3cfd665d9b419168b55624a943",
            "conversationId": "c0849b5545c69d7c16073ebde30373159457fc3cfd665d9b419168b55624a943",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-27T03:02:41.04Z",
            "endTime": "2021-10-27T03:09:09.945Z",
            "timeSpent": 389,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjMAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjMAgAAAAAAAA==/",
            "_etag": "\"0300f26f-0000-0100-0000-6178dcd10000\"",
            "_attachments": "attachments/",
            "_ts": 1635310801
        },
        {
            "id": "f5e8e3fc526adbe966d4bc86d53409e38aa23c3cd97d7b66ff75c12f4285bb6c",
            "conversationId": "f5e8e3fc526adbe966d4bc86d53409e38aa23c3cd97d7b66ff75c12f4285bb6c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-27T03:03:18.353Z",
            "endTime": "2021-10-27T03:03:18.353Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjNAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjNAgAAAAAAAA==/",
            "_etag": "\"0300f16f-0000-0100-0000-6178dcd10000\"",
            "_attachments": "attachments/",
            "_ts": 1635310801
        },
        {
            "id": "cdb7ed8b4dff86a36a15ee398a4c1f244e220857114ffdc2537bfafb03ec1742",
            "conversationId": "cdb7ed8b4dff86a36a15ee398a4c1f244e220857114ffdc2537bfafb03ec1742",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-27T03:03:15.286Z",
            "endTime": "2021-10-27T03:03:15.286Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjOAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjOAgAAAAAAAA==/",
            "_etag": "\"0300f06f-0000-0100-0000-6178dcd10000\"",
            "_attachments": "attachments/",
            "_ts": 1635310801
        },
        {
            "id": "3e16047f27fb937cab598b9888a767961711614cc100fb5dccf929b1377b9ace",
            "conversationId": "3e16047f27fb937cab598b9888a767961711614cc100fb5dccf929b1377b9ace",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-27T03:06:25.81Z",
            "endTime": "2021-10-27T03:09:11.319Z",
            "timeSpent": 166,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjPAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjPAgAAAAAAAA==/",
            "_etag": "\"0300f36f-0000-0100-0000-6178dcd10000\"",
            "_attachments": "attachments/",
            "_ts": 1635310801
        },
    

    
        {
            "id": "68dfeb29fda4bbe953616ef663d935c89c575af14ef44f7984fb4c78606f5fe5",
            "conversationId": "68dfeb29fda4bbe953616ef663d935c89c575af14ef44f7984fb4c78606f5fe5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-27T10:42:52.667Z",
            "endTime": "2021-10-27T10:43:01.524Z",
            "timeSpent": 9,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjQAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjQAgAAAAAAAA==/",
            "_etag": "\"04008b2a-0000-0100-0000-61793f420000\"",
            "_attachments": "attachments/",
            "_ts": 1635336002
        },
        {
            "id": "725bcac79515e7f3fafbbc7793f6c89c759b90069eff4e82c21272f5b046768e",
            "conversationId": "725bcac79515e7f3fafbbc7793f6c89c759b90069eff4e82c21272f5b046768e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain",
                "Fast heartbeat",
                "Sinus pain or pressure"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-10-27T10:43:58.801Z",
            "endTime": "2021-10-27T10:45:01.654Z",
            "timeSpent": 63,
            "isNoteGenerated": "yes",
            "npsGroup": "Passive",
            "npsScore": 7,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjRAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjRAgAAAAAAAA==/",
            "_etag": "\"04008c2a-0000-0100-0000-61793f420000\"",
            "_attachments": "attachments/",
            "_ts": 1635336002
        },
        {
            "id": "14a498efdd4a4c322aa6ff962180c14b1dbf8f465aedfaf11c2620b0d42a1e90",
            "conversationId": "14a498efdd4a4c322aa6ff962180c14b1dbf8f465aedfaf11c2620b0d42a1e90",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-27T16:42:23.124Z",
            "endTime": "2021-10-27T16:46:46.199Z",
            "timeSpent": 263,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjSAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjSAgAAAAAAAA==/",
            "_etag": "\"06009fc8-0000-0100-0000-6179a1b40000\"",
            "_attachments": "attachments/",
            "_ts": 1635361204
        },
        {
            "id": "edfdd88ee9ffae9cbb81bdfa12f1bc35d4487081c1646d9c1aee96d5a8d6ba4d",
            "conversationId": "edfdd88ee9ffae9cbb81bdfa12f1bc35d4487081c1646d9c1aee96d5a8d6ba4d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-10-27T17:56:43.165Z",
            "endTime": "2021-10-27T17:59:51.174Z",
            "timeSpent": 188,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjTAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjTAgAAAAAAAA==/",
            "_etag": "\"060070fe-0000-0100-0000-6179afc30000\"",
            "_attachments": "attachments/",
            "_ts": 1635364803
        },
        {
            "id": "b1befc9881639685ff51393edbdd7d1cffc30f29365fc0563c3ba6cb3460e069",
            "conversationId": "b1befc9881639685ff51393edbdd7d1cffc30f29365fc0563c3ba6cb3460e069",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [
                "Smoking",
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-10-27T18:55:13.256Z",
            "endTime": "2021-10-27T19:05:15.27Z",
            "timeSpent": 602,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjUAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjUAgAAAAAAAA==/",
            "_etag": "\"0700f436-0000-0100-0000-6179bdd40000\"",
            "_attachments": "attachments/",
            "_ts": 1635368404
        },
        {
            "id": "df8152aa77d69d62940c9f9d7281c5db8e59fb954037206550168c30d797f866",
            "conversationId": "df8152aa77d69d62940c9f9d7281c5db8e59fb954037206550168c30d797f866",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [
                "Chronic NSAIDs use",
                "Opioid use",
                "History of tonsillectomy",
                "Smoking",
                "History of anaphylactic reactions",
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2021-10-27T18:17:59.59Z",
            "endTime": "2021-10-27T18:21:10.559Z",
            "timeSpent": 191,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjVAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjVAgAAAAAAAA==/",
            "_etag": "\"06006dfe-0000-0100-0000-6179afc30000\"",
            "_attachments": "attachments/",
            "_ts": 1635364803
        },
        {
            "id": "1e94dc0cc2fd0805178cc221bbd2a8591c76e912ff50bbdf3d535ed19f586457",
            "conversationId": "1e94dc0cc2fd0805178cc221bbd2a8591c76e912ff50bbdf3d535ed19f586457",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-10-27T18:12:08.125Z",
            "endTime": "2021-10-27T18:14:48.153Z",
            "timeSpent": 160,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjWAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjWAgAAAAAAAA==/",
            "_etag": "\"06006cfe-0000-0100-0000-6179afc30000\"",
            "_attachments": "attachments/",
            "_ts": 1635364803
        },
        {
            "id": "b7d5cdadaec038788eebc3d2e6686e704551b0d46656d839bbfb42f49b454fd0",
            "conversationId": "b7d5cdadaec038788eebc3d2e6686e704551b0d46656d839bbfb42f49b454fd0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-27T18:12:08.043Z",
            "endTime": "2021-10-27T18:12:08.043Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjXAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjXAgAAAAAAAA==/",
            "_etag": "\"06006ffe-0000-0100-0000-6179afc30000\"",
            "_attachments": "attachments/",
            "_ts": 1635364803
        },
        {
            "id": "5c2a1746f1eaf5c3d07c9e14e2f7904896278f718629cc3365f96208765669c0",
            "conversationId": "5c2a1746f1eaf5c3d07c9e14e2f7904896278f718629cc3365f96208765669c0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-28T12:22:28.688Z",
            "endTime": "2021-10-28T12:22:33.703Z",
            "timeSpent": 5,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjYAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjYAgAAAAAAAA==/",
            "_etag": "\"0900fab1-0000-0100-0000-617aace20000\"",
            "_attachments": "attachments/",
            "_ts": 1635429602
        },
        {
            "id": "41097181c93f3cebb55c29db749604cf4b12196747dbaeec4a946dcdd4f40c38",
            "conversationId": "41097181c93f3cebb55c29db749604cf4b12196747dbaeec4a946dcdd4f40c38",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-28T12:40:52.12Z",
            "endTime": "2021-10-28T12:40:52.12Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjZAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjZAgAAAAAAAA==/",
            "_etag": "\"0a00f909-0000-0100-0000-617abaf20000\"",
            "_attachments": "attachments/",
            "_ts": 1635433202
        },
        {
            "id": "585d8883abbbaf8923c9d9320a3dd4e8caceecc7083c4386f1af160ff2bb281f",
            "conversationId": "585d8883abbbaf8923c9d9320a3dd4e8caceecc7083c4386f1af160ff2bb281f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "13-17",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-28T12:41:36.711Z",
            "endTime": "2021-10-28T12:41:36.711Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjaAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjaAgAAAAAAAA==/",
            "_etag": "\"0a00fa09-0000-0100-0000-617abaf20000\"",
            "_attachments": "attachments/",
            "_ts": 1635433202
        },
        {
            "id": "765d5eea67dc8c3915d7129251b46dc29477d297c03d480c07ecaa816962e406",
            "conversationId": "765d5eea67dc8c3915d7129251b46dc29477d297c03d480c07ecaa816962e406",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-28T12:00:00Z",
            "endTime": "2021-10-28T12:16:08.926Z",
            "timeSpent": 969,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjbAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjbAgAAAAAAAA==/",
            "_etag": "\"0900f6b1-0000-0100-0000-617aace20000\"",
            "_attachments": "attachments/",
            "_ts": 1635429602
        },
        {
            "id": "e73b863cc1842f28bc35029863d176e80f5e921f08144f055997e434c0426676",
            "conversationId": "e73b863cc1842f28bc35029863d176e80f5e921f08144f055997e434c0426676",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-28T12:16:37.457Z",
            "endTime": "2021-10-28T12:16:37.457Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjcAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjcAgAAAAAAAA==/",
            "_etag": "\"0900f7b1-0000-0100-0000-617aace20000\"",
            "_attachments": "attachments/",
            "_ts": 1635429602
        },
        {
            "id": "55e7cc5aaec926473b6224d4d0da80a2dd0231ad39bd689974a68c671f358ee6",
            "conversationId": "55e7cc5aaec926473b6224d4d0da80a2dd0231ad39bd689974a68c671f358ee6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-28T12:41:13.757Z",
            "endTime": "2021-10-28T12:41:13.757Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjdAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjdAgAAAAAAAA==/",
            "_etag": "\"0a00fc09-0000-0100-0000-617abaf20000\"",
            "_attachments": "attachments/",
            "_ts": 1635433202
        },
        {
            "id": "fc7ea48fd2995fccf74aeb9e3071a4ca44b8321f6f952d9609801b1e6174a1a8",
            "conversationId": "fc7ea48fd2995fccf74aeb9e3071a4ca44b8321f6f952d9609801b1e6174a1a8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Yes"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-28T12:59:37.353Z",
            "endTime": "2021-10-28T12:59:57.569Z",
            "timeSpent": 20,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjeAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjeAgAAAAAAAA==/",
            "_etag": "\"0900f9b1-0000-0100-0000-617aace20000\"",
            "_attachments": "attachments/",
            "_ts": 1635429602
        },
        {
            "id": "aa1b90a24294900c0556c791ff9abc05d37a03037fd43287aa26b21f73d99628",
            "conversationId": "aa1b90a24294900c0556c791ff9abc05d37a03037fd43287aa26b21f73d99628",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-28T12:17:05.559Z",
            "endTime": "2021-10-28T12:17:05.559Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjfAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjfAgAAAAAAAA==/",
            "_etag": "\"0900fdb1-0000-0100-0000-617aace20000\"",
            "_attachments": "attachments/",
            "_ts": 1635429602
        },
        {
            "id": "2c3ff92015e5fc044288c3028f7fa6c8c8b83f140701562ac9e495dac56baea7",
            "conversationId": "2c3ff92015e5fc044288c3028f7fa6c8c8b83f140701562ac9e495dac56baea7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-28T12:41:57.715Z",
            "endTime": "2021-10-28T12:41:57.715Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjgAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjgAgAAAAAAAA==/",
            "_etag": "\"0a00fb09-0000-0100-0000-617abaf20000\"",
            "_attachments": "attachments/",
            "_ts": 1635433202
        },
        {
            "id": "adf97cb69da99f5fa852207b9cc3cffebcb1473177caa1ca807778d952d6124d",
            "conversationId": "adf97cb69da99f5fa852207b9cc3cffebcb1473177caa1ca807778d952d6124d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-28T13:00:28.433Z",
            "endTime": "2021-10-28T13:00:48.182Z",
            "timeSpent": 20,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjhAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjhAgAAAAAAAA==/",
            "_etag": "\"0a00f809-0000-0100-0000-617abaf20000\"",
            "_attachments": "attachments/",
            "_ts": 1635433202
        },
        {
            "id": "3272d4d867d033cd6a5ea10a32ea8f877e6cc5cf6fed3e32a1cba2e314592116",
            "conversationId": "3272d4d867d033cd6a5ea10a32ea8f877e6cc5cf6fed3e32a1cba2e314592116",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-10-28T18:45:11.2Z",
            "endTime": "2021-10-28T18:47:44.726Z",
            "timeSpent": 154,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 5,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjiAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjiAgAAAAAAAA==/",
            "_etag": "\"0b00a0c2-0000-0100-0000-617b01430000\"",
            "_attachments": "attachments/",
            "_ts": 1635451203
        },
        {
            "id": "21b72804bcab1bf264e10d21a4d706789740765afee8878664f7cce3c5284d21",
            "conversationId": "21b72804bcab1bf264e10d21a4d706789740765afee8878664f7cce3c5284d21",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-28T20:57:18.851Z",
            "endTime": "2021-10-28T20:57:26.365Z",
            "timeSpent": 8,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjjAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjjAgAAAAAAAA==/",
            "_etag": "\"0c00be75-0000-0100-0000-617b2b730000\"",
            "_attachments": "attachments/",
            "_ts": 1635462003
        },
        {
            "id": "a37decfeaaa7ca48e2132fbdfe026e56ebc215115114af6561ece90213af1f34",
            "conversationId": "a37decfeaaa7ca48e2132fbdfe026e56ebc215115114af6561ece90213af1f34",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache when chewing"
            ],
            "riskFactors": [
                "Chronic NSAIDs use",
                "Opioid use",
                "History of tonsillectomy",
                "Smoking",
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-10-29T00:52:25.291Z",
            "endTime": "2021-10-29T00:55:29.874Z",
            "timeSpent": 185,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjkAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjkAgAAAAAAAA==/",
            "_etag": "\"0c0078c6-0000-0100-0000-617b55a80000\"",
            "_attachments": "attachments/",
            "_ts": 1635472808
        },
        {
            "id": "8afde1c436771fe7fcfb5ed67ba6e104b4ab93995b1001053b79089f9b793635",
            "conversationId": "8afde1c436771fe7fcfb5ed67ba6e104b4ab93995b1001053b79089f9b793635",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-10-29T16:22:14.049Z",
            "endTime": "2021-10-29T16:22:14.049Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjlAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjlAgAAAAAAAA==/",
            "_etag": "\"1000e683-0000-0100-0000-617c36a50000\"",
            "_attachments": "attachments/",
            "_ts": 1635530405
        },
        {
            "id": "49e23bf685d2fbaba28c7020b248132107d231ab413a450444e983a5a9d475d7",
            "conversationId": "49e23bf685d2fbaba28c7020b248132107d231ab413a450444e983a5a9d475d7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-01T14:00:00Z",
            "endTime": "2021-11-01T14:35:56.813Z",
            "timeSpent": 2157,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjmAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjmAgAAAAAAAA==/",
            "_etag": "\"1700c207-0000-0100-0000-61800f020000\"",
            "_attachments": "attachments/",
            "_ts": 1635782402
        },
        {
            "id": "24cd29e3f71fe56d401efc6f5f5dc86d91b82efb8fbd360f4aea0835eaa2c2dd",
            "conversationId": "24cd29e3f71fe56d401efc6f5f5dc86d91b82efb8fbd360f4aea0835eaa2c2dd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-01T18:57:32.977Z",
            "endTime": "2021-11-01T18:57:32.977Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjnAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjnAgAAAAAAAA==/",
            "_etag": "\"180060e1-0000-0100-0000-618055540000\"",
            "_attachments": "attachments/",
            "_ts": 1635800404
        },
        {
            "id": "ebe360309a1b178133953c36e4c5ad789215ed12b738456add26a549538a8c83",
            "conversationId": "ebe360309a1b178133953c36e4c5ad789215ed12b738456add26a549538a8c83",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-01T20:45:24.603Z",
            "endTime": "2021-11-01T20:45:24.603Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjoAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjoAgAAAAAAAA==/",
            "_etag": "\"19007230-0000-0100-0000-618071720000\"",
            "_attachments": "attachments/",
            "_ts": 1635807602
        },
        {
            "id": "d56da8ec51af7d85dcc2d9e5efd52e1bd1a18cebc8a609e374291c004278b6f0",
            "conversationId": "d56da8ec51af7d85dcc2d9e5efd52e1bd1a18cebc8a609e374291c004278b6f0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-01T21:45:36.48Z",
            "endTime": "2021-11-01T21:45:36.48Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjpAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjpAgAAAAAAAA==/",
            "_etag": "\"19006245-0000-0100-0000-61807f8d0000\"",
            "_attachments": "attachments/",
            "_ts": 1635811213
        },
        {
            "id": "f28a4994d3ffce9afde4b1265798e940a78bbb5145351cb7cbd59d17fc206841",
            "conversationId": "f28a4994d3ffce9afde4b1265798e940a78bbb5145351cb7cbd59d17fc206841",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-02T20:22:16.617Z",
            "endTime": "2021-11-02T20:22:16.617Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjqAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjqAgAAAAAAAA==/",
            "_etag": "\"1e00f01f-0000-0100-0000-6181b4e20000\"",
            "_attachments": "attachments/",
            "_ts": 1635890402
        },
        {
            "id": "2619f92e52a51e0dce34a7c93a56150bb27331bcb1425c9b308eab11538bc72a",
            "conversationId": "2619f92e52a51e0dce34a7c93a56150bb27331bcb1425c9b308eab11538bc72a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-11-05T13:29:24.923Z",
            "endTime": "2021-11-05T13:35:13.747Z",
            "timeSpent": 349,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjrAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjrAgAAAAAAAA==/",
            "_etag": "\"2b004bd6-0000-0100-0000-618555020000\"",
            "_attachments": "attachments/",
            "_ts": 1636128002
        },
        {
            "id": "ef8c30f00d435f4e5627e4215e12643779731cb62df8182d0b61cd73a5d0c8a4",
            "conversationId": "ef8c30f00d435f4e5627e4215e12643779731cb62df8182d0b61cd73a5d0c8a4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Earache",
                "Sore throat",
                "Fever",
                "Loss of voice"
            ],
            "riskFactors": [
                "Smoking",
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will try Self-Care",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2021-11-05T13:55:25.042Z",
            "endTime": "2021-11-05T13:59:09.669Z",
            "timeSpent": 225,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjsAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjsAgAAAAAAAA==/",
            "_etag": "\"2b004cd6-0000-0100-0000-618555020000\"",
            "_attachments": "attachments/",
            "_ts": 1636128002
        },
        {
            "id": "ea8f7a3dd1e228ef822f8d51fee671da366c51b97a1e1d88f3f9711f7a62b723",
            "conversationId": "ea8f7a3dd1e228ef822f8d51fee671da366c51b97a1e1d88f3f9711f7a62b723",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will call 9-1-1",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-11-05T14:55:54.519Z",
            "endTime": "2021-11-05T15:03:11.353Z",
            "timeSpent": 437,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjtAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjtAgAAAAAAAA==/",
            "_etag": "\"2c002934-0000-0100-0000-618563110000\"",
            "_attachments": "attachments/",
            "_ts": 1636131601
        },
        {
            "id": "2619f92e52a51e0dce34a7c93a56150bb27331bcb1425c9b308eab11538bc74g",
            "conversationId": "2619f92e52a51e0dce34a7c93a56150bb27331bcb1425c9b308eab11538bc74g",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-11-05T13:29:24.923Z",
            "endTime": "2021-11-05T13:35:13.747Z",
            "timeSpent": 349,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjuAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjuAgAAAAAAAA==/",
            "_etag": "\"3200eb1e-0000-0100-0000-618905130000\"",
            "_attachments": "attachments/",
            "_ts": 1636369683
        },
        {
            "id": "ea8f7a3dd1e228ef822f8d51fee671da366c51b97a1e1d88f3f9711f7a62xt65",
            "conversationId": "ea8f7a3dd1e228ef822f8d51fee671da366c51b97a1e1d88f3f9711f7a62xt65",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will call 9-1-1",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-11-05T14:55:54.519Z",
            "endTime": "2021-11-05T15:03:11.353Z",
            "timeSpent": 437,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjvAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjvAgAAAAAAAA==/",
            "_etag": "\"32009020-0000-0100-0000-6189065e0000\"",
            "_attachments": "attachments/",
            "_ts": 1636370014
        },
        {
            "id": "6744f92e52a51e0dce34a7c93a56150bb27331bcb1425c9b308eab11538bd84k",
            "conversationId": "6744f92e52a51e0dce34a7c93a56150bb27331bcb1425c9b308eab11538bd84k",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2021-11-05T13:29:24.923Z",
            "endTime": "2021-11-05T13:35:13.747Z",
            "timeSpent": 349,
            "isNoteGenerated": "no",
            "npsGroup": "Passive",
            "npsScore": 8,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjwAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjwAgAAAAAAAA==/",
            "_etag": "\"3600b590-0000-0100-0000-618a3ba30000\"",
            "_attachments": "attachments/",
            "_ts": 1636449187
        },
        {
            "id": "f70022e75c6934092f168fffe7ebbab008c07ef184988d13cee80971006744df",
            "conversationId": "f70022e75c6934092f168fffe7ebbab008c07ef184988d13cee80971006744df",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Send a MyChart Message",
            "patientPlansTo": "I will go to ReadyMED / Urgent Care",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-09T21:37:43.627Z",
            "endTime": "2021-11-09T21:41:49.603Z",
            "timeSpent": 246,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjxAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjxAgAAAAAAAA==/",
            "_etag": "\"3a000320-0000-0100-0000-618afd730000\"",
            "_attachments": "attachments/",
            "_ts": 1636498803
        },
        {
            "id": "34c111c750d1b1c4e9b50b08dc43c5eba4e01f61b39d0cbd0af61c4811b30038",
            "conversationId": "34c111c750d1b1c4e9b50b08dc43c5eba4e01f61b39d0cbd0af61c4811b30038",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-09T21:54:30.792Z",
            "endTime": "2021-11-09T21:54:43.789Z",
            "timeSpent": 13,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjyAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjyAgAAAAAAAA==/",
            "_etag": "\"3a000050-0000-0100-0000-618b0b840000\"",
            "_attachments": "attachments/",
            "_ts": 1636502404
        },
        {
            "id": "24ed1f52780f671b9815647ff603e9d1919898ca067eb221d3e8434170988507",
            "conversationId": "24ed1f52780f671b9815647ff603e9d1919898ca067eb221d3e8434170988507",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T13:26:07.802Z",
            "endTime": "2021-11-10T13:41:08.087Z",
            "timeSpent": 900,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjzAgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjzAgAAAAAAAA==/",
            "_etag": "\"3d00f050-0000-0100-0000-618bec850000\"",
            "_attachments": "attachments/",
            "_ts": 1636560005
        },
        {
            "id": "350b3672c416874533e1c1e29c6dff65e164d8c13dea29d946db644a68c607c5",
            "conversationId": "350b3672c416874533e1c1e29c6dff65e164d8c13dea29d946db644a68c607c5",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 50,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T13:08:59.335Z",
            "endTime": "2021-11-10T13:09:13.009Z",
            "timeSpent": 14,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj0AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj0AgAAAAAAAA==/",
            "_etag": "\"3d003904-0000-0100-0000-618bde7b0000\"",
            "_attachments": "attachments/",
            "_ts": 1636556411
        },
        {
            "id": "ff99d6b9051cca61b4629a6d6cf9c964ed05c8ac5ad9feeaf77c8de168eb7cf1",
            "conversationId": "ff99d6b9051cca61b4629a6d6cf9c964ed05c8ac5ad9feeaf77c8de168eb7cf1",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 56,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T13:55:09.1Z",
            "endTime": "2021-11-10T13:56:47.787Z",
            "timeSpent": 99,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj1AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj1AgAAAAAAAA==/",
            "_etag": "\"3d00fa50-0000-0100-0000-618bec850000\"",
            "_attachments": "attachments/",
            "_ts": 1636560005
        },
        {
            "id": "eb9b6ae7ac50c3567b36438c073221f222820f5a6fd7ee02cb87e9f7fe005653",
            "conversationId": "eb9b6ae7ac50c3567b36438c073221f222820f5a6fd7ee02cb87e9f7fe005653",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T13:27:40.489Z",
            "endTime": "2021-11-10T13:27:40.489Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj2AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj2AgAAAAAAAA==/",
            "_etag": "\"3d003c04-0000-0100-0000-618bde7b0000\"",
            "_attachments": "attachments/",
            "_ts": 1636556411
        },
        {
            "id": "d39b2f14b69d190017ebaa163a148c21eb03fa0bda686f2cebb5530868f62ba8",
            "conversationId": "d39b2f14b69d190017ebaa163a148c21eb03fa0bda686f2cebb5530868f62ba8",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 20,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T13:59:03.024Z",
            "endTime": "2021-11-10T14:00:14.379Z",
            "timeSpent": 71,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj3AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj3AgAAAAAAAA==/",
            "_etag": "\"3d00fb50-0000-0100-0000-618bec850000\"",
            "_attachments": "attachments/",
            "_ts": 1636560005
        },
        {
            "id": "3c10271b953fd8df4376370824aad7301c21c0fee0f20c6d1cb5d24665565604",
            "conversationId": "3c10271b953fd8df4376370824aad7301c21c0fee0f20c6d1cb5d24665565604",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 8,
            "ageGroup": "0-11",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T14:01:05.552Z",
            "endTime": "2021-11-10T14:01:31.641Z",
            "timeSpent": 26,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj4AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj4AgAAAAAAAA==/",
            "_etag": "\"3d00ed50-0000-0100-0000-618bec850000\"",
            "_attachments": "attachments/",
            "_ts": 1636560005
        },
        {
            "id": "f300e26a9abd9a13bc62690260a18c035f1ef23fc72dcf47d81132df7b0122ac",
            "conversationId": "f300e26a9abd9a13bc62690260a18c035f1ef23fc72dcf47d81132df7b0122ac",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 22,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T14:01:52.837Z",
            "endTime": "2021-11-10T14:02:09.719Z",
            "timeSpent": 17,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj5AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj5AgAAAAAAAA==/",
            "_etag": "\"3d00fc50-0000-0100-0000-618bec850000\"",
            "_attachments": "attachments/",
            "_ts": 1636560005
        },
        {
            "id": "3d50de2e20b74ecab6e4e6e0c18e55521600db18f504df812f20771d99fb4ba6",
            "conversationId": "3d50de2e20b74ecab6e4e6e0c18e55521600db18f504df812f20771d99fb4ba6",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 20,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T14:43:21.467Z",
            "endTime": "2021-11-10T14:44:46.861Z",
            "timeSpent": 85,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj6AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj6AgAAAAAAAA==/",
            "_etag": "\"3d00cf95-0000-0100-0000-618bfa920000\"",
            "_attachments": "attachments/",
            "_ts": 1636563602
        },
        {
            "id": "0789182a186f2adfae73ce0979043a3fae071fc52a309a66b642d3f591f05317",
            "conversationId": "0789182a186f2adfae73ce0979043a3fae071fc52a309a66b642d3f591f05317",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 32,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T14:42:43.436Z",
            "endTime": "2021-11-10T15:23:55.749Z",
            "timeSpent": 2472,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj7AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj7AgAAAAAAAA==/",
            "_etag": "\"3d00d595-0000-0100-0000-618bfa920000\"",
            "_attachments": "attachments/",
            "_ts": 1636563602
        },
        {
            "id": "64709051a0f7e154e94caad43271442b718704a12326bbd36d9413c89f7822e6",
            "conversationId": "64709051a0f7e154e94caad43271442b718704a12326bbd36d9413c89f7822e6",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T14:45:39.644Z",
            "endTime": "2021-11-10T14:45:39.644Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj8AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj8AgAAAAAAAA==/",
            "_etag": "\"3d00d095-0000-0100-0000-618bfa920000\"",
            "_attachments": "attachments/",
            "_ts": 1636563602
        },
        {
            "id": "46c3f8346cec4202310b9b9e70d352ebe25556af72351acc43d78adf95cfec7c",
            "conversationId": "46c3f8346cec4202310b9b9e70d352ebe25556af72351acc43d78adf95cfec7c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 25,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T14:46:02.968Z",
            "endTime": "2021-11-10T14:46:30.968Z",
            "timeSpent": 28,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj9AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj9AgAAAAAAAA==/",
            "_etag": "\"3d00d695-0000-0100-0000-618bfa920000\"",
            "_attachments": "attachments/",
            "_ts": 1636563602
        },
        {
            "id": "467a7b024221c22b96ddf774f9bb70dca943f9af758b7d5e8d7de20bcb33a14a",
            "conversationId": "467a7b024221c22b96ddf774f9bb70dca943f9af758b7d5e8d7de20bcb33a14a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 35,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T14:46:59.766Z",
            "endTime": "2021-11-10T14:47:12.324Z",
            "timeSpent": 13,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj+AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj+AgAAAAAAAA==/",
            "_etag": "\"3d00d795-0000-0100-0000-618bfa920000\"",
            "_attachments": "attachments/",
            "_ts": 1636563602
        },
        {
            "id": "020a1ec0a39cb835d02cc339393b567a952d7c948acfd974716387ac0128c53a",
            "conversationId": "020a1ec0a39cb835d02cc339393b567a952d7c948acfd974716387ac0128c53a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 32,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Severe chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-11-10T14:56:31.582Z",
            "endTime": "2021-11-10T14:57:04.064Z",
            "timeSpent": 32,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj-AgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj-AgAAAAAAAA==/",
            "_etag": "\"3d00d295-0000-0100-0000-618bfa920000\"",
            "_attachments": "attachments/",
            "_ts": 1636563602
        },
        {
            "id": "2473744fcdcf9c4fa1b50c236d848d2817458dcf9eba6227a0275c3a91e8a1f1",
            "conversationId": "2473744fcdcf9c4fa1b50c236d848d2817458dcf9eba6227a0275c3a91e8a1f1",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 32,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Dry cough",
                "Nighttime cough",
                "Coma",
                "Fever",
                "Hives"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-10T13:20:01.28Z",
            "endTime": "2021-11-10T13:20:01.28Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgAAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgAAwAAAAAAAA==/",
            "_etag": "\"3d004604-0000-0100-0000-618bde7b0000\"",
            "_attachments": "attachments/",
            "_ts": 1636556411
        },
        {
            "id": "a62891cfa7e95cbb3de52f0cd4d925d716949f2e6213a311b406191e28c8518e",
            "conversationId": "a62891cfa7e95cbb3de52f0cd4d925d716949f2e6213a311b406191e28c8518e",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 13,
            "ageGroup": "12-17",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T14:15:14.009Z",
            "endTime": "2021-11-10T14:19:30.85Z",
            "timeSpent": 257,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgBAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgBAwAAAAAAAA==/",
            "_etag": "\"3d00f150-0000-0100-0000-618bec850000\"",
            "_attachments": "attachments/",
            "_ts": 1636560005
        },
        {
            "id": "69d9dbe9b4798f9d7c46be4bd2e421dbd7eb132a82555996b9c674e0e2107637",
            "conversationId": "69d9dbe9b4798f9d7c46be4bd2e421dbd7eb132a82555996b9c674e0e2107637",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 10,
            "ageGroup": "0-11",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T13:18:30.59Z",
            "endTime": "2021-11-10T13:19:14.677Z",
            "timeSpent": 44,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgCAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgCAwAAAAAAAA==/",
            "_etag": "\"3d004804-0000-0100-0000-618bde7b0000\"",
            "_attachments": "attachments/",
            "_ts": 1636556411
        },
        {
            "id": "025fbec5bdbea6c585c6b795354b6d030c1acccbae41fdb90f18c1c2d2fda070",
            "conversationId": "025fbec5bdbea6c585c6b795354b6d030c1acccbae41fdb90f18c1c2d2fda070",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 8,
            "ageGroup": "0-11",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T13:21:14.009Z",
            "endTime": "2021-11-10T13:22:03.42Z",
            "timeSpent": 49,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgDAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgDAwAAAAAAAA==/",
            "_etag": "\"3d004904-0000-0100-0000-618bde7b0000\"",
            "_attachments": "attachments/",
            "_ts": 1636556411
        },
        {
            "id": "20a07c3b7816d515ac8255ce1f4c5e07284de12d2ebc8b21a939dfd70c3d683f",
            "conversationId": "20a07c3b7816d515ac8255ce1f4c5e07284de12d2ebc8b21a939dfd70c3d683f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 56,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T13:52:22.283Z",
            "endTime": "2021-11-10T13:52:37.348Z",
            "timeSpent": 15,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgEAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgEAwAAAAAAAA==/",
            "_etag": "\"3d00f750-0000-0100-0000-618bec850000\"",
            "_attachments": "attachments/",
            "_ts": 1636560005
        },
        {
            "id": "95dbd43b126b27aa2abab2dd7357d433e9b49b8bfbea53853de5789f753eb742",
            "conversationId": "95dbd43b126b27aa2abab2dd7357d433e9b49b8bfbea53853de5789f753eb742",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-10T14:56:19.436Z",
            "endTime": "2021-11-10T14:56:24.519Z",
            "timeSpent": 5,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgFAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgFAwAAAAAAAA==/",
            "_etag": "\"3d00f850-0000-0100-0000-618bec850000\"",
            "_attachments": "attachments/",
            "_ts": 1636560005
        },
        {
            "id": "72e3b261bd9ec5f27ad7e3d33d64febc7299fc7dd0d59a8169481dd1a816d803",
            "conversationId": "72e3b261bd9ec5f27ad7e3d33d64febc7299fc7dd0d59a8169481dd1a816d803",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 1,
            "ageGroup": "0-11",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T13:30:27.767Z",
            "endTime": "2021-11-10T13:30:42.628Z",
            "timeSpent": 15,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgGAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgGAwAAAAAAAA==/",
            "_etag": "\"3d00fe50-0000-0100-0000-618bec850000\"",
            "_attachments": "attachments/",
            "_ts": 1636560005
        },
        {
            "id": "a0004f619f552c1fa40a026421b8d16bee4fcd90e3b271968452abc54cfa7873",
            "conversationId": "a0004f619f552c1fa40a026421b8d16bee4fcd90e3b271968452abc54cfa7873",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 32,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Oily skin"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T14:09:42.04Z",
            "endTime": "2021-11-10T14:42:29.524Z",
            "timeSpent": 1967,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgHAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgHAwAAAAAAAA==/",
            "_etag": "\"3d00ee50-0000-0100-0000-618bec850000\"",
            "_attachments": "attachments/",
            "_ts": 1636560005
        },
        {
            "id": "7d334362bb069c0d25dc8e5c16035f0fd54e151d8123659cf10db787233a93f6",
            "conversationId": "7d334362bb069c0d25dc8e5c16035f0fd54e151d8123659cf10db787233a93f6",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T14:10:30.589Z",
            "endTime": "2021-11-10T14:10:38.509Z",
            "timeSpent": 8,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgIAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgIAwAAAAAAAA==/",
            "_etag": "\"3d00ef50-0000-0100-0000-618bec850000\"",
            "_attachments": "attachments/",
            "_ts": 1636560005
        },
        {
            "id": "5b4565682f3760e56bb2658458fa5b4dcfbde135a5ce327c5469c1fd9a0b459d",
            "conversationId": "5b4565682f3760e56bb2658458fa5b4dcfbde135a5ce327c5469c1fd9a0b459d",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 14,
            "ageGroup": "12-17",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T14:38:06.883Z",
            "endTime": "2021-11-10T14:39:03.888Z",
            "timeSpent": 57,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgJAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgJAwAAAAAAAA==/",
            "_etag": "\"3d00cd95-0000-0100-0000-618bfa920000\"",
            "_attachments": "attachments/",
            "_ts": 1636563602
        },
        {
            "id": "8d688b1319c9c70c67596411fa3811bbdb961fc1780558ea92138c9ba14fb3f0",
            "conversationId": "8d688b1319c9c70c67596411fa3811bbdb961fc1780558ea92138c9ba14fb3f0",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 7,
            "ageGroup": "0-11",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T14:39:26.916Z",
            "endTime": "2021-11-10T14:40:21.025Z",
            "timeSpent": 54,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgKAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgKAwAAAAAAAA==/",
            "_etag": "\"3d00ce95-0000-0100-0000-618bfa920000\"",
            "_attachments": "attachments/",
            "_ts": 1636563602
        },
        {
            "id": "158b49d4243164215f78c9efa5d4b6b9d293cfdbb20273927c8131715df0258b",
            "conversationId": "158b49d4243164215f78c9efa5d4b6b9d293cfdbb20273927c8131715df0258b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Oily skin"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2021-11-10T15:25:53.184Z",
            "endTime": "2021-11-10T15:29:04.975Z",
            "timeSpent": 192,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgLAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgLAwAAAAAAAA==/",
            "_etag": "\"3d00d195-0000-0100-0000-618bfa920000\"",
            "_attachments": "attachments/",
            "_ts": 1636563602
        },
        {
            "id": "2d6e5921320c1f3d9756230ef4d9ba0409a5ba47d441b662dc2e84f242797ee4",
            "conversationId": "2d6e5921320c1f3d9756230ef4d9ba0409a5ba47d441b662dc2e84f242797ee4",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T15:40:48.866Z",
            "endTime": "2021-11-10T15:40:53.802Z",
            "timeSpent": 5,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgMAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgMAwAAAAAAAA==/",
            "_etag": "\"3d00c9f4-0000-0100-0000-618c08a40000\"",
            "_attachments": "attachments/",
            "_ts": 1636567204
        },
        {
            "id": "85fa80c84dab0001e33d4517539361f964ed2df11c76074e7d6f214eb6f58b70",
            "conversationId": "85fa80c84dab0001e33d4517539361f964ed2df11c76074e7d6f214eb6f58b70",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 34,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T15:47:16.771Z",
            "endTime": "2021-11-10T16:17:44.045Z",
            "timeSpent": 1827,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgNAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgNAwAAAAAAAA==/",
            "_etag": "\"3d00c8f4-0000-0100-0000-618c08a40000\"",
            "_attachments": "attachments/",
            "_ts": 1636567204
        },
        {
            "id": "b5b8f9d0150470c970471c68da8ddbe32192f4031a74be76692fa1224c0b2450",
            "conversationId": "b5b8f9d0150470c970471c68da8ddbe32192f4031a74be76692fa1224c0b2450",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-10T19:16:21.085Z",
            "endTime": "2021-11-10T19:16:26.164Z",
            "timeSpent": 5,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgOAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgOAwAAAAAAAA==/",
            "_etag": "\"3f00d03d-0000-0100-0000-618c32d20000\"",
            "_attachments": "attachments/",
            "_ts": 1636578002
        },
        {
            "id": "c8c51eb4f370f4dfa0a5daa44ba9097e91d18617cfb8aea15432ba9a13823a91",
            "conversationId": "c8c51eb4f370f4dfa0a5daa44ba9097e91d18617cfb8aea15432ba9a13823a91",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 16,
            "ageGroup": "12-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the Emergency Room",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-10T20:48:19.889Z",
            "endTime": "2021-11-10T20:50:18.743Z",
            "timeSpent": 119,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgPAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgPAwAAAAAAAA==/",
            "_etag": "\"3f000386-0000-0100-0000-618c40eb0000\"",
            "_attachments": "attachments/",
            "_ts": 1636581611
        },
        {
            "id": "d087128d58557c95c0e87c1308c3a646f6154a2de92fba5a476583feb9a4366e",
            "conversationId": "d087128d58557c95c0e87c1308c3a646f6154a2de92fba5a476583feb9a4366e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Nose pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-11-10T20:53:15.914Z",
            "endTime": "2021-11-10T20:54:34.992Z",
            "timeSpent": 79,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgQAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgQAwAAAAAAAA==/",
            "_etag": "\"3f000486-0000-0100-0000-618c40eb0000\"",
            "_attachments": "attachments/",
            "_ts": 1636581611
        },
        {
            "id": "dfe0311942affc7ad65e5e9760957882fcf6a29293d3f1bc8961ef557ce9218d",
            "conversationId": "dfe0311942affc7ad65e5e9760957882fcf6a29293d3f1bc8961ef557ce9218d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-10T20:55:08.628Z",
            "endTime": "2021-11-10T20:56:02.935Z",
            "timeSpent": 54,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgRAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgRAwAAAAAAAA==/",
            "_etag": "\"3f0007b6-0000-0100-0000-618c4ef40000\"",
            "_attachments": "attachments/",
            "_ts": 1636585204
        },
        {
            "id": "f3866be05c2246c482d9f5fddf77a9ad99a8e806ea80d42fe5f63eeb7bc15296",
            "conversationId": "f3866be05c2246c482d9f5fddf77a9ad99a8e806ea80d42fe5f63eeb7bc15296",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the Emergency Room",
            "patientPlansTo": "Call me within two days",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2021-11-10T20:45:30.249Z",
            "endTime": "2021-11-10T20:47:47.687Z",
            "timeSpent": 137,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgSAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgSAwAAAAAAAA==/",
            "_etag": "\"3f000286-0000-0100-0000-618c40eb0000\"",
            "_attachments": "attachments/",
            "_ts": 1636581611
        },
        {
            "id": "3e01c6c51b0c6bfb8ece3d119b95df565f710d4920b875c47dd5414f86c1c6a8",
            "conversationId": "3e01c6c51b0c6bfb8ece3d119b95df565f710d4920b875c47dd5414f86c1c6a8",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-11T04:29:42.34Z",
            "endTime": "2021-11-11T04:29:42.34Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgTAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgTAwAAAAAAAA==/",
            "_etag": "\"40002f72-0000-0100-0000-618cb1730000\"",
            "_attachments": "attachments/",
            "_ts": 1636610419
        },
        {
            "id": "1dbd5b40c048a36079382ff2a90743708f34874ad1d6a633c498c1e4e94ef97d",
            "conversationId": "1dbd5b40c048a36079382ff2a90743708f34874ad1d6a633c498c1e4e94ef97d",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 1,
            "ageGroup": "0-11",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to Urgent Care",
            "patientPlansTo": "I will go to the Emergency Room",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-11T05:38:44.798Z",
            "endTime": "2021-11-11T05:39:48.616Z",
            "timeSpent": 64,
            "isNoteGenerated": "no",
            "npsGroup": "Passive",
            "npsScore": 8,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgUAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgUAwAAAAAAAA==/",
            "_etag": "\"40000a90-0000-0100-0000-618cbf730000\"",
            "_attachments": "attachments/",
            "_ts": 1636614003
        },
        {
            "id": "e51c254eaf10d62be98c9bae05c7d3111e9e4d4501f11f897e10d1cc9a9cd8f7",
            "conversationId": "e51c254eaf10d62be98c9bae05c7d3111e9e4d4501f11f897e10d1cc9a9cd8f7",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-11T07:54:31.661Z",
            "endTime": "2021-11-11T08:00:48.738Z",
            "timeSpent": 377,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgVAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgVAwAAAAAAAA==/",
            "_etag": "\"40002cc3-0000-0100-0000-618ce9a50000\"",
            "_attachments": "attachments/",
            "_ts": 1636624805
        },
        {
            "id": "e5907ca98be43d2b9a3e453d2bc501627e840bbc90f8c1774b40141814a3edf0",
            "conversationId": "e5907ca98be43d2b9a3e453d2bc501627e840bbc90f8c1774b40141814a3edf0",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 56,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-11T11:00:22.359Z",
            "endTime": "2021-11-11T11:01:06.118Z",
            "timeSpent": 44,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgWAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgWAwAAAAAAAA==/",
            "_etag": "\"4100843c-0000-0100-0000-618d13d20000\"",
            "_attachments": "attachments/",
            "_ts": 1636635602
        },
        {
            "id": "760c0d6e03c198abbb947aeb02ea7044370b42d4bbb72ac337c3070503ef7dab",
            "conversationId": "760c0d6e03c198abbb947aeb02ea7044370b42d4bbb72ac337c3070503ef7dab",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 48,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Call 9-1-1",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-11T11:01:47.873Z",
            "endTime": "2021-11-11T11:15:50.645Z",
            "timeSpent": 843,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgXAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgXAwAAAAAAAA==/",
            "_etag": "\"4100833c-0000-0100-0000-618d13d20000\"",
            "_attachments": "attachments/",
            "_ts": 1636635602
        },
        {
            "id": "538528a29214f5d016ebc77f93fac560107fb6c773c4c6fba96802b740e42974",
            "conversationId": "538528a29214f5d016ebc77f93fac560107fb6c773c4c6fba96802b740e42974",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-11T12:51:21.915Z",
            "endTime": "2021-11-11T12:51:21.915Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgYAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgYAwAAAAAAAA==/",
            "_etag": "\"410020b4-0000-0100-0000-618d2ff20000\"",
            "_attachments": "attachments/",
            "_ts": 1636642802
        },
        {
            "id": "1084135d653df7ea4df5f2288798134ba1981b9cc5aea850f0a34c1d819dbe29",
            "conversationId": "1084135d653df7ea4df5f2288798134ba1981b9cc5aea850f0a34c1d819dbe29",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-11T14:46:03.331Z",
            "endTime": "2021-11-11T14:46:09.511Z",
            "timeSpent": 6,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgZAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgZAwAAAAAAAA==/",
            "_etag": "\"42005547-0000-0100-0000-618d4c120000\"",
            "_attachments": "attachments/",
            "_ts": 1636650002
        },
        {
            "id": "0f140391164f40b1f1008c56dc0fd90f45e60d3dc7d5511141b913d6fa6db3ed",
            "conversationId": "0f140391164f40b1f1008c56dc0fd90f45e60d3dc7d5511141b913d6fa6db3ed",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 32,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Male",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-11T15:06:32.112Z",
            "endTime": "2021-11-11T15:42:03.802Z",
            "timeSpent": 2132,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgaAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgaAwAAAAAAAA==/",
            "_etag": "\"42005347-0000-0100-0000-618d4c120000\"",
            "_attachments": "attachments/",
            "_ts": 1636650002
        },
        {
            "id": "eab1798bd7a0492efe9ad65ec097731c36ae98327b58582f58496a10f63f8142",
            "conversationId": "eab1798bd7a0492efe9ad65ec097731c36ae98327b58582f58496a10f63f8142",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-11T15:11:15.331Z",
            "endTime": "2021-11-11T15:11:23.08Z",
            "timeSpent": 8,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgbAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgbAwAAAAAAAA==/",
            "_etag": "\"42005247-0000-0100-0000-618d4c120000\"",
            "_attachments": "attachments/",
            "_ts": 1636650002
        },
        {
            "id": "8678cc4455986b200110df06ff1d7232049d931a0382039fca3cae128a4da467",
            "conversationId": "8678cc4455986b200110df06ff1d7232049d931a0382039fca3cae128a4da467",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Fever"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will try Self-Care",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-11T16:52:13.343Z",
            "endTime": "2021-11-11T17:10:46.812Z",
            "timeSpent": 1113,
            "isNoteGenerated": "yes",
            "npsGroup": "Passive",
            "npsScore": 7,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgcAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgcAwAAAAAAAA==/",
            "_etag": "\"42004dd3-0000-0100-0000-618d68350000\"",
            "_attachments": "attachments/",
            "_ts": 1636657205
        },
        {
            "id": "25ab3d990380f114bd7404dce9a37088a2430673ea826710e33fc341c7b40170",
            "conversationId": "25ab3d990380f114bd7404dce9a37088a2430673ea826710e33fc341c7b40170",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 56,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-11T16:50:35.02Z",
            "endTime": "2021-11-11T16:51:01.724Z",
            "timeSpent": 27,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgdAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgdAwAAAAAAAA==/",
            "_etag": "\"420051d3-0000-0100-0000-618d68350000\"",
            "_attachments": "attachments/",
            "_ts": 1636657205
        },
        {
            "id": "da90c97904fb45940f050929451ec05adfee0f105fdde130334c9f93dd20b719",
            "conversationId": "da90c97904fb45940f050929451ec05adfee0f105fdde130334c9f93dd20b719",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-11T16:51:22.025Z",
            "endTime": "2021-11-11T16:51:28.825Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgeAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgeAwAAAAAAAA==/",
            "_etag": "\"42004ed3-0000-0100-0000-618d68350000\"",
            "_attachments": "attachments/",
            "_ts": 1636657205
        },
        {
            "id": "51e6ee931bf574bfae2924d9af7ccb3719cb6dc874e8352f6822d861399a46c2",
            "conversationId": "51e6ee931bf574bfae2924d9af7ccb3719cb6dc874e8352f6822d861399a46c2",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 32,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-11T16:32:59.679Z",
            "endTime": "2021-11-11T16:34:29.433Z",
            "timeSpent": 90,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgfAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgfAwAAAAAAAA==/",
            "_etag": "\"420050d3-0000-0100-0000-618d68350000\"",
            "_attachments": "attachments/",
            "_ts": 1636657205
        },
        {
            "id": "8a5904464f841127c35b93044c0e13e4b064662586e4a2fa06472a1e96c2f390",
            "conversationId": "8a5904464f841127c35b93044c0e13e4b064662586e4a2fa06472a1e96c2f390",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-11T17:50:13.736Z",
            "endTime": "2021-11-11T17:50:13.736Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIggAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIggAwAAAAAAAA==/",
            "_etag": "\"43009f1c-0000-0100-0000-618d76420000\"",
            "_attachments": "attachments/",
            "_ts": 1636660802
        },
        {
            "id": "884dcfcb16ededc6ebe53bbd95d4b0ec49a80553b3581d664133bf616e3cef58",
            "conversationId": "884dcfcb16ededc6ebe53bbd95d4b0ec49a80553b3581d664133bf616e3cef58",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 32,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-11T17:28:48.313Z",
            "endTime": "2021-11-11T17:29:08.133Z",
            "timeSpent": 20,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIghAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIghAwAAAAAAAA==/",
            "_etag": "\"42004bd3-0000-0100-0000-618d68350000\"",
            "_attachments": "attachments/",
            "_ts": 1636657205
        },
        {
            "id": "a212e525f940da578f219a8ebf67b913912990833a303d4763848c81e2f21585",
            "conversationId": "a212e525f940da578f219a8ebf67b913912990833a303d4763848c81e2f21585",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-11T18:40:56.305Z",
            "endTime": "2021-11-11T18:41:57.029Z",
            "timeSpent": 61,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgiAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgiAwAAAAAAAA==/",
            "_etag": "\"43008784-0000-0100-0000-618d84560000\"",
            "_attachments": "attachments/",
            "_ts": 1636664406
        },
        {
            "id": "9a458ce38aa4fc98953ab6876d6ffa909a7c503c24916e15e6ca11de6a60cbc0",
            "conversationId": "9a458ce38aa4fc98953ab6876d6ffa909a7c503c24916e15e6ca11de6a60cbc0",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-11T18:59:23.433Z",
            "endTime": "2021-11-11T18:59:36.666Z",
            "timeSpent": 13,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgjAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgjAwAAAAAAAA==/",
            "_etag": "\"43008884-0000-0100-0000-618d84560000\"",
            "_attachments": "attachments/",
            "_ts": 1636664406
        },
        {
            "id": "728da6c6a3510eabcd46caa48c9384e263c4557a813831aa9f0c325db8201a78",
            "conversationId": "728da6c6a3510eabcd46caa48c9384e263c4557a813831aa9f0c325db8201a78",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-11T19:19:01.232Z",
            "endTime": "2021-11-11T19:19:08.471Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgkAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgkAwAAAAAAAA==/",
            "_etag": "\"43008684-0000-0100-0000-618d84560000\"",
            "_attachments": "attachments/",
            "_ts": 1636664406
        },
        {
            "id": "d58fa2884de738a4c2aa93ab77905c20c039f0827b699cb9e0a68909cbc43869",
            "conversationId": "d58fa2884de738a4c2aa93ab77905c20c039f0827b699cb9e0a68909cbc43869",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 43,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-11-11T20:22:08.491Z",
            "endTime": "2021-11-11T20:34:18.087Z",
            "timeSpent": 730,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIglAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIglAwAAAAAAAA==/",
            "_etag": "\"430071c4-0000-0100-0000-618d92640000\"",
            "_attachments": "attachments/",
            "_ts": 1636668004
        },
        {
            "id": "5a46aabc6dfd08dcfca8f38826fa671685ade7de33de186facc6e7c6050684be",
            "conversationId": "5a46aabc6dfd08dcfca8f38826fa671685ade7de33de186facc6e7c6050684be",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 34,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-11-11T20:23:43.184Z",
            "endTime": "2021-11-11T20:30:36.068Z",
            "timeSpent": 413,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgmAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgmAwAAAAAAAA==/",
            "_etag": "\"44007713-0000-0100-0000-618da0710000\"",
            "_attachments": "attachments/",
            "_ts": 1636671601
        },
        {
            "id": "9667522283ee0bbc32bb41ab96277ab76eef2c04734c25cc0c30aa0e203ce692",
            "conversationId": "9667522283ee0bbc32bb41ab96277ab76eef2c04734c25cc0c30aa0e203ce692",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 12,
            "ageGroup": "12-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-11T20:48:25.671Z",
            "endTime": "2021-11-11T20:49:00.981Z",
            "timeSpent": 35,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgnAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgnAwAAAAAAAA==/",
            "_etag": "\"44007913-0000-0100-0000-618da0710000\"",
            "_attachments": "attachments/",
            "_ts": 1636671601
        },
        {
            "id": "4a08d3140420dde025380735439a8da31bcdab7a67f2418a12c2da140f3bedd3",
            "conversationId": "4a08d3140420dde025380735439a8da31bcdab7a67f2418a12c2da140f3bedd3",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 12,
            "ageGroup": "12-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-11-11T20:39:22.799Z",
            "endTime": "2021-11-11T20:40:05.79Z",
            "timeSpent": 43,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgoAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgoAwAAAAAAAA==/",
            "_etag": "\"44007813-0000-0100-0000-618da0710000\"",
            "_attachments": "attachments/",
            "_ts": 1636671601
        },
        {
            "id": "6bdf31fa610aa45ea15302bf4fef1bb0c1befc5bfa0560f96c34f68890f5157e",
            "conversationId": "6bdf31fa610aa45ea15302bf4fef1bb0c1befc5bfa0560f96c34f68890f5157e",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-12T00:55:14.99Z",
            "endTime": "2021-11-12T00:55:14.99Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgpAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgpAwAAAAAAAA==/",
            "_etag": "\"4400fd82-0000-0100-0000-618dcaa70000\"",
            "_attachments": "attachments/",
            "_ts": 1636682407
        },
        {
            "id": "3c556aba6ed4e2135e0ad02e85a22da28662ee86a036db62f3f7bb5ed13031a6",
            "conversationId": "3c556aba6ed4e2135e0ad02e85a22da28662ee86a036db62f3f7bb5ed13031a6",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-12T02:34:25.536Z",
            "endTime": "2021-11-12T02:34:30.374Z",
            "timeSpent": 5,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgqAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgqAwAAAAAAAA==/",
            "_etag": "\"44000cd1-0000-0100-0000-618df4d30000\"",
            "_attachments": "attachments/",
            "_ts": 1636693203
        },
        {
            "id": "c6f41688a3d052ede8d9e53a68b882a78e689af1b78d981b2e6264b239fe8b7b",
            "conversationId": "c6f41688a3d052ede8d9e53a68b882a78e689af1b78d981b2e6264b239fe8b7b",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 65,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2021-11-12T10:29:24.82Z",
            "endTime": "2021-11-12T10:47:36.031Z",
            "timeSpent": 1091,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgrAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgrAwAAAAAAAA==/",
            "_etag": "\"01005b4a-0000-0100-0000-618e65520000\"",
            "_attachments": "attachments/",
            "_ts": 1636722002
        },
        {
            "id": "cb170605e5b2d7b13ad15cdff7a91b412aedfc8eee9353725a2aa212f63fd91f",
            "conversationId": "cb170605e5b2d7b13ad15cdff7a91b412aedfc8eee9353725a2aa212f63fd91f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 31,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-12T11:52:15.954Z",
            "endTime": "2021-11-12T11:52:28.214Z",
            "timeSpent": 12,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgsAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgsAwAAAAAAAA==/",
            "_etag": "\"01004c63-0000-0100-0000-618e73620000\"",
            "_attachments": "attachments/",
            "_ts": 1636725602
        },
        {
            "id": "b47d4dc566b0da5a85b8d038d1656791357339a0ee2e325abd9614252cc7a6c1",
            "conversationId": "b47d4dc566b0da5a85b8d038d1656791357339a0ee2e325abd9614252cc7a6c1",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 32,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-12T14:57:20.93Z",
            "endTime": "2021-11-12T14:58:26.877Z",
            "timeSpent": 66,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgtAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgtAwAAAAAAAA==/",
            "_etag": "\"010076b9-0000-0100-0000-618e9d920000\"",
            "_attachments": "attachments/",
            "_ts": 1636736402
        },
        {
            "id": "2cfd99f898342429ef604fb9ccf3e28c063aaa2eed8047fb46d1a2d4cef8b4fb",
            "conversationId": "2cfd99f898342429ef604fb9ccf3e28c063aaa2eed8047fb46d1a2d4cef8b4fb",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-12T15:47:49.937Z",
            "endTime": "2021-11-12T15:47:49.937Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIguAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIguAwAAAAAAAA==/",
            "_etag": "\"010090dc-0000-0100-0000-618eaba50000\"",
            "_attachments": "attachments/",
            "_ts": 1636740005
        },
        {
            "id": "c2b61a6bfa3ec64f269001f9b54e573a398f9ff45d4f09859ce9716042115898",
            "conversationId": "c2b61a6bfa3ec64f269001f9b54e573a398f9ff45d4f09859ce9716042115898",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 35,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-12T15:53:02.096Z",
            "endTime": "2021-11-12T16:01:10.899Z",
            "timeSpent": 489,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgvAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgvAwAAAAAAAA==/",
            "_etag": "\"010091dc-0000-0100-0000-618eaba50000\"",
            "_attachments": "attachments/",
            "_ts": 1636740005
        },
        {
            "id": "f889c25ae31fecbf65e4f9255e36cdf8c8c3cf917ae3e6a9b5f9ecc94816174e",
            "conversationId": "f889c25ae31fecbf65e4f9255e36cdf8c8c3cf917ae3e6a9b5f9ecc94816174e",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 32,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-12T18:32:33.904Z",
            "endTime": "2021-11-12T18:32:43.853Z",
            "timeSpent": 10,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgwAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgwAwAAAAAAAA==/",
            "_etag": "\"02002749-0000-0100-0000-618ed5d50000\"",
            "_attachments": "attachments/",
            "_ts": 1636750805
        },
        {
            "id": "1b59a9da20a73f2933c29880f24ae6ef77b07a6f1cecf1f22d446fbc578c7f7e",
            "conversationId": "1b59a9da20a73f2933c29880f24ae6ef77b07a6f1cecf1f22d446fbc578c7f7e",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 120,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Leg pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T05:47:42.213Z",
            "endTime": "2021-11-15T05:49:09.132Z",
            "timeSpent": 87,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgxAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgxAwAAAAAAAA==/",
            "_etag": "\"0800763e-0000-0100-0000-619213820000\"",
            "_attachments": "attachments/",
            "_ts": 1636963202
        },
        {
            "id": "66868e8e748a34a267b8008858207cd16077143c8e4f32045f8e24915d73b900",
            "conversationId": "66868e8e748a34a267b8008858207cd16077143c8e4f32045f8e24915d73b900",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 50,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache",
                "Cough"
            ],
            "riskFactors": [
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic for something other than an appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2021-11-15T06:24:09.081Z",
            "endTime": "2021-11-15T06:30:15.542Z",
            "timeSpent": 366,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgyAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgyAwAAAAAAAA==/",
            "_etag": "\"08006055-0000-0100-0000-619221910000\"",
            "_attachments": "attachments/",
            "_ts": 1636966801
        },
        {
            "id": "d9e8316748112cc6f5858bad6a6d28a8e75496b03e9a1323f8291b91910be57f",
            "conversationId": "d9e8316748112cc6f5858bad6a6d28a8e75496b03e9a1323f8291b91910be57f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 50,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache",
                "Cough"
            ],
            "riskFactors": [
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic for something other than an appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2021-11-15T11:10:32.908Z",
            "endTime": "2021-11-15T11:12:17.309Z",
            "timeSpent": 104,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgzAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgzAwAAAAAAAA==/",
            "_etag": "\"0800c59d-0000-0100-0000-619259d70000\"",
            "_attachments": "attachments/",
            "_ts": 1636981207
        },
    

    
        {
            "id": "1054b8865ca4b9c6fd593365944a557297f02f0d6cfd1a65255ab568a727dc3c",
            "conversationId": "1054b8865ca4b9c6fd593365944a557297f02f0d6cfd1a65255ab568a727dc3c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-15T11:55:12.142Z",
            "endTime": "2021-11-15T11:57:57.692Z",
            "timeSpent": 166,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg0AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg0AwAAAAAAAA==/",
            "_etag": "\"0800d2ae-0000-0100-0000-619267e40000\"",
            "_attachments": "attachments/",
            "_ts": 1636984804
        },
        {
            "id": "2008a99f0396a4310c10ec1aa9bb50ed47886192a45a39da45396f1fd6b97c31",
            "conversationId": "2008a99f0396a4310c10ec1aa9bb50ed47886192a45a39da45396f1fd6b97c31",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-15T12:14:55.571Z",
            "endTime": "2021-11-15T12:16:00.406Z",
            "timeSpent": 65,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg1AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg1AwAAAAAAAA==/",
            "_etag": "\"0800cdae-0000-0100-0000-619267e40000\"",
            "_attachments": "attachments/",
            "_ts": 1636984804
        },
        {
            "id": "21226b3d266f45a8e015c6633928ae649e9d232c27814649669e6bd23182d931",
            "conversationId": "21226b3d266f45a8e015c6633928ae649e9d232c27814649669e6bd23182d931",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-15T12:25:42.055Z",
            "endTime": "2021-11-15T12:27:45.264Z",
            "timeSpent": 123,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg2AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg2AwAAAAAAAA==/",
            "_etag": "\"0800ceae-0000-0100-0000-619267e40000\"",
            "_attachments": "attachments/",
            "_ts": 1636984804
        },
        {
            "id": "0ed9fc85f937621f114a27fa4d900060b7e9409cacc825b8e52037efe4e1531b",
            "conversationId": "0ed9fc85f937621f114a27fa4d900060b7e9409cacc825b8e52037efe4e1531b",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-15T12:19:00.839Z",
            "endTime": "2021-11-15T12:20:15.497Z",
            "timeSpent": 75,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg3AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg3AwAAAAAAAA==/",
            "_etag": "\"0800cfae-0000-0100-0000-619267e40000\"",
            "_attachments": "attachments/",
            "_ts": 1636984804
        },
        {
            "id": "d67fb250a44c466a00aed6ffc971049af29b3980d49c61f3817ce3fbec99e975",
            "conversationId": "d67fb250a44c466a00aed6ffc971049af29b3980d49c61f3817ce3fbec99e975",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T12:20:00.071Z",
            "endTime": "2021-11-15T12:20:00.071Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg4AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg4AwAAAAAAAA==/",
            "_etag": "\"0800d0ae-0000-0100-0000-619267e40000\"",
            "_attachments": "attachments/",
            "_ts": 1636984804
        },
        {
            "id": "e3e1313ee34266dda42cde11dada8087a387af59e807f8e7290429b2c61c4e20",
            "conversationId": "e3e1313ee34266dda42cde11dada8087a387af59e807f8e7290429b2c61c4e20",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T12:53:22.122Z",
            "endTime": "2021-11-15T12:53:22.122Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg5AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg5AwAAAAAAAA==/",
            "_etag": "\"0800c7c4-0000-0100-0000-619275f20000\"",
            "_attachments": "attachments/",
            "_ts": 1636988402
        },
        {
            "id": "ffdb3d36eb3eb64781f4ca7d18c6f7a6632ba46d9a677d4a093f83c4b8b8acc8",
            "conversationId": "ffdb3d36eb3eb64781f4ca7d18c6f7a6632ba46d9a677d4a093f83c4b8b8acc8",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T14:03:07.227Z",
            "endTime": "2021-11-15T14:04:07.655Z",
            "timeSpent": 60,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg6AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg6AwAAAAAAAA==/",
            "_etag": "\"0800f4e0-0000-0100-0000-619284020000\"",
            "_attachments": "attachments/",
            "_ts": 1636992002
        },
        {
            "id": "a703380c7c3b637e3340ea7db3c9fdbbda48aad47d0d60783e9c73b9b6dc333d",
            "conversationId": "a703380c7c3b637e3340ea7db3c9fdbbda48aad47d0d60783e9c73b9b6dc333d",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 32,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T14:53:15.201Z",
            "endTime": "2021-11-15T14:53:46.669Z",
            "timeSpent": 31,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg7AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg7AwAAAAAAAA==/",
            "_etag": "\"080088fb-0000-0100-0000-619292130000\"",
            "_attachments": "attachments/",
            "_ts": 1636995603
        },
        {
            "id": "1854a32c9e688cd376e28dd4c3e46d8eef55b6ccc7f8f40c2795402fa8ebff72",
            "conversationId": "1854a32c9e688cd376e28dd4c3e46d8eef55b6ccc7f8f40c2795402fa8ebff72",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 33,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T16:23:08.527Z",
            "endTime": "2021-11-15T16:45:27.367Z",
            "timeSpent": 1339,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg8AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg8AwAAAAAAAA==/",
            "_etag": "\"0900c629-0000-0100-0000-6192ae340000\"",
            "_attachments": "attachments/",
            "_ts": 1637002804
        },
        {
            "id": "6190e62b572aab201dabfb7b79ab4366ffee03f3c4d278e4a697922b798b3177",
            "conversationId": "6190e62b572aab201dabfb7b79ab4366ffee03f3c4d278e4a697922b798b3177",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 22,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-11-15T15:59:43.136Z",
            "endTime": "2021-11-15T16:18:50.701Z",
            "timeSpent": 1148,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg9AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg9AwAAAAAAAA==/",
            "_etag": "\"0900fd0f-0000-0100-0000-6192a0240000\"",
            "_attachments": "attachments/",
            "_ts": 1636999204
        },
        {
            "id": "76b2cf95acd7646ac87884056a6c6fc9f4a0e2133c89fcaf0f97c60b2f04f956",
            "conversationId": "76b2cf95acd7646ac87884056a6c6fc9f4a0e2133c89fcaf0f97c60b2f04f956",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T17:07:12.022Z",
            "endTime": "2021-11-15T17:07:12.022Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg+AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg+AwAAAAAAAA==/",
            "_etag": "\"0900ae29-0000-0100-0000-6192ae340000\"",
            "_attachments": "attachments/",
            "_ts": 1637002804
        },
        {
            "id": "717e270f53af9ca4fc37e637feaeeb609711ca63fd8194a764127aa2153a33b6",
            "conversationId": "717e270f53af9ca4fc37e637feaeeb609711ca63fd8194a764127aa2153a33b6",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T17:09:26.492Z",
            "endTime": "2021-11-15T17:09:26.492Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg-AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg-AwAAAAAAAA==/",
            "_etag": "\"0900af29-0000-0100-0000-6192ae340000\"",
            "_attachments": "attachments/",
            "_ts": 1637002804
        },
        {
            "id": "e548c04be2424728ffa88ec3a7e9de02c548d94c7ed1cea508eab21b15c56711",
            "conversationId": "e548c04be2424728ffa88ec3a7e9de02c548d94c7ed1cea508eab21b15c56711",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T17:09:03.101Z",
            "endTime": "2021-11-15T17:09:03.101Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhAAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhAAwAAAAAAAA==/",
            "_etag": "\"0900b929-0000-0100-0000-6192ae340000\"",
            "_attachments": "attachments/",
            "_ts": 1637002804
        },
        {
            "id": "2cf3647b321454f56d57c665ad9fffd13895bdbc808236307e5c39642eef0853",
            "conversationId": "2cf3647b321454f56d57c665ad9fffd13895bdbc808236307e5c39642eef0853",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T17:30:51.011Z",
            "endTime": "2021-11-15T17:30:51.011Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhBAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhBAwAAAAAAAA==/",
            "_etag": "\"09006a40-0000-0100-0000-6192bc420000\"",
            "_attachments": "attachments/",
            "_ts": 1637006402
        },
        {
            "id": "3db669da2a11c6045891bad3e4981d6f161912c09dd48a18bc39994cafd16684",
            "conversationId": "3db669da2a11c6045891bad3e4981d6f161912c09dd48a18bc39994cafd16684",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T17:31:01.579Z",
            "endTime": "2021-11-15T17:31:01.579Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhCAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhCAwAAAAAAAA==/",
            "_etag": "\"09005940-0000-0100-0000-6192bc420000\"",
            "_attachments": "attachments/",
            "_ts": 1637006402
        },
        {
            "id": "530c8eb4d42fe1334f3850ae19d1cf26585215604d4533a0fc48ec8583ea26e5",
            "conversationId": "530c8eb4d42fe1334f3850ae19d1cf26585215604d4533a0fc48ec8583ea26e5",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T17:43:31.588Z",
            "endTime": "2021-11-15T17:43:31.588Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhDAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhDAwAAAAAAAA==/",
            "_etag": "\"09004a40-0000-0100-0000-6192bc420000\"",
            "_attachments": "attachments/",
            "_ts": 1637006402
        },
        {
            "id": "5b1438caa838ec805b46932cba4031274394c90c16604a56794c9754880933fb",
            "conversationId": "5b1438caa838ec805b46932cba4031274394c90c16604a56794c9754880933fb",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T17:49:44.27Z",
            "endTime": "2021-11-15T17:49:44.27Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhEAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhEAwAAAAAAAA==/",
            "_etag": "\"09006140-0000-0100-0000-6192bc420000\"",
            "_attachments": "attachments/",
            "_ts": 1637006402
        },
        {
            "id": "49a3e6d42b397f54b8c689daf06009f229c9e12c1a3d47fac2508589b2757e05",
            "conversationId": "49a3e6d42b397f54b8c689daf06009f229c9e12c1a3d47fac2508589b2757e05",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T17:49:49.637Z",
            "endTime": "2021-11-15T17:49:49.637Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhFAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhFAwAAAAAAAA==/",
            "_etag": "\"09006240-0000-0100-0000-6192bc420000\"",
            "_attachments": "attachments/",
            "_ts": 1637006402
        },
        {
            "id": "730ea61b12c8d4a1a8d339166e204d1d893e5bcda4e982e813c442d49491463d",
            "conversationId": "730ea61b12c8d4a1a8d339166e204d1d893e5bcda4e982e813c442d49491463d",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T17:58:09.961Z",
            "endTime": "2021-11-15T17:58:09.961Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhGAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhGAwAAAAAAAA==/",
            "_etag": "\"09006f40-0000-0100-0000-6192bc420000\"",
            "_attachments": "attachments/",
            "_ts": 1637006402
        },
        {
            "id": "e8686cb5c620b9870f5f700549c4ea2663c77bad2b42079d1b8652bc21ec3eb5",
            "conversationId": "e8686cb5c620b9870f5f700549c4ea2663c77bad2b42079d1b8652bc21ec3eb5",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T17:11:58.996Z",
            "endTime": "2021-11-15T17:11:58.996Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhHAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhHAwAAAAAAAA==/",
            "_etag": "\"0900b029-0000-0100-0000-6192ae340000\"",
            "_attachments": "attachments/",
            "_ts": 1637002804
        },
        {
            "id": "eecbb860434bba8492bde178f7029a12ebed08eaeaf6b187125b0a6e11e7cf7e",
            "conversationId": "eecbb860434bba8492bde178f7029a12ebed08eaeaf6b187125b0a6e11e7cf7e",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T17:44:31.684Z",
            "endTime": "2021-11-15T17:44:31.684Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhIAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhIAwAAAAAAAA==/",
            "_etag": "\"09006e40-0000-0100-0000-6192bc420000\"",
            "_attachments": "attachments/",
            "_ts": 1637006402
        },
        {
            "id": "41c9192aefaa3d43e1be88b55cafed861470ab7dfc3276cb49bd5b5c9ba9b848",
            "conversationId": "41c9192aefaa3d43e1be88b55cafed861470ab7dfc3276cb49bd5b5c9ba9b848",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T17:32:24.483Z",
            "endTime": "2021-11-15T17:32:24.483Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhJAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhJAwAAAAAAAA==/",
            "_etag": "\"09005b40-0000-0100-0000-6192bc420000\"",
            "_attachments": "attachments/",
            "_ts": 1637006402
        },
        {
            "id": "70eafbe2d1afa5d905cab50ea1edcf23c66ed4704462cb18990f04e5ba79504c",
            "conversationId": "70eafbe2d1afa5d905cab50ea1edcf23c66ed4704462cb18990f04e5ba79504c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T18:11:08.47Z",
            "endTime": "2021-11-15T18:11:12.475Z",
            "timeSpent": 4,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhKAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhKAwAAAAAAAA==/",
            "_etag": "\"09004940-0000-0100-0000-6192bc420000\"",
            "_attachments": "attachments/",
            "_ts": 1637006402
        },
        {
            "id": "875236791a5c0c9ad7b472ffbd48f7cfd839cb538689e1c61ab1544515f2c557",
            "conversationId": "875236791a5c0c9ad7b472ffbd48f7cfd839cb538689e1c61ab1544515f2c557",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T18:12:43.889Z",
            "endTime": "2021-11-15T18:12:55.967Z",
            "timeSpent": 12,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhLAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhLAwAAAAAAAA==/",
            "_etag": "\"09004b40-0000-0100-0000-6192bc420000\"",
            "_attachments": "attachments/",
            "_ts": 1637006402
        },
        {
            "id": "01b2fcfd0c353a4eaf3d3e1051c301cb84b3e12451ecfeb38fa26cc2bedc6ed6",
            "conversationId": "01b2fcfd0c353a4eaf3d3e1051c301cb84b3e12451ecfeb38fa26cc2bedc6ed6",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T18:19:07.429Z",
            "endTime": "2021-11-15T18:19:07.429Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhMAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhMAwAAAAAAAA==/",
            "_etag": "\"09004c40-0000-0100-0000-6192bc420000\"",
            "_attachments": "attachments/",
            "_ts": 1637006402
        },
        {
            "id": "903cd6bb713368242ab4047dc9ce109fc8c4d820dc839cc23b6c7ee5a689c75c",
            "conversationId": "903cd6bb713368242ab4047dc9ce109fc8c4d820dc839cc23b6c7ee5a689c75c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T18:53:02.868Z",
            "endTime": "2021-11-15T18:53:02.868Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhNAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhNAwAAAAAAAA==/",
            "_etag": "\"0900be54-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "e317b2f90598245c7d1f448c68ac5e23a60bec15452cf0de6a2612f25c48d3b1",
            "conversationId": "e317b2f90598245c7d1f448c68ac5e23a60bec15452cf0de6a2612f25c48d3b1",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T18:54:05.574Z",
            "endTime": "2021-11-15T18:54:05.574Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhOAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhOAwAAAAAAAA==/",
            "_etag": "\"0900bf54-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "c9f3db54457b76e24fc15444f8f5d13ca8ced7c5ad45928ea2ab91a4e3bd0251",
            "conversationId": "c9f3db54457b76e24fc15444f8f5d13ca8ced7c5ad45928ea2ab91a4e3bd0251",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 33,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T18:46:34.66Z",
            "endTime": "2021-11-15T19:01:00.845Z",
            "timeSpent": 866,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhPAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhPAwAAAAAAAA==/",
            "_etag": "\"0900bd54-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "32c545e28ade46b8e52ede431d702150509905dd4517ed94cc30e9f48c9ade6f",
            "conversationId": "32c545e28ade46b8e52ede431d702150509905dd4517ed94cc30e9f48c9ade6f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 32,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2021-11-15T18:57:53.396Z",
            "endTime": "2021-11-15T19:00:25.676Z",
            "timeSpent": 152,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhQAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhQAwAAAAAAAA==/",
            "_etag": "\"0900b554-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "0ae49dbaa1d7993d637ef26e98a7331f9251006d438255d5c5066e2fd174f5bd",
            "conversationId": "0ae49dbaa1d7993d637ef26e98a7331f9251006d438255d5c5066e2fd174f5bd",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T18:02:32.613Z",
            "endTime": "2021-11-15T18:02:32.613Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhRAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhRAwAAAAAAAA==/",
            "_etag": "\"09005a40-0000-0100-0000-6192bc420000\"",
            "_attachments": "attachments/",
            "_ts": 1637006402
        },
        {
            "id": "64633feacd2138622c12b2bb6597d176508ab6062e670273c4b0cef5ef8a59f3",
            "conversationId": "64633feacd2138622c12b2bb6597d176508ab6062e670273c4b0cef5ef8a59f3",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T18:47:43.645Z",
            "endTime": "2021-11-15T18:47:43.645Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhSAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhSAwAAAAAAAA==/",
            "_etag": "\"0900b654-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "6f06bead2b98727914dd58b7589dd76d0d8c5352173190b83c2dcff159dc40bf",
            "conversationId": "6f06bead2b98727914dd58b7589dd76d0d8c5352173190b83c2dcff159dc40bf",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T18:48:25.758Z",
            "endTime": "2021-11-15T18:48:25.758Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhTAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhTAwAAAAAAAA==/",
            "_etag": "\"0900b754-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "1aa73ec64ddf84d8193d0e279d53b14d0e0d27273c31fa2c8b205108ca6075be",
            "conversationId": "1aa73ec64ddf84d8193d0e279d53b14d0e0d27273c31fa2c8b205108ca6075be",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T18:48:20.576Z",
            "endTime": "2021-11-15T18:48:20.576Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhUAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhUAwAAAAAAAA==/",
            "_etag": "\"0900aa54-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "563c6cf4f32e86a01d5cfc2914b2ed6fa6e8eeb56cc8150ea3f63921e9136686",
            "conversationId": "563c6cf4f32e86a01d5cfc2914b2ed6fa6e8eeb56cc8150ea3f63921e9136686",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T18:48:56.705Z",
            "endTime": "2021-11-15T18:48:56.705Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhVAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhVAwAAAAAAAA==/",
            "_etag": "\"0900ab54-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "a40107d5995436fba01138a9429f8e0d2a1c75968f083f87d2ad66d5d760ec2a",
            "conversationId": "a40107d5995436fba01138a9429f8e0d2a1c75968f083f87d2ad66d5d760ec2a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T18:02:49.322Z",
            "endTime": "2021-11-15T18:02:49.322Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhWAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhWAwAAAAAAAA==/",
            "_etag": "\"09006b40-0000-0100-0000-6192bc420000\"",
            "_attachments": "attachments/",
            "_ts": 1637006402
        },
        {
            "id": "97b9fb63616f008c953c8f3e350534c98f21518ef4d4cd7f7126c10818bda207",
            "conversationId": "97b9fb63616f008c953c8f3e350534c98f21518ef4d4cd7f7126c10818bda207",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T18:04:27.241Z",
            "endTime": "2021-11-15T18:04:27.241Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhXAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhXAwAAAAAAAA==/",
            "_etag": "\"09006c40-0000-0100-0000-6192bc420000\"",
            "_attachments": "attachments/",
            "_ts": 1637006402
        },
        {
            "id": "0f8f15ba99366b2f495a09e5644c453c805553db2692c35cf334e4cd51b3c591",
            "conversationId": "0f8f15ba99366b2f495a09e5644c453c805553db2692c35cf334e4cd51b3c591",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-15T18:05:53.35Z",
            "endTime": "2021-11-15T18:06:00.022Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhYAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhYAwAAAAAAAA==/",
            "_etag": "\"09006d40-0000-0100-0000-6192bc420000\"",
            "_attachments": "attachments/",
            "_ts": 1637006402
        },
        {
            "id": "b484ff130830f5ec6d2e654b0501bcd2d42555f99a6a77d35aca544038efb63f",
            "conversationId": "b484ff130830f5ec6d2e654b0501bcd2d42555f99a6a77d35aca544038efb63f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T18:15:49.638Z",
            "endTime": "2021-11-15T18:15:49.638Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhZAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhZAwAAAAAAAA==/",
            "_etag": "\"09006340-0000-0100-0000-6192bc420000\"",
            "_attachments": "attachments/",
            "_ts": 1637006402
        },
        {
            "id": "93156d6959c54c637f910f4a3b2ae44b537186ecbff054cf8b452a7140f20855",
            "conversationId": "93156d6959c54c637f910f4a3b2ae44b537186ecbff054cf8b452a7140f20855",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T18:15:57.359Z",
            "endTime": "2021-11-15T18:15:57.359Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhaAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhaAwAAAAAAAA==/",
            "_etag": "\"09006440-0000-0100-0000-6192bc420000\"",
            "_attachments": "attachments/",
            "_ts": 1637006402
        },
        {
            "id": "8e8f662e2e600fb027e3ec8bc030b038f2724ef99b1a056c4bae2b2575408d1c",
            "conversationId": "8e8f662e2e600fb027e3ec8bc030b038f2724ef99b1a056c4bae2b2575408d1c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 45,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-11-15T19:00:44.339Z",
            "endTime": "2021-11-15T19:09:46.699Z",
            "timeSpent": 542,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhbAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhbAwAAAAAAAA==/",
            "_etag": "\"0900a054-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "5e4f9a2f1049751ba46cb231668c86fe7862dd9562b40cdb6612eebf96e68091",
            "conversationId": "5e4f9a2f1049751ba46cb231668c86fe7862dd9562b40cdb6612eebf96e68091",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-15T19:10:13.405Z",
            "endTime": "2021-11-15T19:26:09.996Z",
            "timeSpent": 957,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhcAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhcAwAAAAAAAA==/",
            "_etag": "\"0900a554-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "d1f8d0c9f4f0ebc8dfddfa41258fe8fcc57838ded76be03ef2b872c939e0e581",
            "conversationId": "d1f8d0c9f4f0ebc8dfddfa41258fe8fcc57838ded76be03ef2b872c939e0e581",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 32,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-11-15T19:26:33.541Z",
            "endTime": "2021-11-15T19:28:46.746Z",
            "timeSpent": 133,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhdAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhdAwAAAAAAAA==/",
            "_etag": "\"0900ac54-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "e39df2e7a82357516a25a8c46ba21cf2b1de3098c99eaf1a39ba7390af19476e",
            "conversationId": "e39df2e7a82357516a25a8c46ba21cf2b1de3098c99eaf1a39ba7390af19476e",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 50,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache",
                "Cough"
            ],
            "riskFactors": [
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic for something other than an appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2021-11-15T19:33:21.234Z",
            "endTime": "2021-11-15T19:36:17.123Z",
            "timeSpent": 176,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIheAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIheAwAAAAAAAA==/",
            "_etag": "\"09001366-0000-0100-0000-6192d8650000\"",
            "_attachments": "attachments/",
            "_ts": 1637013605
        },
        {
            "id": "f3c56e0815a3723d98ae06187553a99b84a6890e0a63ef3b0edb85d437ef3a59",
            "conversationId": "f3c56e0815a3723d98ae06187553a99b84a6890e0a63ef3b0edb85d437ef3a59",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 44,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T19:36:56.533Z",
            "endTime": "2021-11-15T19:37:45.148Z",
            "timeSpent": 49,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhfAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhfAwAAAAAAAA==/",
            "_etag": "\"09000d66-0000-0100-0000-6192d8650000\"",
            "_attachments": "attachments/",
            "_ts": 1637013605
        },
        {
            "id": "b71637c572dec3b8b986ea6372df39a5e743df2f997850b839d20d6d31c23ed9",
            "conversationId": "b71637c572dec3b8b986ea6372df39a5e743df2f997850b839d20d6d31c23ed9",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-15T19:40:17.04Z",
            "endTime": "2021-11-15T19:40:24.708Z",
            "timeSpent": 8,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhgAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhgAwAAAAAAAA==/",
            "_etag": "\"0900b854-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "f6bd024a779ff4ca0e27884a796894ff23eca2af90b04faf01018838cc505dbf",
            "conversationId": "f6bd024a779ff4ca0e27884a796894ff23eca2af90b04faf01018838cc505dbf",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T19:42:19.681Z",
            "endTime": "2021-11-15T19:42:58.898Z",
            "timeSpent": 39,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhhAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhhAwAAAAAAAA==/",
            "_etag": "\"09000f66-0000-0100-0000-6192d8650000\"",
            "_attachments": "attachments/",
            "_ts": 1637013605
        },
        {
            "id": "080b528ca68b51a798009f4fdcda1d25ac9a876205201d8b74ed0ea852d3e139",
            "conversationId": "080b528ca68b51a798009f4fdcda1d25ac9a876205201d8b74ed0ea852d3e139",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 33,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-11-15T19:43:46.034Z",
            "endTime": "2021-11-15T19:44:10.56Z",
            "timeSpent": 25,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhiAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhiAwAAAAAAAA==/",
            "_etag": "\"09001466-0000-0100-0000-6192d8650000\"",
            "_attachments": "attachments/",
            "_ts": 1637013605
        },
        {
            "id": "9ad2dddb4ea016c2224dd93fe11e5ceb31234ee6c6e07ad2031db4a5244f057c",
            "conversationId": "9ad2dddb4ea016c2224dd93fe11e5ceb31234ee6c6e07ad2031db4a5244f057c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T19:09:04.782Z",
            "endTime": "2021-11-15T19:09:04.782Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhjAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhjAwAAAAAAAA==/",
            "_etag": "\"0900ae54-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "93f1ff182c94a6b9996cc2d4b56ec77f36ae3bc19315527b07c7b2c772d34e40",
            "conversationId": "93f1ff182c94a6b9996cc2d4b56ec77f36ae3bc19315527b07c7b2c772d34e40",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T19:09:22.646Z",
            "endTime": "2021-11-15T19:09:22.646Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhkAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhkAwAAAAAAAA==/",
            "_etag": "\"0900af54-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "60197ab0dc8df4319f673981551c66c4c7b90fa8b988a3f21dc2ae1496c21577",
            "conversationId": "60197ab0dc8df4319f673981551c66c4c7b90fa8b988a3f21dc2ae1496c21577",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T19:09:22.877Z",
            "endTime": "2021-11-15T19:09:22.877Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhlAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhlAwAAAAAAAA==/",
            "_etag": "\"0900b054-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "ac79b624d111e5919c675e036089598cf6e8d259d2fca1339aa0f946e788fca1",
            "conversationId": "ac79b624d111e5919c675e036089598cf6e8d259d2fca1339aa0f946e788fca1",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T19:11:54.665Z",
            "endTime": "2021-11-15T19:11:54.665Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhmAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhmAwAAAAAAAA==/",
            "_etag": "\"0900b154-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "1a3fce71f95e25bd9c7bddbab7fd318d661b81b5abdd17760e0a2b7e99a1e95f",
            "conversationId": "1a3fce71f95e25bd9c7bddbab7fd318d661b81b5abdd17760e0a2b7e99a1e95f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T19:54:21.022Z",
            "endTime": "2021-11-15T19:54:21.022Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhnAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhnAwAAAAAAAA==/",
            "_etag": "\"09000966-0000-0100-0000-6192d8650000\"",
            "_attachments": "attachments/",
            "_ts": 1637013605
        },
        {
            "id": "139ec0aa8ca51b764abefc7789a425bf1794860f7aff0edb217f519b6e04fe8f",
            "conversationId": "139ec0aa8ca51b764abefc7789a425bf1794860f7aff0edb217f519b6e04fe8f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-15T19:40:44.277Z",
            "endTime": "2021-11-15T19:40:55.432Z",
            "timeSpent": 11,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhoAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhoAwAAAAAAAA==/",
            "_etag": "\"0900b354-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "d53eaaa1b0b51a6967c6b054e7123151522e3069a80bad3c516a170a76e843f0",
            "conversationId": "d53eaaa1b0b51a6967c6b054e7123151522e3069a80bad3c516a170a76e843f0",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T19:04:43.648Z",
            "endTime": "2021-11-15T19:04:43.648Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhpAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhpAwAAAAAAAA==/",
            "_etag": "\"0900a154-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "0cb3e1123333ebdfcea771e7f8ffe8cdabc9931660f968968e54fc1072e19c27",
            "conversationId": "0cb3e1123333ebdfcea771e7f8ffe8cdabc9931660f968968e54fc1072e19c27",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T19:04:43.705Z",
            "endTime": "2021-11-15T19:04:43.705Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhqAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhqAwAAAAAAAA==/",
            "_etag": "\"0900a254-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "62bc9894e830cadb7fdc4bdd9cdd69f87dcf978cfb0320594741cfef5069722a",
            "conversationId": "62bc9894e830cadb7fdc4bdd9cdd69f87dcf978cfb0320594741cfef5069722a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T19:06:44.125Z",
            "endTime": "2021-11-15T19:06:44.125Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhrAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhrAwAAAAAAAA==/",
            "_etag": "\"0900a354-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "bdd139a3babf174b768367968438607dc7bfa89c6b1c819bcb3317f71614a65c",
            "conversationId": "bdd139a3babf174b768367968438607dc7bfa89c6b1c819bcb3317f71614a65c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T19:09:08.186Z",
            "endTime": "2021-11-15T19:09:08.186Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhsAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhsAwAAAAAAAA==/",
            "_etag": "\"0900a454-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "aab97b99e801cbe4a0a0d8696613b79a623c873b3d73f94f6cc2193b0750020a",
            "conversationId": "aab97b99e801cbe4a0a0d8696613b79a623c873b3d73f94f6cc2193b0750020a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T19:11:02.271Z",
            "endTime": "2021-11-15T19:11:02.271Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhtAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhtAwAAAAAAAA==/",
            "_etag": "\"0900a654-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "f25c5967e1c8af4ffca617d90bed60ce91d615ee36841cfed0c5827f70ec302c",
            "conversationId": "f25c5967e1c8af4ffca617d90bed60ce91d615ee36841cfed0c5827f70ec302c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T19:11:54.466Z",
            "endTime": "2021-11-15T19:11:54.466Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhuAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhuAwAAAAAAAA==/",
            "_etag": "\"0900a754-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "f755e796f39622a6125df6984cd757dc1d531830d2ebffded4d6137e9d871eb8",
            "conversationId": "f755e796f39622a6125df6984cd757dc1d531830d2ebffded4d6137e9d871eb8",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T19:12:22.714Z",
            "endTime": "2021-11-15T19:12:22.714Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhvAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhvAwAAAAAAAA==/",
            "_etag": "\"0900a854-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "deb0c6da44689cfdd8406604129a50170b9b9b9db62aa6976f4535e529052df8",
            "conversationId": "deb0c6da44689cfdd8406604129a50170b9b9b9db62aa6976f4535e529052df8",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T19:12:23.094Z",
            "endTime": "2021-11-15T19:12:23.094Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhwAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhwAwAAAAAAAA==/",
            "_etag": "\"0900a954-0000-0100-0000-6192ca590000\"",
            "_attachments": "attachments/",
            "_ts": 1637010009
        },
        {
            "id": "4f5f57a9b7dad755002eeeb7c8f0e8a564ceaab4ac7b87916ff937bde0cb9b54",
            "conversationId": "4f5f57a9b7dad755002eeeb7c8f0e8a564ceaab4ac7b87916ff937bde0cb9b54",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T20:18:38.592Z",
            "endTime": "2021-11-15T20:18:38.592Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhxAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhxAwAAAAAAAA==/",
            "_etag": "\"09000866-0000-0100-0000-6192d8650000\"",
            "_attachments": "attachments/",
            "_ts": 1637013605
        },
        {
            "id": "7614080a890445187363fe2e5f0ff8fce18163c11f22a0f42da46c9115ba472d",
            "conversationId": "7614080a890445187363fe2e5f0ff8fce18163c11f22a0f42da46c9115ba472d",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T20:54:22.578Z",
            "endTime": "2021-11-15T20:54:22.578Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhyAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhyAwAAAAAAAA==/",
            "_etag": "\"0900f477-0000-0100-0000-6192e6730000\"",
            "_attachments": "attachments/",
            "_ts": 1637017203
        },
        {
            "id": "dc3bcb198ab8eaf90acb68c1a7fb85737f11bc70225d24931796358d60ac5fe3",
            "conversationId": "dc3bcb198ab8eaf90acb68c1a7fb85737f11bc70225d24931796358d60ac5fe3",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 34,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T20:56:25.319Z",
            "endTime": "2021-11-15T20:56:54.479Z",
            "timeSpent": 29,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhzAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhzAwAAAAAAAA==/",
            "_etag": "\"0900ee77-0000-0100-0000-6192e6730000\"",
            "_attachments": "attachments/",
            "_ts": 1637017203
        },
        {
            "id": "f082671cffe97d2a5a11e104e75203e47fa751d50109560999cb67927484585c",
            "conversationId": "f082671cffe97d2a5a11e104e75203e47fa751d50109560999cb67927484585c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T21:47:22.443Z",
            "endTime": "2021-11-15T21:47:22.443Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh0AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh0AwAAAAAAAA==/",
            "_etag": "\"0900ef88-0000-0100-0000-6192f4850000\"",
            "_attachments": "attachments/",
            "_ts": 1637020805
        },
        {
            "id": "13ef5f6b721a9e327d54371d7478ee39616ffe0f9a78da77c2f9b0c46dffa42a",
            "conversationId": "13ef5f6b721a9e327d54371d7478ee39616ffe0f9a78da77c2f9b0c46dffa42a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T21:50:31.902Z",
            "endTime": "2021-11-15T21:50:31.902Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh1AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh1AwAAAAAAAA==/",
            "_etag": "\"0900f088-0000-0100-0000-6192f4850000\"",
            "_attachments": "attachments/",
            "_ts": 1637020805
        },
        {
            "id": "1693379bee435b0feceaa793e75d7c63d38e64aa47d07fb082ebe1a3c969afe7",
            "conversationId": "1693379bee435b0feceaa793e75d7c63d38e64aa47d07fb082ebe1a3c969afe7",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T21:52:22.674Z",
            "endTime": "2021-11-15T21:52:22.674Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh2AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh2AwAAAAAAAA==/",
            "_etag": "\"0900f288-0000-0100-0000-6192f4850000\"",
            "_attachments": "attachments/",
            "_ts": 1637020805
        },
        {
            "id": "3c3b98e0643301e88a9aa1445e70511db657e817781c88f4191d409205d33000",
            "conversationId": "3c3b98e0643301e88a9aa1445e70511db657e817781c88f4191d409205d33000",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T21:24:21.232Z",
            "endTime": "2021-11-15T21:24:21.232Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh3AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh3AwAAAAAAAA==/",
            "_etag": "\"0900ed77-0000-0100-0000-6192e6730000\"",
            "_attachments": "attachments/",
            "_ts": 1637017203
        },
        {
            "id": "adb770e2231e2b1c3611d6d9dcd2ea3d3bdc4c79c0fab5482a97521f0e3face3",
            "conversationId": "adb770e2231e2b1c3611d6d9dcd2ea3d3bdc4c79c0fab5482a97521f0e3face3",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T21:51:08.595Z",
            "endTime": "2021-11-15T21:51:08.595Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh4AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh4AwAAAAAAAA==/",
            "_etag": "\"0900f188-0000-0100-0000-6192f4850000\"",
            "_attachments": "attachments/",
            "_ts": 1637020805
        },
        {
            "id": "b47c21bad80d97c64b58fee81dcd0fd5230356e3a4bfe37e62ac8de58ccbe367",
            "conversationId": "b47c21bad80d97c64b58fee81dcd0fd5230356e3a4bfe37e62ac8de58ccbe367",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-15T22:37:36.817Z",
            "endTime": "2021-11-15T22:37:36.817Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh5AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh5AwAAAAAAAA==/",
            "_etag": "\"0900879b-0000-0100-0000-619302930000\"",
            "_attachments": "attachments/",
            "_ts": 1637024403
        },
        {
            "id": "637179a1ff75d27460289c31589d33c791cfda7cba4caf9a2fb0aec0bfa97044",
            "conversationId": "637179a1ff75d27460289c31589d33c791cfda7cba4caf9a2fb0aec0bfa97044",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T01:00:49.328Z",
            "endTime": "2021-11-16T01:00:49.328Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh6AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh6AwAAAAAAAA==/",
            "_etag": "\"0900e5b7-0000-0100-0000-61931eb70000\"",
            "_attachments": "attachments/",
            "_ts": 1637031607
        },
        {
            "id": "ab1a7100b757d0ee797de850132d2b15e37e56c488cbd89e398b0011236b3ffd",
            "conversationId": "ab1a7100b757d0ee797de850132d2b15e37e56c488cbd89e398b0011236b3ffd",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 50,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T07:00:44.447Z",
            "endTime": "2021-11-16T07:01:07.642Z",
            "timeSpent": 23,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh7AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh7AwAAAAAAAA==/",
            "_etag": "\"0b00abf3-0000-0100-0000-619373150000\"",
            "_attachments": "attachments/",
            "_ts": 1637053205
        },
        {
            "id": "ec22fba399c3f6fac4c112cbec0565f06d5a782381c1ec7e7e3867f32a7cddf5",
            "conversationId": "ec22fba399c3f6fac4c112cbec0565f06d5a782381c1ec7e7e3867f32a7cddf5",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 65,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T07:02:04.728Z",
            "endTime": "2021-11-16T07:03:28.483Z",
            "timeSpent": 84,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh8AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh8AwAAAAAAAA==/",
            "_etag": "\"0b00aaf3-0000-0100-0000-619373150000\"",
            "_attachments": "attachments/",
            "_ts": 1637053205
        },
        {
            "id": "901f7245d46d1f5dd72ab9eb95749cdcdea0d636a383c2b0b5e9ae1bc0e741cd",
            "conversationId": "901f7245d46d1f5dd72ab9eb95749cdcdea0d636a383c2b0b5e9ae1bc0e741cd",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 50,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache",
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-11-16T11:50:06.242Z",
            "endTime": "2021-11-16T11:52:26.794Z",
            "timeSpent": 141,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh9AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh9AwAAAAAAAA==/",
            "_etag": "\"0c00f1da-0000-0100-0000-6193b9660000\"",
            "_attachments": "attachments/",
            "_ts": 1637071206
        },
        {
            "id": "94acf47791e5b4692fa8de6745e6e0aef293e2f7af74aaa4ca74b51d470e4546",
            "conversationId": "94acf47791e5b4692fa8de6745e6e0aef293e2f7af74aaa4ca74b51d470e4546",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 25,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Fever"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to Urgent Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T12:09:59.256Z",
            "endTime": "2021-11-16T12:27:40.41Z",
            "timeSpent": 1061,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh+AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh+AwAAAAAAAA==/",
            "_etag": "\"0c00e4da-0000-0100-0000-6193b9660000\"",
            "_attachments": "attachments/",
            "_ts": 1637071206
        },
        {
            "id": "39d55019a540e32b7edf16c2fd44cf3a80204f30bc4a6fd2c0bdfeacce7bb660",
            "conversationId": "39d55019a540e32b7edf16c2fd44cf3a80204f30bc4a6fd2c0bdfeacce7bb660",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 25,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Fever"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to Urgent Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T12:28:42.221Z",
            "endTime": "2021-11-16T12:32:30.141Z",
            "timeSpent": 228,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh-AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh-AwAAAAAAAA==/",
            "_etag": "\"0c00e5da-0000-0100-0000-6193b9660000\"",
            "_attachments": "attachments/",
            "_ts": 1637071206
        },
        {
            "id": "12a06a9839d33dca1d933ff7b748a0ec8f39480d42dbaa33e0493329df660fa9",
            "conversationId": "12a06a9839d33dca1d933ff7b748a0ec8f39480d42dbaa33e0493329df660fa9",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T13:54:07.99Z",
            "endTime": "2021-11-16T13:58:37.012Z",
            "timeSpent": 269,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiAAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiAAwAAAAAAAA==/",
            "_etag": "\"0c0074fa-0000-0100-0000-6193c7760000\"",
            "_attachments": "attachments/",
            "_ts": 1637074806
        },
        {
            "id": "3c9ae05891abe31c8fc85a4475034ef30e106c9b71e2f66ddf80f14d8f1e6210",
            "conversationId": "3c9ae05891abe31c8fc85a4475034ef30e106c9b71e2f66ddf80f14d8f1e6210",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 25,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Fever"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T12:50:15.739Z",
            "endTime": "2021-11-16T12:53:29.569Z",
            "timeSpent": 194,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiBAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiBAwAAAAAAAA==/",
            "_etag": "\"0c00e8da-0000-0100-0000-6193b9660000\"",
            "_attachments": "attachments/",
            "_ts": 1637071206
        },
        {
            "id": "d04bb9f14b92bfbe49f1ab1de9b5cbe8103fdcfaea0d922796331c90d7acc3d0",
            "conversationId": "d04bb9f14b92bfbe49f1ab1de9b5cbe8103fdcfaea0d922796331c90d7acc3d0",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 25,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Fever"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2021-11-16T12:55:09.799Z",
            "endTime": "2021-11-16T12:58:49.113Z",
            "timeSpent": 219,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiCAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiCAwAAAAAAAA==/",
            "_etag": "\"0c0076fa-0000-0100-0000-6193c7760000\"",
            "_attachments": "attachments/",
            "_ts": 1637074806
        },
        {
            "id": "f082ae023674776d7aeadb11b020bea61d22c0553e09289cf37ddec2587c8f5c",
            "conversationId": "f082ae023674776d7aeadb11b020bea61d22c0553e09289cf37ddec2587c8f5c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T12:02:24.007Z",
            "endTime": "2021-11-16T12:02:24.007Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiDAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiDAwAAAAAAAA==/",
            "_etag": "\"0c00e6da-0000-0100-0000-6193b9660000\"",
            "_attachments": "attachments/",
            "_ts": 1637071206
        },
        {
            "id": "ffbd8c492b8249f8cb9fd0743f3f655c6330ff6555956adac651017e2912ba20",
            "conversationId": "ffbd8c492b8249f8cb9fd0743f3f655c6330ff6555956adac651017e2912ba20",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 50,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache",
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic for something other than an appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-11-16T12:04:58.902Z",
            "endTime": "2021-11-16T12:05:56.046Z",
            "timeSpent": 57,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiEAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiEAwAAAAAAAA==/",
            "_etag": "\"0c00e3da-0000-0100-0000-6193b9660000\"",
            "_attachments": "attachments/",
            "_ts": 1637071206
        },
        {
            "id": "2be7fca51e9faea769f487b603bca1f89b09e6255de89ce407bca2e934f4c580",
            "conversationId": "2be7fca51e9faea769f487b603bca1f89b09e6255de89ce407bca2e934f4c580",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 50,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic for something other than an appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-16T12:06:01.021Z",
            "endTime": "2021-11-16T12:06:57.832Z",
            "timeSpent": 57,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiFAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiFAwAAAAAAAA==/",
            "_etag": "\"0c00ebda-0000-0100-0000-6193b9660000\"",
            "_attachments": "attachments/",
            "_ts": 1637071206
        },
        {
            "id": "af106247219cebe213e9427db5a7647ed571b14250d137e3f8544ac22de71171",
            "conversationId": "af106247219cebe213e9427db5a7647ed571b14250d137e3f8544ac22de71171",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 50,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-16T12:07:06.106Z",
            "endTime": "2021-11-16T12:08:15.545Z",
            "timeSpent": 69,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiGAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiGAwAAAAAAAA==/",
            "_etag": "\"0c00ecda-0000-0100-0000-6193b9660000\"",
            "_attachments": "attachments/",
            "_ts": 1637071206
        },
        {
            "id": "bf81cf6591a6008d5c23fa72399689c0806566e6715db24282344627df954b48",
            "conversationId": "bf81cf6591a6008d5c23fa72399689c0806566e6715db24282344627df954b48",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-16T12:08:41.457Z",
            "endTime": "2021-11-16T12:09:50.692Z",
            "timeSpent": 69,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiHAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiHAwAAAAAAAA==/",
            "_etag": "\"0c00edda-0000-0100-0000-6193b9660000\"",
            "_attachments": "attachments/",
            "_ts": 1637071206
        },
        {
            "id": "aab55f79e83a6e57dadbf572b502db209173ebda226abcd9cf2e28d67a7f3975",
            "conversationId": "aab55f79e83a6e57dadbf572b502db209173ebda226abcd9cf2e28d67a7f3975",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Knee pain",
                "Injury"
            ],
            "riskFactors": [
                "Obesity",
                "Hypertension",
                "Recent physical injury"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2021-11-16T13:23:02.103Z",
            "endTime": "2021-11-16T13:30:02.114Z",
            "timeSpent": 420,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiIAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiIAwAAAAAAAA==/",
            "_etag": "\"0d002332-0000-0100-0000-6193d5860000\"",
            "_attachments": "attachments/",
            "_ts": 1637078406
        },
        {
            "id": "65b5f934640ab4e69c4ccd2d289938b7e1b4e924bb2cb8c5c1b8edc25d57457b",
            "conversationId": "65b5f934640ab4e69c4ccd2d289938b7e1b4e924bb2cb8c5c1b8edc25d57457b",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [
                "Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the Emergency Room",
            "patientPlansTo": "I will try Self-Care",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-16T13:21:10.132Z",
            "endTime": "2021-11-16T13:22:54.176Z",
            "timeSpent": 104,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiJAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiJAwAAAAAAAA==/",
            "_etag": "\"0c0071fa-0000-0100-0000-6193c7760000\"",
            "_attachments": "attachments/",
            "_ts": 1637074806
        },
        {
            "id": "8d7370c60c974ca24001ba9852729efa8fe4cc6355022f0b80402a0216f7949d",
            "conversationId": "8d7370c60c974ca24001ba9852729efa8fe4cc6355022f0b80402a0216f7949d",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T13:51:13.832Z",
            "endTime": "2021-11-16T13:51:13.832Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiKAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiKAwAAAAAAAA==/",
            "_etag": "\"0d003932-0000-0100-0000-6193d5860000\"",
            "_attachments": "attachments/",
            "_ts": 1637078406
        },
        {
            "id": "3e7ffac68b53addba6897b6426f5525db85be790de77228ad763ee218311ca0d",
            "conversationId": "3e7ffac68b53addba6897b6426f5525db85be790de77228ad763ee218311ca0d",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T13:51:59.046Z",
            "endTime": "2021-11-16T13:53:44.002Z",
            "timeSpent": 105,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiLAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiLAwAAAAAAAA==/",
            "_etag": "\"0c0073fa-0000-0100-0000-6193c7760000\"",
            "_attachments": "attachments/",
            "_ts": 1637074806
        },
        {
            "id": "f64b1f786d19e80952fcce7eafc2ca3809d0d2b61f335a131919110e055345d1",
            "conversationId": "f64b1f786d19e80952fcce7eafc2ca3809d0d2b61f335a131919110e055345d1",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [
                "Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-16T14:03:57.187Z",
            "endTime": "2021-11-16T14:15:21.487Z",
            "timeSpent": 684,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiMAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiMAwAAAAAAAA==/",
            "_etag": "\"0d002132-0000-0100-0000-6193d5860000\"",
            "_attachments": "attachments/",
            "_ts": 1637078406
        },
        {
            "id": "fc50431e81e1843c145724dfad0f829d3b9fead2105601fa6ae6ae1b0f41d915",
            "conversationId": "fc50431e81e1843c145724dfad0f829d3b9fead2105601fa6ae6ae1b0f41d915",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 25,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Injury"
            ],
            "riskFactors": [
                "Obesity",
                "Recent physical injury"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to Urgent Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2021-11-16T14:15:46.581Z",
            "endTime": "2021-11-16T14:16:53.952Z",
            "timeSpent": 67,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiNAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiNAwAAAAAAAA==/",
            "_etag": "\"0d002232-0000-0100-0000-6193d5860000\"",
            "_attachments": "attachments/",
            "_ts": 1637078406
        },
        {
            "id": "5e585b38254f26fa302ab95683b6f88c7cb4c6040556dcbcdc8f83ca6840de98",
            "conversationId": "5e585b38254f26fa302ab95683b6f88c7cb4c6040556dcbcdc8f83ca6840de98",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 50,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Injury",
                "Knee pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-11-16T14:17:03.011Z",
            "endTime": "2021-11-16T14:23:08.766Z",
            "timeSpent": 366,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiOAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiOAwAAAAAAAA==/",
            "_etag": "\"0d002432-0000-0100-0000-6193d5860000\"",
            "_attachments": "attachments/",
            "_ts": 1637078406
        },
        {
            "id": "c1f506523e0918fc4456cfc7a2a0d0eee71622affc3b6cb9b35c39387ed5c674",
            "conversationId": "c1f506523e0918fc4456cfc7a2a0d0eee71622affc3b6cb9b35c39387ed5c674",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Injury",
                "Knee pain"
            ],
            "riskFactors": [
                "Obesity",
                "Hypertension",
                "Recent physical injury"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to Urgent Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-11-16T14:25:16.449Z",
            "endTime": "2021-11-16T14:29:36.021Z",
            "timeSpent": 260,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiPAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiPAwAAAAAAAA==/",
            "_etag": "\"0d002532-0000-0100-0000-6193d5860000\"",
            "_attachments": "attachments/",
            "_ts": 1637078406
        },
        {
            "id": "2ea6ba35c280d6d8306a2b187b98cc68d13a5ef026a6014a2dfd8ad51f91164f",
            "conversationId": "2ea6ba35c280d6d8306a2b187b98cc68d13a5ef026a6014a2dfd8ad51f91164f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T14:52:36.609Z",
            "endTime": "2021-11-16T15:02:28.701Z",
            "timeSpent": 592,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiQAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiQAwAAAAAAAA==/",
            "_etag": "\"0d006353-0000-0100-0000-6193e3920000\"",
            "_attachments": "attachments/",
            "_ts": 1637082002
        },
        {
            "id": "439c4a4ab5382a500d10f6a3f7a84970f1e6ebeab572033b5dc6d0c634d87d68",
            "conversationId": "439c4a4ab5382a500d10f6a3f7a84970f1e6ebeab572033b5dc6d0c634d87d68",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T15:06:05.953Z",
            "endTime": "2021-11-16T15:06:05.953Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiRAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiRAwAAAAAAAA==/",
            "_etag": "\"0d006a53-0000-0100-0000-6193e3920000\"",
            "_attachments": "attachments/",
            "_ts": 1637082002
        },
        {
            "id": "1cb345026932a05d3b5e40734cf6d998ee7e8fe03a5fe198d16ab3b3ba01522a",
            "conversationId": "1cb345026932a05d3b5e40734cf6d998ee7e8fe03a5fe198d16ab3b3ba01522a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T15:12:31.479Z",
            "endTime": "2021-11-16T15:12:31.479Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiSAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiSAwAAAAAAAA==/",
            "_etag": "\"0d006d53-0000-0100-0000-6193e3920000\"",
            "_attachments": "attachments/",
            "_ts": 1637082002
        },
        {
            "id": "9300875ccee817636ae3ddacd782e190688756afd466fd2860ddcabb4701d4e7",
            "conversationId": "9300875ccee817636ae3ddacd782e190688756afd466fd2860ddcabb4701d4e7",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T15:12:35.682Z",
            "endTime": "2021-11-16T15:12:35.682Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiTAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiTAwAAAAAAAA==/",
            "_etag": "\"0d006e53-0000-0100-0000-6193e3920000\"",
            "_attachments": "attachments/",
            "_ts": 1637082002
        },
        {
            "id": "db87ee902f8fe33b7268d99c6ff69451b9af6563b640dd29b9ca52bb7cceb11a",
            "conversationId": "db87ee902f8fe33b7268d99c6ff69451b9af6563b640dd29b9ca52bb7cceb11a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T15:12:20.295Z",
            "endTime": "2021-11-16T15:12:20.295Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiUAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiUAwAAAAAAAA==/",
            "_etag": "\"0d006f53-0000-0100-0000-6193e3920000\"",
            "_attachments": "attachments/",
            "_ts": 1637082002
        },
        {
            "id": "8e512c7a5524891da57372e219b4cd77a013841807b1913bcf589609cf4b7062",
            "conversationId": "8e512c7a5524891da57372e219b4cd77a013841807b1913bcf589609cf4b7062",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T15:13:25.672Z",
            "endTime": "2021-11-16T15:13:25.672Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiVAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiVAwAAAAAAAA==/",
            "_etag": "\"0d007053-0000-0100-0000-6193e3920000\"",
            "_attachments": "attachments/",
            "_ts": 1637082002
        },
        {
            "id": "8e231065bf7a280b80fc3172328f5a22ca2b8a4689d4e4726df44143a198e228",
            "conversationId": "8e231065bf7a280b80fc3172328f5a22ca2b8a4689d4e4726df44143a198e228",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T15:14:03.893Z",
            "endTime": "2021-11-16T15:14:03.893Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiWAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiWAwAAAAAAAA==/",
            "_etag": "\"0d007153-0000-0100-0000-6193e3920000\"",
            "_attachments": "attachments/",
            "_ts": 1637082002
        },
        {
            "id": "19899970f7ef4fe6f1023db56356f085fa087d185785dc1072de9020b993bef0",
            "conversationId": "19899970f7ef4fe6f1023db56356f085fa087d185785dc1072de9020b993bef0",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T15:14:30.45Z",
            "endTime": "2021-11-16T15:14:30.45Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiXAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiXAwAAAAAAAA==/",
            "_etag": "\"0d007253-0000-0100-0000-6193e3920000\"",
            "_attachments": "attachments/",
            "_ts": 1637082002
},
    
        {
            "id": "47300e246f2eeb0c8413f9a0d876291ba7e1da8fc7094286683feee7d642ca81",
            "conversationId": "47300e246f2eeb0c8413f9a0d876291ba7e1da8fc7094286683feee7d642ca81",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T15:15:17.802Z",
            "endTime": "2021-11-16T15:15:17.802Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiYAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiYAwAAAAAAAA==/",
            "_etag": "\"0d007353-0000-0100-0000-6193e3920000\"",
            "_attachments": "attachments/",
            "_ts": 1637082002
        },
        {
            "id": "5f3a81f94c6f69d79ad4c9f3776ddc9e91a153f774fff93641992aabedfd406d",
            "conversationId": "5f3a81f94c6f69d79ad4c9f3776ddc9e91a153f774fff93641992aabedfd406d",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T15:45:29.451Z",
            "endTime": "2021-11-16T15:45:29.451Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiZAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiZAwAAAAAAAA==/",
            "_etag": "\"0d00966d-0000-0100-0000-6193f1a50000\"",
            "_attachments": "attachments/",
            "_ts": 1637085605
        },
        {
            "id": "176beb83a86ed4e63bb83ea3695d07496c8a9436d09f4d751aeaa047c1402b33",
            "conversationId": "176beb83a86ed4e63bb83ea3695d07496c8a9436d09f4d751aeaa047c1402b33",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T15:45:27.752Z",
            "endTime": "2021-11-16T15:45:27.752Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiaAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiaAwAAAAAAAA==/",
            "_etag": "\"0d00976d-0000-0100-0000-6193f1a50000\"",
            "_attachments": "attachments/",
            "_ts": 1637085605
        },
        {
            "id": "081b85786b478fbdc7fb2813ba202bc99f6ec25cacca471735fc7f511607b101",
            "conversationId": "081b85786b478fbdc7fb2813ba202bc99f6ec25cacca471735fc7f511607b101",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T15:08:17.16Z",
            "endTime": "2021-11-16T15:08:17.16Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIibAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIibAwAAAAAAAA==/",
            "_etag": "\"0d005b53-0000-0100-0000-6193e3920000\"",
            "_attachments": "attachments/",
            "_ts": 1637082002
        },
        {
            "id": "94650f60b378aa16497ad68552cb74828eefdc85ba0301c7ef32fcce533bf0d2",
            "conversationId": "94650f60b378aa16497ad68552cb74828eefdc85ba0301c7ef32fcce533bf0d2",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T15:08:49.135Z",
            "endTime": "2021-11-16T15:08:49.135Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIicAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIicAwAAAAAAAA==/",
            "_etag": "\"0d005c53-0000-0100-0000-6193e3920000\"",
            "_attachments": "attachments/",
            "_ts": 1637082002
        },
        {
            "id": "7e4640d61a13d6860f76068de6685d9d4a9c5eaccaf5b326744f9d382255f11f",
            "conversationId": "7e4640d61a13d6860f76068de6685d9d4a9c5eaccaf5b326744f9d382255f11f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T15:26:03.095Z",
            "endTime": "2021-11-16T15:26:09.606Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIidAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIidAwAAAAAAAA==/",
            "_etag": "\"0d005d53-0000-0100-0000-6193e3920000\"",
            "_attachments": "attachments/",
            "_ts": 1637082002
        },
        {
            "id": "ec81dea4bec40c7b1946a753077d3d51abea2e4cfc609a7365d8e37601554b81",
            "conversationId": "ec81dea4bec40c7b1946a753077d3d51abea2e4cfc609a7365d8e37601554b81",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T15:26:22.873Z",
            "endTime": "2021-11-16T15:26:22.873Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIieAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIieAwAAAAAAAA==/",
            "_etag": "\"0d005e53-0000-0100-0000-6193e3920000\"",
            "_attachments": "attachments/",
            "_ts": 1637082002
        },
        {
            "id": "0bb86e7637beac8d1c0bf0fa2f45a5b79150d49b0c685260984349947333d8d9",
            "conversationId": "0bb86e7637beac8d1c0bf0fa2f45a5b79150d49b0c685260984349947333d8d9",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T15:26:33.917Z",
            "endTime": "2021-11-16T15:26:33.917Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIifAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIifAwAAAAAAAA==/",
            "_etag": "\"0d005f53-0000-0100-0000-6193e3920000\"",
            "_attachments": "attachments/",
            "_ts": 1637082002
        },
        {
            "id": "8b41da3edab2d235e3a92920b3d4b42c36557a29956dca2dfd2300dd7de69ec3",
            "conversationId": "8b41da3edab2d235e3a92920b3d4b42c36557a29956dca2dfd2300dd7de69ec3",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T15:28:46.04Z",
            "endTime": "2021-11-16T15:28:46.04Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIigAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIigAwAAAAAAAA==/",
            "_etag": "\"0d006053-0000-0100-0000-6193e3920000\"",
            "_attachments": "attachments/",
            "_ts": 1637082002
        },
        {
            "id": "cc356f49a5d915468fcdd63baed7e77a9847c380699dd24841b1b7e67647c102",
            "conversationId": "cc356f49a5d915468fcdd63baed7e77a9847c380699dd24841b1b7e67647c102",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T15:54:12.167Z",
            "endTime": "2021-11-16T15:54:12.167Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIihAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIihAwAAAAAAAA==/",
            "_etag": "\"0d00986d-0000-0100-0000-6193f1a50000\"",
            "_attachments": "attachments/",
            "_ts": 1637085605
        },
        {
            "id": "87f3c2476e57034c0e3de4448c9400c7ddbffc26c903aa7898b7cc9e0a367818",
            "conversationId": "87f3c2476e57034c0e3de4448c9400c7ddbffc26c903aa7898b7cc9e0a367818",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T15:55:44.841Z",
            "endTime": "2021-11-16T15:55:44.841Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiiAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiiAwAAAAAAAA==/",
            "_etag": "\"0d00996d-0000-0100-0000-6193f1a50000\"",
            "_attachments": "attachments/",
            "_ts": 1637085605
        },
        {
            "id": "4392b034c0eaca4669749404806188118f3c267de2084d3fbe777a9ea390468c",
            "conversationId": "4392b034c0eaca4669749404806188118f3c267de2084d3fbe777a9ea390468c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T15:59:07.635Z",
            "endTime": "2021-11-16T15:59:07.635Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIijAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIijAwAAAAAAAA==/",
            "_etag": "\"0d00a46d-0000-0100-0000-6193f1a50000\"",
            "_attachments": "attachments/",
            "_ts": 1637085605
        },
        {
            "id": "6541e92d782389a42508836c5afce9130d5524e898613e7d47dad792305a5993",
            "conversationId": "6541e92d782389a42508836c5afce9130d5524e898613e7d47dad792305a5993",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T14:59:10.722Z",
            "endTime": "2021-11-16T14:59:10.722Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIikAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIikAwAAAAAAAA==/",
            "_etag": "\"0d006153-0000-0100-0000-6193e3920000\"",
            "_attachments": "attachments/",
            "_ts": 1637082002
        },
        {
            "id": "268dfed4ecaac4dd3bdfd2f4062cfe16037392d91538c7d47b87a24a2a00f274",
            "conversationId": "268dfed4ecaac4dd3bdfd2f4062cfe16037392d91538c7d47b87a24a2a00f274",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T15:01:46.815Z",
            "endTime": "2021-11-16T15:01:46.815Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIilAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIilAwAAAAAAAA==/",
            "_etag": "\"0d006253-0000-0100-0000-6193e3920000\"",
            "_attachments": "attachments/",
            "_ts": 1637082002
        },
        {
            "id": "e103d004a7e0ab040981bfaa7024dbd9bbc01a9b6ef11d2994d65e26f354b198",
            "conversationId": "e103d004a7e0ab040981bfaa7024dbd9bbc01a9b6ef11d2994d65e26f354b198",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T15:18:14.687Z",
            "endTime": "2021-11-16T15:18:14.687Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIimAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIimAwAAAAAAAA==/",
            "_etag": "\"0d006953-0000-0100-0000-6193e3920000\"",
            "_attachments": "attachments/",
            "_ts": 1637082002
        },
        {
            "id": "388c796baa454b2ac954293b0191b17a43980a97adb058ac360c70b358a29be8",
            "conversationId": "388c796baa454b2ac954293b0191b17a43980a97adb058ac360c70b358a29be8",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 33,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-11-16T16:27:29.782Z",
            "endTime": "2021-11-16T16:28:32.712Z",
            "timeSpent": 63,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIinAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIinAwAAAAAAAA==/",
            "_etag": "\"0d00926d-0000-0100-0000-6193f1a50000\"",
            "_attachments": "attachments/",
            "_ts": 1637085605
        },
        {
            "id": "52467b91b8c719c2759fa9e0b81bb7ee11405d941353b8a4dd1b22fbb8487402",
            "conversationId": "52467b91b8c719c2759fa9e0b81bb7ee11405d941353b8a4dd1b22fbb8487402",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T16:29:09.375Z",
            "endTime": "2021-11-16T16:33:26.183Z",
            "timeSpent": 257,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIioAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIioAwAAAAAAAA==/",
            "_etag": "\"0d006889-0000-0100-0000-6193ffb50000\"",
            "_attachments": "attachments/",
            "_ts": 1637089205
        },
        {
            "id": "b16b7b026d7a4886b738f0dc0e05fa9e6be58a20ed29468488d05d44b752c23b",
            "conversationId": "b16b7b026d7a4886b738f0dc0e05fa9e6be58a20ed29468488d05d44b752c23b",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T16:05:07.479Z",
            "endTime": "2021-11-16T16:05:07.479Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIipAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIipAwAAAAAAAA==/",
            "_etag": "\"0d008f6d-0000-0100-0000-6193f1a50000\"",
            "_attachments": "attachments/",
            "_ts": 1637085605
        },
        {
            "id": "25cf09b4b4c2bfc56b21d9541b43e79d5885a3373f91f84a5365150b7462d365",
            "conversationId": "25cf09b4b4c2bfc56b21d9541b43e79d5885a3373f91f84a5365150b7462d365",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 44,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-11-16T16:41:40.269Z",
            "endTime": "2021-11-16T16:43:43.644Z",
            "timeSpent": 123,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiqAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiqAwAAAAAAAA==/",
            "_etag": "\"0d006a89-0000-0100-0000-6193ffb50000\"",
            "_attachments": "attachments/",
            "_ts": 1637089205
        },
        {
            "id": "7f10a6e898efd82b1b5ea5d6edc761f008f12d68283d73dcef644a8a1c49f8e1",
            "conversationId": "7f10a6e898efd82b1b5ea5d6edc761f008f12d68283d73dcef644a8a1c49f8e1",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T16:48:30.302Z",
            "endTime": "2021-11-16T16:48:30.302Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIirAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIirAwAAAAAAAA==/",
            "_etag": "\"0d006b89-0000-0100-0000-6193ffb50000\"",
            "_attachments": "attachments/",
            "_ts": 1637089205
        },
        {
            "id": "60af7e268d1d3e4a2da39aa186cbaeb17e6aab2b0949476f2226510a6d9b4d6a",
            "conversationId": "60af7e268d1d3e4a2da39aa186cbaeb17e6aab2b0949476f2226510a6d9b4d6a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T16:49:38.037Z",
            "endTime": "2021-11-16T16:49:38.037Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIisAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIisAwAAAAAAAA==/",
            "_etag": "\"0d006c89-0000-0100-0000-6193ffb50000\"",
            "_attachments": "attachments/",
            "_ts": 1637089205
        },
        {
            "id": "3c1ed8f86c713f561a891934510a445c336d1efa20559a2e2a1c69ad7e3f967e",
            "conversationId": "3c1ed8f86c713f561a891934510a445c336d1efa20559a2e2a1c69ad7e3f967e",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 44,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-11-16T16:49:59.198Z",
            "endTime": "2021-11-16T16:50:48.751Z",
            "timeSpent": 50,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIitAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIitAwAAAAAAAA==/",
            "_etag": "\"0d004d89-0000-0100-0000-6193ffb50000\"",
            "_attachments": "attachments/",
            "_ts": 1637089205
        },
        {
            "id": "4deb5efaa55ab28978151238f44e16428dbcd40b2044b486efb966caa822efd5",
            "conversationId": "4deb5efaa55ab28978151238f44e16428dbcd40b2044b486efb966caa822efd5",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T16:57:28.945Z",
            "endTime": "2021-11-16T16:57:56.669Z",
            "timeSpent": 28,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiuAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiuAwAAAAAAAA==/",
            "_etag": "\"0d005289-0000-0100-0000-6193ffb50000\"",
            "_attachments": "attachments/",
            "_ts": 1637089205
        },
        {
            "id": "a4e89b42e5d43790b3a9be21236cf00bbc90d8b4b45cedf3ea90ab4b995052ce",
            "conversationId": "a4e89b42e5d43790b3a9be21236cf00bbc90d8b4b45cedf3ea90ab4b995052ce",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T17:22:53.397Z",
            "endTime": "2021-11-16T17:22:53.397Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIivAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIivAwAAAAAAAA==/",
            "_etag": "\"0d005789-0000-0100-0000-6193ffb50000\"",
            "_attachments": "attachments/",
            "_ts": 1637089205
        },
        {
            "id": "2671158d4bb329219310d0f64c2c43af20ee1b62eae5d2290a70b519e4ad813b",
            "conversationId": "2671158d4bb329219310d0f64c2c43af20ee1b62eae5d2290a70b519e4ad813b",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Fever"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-16T17:35:27.504Z",
            "endTime": "2021-11-16T17:37:18.561Z",
            "timeSpent": 111,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiwAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiwAwAAAAAAAA==/",
            "_etag": "\"0d008db1-0000-0100-0000-61940dc60000\"",
            "_attachments": "attachments/",
            "_ts": 1637092806
        },
        {
            "id": "46fe167a800958545ff2e75f2049d72544ec8fe6eb06d9fdefd1e71adf3c079d",
            "conversationId": "46fe167a800958545ff2e75f2049d72544ec8fe6eb06d9fdefd1e71adf3c079d",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T17:37:36.011Z",
            "endTime": "2021-11-16T17:38:19.517Z",
            "timeSpent": 44,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIixAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIixAwAAAAAAAA==/",
            "_etag": "\"0d008eb1-0000-0100-0000-61940dc60000\"",
            "_attachments": "attachments/",
            "_ts": 1637092806
        },
        {
            "id": "0f9f5d09b2da40797729ac5ef5340bd463a5698d6b92c701decbba31658282dc",
            "conversationId": "0f9f5d09b2da40797729ac5ef5340bd463a5698d6b92c701decbba31658282dc",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T17:09:50.091Z",
            "endTime": "2021-11-16T17:10:54.012Z",
            "timeSpent": 64,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiyAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiyAwAAAAAAAA==/",
            "_etag": "\"0d004f89-0000-0100-0000-6193ffb50000\"",
            "_attachments": "attachments/",
            "_ts": 1637089205
        },
        {
            "id": "a381fcd6419d2d429af9ca147f850a8fcf62afb8617b6d4af6d0edb73a22ce08",
            "conversationId": "a381fcd6419d2d429af9ca147f850a8fcf62afb8617b6d4af6d0edb73a22ce08",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T17:26:41.847Z",
            "endTime": "2021-11-16T17:26:41.847Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIizAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIizAwAAAAAAAA==/",
            "_etag": "\"0d005089-0000-0100-0000-6193ffb50000\"",
            "_attachments": "attachments/",
            "_ts": 1637089205
        },
        {
            "id": "b2b60ef1ab0ec2fb9431f7efd8a579a3a20ea6367ed8f68819d6687aa8cdc19c",
            "conversationId": "b2b60ef1ab0ec2fb9431f7efd8a579a3a20ea6367ed8f68819d6687aa8cdc19c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T17:27:32.648Z",
            "endTime": "2021-11-16T17:27:32.648Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi0AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi0AwAAAAAAAA==/",
            "_etag": "\"0d005189-0000-0100-0000-6193ffb50000\"",
            "_attachments": "attachments/",
            "_ts": 1637089205
        },
        {
            "id": "f77e1b6ad3f98a9d131e62f513d70dbe0c55e2934adfc47beec9ee9812df997c",
            "conversationId": "f77e1b6ad3f98a9d131e62f513d70dbe0c55e2934adfc47beec9ee9812df997c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T17:28:22.99Z",
            "endTime": "2021-11-16T17:28:22.99Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi1AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi1AwAAAAAAAA==/",
            "_etag": "\"0d005389-0000-0100-0000-6193ffb50000\"",
            "_attachments": "attachments/",
            "_ts": 1637089205
        },
        {
            "id": "58c4ea9d3b8c55f0b1ecd92faa2779328d7face3914fdb697f63418997687214",
            "conversationId": "58c4ea9d3b8c55f0b1ecd92faa2779328d7face3914fdb697f63418997687214",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T17:29:06.523Z",
            "endTime": "2021-11-16T17:29:06.523Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi2AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi2AwAAAAAAAA==/",
            "_etag": "\"0d005489-0000-0100-0000-6193ffb50000\"",
            "_attachments": "attachments/",
            "_ts": 1637089205
        },
        {
            "id": "c4ae435bdbb1bc5070d21b92cd757a2430e0c9f1e6b7051c0b83326de6fdd044",
            "conversationId": "c4ae435bdbb1bc5070d21b92cd757a2430e0c9f1e6b7051c0b83326de6fdd044",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 33,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T17:29:30.192Z",
            "endTime": "2021-11-16T17:30:43.186Z",
            "timeSpent": 73,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi3AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi3AwAAAAAAAA==/",
            "_etag": "\"0d0093b1-0000-0100-0000-61940dc60000\"",
            "_attachments": "attachments/",
            "_ts": 1637092806
        },
        {
            "id": "072dea6a588d8534c555353cb468bba74bb93c276db85d3b52aa2b60d3204361",
            "conversationId": "072dea6a588d8534c555353cb468bba74bb93c276db85d3b52aa2b60d3204361",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T17:09:24.256Z",
            "endTime": "2021-11-16T17:09:28.07Z",
            "timeSpent": 4,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi4AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi4AwAAAAAAAA==/",
            "_etag": "\"0d006089-0000-0100-0000-6193ffb50000\"",
            "_attachments": "attachments/",
            "_ts": 1637089205
        },
        {
            "id": "3e034543dfc15b5755384aa9c2f4a1facec449b4a1f1b3649b36bc7bf237b37c",
            "conversationId": "3e034543dfc15b5755384aa9c2f4a1facec449b4a1f1b3649b36bc7bf237b37c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T17:09:46.779Z",
            "endTime": "2021-11-16T17:09:46.779Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi5AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi5AwAAAAAAAA==/",
            "_etag": "\"0d006189-0000-0100-0000-6193ffb50000\"",
            "_attachments": "attachments/",
            "_ts": 1637089205
        },
        {
            "id": "18a47523527d52603a228597ed387f7ece2ba49a0356f39ece453685e6050850",
            "conversationId": "18a47523527d52603a228597ed387f7ece2ba49a0356f39ece453685e6050850",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T17:12:46.625Z",
            "endTime": "2021-11-16T17:12:46.625Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi6AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi6AwAAAAAAAA==/",
            "_etag": "\"0d006289-0000-0100-0000-6193ffb50000\"",
            "_attachments": "attachments/",
            "_ts": 1637089205
        },
        {
            "id": "900e36e9af585ea31f24544f5eb744a53cab390dbe794e854ea694abf53423b1",
            "conversationId": "900e36e9af585ea31f24544f5eb744a53cab390dbe794e854ea694abf53423b1",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T17:12:55.382Z",
            "endTime": "2021-11-16T17:13:16.536Z",
            "timeSpent": 21,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi7AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi7AwAAAAAAAA==/",
            "_etag": "\"0d006989-0000-0100-0000-6193ffb50000\"",
            "_attachments": "attachments/",
            "_ts": 1637089205
        },
        {
            "id": "e413de1bebb5e614e9e1351db89dec9f57489d964192e8968f1b23bf563a1661",
            "conversationId": "e413de1bebb5e614e9e1351db89dec9f57489d964192e8968f1b23bf563a1661",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T17:43:35.776Z",
            "endTime": "2021-11-16T17:43:44.597Z",
            "timeSpent": 9,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi8AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi8AwAAAAAAAA==/",
            "_etag": "\"0d008fb1-0000-0100-0000-61940dc60000\"",
            "_attachments": "attachments/",
            "_ts": 1637092806
        },
        {
            "id": "288588608bd9a8ca7437e3b489b22355c2ac6bf7912ec7e32baa4eb8c14ef29e",
            "conversationId": "288588608bd9a8ca7437e3b489b22355c2ac6bf7912ec7e32baa4eb8c14ef29e",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T18:23:19.095Z",
            "endTime": "2021-11-16T18:23:19.095Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi9AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi9AwAAAAAAAA==/",
            "_etag": "\"0d0080b1-0000-0100-0000-61940dc60000\"",
            "_attachments": "attachments/",
            "_ts": 1637092806
        },
        {
            "id": "a0069285db03ed8eea64f43756ae14fea3d3b85ba3b0458dfa3790b9b9079490",
            "conversationId": "a0069285db03ed8eea64f43756ae14fea3d3b85ba3b0458dfa3790b9b9079490",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T18:44:57.426Z",
            "endTime": "2021-11-16T18:44:57.426Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi+AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi+AwAAAAAAAA==/",
            "_etag": "\"0d00cdc8-0000-0100-0000-61941bd50000\"",
            "_attachments": "attachments/",
            "_ts": 1637096405
        },
        {
            "id": "d9036417b221ebb325ae4b754307d4a60d79d0d00575dc3018837ff4b81e43d0",
            "conversationId": "d9036417b221ebb325ae4b754307d4a60d79d0d00575dc3018837ff4b81e43d0",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T18:20:24.363Z",
            "endTime": "2021-11-16T18:20:24.363Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi-AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi-AwAAAAAAAA==/",
            "_etag": "\"0d0081b1-0000-0100-0000-61940dc60000\"",
            "_attachments": "attachments/",
            "_ts": 1637092806
        },
        {
            "id": "5f51306a83d7fd72f551a1850829d59405427699fc4f66b9ed59e39b299d9eba",
            "conversationId": "5f51306a83d7fd72f551a1850829d59405427699fc4f66b9ed59e39b299d9eba",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T18:21:05.349Z",
            "endTime": "2021-11-16T18:21:36.118Z",
            "timeSpent": 31,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjAAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjAAwAAAAAAAA==/",
            "_etag": "\"0d0082b1-0000-0100-0000-61940dc60000\"",
            "_attachments": "attachments/",
            "_ts": 1637092806
        },
        {
            "id": "c4b38d4a0c85bf6b901efa6bcc0a11c9fcdec8a0c2fc76cf83e2bcb86a14eaa4",
            "conversationId": "c4b38d4a0c85bf6b901efa6bcc0a11c9fcdec8a0c2fc76cf83e2bcb86a14eaa4",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T18:21:45.676Z",
            "endTime": "2021-11-16T18:23:19.337Z",
            "timeSpent": 94,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjBAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjBAwAAAAAAAA==/",
            "_etag": "\"0d0083b1-0000-0100-0000-61940dc60000\"",
            "_attachments": "attachments/",
            "_ts": 1637092806
        },
        {
            "id": "3c591ae9a5c5105a36fbf72f732be4e1ad36df150a8c82d9c37058363a2e8895",
            "conversationId": "3c591ae9a5c5105a36fbf72f732be4e1ad36df150a8c82d9c37058363a2e8895",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T18:45:09.637Z",
            "endTime": "2021-11-16T18:45:09.637Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjCAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjCAwAAAAAAAA==/",
            "_etag": "\"0d00ccc8-0000-0100-0000-61941bd50000\"",
            "_attachments": "attachments/",
            "_ts": 1637096405
        },
        {
            "id": "2c26391bf240c54cc3524ecf7b4b38d779777f8f269da48b7a83a5f770c5fe8e",
            "conversationId": "2c26391bf240c54cc3524ecf7b4b38d779777f8f269da48b7a83a5f770c5fe8e",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T18:45:51.303Z",
            "endTime": "2021-11-16T18:45:51.303Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjDAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjDAwAAAAAAAA==/",
            "_etag": "\"0d00cfc8-0000-0100-0000-61941bd50000\"",
            "_attachments": "attachments/",
            "_ts": 1637096405
        },
        {
            "id": "157127316bfacb20a4cbc99a9ae2e8387f553b99e26cbc544e7c8f2c820b7aa7",
            "conversationId": "157127316bfacb20a4cbc99a9ae2e8387f553b99e26cbc544e7c8f2c820b7aa7",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T18:45:55.501Z",
            "endTime": "2021-11-16T18:45:59.797Z",
            "timeSpent": 4,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjEAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjEAwAAAAAAAA==/",
            "_etag": "\"0d00cec8-0000-0100-0000-61941bd50000\"",
            "_attachments": "attachments/",
            "_ts": 1637096405
        },
        {
            "id": "17af9f00a050a1c44af6c63564abc338854e7ac32c9cdafec365c1f910923acb",
            "conversationId": "17af9f00a050a1c44af6c63564abc338854e7ac32c9cdafec365c1f910923acb",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T18:20:16.797Z",
            "endTime": "2021-11-16T18:20:16.797Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjFAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjFAwAAAAAAAA==/",
            "_etag": "\"0d0089b1-0000-0100-0000-61940dc60000\"",
            "_attachments": "attachments/",
            "_ts": 1637092806
        },
        {
            "id": "13b145e06fedae2e86649f52debd2e9b32d8fd03e65a1f2f4ffa418721133eb3",
            "conversationId": "13b145e06fedae2e86649f52debd2e9b32d8fd03e65a1f2f4ffa418721133eb3",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 43,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-16T18:22:34.477Z",
            "endTime": "2021-11-16T18:24:19.952Z",
            "timeSpent": 105,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjGAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjGAwAAAAAAAA==/",
            "_etag": "\"0d008ab1-0000-0100-0000-61940dc60000\"",
            "_attachments": "attachments/",
            "_ts": 1637092806
        },
        {
            "id": "b826d9e433c4aa83fae3fe575839561680bc8c0e5c3e5664b7d482cb85adc778",
            "conversationId": "b826d9e433c4aa83fae3fe575839561680bc8c0e5c3e5664b7d482cb85adc778",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T18:33:00.182Z",
            "endTime": "2021-11-16T18:33:06.448Z",
            "timeSpent": 6,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjHAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjHAwAAAAAAAA==/",
            "_etag": "\"0d00dac8-0000-0100-0000-61941bd50000\"",
            "_attachments": "attachments/",
            "_ts": 1637096405
        },
        {
            "id": "460ff2324aad8bd8ad8b8356cbcd5ef8887d2dd5615d25a43d8350d01b748bbd",
            "conversationId": "460ff2324aad8bd8ad8b8356cbcd5ef8887d2dd5615d25a43d8350d01b748bbd",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T18:32:45.187Z",
            "endTime": "2021-11-16T18:32:53.027Z",
            "timeSpent": 8,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjIAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjIAwAAAAAAAA==/",
            "_etag": "\"0d008bb1-0000-0100-0000-61940dc60000\"",
            "_attachments": "attachments/",
            "_ts": 1637092806
        },
        {
            "id": "9ffe9e9bd95dcf9a3d546bdaa30e890916297b78e9b02d01417d1cc8a78928e1",
            "conversationId": "9ffe9e9bd95dcf9a3d546bdaa30e890916297b78e9b02d01417d1cc8a78928e1",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T19:22:44.317Z",
            "endTime": "2021-11-16T19:22:44.317Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjJAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjJAwAAAAAAAA==/",
            "_etag": "\"0d00d2c8-0000-0100-0000-61941bd50000\"",
            "_attachments": "attachments/",
            "_ts": 1637096405
        },
        {
            "id": "55a9fe4d5f57b9a1c6d786cc87655f353b971076941c390bd39f9608b3c602f5",
            "conversationId": "55a9fe4d5f57b9a1c6d786cc87655f353b971076941c390bd39f9608b3c602f5",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T19:33:03.904Z",
            "endTime": "2021-11-16T19:33:03.904Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjKAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjKAwAAAAAAAA==/",
            "_etag": "\"0d00fce5-0000-0100-0000-619429e40000\"",
            "_attachments": "attachments/",
            "_ts": 1637100004
        },
        {
            "id": "b7a4a1443cbb8946730f1634cbfcd8f80bf0bb61031dc9f3df79a08d62486641",
            "conversationId": "b7a4a1443cbb8946730f1634cbfcd8f80bf0bb61031dc9f3df79a08d62486641",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T19:54:28.601Z",
            "endTime": "2021-11-16T19:54:28.601Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjLAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjLAwAAAAAAAA==/",
            "_etag": "\"0d00f7e5-0000-0100-0000-619429e40000\"",
            "_attachments": "attachments/",
            "_ts": 1637100004
        },
        {
            "id": "eb82f8d8879f0ccd6b0a09f3f6f8db310491dec6fde61727dcc920617f74068a",
            "conversationId": "eb82f8d8879f0ccd6b0a09f3f6f8db310491dec6fde61727dcc920617f74068a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 35,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T19:57:08.643Z",
            "endTime": "2021-11-16T19:57:26.389Z",
            "timeSpent": 18,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjMAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjMAwAAAAAAAA==/",
            "_etag": "\"0d00f2e5-0000-0100-0000-619429e40000\"",
            "_attachments": "attachments/",
            "_ts": 1637100004
        },
        {
            "id": "2fc5b36fdf2caccff161db562070b1a3c24f10b1ad4cced83677b2dca65e0bbb",
            "conversationId": "2fc5b36fdf2caccff161db562070b1a3c24f10b1ad4cced83677b2dca65e0bbb",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T19:01:21.597Z",
            "endTime": "2021-11-16T19:01:21.597Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjNAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjNAwAAAAAAAA==/",
            "_etag": "\"0d00cbc8-0000-0100-0000-61941bd50000\"",
            "_attachments": "attachments/",
            "_ts": 1637096405
        },
        {
            "id": "ed91192c000b9709ec509d2be3114c5545aa8e00eb3305e67bbf200e21079555",
            "conversationId": "ed91192c000b9709ec509d2be3114c5545aa8e00eb3305e67bbf200e21079555",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T19:16:04.642Z",
            "endTime": "2021-11-16T19:16:04.642Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjOAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjOAwAAAAAAAA==/",
            "_etag": "\"0d00d0c8-0000-0100-0000-61941bd50000\"",
            "_attachments": "attachments/",
            "_ts": 1637096405
        },
        {
            "id": "e17d18409fcd91907afefc7bddb00d079d5af46683cc721d842388a2743a5644",
            "conversationId": "e17d18409fcd91907afefc7bddb00d079d5af46683cc721d842388a2743a5644",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [
                "Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-16T19:48:31.862Z",
            "endTime": "2021-11-16T19:49:37.168Z",
            "timeSpent": 65,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjPAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjPAwAAAAAAAA==/",
            "_etag": "\"0d00f4e5-0000-0100-0000-619429e40000\"",
            "_attachments": "attachments/",
            "_ts": 1637100004
        },
        {
            "id": "d4167601a9c3a7a00f85dd72a79ed935c9ff30c05d74c38da9dd265714e7977b",
            "conversationId": "d4167601a9c3a7a00f85dd72a79ed935c9ff30c05d74c38da9dd265714e7977b",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T20:21:13.349Z",
            "endTime": "2021-11-16T20:21:13.349Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjQAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjQAwAAAAAAAA==/",
            "_etag": "\"0d00f5e5-0000-0100-0000-619429e40000\"",
            "_attachments": "attachments/",
            "_ts": 1637100004
        },
        {
            "id": "cc0c456e787a61196a2ac9e1681bfa3d05c1e1c78429eb0f4e99f58cce9948cb",
            "conversationId": "cc0c456e787a61196a2ac9e1681bfa3d05c1e1c78429eb0f4e99f58cce9948cb",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T20:21:15.577Z",
            "endTime": "2021-11-16T20:21:15.577Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjRAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjRAwAAAAAAAA==/",
            "_etag": "\"0d00f6e5-0000-0100-0000-619429e40000\"",
            "_attachments": "attachments/",
            "_ts": 1637100004
        },
        {
            "id": "03b8a69a1e889a6c583dafc6fecefe4a981a5ce46d3606c5fd6aa1b15bea35e2",
            "conversationId": "03b8a69a1e889a6c583dafc6fecefe4a981a5ce46d3606c5fd6aa1b15bea35e2",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T20:47:01.958Z",
            "endTime": "2021-11-16T20:47:01.958Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjSAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjSAwAAAAAAAA==/",
            "_etag": "\"0d00ddfa-0000-0100-0000-619437f10000\"",
            "_attachments": "attachments/",
            "_ts": 1637103601
        },
        {
            "id": "c80433bbdf29263608b0c549575370325fd49536271d541a04c4654033910fe4",
            "conversationId": "c80433bbdf29263608b0c549575370325fd49536271d541a04c4654033910fe4",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 32,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Yellow eyes"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T20:47:20.957Z",
            "endTime": "2021-11-16T20:47:57.21Z",
            "timeSpent": 36,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjTAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjTAwAAAAAAAA==/",
            "_etag": "\"0d00defa-0000-0100-0000-619437f10000\"",
            "_attachments": "attachments/",
            "_ts": 1637103601
        },
        {
            "id": "1c4696650f1f503a7ca2e75a03fb7b35c3a11463197da8589ef2c89f7663691b",
            "conversationId": "1c4696650f1f503a7ca2e75a03fb7b35c3a11463197da8589ef2c89f7663691b",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T21:56:40.146Z",
            "endTime": "2021-11-16T21:56:40.146Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjUAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjUAwAAAAAAAA==/",
            "_etag": "\"0e001820-0000-0100-0000-619446040000\"",
            "_attachments": "attachments/",
            "_ts": 1637107204
        },
        {
            "id": "6334d74ae0cc9589fcb896c39e3f885dcaf13b0ee7072c8d7b29aecf87d9d59f",
            "conversationId": "6334d74ae0cc9589fcb896c39e3f885dcaf13b0ee7072c8d7b29aecf87d9d59f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 37,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Measles rash"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T21:13:40.649Z",
            "endTime": "2021-11-16T21:14:23.153Z",
            "timeSpent": 43,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjVAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjVAwAAAAAAAA==/",
            "_etag": "\"0d00dcfa-0000-0100-0000-619437f10000\"",
            "_attachments": "attachments/",
            "_ts": 1637103601
        },
        {
            "id": "6586fe5f55d9b073f4d817dd08998362232f69cc4923bd4bd208e50f1bf6d3cf",
            "conversationId": "6586fe5f55d9b073f4d817dd08998362232f69cc4923bd4bd208e50f1bf6d3cf",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T21:19:08.257Z",
            "endTime": "2021-11-16T21:19:08.257Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjWAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjWAwAAAAAAAA==/",
            "_etag": "\"0d00dffa-0000-0100-0000-619437f10000\"",
            "_attachments": "attachments/",
            "_ts": 1637103601
        },
        {
            "id": "7efc12fb02e5c45477553e87d6f32c7cf28fba05e7da5a3a3a5b6dfa61ee0acb",
            "conversationId": "7efc12fb02e5c45477553e87d6f32c7cf28fba05e7da5a3a3a5b6dfa61ee0acb",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-16T21:11:01.712Z",
            "endTime": "2021-11-16T21:11:01.712Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjXAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjXAwAAAAAAAA==/",
            "_etag": "\"0d00e0fa-0000-0100-0000-619437f10000\"",
            "_attachments": "attachments/",
            "_ts": 1637103601
        },
        {
            "id": "4e84b122415689b431649f1f0f9339d5d2d6af00a52a99a9452cbacfbb27750b",
            "conversationId": "4e84b122415689b431649f1f0f9339d5d2d6af00a52a99a9452cbacfbb27750b",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 33,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-16T22:00:37.317Z",
            "endTime": "2021-11-16T22:01:36.903Z",
            "timeSpent": 60,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjYAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjYAwAAAAAAAA==/",
            "_etag": "\"0e001920-0000-0100-0000-619446040000\"",
            "_attachments": "attachments/",
            "_ts": 1637107204
        },
        {
            "id": "a241c68930287f653d05ecbcafd3f00afdf1849f63909688c0e70e91a701dafa",
            "conversationId": "a241c68930287f653d05ecbcafd3f00afdf1849f63909688c0e70e91a701dafa",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 23,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T07:04:57.157Z",
            "endTime": "2021-11-17T07:05:19.09Z",
            "timeSpent": 22,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjZAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjZAwAAAAAAAA==/",
            "_etag": "\"10009a87-0000-0100-0000-6194c4940000\"",
            "_attachments": "attachments/",
            "_ts": 1637139604
        },
        {
            "id": "281e7610b0719c3e6bf784ced08f7959fe9938f05d884fc67f6608f224e5c659",
            "conversationId": "281e7610b0719c3e6bf784ced08f7959fe9938f05d884fc67f6608f224e5c659",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T12:56:27.735Z",
            "endTime": "2021-11-17T12:56:45.951Z",
            "timeSpent": 18,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjaAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjaAwAAAAAAAA==/",
            "_etag": "\"11007822-0000-0100-0000-619518f40000\"",
            "_attachments": "attachments/",
            "_ts": 1637161204
        },
        {
            "id": "806b740bb9c2dea3093e88848a64c4e69838ae9636ac95b0aed6f77e2e4bfc84",
            "conversationId": "806b740bb9c2dea3093e88848a64c4e69838ae9636ac95b0aed6f77e2e4bfc84",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 34,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T13:39:23.045Z",
            "endTime": "2021-11-17T13:39:42.477Z",
            "timeSpent": 19,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjbAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjbAwAAAAAAAA==/",
            "_etag": "\"11003b46-0000-0100-0000-619527040000\"",
            "_attachments": "attachments/",
            "_ts": 1637164804
        },
        {
            "id": "112e74829d47cbb410465f1acbe3490d7b94952e0cf78a3eb008565c2b218d77",
            "conversationId": "112e74829d47cbb410465f1acbe3490d7b94952e0cf78a3eb008565c2b218d77",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T14:12:47.343Z",
            "endTime": "2021-11-17T14:12:47.343Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjcAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjcAwAAAAAAAA==/",
            "_etag": "\"11003846-0000-0100-0000-619527040000\"",
            "_attachments": "attachments/",
            "_ts": 1637164804
        },
        {
            "id": "63aed8224983c744f58854ca8ba3372fd7c5d8c9c16efdbbc93c63c0b7737901",
            "conversationId": "63aed8224983c744f58854ca8ba3372fd7c5d8c9c16efdbbc93c63c0b7737901",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T14:06:58.383Z",
            "endTime": "2021-11-17T14:06:58.383Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjdAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjdAwAAAAAAAA==/",
            "_etag": "\"11003946-0000-0100-0000-619527040000\"",
            "_attachments": "attachments/",
            "_ts": 1637164804
        },
        {
            "id": "2660a45156cce5789f3e4ac73b814c51e2468e00d86ccd93c490504fe8c85e72",
            "conversationId": "2660a45156cce5789f3e4ac73b814c51e2468e00d86ccd93c490504fe8c85e72",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T14:10:51.129Z",
            "endTime": "2021-11-17T14:10:51.129Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjeAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjeAwAAAAAAAA==/",
            "_etag": "\"11003c46-0000-0100-0000-619527040000\"",
            "_attachments": "attachments/",
            "_ts": 1637164804
        },
        {
            "id": "f9999e1a459dd12750e6bbcb186107904a91628d01b465f04761ed635c356125",
            "conversationId": "f9999e1a459dd12750e6bbcb186107904a91628d01b465f04761ed635c356125",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T15:46:58.944Z",
            "endTime": "2021-11-17T15:46:58.944Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjfAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjfAwAAAAAAAA==/",
            "_etag": "\"11003c89-0000-0100-0000-619543250000\"",
            "_attachments": "attachments/",
            "_ts": 1637172005
        },
        {
            "id": "791c7554811bd7e6ecee3f2b7e867616b84b48e72b92181c3c488c631fb1386b",
            "conversationId": "791c7554811bd7e6ecee3f2b7e867616b84b48e72b92181c3c488c631fb1386b",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T16:12:44.044Z",
            "endTime": "2021-11-17T16:12:44.044Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjgAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjgAwAAAAAAAA==/",
            "_etag": "\"11003089-0000-0100-0000-619543250000\"",
            "_attachments": "attachments/",
            "_ts": 1637172005
        },
        {
            "id": "dc1d69aa9340ba3f31f3289b24e186b88b705642e5ebcb3e27b92816594313cd",
            "conversationId": "dc1d69aa9340ba3f31f3289b24e186b88b705642e5ebcb3e27b92816594313cd",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T16:13:30.324Z",
            "endTime": "2021-11-17T16:13:30.324Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjhAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjhAwAAAAAAAA==/",
            "_etag": "\"11003189-0000-0100-0000-619543250000\"",
            "_attachments": "attachments/",
            "_ts": 1637172005
        },
        {
            "id": "bb010143db40d476dd0fbd0858d3a92c76c317c22081d32f1acd00608c22d642",
            "conversationId": "bb010143db40d476dd0fbd0858d3a92c76c317c22081d32f1acd00608c22d642",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T16:13:44.589Z",
            "endTime": "2021-11-17T16:13:44.589Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjiAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjiAwAAAAAAAA==/",
            "_etag": "\"11003289-0000-0100-0000-619543250000\"",
            "_attachments": "attachments/",
            "_ts": 1637172005
        },
        {
            "id": "a68f85c695771546484fe3b74374723d8baedee07c767d87f638b23936a5e0ac",
            "conversationId": "a68f85c695771546484fe3b74374723d8baedee07c767d87f638b23936a5e0ac",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T16:21:50.105Z",
            "endTime": "2021-11-17T16:21:50.105Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjjAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjjAwAAAAAAAA==/",
            "_etag": "\"11003389-0000-0100-0000-619543250000\"",
            "_attachments": "attachments/",
            "_ts": 1637172005
        },
        {
            "id": "f3ae8f9cef4a97d56c219c7d480f31f3b24f4038c296c1d5c5815491688af013",
            "conversationId": "f3ae8f9cef4a97d56c219c7d480f31f3b24f4038c296c1d5c5815491688af013",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T16:22:59.735Z",
            "endTime": "2021-11-17T16:22:59.735Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjkAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjkAwAAAAAAAA==/",
            "_etag": "\"11003489-0000-0100-0000-619543250000\"",
            "_attachments": "attachments/",
            "_ts": 1637172005
        },
        {
            "id": "b14916fad8d8111a2c82e477f846291a6c12f0c0cb0895bd52a923f0e9115bb1",
            "conversationId": "b14916fad8d8111a2c82e477f846291a6c12f0c0cb0895bd52a923f0e9115bb1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 52,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T16:56:34.432Z",
            "endTime": "2021-11-17T16:56:47.263Z",
            "timeSpent": 13,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjlAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjlAwAAAAAAAA==/",
            "_etag": "\"110021c9-0000-0100-0000-619551350000\"",
            "_attachments": "attachments/",
            "_ts": 1637175605
        },
        {
            "id": "febd6e3a31ac4b5579f5aa80a6bbe9fd4a51edf91fd684d13a76bf532e6e5044",
            "conversationId": "febd6e3a31ac4b5579f5aa80a6bbe9fd4a51edf91fd684d13a76bf532e6e5044",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T16:05:22.825Z",
            "endTime": "2021-11-17T16:05:22.825Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjmAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjmAwAAAAAAAA==/",
            "_etag": "\"11003889-0000-0100-0000-619543250000\"",
            "_attachments": "attachments/",
            "_ts": 1637172005
        },
        {
            "id": "5fec7419634f673178c756b6ff724da49075dbfd13cef67abbd3993cb809d55a",
            "conversationId": "5fec7419634f673178c756b6ff724da49075dbfd13cef67abbd3993cb809d55a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T16:10:48.461Z",
            "endTime": "2021-11-17T16:10:48.461Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjnAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjnAwAAAAAAAA==/",
            "_etag": "\"11003a89-0000-0100-0000-619543250000\"",
            "_attachments": "attachments/",
            "_ts": 1637172005
        },
        {
            "id": "05e62bb83f0efbaac9216a6da8bd3c8e1f4c3096e7fd1c55303c0a44341c52c1",
            "conversationId": "05e62bb83f0efbaac9216a6da8bd3c8e1f4c3096e7fd1c55303c0a44341c52c1",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T16:14:30.026Z",
            "endTime": "2021-11-17T16:14:30.026Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjoAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjoAwAAAAAAAA==/",
            "_etag": "\"11003b89-0000-0100-0000-619543250000\"",
            "_attachments": "attachments/",
            "_ts": 1637172005
        },
        {
            "id": "56266df5ae8fb8a70d993fa67116eb2704dca7a1d81b4723a76199b51d3bb796",
            "conversationId": "56266df5ae8fb8a70d993fa67116eb2704dca7a1d81b4723a76199b51d3bb796",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 52,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T16:59:04.556Z",
            "endTime": "2021-11-17T16:59:04.556Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjpAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjpAwAAAAAAAA==/",
            "_etag": "\"110022c9-0000-0100-0000-619551350000\"",
            "_attachments": "attachments/",
            "_ts": 1637175605
        },
        {
            "id": "314750cbf23cd19cb951c4ff9f1cf5be05b388b0fdbfd83e3895bcfb5bb16d80",
            "conversationId": "314750cbf23cd19cb951c4ff9f1cf5be05b388b0fdbfd83e3895bcfb5bb16d80",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T16:12:56.156Z",
            "endTime": "2021-11-17T16:12:56.156Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjqAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjqAwAAAAAAAA==/",
            "_etag": "\"11003989-0000-0100-0000-619543250000\"",
            "_attachments": "attachments/",
            "_ts": 1637172005
        },
        {
            "id": "71a91f46c928b11eb86a193d614510dc5733542adc80e30bc8428ce0bc0618c2",
            "conversationId": "71a91f46c928b11eb86a193d614510dc5733542adc80e30bc8428ce0bc0618c2",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T16:25:38.476Z",
            "endTime": "2021-11-17T16:25:38.476Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjrAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjrAwAAAAAAAA==/",
            "_etag": "\"11003589-0000-0100-0000-619543250000\"",
            "_attachments": "attachments/",
            "_ts": 1637172005
        },
        {
            "id": "f0a58d5da0b8639fd927c33eb8184f345b80cd8a9daa9cc8feebce72b6204a2a",
            "conversationId": "f0a58d5da0b8639fd927c33eb8184f345b80cd8a9daa9cc8feebce72b6204a2a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T17:23:07.408Z",
            "endTime": "2021-11-17T17:23:07.408Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjsAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjsAwAAAAAAAA==/",
            "_etag": "\"110020c9-0000-0100-0000-619551350000\"",
            "_attachments": "attachments/",
            "_ts": 1637175605
        },
        {
            "id": "e54da263b2c0c3b081de36e1d2f15a379bcd616f7c010cf75161091e052147c3",
            "conversationId": "e54da263b2c0c3b081de36e1d2f15a379bcd616f7c010cf75161091e052147c3",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 33,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T17:50:38.661Z",
            "endTime": "2021-11-17T17:51:13.054Z",
            "timeSpent": 34,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjtAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjtAwAAAAAAAA==/",
            "_etag": "\"110031e5-0000-0100-0000-61955f430000\"",
            "_attachments": "attachments/",
            "_ts": 1637179203
        },
        {
            "id": "06987fc351392ff1440cd67a99a92b61e5a12cfd1484377307c243dc3a61c267",
            "conversationId": "06987fc351392ff1440cd67a99a92b61e5a12cfd1484377307c243dc3a61c267",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-17T18:56:17.798Z",
            "endTime": "2021-11-17T18:56:33.633Z",
            "timeSpent": 16,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjuAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjuAwAAAAAAAA==/",
            "_etag": "\"110024e5-0000-0100-0000-61955f430000\"",
            "_attachments": "attachments/",
            "_ts": 1637179203
        },
        {
            "id": "737e286288307339124e3e06f65b58cc9dcf0582459f77f877a443197ccb3bcc",
            "conversationId": "737e286288307339124e3e06f65b58cc9dcf0582459f77f877a443197ccb3bcc",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 65,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T18:37:34.323Z",
            "endTime": "2021-11-17T18:39:38.947Z",
            "timeSpent": 125,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjvAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjvAwAAAAAAAA==/",
            "_etag": "\"1200f504-0000-0100-0000-61956d560000\"",
            "_attachments": "attachments/",
            "_ts": 1637182806
        },
        {
            "id": "3b59580925c7722bda8638b66f8e8764470b721069086adb2a3b68497aac5f9a",
            "conversationId": "3b59580925c7722bda8638b66f8e8764470b721069086adb2a3b68497aac5f9a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T18:57:39.288Z",
            "endTime": "2021-11-17T18:57:39.288Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjwAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjwAwAAAAAAAA==/",
            "_etag": "\"12000c05-0000-0100-0000-61956d560000\"",
            "_attachments": "attachments/",
            "_ts": 1637182806
        },
        {
            "id": "2e1febc8e57455abf97e08370648b309f38e654453f3b179b0a7f6c9464f7769",
            "conversationId": "2e1febc8e57455abf97e08370648b309f38e654453f3b179b0a7f6c9464f7769",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T18:58:31.746Z",
            "endTime": "2021-11-17T18:58:31.746Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjxAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjxAwAAAAAAAA==/",
            "_etag": "\"12000d05-0000-0100-0000-61956d560000\"",
            "_attachments": "attachments/",
            "_ts": 1637182806
        },
        {
            "id": "f3cbfa31e8469b88ce04a4f056c40030053060213c5bce217ddadb059f3738e0",
            "conversationId": "f3cbfa31e8469b88ce04a4f056c40030053060213c5bce217ddadb059f3738e0",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T19:01:29.923Z",
            "endTime": "2021-11-17T19:01:29.923Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjyAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjyAwAAAAAAAA==/",
            "_etag": "\"1200fd04-0000-0100-0000-61956d560000\"",
            "_attachments": "attachments/",
            "_ts": 1637182806
        },
        {
            "id": "20b63071c96866ee31a8bdac4bc7c6d73bc355b8f51ad9ca6674595db6447bcb",
            "conversationId": "20b63071c96866ee31a8bdac4bc7c6d73bc355b8f51ad9ca6674595db6447bcb",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T19:26:36.42Z",
            "endTime": "2021-11-17T19:27:09.246Z",
            "timeSpent": 33,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjzAwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjzAwAAAAAAAA==/",
            "_etag": "\"1200f804-0000-0100-0000-61956d560000\"",
            "_attachments": "attachments/",
            "_ts": 1637182806
        },
        {
            "id": "cdc569eaa5781b1ae22872f6597c36057be8c3436b3775487b5595d8d452ea0f",
            "conversationId": "cdc569eaa5781b1ae22872f6597c36057be8c3436b3775487b5595d8d452ea0f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T19:39:39.624Z",
            "endTime": "2021-11-17T19:39:39.624Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj0AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj0AwAAAAAAAA==/",
            "_etag": "\"1200b01a-0000-0100-0000-61957b650000\"",
            "_attachments": "attachments/",
            "_ts": 1637186405
        },
        {
            "id": "cced7b41fe2c0d2dda6d9eb931bdfe40508fe3f23938c2146b81c4e4610fc89a",
            "conversationId": "cced7b41fe2c0d2dda6d9eb931bdfe40508fe3f23938c2146b81c4e4610fc89a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T19:40:11.255Z",
            "endTime": "2021-11-17T19:40:11.255Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj1AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj1AwAAAAAAAA==/",
            "_etag": "\"1200a01a-0000-0100-0000-61957b650000\"",
            "_attachments": "attachments/",
            "_ts": 1637186405
        },
        {
            "id": "6d9f01899e4ae99b5067bcb7af175c6ee85026ae57592bef92af7497124a0042",
            "conversationId": "6d9f01899e4ae99b5067bcb7af175c6ee85026ae57592bef92af7497124a0042",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T19:01:55.931Z",
            "endTime": "2021-11-17T19:01:55.931Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj2AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj2AwAAAAAAAA==/",
            "_etag": "\"1200f904-0000-0100-0000-61956d560000\"",
            "_attachments": "attachments/",
            "_ts": 1637182806
        },
        {
            "id": "ab318a2b3f51eece689c67fd53b001618b8b296b36ad99485a51f5a7639c4364",
            "conversationId": "ab318a2b3f51eece689c67fd53b001618b8b296b36ad99485a51f5a7639c4364",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T19:03:34.422Z",
            "endTime": "2021-11-17T19:03:34.422Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj3AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj3AwAAAAAAAA==/",
            "_etag": "\"1200fa04-0000-0100-0000-61956d560000\"",
            "_attachments": "attachments/",
            "_ts": 1637182806
        },
        {
            "id": "0c0a2ee8798405112c864f97ab01f214bf9e06fad62c8a7e454d9c1551a8fe97",
            "conversationId": "0c0a2ee8798405112c864f97ab01f214bf9e06fad62c8a7e454d9c1551a8fe97",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T19:06:21.989Z",
            "endTime": "2021-11-17T19:06:21.989Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj4AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj4AwAAAAAAAA==/",
            "_etag": "\"1200fb04-0000-0100-0000-61956d560000\"",
            "_attachments": "attachments/",
            "_ts": 1637182806
        },
        {
            "id": "3a3ad1f09ad4ee4ce1a340b805b9dea2386095199a9edd4b357678f1268fcba9",
            "conversationId": "3a3ad1f09ad4ee4ce1a340b805b9dea2386095199a9edd4b357678f1268fcba9",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T19:29:46.199Z",
            "endTime": "2021-11-17T19:29:46.199Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj5AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj5AwAAAAAAAA==/",
            "_etag": "\"1200fc04-0000-0100-0000-61956d560000\"",
            "_attachments": "attachments/",
            "_ts": 1637182806
        },
        {
            "id": "c14b0a394599184764c875be79afe9c7decd123248c9d1995516429dd80b7296",
            "conversationId": "c14b0a394599184764c875be79afe9c7decd123248c9d1995516429dd80b7296",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T19:11:46.244Z",
            "endTime": "2021-11-17T19:11:46.244Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj6AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj6AwAAAAAAAA==/",
            "_etag": "\"1200f604-0000-0100-0000-61956d560000\"",
            "_attachments": "attachments/",
            "_ts": 1637182806
        },
        {
            "id": "ebb44acf17361f6e1d3f938bf40acf95cc3faf04acac1b09960d5deb48dfcd4e",
            "conversationId": "ebb44acf17361f6e1d3f938bf40acf95cc3faf04acac1b09960d5deb48dfcd4e",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T19:25:52.402Z",
            "endTime": "2021-11-17T19:25:52.402Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj7AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj7AwAAAAAAAA==/",
            "_etag": "\"1200f704-0000-0100-0000-61956d560000\"",
            "_attachments": "attachments/",
            "_ts": 1637182806
},
    
        {
            "id": "a9701dbce2cc761a437034a075cfe98a59dac02d1b49278c8fffc1b3b3b4c681",
            "conversationId": "a9701dbce2cc761a437034a075cfe98a59dac02d1b49278c8fffc1b3b3b4c681",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T20:31:59.927Z",
            "endTime": "2021-11-17T20:31:59.927Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj8AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj8AwAAAAAAAA==/",
            "_etag": "\"1200ec33-0000-0100-0000-619589750000\"",
            "_attachments": "attachments/",
            "_ts": 1637190005
        },
        {
            "id": "50f4ebac71fa23f04ab3e7928a65fdadcbcfea95649eac31cf961165684dfca6",
            "conversationId": "50f4ebac71fa23f04ab3e7928a65fdadcbcfea95649eac31cf961165684dfca6",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T20:32:30.494Z",
            "endTime": "2021-11-17T20:32:30.494Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj9AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj9AwAAAAAAAA==/",
            "_etag": "\"1200ed33-0000-0100-0000-619589750000\"",
            "_attachments": "attachments/",
            "_ts": 1637190005
        },
        {
            "id": "4f227aa251ccd181184253926c3635904343879b8c0afba9e4333889173bc0bd",
            "conversationId": "4f227aa251ccd181184253926c3635904343879b8c0afba9e4333889173bc0bd",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T20:45:53.945Z",
            "endTime": "2021-11-17T20:45:53.945Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj+AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj+AwAAAAAAAA==/",
            "_etag": "\"1200ee33-0000-0100-0000-619589750000\"",
            "_attachments": "attachments/",
            "_ts": 1637190005
        },
        {
            "id": "7b3332146b29ce755dfcd22032f076ba0f5608ff4baf15003463bb0c91351007",
            "conversationId": "7b3332146b29ce755dfcd22032f076ba0f5608ff4baf15003463bb0c91351007",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T20:48:39.317Z",
            "endTime": "2021-11-17T20:48:39.317Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj-AwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj-AwAAAAAAAA==/",
            "_etag": "\"1200ef33-0000-0100-0000-619589750000\"",
            "_attachments": "attachments/",
            "_ts": 1637190005
        },
        {
            "id": "17247928ed8aa9f5fa68b77d9cc98a1cd6ee702f17d1958a2ee5c27d866b1861",
            "conversationId": "17247928ed8aa9f5fa68b77d9cc98a1cd6ee702f17d1958a2ee5c27d866b1861",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T20:49:57.881Z",
            "endTime": "2021-11-17T20:49:57.881Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgABAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgABAAAAAAAAA==/",
            "_etag": "\"1200f033-0000-0100-0000-619589750000\"",
            "_attachments": "attachments/",
            "_ts": 1637190005
        },
        {
            "id": "a604f1b1c839dddd708407dad7df28ef334bf6a418e9937d6ac4141c679ece65",
            "conversationId": "a604f1b1c839dddd708407dad7df28ef334bf6a418e9937d6ac4141c679ece65",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T20:50:22.226Z",
            "endTime": "2021-11-17T20:50:22.226Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgBBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgBBAAAAAAAAA==/",
            "_etag": "\"1200f133-0000-0100-0000-619589750000\"",
            "_attachments": "attachments/",
            "_ts": 1637190005
        },
        {
            "id": "de00193ffbb86f839aa13b0e786193e4ec665ca2440607205c028fc8a7670959",
            "conversationId": "de00193ffbb86f839aa13b0e786193e4ec665ca2440607205c028fc8a7670959",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T20:50:33.426Z",
            "endTime": "2021-11-17T20:50:33.426Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgCBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgCBAAAAAAAAA==/",
            "_etag": "\"1200e333-0000-0100-0000-619589750000\"",
            "_attachments": "attachments/",
            "_ts": 1637190005
        },
        {
            "id": "6f55e25da9c30bafbf39e234e9c2bd56813f3c26cc8e1348ccf936b27abf43f3",
            "conversationId": "6f55e25da9c30bafbf39e234e9c2bd56813f3c26cc8e1348ccf936b27abf43f3",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T20:50:45.969Z",
            "endTime": "2021-11-17T20:50:45.969Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgDBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgDBAAAAAAAAA==/",
            "_etag": "\"1200e433-0000-0100-0000-619589750000\"",
            "_attachments": "attachments/",
            "_ts": 1637190005
        },
        {
            "id": "5902540fbd478e8202575c567954505b7f99879670473c7215bb76bbf3bbdbed",
            "conversationId": "5902540fbd478e8202575c567954505b7f99879670473c7215bb76bbf3bbdbed",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T20:51:01.875Z",
            "endTime": "2021-11-17T20:51:01.875Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgEBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgEBAAAAAAAAA==/",
            "_etag": "\"1200ea33-0000-0100-0000-619589750000\"",
            "_attachments": "attachments/",
            "_ts": 1637190005
        },
        {
            "id": "768f3a904da4911865efd7e7223904d7593c94a55616e1598813ecde8ade02d4",
            "conversationId": "768f3a904da4911865efd7e7223904d7593c94a55616e1598813ecde8ade02d4",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T20:51:42.054Z",
            "endTime": "2021-11-17T20:51:42.054Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgFBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgFBAAAAAAAAA==/",
            "_etag": "\"1200e533-0000-0100-0000-619589750000\"",
            "_attachments": "attachments/",
            "_ts": 1637190005
        },
        {
            "id": "7349a9964c8ae5435078ec0246582ef76cb4dcb19de82234741fa7e38bc991a0",
            "conversationId": "7349a9964c8ae5435078ec0246582ef76cb4dcb19de82234741fa7e38bc991a0",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T20:52:16.293Z",
            "endTime": "2021-11-17T20:52:16.293Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgGBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgGBAAAAAAAAA==/",
            "_etag": "\"1200e633-0000-0100-0000-619589750000\"",
            "_attachments": "attachments/",
            "_ts": 1637190005
        },
        {
            "id": "51077e23836274c56e1c77a44a5a1d79d2ae27df038df6ff412eee9a8c27ac82",
            "conversationId": "51077e23836274c56e1c77a44a5a1d79d2ae27df038df6ff412eee9a8c27ac82",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T20:55:42.291Z",
            "endTime": "2021-11-17T20:55:42.291Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgHBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgHBAAAAAAAAA==/",
            "_etag": "\"1200e733-0000-0100-0000-619589750000\"",
            "_attachments": "attachments/",
            "_ts": 1637190005
        },
        {
            "id": "4fffed9c5c3a51a8575f80aabc4ea6a83a142b67e2149493bf419bc7b6c31e7f",
            "conversationId": "4fffed9c5c3a51a8575f80aabc4ea6a83a142b67e2149493bf419bc7b6c31e7f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T20:55:29.514Z",
            "endTime": "2021-11-17T20:55:29.514Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgIBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgIBAAAAAAAAA==/",
            "_etag": "\"1200eb33-0000-0100-0000-619589750000\"",
            "_attachments": "attachments/",
            "_ts": 1637190005
        },
        {
            "id": "37fe8fc3dd47b5c393f861e897f9dfe396a0b43bdf24b51e47a5585c5426e919",
            "conversationId": "37fe8fc3dd47b5c393f861e897f9dfe396a0b43bdf24b51e47a5585c5426e919",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T20:56:15.12Z",
            "endTime": "2021-11-17T20:56:15.12Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgJBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgJBAAAAAAAAA==/",
            "_etag": "\"1200e833-0000-0100-0000-619589750000\"",
            "_attachments": "attachments/",
            "_ts": 1637190005
        },
        {
            "id": "744559308fe07495e9cd293ff15f35b2575e5ec9fa6f22c8bd1331a053d87cae",
            "conversationId": "744559308fe07495e9cd293ff15f35b2575e5ec9fa6f22c8bd1331a053d87cae",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T20:58:04.834Z",
            "endTime": "2021-11-17T20:58:43.48Z",
            "timeSpent": 39,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgKBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgKBAAAAAAAAA==/",
            "_etag": "\"1200e933-0000-0100-0000-619589750000\"",
            "_attachments": "attachments/",
            "_ts": 1637190005
        },
        {
            "id": "04d38278f08c727bd83fdab1f2ac0f40d9de510b7c5b7f258733bf878028dd94",
            "conversationId": "04d38278f08c727bd83fdab1f2ac0f40d9de510b7c5b7f258733bf878028dd94",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T20:03:19.804Z",
            "endTime": "2021-11-17T20:03:19.804Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgLBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgLBAAAAAAAAA==/",
            "_etag": "\"12009e1a-0000-0100-0000-61957b650000\"",
            "_attachments": "attachments/",
            "_ts": 1637186405
        },
        {
            "id": "19473ab37802eabaad50ce96619ef8db6a9447218bc6da737faffdc1c2214c50",
            "conversationId": "19473ab37802eabaad50ce96619ef8db6a9447218bc6da737faffdc1c2214c50",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Hair loss"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-17T20:03:19.807Z",
            "endTime": "2021-11-17T20:03:41.494Z",
            "timeSpent": 22,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgMBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgMBAAAAAAAAA==/",
            "_etag": "\"12009f1a-0000-0100-0000-61957b650000\"",
            "_attachments": "attachments/",
            "_ts": 1637186405
        },
        {
            "id": "80acb7e1337d442a574a6eadc03e98056430af552328388e8386c541e2091932",
            "conversationId": "80acb7e1337d442a574a6eadc03e98056430af552328388e8386c541e2091932",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 23,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T08:04:35.794Z",
            "endTime": "2021-11-18T08:06:00.962Z",
            "timeSpent": 85,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgNBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgNBAAAAAAAAA==/",
            "_etag": "\"1400fd21-0000-0100-0000-619624290000\"",
            "_attachments": "attachments/",
            "_ts": 1637229609
        },
        {
            "id": "558948c58207ef6a89e7d8344b04128682a76186c8700e7e6952f4c6906b98c7",
            "conversationId": "558948c58207ef6a89e7d8344b04128682a76186c8700e7e6952f4c6906b98c7",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 99,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T12:58:16.945Z",
            "endTime": "2021-11-18T13:22:19.022Z",
            "timeSpent": 1442,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgOBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgOBAAAAAAAAA==/",
            "_etag": "\"1400a0b6-0000-0100-0000-61966a720000\"",
            "_attachments": "attachments/",
            "_ts": 1637247602
        },
        {
            "id": "11247f3ba4ba1bce319ff187c2f3ea14a993bf7228b3fd688706be8c49bacbf0",
            "conversationId": "11247f3ba4ba1bce319ff187c2f3ea14a993bf7228b3fd688706be8c49bacbf0",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 99,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T13:00:00Z",
            "endTime": "2021-11-18T13:23:36.364Z",
            "timeSpent": 1416,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgPBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgPBAAAAAAAAA==/",
            "_etag": "\"1400a1b6-0000-0100-0000-61966a720000\"",
            "_attachments": "attachments/",
            "_ts": 1637247602
        },
        {
            "id": "4ec8a4e6891e49af29be8135886125f2766a3aef410ca9fb632386a121c851a3",
            "conversationId": "4ec8a4e6891e49af29be8135886125f2766a3aef410ca9fb632386a121c851a3",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T13:54:06.146Z",
            "endTime": "2021-11-18T13:54:06.146Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgQBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgQBAAAAAAAAA==/",
            "_etag": "\"14002acd-0000-0100-0000-619678820000\"",
            "_attachments": "attachments/",
            "_ts": 1637251202
        },
        {
            "id": "d21cbee4057dff57ef25b1f692cac10c01a0590386d924e557ca0231e3bd909b",
            "conversationId": "d21cbee4057dff57ef25b1f692cac10c01a0590386d924e557ca0231e3bd909b",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T13:58:03.733Z",
            "endTime": "2021-11-18T13:58:03.733Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgRBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgRBAAAAAAAAA==/",
            "_etag": "\"140027cd-0000-0100-0000-619678820000\"",
            "_attachments": "attachments/",
            "_ts": 1637251202
        },
        {
            "id": "af627a354fab6af479114e0b3068af2627d36228d74cc69a5eb18115eed3187f",
            "conversationId": "af627a354fab6af479114e0b3068af2627d36228d74cc69a5eb18115eed3187f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T15:42:01.179Z",
            "endTime": "2021-11-18T15:42:01.179Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgSBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgSBAAAAAAAAA==/",
            "_etag": "\"1400cfe9-0000-0100-0000-6196869e0000\"",
            "_attachments": "attachments/",
            "_ts": 1637254814
        },
        {
            "id": "138222ca4dba3025f86f63a864f65b05a95e250145a58466c413cc6e56985ec8",
            "conversationId": "138222ca4dba3025f86f63a864f65b05a95e250145a58466c413cc6e56985ec8",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 34,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Eye pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try Self-Care",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2021-11-18T15:22:51.458Z",
            "endTime": "2021-11-18T15:23:58.887Z",
            "timeSpent": 67,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgTBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgTBAAAAAAAAA==/",
            "_etag": "\"1400d0e9-0000-0100-0000-6196869e0000\"",
            "_attachments": "attachments/",
            "_ts": 1637254814
        },
        {
            "id": "1d38f1ea42f1ed3abaeac9d5ad15cf90257c24eb08f54ce10d1f4446a2af3f68",
            "conversationId": "1d38f1ea42f1ed3abaeac9d5ad15cf90257c24eb08f54ce10d1f4446a2af3f68",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 32,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will contact my clinic for something other than an appointment",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-18T15:24:28.462Z",
            "endTime": "2021-11-18T15:26:22.379Z",
            "timeSpent": 114,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgUBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgUBAAAAAAAAA==/",
            "_etag": "\"1400d1e9-0000-0100-0000-6196869e0000\"",
            "_attachments": "attachments/",
            "_ts": 1637254814
        },
        {
            "id": "c6811febce30754d2033c67836db41e27321f7f94110ed612ebcc6693030fb9a",
            "conversationId": "c6811febce30754d2033c67836db41e27321f7f94110ed612ebcc6693030fb9a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T17:11:14.556Z",
            "endTime": "2021-11-18T17:11:14.556Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgVBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgVBAAAAAAAAA==/",
            "_etag": "\"15000a16-0000-0100-0000-6196a2b20000\"",
            "_attachments": "attachments/",
            "_ts": 1637262002
        },
        {
            "id": "bd998b57fbd850553df47c46064508bb171b86d1eb12eda4530bde5d4b671f7a",
            "conversationId": "bd998b57fbd850553df47c46064508bb171b86d1eb12eda4530bde5d4b671f7a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T17:02:34.858Z",
            "endTime": "2021-11-18T17:02:34.858Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgWBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgWBAAAAAAAAA==/",
            "_etag": "\"15000716-0000-0100-0000-6196a2b20000\"",
            "_attachments": "attachments/",
            "_ts": 1637262002
        },
        {
            "id": "8dbe240d5dc65e38004b96ac9a2f019262559c45aca6803c65f7bfb5fc6d23da",
            "conversationId": "8dbe240d5dc65e38004b96ac9a2f019262559c45aca6803c65f7bfb5fc6d23da",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T17:03:14.754Z",
            "endTime": "2021-11-18T17:03:21.151Z",
            "timeSpent": 6,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgXBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgXBAAAAAAAAA==/",
            "_etag": "\"15000816-0000-0100-0000-6196a2b20000\"",
            "_attachments": "attachments/",
            "_ts": 1637262002
        },
        {
            "id": "4be03d94ee4cfd57b4a7cf78bc410b7d210eb870325ebfc72806d88ed439abd3",
            "conversationId": "4be03d94ee4cfd57b4a7cf78bc410b7d210eb870325ebfc72806d88ed439abd3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T17:33:20.926Z",
            "endTime": "2021-11-18T17:33:28.225Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgYBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgYBAAAAAAAAA==/",
            "_etag": "\"15006332-0000-0100-0000-6196b0c10000\"",
            "_attachments": "attachments/",
            "_ts": 1637265601
        },
        {
            "id": "93b9bdc1044b0d0452dc9b5d3034bd1d04d5c83dff59fdebf37c3b53d0820282",
            "conversationId": "93b9bdc1044b0d0452dc9b5d3034bd1d04d5c83dff59fdebf37c3b53d0820282",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 43,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T19:25:01.555Z",
            "endTime": "2021-11-18T19:25:17.705Z",
            "timeSpent": 16,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgZBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgZBAAAAAAAAA==/",
            "_etag": "\"1500d648-0000-0100-0000-6196bed10000\"",
            "_attachments": "attachments/",
            "_ts": 1637269201
        },
        {
            "id": "251ccbca1e0162665643802c4fa73e0b8b719642dd8824abadc1edff5554d27c",
            "conversationId": "251ccbca1e0162665643802c4fa73e0b8b719642dd8824abadc1edff5554d27c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 32,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-18T20:07:34.443Z",
            "endTime": "2021-11-18T20:11:31.209Z",
            "timeSpent": 237,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgaBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgaBAAAAAAAAA==/",
            "_etag": "\"1500df5b-0000-0100-0000-6196cce20000\"",
            "_attachments": "attachments/",
            "_ts": 1637272802
        },
        {
            "id": "2a55e7dc5f0a9417c15c0176ebec25c3752f0006792fd116c2fc1a5bc1ac6548",
            "conversationId": "2a55e7dc5f0a9417c15c0176ebec25c3752f0006792fd116c2fc1a5bc1ac6548",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 23,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Fever"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T21:17:56.948Z",
            "endTime": "2021-11-18T21:19:17.021Z",
            "timeSpent": 80,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgbBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgbBAAAAAAAAA==/",
            "_etag": "\"1500686a-0000-0100-0000-6196daf30000\"",
            "_attachments": "attachments/",
            "_ts": 1637276403
        },
        {
            "id": "bc4d11656ae58a968d0326b299f089c6905a9c655b2a6e4341f1a89fff54af8d",
            "conversationId": "bc4d11656ae58a968d0326b299f089c6905a9c655b2a6e4341f1a89fff54af8d",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T21:27:13.73Z",
            "endTime": "2021-11-18T21:27:13.73Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgcBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgcBAAAAAAAAA==/",
            "_etag": "\"15006c6a-0000-0100-0000-6196daf30000\"",
            "_attachments": "attachments/",
            "_ts": 1637276403
        },
        {
            "id": "6a2ef8a657d351f3a80c604bed4a7363130e92e94c07fc8867213a7fdabd5b4e",
            "conversationId": "6a2ef8a657d351f3a80c604bed4a7363130e92e94c07fc8867213a7fdabd5b4e",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T21:27:26.521Z",
            "endTime": "2021-11-18T21:27:26.521Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgdBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgdBAAAAAAAAA==/",
            "_etag": "\"1500706a-0000-0100-0000-6196daf30000\"",
            "_attachments": "attachments/",
            "_ts": 1637276403
        },
        {
            "id": "2f07f4ba9dc197209ef8e654c19e89bc26b01d52724e98f9d03431cb035bb268",
            "conversationId": "2f07f4ba9dc197209ef8e654c19e89bc26b01d52724e98f9d03431cb035bb268",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T21:27:22.825Z",
            "endTime": "2021-11-18T21:27:22.825Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgeBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgeBAAAAAAAAA==/",
            "_etag": "\"15006f6a-0000-0100-0000-6196daf30000\"",
            "_attachments": "attachments/",
            "_ts": 1637276403
        },
        {
            "id": "a36841c1448ff2e8b646732f4a1b71bf2c8de0a398af9d74cbf109cb81c8f0a3",
            "conversationId": "a36841c1448ff2e8b646732f4a1b71bf2c8de0a398af9d74cbf109cb81c8f0a3",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T21:27:20.999Z",
            "endTime": "2021-11-18T21:27:20.999Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgfBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgfBAAAAAAAAA==/",
            "_etag": "\"15006e6a-0000-0100-0000-6196daf30000\"",
            "_attachments": "attachments/",
            "_ts": 1637276403
        },
        {
            "id": "baab246488bceff335d0ecc5b6c1f940ee54fbbadfe8698b5eeec66117252020",
            "conversationId": "baab246488bceff335d0ecc5b6c1f940ee54fbbadfe8698b5eeec66117252020",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T21:27:18.25Z",
            "endTime": "2021-11-18T21:27:18.25Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIggBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIggBAAAAAAAAA==/",
            "_etag": "\"15006d6a-0000-0100-0000-6196daf30000\"",
            "_attachments": "attachments/",
            "_ts": 1637276403
        },
        {
            "id": "4cbfa2c804cb427863a19d78de04ee8b16f843758cf83c4ea7597dcefb8d8b0f",
            "conversationId": "4cbfa2c804cb427863a19d78de04ee8b16f843758cf83c4ea7597dcefb8d8b0f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T21:21:25.899Z",
            "endTime": "2021-11-18T21:21:25.899Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIghBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIghBAAAAAAAAA==/",
            "_etag": "\"15006a6a-0000-0100-0000-6196daf30000\"",
            "_attachments": "attachments/",
            "_ts": 1637276403
        },
        {
            "id": "ee71419fa1ac986b684d3f7a60b017ddbb31fc80f713bfbeed1d4f2e3afc0dde",
            "conversationId": "ee71419fa1ac986b684d3f7a60b017ddbb31fc80f713bfbeed1d4f2e3afc0dde",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T21:34:37.813Z",
            "endTime": "2021-11-18T21:34:37.813Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgiBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgiBAAAAAAAAA==/",
            "_etag": "\"15004591-0000-0100-0000-6196e9020000\"",
            "_attachments": "attachments/",
            "_ts": 1637280002
        },
        {
            "id": "ddc07548871e2b6606ca821a1c476dd754b23fc2344fb6d2b2104b1a7ce3503a",
            "conversationId": "ddc07548871e2b6606ca821a1c476dd754b23fc2344fb6d2b2104b1a7ce3503a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T22:20:47.225Z",
            "endTime": "2021-11-18T22:20:47.225Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgjBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgjBAAAAAAAAA==/",
            "_etag": "\"15004691-0000-0100-0000-6196e9020000\"",
            "_attachments": "attachments/",
            "_ts": 1637280002
        },
        {
            "id": "de838484a29ceb8705d3cf75a0978400844eded9d0746e50a8d88836730a0046",
            "conversationId": "de838484a29ceb8705d3cf75a0978400844eded9d0746e50a8d88836730a0046",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T22:21:16.727Z",
            "endTime": "2021-11-18T22:21:16.727Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgkBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgkBAAAAAAAAA==/",
            "_etag": "\"15004791-0000-0100-0000-6196e9020000\"",
            "_attachments": "attachments/",
            "_ts": 1637280002
        },
        {
            "id": "9ab4ea2961c1ad444e88eb029a794585330385ecbea5cf1879f138692e006ef7",
            "conversationId": "9ab4ea2961c1ad444e88eb029a794585330385ecbea5cf1879f138692e006ef7",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T22:21:45.847Z",
            "endTime": "2021-11-18T22:21:45.847Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIglBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIglBAAAAAAAAA==/",
            "_etag": "\"15004891-0000-0100-0000-6196e9020000\"",
            "_attachments": "attachments/",
            "_ts": 1637280002
        },
        {
            "id": "87824b36dac130c06c2973d8c182a11ef2698b681d354d59830a7ff43e385ce0",
            "conversationId": "87824b36dac130c06c2973d8c182a11ef2698b681d354d59830a7ff43e385ce0",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T22:21:56.714Z",
            "endTime": "2021-11-18T22:21:56.714Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgmBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgmBAAAAAAAAA==/",
            "_etag": "\"15004991-0000-0100-0000-6196e9020000\"",
            "_attachments": "attachments/",
            "_ts": 1637280002
        },
        {
            "id": "60b61a8cc87bbd0a11e14b4b7b7061a4833e5d50d8baa210f510e5b7d503a113",
            "conversationId": "60b61a8cc87bbd0a11e14b4b7b7061a4833e5d50d8baa210f510e5b7d503a113",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T22:22:27.119Z",
            "endTime": "2021-11-18T22:22:33.529Z",
            "timeSpent": 6,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgnBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgnBAAAAAAAAA==/",
            "_etag": "\"15004b91-0000-0100-0000-6196e9020000\"",
            "_attachments": "attachments/",
            "_ts": 1637280002
        },
        {
            "id": "b0c33d48c7b0774f7507793167fe1817b8b1baf3f3877b295d06ecbb777a40db",
            "conversationId": "b0c33d48c7b0774f7507793167fe1817b8b1baf3f3877b295d06ecbb777a40db",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T22:24:53.743Z",
            "endTime": "2021-11-18T22:27:42.899Z",
            "timeSpent": 169,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgoBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgoBAAAAAAAAA==/",
            "_etag": "\"15004a91-0000-0100-0000-6196e9020000\"",
            "_attachments": "attachments/",
            "_ts": 1637280002
        },
        {
            "id": "578d04d32dfdf2aa106a39f50ce5a3ba2317f4b99d5d3cc05fc1c4df6a4493ef",
            "conversationId": "578d04d32dfdf2aa106a39f50ce5a3ba2317f4b99d5d3cc05fc1c4df6a4493ef",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T22:12:20.509Z",
            "endTime": "2021-11-18T22:12:20.509Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgpBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgpBAAAAAAAAA==/",
            "_etag": "\"15004491-0000-0100-0000-6196e9020000\"",
            "_attachments": "attachments/",
            "_ts": 1637280002
        },
        {
            "id": "d1777bd5ed527ff19793408ae6a80a7aba9de58c312f9c00050651ebf332add2",
            "conversationId": "d1777bd5ed527ff19793408ae6a80a7aba9de58c312f9c00050651ebf332add2",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T22:00:54.725Z",
            "endTime": "2021-11-18T22:00:54.725Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgqBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgqBAAAAAAAAA==/",
            "_etag": "\"15003b91-0000-0100-0000-6196e9020000\"",
            "_attachments": "attachments/",
            "_ts": 1637280002
        },
        {
            "id": "36f6693d8c4256da07d319890a4e510aa63d7139c8aa9e32ceec481f628b98cf",
            "conversationId": "36f6693d8c4256da07d319890a4e510aa63d7139c8aa9e32ceec481f628b98cf",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T22:38:53.872Z",
            "endTime": "2021-11-18T22:38:53.872Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgrBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgrBAAAAAAAAA==/",
            "_etag": "\"150031a7-0000-0100-0000-6196f7190000\"",
            "_attachments": "attachments/",
            "_ts": 1637283609
        },
        {
            "id": "e2ea65f77fa85efb68b33c338e78321f2669bcd1e29fe40c03a022883d8e2671",
            "conversationId": "e2ea65f77fa85efb68b33c338e78321f2669bcd1e29fe40c03a022883d8e2671",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T22:38:59.691Z",
            "endTime": "2021-11-18T22:38:59.691Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgsBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgsBAAAAAAAAA==/",
            "_etag": "\"150032a7-0000-0100-0000-6196f7190000\"",
            "_attachments": "attachments/",
            "_ts": 1637283609
        },
        {
            "id": "ad00186072fbbb9175d485cd597a6a0000e05432aef9b068d7484f569f233d8e",
            "conversationId": "ad00186072fbbb9175d485cd597a6a0000e05432aef9b068d7484f569f233d8e",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T22:39:16.51Z",
            "endTime": "2021-11-18T22:39:29.285Z",
            "timeSpent": 13,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgtBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgtBAAAAAAAAA==/",
            "_etag": "\"150033a7-0000-0100-0000-6196f7190000\"",
            "_attachments": "attachments/",
            "_ts": 1637283609
        },
        {
            "id": "91b108f20a13a236ebcc1521030a8732730de1c4ce4affa4f03b5ff47921ba19",
            "conversationId": "91b108f20a13a236ebcc1521030a8732730de1c4ce4affa4f03b5ff47921ba19",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T22:39:09.063Z",
            "endTime": "2021-11-18T22:39:09.063Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIguBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIguBAAAAAAAAA==/",
            "_etag": "\"150034a7-0000-0100-0000-6196f7190000\"",
            "_attachments": "attachments/",
            "_ts": 1637283609
        },
        {
            "id": "2c5e361f6796edc347b95950fd2585bcadb022afd095ae740bd5c5066ea5f360",
            "conversationId": "2c5e361f6796edc347b95950fd2585bcadb022afd095ae740bd5c5066ea5f360",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T22:40:18.603Z",
            "endTime": "2021-11-18T22:40:18.603Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgvBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgvBAAAAAAAAA==/",
            "_etag": "\"150035a7-0000-0100-0000-6196f7190000\"",
            "_attachments": "attachments/",
            "_ts": 1637283609
        },
        {
            "id": "8e5302da80b039cbacc3787675c23651cccecd43a1d953ff6f8a9ecc465999d3",
            "conversationId": "8e5302da80b039cbacc3787675c23651cccecd43a1d953ff6f8a9ecc465999d3",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T22:40:28.109Z",
            "endTime": "2021-11-18T22:40:28.109Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgwBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgwBAAAAAAAAA==/",
            "_etag": "\"150036a7-0000-0100-0000-6196f7190000\"",
            "_attachments": "attachments/",
            "_ts": 1637283609
        },
        {
            "id": "e9dc352d0c75a3001e5764aac02bec898d7b0c075f3590262a9b55804815a14b",
            "conversationId": "e9dc352d0c75a3001e5764aac02bec898d7b0c075f3590262a9b55804815a14b",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T22:40:36.965Z",
            "endTime": "2021-11-18T22:40:36.965Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgxBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgxBAAAAAAAAA==/",
            "_etag": "\"150037a7-0000-0100-0000-6196f7190000\"",
            "_attachments": "attachments/",
            "_ts": 1637283609
        },
        {
            "id": "9103c0d36cec8d786c293c3588f19f599dac6304b62ce7226b6e27734f528359",
            "conversationId": "9103c0d36cec8d786c293c3588f19f599dac6304b62ce7226b6e27734f528359",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-18T22:41:55.061Z",
            "endTime": "2021-11-18T22:41:55.061Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgyBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgyBAAAAAAAAA==/",
            "_etag": "\"150038a7-0000-0100-0000-6196f7190000\"",
            "_attachments": "attachments/",
            "_ts": 1637283609
        },
        {
            "id": "fe68563d74ae397eb84de20aba1b18d4cbc778928261c94069cfd833667793e0",
            "conversationId": "fe68563d74ae397eb84de20aba1b18d4cbc778928261c94069cfd833667793e0",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T06:43:52.919Z",
            "endTime": "2021-11-19T06:44:27.228Z",
            "timeSpent": 34,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgzBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgzBAAAAAAAAA==/",
            "_etag": "\"160095d8-0000-0100-0000-619767910000\"",
            "_attachments": "attachments/",
            "_ts": 1637312401
        },
        {
            "id": "5fe7e81227f90ff28edda2270dfd4e0acd4025d3b0ee10677b0198abdd762ed6",
            "conversationId": "5fe7e81227f90ff28edda2270dfd4e0acd4025d3b0ee10677b0198abdd762ed6",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Yes",
            "age": 33,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-19T11:04:57.662Z",
            "endTime": "2021-11-19T11:05:25.95Z",
            "timeSpent": 28,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg0BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg0BAAAAAAAAA==/",
            "_etag": "\"1700ac40-0000-0100-0000-61979fd80000\"",
            "_attachments": "attachments/",
            "_ts": 1637326808
        },
        {
            "id": "8336874734919b07e024519f87da6c9d269416cb68a9538eb8c39ba516b05b0f",
            "conversationId": "8336874734919b07e024519f87da6c9d269416cb68a9538eb8c39ba516b05b0f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-19T12:14:03.301Z",
            "endTime": "2021-11-19T12:14:25.001Z",
            "timeSpent": 22,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg1BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg1BAAAAAAAAA==/",
            "_etag": "\"17009e57-0000-0100-0000-6197ade20000\"",
            "_attachments": "attachments/",
            "_ts": 1637330402
        },
        {
            "id": "5dced407c085f46c022ac180315552406ae5792a94db808a74b91fb859a67373",
            "conversationId": "5dced407c085f46c022ac180315552406ae5792a94db808a74b91fb859a67373",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 23,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Yes"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-19T12:24:43.068Z",
            "endTime": "2021-11-19T12:30:03.187Z",
            "timeSpent": 320,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg2BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg2BAAAAAAAAA==/",
            "_etag": "\"17009f57-0000-0100-0000-6197ade20000\"",
            "_attachments": "attachments/",
            "_ts": 1637330402
        },
        {
            "id": "18fa3ac77a08889c4474721d39f3a54d122f1cc1b1b5962d0b530a3111d877aa",
            "conversationId": "18fa3ac77a08889c4474721d39f3a54d122f1cc1b1b5962d0b530a3111d877aa",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 32,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T12:21:14.757Z",
            "endTime": "2021-11-19T12:22:46.043Z",
            "timeSpent": 91,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg3BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg3BAAAAAAAAA==/",
            "_etag": "\"1700a257-0000-0100-0000-6197ade20000\"",
            "_attachments": "attachments/",
            "_ts": 1637330402
        },
        {
            "id": "b6def9c3c98556b887656884d0cdf00e8be2a66835aa23ad1ae8569060c8a935",
            "conversationId": "b6def9c3c98556b887656884d0cdf00e8be2a66835aa23ad1ae8569060c8a935",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T13:33:27.772Z",
            "endTime": "2021-11-19T13:33:29.795Z",
            "timeSpent": 2,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg4BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg4BAAAAAAAAA==/",
            "_etag": "\"17001589-0000-0100-0000-6197ca400000\"",
            "_attachments": "attachments/",
            "_ts": 1637337664
        },
        {
            "id": "83408c8d63024727a5ee7b26c9469b4eaaad8161b0bcb1d4da0cbbf34583b045",
            "conversationId": "83408c8d63024727a5ee7b26c9469b4eaaad8161b0bcb1d4da0cbbf34583b045",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 34,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T13:54:51.128Z",
            "endTime": "2021-11-19T13:55:06.096Z",
            "timeSpent": 15,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg5BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg5BAAAAAAAAA==/",
            "_etag": "\"17000789-0000-0100-0000-6197ca400000\"",
            "_attachments": "attachments/",
            "_ts": 1637337664
        },
        {
            "id": "1ece759169c25b92309989cae69c4004e26ddbd1ab436723eb06dc1ee4c314dc",
            "conversationId": "1ece759169c25b92309989cae69c4004e26ddbd1ab436723eb06dc1ee4c314dc",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T13:34:29.1Z",
            "endTime": "2021-11-19T13:34:29.1Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg6BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg6BAAAAAAAAA==/",
            "_etag": "\"17001789-0000-0100-0000-6197ca400000\"",
            "_attachments": "attachments/",
            "_ts": 1637337664
        },
        {
            "id": "eda7e7bf4f60365c1a4cbcae3d741cf5be7719eb70c4851185501031d21ed55d",
            "conversationId": "eda7e7bf4f60365c1a4cbcae3d741cf5be7719eb70c4851185501031d21ed55d",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T13:39:49.886Z",
            "endTime": "2021-11-19T13:39:49.886Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg7BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg7BAAAAAAAAA==/",
            "_etag": "\"17000f89-0000-0100-0000-6197ca400000\"",
            "_attachments": "attachments/",
            "_ts": 1637337664
        },
        {
            "id": "ab4aac2e7ae7b077a085604fa68593fd6056c5ce573a5bdb5b8fba0c25f59c52",
            "conversationId": "ab4aac2e7ae7b077a085604fa68593fd6056c5ce573a5bdb5b8fba0c25f59c52",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T13:40:53.186Z",
            "endTime": "2021-11-19T13:40:53.186Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg8BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg8BAAAAAAAAA==/",
            "_etag": "\"17001389-0000-0100-0000-6197ca400000\"",
            "_attachments": "attachments/",
            "_ts": 1637337664
        },
        {
            "id": "68960308e1b4e17f900d79d0bcd0269b69bd9acfd6170a981b283b66d4de8f5c",
            "conversationId": "68960308e1b4e17f900d79d0bcd0269b69bd9acfd6170a981b283b66d4de8f5c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 96,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T13:28:37.749Z",
            "endTime": "2021-11-19T13:29:06.192Z",
            "timeSpent": 28,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg9BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg9BAAAAAAAAA==/",
            "_etag": "\"17007d73-0000-0100-0000-6197bbf40000\"",
            "_attachments": "attachments/",
            "_ts": 1637334004
        },
        {
            "id": "98d8afd6a6c05bd68c7670e41a8a14023b0071d6163984609c457daf74369c2f",
            "conversationId": "98d8afd6a6c05bd68c7670e41a8a14023b0071d6163984609c457daf74369c2f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Fever"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T13:30:43.171Z",
            "endTime": "2021-11-19T13:31:02.306Z",
            "timeSpent": 19,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg+BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg+BAAAAAAAAA==/",
            "_etag": "\"17000889-0000-0100-0000-6197ca400000\"",
            "_attachments": "attachments/",
            "_ts": 1637337664
        },
        {
            "id": "1b7846a6412d694807503232708fb73171c1e0b4595cef24583ddb70dd0a5536",
            "conversationId": "1b7846a6412d694807503232708fb73171c1e0b4595cef24583ddb70dd0a5536",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 65,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Fever"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T13:32:18.577Z",
            "endTime": "2021-11-19T13:32:41.054Z",
            "timeSpent": 22,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg-BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg-BAAAAAAAAA==/",
            "_etag": "\"17000989-0000-0100-0000-6197ca400000\"",
            "_attachments": "attachments/",
            "_ts": 1637337664
        },
        {
            "id": "b0c2a796f7996ad6939be005dfac5f681f0aa90ba46ddb05b5d8b917e4e7033f",
            "conversationId": "b0c2a796f7996ad6939be005dfac5f681f0aa90ba46ddb05b5d8b917e4e7033f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 36,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T13:32:43.368Z",
            "endTime": "2021-11-19T13:34:42.812Z",
            "timeSpent": 119,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhABAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhABAAAAAAAAA==/",
            "_etag": "\"17000e89-0000-0100-0000-6197ca400000\"",
            "_attachments": "attachments/",
            "_ts": 1637337664
        },
        {
            "id": "0e5610056662b97c357a347de244ef268aefc6c50a8286d76079847d3f140b3b",
            "conversationId": "0e5610056662b97c357a347de244ef268aefc6c50a8286d76079847d3f140b3b",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 98,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Fever"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T13:32:59.156Z",
            "endTime": "2021-11-19T13:33:25.41Z",
            "timeSpent": 26,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhBBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhBBAAAAAAAAA==/",
            "_etag": "\"17000a89-0000-0100-0000-6197ca400000\"",
            "_attachments": "attachments/",
            "_ts": 1637337664
        },
        {
            "id": "122a5aa1407e8f8fee132a0298d452f2963edf1e8484cd2d740b4e689b1287c7",
            "conversationId": "122a5aa1407e8f8fee132a0298d452f2963edf1e8484cd2d740b4e689b1287c7",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 52,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T13:33:52.856Z",
            "endTime": "2021-11-19T13:34:23.04Z",
            "timeSpent": 30,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhCBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhCBAAAAAAAAA==/",
            "_etag": "\"17001689-0000-0100-0000-6197ca400000\"",
            "_attachments": "attachments/",
            "_ts": 1637337664
        },
        {
            "id": "2917b6c8f22922c4a371e67d2ce25c44519fae9c7c4ae75673a737dd27b82952",
            "conversationId": "2917b6c8f22922c4a371e67d2ce25c44519fae9c7c4ae75673a737dd27b82952",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T13:34:21.33Z",
            "endTime": "2021-11-19T13:34:25.738Z",
            "timeSpent": 4,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhDBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhDBAAAAAAAAA==/",
            "_etag": "\"17001889-0000-0100-0000-6197ca400000\"",
            "_attachments": "attachments/",
            "_ts": 1637337664
        },
        {
            "id": "292ace352b6969e0425109c02ddf7b08872e93fe096427c9a9970409dca85ff6",
            "conversationId": "292ace352b6969e0425109c02ddf7b08872e93fe096427c9a9970409dca85ff6",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T13:39:54.197Z",
            "endTime": "2021-11-19T13:39:54.197Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhEBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhEBAAAAAAAAA==/",
            "_etag": "\"17001089-0000-0100-0000-6197ca400000\"",
            "_attachments": "attachments/",
            "_ts": 1637337664
        },
        {
            "id": "de64da70d8231cee0430a6180b27935b038f26082ed76dcc50ebf816232b35d0",
            "conversationId": "de64da70d8231cee0430a6180b27935b038f26082ed76dcc50ebf816232b35d0",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T13:40:09.475Z",
            "endTime": "2021-11-19T13:40:09.475Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhFBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhFBAAAAAAAAA==/",
            "_etag": "\"17001189-0000-0100-0000-6197ca400000\"",
            "_attachments": "attachments/",
            "_ts": 1637337664
        },
        {
            "id": "ddf18aecd9cc751ae8e1a560f51b5f4a8eb7923ff3ad50d013184d7ed6779e73",
            "conversationId": "ddf18aecd9cc751ae8e1a560f51b5f4a8eb7923ff3ad50d013184d7ed6779e73",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T13:40:14.115Z",
            "endTime": "2021-11-19T13:40:14.115Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhGBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhGBAAAAAAAAA==/",
            "_etag": "\"17001289-0000-0100-0000-6197ca400000\"",
            "_attachments": "attachments/",
            "_ts": 1637337664
        },
        {
            "id": "b3490c208a604f76ba24033ab58ea4d39296e8a80f1269634c07f9ffb60a9d60",
            "conversationId": "b3490c208a604f76ba24033ab58ea4d39296e8a80f1269634c07f9ffb60a9d60",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T13:59:51.695Z",
            "endTime": "2021-11-19T13:59:51.695Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhHBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhHBAAAAAAAAA==/",
            "_etag": "\"17001989-0000-0100-0000-6197ca400000\"",
            "_attachments": "attachments/",
            "_ts": 1637337664
        },
        {
            "id": "a15d7ddb67b3865b81dba974d743b4669d903885fb1943b0109b29a5fd72a651",
            "conversationId": "a15d7ddb67b3865b81dba974d743b4669d903885fb1943b0109b29a5fd72a651",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 32,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Fever"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T14:30:53.134Z",
            "endTime": "2021-11-19T14:31:29.335Z",
            "timeSpent": 36,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhIBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhIBAAAAAAAAA==/",
            "_etag": "\"170050a0-0000-0100-0000-6197d8110000\"",
            "_attachments": "attachments/",
            "_ts": 1637341201
        },
        {
            "id": "15e2fadd0d0f45afd483b6c415997fc51488496830ca94aa13f0184036d5ec28",
            "conversationId": "15e2fadd0d0f45afd483b6c415997fc51488496830ca94aa13f0184036d5ec28",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T15:14:02.848Z",
            "endTime": "2021-11-19T15:14:08.293Z",
            "timeSpent": 5,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhJBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhJBAAAAAAAAA==/",
            "_etag": "\"170040a0-0000-0100-0000-6197d8110000\"",
            "_attachments": "attachments/",
            "_ts": 1637341201
        },
        {
            "id": "eec5649c1890626506152027418dbdd3b99f01eca3e7e43e99c9c7ebbe156b30",
            "conversationId": "eec5649c1890626506152027418dbdd3b99f01eca3e7e43e99c9c7ebbe156b30",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T15:14:17.752Z",
            "endTime": "2021-11-19T15:14:17.752Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhKBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhKBAAAAAAAAA==/",
            "_etag": "\"170041a0-0000-0100-0000-6197d8110000\"",
            "_attachments": "attachments/",
            "_ts": 1637341201
        },
        {
            "id": "bdc4ca7f416242c2c9d67c8a4afe83e15f285c175ca31633804dd308b1ab19cd",
            "conversationId": "bdc4ca7f416242c2c9d67c8a4afe83e15f285c175ca31633804dd308b1ab19cd",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 23,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Eye pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try Self-Care",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-19T15:34:01.046Z",
            "endTime": "2021-11-19T15:57:14.315Z",
            "timeSpent": 1393,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhLBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhLBAAAAAAAAA==/",
            "_etag": "\"1700aebd-0000-0100-0000-6197e6220000\"",
            "_attachments": "attachments/",
            "_ts": 1637344802
        },
        {
            "id": "579168ae53bc840276da22742223740b7bf2e614aeb4bcf2643eb43d54af7bca",
            "conversationId": "579168ae53bc840276da22742223740b7bf2e614aeb4bcf2643eb43d54af7bca",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 23,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T15:27:16.927Z",
            "endTime": "2021-11-19T15:31:22.647Z",
            "timeSpent": 246,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhMBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhMBAAAAAAAAA==/",
            "_etag": "\"1700adbd-0000-0100-0000-6197e6220000\"",
            "_attachments": "attachments/",
            "_ts": 1637344802
        },
        {
            "id": "d116e754b20241727c61e3cf7ec20c5e5f60561c91f22bc0ead4dcc01628e0b6",
            "conversationId": "d116e754b20241727c61e3cf7ec20c5e5f60561c91f22bc0ead4dcc01628e0b6",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-19T15:31:34.367Z",
            "endTime": "2021-11-19T15:32:49.064Z",
            "timeSpent": 75,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhNBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhNBAAAAAAAAA==/",
            "_etag": "\"170051a0-0000-0100-0000-6197d8110000\"",
            "_attachments": "attachments/",
            "_ts": 1637341201
        },
        {
            "id": "7b2cc2516c5e58507763b0fbc140daf19b5474f71e9577f8af2a2b49eae3dd16",
            "conversationId": "7b2cc2516c5e58507763b0fbc140daf19b5474f71e9577f8af2a2b49eae3dd16",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 23,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Eye pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try Self-Care",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2021-11-19T16:06:31.852Z",
            "endTime": "2021-11-19T16:10:52.089Z",
            "timeSpent": 260,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhOBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhOBAAAAAAAAA==/",
            "_etag": "\"17009ebd-0000-0100-0000-6197e6220000\"",
            "_attachments": "attachments/",
            "_ts": 1637344802
        },
        {
            "id": "f10becdc97f8c55a415ad633c8f9d0e3a5a0afea45cde5fedb5e6ccefe60bb92",
            "conversationId": "f10becdc97f8c55a415ad633c8f9d0e3a5a0afea45cde5fedb5e6ccefe60bb92",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 43,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will contact my clinic for something other than an appointment",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2021-11-19T16:32:51.507Z",
            "endTime": "2021-11-19T16:35:22.987Z",
            "timeSpent": 151,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhPBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhPBAAAAAAAAA==/",
            "_etag": "\"17009cbd-0000-0100-0000-6197e6220000\"",
            "_attachments": "attachments/",
            "_ts": 1637344802
        },
        {
            "id": "5e976a27b557133706a5b8f46b4ed4565b70f2b1bea53d2b632b6f4099d7451d",
            "conversationId": "5e976a27b557133706a5b8f46b4ed4565b70f2b1bea53d2b632b6f4099d7451d",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 23,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2021-11-19T16:55:41.014Z",
            "endTime": "2021-11-19T16:58:07.299Z",
            "timeSpent": 146,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhQBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhQBAAAAAAAAA==/",
            "_etag": "\"17003adf-0000-0100-0000-6197f4320000\"",
            "_attachments": "attachments/",
            "_ts": 1637348402
        },
        {
            "id": "f570a001e9549b85c159312869178a7902b8dab4fd7127ee468d408e7b3e3ba1",
            "conversationId": "f570a001e9549b85c159312869178a7902b8dab4fd7127ee468d408e7b3e3ba1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T16:52:43.622Z",
            "endTime": "2021-11-19T16:52:46.762Z",
            "timeSpent": 3,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhRBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhRBAAAAAAAAA==/",
            "_etag": "\"170033df-0000-0100-0000-6197f4320000\"",
            "_attachments": "attachments/",
            "_ts": 1637348402
        },
        {
            "id": "cf43e3b6f493d3264c8c65cd3c32367aa052bba079c58ba11afe4e581372b11b",
            "conversationId": "cf43e3b6f493d3264c8c65cd3c32367aa052bba079c58ba11afe4e581372b11b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T16:53:03.062Z",
            "endTime": "2021-11-19T16:53:03.062Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhSBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhSBAAAAAAAAA==/",
            "_etag": "\"170034df-0000-0100-0000-6197f4320000\"",
            "_attachments": "attachments/",
            "_ts": 1637348402
        },
        {
            "id": "6763bab93b45c8a727bcd2704259bedde3d5e5102e84b9aaf2f75d74d8645e8f",
            "conversationId": "6763bab93b45c8a727bcd2704259bedde3d5e5102e84b9aaf2f75d74d8645e8f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T16:53:21.154Z",
            "endTime": "2021-11-19T16:53:21.154Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhTBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhTBAAAAAAAAA==/",
            "_etag": "\"170036df-0000-0100-0000-6197f4320000\"",
            "_attachments": "attachments/",
            "_ts": 1637348402
        },
        {
            "id": "5e42ac5df0a204085d8ed55ae58267ecc1473b2913ba7a7d3ab817fe755f21e7",
            "conversationId": "5e42ac5df0a204085d8ed55ae58267ecc1473b2913ba7a7d3ab817fe755f21e7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T16:53:10.26Z",
            "endTime": "2021-11-19T16:53:10.26Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhUBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhUBAAAAAAAAA==/",
            "_etag": "\"170035df-0000-0100-0000-6197f4320000\"",
            "_attachments": "attachments/",
            "_ts": 1637348402
        },
        {
            "id": "d1c9c1fcdd16b514f19441b85e57f81fd57ab18b80a037ac5b141bc3ee38d3e7",
            "conversationId": "d1c9c1fcdd16b514f19441b85e57f81fd57ab18b80a037ac5b141bc3ee38d3e7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Bloating"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T16:53:16.888Z",
            "endTime": "2021-11-19T16:53:30.576Z",
            "timeSpent": 14,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhVBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhVBAAAAAAAAA==/",
            "_etag": "\"170037df-0000-0100-0000-6197f4320000\"",
            "_attachments": "attachments/",
            "_ts": 1637348402
        },
        {
            "id": "af2fdc14aa92976e9024516d758bc560a200fcca3fcfcd946d25bbfd5a1d87aa",
            "conversationId": "af2fdc14aa92976e9024516d758bc560a200fcca3fcfcd946d25bbfd5a1d87aa",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T16:53:47.329Z",
            "endTime": "2021-11-19T16:53:47.329Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhWBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhWBAAAAAAAAA==/",
            "_etag": "\"170039df-0000-0100-0000-6197f4320000\"",
            "_attachments": "attachments/",
            "_ts": 1637348402
        },
        {
            "id": "30c384ea1b0faf25cb73bf57249bd9316efa62d324ce03007da0156d885b35b3",
            "conversationId": "30c384ea1b0faf25cb73bf57249bd9316efa62d324ce03007da0156d885b35b3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T16:53:47.331Z",
            "endTime": "2021-11-19T16:53:47.331Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhXBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhXBAAAAAAAAA==/",
            "_etag": "\"170038df-0000-0100-0000-6197f4320000\"",
            "_attachments": "attachments/",
            "_ts": 1637348402
        },
        {
            "id": "5bc68744b7dc868b3d01e8c741048d60a8a547216aff91fa92adc0a8d4be346a",
            "conversationId": "5bc68744b7dc868b3d01e8c741048d60a8a547216aff91fa92adc0a8d4be346a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T16:42:03.246Z",
            "endTime": "2021-11-19T17:06:58.042Z",
            "timeSpent": 1495,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhYBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhYBAAAAAAAAA==/",
            "_etag": "\"170029df-0000-0100-0000-6197f4320000\"",
            "_attachments": "attachments/",
            "_ts": 1637348402
        },
        {
            "id": "3a92c79965a9dceefbc98eeb6f19db478cbd77ffdd24e25a403d47090b09f0d2",
            "conversationId": "3a92c79965a9dceefbc98eeb6f19db478cbd77ffdd24e25a403d47090b09f0d2",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-19T16:41:56.343Z",
            "endTime": "2021-11-19T16:42:14.678Z",
            "timeSpent": 18,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhZBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhZBAAAAAAAAA==/",
            "_etag": "\"1700abbd-0000-0100-0000-6197e6220000\"",
            "_attachments": "attachments/",
            "_ts": 1637344802
        },
        {
            "id": "93386bb4e4a72f88578bfca7b1c91f95d97abaed3f27c64ec791b6e11f4e67d8",
            "conversationId": "93386bb4e4a72f88578bfca7b1c91f95d97abaed3f27c64ec791b6e11f4e67d8",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T16:42:48.971Z",
            "endTime": "2021-11-19T16:42:48.971Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhaBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhaBAAAAAAAAA==/",
            "_etag": "\"170031df-0000-0100-0000-6197f4320000\"",
            "_attachments": "attachments/",
            "_ts": 1637348402
        },
        {
            "id": "786b261869e68eb9adb4c8d0199f2342c60eff2069505389c2d39d9830aa432c",
            "conversationId": "786b261869e68eb9adb4c8d0199f2342c60eff2069505389c2d39d9830aa432c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 34,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Fast heartbeat"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-19T16:43:57.356Z",
            "endTime": "2021-11-19T16:44:23.395Z",
            "timeSpent": 26,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhbBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhbBAAAAAAAAA==/",
            "_etag": "\"170032df-0000-0100-0000-6197f4320000\"",
            "_attachments": "attachments/",
            "_ts": 1637348402
        },
        {
            "id": "55c3dcc057bb502cb5538d32c59774de633dc462e6deead4c4e637a9b542ee92",
            "conversationId": "55c3dcc057bb502cb5538d32c59774de633dc462e6deead4c4e637a9b542ee92",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-19T16:45:33.934Z",
            "endTime": "2021-11-19T16:45:40.508Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhcBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhcBAAAAAAAAA==/",
            "_etag": "\"1700a4bd-0000-0100-0000-6197e6220000\"",
            "_attachments": "attachments/",
            "_ts": 1637344802
        },
        {
            "id": "43853dda7e4a52dff08e5936152cc946d6cc679ed3e787d5d9a95035e0c399cc",
            "conversationId": "43853dda7e4a52dff08e5936152cc946d6cc679ed3e787d5d9a95035e0c399cc",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-19T16:36:50.113Z",
            "endTime": "2021-11-19T16:36:56.568Z",
            "timeSpent": 6,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhdBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhdBAAAAAAAAA==/",
            "_etag": "\"17009fbd-0000-0100-0000-6197e6220000\"",
            "_attachments": "attachments/",
            "_ts": 1637344802
        },
        {
            "id": "0b50c4ebbdf1956df1b91cec32b54eae1c8ce013bdec59951ef20ef53a9f0041",
            "conversationId": "0b50c4ebbdf1956df1b91cec32b54eae1c8ce013bdec59951ef20ef53a9f0041",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T17:44:20.605Z",
            "endTime": "2021-11-19T17:44:26.502Z",
            "timeSpent": 6,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIheBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIheBAAAAAAAAA==/",
            "_etag": "\"17006efa-0000-0100-0000-619802410000\"",
            "_attachments": "attachments/",
            "_ts": 1637352001
        },
        {
            "id": "cee789be1f3ee9004a2608c58808297b27e8cbb31374c18f1e4b75ee0a019f1d",
            "conversationId": "cee789be1f3ee9004a2608c58808297b27e8cbb31374c18f1e4b75ee0a019f1d",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 25,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try Self-Care",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-19T16:59:22.372Z",
            "endTime": "2021-11-19T17:02:38.198Z",
            "timeSpent": 196,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhfBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhfBAAAAAAAAA==/",
            "_etag": "\"170028df-0000-0100-0000-6197f4320000\"",
            "_attachments": "attachments/",
            "_ts": 1637348402
},
    
        {
            "id": "7a85738c751c7f1c2bbcdb686f0cd1c10091bf94a8abdc05cbc66feb9533db33",
            "conversationId": "7a85738c751c7f1c2bbcdb686f0cd1c10091bf94a8abdc05cbc66feb9533db33",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 35,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will try Self-Care",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-19T17:12:52.642Z",
            "endTime": "2021-11-19T17:25:56.686Z",
            "timeSpent": 784,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhgBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhgBAAAAAAAAA==/",
            "_etag": "\"17002fdf-0000-0100-0000-6197f4320000\"",
            "_attachments": "attachments/",
            "_ts": 1637348402
        },
        {
            "id": "00bf936a46aaab32b2aee8e8399883d37adfdc766b8fbe8de2bdeaa87d465b65",
            "conversationId": "00bf936a46aaab32b2aee8e8399883d37adfdc766b8fbe8de2bdeaa87d465b65",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-19T17:12:40.436Z",
            "endTime": "2021-11-19T17:12:47.524Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhhBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhhBAAAAAAAAA==/",
            "_etag": "\"170030df-0000-0100-0000-6197f4320000\"",
            "_attachments": "attachments/",
            "_ts": 1637348402
        },
        {
            "id": "1e4ec304ae70ada060ec2c790be27feff36e8c2845a0d0fd4c9182c93f1997c3",
            "conversationId": "1e4ec304ae70ada060ec2c790be27feff36e8c2845a0d0fd4c9182c93f1997c3",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 65,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T17:00:02.031Z",
            "endTime": "2021-11-19T17:00:33.916Z",
            "timeSpent": 32,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhiBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhiBAAAAAAAAA==/",
            "_etag": "\"170027df-0000-0100-0000-6197f4320000\"",
            "_attachments": "attachments/",
            "_ts": 1637348402
        },
        {
            "id": "53f25081513f6a66233eab567241fe7df9993b38537f7fac1b05edb1e09b86db",
            "conversationId": "53f25081513f6a66233eab567241fe7df9993b38537f7fac1b05edb1e09b86db",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T17:07:29.591Z",
            "endTime": "2021-11-19T17:07:29.591Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhjBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhjBAAAAAAAAA==/",
            "_etag": "\"17002adf-0000-0100-0000-6197f4320000\"",
            "_attachments": "attachments/",
            "_ts": 1637348402
        },
        {
            "id": "fac847763de9d4da493d51901ea4132e4ded60628fc4536ee2108e3aff771582",
            "conversationId": "fac847763de9d4da493d51901ea4132e4ded60628fc4536ee2108e3aff771582",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 12,
            "ageGroup": "12-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T18:11:16.738Z",
            "endTime": "2021-11-19T18:12:19.023Z",
            "timeSpent": 62,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhkBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhkBAAAAAAAAA==/",
            "_etag": "\"17006cfa-0000-0100-0000-619802410000\"",
            "_attachments": "attachments/",
            "_ts": 1637352001
        },
        {
            "id": "04a23980fb8e78f247551dc37a1de9f12982457d81c8af1cbf29494946092a48",
            "conversationId": "04a23980fb8e78f247551dc37a1de9f12982457d81c8af1cbf29494946092a48",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 32,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Female"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T18:41:35.807Z",
            "endTime": "2021-11-19T18:42:39.4Z",
            "timeSpent": 64,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhlBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhlBAAAAAAAAA==/",
            "_etag": "\"1800780f-0000-0100-0000-619810530000\"",
            "_attachments": "attachments/",
            "_ts": 1637355603
        },
        {
            "id": "4221949328c32802f17646472866fc4a9580bc8b08868e94af0fdb8a3159d697",
            "conversationId": "4221949328c32802f17646472866fc4a9580bc8b08868e94af0fdb8a3159d697",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 32,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T18:42:51.774Z",
            "endTime": "2021-11-19T18:43:07.493Z",
            "timeSpent": 16,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhmBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhmBAAAAAAAAA==/",
            "_etag": "\"1800790f-0000-0100-0000-619810530000\"",
            "_attachments": "attachments/",
            "_ts": 1637355603
        },
        {
            "id": "06ddb8746cec4340bf7aa17c710cb8637b40ce4d581255b1a27bfa23079d83be",
            "conversationId": "06ddb8746cec4340bf7aa17c710cb8637b40ce4d581255b1a27bfa23079d83be",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T20:20:41.309Z",
            "endTime": "2021-11-19T20:32:10.804Z",
            "timeSpent": 689,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhnBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhnBAAAAAAAAA==/",
            "_etag": "\"1800403b-0000-0100-0000-61982c730000\"",
            "_attachments": "attachments/",
            "_ts": 1637362803
        },
        {
            "id": "f0f06fb29dbc7be0b664cda89b8b0927a66dac63521c108c2719219b36e75fea",
            "conversationId": "f0f06fb29dbc7be0b664cda89b8b0927a66dac63521c108c2719219b36e75fea",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-19T20:20:31.1Z",
            "endTime": "2021-11-19T20:20:31.1Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhoBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhoBAAAAAAAAA==/",
            "_etag": "\"18002d25-0000-0100-0000-61981e610000\"",
            "_attachments": "attachments/",
            "_ts": 1637359201
        },
        {
            "id": "178789a33a129b1906a2b15adb2d646788a1cf323018e8ec3bebdc07da24b8e0",
            "conversationId": "178789a33a129b1906a2b15adb2d646788a1cf323018e8ec3bebdc07da24b8e0",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T08:55:05.082Z",
            "endTime": "2021-11-22T08:55:05.082Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhpBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhpBAAAAAAAAA==/",
            "_etag": "\"1d00024f-0000-0100-0000-619b78310000\"",
            "_attachments": "attachments/",
            "_ts": 1637578801
        },
        {
            "id": "36a4258bb3084b7d862293276a72bb704be73442d2615f8c2f0b90f3da5aa914",
            "conversationId": "36a4258bb3084b7d862293276a72bb704be73442d2615f8c2f0b90f3da5aa914",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T08:03:13.235Z",
            "endTime": "2021-11-22T08:03:22.508Z",
            "timeSpent": 9,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhqBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhqBAAAAAAAAA==/",
            "_etag": "\"1d00724a-0000-0100-0000-619b6a210000\"",
            "_attachments": "attachments/",
            "_ts": 1637575201
        },
        {
            "id": "6449664fadef7e2443b0b65368f9712e68eca511c801e36ef9bb49b9bbbcbd63",
            "conversationId": "6449664fadef7e2443b0b65368f9712e68eca511c801e36ef9bb49b9bbbcbd63",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 23,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T10:02:59.902Z",
            "endTime": "2021-11-22T10:05:23.594Z",
            "timeSpent": 144,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhrBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhrBAAAAAAAAA==/",
            "_etag": "\"1d00c15b-0000-0100-0000-619b86410000\"",
            "_attachments": "attachments/",
            "_ts": 1637582401
        },
        {
            "id": "cfa1c212c81c34589b2a73ac699df6881d6d50f9d913e8c14f9b9e5d6a5582db",
            "conversationId": "cfa1c212c81c34589b2a73ac699df6881d6d50f9d913e8c14f9b9e5d6a5582db",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [
                "##edit@4@Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-22T12:10:29.694Z",
            "endTime": "2021-11-22T12:14:15.751Z",
            "timeSpent": 226,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhsBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhsBAAAAAAAAA==/",
            "_etag": "\"1d00547a-0000-0100-0000-619ba29e0000\"",
            "_attachments": "attachments/",
            "_ts": 1637589662
        },
        {
            "id": "eb098d6b5d97b73bfdbca5d78951237a89d818dd46de78800d0b3e3c30a8011d",
            "conversationId": "eb098d6b5d97b73bfdbca5d78951237a89d818dd46de78800d0b3e3c30a8011d",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [
                "Obesity",
                "Smoking cigarettes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-22T12:14:53.982Z",
            "endTime": "2021-11-22T12:16:17.853Z",
            "timeSpent": 84,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhtBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhtBAAAAAAAAA==/",
            "_etag": "\"1d00557a-0000-0100-0000-619ba29e0000\"",
            "_attachments": "attachments/",
            "_ts": 1637589662
        },
        {
            "id": "15f818fae57e4f061252bf29600f250429c69495c5680a10e193dc4d054c4c0c",
            "conversationId": "15f818fae57e4f061252bf29600f250429c69495c5680a10e193dc4d054c4c0c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 33,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to Urgent Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T12:16:27.128Z",
            "endTime": "2021-11-22T12:17:17.289Z",
            "timeSpent": 50,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhuBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhuBAAAAAAAAA==/",
            "_etag": "\"1d00567a-0000-0100-0000-619ba29e0000\"",
            "_attachments": "attachments/",
            "_ts": 1637589662
        },
        {
            "id": "88ad44bf1606d1471934f2b49e74fe8d9c9f0426f9bcd5a164c124d777b42be9",
            "conversationId": "88ad44bf1606d1471934f2b49e74fe8d9c9f0426f9bcd5a164c124d777b42be9",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 22,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Vomiting"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Vomiting,Chest pain,",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T12:05:41.075Z",
            "endTime": "2021-11-22T12:06:15.139Z",
            "timeSpent": 34,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhvBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhvBAAAAAAAAA==/",
            "_etag": "\"1d00517a-0000-0100-0000-619ba29e0000\"",
            "_attachments": "attachments/",
            "_ts": 1637589662
        },
        {
            "id": "8036ad73c82c9ceb93e4bbc26caa19a77225f3452b55428c5ef8740eac589833",
            "conversationId": "8036ad73c82c9ceb93e4bbc26caa19a77225f3452b55428c5ef8740eac589833",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T13:51:25.105Z",
            "endTime": "2021-11-22T13:51:27.222Z",
            "timeSpent": 2,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhwBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhwBAAAAAAAAA==/",
            "_etag": "\"1d0097a5-0000-0100-0000-619bbe820000\"",
            "_attachments": "attachments/",
            "_ts": 1637596802
        },
        {
            "id": "95808091b4b356c7100ca9b316135a083a6f4e2774c5f015f8ade9d87ba3ce81",
            "conversationId": "95808091b4b356c7100ca9b316135a083a6f4e2774c5f015f8ade9d87ba3ce81",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 45,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T13:51:31.102Z",
            "endTime": "2021-11-22T13:51:41.122Z",
            "timeSpent": 10,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhxBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhxBAAAAAAAAA==/",
            "_etag": "\"1d0098a5-0000-0100-0000-619bbe820000\"",
            "_attachments": "attachments/",
            "_ts": 1637596802
        },
        {
            "id": "6ef1b1bb4f38b23d5ab7c056ab0b0feefe566e2f089a1153a5df75bd9f41d573",
            "conversationId": "6ef1b1bb4f38b23d5ab7c056ab0b0feefe566e2f089a1153a5df75bd9f41d573",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T14:43:51.232Z",
            "endTime": "2021-11-22T14:43:51.232Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhyBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhyBAAAAAAAAA==/",
            "_etag": "\"1d00e3b9-0000-0100-0000-619bcc910000\"",
            "_attachments": "attachments/",
            "_ts": 1637600401
        },
        {
            "id": "16fab8d983b9d9e1240a905662fc1c34393a4b049372a306a442122e30a08ee0",
            "conversationId": "16fab8d983b9d9e1240a905662fc1c34393a4b049372a306a442122e30a08ee0",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T16:36:56.931Z",
            "endTime": "2021-11-22T16:37:04.177Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhzBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhzBAAAAAAAAA==/",
            "_etag": "\"1d0060e7-0000-0100-0000-619be8f00000\"",
            "_attachments": "attachments/",
            "_ts": 1637607664
        },
        {
            "id": "d0920646e7af2850272a71c59e1fbeaa1403f725f0e7d69d8145c04ad6ecf57e",
            "conversationId": "d0920646e7af2850272a71c59e1fbeaa1403f725f0e7d69d8145c04ad6ecf57e",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T16:36:50.922Z",
            "endTime": "2021-11-22T16:36:50.922Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh0BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh0BAAAAAAAAA==/",
            "_etag": "\"1d005fe7-0000-0100-0000-619be8f00000\"",
            "_attachments": "attachments/",
            "_ts": 1637607664
        },
        {
            "id": "68432cc4480eba0ddfeca591e160a1e72bb24f3ed3e8e08422e42da8cf4837fe",
            "conversationId": "68432cc4480eba0ddfeca591e160a1e72bb24f3ed3e8e08422e42da8cf4837fe",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T16:46:26.821Z",
            "endTime": "2021-11-22T16:46:53.933Z",
            "timeSpent": 27,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh1BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh1BAAAAAAAAA==/",
            "_etag": "\"1d005ee7-0000-0100-0000-619be8f00000\"",
            "_attachments": "attachments/",
            "_ts": 1637607664
        },
        {
            "id": "6f243ea8ca8463d6f405f37ee78df0d377bf8404d37edcb3cff2a103b0d80819",
            "conversationId": "6f243ea8ca8463d6f405f37ee78df0d377bf8404d37edcb3cff2a103b0d80819",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T19:19:30.154Z",
            "endTime": "2021-11-22T19:19:30.154Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh2BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh2BAAAAAAAAA==/",
            "_etag": "\"1e002e12-0000-0100-0000-619c04d20000\"",
            "_attachments": "attachments/",
            "_ts": 1637614802
        },
        {
            "id": "44f474c619da979ca811606d62f00118030617d6e9bd3b85819527d4fa317ed1",
            "conversationId": "44f474c619da979ca811606d62f00118030617d6e9bd3b85819527d4fa317ed1",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T19:19:42.967Z",
            "endTime": "2021-11-22T19:19:42.967Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh3BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh3BAAAAAAAAA==/",
            "_etag": "\"1e002f12-0000-0100-0000-619c04d20000\"",
            "_attachments": "attachments/",
            "_ts": 1637614802
        },
        {
            "id": "72b7d9782a44be453434790bb856f643ab88aeba9807dadf38e2572dc41b1ff3",
            "conversationId": "72b7d9782a44be453434790bb856f643ab88aeba9807dadf38e2572dc41b1ff3",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T19:32:40.828Z",
            "endTime": "2021-11-22T19:32:40.828Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh4BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh4BAAAAAAAAA==/",
            "_etag": "\"1e00682e-0000-0100-0000-619c12e20000\"",
            "_attachments": "attachments/",
            "_ts": 1637618402
        },
        {
            "id": "96cbea63dfd556856bca9cbe7d6815e5af007fd12e7a850ea436ff9f89e26d16",
            "conversationId": "96cbea63dfd556856bca9cbe7d6815e5af007fd12e7a850ea436ff9f89e26d16",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-22T19:33:32.05Z",
            "endTime": "2021-11-22T19:33:44.613Z",
            "timeSpent": 13,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh5BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh5BAAAAAAAAA==/",
            "_etag": "\"1e003112-0000-0100-0000-619c04d20000\"",
            "_attachments": "attachments/",
            "_ts": 1637614802
        },
        {
            "id": "c710b7a2eda7a02bc982d7c8a1b58f042650ce63a37cb311bcffc0ce2146c553",
            "conversationId": "c710b7a2eda7a02bc982d7c8a1b58f042650ce63a37cb311bcffc0ce2146c553",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T19:45:19.466Z",
            "endTime": "2021-11-22T19:45:19.466Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh6BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh6BAAAAAAAAA==/",
            "_etag": "\"1e00702e-0000-0100-0000-619c12e20000\"",
            "_attachments": "attachments/",
            "_ts": 1637618402
        },
        {
            "id": "5e9539b1b0c6f8487cc4ac7ede15514e4612dd4a907e032a91e46a5eb2be13d9",
            "conversationId": "5e9539b1b0c6f8487cc4ac7ede15514e4612dd4a907e032a91e46a5eb2be13d9",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T19:46:18.452Z",
            "endTime": "2021-11-22T19:46:18.452Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh7BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh7BAAAAAAAAA==/",
            "_etag": "\"1e00722e-0000-0100-0000-619c12e20000\"",
            "_attachments": "attachments/",
            "_ts": 1637618402
        },
        {
            "id": "8f788369f9861daeb575305211963aed0511235131a5cd7dcdbdb1a14845fb02",
            "conversationId": "8f788369f9861daeb575305211963aed0511235131a5cd7dcdbdb1a14845fb02",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T19:24:44.409Z",
            "endTime": "2021-11-22T19:24:44.409Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh8BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh8BAAAAAAAAA==/",
            "_etag": "\"1e003612-0000-0100-0000-619c04d20000\"",
            "_attachments": "attachments/",
            "_ts": 1637614802
        },
        {
            "id": "6667987bbb30d74e1a71e221c3f188730d8b703aed1cdbd2f7d94a9ed2b8b6f5",
            "conversationId": "6667987bbb30d74e1a71e221c3f188730d8b703aed1cdbd2f7d94a9ed2b8b6f5",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T19:34:54.631Z",
            "endTime": "2021-11-22T19:34:54.631Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh9BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh9BAAAAAAAAA==/",
            "_etag": "\"1e00652e-0000-0100-0000-619c12e20000\"",
            "_attachments": "attachments/",
            "_ts": 1637618402
        },
        {
            "id": "24079a893ad660ff9674b6bf1001d9a359ef5f6897407203eaaf14dc960bd0b2",
            "conversationId": "24079a893ad660ff9674b6bf1001d9a359ef5f6897407203eaaf14dc960bd0b2",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T19:45:28.58Z",
            "endTime": "2021-11-22T19:45:28.58Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh+BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh+BAAAAAAAAA==/",
            "_etag": "\"1e006f2e-0000-0100-0000-619c12e20000\"",
            "_attachments": "attachments/",
            "_ts": 1637618402
        },
        {
            "id": "ce2b5e6a3867b63c174e69b2c437b1caf15f078ad375697c25f6cafe2bdc26dd",
            "conversationId": "ce2b5e6a3867b63c174e69b2c437b1caf15f078ad375697c25f6cafe2bdc26dd",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T19:46:03.357Z",
            "endTime": "2021-11-22T19:46:03.357Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh-BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh-BAAAAAAAAA==/",
            "_etag": "\"1e00712e-0000-0100-0000-619c12e20000\"",
            "_attachments": "attachments/",
            "_ts": 1637618402
        },
        {
            "id": "52d40f1c8e28e9f62b753c44b11100ef527f0f9f97d51601f759d84588a0edb4",
            "conversationId": "52d40f1c8e28e9f62b753c44b11100ef527f0f9f97d51601f759d84588a0edb4",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T20:45:42.567Z",
            "endTime": "2021-11-22T20:45:42.567Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiABAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiABAAAAAAAAA==/",
            "_etag": "\"1e00923d-0000-0100-0000-619c20f30000\"",
            "_attachments": "attachments/",
            "_ts": 1637622003
        },
        {
            "id": "317ac29377fe211ce0c30fb78fe57fe11cbfd8cf22c10e40d633588d10e0f748",
            "conversationId": "317ac29377fe211ce0c30fb78fe57fe11cbfd8cf22c10e40d633588d10e0f748",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T20:46:10.265Z",
            "endTime": "2021-11-22T20:46:10.265Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiBBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiBBAAAAAAAAA==/",
            "_etag": "\"1e00933d-0000-0100-0000-619c20f30000\"",
            "_attachments": "attachments/",
            "_ts": 1637622003
        },
        {
            "id": "cef65c3fe59c605b00aa440dc27a29458c142427fc4f5d8fbd7714abb1cc4e07",
            "conversationId": "cef65c3fe59c605b00aa440dc27a29458c142427fc4f5d8fbd7714abb1cc4e07",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T20:58:45.112Z",
            "endTime": "2021-11-22T20:58:45.112Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiCBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiCBAAAAAAAAA==/",
            "_etag": "\"1e00953d-0000-0100-0000-619c20f30000\"",
            "_attachments": "attachments/",
            "_ts": 1637622003
        },
        {
            "id": "15a60c51be3c17cfacf4d83fb6dc35fc76ad862c6db099cfdaa58182f85a1dbf",
            "conversationId": "15a60c51be3c17cfacf4d83fb6dc35fc76ad862c6db099cfdaa58182f85a1dbf",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T21:38:39.89Z",
            "endTime": "2021-11-22T21:38:39.89Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiDBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiDBAAAAAAAAA==/",
            "_etag": "\"1e00c747-0000-0100-0000-619c2f010000\"",
            "_attachments": "attachments/",
            "_ts": 1637625601
        },
        {
            "id": "2782c394bde34309b026b39d18647a4b7742396e780b74cd047b43b6f13c0497",
            "conversationId": "2782c394bde34309b026b39d18647a4b7742396e780b74cd047b43b6f13c0497",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T21:40:38.867Z",
            "endTime": "2021-11-22T21:40:38.867Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiEBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiEBAAAAAAAAA==/",
            "_etag": "\"1e00c847-0000-0100-0000-619c2f010000\"",
            "_attachments": "attachments/",
            "_ts": 1637625601
        },
        {
            "id": "3a414799a34b8c7c8297d5066ae480758c19d3d24b3558c0d64a3dfa95e8cfcd",
            "conversationId": "3a414799a34b8c7c8297d5066ae480758c19d3d24b3558c0d64a3dfa95e8cfcd",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T21:46:41.315Z",
            "endTime": "2021-11-22T21:46:41.315Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiFBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiFBAAAAAAAAA==/",
            "_etag": "\"1e00c947-0000-0100-0000-619c2f010000\"",
            "_attachments": "attachments/",
            "_ts": 1637625601
        },
        {
            "id": "72923244fbca77c4b7b64f6f1a11189cceb986047afb9b72aa78ca9631839f9f",
            "conversationId": "72923244fbca77c4b7b64f6f1a11189cceb986047afb9b72aa78ca9631839f9f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T21:12:48.728Z",
            "endTime": "2021-11-22T21:12:48.728Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiGBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiGBAAAAAAAAA==/",
            "_etag": "\"1e00913d-0000-0100-0000-619c20f30000\"",
            "_attachments": "attachments/",
            "_ts": 1637622003
        },
        {
            "id": "084d3a58adf3463e0b75b951667cbb86c0f1fb84b3a82cc078ebe3ea718be896",
            "conversationId": "084d3a58adf3463e0b75b951667cbb86c0f1fb84b3a82cc078ebe3ea718be896",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-22T21:57:21.232Z",
            "endTime": "2021-11-22T21:57:21.232Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiHBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiHBAAAAAAAAA==/",
            "_etag": "\"1e00ca47-0000-0100-0000-619c2f010000\"",
            "_attachments": "attachments/",
            "_ts": 1637625601
        },
        {
            "id": "23bfffceb38523e5f8369e12ca40b081f93684e039da87b374dea28617605355",
            "conversationId": "23bfffceb38523e5f8369e12ca40b081f93684e039da87b374dea28617605355",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T01:23:15.473Z",
            "endTime": "2021-11-23T01:23:15.473Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiIBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiIBAAAAAAAAA==/",
            "_etag": "\"1e00826a-0000-0100-0000-619c59310000\"",
            "_attachments": "attachments/",
            "_ts": 1637636401
        },
        {
            "id": "874b1ac7911aa12645f86edb893cdb8d688bf94202fccbb042bd87ee5d0fe29d",
            "conversationId": "874b1ac7911aa12645f86edb893cdb8d688bf94202fccbb042bd87ee5d0fe29d",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 22,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain",
                "Heartburn",
                "Fast heartbeat",
                "Palpitations"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": "I will try Self-Care",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-11-23T08:37:55.888Z",
            "endTime": "2021-11-23T08:40:10.169Z",
            "timeSpent": 134,
            "isNoteGenerated": "no",
            "npsGroup": "Passive",
            "npsScore": 8,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiJBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiJBAAAAAAAAA==/",
            "_etag": "\"1f00c4e8-0000-0100-0000-619cbba30000\"",
            "_attachments": "attachments/",
            "_ts": 1637661603
        },
        {
            "id": "f34f60484a176f8e6527b1f97ef7928732c05c18086900244429f8e9f5c38cee",
            "conversationId": "f34f60484a176f8e6527b1f97ef7928732c05c18086900244429f8e9f5c38cee",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 22,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will go to Urgent Care",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-23T08:24:44.469Z",
            "endTime": "2021-11-23T08:31:26.227Z",
            "timeSpent": 402,
            "isNoteGenerated": "no",
            "npsGroup": "Passive",
            "npsScore": 8,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiKBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiKBAAAAAAAAA==/",
            "_etag": "\"1f00c3e8-0000-0100-0000-619cbba30000\"",
            "_attachments": "attachments/",
            "_ts": 1637661603
        },
        {
            "id": "5428d23b2ebe0e9a9f082de36a58dd62174cdcb9f35c05b8c06bccc57cc677b9",
            "conversationId": "5428d23b2ebe0e9a9f082de36a58dd62174cdcb9f35c05b8c06bccc57cc677b9",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T13:07:09.776Z",
            "endTime": "2021-11-23T13:07:09.776Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiLBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiLBAAAAAAAAA==/",
            "_etag": "\"21001b61-0000-0100-0000-619d01f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637679602
        },
        {
            "id": "91ec7efe6d2f3e462de73cc473356ffe6a22b5a4be1bcd5bb5392169c79b1815",
            "conversationId": "91ec7efe6d2f3e462de73cc473356ffe6a22b5a4be1bcd5bb5392169c79b1815",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 23,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T13:11:01.171Z",
            "endTime": "2021-11-23T13:18:15.843Z",
            "timeSpent": 435,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiMBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiMBAAAAAAAAA==/",
            "_etag": "\"21001961-0000-0100-0000-619d01f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637679602
        },
        {
            "id": "9120e27fc4c89d9e81523d3c8e53018f752a5c591cc119f7f7c1badea4d8711c",
            "conversationId": "9120e27fc4c89d9e81523d3c8e53018f752a5c591cc119f7f7c1badea4d8711c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T14:47:19.09Z",
            "endTime": "2021-11-23T14:47:19.09Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiNBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiNBAAAAAAAAA==/",
            "_etag": "\"2100f3bc-0000-0100-0000-619d1e120000\"",
            "_attachments": "attachments/",
            "_ts": 1637686802
        },
        {
            "id": "07bca3a7539115012ec1c4b00f1d6873285f1351dc6364f051326d02ea230b7e",
            "conversationId": "07bca3a7539115012ec1c4b00f1d6873285f1351dc6364f051326d02ea230b7e",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T15:52:31.582Z",
            "endTime": "2021-11-23T15:54:39.902Z",
            "timeSpent": 128,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiOBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiOBAAAAAAAAA==/",
            "_etag": "\"22008b0f-0000-0100-0000-619d2cac0000\"",
            "_attachments": "attachments/",
            "_ts": 1637690540
        },
        {
            "id": "3b63a81b8c819ed859455bffae929cca14f96e3796c0ff903a83767f745122d0",
            "conversationId": "3b63a81b8c819ed859455bffae929cca14f96e3796c0ff903a83767f745122d0",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 43,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Fast heartbeat"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-23T15:02:57.496Z",
            "endTime": "2021-11-23T15:03:35.364Z",
            "timeSpent": 38,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiPBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiPBAAAAAAAAA==/",
            "_etag": "\"2100f0bc-0000-0100-0000-619d1e120000\"",
            "_attachments": "attachments/",
            "_ts": 1637686802
        },
        {
            "id": "f8185f3f6ea1f8ea15f38db3b9d543c282c9d1b9eed3bc676f07ac8a1becbd68",
            "conversationId": "f8185f3f6ea1f8ea15f38db3b9d543c282c9d1b9eed3bc676f07ac8a1becbd68",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-23T15:01:45.871Z",
            "endTime": "2021-11-23T15:01:52.078Z",
            "timeSpent": 6,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiQBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiQBAAAAAAAAA==/",
            "_etag": "\"2100f1bc-0000-0100-0000-619d1e120000\"",
            "_attachments": "attachments/",
            "_ts": 1637686802
        },
        {
            "id": "6b995a7b06a9ccc436abcdd794274806cfb72e77b2555980ef3a434b1ee826a8",
            "conversationId": "6b995a7b06a9ccc436abcdd794274806cfb72e77b2555980ef3a434b1ee826a8",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 54,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2021-11-23T15:47:25.583Z",
            "endTime": "2021-11-23T16:35:30.352Z",
            "timeSpent": 2885,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiRBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiRBAAAAAAAAA==/",
            "_etag": "\"2200f52d-0000-0100-0000-619d3a310000\"",
            "_attachments": "attachments/",
            "_ts": 1637694001
        },
        {
            "id": "cbeee57ea224ab3a1581e829f3b8bd07a0a03a3e0b6e06471b4f993753bc4ef6",
            "conversationId": "cbeee57ea224ab3a1581e829f3b8bd07a0a03a3e0b6e06471b4f993753bc4ef6",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T15:01:20.605Z",
            "endTime": "2021-11-23T15:01:20.605Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiSBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiSBAAAAAAAAA==/",
            "_etag": "\"2100f2bc-0000-0100-0000-619d1e120000\"",
            "_attachments": "attachments/",
            "_ts": 1637686802
        },
        {
            "id": "12b1250de57ed203fa381b720011f355ac89504c84e37a90a7d4f32b7335a8e9",
            "conversationId": "12b1250de57ed203fa381b720011f355ac89504c84e37a90a7d4f32b7335a8e9",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T16:51:16.83Z",
            "endTime": "2021-11-23T16:51:16.83Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiTBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiTBAAAAAAAAA==/",
            "_etag": "\"2200002e-0000-0100-0000-619d3a310000\"",
            "_attachments": "attachments/",
            "_ts": 1637694001
        },
        {
            "id": "3ad1ffbcfe3cbc927d859b934b5774aa18d15f162614afcca7cddde480a27bfb",
            "conversationId": "3ad1ffbcfe3cbc927d859b934b5774aa18d15f162614afcca7cddde480a27bfb",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T17:59:31.341Z",
            "endTime": "2021-11-23T17:59:31.341Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiUBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiUBAAAAAAAAA==/",
            "_etag": "\"2200f948-0000-0100-0000-619d48430000\"",
            "_attachments": "attachments/",
            "_ts": 1637697603
        },
        {
            "id": "74b0b87fec23667330578b6838699f324a5c629117d4514cf20f0a35769091cb",
            "conversationId": "74b0b87fec23667330578b6838699f324a5c629117d4514cf20f0a35769091cb",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T18:16:38.98Z",
            "endTime": "2021-11-23T18:16:38.98Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiVBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiVBAAAAAAAAA==/",
            "_etag": "\"2200f348-0000-0100-0000-619d48430000\"",
            "_attachments": "attachments/",
            "_ts": 1637697603
        },
        {
            "id": "b6346ef6cea1dcaaa3c4fc21900b91885c8cac8447e37c54ef5e3f309b4f1e1d",
            "conversationId": "b6346ef6cea1dcaaa3c4fc21900b91885c8cac8447e37c54ef5e3f309b4f1e1d",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T18:23:17.176Z",
            "endTime": "2021-11-23T18:23:17.176Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiWBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiWBAAAAAAAAA==/",
            "_etag": "\"2200f448-0000-0100-0000-619d48430000\"",
            "_attachments": "attachments/",
            "_ts": 1637697603
        },
        {
            "id": "f6bc05e48cbcf2b6e6d2ed54699d09e581fca94844aad9ca92c8e8670f070e71",
            "conversationId": "f6bc05e48cbcf2b6e6d2ed54699d09e581fca94844aad9ca92c8e8670f070e71",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T18:25:06.476Z",
            "endTime": "2021-11-23T18:25:06.476Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiXBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiXBAAAAAAAAA==/",
            "_etag": "\"2200f548-0000-0100-0000-619d48430000\"",
            "_attachments": "attachments/",
            "_ts": 1637697603
        },
        {
            "id": "ee79079c80dc382d3a68bdd42f1999f63139ee1cd37f7be767a81def9620b35a",
            "conversationId": "ee79079c80dc382d3a68bdd42f1999f63139ee1cd37f7be767a81def9620b35a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T18:27:58.226Z",
            "endTime": "2021-11-23T18:27:58.226Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiYBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiYBAAAAAAAAA==/",
            "_etag": "\"2200f748-0000-0100-0000-619d48430000\"",
            "_attachments": "attachments/",
            "_ts": 1637697603
        },
        {
            "id": "9f9649073d20932b332ff7ab761fc15fbd81bddc5b182bdd2e516777a1701b9b",
            "conversationId": "9f9649073d20932b332ff7ab761fc15fbd81bddc5b182bdd2e516777a1701b9b",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T18:28:10.834Z",
            "endTime": "2021-11-23T18:28:10.834Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiZBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiZBAAAAAAAAA==/",
            "_etag": "\"2200f848-0000-0100-0000-619d48430000\"",
            "_attachments": "attachments/",
            "_ts": 1637697603
        },
        {
            "id": "1c223230d007f3a6fe4531dbdb073fb4bff919af4423a7a0fe2e1915feeea8f3",
            "conversationId": "1c223230d007f3a6fe4531dbdb073fb4bff919af4423a7a0fe2e1915feeea8f3",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T18:09:51.928Z",
            "endTime": "2021-11-23T18:09:51.928Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiaBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiaBAAAAAAAAA==/",
            "_etag": "\"2200ef48-0000-0100-0000-619d48430000\"",
            "_attachments": "attachments/",
            "_ts": 1637697603
        },
        {
            "id": "6dd0a4db9be3a1a315d89c76e6778a4bd065514aaca9410112b672254177b571",
            "conversationId": "6dd0a4db9be3a1a315d89c76e6778a4bd065514aaca9410112b672254177b571",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T18:11:49.752Z",
            "endTime": "2021-11-23T18:11:49.752Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIibBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIibBAAAAAAAAA==/",
            "_etag": "\"2200f048-0000-0100-0000-619d48430000\"",
            "_attachments": "attachments/",
            "_ts": 1637697603
        },
        {
            "id": "75b98d0a324fa0b1ea82f9a7a4851a9b69e9990bc451f3b4f22e0c8be34f3d13",
            "conversationId": "75b98d0a324fa0b1ea82f9a7a4851a9b69e9990bc451f3b4f22e0c8be34f3d13",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T18:13:26.384Z",
            "endTime": "2021-11-23T18:13:26.384Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIicBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIicBAAAAAAAAA==/",
            "_etag": "\"2200f148-0000-0100-0000-619d48430000\"",
            "_attachments": "attachments/",
            "_ts": 1637697603
        },
        {
            "id": "eac649273d8ecfa2a13f298222ca1ce9b6c5e89fb1f24951a18712134d57648c",
            "conversationId": "eac649273d8ecfa2a13f298222ca1ce9b6c5e89fb1f24951a18712134d57648c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T18:19:58.714Z",
            "endTime": "2021-11-23T18:19:58.714Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIidBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIidBAAAAAAAAA==/",
            "_etag": "\"2200f248-0000-0100-0000-619d48430000\"",
            "_attachments": "attachments/",
            "_ts": 1637697603
        },
        {
            "id": "ef0f54b28b0359fa192dedd23c9354e6873faf4401d7fedba802a957208d9961",
            "conversationId": "ef0f54b28b0359fa192dedd23c9354e6873faf4401d7fedba802a957208d9961",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T18:01:57.254Z",
            "endTime": "2021-11-23T18:01:57.254Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIieBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIieBAAAAAAAAA==/",
            "_etag": "\"2200fa48-0000-0100-0000-619d48430000\"",
            "_attachments": "attachments/",
            "_ts": 1637697603
        },
        {
            "id": "ef89c45d30b4b923cfbf3fb4eda0f3dbc56429415c174e67f3d0eaff981c52f2",
            "conversationId": "ef89c45d30b4b923cfbf3fb4eda0f3dbc56429415c174e67f3d0eaff981c52f2",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T18:04:53.693Z",
            "endTime": "2021-11-23T18:34:53.265Z",
            "timeSpent": 1800,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIifBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIifBAAAAAAAAA==/",
            "_etag": "\"2200fb48-0000-0100-0000-619d48430000\"",
            "_attachments": "attachments/",
            "_ts": 1637697603
        },
        {
            "id": "cab09af361ec993855788be2123572aa7278b2e833722034ea6162538668bdde",
            "conversationId": "cab09af361ec993855788be2123572aa7278b2e833722034ea6162538668bdde",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T19:50:27.112Z",
            "endTime": "2021-11-23T19:50:27.112Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIigBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIigBAAAAAAAAA==/",
            "_etag": "\"22007374-0000-0100-0000-619d64620000\"",
            "_attachments": "attachments/",
            "_ts": 1637704802
        },
        {
            "id": "bf457134c509cf7e398eca5b285d8865475a09dac7f7b4154653f350d0a5eed5",
            "conversationId": "bf457134c509cf7e398eca5b285d8865475a09dac7f7b4154653f350d0a5eed5",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T19:55:51.489Z",
            "endTime": "2021-11-23T19:55:51.489Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIihBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIihBAAAAAAAAA==/",
            "_etag": "\"22006b74-0000-0100-0000-619d64620000\"",
            "_attachments": "attachments/",
            "_ts": 1637704802
        },
        {
            "id": "b9d3795cb352ecd315b3a13e78a6648ccd0c4eea29d16c8a36b061ce5c3528b8",
            "conversationId": "b9d3795cb352ecd315b3a13e78a6648ccd0c4eea29d16c8a36b061ce5c3528b8",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T20:42:52.118Z",
            "endTime": "2021-11-23T20:42:52.118Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiiBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiiBAAAAAAAAA==/",
            "_etag": "\"22001594-0000-0100-0000-619d72730000\"",
            "_attachments": "attachments/",
            "_ts": 1637708403
        },
        {
            "id": "21326dd495e8c07966dcf441f7e08f786649a01f05ac92d0f9b1cecde3dd4893",
            "conversationId": "21326dd495e8c07966dcf441f7e08f786649a01f05ac92d0f9b1cecde3dd4893",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 45,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-23T20:57:11.243Z",
            "endTime": "2021-11-23T20:58:00.792Z",
            "timeSpent": 50,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIijBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIijBAAAAAAAAA==/",
            "_etag": "\"22001294-0000-0100-0000-619d72730000\"",
            "_attachments": "attachments/",
            "_ts": 1637708403
        },
        {
            "id": "1ede4444f128c2482a7969503f0f9ae689090691e89c5a12e1189d97e1bc0291",
            "conversationId": "1ede4444f128c2482a7969503f0f9ae689090691e89c5a12e1189d97e1bc0291",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T20:34:49.772Z",
            "endTime": "2021-11-23T20:34:49.772Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIikBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIikBAAAAAAAAA==/",
            "_etag": "\"22000b94-0000-0100-0000-619d72730000\"",
            "_attachments": "attachments/",
            "_ts": 1637708403
        },
        {
            "id": "4129f2b4ec11a6f75cb83690b40ed063198094546984240b66bce2a768c8e241",
            "conversationId": "4129f2b4ec11a6f75cb83690b40ed063198094546984240b66bce2a768c8e241",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T20:10:01.913Z",
            "endTime": "2021-11-23T20:10:01.913Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIilBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIilBAAAAAAAAA==/",
            "_etag": "\"22006774-0000-0100-0000-619d64620000\"",
            "_attachments": "attachments/",
            "_ts": 1637704802
        },
        {
            "id": "d7d0820c256f8b2e9311451cc70e7dc68b63ce8f7f5aa39bc9bff88f77270d51",
            "conversationId": "d7d0820c256f8b2e9311451cc70e7dc68b63ce8f7f5aa39bc9bff88f77270d51",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T20:19:18.899Z",
            "endTime": "2021-11-23T20:19:18.899Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIimBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIimBAAAAAAAAA==/",
            "_etag": "\"22007074-0000-0100-0000-619d64620000\"",
            "_attachments": "attachments/",
            "_ts": 1637704802
        },
        {
            "id": "367ce439175522dbe4a26761e93c881d673ec43a53244b58d57bf28bc349fee3",
            "conversationId": "367ce439175522dbe4a26761e93c881d673ec43a53244b58d57bf28bc349fee3",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T20:19:27.536Z",
            "endTime": "2021-11-23T20:19:27.536Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIinBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIinBAAAAAAAAA==/",
            "_etag": "\"22007174-0000-0100-0000-619d64620000\"",
            "_attachments": "attachments/",
            "_ts": 1637704802
        },
        {
            "id": "129918aca326e1b6481f5c41dabb6cf849e9c4a2fb6aa6f348ebcbf44904f6e7",
            "conversationId": "129918aca326e1b6481f5c41dabb6cf849e9c4a2fb6aa6f348ebcbf44904f6e7",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T20:20:07.273Z",
            "endTime": "2021-11-23T20:20:07.273Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIioBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIioBAAAAAAAAA==/",
            "_etag": "\"22007274-0000-0100-0000-619d64620000\"",
            "_attachments": "attachments/",
            "_ts": 1637704802
        },
        {
            "id": "0346f2474e9ac4c9ec059603a9e3dc9e8acdf92f62bf9cbb09cf6832aaf56098",
            "conversationId": "0346f2474e9ac4c9ec059603a9e3dc9e8acdf92f62bf9cbb09cf6832aaf56098",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T20:27:30.082Z",
            "endTime": "2021-11-23T20:27:30.082Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIipBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIipBAAAAAAAAA==/",
            "_etag": "\"22007474-0000-0100-0000-619d64620000\"",
            "_attachments": "attachments/",
            "_ts": 1637704802
        },
        {
            "id": "adf2fc7f12e7d03c16c8e559f3592271fcaf5ed8b8a72993811e0c22efb631cb",
            "conversationId": "adf2fc7f12e7d03c16c8e559f3592271fcaf5ed8b8a72993811e0c22efb631cb",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T20:27:16.459Z",
            "endTime": "2021-11-23T20:27:16.459Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiqBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiqBAAAAAAAAA==/",
            "_etag": "\"22007574-0000-0100-0000-619d64620000\"",
            "_attachments": "attachments/",
            "_ts": 1637704802
        },
        {
            "id": "b6ba9890f92cd978fd2e5d21f1b795a701f7af752e45e52f1aaaed400be98e47",
            "conversationId": "b6ba9890f92cd978fd2e5d21f1b795a701f7af752e45e52f1aaaed400be98e47",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T20:28:24.167Z",
            "endTime": "2021-11-23T20:28:24.167Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIirBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIirBAAAAAAAAA==/",
            "_etag": "\"22007674-0000-0100-0000-619d64620000\"",
            "_attachments": "attachments/",
            "_ts": 1637704802
        },
        {
            "id": "05e904c4f6a08c6edfd9fb6579b4db5826472ae70fbdd8c2c3c6b627f352602c",
            "conversationId": "05e904c4f6a08c6edfd9fb6579b4db5826472ae70fbdd8c2c3c6b627f352602c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T20:29:00.55Z",
            "endTime": "2021-11-23T20:29:00.55Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIisBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIisBAAAAAAAAA==/",
            "_etag": "\"22007774-0000-0100-0000-619d64620000\"",
            "_attachments": "attachments/",
            "_ts": 1637704802
        },
        {
            "id": "6a7b3c835fee37acc816a7d774e1f3114631caa9d13cf5fe1936dc6a00dbbb33",
            "conversationId": "6a7b3c835fee37acc816a7d774e1f3114631caa9d13cf5fe1936dc6a00dbbb33",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T20:29:27.583Z",
            "endTime": "2021-11-23T20:29:27.583Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIitBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIitBAAAAAAAAA==/",
            "_etag": "\"22007874-0000-0100-0000-619d64620000\"",
            "_attachments": "attachments/",
            "_ts": 1637704802
        },
        {
            "id": "f1de936df4383cf7d7c9fc9513d29e17e0290e3c857d702f1261fc0dda434988",
            "conversationId": "f1de936df4383cf7d7c9fc9513d29e17e0290e3c857d702f1261fc0dda434988",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T20:30:32.512Z",
            "endTime": "2021-11-23T20:30:32.512Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiuBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiuBAAAAAAAAA==/",
            "_etag": "\"22000994-0000-0100-0000-619d72730000\"",
            "_attachments": "attachments/",
            "_ts": 1637708403
        },
        {
            "id": "467f29c83a9393d5a5adc40dc99d0f5c6fb8662862db515f76fe5ee81fa76ca1",
            "conversationId": "467f29c83a9393d5a5adc40dc99d0f5c6fb8662862db515f76fe5ee81fa76ca1",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T20:41:46.524Z",
            "endTime": "2021-11-23T20:41:46.524Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIivBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIivBAAAAAAAAA==/",
            "_etag": "\"22001494-0000-0100-0000-619d72730000\"",
            "_attachments": "attachments/",
            "_ts": 1637708403
        },
        {
            "id": "28762fd03c182b3a7ba7145f2234a35e5b2b615adb7fc428a194f785ec4ff31a",
            "conversationId": "28762fd03c182b3a7ba7145f2234a35e5b2b615adb7fc428a194f785ec4ff31a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T20:55:59.052Z",
            "endTime": "2021-11-23T20:55:59.052Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiwBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiwBAAAAAAAAA==/",
            "_etag": "\"22001094-0000-0100-0000-619d72730000\"",
            "_attachments": "attachments/",
            "_ts": 1637708403
        },
        {
            "id": "12bb84d69156835ffc376d93d0a0cacba82783cbbf4470f075ba81dae93dee4d",
            "conversationId": "12bb84d69156835ffc376d93d0a0cacba82783cbbf4470f075ba81dae93dee4d",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T21:26:42.744Z",
            "endTime": "2021-11-23T21:26:42.744Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIixBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIixBAAAAAAAAA==/",
            "_etag": "\"22001194-0000-0100-0000-619d72730000\"",
            "_attachments": "attachments/",
            "_ts": 1637708403
        },
        {
            "id": "7f00ddfc5d7a2d1af397215b293e35c3b482a1db9d442fef27086d0a1bafcfb3",
            "conversationId": "7f00ddfc5d7a2d1af397215b293e35c3b482a1db9d442fef27086d0a1bafcfb3",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T21:26:20.91Z",
            "endTime": "2021-11-23T21:26:20.91Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiyBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiyBAAAAAAAAA==/",
            "_etag": "\"22000494-0000-0100-0000-619d72730000\"",
            "_attachments": "attachments/",
            "_ts": 1637708403
        },
        {
            "id": "891d442935e638bbeff69981d9654ae413e3d24e4a28f56d8cca03a0937b3215",
            "conversationId": "891d442935e638bbeff69981d9654ae413e3d24e4a28f56d8cca03a0937b3215",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 44,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-23T21:43:15.633Z",
            "endTime": "2021-11-23T21:46:08.014Z",
            "timeSpent": 172,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIizBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIizBAAAAAAAAA==/",
            "_etag": "\"2200c9ad-0000-0100-0000-619d80810000\"",
            "_attachments": "attachments/",
            "_ts": 1637712001
        },
        {
            "id": "101f955c50fd5fc2dc01cccf91fac2f21ff40fdd7dad14a4332c09b92f6632c6",
            "conversationId": "101f955c50fd5fc2dc01cccf91fac2f21ff40fdd7dad14a4332c09b92f6632c6",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T21:48:57.622Z",
            "endTime": "2021-11-23T21:48:57.622Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi0BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi0BAAAAAAAAA==/",
            "_etag": "\"2200cead-0000-0100-0000-619d80810000\"",
            "_attachments": "attachments/",
            "_ts": 1637712001
        },
        {
            "id": "9a14c94cfb77529e3aba42522c1b8a5e33fa9adfe76db40b096dd5d1096ee56f",
            "conversationId": "9a14c94cfb77529e3aba42522c1b8a5e33fa9adfe76db40b096dd5d1096ee56f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 34,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T21:00:10.046Z",
            "endTime": "2021-11-23T21:00:21.417Z",
            "timeSpent": 11,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi1BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi1BAAAAAAAAA==/",
            "_etag": "\"22000894-0000-0100-0000-619d72730000\"",
            "_attachments": "attachments/",
            "_ts": 1637708403
        },
        {
            "id": "17c31a08a91595b6952c39942cbcfb9f1e4f28f53fa0a4634c8dc218f5ef4dc3",
            "conversationId": "17c31a08a91595b6952c39942cbcfb9f1e4f28f53fa0a4634c8dc218f5ef4dc3",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T21:02:31.797Z",
            "endTime": "2021-11-23T21:02:31.797Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi2BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi2BAAAAAAAAA==/",
            "_etag": "\"22000a94-0000-0100-0000-619d72730000\"",
            "_attachments": "attachments/",
            "_ts": 1637708403
        },
        {
            "id": "cd5153c4ae93cbd2e80790955cd9d8f0c5a34f4e4938890c56b66086d727a0f9",
            "conversationId": "cd5153c4ae93cbd2e80790955cd9d8f0c5a34f4e4938890c56b66086d727a0f9",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T21:53:23.579Z",
            "endTime": "2021-11-23T21:53:23.579Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi3BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi3BAAAAAAAAA==/",
            "_etag": "\"2200d2ad-0000-0100-0000-619d80810000\"",
            "_attachments": "attachments/",
            "_ts": 1637712001
        },
        {
            "id": "cdc7057ef968731e58cb4a5574e7fff50f9d3e96129da4cb8637d5a2da2a310b",
            "conversationId": "cdc7057ef968731e58cb4a5574e7fff50f9d3e96129da4cb8637d5a2da2a310b",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T21:35:40.913Z",
            "endTime": "2021-11-23T21:35:40.913Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi4BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi4BAAAAAAAAA==/",
            "_etag": "\"2200d6ad-0000-0100-0000-619d80810000\"",
            "_attachments": "attachments/",
            "_ts": 1637712001
        },
        {
            "id": "3644a0ad95acc3084136f423a928fc5e42146fdc07b211d830e5f7761447f3ed",
            "conversationId": "3644a0ad95acc3084136f423a928fc5e42146fdc07b211d830e5f7761447f3ed",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T21:41:12.436Z",
            "endTime": "2021-11-23T21:41:12.436Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi5BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi5BAAAAAAAAA==/",
            "_etag": "\"2200c6ad-0000-0100-0000-619d80810000\"",
            "_attachments": "attachments/",
            "_ts": 1637712001
        },
        {
            "id": "383e7e8f460f71e4c7953e40679a54e7a4d11be9cf41d43941714483b9c9333d",
            "conversationId": "383e7e8f460f71e4c7953e40679a54e7a4d11be9cf41d43941714483b9c9333d",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T21:41:45.795Z",
            "endTime": "2021-11-23T21:41:45.795Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi6BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi6BAAAAAAAAA==/",
            "_etag": "\"2200d7ad-0000-0100-0000-619d80810000\"",
            "_attachments": "attachments/",
            "_ts": 1637712001
        },
        {
            "id": "2588873c7814d73ced2bdfcdc50aa167bf41c711aa866d93ff6d6a539544891e",
            "conversationId": "2588873c7814d73ced2bdfcdc50aa167bf41c711aa866d93ff6d6a539544891e",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T21:42:52.271Z",
            "endTime": "2021-11-23T21:42:52.271Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi7BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi7BAAAAAAAAA==/",
            "_etag": "\"2200c7ad-0000-0100-0000-619d80810000\"",
            "_attachments": "attachments/",
            "_ts": 1637712001
        },
        {
            "id": "4756ac7cfdc223e3f103051e50ee5e1467539d7d2eedea79c95fa46eddef58b6",
            "conversationId": "4756ac7cfdc223e3f103051e50ee5e1467539d7d2eedea79c95fa46eddef58b6",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T21:49:46.251Z",
            "endTime": "2021-11-23T21:49:46.251Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi8BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi8BAAAAAAAAA==/",
            "_etag": "\"2200cfad-0000-0100-0000-619d80810000\"",
            "_attachments": "attachments/",
            "_ts": 1637712001
        },
        {
            "id": "f354d0cba9aa0282ac71027c320836ebc6a2abe0cfb4b37a124d4affae5f73c6",
            "conversationId": "f354d0cba9aa0282ac71027c320836ebc6a2abe0cfb4b37a124d4affae5f73c6",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T21:49:55.608Z",
            "endTime": "2021-11-23T21:49:55.608Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi9BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi9BAAAAAAAAA==/",
            "_etag": "\"2200d0ad-0000-0100-0000-619d80810000\"",
            "_attachments": "attachments/",
            "_ts": 1637712001
        },
        {
            "id": "9dc25f0a3c960884d10fee5e8aab7faa2109a0f988f4cde2db3ec346230d188e",
            "conversationId": "9dc25f0a3c960884d10fee5e8aab7faa2109a0f988f4cde2db3ec346230d188e",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T21:50:27.206Z",
            "endTime": "2021-11-23T21:50:27.206Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi+BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi+BAAAAAAAAA==/",
            "_etag": "\"2200caad-0000-0100-0000-619d80810000\"",
            "_attachments": "attachments/",
            "_ts": 1637712001
        },
        {
            "id": "6fd5bc1621487bf01aee64c450b12b17dfefa1fa2558182ce73c2a80b379ccaa",
            "conversationId": "6fd5bc1621487bf01aee64c450b12b17dfefa1fa2558182ce73c2a80b379ccaa",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T21:51:14.339Z",
            "endTime": "2021-11-23T21:51:14.339Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi-BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi-BAAAAAAAAA==/",
            "_etag": "\"2200cbad-0000-0100-0000-619d80810000\"",
            "_attachments": "attachments/",
            "_ts": 1637712001
        },
        {
            "id": "3e67d75384a5a70d84beacd08ac8f4413b9a45d5d9f202c2407a870fc2e9ab3b",
            "conversationId": "3e67d75384a5a70d84beacd08ac8f4413b9a45d5d9f202c2407a870fc2e9ab3b",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T21:52:39.486Z",
            "endTime": "2021-11-23T21:52:39.486Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjABAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjABAAAAAAAAA==/",
            "_etag": "\"2200d1ad-0000-0100-0000-619d80810000\"",
            "_attachments": "attachments/",
            "_ts": 1637712001
        },
        {
            "id": "0f7c412734e3195e578442819b2c0f2552d50bf651b5f45d18a6b7f4f84ad5b8",
            "conversationId": "0f7c412734e3195e578442819b2c0f2552d50bf651b5f45d18a6b7f4f84ad5b8",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T21:53:58.995Z",
            "endTime": "2021-11-23T21:53:58.995Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjBBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjBBAAAAAAAAA==/",
            "_etag": "\"2200d3ad-0000-0100-0000-619d80810000\"",
            "_attachments": "attachments/",
            "_ts": 1637712001
        },
        {
            "id": "01213a8859252e3e436681eaefcd5f6338305169a4afb423e6b3f0d5e08fc337",
            "conversationId": "01213a8859252e3e436681eaefcd5f6338305169a4afb423e6b3f0d5e08fc337",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T21:54:14.791Z",
            "endTime": "2021-11-23T21:54:14.791Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjCBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjCBAAAAAAAAA==/",
            "_etag": "\"2200ccad-0000-0100-0000-619d80810000\"",
            "_attachments": "attachments/",
            "_ts": 1637712001
        },
        {
            "id": "e6d0cafeaed9a9dc1b47bb8657ceee0f8be13021e885c5273a4d059ef6029351",
            "conversationId": "e6d0cafeaed9a9dc1b47bb8657ceee0f8be13021e885c5273a4d059ef6029351",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T22:09:32.369Z",
            "endTime": "2021-11-23T22:09:32.369Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjDBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjDBAAAAAAAAA==/",
            "_etag": "\"2200c5ad-0000-0100-0000-619d80810000\"",
            "_attachments": "attachments/",
            "_ts": 1637712001
},
    
        {
            "id": "4287ba959d4118ad7e094e1b8cd83c71d9272f3d476ce07e29de697b700a7fe6",
            "conversationId": "4287ba959d4118ad7e094e1b8cd83c71d9272f3d476ce07e29de697b700a7fe6",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T22:02:46.904Z",
            "endTime": "2021-11-23T22:02:46.904Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjEBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjEBAAAAAAAAA==/",
            "_etag": "\"2200d5ad-0000-0100-0000-619d80810000\"",
            "_attachments": "attachments/",
            "_ts": 1637712001
        },
        {
            "id": "2f0ee227659ea2f35417e9bad357125d095dffc661dc644a93c6f27d0777ef46",
            "conversationId": "2f0ee227659ea2f35417e9bad357125d095dffc661dc644a93c6f27d0777ef46",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T22:03:02.569Z",
            "endTime": "2021-11-23T22:03:02.569Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjFBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjFBAAAAAAAAA==/",
            "_etag": "\"2200d4ad-0000-0100-0000-619d80810000\"",
            "_attachments": "attachments/",
            "_ts": 1637712001
        },
        {
            "id": "cb661b2c2a6ac42c4e2c2f1ff2c029e453b34cb37158584bdb7c2d1a7d6e0e3b",
            "conversationId": "cb661b2c2a6ac42c4e2c2f1ff2c029e453b34cb37158584bdb7c2d1a7d6e0e3b",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T22:08:59.865Z",
            "endTime": "2021-11-23T22:08:59.865Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjGBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjGBAAAAAAAAA==/",
            "_etag": "\"2200cdad-0000-0100-0000-619d80810000\"",
            "_attachments": "attachments/",
            "_ts": 1637712001
        },
        {
            "id": "cda093cc716466c55e27310f161c1b2220bc2aa1fcf198e4887169db886831da",
            "conversationId": "cda093cc716466c55e27310f161c1b2220bc2aa1fcf198e4887169db886831da",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-23T22:09:27.076Z",
            "endTime": "2021-11-23T22:09:27.076Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjHBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjHBAAAAAAAAA==/",
            "_etag": "\"2200c8ad-0000-0100-0000-619d80810000\"",
            "_attachments": "attachments/",
            "_ts": 1637712001
        },
        {
            "id": "4b4279018f26b4fbc55a6cebdfbfb8666dd07dc539347a09b366d7c9ff23f40c",
            "conversationId": "4b4279018f26b4fbc55a6cebdfbfb8666dd07dc539347a09b366d7c9ff23f40c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 99,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Sinus pain or pressure"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T02:28:36.081Z",
            "endTime": "2021-11-24T02:30:25.107Z",
            "timeSpent": 109,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjIBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjIBAAAAAAAAA==/",
            "_etag": "\"2300a157-0000-0100-0000-619dc6d20000\"",
            "_attachments": "attachments/",
            "_ts": 1637730002
        },
        {
            "id": "74f43a654823255d0bb47555f6b969e72748c89e771d6acff5b464244e66a4d7",
            "conversationId": "74f43a654823255d0bb47555f6b969e72748c89e771d6acff5b464244e66a4d7",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T07:31:33.319Z",
            "endTime": "2021-11-24T07:31:33.319Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjJBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjJBAAAAAAAAA==/",
            "_etag": "\"24006d7e-0000-0100-0000-619e0d220000\"",
            "_attachments": "attachments/",
            "_ts": 1637748002
        },
        {
            "id": "eb6e2b680f8b9ed16cb1e6f3f6c8b312477a6d30f1710eafddcbfe1c48232368",
            "conversationId": "eb6e2b680f8b9ed16cb1e6f3f6c8b312477a6d30f1710eafddcbfe1c48232368",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T08:11:56.49Z",
            "endTime": "2021-11-24T08:12:16.801Z",
            "timeSpent": 20,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjKBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjKBAAAAAAAAA==/",
            "_etag": "\"24006e7e-0000-0100-0000-619e0d220000\"",
            "_attachments": "attachments/",
            "_ts": 1637748002
        },
        {
            "id": "1fd0391319ee3ccc8ebd28bc0a26d3cf169fe1068986b5717906b0b00ffa65e0",
            "conversationId": "1fd0391319ee3ccc8ebd28bc0a26d3cf169fe1068986b5717906b0b00ffa65e0",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T11:43:22.566Z",
            "endTime": "2021-11-24T11:43:22.566Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjLBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjLBAAAAAAAAA==/",
            "_etag": "\"25005342-0000-0100-0000-619e45630000\"",
            "_attachments": "attachments/",
            "_ts": 1637762403
        },
        {
            "id": "1225847351acf5adbba7a0acf5ab3c0ad8c195bbc1c5c54ca741a1ba23e0b501",
            "conversationId": "1225847351acf5adbba7a0acf5ab3c0ad8c195bbc1c5c54ca741a1ba23e0b501",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T12:58:08.071Z",
            "endTime": "2021-11-24T12:58:08.071Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjMBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjMBAAAAAAAAA==/",
            "_etag": "\"2500f363-0000-0100-0000-619e53710000\"",
            "_attachments": "attachments/",
            "_ts": 1637766001
        },
        {
            "id": "a571ad73e16403666a7b3b7bbbce7ac48717ca864c0bf990e6256598ab3773af",
            "conversationId": "a571ad73e16403666a7b3b7bbbce7ac48717ca864c0bf990e6256598ab3773af",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T12:25:47.843Z",
            "endTime": "2021-11-24T12:25:47.843Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjNBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjNBAAAAAAAAA==/",
            "_etag": "\"25004e42-0000-0100-0000-619e45630000\"",
            "_attachments": "attachments/",
            "_ts": 1637762403
        },
        {
            "id": "c5097bd26a4f700b7d01d5e68108c6f5ed841ed68bce2db4579101d4d6a6fe80",
            "conversationId": "c5097bd26a4f700b7d01d5e68108c6f5ed841ed68bce2db4579101d4d6a6fe80",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T12:26:51.034Z",
            "endTime": "2021-11-24T12:26:51.034Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjOBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjOBAAAAAAAAA==/",
            "_etag": "\"25004d42-0000-0100-0000-619e45630000\"",
            "_attachments": "attachments/",
            "_ts": 1637762403
        },
        {
            "id": "f7d5a89adbc016a4cffd38e452d2dcbcfc13203b580dfba795d79fe86c082b25",
            "conversationId": "f7d5a89adbc016a4cffd38e452d2dcbcfc13203b580dfba795d79fe86c082b25",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T12:45:08.116Z",
            "endTime": "2021-11-24T12:45:08.116Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjPBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjPBAAAAAAAAA==/",
            "_etag": "\"2500eb63-0000-0100-0000-619e53710000\"",
            "_attachments": "attachments/",
            "_ts": 1637766001
        },
        {
            "id": "58bae8ad2c832cecf41a16326ceeb88609116bbb2b43daf1887493e106332775",
            "conversationId": "58bae8ad2c832cecf41a16326ceeb88609116bbb2b43daf1887493e106332775",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T12:49:21.437Z",
            "endTime": "2021-11-24T12:49:21.437Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjQBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjQBAAAAAAAAA==/",
            "_etag": "\"2500ec63-0000-0100-0000-619e53710000\"",
            "_attachments": "attachments/",
            "_ts": 1637766001
        },
        {
            "id": "27e61249ac93d7dbc00d1e506c92b8ae2e71c1d11bd9473ab4d8b987831a5204",
            "conversationId": "27e61249ac93d7dbc00d1e506c92b8ae2e71c1d11bd9473ab4d8b987831a5204",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T12:49:32.634Z",
            "endTime": "2021-11-24T12:49:32.634Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjRBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjRBAAAAAAAAA==/",
            "_etag": "\"2500ed63-0000-0100-0000-619e53710000\"",
            "_attachments": "attachments/",
            "_ts": 1637766001
        },
        {
            "id": "c2ad91191f9b89b65905ea48ef712d01db6bb0eebcb5543556c279232e12639f",
            "conversationId": "c2ad91191f9b89b65905ea48ef712d01db6bb0eebcb5543556c279232e12639f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T12:53:33.011Z",
            "endTime": "2021-11-24T12:53:33.011Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjSBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjSBAAAAAAAAA==/",
            "_etag": "\"2500ee63-0000-0100-0000-619e53710000\"",
            "_attachments": "attachments/",
            "_ts": 1637766001
        },
        {
            "id": "b2b75658ca8631c746dc603f3de6d661a13cf255768f95fab565243553296683",
            "conversationId": "b2b75658ca8631c746dc603f3de6d661a13cf255768f95fab565243553296683",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T12:54:02.091Z",
            "endTime": "2021-11-24T12:54:02.091Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjTBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjTBAAAAAAAAA==/",
            "_etag": "\"2500ef63-0000-0100-0000-619e53710000\"",
            "_attachments": "attachments/",
            "_ts": 1637766001
        },
        {
            "id": "2bf31dbca4f90aff9aa6bfb079e732ae7e46c0fb3e4097d24af6744115256282",
            "conversationId": "2bf31dbca4f90aff9aa6bfb079e732ae7e46c0fb3e4097d24af6744115256282",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T12:54:41.339Z",
            "endTime": "2021-11-24T12:54:41.339Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjUBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjUBAAAAAAAAA==/",
            "_etag": "\"2500f063-0000-0100-0000-619e53710000\"",
            "_attachments": "attachments/",
            "_ts": 1637766001
        },
        {
            "id": "a6101fc72db5813628abae3d55ac6a183a654f08e69cc974abfeba107cfe12ab",
            "conversationId": "a6101fc72db5813628abae3d55ac6a183a654f08e69cc974abfeba107cfe12ab",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T12:56:29.632Z",
            "endTime": "2021-11-24T12:56:29.632Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjVBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjVBAAAAAAAAA==/",
            "_etag": "\"2500f263-0000-0100-0000-619e53710000\"",
            "_attachments": "attachments/",
            "_ts": 1637766001
        },
        {
            "id": "21712a6966cee35ba41d2b278acdb14c5ef9f9b47d08527f8eea9ff165ac1eec",
            "conversationId": "21712a6966cee35ba41d2b278acdb14c5ef9f9b47d08527f8eea9ff165ac1eec",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T12:43:56.531Z",
            "endTime": "2021-11-24T12:43:56.531Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjWBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjWBAAAAAAAAA==/",
            "_etag": "\"2500ea63-0000-0100-0000-619e53710000\"",
            "_attachments": "attachments/",
            "_ts": 1637766001
        },
        {
            "id": "a07354345bbf57044e79f0aed0e2c889d2acbfff66b295a1e25d35c6b3ad8e06",
            "conversationId": "a07354345bbf57044e79f0aed0e2c889d2acbfff66b295a1e25d35c6b3ad8e06",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T12:56:11.696Z",
            "endTime": "2021-11-24T12:56:11.696Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjXBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjXBAAAAAAAAA==/",
            "_etag": "\"2500f163-0000-0100-0000-619e53710000\"",
            "_attachments": "attachments/",
            "_ts": 1637766001
        },
        {
            "id": "6f705ffce721046f39c3adbcdea9187ec9faf23b348e908bf3daf7ab21a29ee0",
            "conversationId": "6f705ffce721046f39c3adbcdea9187ec9faf23b348e908bf3daf7ab21a29ee0",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T13:32:47.721Z",
            "endTime": "2021-11-24T13:32:47.721Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjYBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjYBAAAAAAAAA==/",
            "_etag": "\"2500ee7f-0000-0100-0000-619e61820000\"",
            "_attachments": "attachments/",
            "_ts": 1637769602
        },
        {
            "id": "2540f28dbfd08d102542f4ddbb74cb1ed1478d025f645fe1046928f767bd0ccf",
            "conversationId": "2540f28dbfd08d102542f4ddbb74cb1ed1478d025f645fe1046928f767bd0ccf",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T13:47:07.404Z",
            "endTime": "2021-11-24T13:47:07.404Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjZBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjZBAAAAAAAAA==/",
            "_etag": "\"2500f07f-0000-0100-0000-619e61820000\"",
            "_attachments": "attachments/",
            "_ts": 1637769602
        },
        {
            "id": "53d659416c36d976b29eb53a0179591f3b97a492b01be83b830a335e909ea8f9",
            "conversationId": "53d659416c36d976b29eb53a0179591f3b97a492b01be83b830a335e909ea8f9",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 34,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T13:56:24.226Z",
            "endTime": "2021-11-24T13:56:34.108Z",
            "timeSpent": 10,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjaBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjaBAAAAAAAAA==/",
            "_etag": "\"2500f17f-0000-0100-0000-619e61820000\"",
            "_attachments": "attachments/",
            "_ts": 1637769602
        },
        {
            "id": "a20c490176c21e4bf2388a34fbdd7988659bf5642d50f4ed5bced55573d17307",
            "conversationId": "a20c490176c21e4bf2388a34fbdd7988659bf5642d50f4ed5bced55573d17307",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T13:28:20.032Z",
            "endTime": "2021-11-24T13:28:20.032Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjbBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjbBAAAAAAAAA==/",
            "_etag": "\"2500e463-0000-0100-0000-619e53710000\"",
            "_attachments": "attachments/",
            "_ts": 1637766001
        },
        {
            "id": "554f14f6903f33f253371ca6def0714e9a576fa298a3507b68d22dfe0037f045",
            "conversationId": "554f14f6903f33f253371ca6def0714e9a576fa298a3507b68d22dfe0037f045",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T13:03:20.169Z",
            "endTime": "2021-11-24T13:03:20.169Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjcBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjcBAAAAAAAAA==/",
            "_etag": "\"2500e563-0000-0100-0000-619e53710000\"",
            "_attachments": "attachments/",
            "_ts": 1637766001
        },
        {
            "id": "0d63a835c33822b2c28999e5a2e124d659a62d07eaa84386dde0f0e4a8b15be7",
            "conversationId": "0d63a835c33822b2c28999e5a2e124d659a62d07eaa84386dde0f0e4a8b15be7",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T13:18:32.98Z",
            "endTime": "2021-11-24T13:18:32.98Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjdBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjdBAAAAAAAAA==/",
            "_etag": "\"2500e663-0000-0100-0000-619e53710000\"",
            "_attachments": "attachments/",
            "_ts": 1637766001
        },
        {
            "id": "a705e2ebfdbfedcdb17287e74a6db89c6bcb5270d1c42f225e18068d8df785b3",
            "conversationId": "a705e2ebfdbfedcdb17287e74a6db89c6bcb5270d1c42f225e18068d8df785b3",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T13:15:49.446Z",
            "endTime": "2021-11-24T13:15:49.446Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjeBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjeBAAAAAAAAA==/",
            "_etag": "\"2500e763-0000-0100-0000-619e53710000\"",
            "_attachments": "attachments/",
            "_ts": 1637766001
        },
        {
            "id": "f326316cc8efce8ddc42b9a5b0efabdeea790ee08ab0f618eef0e12849067fbc",
            "conversationId": "f326316cc8efce8ddc42b9a5b0efabdeea790ee08ab0f618eef0e12849067fbc",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T13:10:51.172Z",
            "endTime": "2021-11-24T13:10:51.172Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjfBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjfBAAAAAAAAA==/",
            "_etag": "\"2500e863-0000-0100-0000-619e53710000\"",
            "_attachments": "attachments/",
            "_ts": 1637766001
        },
        {
            "id": "dadc3eb344a6579fcd8a85ffca41926e7a67f2c45f8101dc1f183362da92960a",
            "conversationId": "dadc3eb344a6579fcd8a85ffca41926e7a67f2c45f8101dc1f183362da92960a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T13:27:47.592Z",
            "endTime": "2021-11-24T13:27:47.592Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjgBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjgBAAAAAAAAA==/",
            "_etag": "\"2500e963-0000-0100-0000-619e53710000\"",
            "_attachments": "attachments/",
            "_ts": 1637766001
        },
        {
            "id": "d2c0c31c12dec6b0a1ca656affaa1e6088f870b887b451b8a0bd98d38bab299e",
            "conversationId": "d2c0c31c12dec6b0a1ca656affaa1e6088f870b887b451b8a0bd98d38bab299e",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache",
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T14:24:26.967Z",
            "endTime": "2021-11-24T14:27:29.825Z",
            "timeSpent": 183,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjhBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjhBAAAAAAAAA==/",
            "_etag": "\"2500ea7f-0000-0100-0000-619e61820000\"",
            "_attachments": "attachments/",
            "_ts": 1637769602
        },
        {
            "id": "d84a4066e06389e93605ec9bec657f0181278425d1105c9fa9ec6b9028f142c2",
            "conversationId": "d84a4066e06389e93605ec9bec657f0181278425d1105c9fa9ec6b9028f142c2",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T14:03:42.567Z",
            "endTime": "2021-11-24T14:03:42.567Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjiBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjiBAAAAAAAAA==/",
            "_etag": "\"2500ef7f-0000-0100-0000-619e61820000\"",
            "_attachments": "attachments/",
            "_ts": 1637769602
        },
        {
            "id": "d35f5d5fca1cdca4e8f8e839fda534acda6003a95c26d26aeb366ee6c812bf0f",
            "conversationId": "d35f5d5fca1cdca4e8f8e839fda534acda6003a95c26d26aeb366ee6c812bf0f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T15:54:38.156Z",
            "endTime": "2021-11-24T15:54:38.156Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjjBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjjBAAAAAAAAA==/",
            "_etag": "\"250017db-0000-0100-0000-619e7da10000\"",
            "_attachments": "attachments/",
            "_ts": 1637776801
        },
        {
            "id": "8f363d0ac02bacfa4479e81ed4625569470263a25055ad111ce386a43fe4fb54",
            "conversationId": "8f363d0ac02bacfa4479e81ed4625569470263a25055ad111ce386a43fe4fb54",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T15:55:56.962Z",
            "endTime": "2021-11-24T15:55:56.962Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjkBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjkBAAAAAAAAA==/",
            "_etag": "\"25000ddb-0000-0100-0000-619e7da10000\"",
            "_attachments": "attachments/",
            "_ts": 1637776801
        },
        {
            "id": "8dac68d090e57e2c78f3faa331208ed50e000bf2481b34051b7c87b5c08b2f0f",
            "conversationId": "8dac68d090e57e2c78f3faa331208ed50e000bf2481b34051b7c87b5c08b2f0f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Female",
            "age": 99,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T15:55:16.035Z",
            "endTime": "2021-11-24T15:57:48.251Z",
            "timeSpent": 152,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjlBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjlBAAAAAAAAA==/",
            "_etag": "\"25000edb-0000-0100-0000-619e7da10000\"",
            "_attachments": "attachments/",
            "_ts": 1637776801
        },
        {
            "id": "6d38f93c908e748677158c286dea24ec75ff5b91101597d50d99a2bdf99cb3a1",
            "conversationId": "6d38f93c908e748677158c286dea24ec75ff5b91101597d50d99a2bdf99cb3a1",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T15:48:28.265Z",
            "endTime": "2021-11-24T15:48:50.296Z",
            "timeSpent": 22,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjmBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjmBAAAAAAAAA==/",
            "_etag": "\"250016db-0000-0100-0000-619e7da10000\"",
            "_attachments": "attachments/",
            "_ts": 1637776801
        },
        {
            "id": "b7fa5fd954d753293bd7b9929bfe12063feff2a43bb094f24aae8a6a0b54d93e",
            "conversationId": "b7fa5fd954d753293bd7b9929bfe12063feff2a43bb094f24aae8a6a0b54d93e",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T16:41:23.223Z",
            "endTime": "2021-11-24T16:41:23.223Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjnBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjnBAAAAAAAAA==/",
            "_etag": "\"2600392e-0000-0100-0000-619e8bb20000\"",
            "_attachments": "attachments/",
            "_ts": 1637780402
        },
        {
            "id": "5c42bb8a05c03613ff3e18d169ac2581db2124ca988884db74a823b6bb799acc",
            "conversationId": "5c42bb8a05c03613ff3e18d169ac2581db2124ca988884db74a823b6bb799acc",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T16:43:00.302Z",
            "endTime": "2021-11-24T16:43:00.302Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjoBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjoBAAAAAAAAA==/",
            "_etag": "\"26003c2e-0000-0100-0000-619e8bb20000\"",
            "_attachments": "attachments/",
            "_ts": 1637780402
        },
        {
            "id": "0b2e57b98a6327ac620bbd451c87b56f0ab3a9d469b49ef1fcc3fa7dadfeac83",
            "conversationId": "0b2e57b98a6327ac620bbd451c87b56f0ab3a9d469b49ef1fcc3fa7dadfeac83",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T16:56:14.881Z",
            "endTime": "2021-11-24T16:56:14.881Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjpBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjpBAAAAAAAAA==/",
            "_etag": "\"26003f2e-0000-0100-0000-619e8bb20000\"",
            "_attachments": "attachments/",
            "_ts": 1637780402
        },
        {
            "id": "84825c48a97c749f4eb42f88aabfce021b71e292e27a0a24ef5ba39659e0d770",
            "conversationId": "84825c48a97c749f4eb42f88aabfce021b71e292e27a0a24ef5ba39659e0d770",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T16:49:20.245Z",
            "endTime": "2021-11-24T16:49:20.245Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjqBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjqBAAAAAAAAA==/",
            "_etag": "\"26003a2e-0000-0100-0000-619e8bb20000\"",
            "_attachments": "attachments/",
            "_ts": 1637780402
        },
        {
            "id": "9625b9d3dbb949e13232da4fd6bc9aa5c55e6d44344100db7903faba48c9cf6f",
            "conversationId": "9625b9d3dbb949e13232da4fd6bc9aa5c55e6d44344100db7903faba48c9cf6f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T16:52:59.204Z",
            "endTime": "2021-11-24T16:52:59.204Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjrBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjrBAAAAAAAAA==/",
            "_etag": "\"26003e2e-0000-0100-0000-619e8bb20000\"",
            "_attachments": "attachments/",
            "_ts": 1637780402
        },
        {
            "id": "aa824ac1c5a3886b70d966cef137b0537c7e3cb215c7e97a61122a8058fab3aa",
            "conversationId": "aa824ac1c5a3886b70d966cef137b0537c7e3cb215c7e97a61122a8058fab3aa",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 33,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-24T17:07:52.378Z",
            "endTime": "2021-11-24T17:09:19.077Z",
            "timeSpent": 87,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjsBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjsBAAAAAAAAA==/",
            "_etag": "\"2600382e-0000-0100-0000-619e8bb20000\"",
            "_attachments": "attachments/",
            "_ts": 1637780402
        },
        {
            "id": "56de49e5f1c433c9433192472c5ecc6550d777df93b25d1b6f68259612858af5",
            "conversationId": "56de49e5f1c433c9433192472c5ecc6550d777df93b25d1b6f68259612858af5",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T17:40:58.483Z",
            "endTime": "2021-11-24T17:40:58.483Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjtBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjtBAAAAAAAAA==/",
            "_etag": "\"2600ac48-0000-0100-0000-619e99c10000\"",
            "_attachments": "attachments/",
            "_ts": 1637784001
        },
        {
            "id": "8ac07b8c054412e736d379dfdf1155121c9b9180f2941a2e9dee6a9cd820be0c",
            "conversationId": "8ac07b8c054412e736d379dfdf1155121c9b9180f2941a2e9dee6a9cd820be0c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T17:32:17.83Z",
            "endTime": "2021-11-24T17:32:17.83Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjuBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjuBAAAAAAAAA==/",
            "_etag": "\"2600a748-0000-0100-0000-619e99c10000\"",
            "_attachments": "attachments/",
            "_ts": 1637784001
        },
        {
            "id": "64f415256f061eaa8f6b53756edb4caea8e865303093366d3ebfb25aa0f7373c",
            "conversationId": "64f415256f061eaa8f6b53756edb4caea8e865303093366d3ebfb25aa0f7373c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T17:33:29.285Z",
            "endTime": "2021-11-24T17:33:29.285Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjvBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjvBAAAAAAAAA==/",
            "_etag": "\"2600a848-0000-0100-0000-619e99c10000\"",
            "_attachments": "attachments/",
            "_ts": 1637784001
        },
        {
            "id": "64af5cc422855a034586a817b31b2703383dee04668ac1738d873ab06ddee903",
            "conversationId": "64af5cc422855a034586a817b31b2703383dee04668ac1738d873ab06ddee903",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T17:41:15.546Z",
            "endTime": "2021-11-24T17:41:15.546Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjwBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjwBAAAAAAAAA==/",
            "_etag": "\"2600ad48-0000-0100-0000-619e99c10000\"",
            "_attachments": "attachments/",
            "_ts": 1637784001
        },
        {
            "id": "fda0ba9d6a59c5bb962997c7005043f9b9003f86c627e302d653cf6f68b964ab",
            "conversationId": "fda0ba9d6a59c5bb962997c7005043f9b9003f86c627e302d653cf6f68b964ab",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T17:43:04.531Z",
            "endTime": "2021-11-24T17:43:04.531Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjxBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjxBAAAAAAAAA==/",
            "_etag": "\"2600ae48-0000-0100-0000-619e99c10000\"",
            "_attachments": "attachments/",
            "_ts": 1637784001
        },
        {
            "id": "2c95f5886308347189e1727274a1e37cd643efabed884d17105d8fa6edf056bd",
            "conversationId": "2c95f5886308347189e1727274a1e37cd643efabed884d17105d8fa6edf056bd",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T17:50:53.151Z",
            "endTime": "2021-11-24T17:50:53.151Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjyBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjyBAAAAAAAAA==/",
            "_etag": "\"2600af48-0000-0100-0000-619e99c10000\"",
            "_attachments": "attachments/",
            "_ts": 1637784001
        },
        {
            "id": "13ef6488a4816452b621f2e718f86595ecbfc728ec5cd7999ae341aeb190239f",
            "conversationId": "13ef6488a4816452b621f2e718f86595ecbfc728ec5cd7999ae341aeb190239f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 33,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T17:42:02.94Z",
            "endTime": "2021-11-24T17:42:27.251Z",
            "timeSpent": 24,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjzBAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjzBAAAAAAAAA==/",
            "_etag": "\"2600a348-0000-0100-0000-619e99c10000\"",
            "_attachments": "attachments/",
            "_ts": 1637784001
        },
        {
            "id": "96be62447fde6183825636199902b6c2f17d40ac48d6e912368cdcfb7202e9f2",
            "conversationId": "96be62447fde6183825636199902b6c2f17d40ac48d6e912368cdcfb7202e9f2",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-11-24T18:43:55.318Z",
            "endTime": "2021-11-24T18:44:40.564Z",
            "timeSpent": 45,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj0BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj0BAAAAAAAAA==/",
            "_etag": "\"26005774-0000-0100-0000-619ea7d10000\"",
            "_attachments": "attachments/",
            "_ts": 1637787601
        },
        {
            "id": "07505da8472c4d67491524a7b7cd1222f1f63a89dd10aad8af8f9c818a2ea03f",
            "conversationId": "07505da8472c4d67491524a7b7cd1222f1f63a89dd10aad8af8f9c818a2ea03f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T18:16:43.63Z",
            "endTime": "2021-11-24T18:16:43.63Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj1BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj1BAAAAAAAAA==/",
            "_etag": "\"2600a548-0000-0100-0000-619e99c10000\"",
            "_attachments": "attachments/",
            "_ts": 1637784001
        },
        {
            "id": "b1d494a594b80ada2005c52d27235e49570591348f59cfac92d4fbc140bc886c",
            "conversationId": "b1d494a594b80ada2005c52d27235e49570591348f59cfac92d4fbc140bc886c",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T19:40:20.975Z",
            "endTime": "2021-11-24T19:40:20.975Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj2BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj2BAAAAAAAAA==/",
            "_etag": "\"260040af-0000-0100-0000-619eb5e20000\"",
            "_attachments": "attachments/",
            "_ts": 1637791202
        },
        {
            "id": "97b6b29bba961c99e851b848d43c43755b9459fa5399df81027036ad22aefa87",
            "conversationId": "97b6b29bba961c99e851b848d43c43755b9459fa5399df81027036ad22aefa87",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T19:41:49.439Z",
            "endTime": "2021-11-24T19:41:49.439Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj3BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj3BAAAAAAAAA==/",
            "_etag": "\"260041af-0000-0100-0000-619eb5e20000\"",
            "_attachments": "attachments/",
            "_ts": 1637791202
        },
        {
            "id": "9139f48bab605532814a9824f049766c73bc66248b0cb26ffe0f5d955c400bea",
            "conversationId": "9139f48bab605532814a9824f049766c73bc66248b0cb26ffe0f5d955c400bea",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T19:22:51.155Z",
            "endTime": "2021-11-24T19:22:51.155Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj4BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj4BAAAAAAAAA==/",
            "_etag": "\"26005174-0000-0100-0000-619ea7d10000\"",
            "_attachments": "attachments/",
            "_ts": 1637787601
        },
        {
            "id": "16328f4322503da9d68e37ab8335a0e19fae8ee3b08988af43c5fece0984f4d8",
            "conversationId": "16328f4322503da9d68e37ab8335a0e19fae8ee3b08988af43c5fece0984f4d8",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T19:31:11.24Z",
            "endTime": "2021-11-24T19:31:11.24Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj5BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj5BAAAAAAAAA==/",
            "_etag": "\"26003daf-0000-0100-0000-619eb5e20000\"",
            "_attachments": "attachments/",
            "_ts": 1637791202
        },
        {
            "id": "640d90ff3994beebcfcc0b4c2324939cc76ea279ac5332b655926d3c141fac1b",
            "conversationId": "640d90ff3994beebcfcc0b4c2324939cc76ea279ac5332b655926d3c141fac1b",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T19:31:21.005Z",
            "endTime": "2021-11-24T19:31:21.005Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj6BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj6BAAAAAAAAA==/",
            "_etag": "\"26003eaf-0000-0100-0000-619eb5e20000\"",
            "_attachments": "attachments/",
            "_ts": 1637791202
        },
        {
            "id": "6aeaa2bb79e0be1becc3dbb779e4179711f9adfe4e1b122d658d9d7f566f9189",
            "conversationId": "6aeaa2bb79e0be1becc3dbb779e4179711f9adfe4e1b122d658d9d7f566f9189",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T19:31:32.261Z",
            "endTime": "2021-11-24T19:31:32.261Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj7BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj7BAAAAAAAAA==/",
            "_etag": "\"26003faf-0000-0100-0000-619eb5e20000\"",
            "_attachments": "attachments/",
            "_ts": 1637791202
        },
        {
            "id": "e80bc395cd2139bc44ffe41be212b24bdd9b625083733780186d032dba9e12fc",
            "conversationId": "e80bc395cd2139bc44ffe41be212b24bdd9b625083733780186d032dba9e12fc",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T19:16:33.137Z",
            "endTime": "2021-11-24T19:16:33.137Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj8BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj8BAAAAAAAAA==/",
            "_etag": "\"26005574-0000-0100-0000-619ea7d10000\"",
            "_attachments": "attachments/",
            "_ts": 1637787601
        },
        {
            "id": "3fe3940882cfead68750323834c51b6bc14ea6e0de7b9d7b085a020e35f3aedf",
            "conversationId": "3fe3940882cfead68750323834c51b6bc14ea6e0de7b9d7b085a020e35f3aedf",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T19:24:10.454Z",
            "endTime": "2021-11-24T19:24:10.454Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj9BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj9BAAAAAAAAA==/",
            "_etag": "\"26005874-0000-0100-0000-619ea7d10000\"",
            "_attachments": "attachments/",
            "_ts": 1637787601
        },
        {
            "id": "f724726dbf4e8168e75ff28d4725aacd25593de71e50b6de1492a45c5c95b2db",
            "conversationId": "f724726dbf4e8168e75ff28d4725aacd25593de71e50b6de1492a45c5c95b2db",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T20:43:22.888Z",
            "endTime": "2021-11-24T20:43:22.888Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj+BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj+BAAAAAAAAA==/",
            "_etag": "\"26006ac1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "b6e5eeb16f2ca9ac61dc028435e9ca5c63c2257f79093d061a9acc56e43ce151",
            "conversationId": "b6e5eeb16f2ca9ac61dc028435e9ca5c63c2257f79093d061a9acc56e43ce151",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T20:34:53.354Z",
            "endTime": "2021-11-24T20:34:53.354Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj-BAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj-BAAAAAAAAA==/",
            "_etag": "\"260077c1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "4f6bccea9c5dbb490cac4575c8681f91b96179ba77f043a2799d910c91afbd4f",
            "conversationId": "4f6bccea9c5dbb490cac4575c8681f91b96179ba77f043a2799d910c91afbd4f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T20:55:56.258Z",
            "endTime": "2021-11-24T20:55:56.258Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgABQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgABQAAAAAAAA==/",
            "_etag": "\"26006cc1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "67c22ece7ffa660422832bfbdaad7242dc41f033a9c114ed405c0116faf63e99",
            "conversationId": "67c22ece7ffa660422832bfbdaad7242dc41f033a9c114ed405c0116faf63e99",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:03:03.134Z",
            "endTime": "2021-11-24T21:03:03.134Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgBBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgBBQAAAAAAAA==/",
            "_etag": "\"26005dc1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "4e4d2e9a0b58a06755974297536d246464a2dce901d589c1a463c6631f028d04",
            "conversationId": "4e4d2e9a0b58a06755974297536d246464a2dce901d589c1a463c6631f028d04",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:08:25.056Z",
            "endTime": "2021-11-24T21:08:36.148Z",
            "timeSpent": 11,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgCBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgCBQAAAAAAAA==/",
            "_etag": "\"260062c1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "d617729baddc985c75e06e4c4679773a5d482a49713cde78764a671a79c1a705",
            "conversationId": "d617729baddc985c75e06e4c4679773a5d482a49713cde78764a671a79c1a705",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:17:26.577Z",
            "endTime": "2021-11-24T21:17:53.285Z",
            "timeSpent": 27,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgDBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgDBQAAAAAAAA==/",
            "_etag": "\"260059c1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "07d06706788ef74decf4ca7899e8a1aa3b6eacbc52b13e5737f3200fcd2e1199",
            "conversationId": "07d06706788ef74decf4ca7899e8a1aa3b6eacbc52b13e5737f3200fcd2e1199",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:21:15.215Z",
            "endTime": "2021-11-24T21:21:15.215Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgEBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgEBQAAAAAAAA==/",
            "_etag": "\"26005ac1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "88b33998863798945ae984e40dc9e16005635c5f4bbeda87194a497512f242b0",
            "conversationId": "88b33998863798945ae984e40dc9e16005635c5f4bbeda87194a497512f242b0",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:21:50.127Z",
            "endTime": "2021-11-24T21:21:50.127Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgFBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgFBQAAAAAAAA==/",
            "_etag": "\"26005bc1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "278714b4675abc6d4a32aab0306f6c5b42059e6db60fc7d8679ceaba31368640",
            "conversationId": "278714b4675abc6d4a32aab0306f6c5b42059e6db60fc7d8679ceaba31368640",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:31:53.965Z",
            "endTime": "2021-11-24T21:31:53.965Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgGBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgGBQAAAAAAAA==/",
            "_etag": "\"26007bd8-0000-0100-0000-619ed2030000\"",
            "_attachments": "attachments/",
            "_ts": 1637798403
        },
        {
            "id": "dac08bde81c7dd9d3bcf1429f6e782935dda940c24107af16f07fbe3ac788893",
            "conversationId": "dac08bde81c7dd9d3bcf1429f6e782935dda940c24107af16f07fbe3ac788893",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:11:42.307Z",
            "endTime": "2021-11-24T21:11:45.367Z",
            "timeSpent": 3,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgHBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgHBQAAAAAAAA==/",
            "_etag": "\"260065c1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "db644ad219556aedcdc640f419aff1f56d335a305b563e90983347dd4efcb4ec",
            "conversationId": "db644ad219556aedcdc640f419aff1f56d335a305b563e90983347dd4efcb4ec",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:14:27.847Z",
            "endTime": "2021-11-24T21:14:32.348Z",
            "timeSpent": 5,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgIBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgIBQAAAAAAAA==/",
            "_etag": "\"260066c1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "21a2fbfaa433e37c98d0dd0a6e331a529cafe33bd7c6d68d7cd048cc66105465",
            "conversationId": "21a2fbfaa433e37c98d0dd0a6e331a529cafe33bd7c6d68d7cd048cc66105465",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:22:23.566Z",
            "endTime": "2021-11-24T21:22:36.43Z",
            "timeSpent": 13,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgJBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgJBQAAAAAAAA==/",
            "_etag": "\"26006fc1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "6a9de8f4c5dcc6294a11937f1c6ceb0899f27dafdbdd35660aa95a93a55ef867",
            "conversationId": "6a9de8f4c5dcc6294a11937f1c6ceb0899f27dafdbdd35660aa95a93a55ef867",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:22:47.377Z",
            "endTime": "2021-11-24T21:23:57.573Z",
            "timeSpent": 70,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgKBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgKBQAAAAAAAA==/",
            "_etag": "\"260070c1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "83581ad9337b3eb0978e56b4821f2d3227b120916bde760187730f68a759dab2",
            "conversationId": "83581ad9337b3eb0978e56b4821f2d3227b120916bde760187730f68a759dab2",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:24:00.235Z",
            "endTime": "2021-11-24T21:24:35.281Z",
            "timeSpent": 35,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgLBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgLBQAAAAAAAA==/",
            "_etag": "\"260071c1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "5eea88a7ebea7219fac3c0595dc3bf3c1a213aaf86219d0bd718b40fcf3f2bbc",
            "conversationId": "5eea88a7ebea7219fac3c0595dc3bf3c1a213aaf86219d0bd718b40fcf3f2bbc",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:25:01.32Z",
            "endTime": "2021-11-24T21:25:07.571Z",
            "timeSpent": 6,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgMBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgMBQAAAAAAAA==/",
            "_etag": "\"260072c1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "9bda6c1709f1079e9b6fdd25dbe32446619afa7349fbc2af37693a030525d4d4",
            "conversationId": "9bda6c1709f1079e9b6fdd25dbe32446619afa7349fbc2af37693a030525d4d4",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:25:12.014Z",
            "endTime": "2021-11-24T21:25:12.014Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgNBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgNBQAAAAAAAA==/",
            "_etag": "\"260073c1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "4cdc309fcd451e269e188ce5068bc44465b58ad19496c608d0b0e34401e4d1a2",
            "conversationId": "4cdc309fcd451e269e188ce5068bc44465b58ad19496c608d0b0e34401e4d1a2",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:28:19.32Z",
            "endTime": "2021-11-24T21:28:19.32Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgOBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgOBQAAAAAAAA==/",
            "_etag": "\"260074c1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "d2a35ffd7f61037b47674890d5d10d2fd3097a5c44ed1340e9926e7e3969520a",
            "conversationId": "d2a35ffd7f61037b47674890d5d10d2fd3097a5c44ed1340e9926e7e3969520a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:04:16.12Z",
            "endTime": "2021-11-24T21:04:16.12Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgPBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgPBQAAAAAAAA==/",
            "_etag": "\"26005fc1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "258a277d573aec733f1072c625b498a422456ca181460ad416e149e90deb611e",
            "conversationId": "258a277d573aec733f1072c625b498a422456ca181460ad416e149e90deb611e",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:04:35.229Z",
            "endTime": "2021-11-24T21:04:35.229Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgQBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgQBQAAAAAAAA==/",
            "_etag": "\"260060c1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "b9efe29bd98998c3e6d98dbbcb16d7e635e0f6774e126eb61eacdab8a583bbf8",
            "conversationId": "b9efe29bd98998c3e6d98dbbcb16d7e635e0f6774e126eb61eacdab8a583bbf8",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:04:02.865Z",
            "endTime": "2021-11-24T21:04:02.865Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgRBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgRBQAAAAAAAA==/",
            "_etag": "\"26005ec1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "348006cfa4309ac20568fead4ff916ca38a1edbfad8f7607195cf7ddf93e64cc",
            "conversationId": "348006cfa4309ac20568fead4ff916ca38a1edbfad8f7607195cf7ddf93e64cc",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:05:02.649Z",
            "endTime": "2021-11-24T21:05:21.868Z",
            "timeSpent": 19,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgSBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgSBQAAAAAAAA==/",
            "_etag": "\"260061c1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "6caf6e38b74804defc6994e0d75fee5b86f6ca9c4386d9b8627055efe4f41070",
            "conversationId": "6caf6e38b74804defc6994e0d75fee5b86f6ca9c4386d9b8627055efe4f41070",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:08:51.214Z",
            "endTime": "2021-11-24T21:08:51.214Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgTBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgTBQAAAAAAAA==/",
            "_etag": "\"260063c1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "02a56e099d5da2d7526edea30f4703e83c836d26d0188d6e438d9a27d8358827",
            "conversationId": "02a56e099d5da2d7526edea30f4703e83c836d26d0188d6e438d9a27d8358827",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:10:35.99Z",
            "endTime": "2021-11-24T21:10:35.99Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgUBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgUBQAAAAAAAA==/",
            "_etag": "\"260064c1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "00ec53ae3f4d87cccd16fbf7405eb37fa8efb6505152fc59cfa18f9c7898f8bc",
            "conversationId": "00ec53ae3f4d87cccd16fbf7405eb37fa8efb6505152fc59cfa18f9c7898f8bc",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:13:09.851Z",
            "endTime": "2021-11-24T21:13:09.851Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgVBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgVBQAAAAAAAA==/",
            "_etag": "\"260069c1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "402b2a85d9bbd79df8f91b69b882930f87e72d9cf3a83440a400ee32c01407b5",
            "conversationId": "402b2a85d9bbd79df8f91b69b882930f87e72d9cf3a83440a400ee32c01407b5",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:15:50.874Z",
            "endTime": "2021-11-24T21:16:16.337Z",
            "timeSpent": 25,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgWBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgWBQAAAAAAAA==/",
            "_etag": "\"26006bc1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "0547689f453b9f8aff8defd4b23e7561de99866e4d8b5c32236ec9166e69a758",
            "conversationId": "0547689f453b9f8aff8defd4b23e7561de99866e4d8b5c32236ec9166e69a758",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:29:28.621Z",
            "endTime": "2021-11-24T21:29:28.621Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgXBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgXBQAAAAAAAA==/",
            "_etag": "\"26006dc1-0000-0100-0000-619ec3f20000\"",
            "_attachments": "attachments/",
            "_ts": 1637794802
        },
        {
            "id": "0b32fed9ead32d6a9ebfdab08dcec3b44fd5c128d8a94a16a4ea3a7289c30c92",
            "conversationId": "0b32fed9ead32d6a9ebfdab08dcec3b44fd5c128d8a94a16a4ea3a7289c30c92",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T21:47:29.331Z",
            "endTime": "2021-11-24T21:47:29.331Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgYBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgYBQAAAAAAAA==/",
            "_etag": "\"260088d8-0000-0100-0000-619ed2030000\"",
            "_attachments": "attachments/",
            "_ts": 1637798403
        },
        {
            "id": "3fb97ea4f4e4bbd54155cda76443f265a79403ac971d8a127362a37c8578a77a",
            "conversationId": "3fb97ea4f4e4bbd54155cda76443f265a79403ac971d8a127362a37c8578a77a",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T22:07:02.52Z",
            "endTime": "2021-11-24T22:07:02.52Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgZBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgZBQAAAAAAAA==/",
            "_etag": "\"26007cd8-0000-0100-0000-619ed2030000\"",
            "_attachments": "attachments/",
            "_ts": 1637798403
        },
        {
            "id": "fa41ea2fb73b8f92b7a1f3ebc5bac76fda0f69472bbac156cbd5a5fa42eeea92",
            "conversationId": "fa41ea2fb73b8f92b7a1f3ebc5bac76fda0f69472bbac156cbd5a5fa42eeea92",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T22:09:17.799Z",
            "endTime": "2021-11-24T22:09:17.799Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgaBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgaBQAAAAAAAA==/",
            "_etag": "\"26007dd8-0000-0100-0000-619ed2030000\"",
            "_attachments": "attachments/",
            "_ts": 1637798403
        },
        {
            "id": "2315aa7005b03ab45751cd9d39f5f7ceaa318eb8e75f1a17703c81446c9f0a07",
            "conversationId": "2315aa7005b03ab45751cd9d39f5f7ceaa318eb8e75f1a17703c81446c9f0a07",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T22:00:17.441Z",
            "endTime": "2021-11-24T22:00:17.441Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgbBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgbBQAAAAAAAA==/",
            "_etag": "\"26008bd8-0000-0100-0000-619ed2030000\"",
            "_attachments": "attachments/",
            "_ts": 1637798403
        },
        {
            "id": "dacba3a924674f5ab3c3ff9fb8913b39f9b65627f40740cb09723fcfd145648f",
            "conversationId": "dacba3a924674f5ab3c3ff9fb8913b39f9b65627f40740cb09723fcfd145648f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T22:00:58.648Z",
            "endTime": "2021-11-24T22:00:58.648Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgcBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgcBQAAAAAAAA==/",
            "_etag": "\"26007fd8-0000-0100-0000-619ed2030000\"",
            "_attachments": "attachments/",
            "_ts": 1637798403
        },
        {
            "id": "2d1eab3df3ef37da3e3523be3a28703901e35e10067cbeeb807d795e00b5fb0e",
            "conversationId": "2d1eab3df3ef37da3e3523be3a28703901e35e10067cbeeb807d795e00b5fb0e",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T22:04:56.211Z",
            "endTime": "2021-11-24T22:04:56.211Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgdBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgdBQAAAAAAAA==/",
            "_etag": "\"26007ed8-0000-0100-0000-619ed2030000\"",
            "_attachments": "attachments/",
            "_ts": 1637798403
        },
        {
            "id": "15261c74960a4d5a34c7a04bb776cb3ca0ec2f02c20c02960a83465b77b45d99",
            "conversationId": "15261c74960a4d5a34c7a04bb776cb3ca0ec2f02c20c02960a83465b77b45d99",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T22:05:30.17Z",
            "endTime": "2021-11-24T22:05:30.17Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgeBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgeBQAAAAAAAA==/",
            "_etag": "\"260080d8-0000-0100-0000-619ed2030000\"",
            "_attachments": "attachments/",
            "_ts": 1637798403
        },
        {
            "id": "77b8ec6516ae30d0c3ab1dc4f1b6d6da2a41290fecec4dd7681df6a5d8478b72",
            "conversationId": "77b8ec6516ae30d0c3ab1dc4f1b6d6da2a41290fecec4dd7681df6a5d8478b72",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T22:05:55.73Z",
            "endTime": "2021-11-24T22:05:55.73Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgfBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgfBQAAAAAAAA==/",
            "_etag": "\"260081d8-0000-0100-0000-619ed2030000\"",
            "_attachments": "attachments/",
            "_ts": 1637798403
        },
        {
            "id": "6a16e432dc647a8ecf514559abd8facfcde46838b8b479abdddfb1d8ed638a3f",
            "conversationId": "6a16e432dc647a8ecf514559abd8facfcde46838b8b479abdddfb1d8ed638a3f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T22:06:40.892Z",
            "endTime": "2021-11-24T22:06:40.892Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIggBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIggBQAAAAAAAA==/",
            "_etag": "\"260082d8-0000-0100-0000-619ed2030000\"",
            "_attachments": "attachments/",
            "_ts": 1637798403
        },
        {
            "id": "e2a6d6ef4129b086fe280a32aaf63e5f8a142ace5c548ff27802acea34b363b7",
            "conversationId": "e2a6d6ef4129b086fe280a32aaf63e5f8a142ace5c548ff27802acea34b363b7",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T22:14:29.593Z",
            "endTime": "2021-11-24T22:14:29.593Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIghBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIghBQAAAAAAAA==/",
            "_etag": "\"260083d8-0000-0100-0000-619ed2030000\"",
            "_attachments": "attachments/",
            "_ts": 1637798403
        },
        {
            "id": "ba2708bc1307903ec60082dc9cf36901a5a00ea1a54d582daf3fddb63aa26101",
            "conversationId": "ba2708bc1307903ec60082dc9cf36901a5a00ea1a54d582daf3fddb63aa26101",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T22:15:56.038Z",
            "endTime": "2021-11-24T22:15:56.038Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgiBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgiBQAAAAAAAA==/",
            "_etag": "\"260084d8-0000-0100-0000-619ed2030000\"",
            "_attachments": "attachments/",
            "_ts": 1637798403
        },
        {
            "id": "9ec81690927b60fa2743fa3a17d748a49d1d6be3b352c0507a889d14402fde3b",
            "conversationId": "9ec81690927b60fa2743fa3a17d748a49d1d6be3b352c0507a889d14402fde3b",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T22:17:37.548Z",
            "endTime": "2021-11-24T22:17:37.548Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgjBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgjBQAAAAAAAA==/",
            "_etag": "\"260085d8-0000-0100-0000-619ed2030000\"",
            "_attachments": "attachments/",
            "_ts": 1637798403
        },
        {
            "id": "910b8cf11502b40a37d44ac46c76bafae51540d2364a62e00c25dcde404283ab",
            "conversationId": "910b8cf11502b40a37d44ac46c76bafae51540d2364a62e00c25dcde404283ab",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T22:18:07.176Z",
            "endTime": "2021-11-24T22:18:07.176Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgkBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgkBQAAAAAAAA==/",
            "_etag": "\"260086d8-0000-0100-0000-619ed2030000\"",
            "_attachments": "attachments/",
            "_ts": 1637798403
        },
        {
            "id": "e030adb1786a95a354d15672e8e88b4ffa1489ccec0ccb4458da42dceb88f404",
            "conversationId": "e030adb1786a95a354d15672e8e88b4ffa1489ccec0ccb4458da42dceb88f404",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T22:18:22.35Z",
            "endTime": "2021-11-24T22:18:22.35Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIglBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIglBQAAAAAAAA==/",
            "_etag": "\"260087d8-0000-0100-0000-619ed2030000\"",
            "_attachments": "attachments/",
            "_ts": 1637798403
        },
        {
            "id": "edc94d33fbc54748169e19edca105a477164cd9989c7991f9fd0e097334cbb5d",
            "conversationId": "edc94d33fbc54748169e19edca105a477164cd9989c7991f9fd0e097334cbb5d",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T22:23:27.636Z",
            "endTime": "2021-11-24T22:23:27.636Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgmBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgmBQAAAAAAAA==/",
            "_etag": "\"260089d8-0000-0100-0000-619ed2030000\"",
            "_attachments": "attachments/",
            "_ts": 1637798403
        },
        {
            "id": "58bd11a907ca2e7eb406260cace22b90f8fb632428083e104a2f0db97d30027f",
            "conversationId": "58bd11a907ca2e7eb406260cace22b90f8fb632428083e104a2f0db97d30027f",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-24T22:23:33.24Z",
            "endTime": "2021-11-24T22:23:33.24Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgnBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgnBQAAAAAAAA==/",
            "_etag": "\"26008ad8-0000-0100-0000-619ed2030000\"",
            "_attachments": "attachments/",
            "_ts": 1637798403
},
    
        {
            "id": "74ff3cb229a7c3475a87e97bebae22cf969749fb04122301c559f80feaab3092",
            "conversationId": "74ff3cb229a7c3475a87e97bebae22cf969749fb04122301c559f80feaab3092",
            "cdoId": null,
            "cdoName": "M&R",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-25T06:01:41.993Z",
            "endTime": "2021-11-25T06:01:54.155Z",
            "timeSpent": 12,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgoBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgoBQAAAAAAAA==/",
            "_etag": "\"2800a135-0000-0100-0000-619f42830000\"",
            "_attachments": "attachments/",
            "_ts": 1637827203
        },
        {
            "id": "8509ef00edc98855738fecc1193386c21fed3bcc4118fba75349e063709d6c4c",
            "conversationId": "8509ef00edc98855738fecc1193386c21fed3bcc4118fba75349e063709d6c4c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Diarrhea",
                "Wrist drop"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Call 9-1-1",
            "patientPlansTo": "Call me within two days",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-11-25T11:05:37.966Z",
            "endTime": "2021-11-25T11:10:23.316Z",
            "timeSpent": 285,
            "isNoteGenerated": "yes",
            "npsGroup": "Passive",
            "npsScore": 8,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgpBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgpBQAAAAAAAA==/",
            "_etag": "\"2900054f-0000-0100-0000-619f88d10000\"",
            "_attachments": "attachments/",
            "_ts": 1637845201
        },
        {
            "id": "83bbc288043d4436594cc82ef701f714578930571fc1dfb06232b263c8745076",
            "conversationId": "83bbc288043d4436594cc82ef701f714578930571fc1dfb06232b263c8745076",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain",
                "Thigh injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the Emergency Room",
            "patientPlansTo": "I will go to ReadyMED / Urgent Care",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-30T10:12:47.557Z",
            "endTime": "2021-11-30T10:13:55.82Z",
            "timeSpent": 68,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgqBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgqBQAAAAAAAA==/",
            "_etag": "\"32006993-0000-0100-0000-61a612410000\"",
            "_attachments": "attachments/",
            "_ts": 1638273601
        },
        {
            "id": "2f89014c1de93a4280f722f24d2820d5d03fd5b26572f95f1fb2fec41c26f593",
            "conversationId": "2f89014c1de93a4280f722f24d2820d5d03fd5b26572f95f1fb2fec41c26f593",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-30T10:09:59.506Z",
            "endTime": "2021-11-30T10:12:23.132Z",
            "timeSpent": 144,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgrBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgrBQAAAAAAAA==/",
            "_etag": "\"32006793-0000-0100-0000-61a612410000\"",
            "_attachments": "attachments/",
            "_ts": 1638273601
        },
        {
            "id": "00d23c7a11dc8216b8d87023b1554084dd993cffb70b10975b764deed2942afa",
            "conversationId": "00d23c7a11dc8216b8d87023b1554084dd993cffb70b10975b764deed2942afa",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Thigh injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-11-30T10:10:59.733Z",
            "endTime": "2021-11-30T10:12:25.217Z",
            "timeSpent": 85,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgsBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgsBQAAAAAAAA==/",
            "_etag": "\"32006893-0000-0100-0000-61a612410000\"",
            "_attachments": "attachments/",
            "_ts": 1638273601
        },
        {
            "id": "6486cf4e6eb61577e9cc6f68b9f3fd4a3a15b513beb517d40b13db02c007e59f",
            "conversationId": "6486cf4e6eb61577e9cc6f68b9f3fd4a3a15b513beb517d40b13db02c007e59f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sinus pain or pressure"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-11-30T18:17:53.886Z",
            "endTime": "2021-11-30T18:18:35.732Z",
            "timeSpent": 42,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgtBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgtBQAAAAAAAA==/",
            "_etag": "\"34009666-0000-0100-0000-61a682c30000\"",
            "_attachments": "attachments/",
            "_ts": 1638302403
        },
        {
            "id": "95c908b0ecdb61c3bc78c9d2a4aadfc6e1b38b5db49e1d476da0787e03a7759b",
            "conversationId": "95c908b0ecdb61c3bc78c9d2a4aadfc6e1b38b5db49e1d476da0787e03a7759b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-01T17:13:21.328Z",
            "endTime": "2021-12-01T17:13:21.328Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIguBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIguBQAAAAAAAA==/",
            "_etag": "\"3b00e1cb-0000-0100-0000-61a7c6340000\"",
            "_attachments": "attachments/",
            "_ts": 1638385204
        },
        {
            "id": "5a76055a742a12f957a865c8e237dcbfdf8b58040673a5d148df93dc56e9cd2c",
            "conversationId": "5a76055a742a12f957a865c8e237dcbfdf8b58040673a5d148df93dc56e9cd2c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-02T09:12:12.868Z",
            "endTime": "2021-12-02T09:12:12.868Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgvBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgvBQAAAAAAAA==/",
            "_etag": "\"3e00aba9-0000-0100-0000-61a8a7320000\"",
            "_attachments": "attachments/",
            "_ts": 1638442802
        },
        {
            "id": "062d2fbb23b00d8f6038d363e89f373fa9a9ce9451679558e292d8cd8e8ac50e",
            "conversationId": "062d2fbb23b00d8f6038d363e89f373fa9a9ce9451679558e292d8cd8e8ac50e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T15:47:42.099Z",
            "endTime": "2021-12-09T15:47:48.756Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgwBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgwBQAAAAAAAA==/",
            "_etag": "\"37005e7e-0000-0100-0000-61b244280000\"",
            "_attachments": "attachments/",
            "_ts": 1639072808
        },
        {
            "id": "b04604e405f98aac934845037af55add5f6e5c21388bdc84b87c3c434cdbb9a3",
            "conversationId": "b04604e405f98aac934845037af55add5f6e5c21388bdc84b87c3c434cdbb9a3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T15:00:00Z",
            "endTime": "2021-12-09T15:29:08.86Z",
            "timeSpent": 1749,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgxBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgxBQAAAAAAAA==/",
            "_etag": "\"3700eb37-0000-0100-0000-61b236130000\"",
            "_attachments": "attachments/",
            "_ts": 1639069203
        },
        {
            "id": "d155c5000d1f071037006e0e64af2c3e8a8ee3aad4ce346c84276d1d1546b25d",
            "conversationId": "d155c5000d1f071037006e0e64af2c3e8a8ee3aad4ce346c84276d1d1546b25d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T15:52:34.467Z",
            "endTime": "2021-12-09T15:52:44.643Z",
            "timeSpent": 10,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgyBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgyBQAAAAAAAA==/",
            "_etag": "\"37005f7e-0000-0100-0000-61b244280000\"",
            "_attachments": "attachments/",
            "_ts": 1639072808
        },
        {
            "id": "4704b221b1f9f77fd889a270287caa9cc1f7ba9e73bc9e91c7a5132576b26ff0",
            "conversationId": "4704b221b1f9f77fd889a270287caa9cc1f7ba9e73bc9e91c7a5132576b26ff0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T15:52:52.308Z",
            "endTime": "2021-12-09T15:52:57.817Z",
            "timeSpent": 6,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgzBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgzBQAAAAAAAA==/",
            "_etag": "\"3700607e-0000-0100-0000-61b244280000\"",
            "_attachments": "attachments/",
            "_ts": 1639072808
        },
        {
            "id": "4acd839cd52b0c17ec422707156041a44ba41946aa0e7c9fa581e64b82bb199d",
            "conversationId": "4acd839cd52b0c17ec422707156041a44ba41946aa0e7c9fa581e64b82bb199d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T15:29:24.211Z",
            "endTime": "2021-12-09T15:29:34.503Z",
            "timeSpent": 10,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg0BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg0BQAAAAAAAA==/",
            "_etag": "\"3700ee37-0000-0100-0000-61b236130000\"",
            "_attachments": "attachments/",
            "_ts": 1639069203
        },
        {
            "id": "68426adcdbc6b146c3ec6a86098e433555569c02c7f38040a4799bad8a096017",
            "conversationId": "68426adcdbc6b146c3ec6a86098e433555569c02c7f38040a4799bad8a096017",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T15:42:14.314Z",
            "endTime": "2021-12-09T15:42:14.314Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg1BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg1BQAAAAAAAA==/",
            "_etag": "\"37005c7e-0000-0100-0000-61b244280000\"",
            "_attachments": "attachments/",
            "_ts": 1639072808
        },
        {
            "id": "8988827d2e1b58f43f9327785dceeb34cc0386e21d0d7d0280843ec3faad2d68",
            "conversationId": "8988827d2e1b58f43f9327785dceeb34cc0386e21d0d7d0280843ec3faad2d68",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T15:45:36.473Z",
            "endTime": "2021-12-09T15:45:36.473Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg2BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg2BQAAAAAAAA==/",
            "_etag": "\"37005d7e-0000-0100-0000-61b244280000\"",
            "_attachments": "attachments/",
            "_ts": 1639072808
        },
        {
            "id": "e90740d2285a5a31001708fdc53d9f36853fd7f7f7e9685a31eeb0888a609a44",
            "conversationId": "e90740d2285a5a31001708fdc53d9f36853fd7f7f7e9685a31eeb0888a609a44",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T16:00:00Z",
            "endTime": "2021-12-09T16:28:54.469Z",
            "timeSpent": 1734,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg3BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg3BQAAAAAAAA==/",
            "_etag": "\"37004f7e-0000-0100-0000-61b244280000\"",
            "_attachments": "attachments/",
            "_ts": 1639072808
        },
        {
            "id": "6f99c184e2f4b233013f420dae3971654b9139bfd9513003339f7c2f4b902779",
            "conversationId": "6f99c184e2f4b233013f420dae3971654b9139bfd9513003339f7c2f4b902779",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will try Self-Care",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-12-09T16:51:33.74Z",
            "endTime": "2021-12-09T16:57:49.376Z",
            "timeSpent": 376,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg4BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg4BQAAAAAAAA==/",
            "_etag": "\"37004b7e-0000-0100-0000-61b244280000\"",
            "_attachments": "attachments/",
            "_ts": 1639072808
        },
        {
            "id": "8969a86a42ee20644b7e15bb69acde34da7e936d1a985649d39c632e376c7af9",
            "conversationId": "8969a86a42ee20644b7e15bb69acde34da7e936d1a985649d39c632e376c7af9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T16:00:00Z",
            "endTime": "2021-12-09T16:33:09.977Z",
            "timeSpent": 1990,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg5BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg5BQAAAAAAAA==/",
            "_etag": "\"3700527e-0000-0100-0000-61b244280000\"",
            "_attachments": "attachments/",
            "_ts": 1639072808
        },
        {
            "id": "9999d4afdb7bad709e62a95fc4671c6446a3408ce49de20ec28c91bcabc349db",
            "conversationId": "9999d4afdb7bad709e62a95fc4671c6446a3408ce49de20ec28c91bcabc349db",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T16:36:10.167Z",
            "endTime": "2021-12-09T16:37:09.043Z",
            "timeSpent": 59,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg6BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg6BQAAAAAAAA==/",
            "_etag": "\"3700567e-0000-0100-0000-61b244280000\"",
            "_attachments": "attachments/",
            "_ts": 1639072808
        },
        {
            "id": "550a2436f2d1057eb19eda65a260e209e5890604b88aaca65ea946a5dda11428",
            "conversationId": "550a2436f2d1057eb19eda65a260e209e5890604b88aaca65ea946a5dda11428",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T16:00:00Z",
            "endTime": "2021-12-09T16:21:02.704Z",
            "timeSpent": 1263,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg7BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg7BQAAAAAAAA==/",
            "_etag": "\"3700597e-0000-0100-0000-61b244280000\"",
            "_attachments": "attachments/",
            "_ts": 1639072808
        },
        {
            "id": "806d03012d2656ea1721631025ff0341b5bbc318a9abd3f07e77e2d9b5ab6ca4",
            "conversationId": "806d03012d2656ea1721631025ff0341b5bbc318a9abd3f07e77e2d9b5ab6ca4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T16:04:40.1Z",
            "endTime": "2021-12-09T16:05:07.38Z",
            "timeSpent": 27,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg8BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg8BQAAAAAAAA==/",
            "_etag": "\"3700547e-0000-0100-0000-61b244280000\"",
            "_attachments": "attachments/",
            "_ts": 1639072808
        },
        {
            "id": "7cc4f3dd054c3004af0df48948b6eebacd27b3cf56eb40cdeaf223542445caf2",
            "conversationId": "7cc4f3dd054c3004af0df48948b6eebacd27b3cf56eb40cdeaf223542445caf2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T16:02:01.704Z",
            "endTime": "2021-12-09T16:04:18.447Z",
            "timeSpent": 137,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg9BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg9BQAAAAAAAA==/",
            "_etag": "\"3700537e-0000-0100-0000-61b244280000\"",
            "_attachments": "attachments/",
            "_ts": 1639072808
        },
        {
            "id": "11a66d4f04377a4f3dccb6f9684a427eb97ae88fec28416ffe2a3f0765bf0048",
            "conversationId": "11a66d4f04377a4f3dccb6f9684a427eb97ae88fec28416ffe2a3f0765bf0048",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T16:07:14.292Z",
            "endTime": "2021-12-09T16:08:01.696Z",
            "timeSpent": 47,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg+BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg+BQAAAAAAAA==/",
            "_etag": "\"3700577e-0000-0100-0000-61b244280000\"",
            "_attachments": "attachments/",
            "_ts": 1639072808
        },
        {
            "id": "1f8ddbc147f600b1d7c3e4cc1a9cb2af537012b18fd0bc399d783c3ff951aedc",
            "conversationId": "1f8ddbc147f600b1d7c3e4cc1a9cb2af537012b18fd0bc399d783c3ff951aedc",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T16:13:46.968Z",
            "endTime": "2021-12-09T16:16:48.078Z",
            "timeSpent": 181,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg-BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg-BQAAAAAAAA==/",
            "_etag": "\"3700587e-0000-0100-0000-61b244280000\"",
            "_attachments": "attachments/",
            "_ts": 1639072808
        },
        {
            "id": "b65b736c7915c4a157940c4b4978d48cf230aaac07c913e2cb381ce9ded5e39b",
            "conversationId": "b65b736c7915c4a157940c4b4978d48cf230aaac07c913e2cb381ce9ded5e39b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T16:26:28.258Z",
            "endTime": "2021-12-09T16:26:28.258Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhABQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhABQAAAAAAAA==/",
            "_etag": "\"37004a7e-0000-0100-0000-61b244280000\"",
            "_attachments": "attachments/",
            "_ts": 1639072808
        },
        {
            "id": "6168ed47f5ce676d36079ad9326ba592747906e71fbf798f3eb609ae9daf1324",
            "conversationId": "6168ed47f5ce676d36079ad9326ba592747906e71fbf798f3eb609ae9daf1324",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T16:08:44.004Z",
            "endTime": "2021-12-09T16:10:12.438Z",
            "timeSpent": 88,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhBBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhBBQAAAAAAAA==/",
            "_etag": "\"37005a7e-0000-0100-0000-61b244280000\"",
            "_attachments": "attachments/",
            "_ts": 1639072808
        },
        {
            "id": "f5c8235e561240c8e137139ff8549a0932be7acddf2d6f03a235c14589ac65ab",
            "conversationId": "f5c8235e561240c8e137139ff8549a0932be7acddf2d6f03a235c14589ac65ab",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T16:05:26.556Z",
            "endTime": "2021-12-09T16:05:35.129Z",
            "timeSpent": 9,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhCBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhCBQAAAAAAAA==/",
            "_etag": "\"3700557e-0000-0100-0000-61b244280000\"",
            "_attachments": "attachments/",
            "_ts": 1639072808
        },
        {
            "id": "47ed2c7d407feea177373421a05e415b6f16a7d3f85f0d5b757cf8fca8f42597",
            "conversationId": "47ed2c7d407feea177373421a05e415b6f16a7d3f85f0d5b757cf8fca8f42597",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T16:00:00Z",
            "endTime": "2021-12-09T16:24:11.438Z",
            "timeSpent": 1451,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhDBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhDBQAAAAAAAA==/",
            "_etag": "\"37005b7e-0000-0100-0000-61b244280000\"",
            "_attachments": "attachments/",
            "_ts": 1639072808
        },
        {
            "id": "f93f72f3958e05fe89ae561ba3af6f0395ce55c0770fea55b46804fdd2859f24",
            "conversationId": "f93f72f3958e05fe89ae561ba3af6f0395ce55c0770fea55b46804fdd2859f24",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T17:01:32.199Z",
            "endTime": "2021-12-09T17:02:02.817Z",
            "timeSpent": 31,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhEBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhEBQAAAAAAAA==/",
            "_etag": "\"3700ffb8-0000-0100-0000-61b252350000\"",
            "_attachments": "attachments/",
            "_ts": 1639076405
        },
        {
            "id": "5da842d35123e557c28b6a25e1159dd9137946587067465ae59a9a8ad645a29e",
            "conversationId": "5da842d35123e557c28b6a25e1159dd9137946587067465ae59a9a8ad645a29e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T17:04:12.697Z",
            "endTime": "2021-12-09T17:04:19.656Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhFBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhFBQAAAAAAAA==/",
            "_etag": "\"370000b9-0000-0100-0000-61b252350000\"",
            "_attachments": "attachments/",
            "_ts": 1639076405
        },
        {
            "id": "427da9946e2f336a206862bf32285e0510460a4c88a23d1e0431b0eba56f6fe5",
            "conversationId": "427da9946e2f336a206862bf32285e0510460a4c88a23d1e0431b0eba56f6fe5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sinus pain or pressure"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "Send me a MyChart message within two days",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2021-12-09T17:09:24.684Z",
            "endTime": "2021-12-09T17:12:52.085Z",
            "timeSpent": 207,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 6,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhGBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhGBQAAAAAAAA==/",
            "_etag": "\"370001b9-0000-0100-0000-61b252350000\"",
            "_attachments": "attachments/",
            "_ts": 1639076405
        },
        {
            "id": "579c9c8585ed151b3aa9add2702c1f3f047774cc241f010eac6e7651e6327911",
            "conversationId": "579c9c8585ed151b3aa9add2702c1f3f047774cc241f010eac6e7651e6327911",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T17:04:53.74Z",
            "endTime": "2021-12-09T17:09:07.411Z",
            "timeSpent": 254,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhHBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhHBQAAAAAAAA==/",
            "_etag": "\"370003b9-0000-0100-0000-61b252350000\"",
            "_attachments": "attachments/",
            "_ts": 1639076405
        },
        {
            "id": "ebd54a2c76ffc913c8a4990127c2a1dc5b02b9d7ce89b017ac7d567d6149288d",
            "conversationId": "ebd54a2c76ffc913c8a4990127c2a1dc5b02b9d7ce89b017ac7d567d6149288d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T17:04:51.529Z",
            "endTime": "2021-12-09T17:04:51.529Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhIBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhIBQAAAAAAAA==/",
            "_etag": "\"370002b9-0000-0100-0000-61b252350000\"",
            "_attachments": "attachments/",
            "_ts": 1639076405
        },
        {
            "id": "c03b24df15db34eca91108d7836cd2188b9c11b862101c0a83af46084c46421a",
            "conversationId": "c03b24df15db34eca91108d7836cd2188b9c11b862101c0a83af46084c46421a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T19:55:26.901Z",
            "endTime": "2021-12-09T19:55:36.985Z",
            "timeSpent": 10,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhJBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhJBQAAAAAAAA==/",
            "_etag": "\"38002993-0000-0100-0000-61b27c630000\"",
            "_attachments": "attachments/",
            "_ts": 1639087203
        },
        {
            "id": "75df16bebc564df98844012dc2399c72b37c699a61af97d984fdc87c4b487725",
            "conversationId": "75df16bebc564df98844012dc2399c72b37c699a61af97d984fdc87c4b487725",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Feeling sick"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2021-12-09T20:32:47.877Z",
            "endTime": "2021-12-09T20:49:01.184Z",
            "timeSpent": 973,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 6,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhKBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhKBQAAAAAAAA==/",
            "_etag": "\"38002a93-0000-0100-0000-61b27c630000\"",
            "_attachments": "attachments/",
            "_ts": 1639087203
        },
        {
            "id": "ae4da47fc6af3560087afb52b9b6d4ee530161ecef41f915195efdb602e4b98d",
            "conversationId": "ae4da47fc6af3560087afb52b9b6d4ee530161ecef41f915195efdb602e4b98d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-12-09T20:59:01.562Z",
            "endTime": "2021-12-09T20:59:18.485Z",
            "timeSpent": 17,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhLBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhLBQAAAAAAAA==/",
            "_etag": "\"38002b93-0000-0100-0000-61b27c630000\"",
            "_attachments": "attachments/",
            "_ts": 1639087203
        },
        {
            "id": "9952eb73399a51ee4c2253adbae6f2349c30381f1dc20fa25976030510809ed7",
            "conversationId": "9952eb73399a51ee4c2253adbae6f2349c30381f1dc20fa25976030510809ed7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T20:00:00Z",
            "endTime": "2021-12-09T20:31:26.172Z",
            "timeSpent": 1886,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhMBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhMBQAAAAAAAA==/",
            "_etag": "\"38002c93-0000-0100-0000-61b27c630000\"",
            "_attachments": "attachments/",
            "_ts": 1639087203
        },
        {
            "id": "a049cdcc5ee52bf0d5f519353ae8a0e6344ad197e23302063b9e1f989ed4f940",
            "conversationId": "a049cdcc5ee52bf0d5f519353ae8a0e6344ad197e23302063b9e1f989ed4f940",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T20:00:00Z",
            "endTime": "2021-12-09T20:32:02.235Z",
            "timeSpent": 1922,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhNBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhNBQAAAAAAAA==/",
            "_etag": "\"38002d93-0000-0100-0000-61b27c630000\"",
            "_attachments": "attachments/",
            "_ts": 1639087203
        },
        {
            "id": "2886e2e8c9b51464f6a917db56da5497127756edb6fbcff5b9960cd1a02b4b82",
            "conversationId": "2886e2e8c9b51464f6a917db56da5497127756edb6fbcff5b9960cd1a02b4b82",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-09T20:06:18.809Z",
            "endTime": "2021-12-09T20:06:41.308Z",
            "timeSpent": 22,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhOBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhOBQAAAAAAAA==/",
            "_etag": "\"38002e93-0000-0100-0000-61b27c630000\"",
            "_attachments": "attachments/",
            "_ts": 1639087203
        },
        {
            "id": "c7f4a33275924c0bee76de1434ecee9a8870b545ba19a7bc601c2de45a85f75d",
            "conversationId": "c7f4a33275924c0bee76de1434ecee9a8870b545ba19a7bc601c2de45a85f75d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try Self-Care",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-12-10T02:29:12.384Z",
            "endTime": "2021-12-10T02:38:12.965Z",
            "timeSpent": 541,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhPBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhPBQAAAAAAAA==/",
            "_etag": "\"3900b893-0000-0100-0000-61b2d0c50000\"",
            "_attachments": "attachments/",
            "_ts": 1639108805
        },
        {
            "id": "1465a8b8ae352f786f244fcf2d9c1c26dcea9b34344c53c40c62690f21ec0e8d",
            "conversationId": "1465a8b8ae352f786f244fcf2d9c1c26dcea9b34344c53c40c62690f21ec0e8d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache",
                "Red eye"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "Send me a MyChart message within two days",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2021-12-10T02:23:06.095Z",
            "endTime": "2021-12-10T02:27:49.978Z",
            "timeSpent": 284,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhQBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhQBQAAAAAAAA==/",
            "_etag": "\"3900b993-0000-0100-0000-61b2d0c50000\"",
            "_attachments": "attachments/",
            "_ts": 1639108805
        },
        {
            "id": "22a7c96b02990cdd859a4b6452713a7b08fd547bd9b7219675c796c988aaf326",
            "conversationId": "22a7c96b02990cdd859a4b6452713a7b08fd547bd9b7219675c796c988aaf326",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will try Self-Care",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-12-10T08:05:37.945Z",
            "endTime": "2021-12-10T08:10:42.975Z",
            "timeSpent": 305,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhRBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhRBQAAAAAAAA==/",
            "_etag": "\"3b007b1c-0000-0100-0000-61b325290000\"",
            "_attachments": "attachments/",
            "_ts": 1639130409
        },
        {
            "id": "38b70d8c1bfe1df194e33fab745b208e3186eb6408a3f99d18ee83bad52697c9",
            "conversationId": "38b70d8c1bfe1df194e33fab745b208e3186eb6408a3f99d18ee83bad52697c9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-12-10T08:03:59.173Z",
            "endTime": "2021-12-10T08:04:19.667Z",
            "timeSpent": 20,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhSBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhSBQAAAAAAAA==/",
            "_etag": "\"3b007a1c-0000-0100-0000-61b325290000\"",
            "_attachments": "attachments/",
            "_ts": 1639130409
        },
        {
            "id": "6ab20671ffc456d26d809223f9c8314453771e9f9fd7d3041636c64af0972056",
            "conversationId": "6ab20671ffc456d26d809223f9c8314453771e9f9fd7d3041636c64af0972056",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try Self-Care",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-12-13T16:29:11.151Z",
            "endTime": "2021-12-13T16:36:47.735Z",
            "timeSpent": 457,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhTBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhTBQAAAAAAAA==/",
            "_etag": "\"4c00635c-0000-0100-0000-61b798320000\"",
            "_attachments": "attachments/",
            "_ts": 1639422002
        },
        {
            "id": "38836cff8797f78450bd8d4acbaaa2f27eae4df66ddafe874b0b0a15d4c4864a",
            "conversationId": "38836cff8797f78450bd8d4acbaaa2f27eae4df66ddafe874b0b0a15d4c4864a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-12-13T16:38:31.647Z",
            "endTime": "2021-12-13T16:43:19.746Z",
            "timeSpent": 288,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhUBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhUBQAAAAAAAA==/",
            "_etag": "\"4c00b625-0000-0100-0000-61b78a260000\"",
            "_attachments": "attachments/",
            "_ts": 1639418406
        },
        {
            "id": "a835f73079973a3ba3c01a57e4d83120ffa4327402ae8b6d3fd141970c1159c3",
            "conversationId": "a835f73079973a3ba3c01a57e4d83120ffa4327402ae8b6d3fd141970c1159c3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-13T16:07:41.585Z",
            "endTime": "2021-12-13T16:07:41.585Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhVBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhVBQAAAAAAAA==/",
            "_etag": "\"4c00b525-0000-0100-0000-61b78a260000\"",
            "_attachments": "attachments/",
            "_ts": 1639418406
        },
        {
            "id": "98ec043a74d6c56bba44cc3fe443bd4c3742088b0723e1cda2c9afb3efa2880d",
            "conversationId": "98ec043a74d6c56bba44cc3fe443bd4c3742088b0723e1cda2c9afb3efa2880d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-13T19:23:08.895Z",
            "endTime": "2021-12-13T19:38:46.226Z",
            "timeSpent": 937,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhWBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhWBQAAAAAAAA==/",
            "_etag": "\"4c0068e0-0000-0100-0000-61b7c2610000\"",
            "_attachments": "attachments/",
            "_ts": 1639432801
        },
        {
            "id": "bc96031747039df142e99a7e822cf02891aaafc829c85544fa145a6d8185e9cf",
            "conversationId": "bc96031747039df142e99a7e822cf02891aaafc829c85544fa145a6d8185e9cf",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-12-13T20:41:42.645Z",
            "endTime": "2021-12-13T20:43:38.541Z",
            "timeSpent": 116,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhXBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhXBQAAAAAAAA==/",
            "_etag": "\"4d00ab15-0000-0100-0000-61b7d0720000\"",
            "_attachments": "attachments/",
            "_ts": 1639436402
        },
        {
            "id": "9637b7434786446edf6a2233e11d68b0d395cd8f419da0caf53646797b69df08",
            "conversationId": "9637b7434786446edf6a2233e11d68b0d395cd8f419da0caf53646797b69df08",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 79,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will try Self-Care",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-12-13T21:10:19.411Z",
            "endTime": "2021-12-13T21:11:55.514Z",
            "timeSpent": 96,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhYBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhYBQAAAAAAAA==/",
            "_etag": "\"4d00a715-0000-0100-0000-61b7d0720000\"",
            "_attachments": "attachments/",
            "_ts": 1639436402
        },
        {
            "id": "a6f883f60266879203e9aae9d4adeb33c1ab19086a998cc9d4d917590f0a8b1d",
            "conversationId": "a6f883f60266879203e9aae9d4adeb33c1ab19086a998cc9d4d917590f0a8b1d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2021-12-13T21:14:40.42Z",
            "endTime": "2021-12-13T21:16:13.588Z",
            "timeSpent": 93,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhZBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhZBQAAAAAAAA==/",
            "_etag": "\"4d00a815-0000-0100-0000-61b7d0720000\"",
            "_attachments": "attachments/",
            "_ts": 1639436402
        },
        {
            "id": "8bf5977268574bee640f052f195e79efda20756f8d730007280d2d6b8fc11fa0",
            "conversationId": "8bf5977268574bee640f052f195e79efda20756f8d730007280d2d6b8fc11fa0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-13T21:04:36.804Z",
            "endTime": "2021-12-13T21:06:09.641Z",
            "timeSpent": 93,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhaBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhaBQAAAAAAAA==/",
            "_etag": "\"4d00a915-0000-0100-0000-61b7d0720000\"",
            "_attachments": "attachments/",
            "_ts": 1639436402
        },
        {
            "id": "513a3a4b921876fb8a81b467ebe119d07fc5735d4310ddddc26607c93dfb7654",
            "conversationId": "513a3a4b921876fb8a81b467ebe119d07fc5735d4310ddddc26607c93dfb7654",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-13T21:02:27.156Z",
            "endTime": "2021-12-13T21:02:27.156Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhbBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhbBQAAAAAAAA==/",
            "_etag": "\"4d00aa15-0000-0100-0000-61b7d0720000\"",
            "_attachments": "attachments/",
            "_ts": 1639436402
        },
        {
            "id": "91b34625e53b6436d83290a280e586e680815546de33be100f4e0f9ff9c03811",
            "conversationId": "91b34625e53b6436d83290a280e586e680815546de33be100f4e0f9ff9c03811",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2021-12-14T03:53:27.997Z",
            "endTime": "2021-12-14T03:54:21.813Z",
            "timeSpent": 54,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhcBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhcBQAAAAAAAA==/",
            "_etag": "\"4e001856-0000-0100-0000-61b824d10000\"",
            "_attachments": "attachments/",
            "_ts": 1639458001
        },
        {
            "id": "e696a981c18981e59889f6ee7137f0a24b5abdd93d6bf3255cbbc10c706f2294",
            "conversationId": "e696a981c18981e59889f6ee7137f0a24b5abdd93d6bf3255cbbc10c706f2294",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-14T03:57:38.226Z",
            "endTime": "2021-12-14T03:57:38.226Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhdBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhdBQAAAAAAAA==/",
            "_etag": "\"4e00437a-0000-0100-0000-61b832e20000\"",
            "_attachments": "attachments/",
            "_ts": 1639461602
        },
        {
            "id": "2228bba42a418b978788d273cddbe589887b76a3ca5392718a2a8fbf8ce2f79d",
            "conversationId": "2228bba42a418b978788d273cddbe589887b76a3ca5392718a2a8fbf8ce2f79d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [
                "Pregnancy"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-12-14T03:57:48.637Z",
            "endTime": "2021-12-14T04:03:41.631Z",
            "timeSpent": 353,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIheBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIheBQAAAAAAAA==/",
            "_etag": "\"4e003a7a-0000-0100-0000-61b832e20000\"",
            "_attachments": "attachments/",
            "_ts": 1639461602
        },
        {
            "id": "bab30df3dc33afe13668be7320d00909152bb33eba5c0714944f12a323d79a73",
            "conversationId": "bab30df3dc33afe13668be7320d00909152bb33eba5c0714944f12a323d79a73",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-14T03:57:39.931Z",
            "endTime": "2021-12-14T03:57:39.931Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhfBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhfBQAAAAAAAA==/",
            "_etag": "\"4e00447a-0000-0100-0000-61b832e20000\"",
            "_attachments": "attachments/",
            "_ts": 1639461602
        },
        {
            "id": "d33e593db2c756566a027eddeeaf207919e15ec0dbc832c7ecd14f7be95f94ec",
            "conversationId": "d33e593db2c756566a027eddeeaf207919e15ec0dbc832c7ecd14f7be95f94ec",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-14T03:57:41.639Z",
            "endTime": "2021-12-14T03:57:41.639Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhgBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhgBQAAAAAAAA==/",
            "_etag": "\"4e00417a-0000-0100-0000-61b832e20000\"",
            "_attachments": "attachments/",
            "_ts": 1639461602
        },
        {
            "id": "05c6d3896a5466e428bbd1b265b143501e6b63dd867c730238089b292dc2c517",
            "conversationId": "05c6d3896a5466e428bbd1b265b143501e6b63dd867c730238089b292dc2c517",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-14T03:57:43.155Z",
            "endTime": "2021-12-14T03:57:43.155Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhhBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhhBQAAAAAAAA==/",
            "_etag": "\"4e00427a-0000-0100-0000-61b832e20000\"",
            "_attachments": "attachments/",
            "_ts": 1639461602
        },
        {
            "id": "7eb0523b7a7335ca4999269f67f94f5b2bef955db4872bdfcf9ccb119f77d0fa",
            "conversationId": "7eb0523b7a7335ca4999269f67f94f5b2bef955db4872bdfcf9ccb119f77d0fa",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [
                "Pregnancy"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "Call me within two days",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-12-14T04:11:38.307Z",
            "endTime": "2021-12-14T04:16:41.502Z",
            "timeSpent": 303,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhiBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhiBQAAAAAAAA==/",
            "_etag": "\"4e003b7a-0000-0100-0000-61b832e20000\"",
            "_attachments": "attachments/",
            "_ts": 1639461602
        },
        {
            "id": "b9d50b53d09d3ec89d79876759888f7776d3dd0c8c4c732b4e20236b12677c6d",
            "conversationId": "b9d50b53d09d3ec89d79876759888f7776d3dd0c8c4c732b4e20236b12677c6d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-14T04:11:35.497Z",
            "endTime": "2021-12-14T04:11:35.497Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhjBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhjBQAAAAAAAA==/",
            "_etag": "\"4e003e7a-0000-0100-0000-61b832e20000\"",
            "_attachments": "attachments/",
            "_ts": 1639461602
        },
        {
            "id": "d55d9d68014c4f6c44214a07c0975a24971bd4f881ee50908413e741305b78c5",
            "conversationId": "d55d9d68014c4f6c44214a07c0975a24971bd4f881ee50908413e741305b78c5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2021-12-14T04:22:29.823Z",
            "endTime": "2021-12-14T04:26:46.057Z",
            "timeSpent": 256,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhkBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhkBQAAAAAAAA==/",
            "_etag": "\"4e003c7a-0000-0100-0000-61b832e20000\"",
            "_attachments": "attachments/",
            "_ts": 1639461602
        },
        {
            "id": "d301eb1d461fda1a5f4c8af77bf9114d395b89b21af5253d304abbcb135cbc77",
            "conversationId": "d301eb1d461fda1a5f4c8af77bf9114d395b89b21af5253d304abbcb135cbc77",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-12-14T04:44:08.533Z",
            "endTime": "2021-12-14T04:46:39.78Z",
            "timeSpent": 151,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhlBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhlBQAAAAAAAA==/",
            "_etag": "\"4e00b6bb-0000-0100-0000-61b840f20000\"",
            "_attachments": "attachments/",
            "_ts": 1639465202
        },
        {
            "id": "28d4efe4cbfd26103be7ec3b3fc32038fde041e59ff2c9276cd286ecf9d3ac9d",
            "conversationId": "28d4efe4cbfd26103be7ec3b3fc32038fde041e59ff2c9276cd286ecf9d3ac9d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Depressed mood",
                "Elevated mood",
                "Lost will to live"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Send a MyChart Message",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2021-12-14T04:49:39.645Z",
            "endTime": "2021-12-14T04:58:02.854Z",
            "timeSpent": 503,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhmBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhmBQAAAAAAAA==/",
            "_etag": "\"4e003f7a-0000-0100-0000-61b832e20000\"",
            "_attachments": "attachments/",
            "_ts": 1639461602
        },
        {
            "id": "63d40ef93f9fa0c84b51c1f65b5dd6d7b3b1081fa334ddf549a904b5d0e68b88",
            "conversationId": "63d40ef93f9fa0c84b51c1f65b5dd6d7b3b1081fa334ddf549a904b5d0e68b88",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-14T04:49:38.519Z",
            "endTime": "2021-12-14T04:49:38.519Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhnBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhnBQAAAAAAAA==/",
            "_etag": "\"4e00b7bb-0000-0100-0000-61b840f20000\"",
            "_attachments": "attachments/",
            "_ts": 1639465202
        },
        {
            "id": "09bc9738485ec4f780890bbd30e69580565f379facf53bc365fd437c382a3bfe",
            "conversationId": "09bc9738485ec4f780890bbd30e69580565f379facf53bc365fd437c382a3bfe",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try Self-Care",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-12-14T13:22:18.991Z",
            "endTime": "2021-12-14T13:31:37.144Z",
            "timeSpent": 558,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhoBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhoBQAAAAAAAA==/",
            "_etag": "\"5100e78e-0000-0100-0000-61b8b1710000\"",
            "_attachments": "attachments/",
            "_ts": 1639494001
        },
        {
            "id": "c60e44a1235f27388cdfd0b2e47d8459d1ba28239a5530cdfbe1bbe398154435",
            "conversationId": "c60e44a1235f27388cdfd0b2e47d8459d1ba28239a5530cdfbe1bbe398154435",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-12-14T13:37:19.739Z",
            "endTime": "2021-12-14T13:38:12.422Z",
            "timeSpent": 53,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhpBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhpBQAAAAAAAA==/",
            "_etag": "\"5100e5da-0000-0100-0000-61b8bf810000\"",
            "_attachments": "attachments/",
            "_ts": 1639497601
        },
        {
            "id": "d599e65d58e4111ce5e800befdc863d8a6624f05e671fc13fd923c96891ce67c",
            "conversationId": "d599e65d58e4111ce5e800befdc863d8a6624f05e671fc13fd923c96891ce67c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-12-14T13:42:04.132Z",
            "endTime": "2021-12-14T13:43:38.48Z",
            "timeSpent": 94,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhqBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhqBQAAAAAAAA==/",
            "_etag": "\"5100e3da-0000-0100-0000-61b8bf810000\"",
            "_attachments": "attachments/",
            "_ts": 1639497601
        },
        {
            "id": "646c7018e89308d3ca70393c4a9bdba081189a7d670d8ae01d4ef2cbf615b3b3",
            "conversationId": "646c7018e89308d3ca70393c4a9bdba081189a7d670d8ae01d4ef2cbf615b3b3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-12-14T13:47:35.464Z",
            "endTime": "2021-12-14T13:49:33.868Z",
            "timeSpent": 118,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhrBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhrBQAAAAAAAA==/",
            "_etag": "\"5100e6da-0000-0100-0000-61b8bf810000\"",
            "_attachments": "attachments/",
            "_ts": 1639497601
        },
        {
            "id": "0706d97532b7c5bd6e58bd9bd3e61fc16b0a4ee33a09a19ec7cf8a6c8c3d27eb",
            "conversationId": "0706d97532b7c5bd6e58bd9bd3e61fc16b0a4ee33a09a19ec7cf8a6c8c3d27eb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will go to the Emergency Room",
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-12-14T13:32:35.299Z",
            "endTime": "2021-12-14T13:35:28.815Z",
            "timeSpent": 174,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhsBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhsBQAAAAAAAA==/",
            "_etag": "\"5100e88e-0000-0100-0000-61b8b1710000\"",
            "_attachments": "attachments/",
            "_ts": 1639494001
        },
        {
            "id": "a363a5edb7db2545b66a4ce0845e7da799a81209410eb6c4db049fc65802e0e7",
            "conversationId": "a363a5edb7db2545b66a4ce0845e7da799a81209410eb6c4db049fc65802e0e7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-12-14T13:39:57.93Z",
            "endTime": "2021-12-14T13:41:40.61Z",
            "timeSpent": 103,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhtBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhtBQAAAAAAAA==/",
            "_etag": "\"5100e1da-0000-0100-0000-61b8bf810000\"",
            "_attachments": "attachments/",
            "_ts": 1639497601
        },
        {
            "id": "e58809788819d9e17ed6d8308048b3c10b54c1ccddbb67567a79d13674842342",
            "conversationId": "e58809788819d9e17ed6d8308048b3c10b54c1ccddbb67567a79d13674842342",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-14T13:42:02.035Z",
            "endTime": "2021-12-14T13:42:02.035Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhuBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhuBQAAAAAAAA==/",
            "_etag": "\"5100e2da-0000-0100-0000-61b8bf810000\"",
            "_attachments": "attachments/",
            "_ts": 1639497601
        },
        {
            "id": "3a91d64a1f14ed640a3d8fca2406567ca711c91499f4319b67d0d406971ef276",
            "conversationId": "3a91d64a1f14ed640a3d8fca2406567ca711c91499f4319b67d0d406971ef276",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-14T15:14:23.69Z",
            "endTime": "2021-12-14T15:17:19.816Z",
            "timeSpent": 176,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhvBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhvBQAAAAAAAA==/",
            "_etag": "\"52005d23-0000-0100-0000-61b8cd930000\"",
            "_attachments": "attachments/",
            "_ts": 1639501203
        },
        {
            "id": "bb005890843c44a2f44c5087a5e9b6ce14096b85d6ed55995094914d1d4a2faf",
            "conversationId": "bb005890843c44a2f44c5087a5e9b6ce14096b85d6ed55995094914d1d4a2faf",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-12-14T15:19:13.373Z",
            "endTime": "2021-12-14T15:21:07.755Z",
            "timeSpent": 114,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhwBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhwBQAAAAAAAA==/",
            "_etag": "\"52005e23-0000-0100-0000-61b8cd930000\"",
            "_attachments": "attachments/",
            "_ts": 1639501203
        },
        {
            "id": "c3bf2823a67b7e4c79eee245ca14b9ffee9d3bd859e818f01f88ef64e0181f0e",
            "conversationId": "c3bf2823a67b7e4c79eee245ca14b9ffee9d3bd859e818f01f88ef64e0181f0e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-14T15:13:20.256Z",
            "endTime": "2021-12-14T15:13:35.728Z",
            "timeSpent": 15,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhxBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhxBQAAAAAAAA==/",
            "_etag": "\"52005f23-0000-0100-0000-61b8cd930000\"",
            "_attachments": "attachments/",
            "_ts": 1639501203
        },
        {
            "id": "0748c071dd1ba38588dbcc85d6e76914c8f714304d0dc6eaa3f2a2c8f7ce9b7f",
            "conversationId": "0748c071dd1ba38588dbcc85d6e76914c8f714304d0dc6eaa3f2a2c8f7ce9b7f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 74,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-14T15:23:17.217Z",
            "endTime": "2021-12-14T15:23:17.217Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhyBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhyBQAAAAAAAA==/",
            "_etag": "\"52006023-0000-0100-0000-61b8cd930000\"",
            "_attachments": "attachments/",
            "_ts": 1639501203
        },
        {
            "id": "3f43e32106d155c1e3d5fc29c6f3c18698a73d36f5e66640e530cf66c626cd4f",
            "conversationId": "3f43e32106d155c1e3d5fc29c6f3c18698a73d36f5e66640e530cf66c626cd4f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2021-12-14T17:31:58.957Z",
            "endTime": "2021-12-14T17:45:46.672Z",
            "timeSpent": 828,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhzBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhzBQAAAAAAAA==/",
            "_etag": "\"00000d32-0000-0100-0000-61b8f7c10000\"",
            "_attachments": "attachments/",
            "_ts": 1639512001
        },
        {
            "id": "e4e5e1af00ede632845517827616b6c47734390b2ac12f5e3a65441fbe87e0ef",
            "conversationId": "e4e5e1af00ede632845517827616b6c47734390b2ac12f5e3a65441fbe87e0ef",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-21T16:30:55.138Z",
            "endTime": "2021-12-21T16:30:55.138Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh0BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh0BQAAAAAAAA==/",
            "_etag": "\"1c00b75b-0000-0100-0000-61c216520000\"",
            "_attachments": "attachments/",
            "_ts": 1640109650
        },
        {
            "id": "0c3a41fa7238887d6fc9c50a355c1a84413028abe495bd3cb74f605351dd6b57",
            "conversationId": "0c3a41fa7238887d6fc9c50a355c1a84413028abe495bd3cb74f605351dd6b57",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2021-12-21T16:26:57.878Z",
            "endTime": "2021-12-21T16:26:57.878Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh1BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh1BQAAAAAAAA==/",
            "_etag": "\"1c00b65b-0000-0100-0000-61c216520000\"",
            "_attachments": "attachments/",
            "_ts": 1640109650
        },
        {
            "id": "e536e9e62a7e5022b5540f0e71fc4d7c3b2a6d177ae36fc7032402b88cf38246",
            "conversationId": "e536e9e62a7e5022b5540f0e71fc4d7c3b2a6d177ae36fc7032402b88cf38246",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-06T00:00:00Z",
            "endTime": "2022-01-06T00:51:46.621Z",
            "timeSpent": 3107,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh2BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh2BQAAAAAAAA==/",
            "_etag": "\"0501f3a9-0000-0100-0000-6242e45c0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551004
        },
        {
            "id": "eec1aa18f92b78beda469a69c36caa4d9741b48d695fc5cc1f432bc705a6f76f",
            "conversationId": "eec1aa18f92b78beda469a69c36caa4d9741b48d695fc5cc1f432bc705a6f76f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-06T00:00:00Z",
            "endTime": "2022-01-06T00:52:11.806Z",
            "timeSpent": 3132,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh3BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh3BQAAAAAAAA==/",
            "_etag": "\"0501f4a9-0000-0100-0000-6242e45c0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551004
        },
        {
            "id": "e10bdf85738c41054e7016fe55ca186dfb116c59f6284dc59514966f62e371a7",
            "conversationId": "e10bdf85738c41054e7016fe55ca186dfb116c59f6284dc59514966f62e371a7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 29,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-06T00:54:01.4Z",
            "endTime": "2022-01-06T00:54:39.492Z",
            "timeSpent": 38,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh4BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh4BQAAAAAAAA==/",
            "_etag": "\"0501f5a9-0000-0100-0000-6242e45c0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551004
        },
        {
            "id": "8443386a4940f8f8d0a4a4babe1e415d4ea3fa631c89ec5855a43d7ddf26bfec",
            "conversationId": "8443386a4940f8f8d0a4a4babe1e415d4ea3fa631c89ec5855a43d7ddf26bfec",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-06T14:58:08.489Z",
            "endTime": "2022-01-06T15:02:01.834Z",
            "timeSpent": 233,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh5BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh5BQAAAAAAAA==/",
            "_etag": "\"0501f6a9-0000-0100-0000-6242e45c0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551004
        },
        {
            "id": "cbbcf86222638f645c51d099cabeabd9bee8883b49a5aeea732284493a24e4a7",
            "conversationId": "cbbcf86222638f645c51d099cabeabd9bee8883b49a5aeea732284493a24e4a7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-06T14:00:00Z",
            "endTime": "2022-01-06T14:55:52.189Z",
            "timeSpent": 3352,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh6BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh6BQAAAAAAAA==/",
            "_etag": "\"0501f7a9-0000-0100-0000-6242e45c0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551004
        },
        {
            "id": "fce82ba70c5d886b6116debdf237919c68879abf9472896035f6769efc679914",
            "conversationId": "fce82ba70c5d886b6116debdf237919c68879abf9472896035f6769efc679914",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-06T14:00:00Z",
            "endTime": "2022-01-06T14:57:49.108Z",
            "timeSpent": 3469,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh7BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh7BQAAAAAAAA==/",
            "_etag": "\"0501f8a9-0000-0100-0000-6242e45c0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551004
        },
        {
            "id": "bdaf9491551768cc3349afaf0d111d15c412e40cde8641e50d354034b93d6ae8",
            "conversationId": "bdaf9491551768cc3349afaf0d111d15c412e40cde8641e50d354034b93d6ae8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-06T15:01:24.307Z",
            "endTime": "2022-01-06T15:11:11.077Z",
            "timeSpent": 587,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh8BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh8BQAAAAAAAA==/",
            "_etag": "\"0501f9a9-0000-0100-0000-6242e45c0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551004
        },
        {
            "id": "9f77c31ed80989e2658d92a8f0bf0050b2c20c35495984c9b9a29b0cded61872",
            "conversationId": "9f77c31ed80989e2658d92a8f0bf0050b2c20c35495984c9b9a29b0cded61872",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-06T15:00:00Z",
            "endTime": "2022-01-06T15:11:53.477Z",
            "timeSpent": 713,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh9BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh9BQAAAAAAAA==/",
            "_etag": "\"0501faa9-0000-0100-0000-6242e45c0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551004
        },
        {
            "id": "9cefaeba99325efe77d04f574389986136529b20a326060f50f4d4535e5e9fde",
            "conversationId": "9cefaeba99325efe77d04f574389986136529b20a326060f50f4d4535e5e9fde",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-06T15:12:14.2Z",
            "endTime": "2022-01-06T15:12:34.978Z",
            "timeSpent": 21,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh+BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh+BQAAAAAAAA==/",
            "_etag": "\"0501fba9-0000-0100-0000-6242e45c0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551004
        },
        {
            "id": "ee242fc989f80230c6a420ad79aecc4bac797b5f9a64bc7c40b97db137c597b4",
            "conversationId": "ee242fc989f80230c6a420ad79aecc4bac797b5f9a64bc7c40b97db137c597b4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-01-06T15:14:11.472Z",
            "endTime": "2022-01-06T15:17:27.712Z",
            "timeSpent": 196,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh-BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh-BQAAAAAAAA==/",
            "_etag": "\"0501fca9-0000-0100-0000-6242e45c0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551004
        },
        {
            "id": "03c6d1b75ac5f8ec9e713e39361c2f9f02d7e8ce6f9cf74ce6123a78c355aed5",
            "conversationId": "03c6d1b75ac5f8ec9e713e39361c2f9f02d7e8ce6f9cf74ce6123a78c355aed5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-01-06T15:38:04.66Z",
            "endTime": "2022-01-06T15:39:31.924Z",
            "timeSpent": 87,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiABQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiABQAAAAAAAA==/",
            "_etag": "\"0501fda9-0000-0100-0000-6242e45c0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551004
        },
        {
            "id": "32fdf142c3edd77cc7ce2893eb003b1ffbf3600ffce673e77614f3c876e69995",
            "conversationId": "32fdf142c3edd77cc7ce2893eb003b1ffbf3600ffce673e77614f3c876e69995",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-01-06T17:35:57.255Z",
            "endTime": "2022-01-06T17:38:22.395Z",
            "timeSpent": 145,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiBBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiBBQAAAAAAAA==/",
            "_etag": "\"0501fea9-0000-0100-0000-6242e45c0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551004
        },
        {
            "id": "669c9d386aae11aca8c729cec1a88db6104fd4e8542ba80042ac3fb7e218d854",
            "conversationId": "669c9d386aae11aca8c729cec1a88db6104fd4e8542ba80042ac3fb7e218d854",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-06T19:41:47.261Z",
            "endTime": "2022-01-06T19:43:51.161Z",
            "timeSpent": 124,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiCBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiCBQAAAAAAAA==/",
            "_etag": "\"0501ffa9-0000-0100-0000-6242e45c0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551004
        },
        {
            "id": "6b48048b2db35842812a8b91ac38ee61ca2cdded07ddb90583ec0471f1737371",
            "conversationId": "6b48048b2db35842812a8b91ac38ee61ca2cdded07ddb90583ec0471f1737371",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-01-06T20:16:29.768Z",
            "endTime": "2022-01-06T20:18:25Z",
            "timeSpent": 115,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiDBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiDBQAAAAAAAA==/",
            "_etag": "\"050100aa-0000-0100-0000-6242e45c0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551004
        },
        {
            "id": "c939b555d564c523d402eb0686f98b9238ea407e2ea38eb783318af69cdf7c57",
            "conversationId": "c939b555d564c523d402eb0686f98b9238ea407e2ea38eb783318af69cdf7c57",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-01-07T04:03:36.652Z",
            "endTime": "2022-01-07T04:04:40.174Z",
            "timeSpent": 64,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiEBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiEBQAAAAAAAA==/",
            "_etag": "\"050101aa-0000-0100-0000-6242e45c0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551004
        },
        {
            "id": "a8e5fb8895a11c4621eef1238dbad0989eb2296bf1a78117f56972c1f1651f70",
            "conversationId": "a8e5fb8895a11c4621eef1238dbad0989eb2296bf1a78117f56972c1f1651f70",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Hair loss"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-01-07T04:09:09.273Z",
            "endTime": "2022-01-07T04:10:54.124Z",
            "timeSpent": 105,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiFBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiFBQAAAAAAAA==/",
            "_etag": "\"050102aa-0000-0100-0000-6242e45c0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551004
        },
        {
            "id": "8bbcf688e356c7cbef2bc0dfeeca6b7377783e8a9398c99cb04f1c3a4f8388e4",
            "conversationId": "8bbcf688e356c7cbef2bc0dfeeca6b7377783e8a9398c99cb04f1c3a4f8388e4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-01-07T04:32:42.031Z",
            "endTime": "2022-01-07T04:36:07.428Z",
            "timeSpent": 205,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiGBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiGBQAAAAAAAA==/",
            "_etag": "\"050103aa-0000-0100-0000-6242e45c0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551004
        },
        {
            "id": "f2342504f4b36747e3228529a322222c20f16fa64a77814b9061c915bd36ad9b",
            "conversationId": "f2342504f4b36747e3228529a322222c20f16fa64a77814b9061c915bd36ad9b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-01-07T04:40:20.433Z",
            "endTime": "2022-01-07T04:42:29.385Z",
            "timeSpent": 129,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiHBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiHBQAAAAAAAA==/",
            "_etag": "\"050104aa-0000-0100-0000-6242e45c0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551004
        },
        {
            "id": "427d2bac6abaaedbdb7471123f6df67052d9f08ccef57666568844b15885edfd",
            "conversationId": "427d2bac6abaaedbdb7471123f6df67052d9f08ccef57666568844b15885edfd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-07T04:42:56.089Z",
            "endTime": "2022-01-07T04:43:06.869Z",
            "timeSpent": 11,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiIBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiIBQAAAAAAAA==/",
            "_etag": "\"050105aa-0000-0100-0000-6242e45c0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551004
        },
        {
            "id": "7a62a5451f07b9fbf849c9fa572d8d6c85ba7c74327e5840829968e3bfd0c44c",
            "conversationId": "7a62a5451f07b9fbf849c9fa572d8d6c85ba7c74327e5840829968e3bfd0c44c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Head pressure"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-01-07T04:43:32.054Z",
            "endTime": "2022-01-07T04:45:18.479Z",
            "timeSpent": 106,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiJBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiJBQAAAAAAAA==/",
            "_etag": "\"050106aa-0000-0100-0000-6242e45c0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551004
        },
        {
            "id": "0e9e5bb07e5ecbdda1b98eb22682dfe435f4d113e7dee58c40cb546edfa058a7",
            "conversationId": "0e9e5bb07e5ecbdda1b98eb22682dfe435f4d113e7dee58c40cb546edfa058a7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-07T04:45:39.58Z",
            "endTime": "2022-01-07T04:45:39.58Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiKBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiKBQAAAAAAAA==/",
            "_etag": "\"05018da9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "858c5c82366ad2d4fe35d050d534ade892f27886b57f283983c15419a1b967c9",
            "conversationId": "858c5c82366ad2d4fe35d050d534ade892f27886b57f283983c15419a1b967c9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-01-07T04:48:06.96Z",
            "endTime": "2022-01-07T04:49:16.337Z",
            "timeSpent": 69,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiLBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiLBQAAAAAAAA==/",
            "_etag": "\"05018ea9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
},
    
        {
            "id": "6e4a0826fba2431b99e06ff3e6708c1acfce6e0c51d7cdd1a14d0dc13fd0781d",
            "conversationId": "6e4a0826fba2431b99e06ff3e6708c1acfce6e0c51d7cdd1a14d0dc13fd0781d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Hair loss"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-07T04:06:23.812Z",
            "endTime": "2022-01-07T04:07:29.091Z",
            "timeSpent": 65,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiMBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiMBQAAAAAAAA==/",
            "_etag": "\"05018fa9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "e5477e2a13e820ae6e66d2ac4485b1678bddb194af6ed20656132fb5ef8e3b4a",
            "conversationId": "e5477e2a13e820ae6e66d2ac4485b1678bddb194af6ed20656132fb5ef8e3b4a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Nausea"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-01-07T06:14:57.575Z",
            "endTime": "2022-01-07T06:15:57.484Z",
            "timeSpent": 60,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiNBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiNBQAAAAAAAA==/",
            "_etag": "\"050190a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "93d5df497928d5b799edb3b637718c9878531707b64b449cfc69aaaa205b7963",
            "conversationId": "93d5df497928d5b799edb3b637718c9878531707b64b449cfc69aaaa205b7963",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Nausea"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-01-07T06:19:01.087Z",
            "endTime": "2022-01-07T06:21:36.647Z",
            "timeSpent": 156,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiOBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiOBQAAAAAAAA==/",
            "_etag": "\"050191a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "de6b1432416bb236f923b60022d0128dc78bbe4eddaac766b233cee147e35fb8",
            "conversationId": "de6b1432416bb236f923b60022d0128dc78bbe4eddaac766b233cee147e35fb8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Seizures"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-07T06:03:51.965Z",
            "endTime": "2022-01-07T06:12:31.99Z",
            "timeSpent": 520,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiPBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiPBQAAAAAAAA==/",
            "_etag": "\"050192a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "c6954bb43cb1a47e9976edbc914f87480bf6083e44de1fd1c14a725c4c15ac5f",
            "conversationId": "c6954bb43cb1a47e9976edbc914f87480bf6083e44de1fd1c14a725c4c15ac5f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-07T16:32:04.867Z",
            "endTime": "2022-01-07T16:33:42.813Z",
            "timeSpent": 98,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiQBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiQBQAAAAAAAA==/",
            "_etag": "\"050193a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "9fe40679995cf4709bc463a458e4babef8bf474c8e0a6adffa627feeb721eb67",
            "conversationId": "9fe40679995cf4709bc463a458e4babef8bf474c8e0a6adffa627feeb721eb67",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-07T16:36:55.707Z",
            "endTime": "2022-01-07T16:37:46.603Z",
            "timeSpent": 51,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiRBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiRBQAAAAAAAA==/",
            "_etag": "\"050194a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "7f28ffb4b4b53d8b9e315d602f41d988d7da3126a064eabaaf665ea766742d11",
            "conversationId": "7f28ffb4b4b53d8b9e315d602f41d988d7da3126a064eabaaf665ea766742d11",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-07T16:53:52.203Z",
            "endTime": "2022-01-07T16:54:09.072Z",
            "timeSpent": 17,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiSBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiSBQAAAAAAAA==/",
            "_etag": "\"050195a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "94d70afcd655ce38a0e50d89b236985c81cb63c7d4f0b00066b1085a7aa5f698",
            "conversationId": "94d70afcd655ce38a0e50d89b236985c81cb63c7d4f0b00066b1085a7aa5f698",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-07T16:03:58.378Z",
            "endTime": "2022-01-07T16:04:56.705Z",
            "timeSpent": 58,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiTBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiTBQAAAAAAAA==/",
            "_etag": "\"050196a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "81829fd71a8f527ae6c9a6aea1579414dcfb1d90e1c9af4c261b1890ffcd411d",
            "conversationId": "81829fd71a8f527ae6c9a6aea1579414dcfb1d90e1c9af4c261b1890ffcd411d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-07T16:13:07.058Z",
            "endTime": "2022-01-07T16:13:16.385Z",
            "timeSpent": 9,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiUBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiUBQAAAAAAAA==/",
            "_etag": "\"050197a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "379889d3ec7bc7d4bd55433d2a1aa40816f0f56cddd8299140bb4b073ed4aeae",
            "conversationId": "379889d3ec7bc7d4bd55433d2a1aa40816f0f56cddd8299140bb4b073ed4aeae",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-07T16:34:31.448Z",
            "endTime": "2022-01-07T16:36:14.143Z",
            "timeSpent": 103,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiVBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiVBQAAAAAAAA==/",
            "_etag": "\"050198a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "6489527fc71b3e8f1087408fa95991089acb8c92e4f4ab77a84037fc69eae511",
            "conversationId": "6489527fc71b3e8f1087408fa95991089acb8c92e4f4ab77a84037fc69eae511",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-07T16:34:29.998Z",
            "endTime": "2022-01-07T16:34:29.998Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiWBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiWBQAAAAAAAA==/",
            "_etag": "\"050199a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "3e2621f3566a148b54aa630eca9574f62a535b4da8dee6118f7ba7de8298bb7e",
            "conversationId": "3e2621f3566a148b54aa630eca9574f62a535b4da8dee6118f7ba7de8298bb7e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Seizures"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-07T16:40:57.39Z",
            "endTime": "2022-01-07T16:41:30.925Z",
            "timeSpent": 34,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiXBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiXBQAAAAAAAA==/",
            "_etag": "\"05019aa9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "61b029ed2d5620859e304e4281d0804ce152e1b14d367fc09015929de9749ce0",
            "conversationId": "61b029ed2d5620859e304e4281d0804ce152e1b14d367fc09015929de9749ce0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Spine asymmetry"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-07T16:44:38.299Z",
            "endTime": "2022-01-07T16:51:32.634Z",
            "timeSpent": 414,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiYBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiYBQAAAAAAAA==/",
            "_etag": "\"05019ba9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "43a4f32facc151f3710a08ef094caf2f179a0084721fc067adaba80108ede88b",
            "conversationId": "43a4f32facc151f3710a08ef094caf2f179a0084721fc067adaba80108ede88b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-07T16:24:34.93Z",
            "endTime": "2022-01-07T16:27:08.702Z",
            "timeSpent": 154,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiZBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiZBQAAAAAAAA==/",
            "_etag": "\"05019ca9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "0c9423d5d6fcf9a3bfb66c58821a7010eb850dc45602ce38ca5ad0e538275233",
            "conversationId": "0c9423d5d6fcf9a3bfb66c58821a7010eb850dc45602ce38ca5ad0e538275233",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Seizures"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will call 9-1-1",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-07T16:27:35.561Z",
            "endTime": "2022-01-07T16:30:55.383Z",
            "timeSpent": 200,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiaBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiaBQAAAAAAAA==/",
            "_etag": "\"05019da9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "1c67ed408e1d707c8ba88a73475a403a6ad98a6f85aa59f92580097718ba5460",
            "conversationId": "1c67ed408e1d707c8ba88a73475a403a6ad98a6f85aa59f92580097718ba5460",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Seizures"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-07T16:39:17.937Z",
            "endTime": "2022-01-07T16:40:05.109Z",
            "timeSpent": 47,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIibBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIibBQAAAAAAAA==/",
            "_etag": "\"05019ea9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "b84bd4460b4491662d361400d132e2e755d4b95470528a06b37d0453f6a894b8",
            "conversationId": "b84bd4460b4491662d361400d132e2e755d4b95470528a06b37d0453f6a894b8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-07T17:00:00Z",
            "endTime": "2022-01-07T17:01:58.173Z",
            "timeSpent": 118,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIicBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIicBQAAAAAAAA==/",
            "_etag": "\"05019fa9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "0750d631d55d542a469f39cc41947c83f170852ebef69c61814b125486f9094d",
            "conversationId": "0750d631d55d542a469f39cc41947c83f170852ebef69c61814b125486f9094d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-07T17:13:41.927Z",
            "endTime": "2022-01-07T17:17:11.07Z",
            "timeSpent": 209,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIidBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIidBQAAAAAAAA==/",
            "_etag": "\"0501a0a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "b4ec4a2be3abf9033cbffa2a62fe0246fa65cc9a60f6d2224ca03bf7f7e6e1d4",
            "conversationId": "b4ec4a2be3abf9033cbffa2a62fe0246fa65cc9a60f6d2224ca03bf7f7e6e1d4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-07T17:17:33.525Z",
            "endTime": "2022-01-07T17:19:23.248Z",
            "timeSpent": 110,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIieBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIieBQAAAAAAAA==/",
            "_etag": "\"0501a1a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "7a55cfc73562dee75fb98370b0358a7558bbbf8f22d9c28c4e2bd6c334e38f79",
            "conversationId": "7a55cfc73562dee75fb98370b0358a7558bbbf8f22d9c28c4e2bd6c334e38f79",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Double vision"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-01-07T17:24:54.354Z",
            "endTime": "2022-01-07T17:42:52.119Z",
            "timeSpent": 1078,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIifBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIifBQAAAAAAAA==/",
            "_etag": "\"0501a2a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "3768b7f5808653cc4194f70be89b006785276bb776d02c204915fac68e237cca",
            "conversationId": "3768b7f5808653cc4194f70be89b006785276bb776d02c204915fac68e237cca",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-07T17:48:53.236Z",
            "endTime": "2022-01-07T17:48:53.236Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIigBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIigBQAAAAAAAA==/",
            "_etag": "\"0501a3a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "c3b168fe1590bbf9ae674cc320d67ecc8308551a2d525438a74cea88585d0a5f",
            "conversationId": "c3b168fe1590bbf9ae674cc320d67ecc8308551a2d525438a74cea88585d0a5f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-07T17:00:00Z",
            "endTime": "2022-01-07T17:17:27.479Z",
            "timeSpent": 1047,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIihBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIihBQAAAAAAAA==/",
            "_etag": "\"0501a4a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "b257d34531590db2ebf02c27daf48d47ce2ba2d64a2c13650d6a9a51a9f98266",
            "conversationId": "b257d34531590db2ebf02c27daf48d47ce2ba2d64a2c13650d6a9a51a9f98266",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-07T17:00:00Z",
            "endTime": "2022-01-07T17:01:19.893Z",
            "timeSpent": 80,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiiBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiiBQAAAAAAAA==/",
            "_etag": "\"0501a5a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "5e155033d36fe625b6d688f10505b0eed0a5c1877f579763b83cf8d2e226ac3f",
            "conversationId": "5e155033d36fe625b6d688f10505b0eed0a5c1877f579763b83cf8d2e226ac3f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-07T17:00:00Z",
            "endTime": "2022-01-07T17:01:41.431Z",
            "timeSpent": 101,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIijBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIijBQAAAAAAAA==/",
            "_etag": "\"0501a6a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "68c274d498ed4ee19fd169eb8f9b33dce07db89d6500508b38a0212b665b85dd",
            "conversationId": "68c274d498ed4ee19fd169eb8f9b33dce07db89d6500508b38a0212b665b85dd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-07T17:00:00Z",
            "endTime": "2022-01-07T17:02:15.15Z",
            "timeSpent": 135,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIikBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIikBQAAAAAAAA==/",
            "_etag": "\"0501a7a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "077bf046852e9dff6afdb64c612a706929281b3bf4ef4fff0f1ac3b127c2a4c6",
            "conversationId": "077bf046852e9dff6afdb64c612a706929281b3bf4ef4fff0f1ac3b127c2a4c6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the emergency room",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-07T17:02:34.838Z",
            "endTime": "2022-01-07T17:12:30.235Z",
            "timeSpent": 595,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIilBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIilBQAAAAAAAA==/",
            "_etag": "\"0501a8a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "c62995a18d90cb1013e4580562f08690b36948f51e16f482aa307dd22eda6470",
            "conversationId": "c62995a18d90cb1013e4580562f08690b36948f51e16f482aa307dd22eda6470",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-07T17:13:46.179Z",
            "endTime": "2022-01-07T17:13:46.179Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIimBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIimBQAAAAAAAA==/",
            "_etag": "\"0501a9a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "505c959583e9578be91bac2e83c839472b5043e169fafa646f58d393935dd8d9",
            "conversationId": "505c959583e9578be91bac2e83c839472b5043e169fafa646f58d393935dd8d9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-07T17:00:00Z",
            "endTime": "2022-01-07T17:17:30.865Z",
            "timeSpent": 1051,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIinBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIinBQAAAAAAAA==/",
            "_etag": "\"0501aaa9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "66ff841d988745751e23dc7d3e1701f041aa29fb789ac940f55ae43224bd11af",
            "conversationId": "66ff841d988745751e23dc7d3e1701f041aa29fb789ac940f55ae43224bd11af",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-07T17:48:48.371Z",
            "endTime": "2022-01-07T17:48:48.371Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIioBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIioBQAAAAAAAA==/",
            "_etag": "\"0501aba9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "3f7eff80ddc1a7c9c923d391afe6c03b8a7976b8d57f34b407f26012eec6d7a6",
            "conversationId": "3f7eff80ddc1a7c9c923d391afe6c03b8a7976b8d57f34b407f26012eec6d7a6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Thigh pain",
                "Knee pain",
                "Slower walk"
            ],
            "riskFactors": [
                "Smoking",
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the emergency room",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-01-07T18:27:09.62Z",
            "endTime": "2022-01-07T18:30:47.456Z",
            "timeSpent": 218,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIipBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIipBQAAAAAAAA==/",
            "_etag": "\"0501aca9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "86788b5ec357bfc3f221eb0697343b6618aa58e5bcb698f1a22add32ca73ce19",
            "conversationId": "86788b5ec357bfc3f221eb0697343b6618aa58e5bcb698f1a22add32ca73ce19",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Hair loss"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-07T18:32:35.665Z",
            "endTime": "2022-01-07T18:33:44.391Z",
            "timeSpent": 69,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiqBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiqBQAAAAAAAA==/",
            "_etag": "\"0501ada9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "70fa91e06a4b7425080676da48a3da58d7987e17e19c30f0ba9a818612b71024",
            "conversationId": "70fa91e06a4b7425080676da48a3da58d7987e17e19c30f0ba9a818612b71024",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-07T18:45:14.873Z",
            "endTime": "2022-01-07T18:47:01.14Z",
            "timeSpent": 106,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIirBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIirBQAAAAAAAA==/",
            "_etag": "\"0501aea9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "dbe9f4d5d679de2d619f437a0dc626201e72cf1e28ba9a8260098256ba95e29d",
            "conversationId": "dbe9f4d5d679de2d619f437a0dc626201e72cf1e28ba9a8260098256ba95e29d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Depressed mood",
                "Mood swings"
            ],
            "riskFactors": [
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-01-07T18:34:02.465Z",
            "endTime": "2022-01-07T18:36:52.551Z",
            "timeSpent": 170,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIisBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIisBQAAAAAAAA==/",
            "_etag": "\"0501afa9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "332774618c09025a27c285c853bdda22f4bdd2612abbf489f93d023906227755",
            "conversationId": "332774618c09025a27c285c853bdda22f4bdd2612abbf489f93d023906227755",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-07T18:21:54.688Z",
            "endTime": "2022-01-07T18:24:32.224Z",
            "timeSpent": 158,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIitBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIitBQAAAAAAAA==/",
            "_etag": "\"0501b0a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "f07c4794b340bba77e3b1fdfe26499aabfb13aa60e176965d5089c96dce671f1",
            "conversationId": "f07c4794b340bba77e3b1fdfe26499aabfb13aa60e176965d5089c96dce671f1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-07T18:00:00Z",
            "endTime": "2022-01-07T18:37:14.473Z",
            "timeSpent": 2234,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiuBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiuBQAAAAAAAA==/",
            "_etag": "\"0501b1a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "1e7ff8dd685dfbd7d2beeb0bdf50fb885e8558a18083ba194dd36be5a11229f8",
            "conversationId": "1e7ff8dd685dfbd7d2beeb0bdf50fb885e8558a18083ba194dd36be5a11229f8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-07T18:37:44.154Z",
            "endTime": "2022-01-07T18:38:50.005Z",
            "timeSpent": 66,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIivBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIivBQAAAAAAAA==/",
            "_etag": "\"0501b2a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "21ac1ebef6bf6afba8259c2a7c559936329bae617b6ff7515de08a24782c2ef2",
            "conversationId": "21ac1ebef6bf6afba8259c2a7c559936329bae617b6ff7515de08a24782c2ef2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-07T18:26:26.222Z",
            "endTime": "2022-01-07T18:26:26.222Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiwBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiwBQAAAAAAAA==/",
            "_etag": "\"0501b3a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "275e2b33eecd85e868eb486e55d67dd7f99a146a897fdba7bf46839933469226",
            "conversationId": "275e2b33eecd85e868eb486e55d67dd7f99a146a897fdba7bf46839933469226",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-07T18:26:56.281Z",
            "endTime": "2022-01-07T18:26:56.281Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIixBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIixBQAAAAAAAA==/",
            "_etag": "\"0501b4a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "7ea24b9ab1c12cdba6a5ae584b58e816c4ba2044dcd6040ed29c8bc79ef31b23",
            "conversationId": "7ea24b9ab1c12cdba6a5ae584b58e816c4ba2044dcd6040ed29c8bc79ef31b23",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-07T18:26:59.042Z",
            "endTime": "2022-01-07T18:26:59.042Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiyBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiyBQAAAAAAAA==/",
            "_etag": "\"0501b5a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "e4d4313b1017627c5d73a0c24e5aee72ccbb6271f56e0e6bef5e2693a6b4ace9",
            "conversationId": "e4d4313b1017627c5d73a0c24e5aee72ccbb6271f56e0e6bef5e2693a6b4ace9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Hair loss"
            ],
            "riskFactors": [
                "Chronic NSAIDs use",
                "Opioid use",
                "History of tonsillectomy",
                "Smoking",
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-01-07T20:41:29.534Z",
            "endTime": "2022-01-07T20:44:59.04Z",
            "timeSpent": 210,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIizBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIizBQAAAAAAAA==/",
            "_etag": "\"0501b6a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "89ac98067283b3be103c1bbe74a1acdfa8e4f2ef5bbbeaf48f9d690b93976043",
            "conversationId": "89ac98067283b3be103c1bbe74a1acdfa8e4f2ef5bbbeaf48f9d690b93976043",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Hair loss"
            ],
            "riskFactors": [
                "Opioid use",
                "History of tonsillectomy",
                "Smoking",
                "History of anaphylactic reactions",
                "Pregnancy"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-01-07T20:18:43.86Z",
            "endTime": "2022-01-07T20:39:03.096Z",
            "timeSpent": 1219,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi0BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi0BQAAAAAAAA==/",
            "_etag": "\"0501b7a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "e30f93aaa1747fa986790258512caa027611d5e585caef066395f577432899a2",
            "conversationId": "e30f93aaa1747fa986790258512caa027611d5e585caef066395f577432899a2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Hair loss"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-07T20:17:47.83Z",
            "endTime": "2022-01-07T20:18:07.329Z",
            "timeSpent": 19,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi1BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi1BQAAAAAAAA==/",
            "_etag": "\"0501b8a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "50ae2146666d49f8d0d2d4cc943fdac35647aa90c5e472e95587c7341be4de87",
            "conversationId": "50ae2146666d49f8d0d2d4cc943fdac35647aa90c5e472e95587c7341be4de87",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain",
                " subsides during rest"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-01-09T02:25:05.269Z",
            "endTime": "2022-01-09T02:28:37.192Z",
            "timeSpent": 212,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi2BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi2BQAAAAAAAA==/",
            "_etag": "\"0501b9a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "24d269935c614303f45767888b94675116c9ab143f1b1309daf7be38291e0bc6",
            "conversationId": "24d269935c614303f45767888b94675116c9ab143f1b1309daf7be38291e0bc6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Current cigarette smoking"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-01-09T02:32:11.629Z",
            "endTime": "2022-01-09T02:34:28.808Z",
            "timeSpent": 137,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi3BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi3BQAAAAAAAA==/",
            "_etag": "\"0501baa9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "9e20582291ef3535862c0de8e646071507a80e20e5a59ba7c75ecfae773ef554",
            "conversationId": "9e20582291ef3535862c0de8e646071507a80e20e5a59ba7c75ecfae773ef554",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-01-09T02:39:43.292Z",
            "endTime": "2022-01-09T02:42:46.275Z",
            "timeSpent": 183,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi4BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi4BQAAAAAAAA==/",
            "_etag": "\"0501bba9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "672a370e23730cabc4f13ab22fe8ac3158c25fd7ccebe9e673f8c80b4887ad1a",
            "conversationId": "672a370e23730cabc4f13ab22fe8ac3158c25fd7ccebe9e673f8c80b4887ad1a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-09T02:50:58.134Z",
            "endTime": "2022-01-09T02:51:00.981Z",
            "timeSpent": 3,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi5BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi5BQAAAAAAAA==/",
            "_etag": "\"0501bca9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "ef6bbe0ff54692a165d57dc073a1194d2d85a26e29a53061f1571897d0dfd50c",
            "conversationId": "ef6bbe0ff54692a165d57dc073a1194d2d85a26e29a53061f1571897d0dfd50c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-09T02:00:00Z",
            "endTime": "2022-01-09T02:24:54.849Z",
            "timeSpent": 1495,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi6BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi6BQAAAAAAAA==/",
            "_etag": "\"0501bda9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "7c4232a673ef2065f221a23d14c1ba0232cc4344b63121ba93ea6daa17e2d914",
            "conversationId": "7c4232a673ef2065f221a23d14c1ba0232cc4344b63121ba93ea6daa17e2d914",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-09T02:51:09.306Z",
            "endTime": "2022-01-09T02:51:16.987Z",
            "timeSpent": 8,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi7BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi7BQAAAAAAAA==/",
            "_etag": "\"0501bea9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "24c05877ad0abfebd6563c214f50672c768694f903145d5e753cdb3d14c191fd",
            "conversationId": "24c05877ad0abfebd6563c214f50672c768694f903145d5e753cdb3d14c191fd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Mood swings",
                "Anxiety"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-09T04:00:30.777Z",
            "endTime": "2022-01-09T04:11:23.469Z",
            "timeSpent": 653,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi8BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi8BQAAAAAAAA==/",
            "_etag": "\"0501bfa9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "8c834855bea7cb6dbed5e953d5990c6d4dbe93e30aef8dc2107972286d3cd4c8",
            "conversationId": "8c834855bea7cb6dbed5e953d5990c6d4dbe93e30aef8dc2107972286d3cd4c8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Anxiety",
                "Mood swings"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-01-09T04:33:16.908Z",
            "endTime": "2022-01-09T04:54:24.559Z",
            "timeSpent": 1268,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi9BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi9BQAAAAAAAA==/",
            "_etag": "\"0501c0a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "8e0ff2663da0e03e7b7a328b491eb9f355685b80df09621a90bef290ef2bdd90",
            "conversationId": "8e0ff2663da0e03e7b7a328b491eb9f355685b80df09621a90bef290ef2bdd90",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-09T04:33:12.642Z",
            "endTime": "2022-01-09T04:33:12.642Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi+BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi+BQAAAAAAAA==/",
            "_etag": "\"0501c1a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "5d41fa70ec421d14d2f2043e2236b208de0cfca51f1219684f016a3e79d38b1e",
            "conversationId": "5d41fa70ec421d14d2f2043e2236b208de0cfca51f1219684f016a3e79d38b1e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Tingling around the mouth"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-01-10T12:38:03.298Z",
            "endTime": "2022-01-10T12:47:25.52Z",
            "timeSpent": 562,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi-BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi-BQAAAAAAAA==/",
            "_etag": "\"0501c2a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "7f30ed9a3f9703243241ff0b68d9b0084b7dbac414db756129b2ecd8dd8dc79b",
            "conversationId": "7f30ed9a3f9703243241ff0b68d9b0084b7dbac414db756129b2ecd8dd8dc79b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sinus pain or pressure"
            ],
            "riskFactors": [
                "Chronic NSAIDs use",
                "Opioid use",
                "History of tonsillectomy",
                "Smoking",
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-01-10T12:49:02.481Z",
            "endTime": "2022-01-10T13:09:51.236Z",
            "timeSpent": 1249,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjABQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjABQAAAAAAAA==/",
            "_etag": "\"0501c3a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "a2d7ff72e2ef8753c718c1d1130fd5d639aa7468188cfabed1817efed30f63f9",
            "conversationId": "a2d7ff72e2ef8753c718c1d1130fd5d639aa7468188cfabed1817efed30f63f9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Seizures"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-10T13:16:06.424Z",
            "endTime": "2022-01-10T13:18:32.11Z",
            "timeSpent": 146,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 4,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjBBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjBBQAAAAAAAA==/",
            "_etag": "\"0501c4a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "7fbfbb869f779dcdfb72b90a6f23f92319b40b76bdedc5ad859a43535d0aa845",
            "conversationId": "7fbfbb869f779dcdfb72b90a6f23f92319b40b76bdedc5ad859a43535d0aa845",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Depressed mood",
                "Anxiety"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-01-10T13:47:57.458Z",
            "endTime": "2022-01-10T13:50:55.485Z",
            "timeSpent": 178,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjCBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjCBQAAAAAAAA==/",
            "_etag": "\"0501c5a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "cad7b5da0d23885cdc428217abdcb92edb4520e4dc37c676ca84a613ad3ff1bd",
            "conversationId": "cad7b5da0d23885cdc428217abdcb92edb4520e4dc37c676ca84a613ad3ff1bd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-01-10T13:11:44.91Z",
            "endTime": "2022-01-10T13:15:33.485Z",
            "timeSpent": 229,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjDBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjDBQAAAAAAAA==/",
            "_etag": "\"0501c6a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "28c9d397fe826e6de9cc1e58ff2868bee49c4ec0fb37066220b81fe0ac5e929b",
            "conversationId": "28c9d397fe826e6de9cc1e58ff2868bee49c4ec0fb37066220b81fe0ac5e929b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-10T13:25:17.521Z",
            "endTime": "2022-01-10T13:25:25.787Z",
            "timeSpent": 8,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjEBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjEBQAAAAAAAA==/",
            "_etag": "\"0501c7a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "6ca2f4bcd69aded55596aba391b6cf278e5027e48f8ac30204aecfc10654bed1",
            "conversationId": "6ca2f4bcd69aded55596aba391b6cf278e5027e48f8ac30204aecfc10654bed1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 29,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-10T15:01:20.716Z",
            "endTime": "2022-01-10T15:01:20.716Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjFBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjFBQAAAAAAAA==/",
            "_etag": "\"0501c8a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "d6a7565d2da9085d4b29a982158ead4d9caa6324f6629dcee33590f0faa127ff",
            "conversationId": "d6a7565d2da9085d4b29a982158ead4d9caa6324f6629dcee33590f0faa127ff",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Hair loss"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-01-10T15:57:05.396Z",
            "endTime": "2022-01-10T16:02:08.636Z",
            "timeSpent": 303,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjGBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjGBQAAAAAAAA==/",
            "_etag": "\"0501c9a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "577ff3b70a57645c7d5d37ce867aa6804860955dbbe3eab880c011e70f126c94",
            "conversationId": "577ff3b70a57645c7d5d37ce867aa6804860955dbbe3eab880c011e70f126c94",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-10T16:21:58.929Z",
            "endTime": "2022-01-10T16:22:12.127Z",
            "timeSpent": 13,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjHBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjHBQAAAAAAAA==/",
            "_etag": "\"0501caa9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "0a64e541fafa9028c6061cf5aaebf0aebef3950c911841a407adbf575e73cf94",
            "conversationId": "0a64e541fafa9028c6061cf5aaebf0aebef3950c911841a407adbf575e73cf94",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Mood swings",
                "Depressed mood"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-01-10T16:22:54.07Z",
            "endTime": "2022-01-10T16:29:51.838Z",
            "timeSpent": 418,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjIBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjIBQAAAAAAAA==/",
            "_etag": "\"0501cba9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "13bb1535a78d7065dff0b889649c321884f63d47c7d44467afb7870309165db7",
            "conversationId": "13bb1535a78d7065dff0b889649c321884f63d47c7d44467afb7870309165db7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-10T16:21:46.814Z",
            "endTime": "2022-01-10T16:21:46.814Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjJBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjJBQAAAAAAAA==/",
            "_etag": "\"0501cca9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "dbcc1b3481d56de6125695f96bea390bc360144db2629ac13817fba9ed60c7cb",
            "conversationId": "dbcc1b3481d56de6125695f96bea390bc360144db2629ac13817fba9ed60c7cb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-10T16:22:44.401Z",
            "endTime": "2022-01-10T16:22:44.401Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjKBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjKBQAAAAAAAA==/",
            "_etag": "\"0501cda9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "cf09db9fddda8a78876f601ab7fcc82bc70c169df987623778e026baf6c2da75",
            "conversationId": "cf09db9fddda8a78876f601ab7fcc82bc70c169df987623778e026baf6c2da75",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-10T17:12:37.987Z",
            "endTime": "2022-01-10T17:12:43.746Z",
            "timeSpent": 6,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjLBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjLBQAAAAAAAA==/",
            "_etag": "\"0501cea9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "0b62591201399a628efe7b1f980faf75e2f979ddeec0f05c4ba2f20e89937cd8",
            "conversationId": "0b62591201399a628efe7b1f980faf75e2f979ddeec0f05c4ba2f20e89937cd8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-10T18:00:00Z",
            "endTime": "2022-01-10T18:36:05.528Z",
            "timeSpent": 2166,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjMBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjMBQAAAAAAAA==/",
            "_etag": "\"0501cfa9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "d0710d9aad8db7113b493813157eae227f6cc355764b12c3a50ea33a4adeb731",
            "conversationId": "d0710d9aad8db7113b493813157eae227f6cc355764b12c3a50ea33a4adeb731",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe joint pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-10T18:39:26.928Z",
            "endTime": "2022-01-10T18:56:36.339Z",
            "timeSpent": 1029,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjNBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjNBQAAAAAAAA==/",
            "_etag": "\"0501d0a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "63f6aab264df3b91dcc05a9ec7c506fd7569c01c1e354244fc53edb048aed057",
            "conversationId": "63f6aab264df3b91dcc05a9ec7c506fd7569c01c1e354244fc53edb048aed057",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Seizures"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-10T19:06:47.631Z",
            "endTime": "2022-01-10T19:11:26.575Z",
            "timeSpent": 279,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjOBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjOBQAAAAAAAA==/",
            "_etag": "\"0501d1a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "37d4d0b27093ec1d184110d76cd70b9c2f93ee37c1e15279568f1448014c20c9",
            "conversationId": "37d4d0b27093ec1d184110d76cd70b9c2f93ee37c1e15279568f1448014c20c9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-10T19:06:29.549Z",
            "endTime": "2022-01-10T19:06:29.549Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjPBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjPBQAAAAAAAA==/",
            "_etag": "\"0501d2a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "c9bd94bb0915698c5af64faeb0ccefa0069917e70a20c8e45d64c1c84a99cfa1",
            "conversationId": "c9bd94bb0915698c5af64faeb0ccefa0069917e70a20c8e45d64c1c84a99cfa1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-10T19:06:35.199Z",
            "endTime": "2022-01-10T19:06:35.199Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjQBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjQBQAAAAAAAA==/",
            "_etag": "\"0501d3a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "ca0ee8988d264c664cf01fd7ec95dbddcc935e0f2e27236840b213e5acfbb125",
            "conversationId": "ca0ee8988d264c664cf01fd7ec95dbddcc935e0f2e27236840b213e5acfbb125",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe joint pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-10T20:30:50.798Z",
            "endTime": "2022-01-10T20:33:01.537Z",
            "timeSpent": 131,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjRBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjRBQAAAAAAAA==/",
            "_etag": "\"0501d4a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "3c01528d26fd0c5e47688b50dae313c45522c60e97d15dd46b6f13c521d405eb",
            "conversationId": "3c01528d26fd0c5e47688b50dae313c45522c60e97d15dd46b6f13c521d405eb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe joint pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-10T20:33:16.291Z",
            "endTime": "2022-01-10T20:36:51.355Z",
            "timeSpent": 215,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjSBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjSBQAAAAAAAA==/",
            "_etag": "\"0501d5a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "0962d0163ee4224631f27b2c978ab17832afa600fe903bcc05aec0a5cd716a4c",
            "conversationId": "0962d0163ee4224631f27b2c978ab17832afa600fe903bcc05aec0a5cd716a4c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-01-10T20:38:01.133Z",
            "endTime": "2022-01-10T20:42:57.831Z",
            "timeSpent": 297,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjTBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjTBQAAAAAAAA==/",
            "_etag": "\"0501d6a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "50076b3c395fd800831a67bccaac558ba3e4ff58a5122e0b27033b949008dd96",
            "conversationId": "50076b3c395fd800831a67bccaac558ba3e4ff58a5122e0b27033b949008dd96",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-10T20:25:25.536Z",
            "endTime": "2022-01-10T20:30:15.023Z",
            "timeSpent": 289,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjUBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjUBQAAAAAAAA==/",
            "_etag": "\"0501d7a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "1b625b612fe0e9e13c44361f110f59f8f951d37c28d1faace4109cb56ff1dbed",
            "conversationId": "1b625b612fe0e9e13c44361f110f59f8f951d37c28d1faace4109cb56ff1dbed",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-10T20:30:50.711Z",
            "endTime": "2022-01-10T20:30:50.711Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjVBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjVBQAAAAAAAA==/",
            "_etag": "\"0501d8a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "c7447a61e5df9dcb94ad4fb0f0a1c268035fb94cbeca865885a3ba0ee313a75b",
            "conversationId": "c7447a61e5df9dcb94ad4fb0f0a1c268035fb94cbeca865885a3ba0ee313a75b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-10T20:37:04.859Z",
            "endTime": "2022-01-10T20:37:39.326Z",
            "timeSpent": 34,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjWBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjWBQAAAAAAAA==/",
            "_etag": "\"0501d9a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "1407042d56831e095fd2176e649e765616046ecff4a9d684a95704c54dfd0df4",
            "conversationId": "1407042d56831e095fd2176e649e765616046ecff4a9d684a95704c54dfd0df4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-10T21:30:28.796Z",
            "endTime": "2022-01-10T21:30:28.796Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjXBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjXBQAAAAAAAA==/",
            "_etag": "\"0501daa9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "fc2ac110feec535657e9b6d78e3198354446c199cf51875f635713a324639d40",
            "conversationId": "fc2ac110feec535657e9b6d78e3198354446c199cf51875f635713a324639d40",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-11T01:42:13.382Z",
            "endTime": "2022-01-11T01:53:11.409Z",
            "timeSpent": 658,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjYBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjYBQAAAAAAAA==/",
            "_etag": "\"0501dba9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "2785453c5f1b85c1d9cdf8a8f909fd5281cb115b94aa5104934fe45cc1474d15",
            "conversationId": "2785453c5f1b85c1d9cdf8a8f909fd5281cb115b94aa5104934fe45cc1474d15",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T01:53:50.531Z",
            "endTime": "2022-01-11T01:53:50.531Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjZBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjZBQAAAAAAAA==/",
            "_etag": "\"0501dca9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "c6ffb86b33d5bb225b26a10dbcaeae60f81de5d4d5e49b95fefb2bb2a5e9f6f5",
            "conversationId": "c6ffb86b33d5bb225b26a10dbcaeae60f81de5d4d5e49b95fefb2bb2a5e9f6f5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-01-11T01:53:51.15Z",
            "endTime": "2022-01-11T01:54:19.519Z",
            "timeSpent": 28,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjaBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjaBQAAAAAAAA==/",
            "_etag": "\"0501dda9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "76e2ad8c6044d3aee2ae757ecb19ffba9c7b6dc5451064f99148fad1f78fc5d9",
            "conversationId": "76e2ad8c6044d3aee2ae757ecb19ffba9c7b6dc5451064f99148fad1f78fc5d9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Loss of interest in everyday matters"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-01-11T02:41:54.676Z",
            "endTime": "2022-01-11T03:02:34.676Z",
            "timeSpent": 1240,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjbBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjbBQAAAAAAAA==/",
            "_etag": "\"0501dea9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "1aa76578cb96b0716ee8fa17381a64fc32f3aa56ca7f8eee1e1e3dc7eafa1ea4",
            "conversationId": "1aa76578cb96b0716ee8fa17381a64fc32f3aa56ca7f8eee1e1e3dc7eafa1ea4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sinus pain or pressure",
                "Stuffed nose",
                "Stuffy Nose"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T02:06:43.726Z",
            "endTime": "2022-01-11T02:14:15.226Z",
            "timeSpent": 452,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjcBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjcBQAAAAAAAA==/",
            "_etag": "\"0501dfa9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "677c3e346f31c24b9ef7944f5be2219601519d470fc9eb83f46d12ff2d0e2345",
            "conversationId": "677c3e346f31c24b9ef7944f5be2219601519d470fc9eb83f46d12ff2d0e2345",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Sinus pain or pressure"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T02:02:27.471Z",
            "endTime": "2022-01-11T02:03:00.253Z",
            "timeSpent": 33,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjdBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjdBQAAAAAAAA==/",
            "_etag": "\"0501e0a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "c60daaf464442bbcf1a4239c46435e29775fdcd9c236397fab9b0e5b18ea426f",
            "conversationId": "c60daaf464442bbcf1a4239c46435e29775fdcd9c236397fab9b0e5b18ea426f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Sinus pain or pressure"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T02:00:39.42Z",
            "endTime": "2022-01-11T02:01:26.156Z",
            "timeSpent": 47,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjeBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjeBQAAAAAAAA==/",
            "_etag": "\"0501e1a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "d8fb5cd29043028d4bb9097465a392578028ddb5e5f850eea39dc4d03cc0552f",
            "conversationId": "d8fb5cd29043028d4bb9097465a392578028ddb5e5f850eea39dc4d03cc0552f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T03:50:05.52Z",
            "endTime": "2022-01-11T03:50:05.52Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjfBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjfBQAAAAAAAA==/",
            "_etag": "\"0501e2a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "bca66e263dd9454cec1f749ea540951b10555b97628dba037988f367b8cab8d3",
            "conversationId": "bca66e263dd9454cec1f749ea540951b10555b97628dba037988f367b8cab8d3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Swollen penis",
                "Stomach pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-01-11T03:54:11.208Z",
            "endTime": "2022-01-11T03:55:27.776Z",
            "timeSpent": 77,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjgBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjgBQAAAAAAAA==/",
            "_etag": "\"0501e3a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "42abdab85e5137ca8d5a16e0cea426da468d7715440bfca389aa51b650c69d29",
            "conversationId": "42abdab85e5137ca8d5a16e0cea426da468d7715440bfca389aa51b650c69d29",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Seizures"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-11T03:58:39.322Z",
            "endTime": "2022-01-11T03:59:07.116Z",
            "timeSpent": 28,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjhBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjhBQAAAAAAAA==/",
            "_etag": "\"0501e4a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "4ddc448134d5f308df6df47b265ea60d2526bb663aa35acb4765068366631d66",
            "conversationId": "4ddc448134d5f308df6df47b265ea60d2526bb663aa35acb4765068366631d66",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Insomnia",
                "Sleep disorder"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-01-11T03:17:19.073Z",
            "endTime": "2022-01-11T03:25:20.098Z",
            "timeSpent": 481,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjiBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjiBQAAAAAAAA==/",
            "_etag": "\"0501e5a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "2a6e267bdc055c26068336951e728867a9a276b9ed88b246eafced7e96d47c1c",
            "conversationId": "2a6e267bdc055c26068336951e728867a9a276b9ed88b246eafced7e96d47c1c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Many birthmarks"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will call 9-1-1",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-01-11T03:04:06.835Z",
            "endTime": "2022-01-11T03:06:02.436Z",
            "timeSpent": 116,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjjBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjjBQAAAAAAAA==/",
            "_etag": "\"0501e6a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "99efb128ab92105ccf920419fa6f05cd7c1b45c683fcbd2857a26de6f7647f4e",
            "conversationId": "99efb128ab92105ccf920419fa6f05cd7c1b45c683fcbd2857a26de6f7647f4e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Fear of obesity"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-01-11T03:06:38.72Z",
            "endTime": "2022-01-11T03:10:33.671Z",
            "timeSpent": 235,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 1,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjkBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjkBQAAAAAAAA==/",
            "_etag": "\"0501e7a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "9192ccdcddccb7c24e8ca1a8338a675872af51e3b6b440e45545e8ed3f99d545",
            "conversationId": "9192ccdcddccb7c24e8ca1a8338a675872af51e3b6b440e45545e8ed3f99d545",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T03:16:11.589Z",
            "endTime": "2022-01-11T03:16:11.589Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjlBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjlBQAAAAAAAA==/",
            "_etag": "\"0501e8a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "c87815900165e38a799f2c2532adaf8f533930bf3a77f9e3cd5549a1f05d03be",
            "conversationId": "c87815900165e38a799f2c2532adaf8f533930bf3a77f9e3cd5549a1f05d03be",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Visual agnosia",
                "Inability to recognize objects",
                " people",
                " sounds",
                " shapes",
                " or smells",
                "Auditory verbal agnosia",
                "Amusia"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-01-11T03:16:11.496Z",
            "endTime": "2022-01-11T03:17:07.698Z",
            "timeSpent": 56,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjmBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjmBQAAAAAAAA==/",
            "_etag": "\"0501e9a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "00a1bee9c6a4d7b6200eb98777199f0c4b7fb1093b56d8cc4cdc619c92b15c6d",
            "conversationId": "00a1bee9c6a4d7b6200eb98777199f0c4b7fb1093b56d8cc4cdc619c92b15c6d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Apraxia",
                "Perseveration"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-01-11T03:11:20.29Z",
            "endTime": "2022-01-11T03:12:39.715Z",
            "timeSpent": 79,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 6,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjnBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjnBQAAAAAAAA==/",
            "_etag": "\"0501eaa9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "78b4f04cd34be137e066e044ef4d528501de0e1324d6b4a307678ec0cd39630e",
            "conversationId": "78b4f04cd34be137e066e044ef4d528501de0e1324d6b4a307678ec0cd39630e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T03:13:36.65Z",
            "endTime": "2022-01-11T03:13:36.65Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjoBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjoBQAAAAAAAA==/",
            "_etag": "\"0501eba9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "16514b1ba527e9390596a51003f63da01c181565a3f7a9a8c28648fa901b03db",
            "conversationId": "16514b1ba527e9390596a51003f63da01c181565a3f7a9a8c28648fa901b03db",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T03:30:06.2Z",
            "endTime": "2022-01-11T03:30:06.2Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjpBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjpBQAAAAAAAA==/",
            "_etag": "\"0501eca9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "76e4ee088b081bf8368082205fa099f8f6dea0cd9cc988238ccfe223964e49a1",
            "conversationId": "76e4ee088b081bf8368082205fa099f8f6dea0cd9cc988238ccfe223964e49a1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T03:49:32.189Z",
            "endTime": "2022-01-11T03:49:32.189Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjqBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjqBQAAAAAAAA==/",
            "_etag": "\"0501eda9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "efc5eb57e244dd7e4a411d3fd18c07fa814b314690d957ba2a7002145d7ffc4d",
            "conversationId": "efc5eb57e244dd7e4a411d3fd18c07fa814b314690d957ba2a7002145d7ffc4d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-11T04:01:04.86Z",
            "endTime": "2022-01-11T04:03:11.386Z",
            "timeSpent": 127,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjrBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjrBQAAAAAAAA==/",
            "_etag": "\"0501eea9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "558c0c00c029b45dec480225dd8df4aabe967f4fa03f4d7293d5cf06e0f43846",
            "conversationId": "558c0c00c029b45dec480225dd8df4aabe967f4fa03f4d7293d5cf06e0f43846",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-01-11T04:03:40.195Z",
            "endTime": "2022-01-11T04:04:30.61Z",
            "timeSpent": 50,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjsBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjsBQAAAAAAAA==/",
            "_etag": "\"0501efa9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "f1d3cdf1aed7ce00f8707ce541f7b3e3f7a3634d492fca1fe6136cca3c7181f4",
            "conversationId": "f1d3cdf1aed7ce00f8707ce541f7b3e3f7a3634d492fca1fe6136cca3c7181f4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-11T04:12:30.044Z",
            "endTime": "2022-01-11T04:16:17Z",
            "timeSpent": 227,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjtBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjtBQAAAAAAAA==/",
            "_etag": "\"0501f0a9-0000-0100-0000-6242e45b0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551003
        },
        {
            "id": "ceddba294e42b47d87b9ed50f1efe4a1afcf261300394b26cbaaf76212e3d307",
            "conversationId": "ceddba294e42b47d87b9ed50f1efe4a1afcf261300394b26cbaaf76212e3d307",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Seizures"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-11T04:31:29.891Z",
            "endTime": "2022-01-11T04:33:27.59Z",
            "timeSpent": 118,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjuBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjuBQAAAAAAAA==/",
            "_etag": "\"050107aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "64b136847bf95a049f078a100b11cd3e545182e71665b8adfaadc171a03caf98",
            "conversationId": "64b136847bf95a049f078a100b11cd3e545182e71665b8adfaadc171a03caf98",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Seizures"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-11T04:51:18.205Z",
            "endTime": "2022-01-11T04:51:42.884Z",
            "timeSpent": 25,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjvBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjvBQAAAAAAAA==/",
            "_etag": "\"050108aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
},
    
        {
            "id": "eb11cc51bc7d103d1d44b151c791dedf3f6494bca942d692c24d9c15c52ff082",
            "conversationId": "eb11cc51bc7d103d1d44b151c791dedf3f6494bca942d692c24d9c15c52ff082",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Seizures"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-11T04:54:59.216Z",
            "endTime": "2022-01-11T04:55:18.944Z",
            "timeSpent": 20,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjwBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjwBQAAAAAAAA==/",
            "_etag": "\"050109aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "2c2e7329a4138c7c849108deab5d7e64142e46a7e05fa7968d2f9a45962a75ac",
            "conversationId": "2c2e7329a4138c7c849108deab5d7e64142e46a7e05fa7968d2f9a45962a75ac",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Mood swings",
                "Depressed mood"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-11T04:56:28.912Z",
            "endTime": "2022-01-11T05:00:04.621Z",
            "timeSpent": 216,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjxBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjxBQAAAAAAAA==/",
            "_etag": "\"05010aaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "49fd66fb95553a822c734230291724f181fe5755aa3f8209244f185e3018c63d",
            "conversationId": "49fd66fb95553a822c734230291724f181fe5755aa3f8209244f185e3018c63d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T04:42:26.534Z",
            "endTime": "2022-01-11T04:50:50.98Z",
            "timeSpent": 504,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjyBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjyBQAAAAAAAA==/",
            "_etag": "\"05010baa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "73bd1b229221c7a775eb39a7d49798e629685f07c8818538654eed584a3aba91",
            "conversationId": "73bd1b229221c7a775eb39a7d49798e629685f07c8818538654eed584a3aba91",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-01-11T04:22:34.535Z",
            "endTime": "2022-01-11T04:23:30.005Z",
            "timeSpent": 55,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjzBQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjzBQAAAAAAAA==/",
            "_etag": "\"05010caa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "3208c327b8e5a99f000f6c6a9f4a71b3732f3a10d36f4d685cdbfa993a30a10c",
            "conversationId": "3208c327b8e5a99f000f6c6a9f4a71b3732f3a10d36f4d685cdbfa993a30a10c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T04:00:00Z",
            "endTime": "2022-01-11T04:56:00.632Z",
            "timeSpent": 3361,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj0BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj0BQAAAAAAAA==/",
            "_etag": "\"05010daa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "ff3d27293dc641bae0ab84c2416c38b093d772a7a98a02269720853089dfe377",
            "conversationId": "ff3d27293dc641bae0ab84c2416c38b093d772a7a98a02269720853089dfe377",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T05:09:10.049Z",
            "endTime": "2022-01-11T05:09:15.761Z",
            "timeSpent": 6,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj1BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj1BQAAAAAAAA==/",
            "_etag": "\"05010eaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "92896a37b7f7b67145498dad117d3b5f87c06541a5d3498d5bbd3367f0c860ef",
            "conversationId": "92896a37b7f7b67145498dad117d3b5f87c06541a5d3498d5bbd3367f0c860ef",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-01-11T12:50:14.495Z",
            "endTime": "2022-01-11T12:51:34.458Z",
            "timeSpent": 80,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj2BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj2BQAAAAAAAA==/",
            "_etag": "\"05010faa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "e2b4c499a0ce3a1c6972cefdb2fe63ce69c97bcb18536545318cb167d6c21938",
            "conversationId": "e2b4c499a0ce3a1c6972cefdb2fe63ce69c97bcb18536545318cb167d6c21938",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Fast heartbeat"
            ],
            "riskFactors": [
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-01-11T12:53:25.989Z",
            "endTime": "2022-01-11T12:58:17.951Z",
            "timeSpent": 292,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj3BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj3BQAAAAAAAA==/",
            "_etag": "\"050110aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "1a11e0ac743864dee4d57a06c99333043832d52ab556d92cc0aa530b91e6e7af",
            "conversationId": "1a11e0ac743864dee4d57a06c99333043832d52ab556d92cc0aa530b91e6e7af",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-01-11T12:52:10.88Z",
            "endTime": "2022-01-11T12:53:08.367Z",
            "timeSpent": 57,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj4BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj4BQAAAAAAAA==/",
            "_etag": "\"050111aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "57983958e60b7f01ec7ab2135b3fa7c3a4e5441a312f931dd7d3ddf8e6769d75",
            "conversationId": "57983958e60b7f01ec7ab2135b3fa7c3a4e5441a312f931dd7d3ddf8e6769d75",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T12:36:27.674Z",
            "endTime": "2022-01-11T12:38:03.817Z",
            "timeSpent": 96,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj5BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj5BQAAAAAAAA==/",
            "_etag": "\"050112aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "458c61aceb0e266b48ba57852c7cc39b909e546b413cf315db72524b12d105a8",
            "conversationId": "458c61aceb0e266b48ba57852c7cc39b909e546b413cf315db72524b12d105a8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T13:56:11.79Z",
            "endTime": "2022-01-11T13:57:35.554Z",
            "timeSpent": 84,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj6BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj6BQAAAAAAAA==/",
            "_etag": "\"050113aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "bcebf7e606ddc84d2615a4aff4c9eccb1091399beafd13d48d4146c80f70bd42",
            "conversationId": "bcebf7e606ddc84d2615a4aff4c9eccb1091399beafd13d48d4146c80f70bd42",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T13:56:10.79Z",
            "endTime": "2022-01-11T13:56:10.79Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj7BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj7BQAAAAAAAA==/",
            "_etag": "\"050114aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "8a77abc7eb9def7e773d45b965a889672cccf48de273d7005de3d78d140bda97",
            "conversationId": "8a77abc7eb9def7e773d45b965a889672cccf48de273d7005de3d78d140bda97",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-11T13:58:22.24Z",
            "endTime": "2022-01-11T13:58:51.912Z",
            "timeSpent": 30,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj8BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj8BQAAAAAAAA==/",
            "_etag": "\"050115aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "415409e945dcc42eec4ac766760adcbbd12b042d1fc653bae76567827ebedec8",
            "conversationId": "415409e945dcc42eec4ac766760adcbbd12b042d1fc653bae76567827ebedec8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T13:42:12.623Z",
            "endTime": "2022-01-11T13:42:47.954Z",
            "timeSpent": 35,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj9BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj9BQAAAAAAAA==/",
            "_etag": "\"050116aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "00cc9c437ad189d04cbb3ebcd3f7aaea84277ce565234e5156b83a469f0f355a",
            "conversationId": "00cc9c437ad189d04cbb3ebcd3f7aaea84277ce565234e5156b83a469f0f355a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-11T13:46:31.507Z",
            "endTime": "2022-01-11T13:47:17.591Z",
            "timeSpent": 46,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj+BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj+BQAAAAAAAA==/",
            "_etag": "\"050117aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "aab29454ff54b095ee3f78d3681e96d9b6874e61167e2100c4d7113c322b81d3",
            "conversationId": "aab29454ff54b095ee3f78d3681e96d9b6874e61167e2100c4d7113c322b81d3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-11T13:59:49.835Z",
            "endTime": "2022-01-11T14:01:22.429Z",
            "timeSpent": 93,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj-BQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj-BQAAAAAAAA==/",
            "_etag": "\"050118aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "1919fad3fddc1aa87a2640462df8e2cb183255252a6e326bae5474e041cb3496",
            "conversationId": "1919fad3fddc1aa87a2640462df8e2cb183255252a6e326bae5474e041cb3496",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Seizures"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-11T14:07:44.646Z",
            "endTime": "2022-01-11T14:08:20.224Z",
            "timeSpent": 36,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgABgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgABgAAAAAAAA==/",
            "_etag": "\"050119aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "bfddc85b24b9a729c59e822028c6442d378d9fb89d877b01bc5c2c046c454281",
            "conversationId": "bfddc85b24b9a729c59e822028c6442d378d9fb89d877b01bc5c2c046c454281",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Seizures"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-11T14:29:55.895Z",
            "endTime": "2022-01-11T14:30:20.509Z",
            "timeSpent": 25,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgBBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgBBgAAAAAAAA==/",
            "_etag": "\"05011aaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "84fac1d96d635d1813e860649bb9b37f06a1703d55658811df09a014ef9d926a",
            "conversationId": "84fac1d96d635d1813e860649bb9b37f06a1703d55658811df09a014ef9d926a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Seizures"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-11T14:04:57.661Z",
            "endTime": "2022-01-11T14:05:53.695Z",
            "timeSpent": 56,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgCBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgCBgAAAAAAAA==/",
            "_etag": "\"05011baa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "4044037309a723884b9ae94cb8de2dabf697eff749df701692efdbc7febc8830",
            "conversationId": "4044037309a723884b9ae94cb8de2dabf697eff749df701692efdbc7febc8830",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T14:07:39.203Z",
            "endTime": "2022-01-11T14:07:39.203Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgDBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgDBgAAAAAAAA==/",
            "_etag": "\"05011caa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "ee126cf2e170510962df5815a6160d40568e57abf83700b2d605d4d121c902b3",
            "conversationId": "ee126cf2e170510962df5815a6160d40568e57abf83700b2d605d4d121c902b3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-11T14:59:23.297Z",
            "endTime": "2022-01-11T15:00:15.043Z",
            "timeSpent": 52,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgEBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgEBgAAAAAAAA==/",
            "_etag": "\"05011daa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "c83f77740c9a144475ed328de9827ca04fb7aab3d961b120c57e8e39123038f7",
            "conversationId": "c83f77740c9a144475ed328de9827ca04fb7aab3d961b120c57e8e39123038f7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-11T15:06:44.392Z",
            "endTime": "2022-01-11T15:08:50.757Z",
            "timeSpent": 126,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgFBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgFBgAAAAAAAA==/",
            "_etag": "\"05011eaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "cb4f632dc4dc13a9e8e9cc34fe47b78e9a600e6224b77f227de45b1bb2255dfc",
            "conversationId": "cb4f632dc4dc13a9e8e9cc34fe47b78e9a600e6224b77f227de45b1bb2255dfc",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-11T15:09:53.91Z",
            "endTime": "2022-01-11T15:10:35.225Z",
            "timeSpent": 41,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgGBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgGBgAAAAAAAA==/",
            "_etag": "\"05011faa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "ddf62778fd4d4d28f8f52ee944b53e5df757e34cde6940f105243c43afd455f1",
            "conversationId": "ddf62778fd4d4d28f8f52ee944b53e5df757e34cde6940f105243c43afd455f1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Seizures"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-11T15:32:40.908Z",
            "endTime": "2022-01-11T15:34:03.546Z",
            "timeSpent": 83,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgHBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgHBgAAAAAAAA==/",
            "_etag": "\"050120aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "2c174429f82b6ab185dfc2a488305d4eb61b6980371971a5a1efadfdeac14b5e",
            "conversationId": "2c174429f82b6ab185dfc2a488305d4eb61b6980371971a5a1efadfdeac14b5e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Seizures"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-11T15:31:43.418Z",
            "endTime": "2022-01-11T15:32:04.941Z",
            "timeSpent": 22,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgIBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgIBgAAAAAAAA==/",
            "_etag": "\"050121aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "40c87a544a9f5d29f6e99101d9f838268a40bf8dec3c2806bd83c3a12c706c3f",
            "conversationId": "40c87a544a9f5d29f6e99101d9f838268a40bf8dec3c2806bd83c3a12c706c3f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Heartburn",
                "Chest pain",
                "Slow heart rate",
                "Fast heartbeat"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-11T15:37:57.234Z",
            "endTime": "2022-01-11T15:38:54.542Z",
            "timeSpent": 57,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgJBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgJBgAAAAAAAA==/",
            "_etag": "\"050122aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "367ff03bd84b90c21761a3956ff32d8f10c5d1f02ef74c9a326af1836c08ce2e",
            "conversationId": "367ff03bd84b90c21761a3956ff32d8f10c5d1f02ef74c9a326af1836c08ce2e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T17:43:51.797Z",
            "endTime": "2022-01-11T17:43:51.797Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgKBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgKBgAAAAAAAA==/",
            "_etag": "\"050123aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "af83f4a1ad85cebb3dad61cb3ada7f555f3624487a0c2052fd1609eca249ae33",
            "conversationId": "af83f4a1ad85cebb3dad61cb3ada7f555f3624487a0c2052fd1609eca249ae33",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Seizures"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-11T18:15:51.392Z",
            "endTime": "2022-01-11T18:17:17.374Z",
            "timeSpent": 86,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgLBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgLBgAAAAAAAA==/",
            "_etag": "\"050124aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "f72dbc3cdb85b22502b985065c305b1a64bb0f928bf8c415199c1497401a9e12",
            "conversationId": "f72dbc3cdb85b22502b985065c305b1a64bb0f928bf8c415199c1497401a9e12",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Excessive worry about the health of a child"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-11T18:18:55.011Z",
            "endTime": "2022-01-11T18:19:55.975Z",
            "timeSpent": 61,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgMBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgMBgAAAAAAAA==/",
            "_etag": "\"050125aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "fcbe7923649279b27eababd680c81af9ce088e76f9f70d1048e5c0dd27339b28",
            "conversationId": "fcbe7923649279b27eababd680c81af9ce088e76f9f70d1048e5c0dd27339b28",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T18:20:29.421Z",
            "endTime": "2022-01-11T18:20:35.951Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgNBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgNBgAAAAAAAA==/",
            "_etag": "\"050126aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "275ff65120c1d84a4f974bb1b3420e7557fa444e860d5b1a905d817f89867003",
            "conversationId": "275ff65120c1d84a4f974bb1b3420e7557fa444e860d5b1a905d817f89867003",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Depressed mood"
            ],
            "riskFactors": [
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-01-11T18:20:55.174Z",
            "endTime": "2022-01-11T18:34:11.885Z",
            "timeSpent": 797,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgOBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgOBgAAAAAAAA==/",
            "_etag": "\"050127aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "db882b075d21289c7991bdc44af9981ee084023f9630c71c761d9875116db4ab",
            "conversationId": "db882b075d21289c7991bdc44af9981ee084023f9630c71c761d9875116db4ab",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T18:06:00.904Z",
            "endTime": "2022-01-11T18:06:15.154Z",
            "timeSpent": 14,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgPBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgPBgAAAAAAAA==/",
            "_etag": "\"050128aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "f7dbbf41e974d9079214746f4c7faad3b1ad09d3b274469edc9492c73c6b0362",
            "conversationId": "f7dbbf41e974d9079214746f4c7faad3b1ad09d3b274469edc9492c73c6b0362",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T18:37:03.254Z",
            "endTime": "2022-01-11T18:37:03.254Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgQBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgQBgAAAAAAAA==/",
            "_etag": "\"050129aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "e1b18891cf07e21f0dd1da9b72e9cfde97200ff75b8c02577cf9de544b96972f",
            "conversationId": "e1b18891cf07e21f0dd1da9b72e9cfde97200ff75b8c02577cf9de544b96972f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-11T18:40:47.472Z",
            "endTime": "2022-01-11T18:42:07.48Z",
            "timeSpent": 80,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgRBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgRBgAAAAAAAA==/",
            "_etag": "\"05012aaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "d5fab7486cc60bb2688263d44021008deae956d5784b5830101c3f1b821dd0db",
            "conversationId": "d5fab7486cc60bb2688263d44021008deae956d5784b5830101c3f1b821dd0db",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Lice eggs or nits on hair shafts"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-11T18:06:30.745Z",
            "endTime": "2022-01-11T18:08:52.498Z",
            "timeSpent": 142,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgSBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgSBgAAAAAAAA==/",
            "_etag": "\"05012baa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "564479eca2babedfe5547be34aac2b98528953539d9718f2e39d7817818dd88f",
            "conversationId": "564479eca2babedfe5547be34aac2b98528953539d9718f2e39d7817818dd88f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T19:22:05.173Z",
            "endTime": "2022-01-11T19:37:24.572Z",
            "timeSpent": 919,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgTBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgTBgAAAAAAAA==/",
            "_etag": "\"05012caa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "ceb37cebf120f33e6ab10c793e379ea52982555bcd8ba6c7ce2365773af2230c",
            "conversationId": "ceb37cebf120f33e6ab10c793e379ea52982555bcd8ba6c7ce2365773af2230c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Hair loss"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-01-11T20:08:24.901Z",
            "endTime": "2022-01-11T20:11:42.131Z",
            "timeSpent": 197,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgUBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgUBgAAAAAAAA==/",
            "_etag": "\"05012daa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "f34de6f04f9c2bd1d3619c86aec8a3872709d717a283b1f723d1bf4d6c83f912",
            "conversationId": "f34de6f04f9c2bd1d3619c86aec8a3872709d717a283b1f723d1bf4d6c83f912",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T20:15:50.28Z",
            "endTime": "2022-01-11T20:15:56.372Z",
            "timeSpent": 6,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgVBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgVBgAAAAAAAA==/",
            "_etag": "\"05012eaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "56cb03bd2fda58528fd4b5f375870c837509b3ed948abee1f9a9944f9fb74287",
            "conversationId": "56cb03bd2fda58528fd4b5f375870c837509b3ed948abee1f9a9944f9fb74287",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Hair loss"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-01-11T20:08:07.07Z",
            "endTime": "2022-01-11T20:16:09.474Z",
            "timeSpent": 482,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgWBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgWBgAAAAAAAA==/",
            "_etag": "\"05012faa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "82ac4c0cf3bf8216d504b80087fdff14623b0a9d428e6d5806b1d67602c74c24",
            "conversationId": "82ac4c0cf3bf8216d504b80087fdff14623b0a9d428e6d5806b1d67602c74c24",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Pain in upper limb"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will go to ReadyMED / urgent care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-01-11T20:33:32.778Z",
            "endTime": "2022-01-11T20:46:22.758Z",
            "timeSpent": 770,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgXBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgXBgAAAAAAAA==/",
            "_etag": "\"050130aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "cc843dc2ecccf6123ecb75ac857183108cf9edec30fe19cac41b77860dd189a2",
            "conversationId": "cc843dc2ecccf6123ecb75ac857183108cf9edec30fe19cac41b77860dd189a2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T20:13:46.14Z",
            "endTime": "2022-01-11T20:13:55.982Z",
            "timeSpent": 10,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgYBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgYBgAAAAAAAA==/",
            "_etag": "\"050131aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "8b0e7bb857b2f9a4a56d33045574a8a6c1593b556b7612da86797923cfb16756",
            "conversationId": "8b0e7bb857b2f9a4a56d33045574a8a6c1593b556b7612da86797923cfb16756",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-11T20:31:05.96Z",
            "endTime": "2022-01-11T20:31:12.589Z",
            "timeSpent": 7,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgZBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgZBgAAAAAAAA==/",
            "_etag": "\"050132aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "c8683d60e539ace71562af50f2d717ecff068510141d11c67aad3f54d1b67a8b",
            "conversationId": "c8683d60e539ace71562af50f2d717ecff068510141d11c67aad3f54d1b67a8b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T20:33:29.483Z",
            "endTime": "2022-01-11T20:33:29.483Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgaBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgaBgAAAAAAAA==/",
            "_etag": "\"050133aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "35f918a1456b3e93c2b2197fc671f503fba03c39a845ca1bbfa969df60a7ce45",
            "conversationId": "35f918a1456b3e93c2b2197fc671f503fba03c39a845ca1bbfa969df60a7ce45",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Coma",
                "Hives",
                "Fever",
                "Urination while coughing or laughing or during physical exertion"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-11T20:02:44.537Z",
            "endTime": "2022-01-11T20:05:49.959Z",
            "timeSpent": 185,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgbBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgbBgAAAAAAAA==/",
            "_etag": "\"050134aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "ba39f6b24ad7ea7c05c2ba8841b831ca656ebd9b3e2090958a090963a2c1495e",
            "conversationId": "ba39f6b24ad7ea7c05c2ba8841b831ca656ebd9b3e2090958a090963a2c1495e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T20:00:00Z",
            "endTime": "2022-01-11T20:13:25.303Z",
            "timeSpent": 805,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgcBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgcBgAAAAAAAA==/",
            "_etag": "\"050135aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "5aa3ee31c20c7d69a2ad39a3c57785cb2df79d90734da1db2f3dbca93d4efaf2",
            "conversationId": "5aa3ee31c20c7d69a2ad39a3c57785cb2df79d90734da1db2f3dbca93d4efaf2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T20:00:00Z",
            "endTime": "2022-01-11T20:13:26.316Z",
            "timeSpent": 806,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgdBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgdBgAAAAAAAA==/",
            "_etag": "\"050136aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "d4d50e602a16aaa9ced7048548727d929de87cad45fdf2139700f8669b0cc37e",
            "conversationId": "d4d50e602a16aaa9ced7048548727d929de87cad45fdf2139700f8669b0cc37e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T20:15:49.703Z",
            "endTime": "2022-01-11T20:15:49.703Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgeBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgeBgAAAAAAAA==/",
            "_etag": "\"050137aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "f44dea9753bb7248258ecb94ff37b135c16fd9e6f9c1d50a040ea4be8ceac7c4",
            "conversationId": "f44dea9753bb7248258ecb94ff37b135c16fd9e6f9c1d50a040ea4be8ceac7c4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T20:48:57.994Z",
            "endTime": "2022-01-11T20:49:17.056Z",
            "timeSpent": 19,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgfBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgfBgAAAAAAAA==/",
            "_etag": "\"050138aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "08dc9b234d7e5acee49873a102d06f85542407d6e9ab1449425c753e419c1d3e",
            "conversationId": "08dc9b234d7e5acee49873a102d06f85542407d6e9ab1449425c753e419c1d3e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-11T20:30:08.19Z",
            "endTime": "2022-01-11T20:30:39.797Z",
            "timeSpent": 32,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIggBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIggBgAAAAAAAA==/",
            "_etag": "\"050139aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "5dfc2f85935088972f45b557c1d04154c26360baf7aabaa1f30b7cb8712c3cdd",
            "conversationId": "5dfc2f85935088972f45b557c1d04154c26360baf7aabaa1f30b7cb8712c3cdd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-11T22:19:07.915Z",
            "endTime": "2022-01-11T22:23:26.876Z",
            "timeSpent": 259,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIghBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIghBgAAAAAAAA==/",
            "_etag": "\"05013aaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "b6457a0ff3112bb9c70d5512bf08d4c1446bf477c7c7075b4dc93a93be2af367",
            "conversationId": "b6457a0ff3112bb9c70d5512bf08d4c1446bf477c7c7075b4dc93a93be2af367",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Hair loss"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-01-11T22:26:45.412Z",
            "endTime": "2022-01-11T22:29:11.778Z",
            "timeSpent": 146,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgiBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgiBgAAAAAAAA==/",
            "_etag": "\"05013baa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "100d2482b91fae7863f65656174924ccb0612d0e5aefce1d4240457af8cf5489",
            "conversationId": "100d2482b91fae7863f65656174924ccb0612d0e5aefce1d4240457af8cf5489",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Lower back pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-01-11T22:32:42.838Z",
            "endTime": "2022-01-11T22:36:24.064Z",
            "timeSpent": 221,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgjBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgjBgAAAAAAAA==/",
            "_etag": "\"05013caa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "3ad6efe2f92d00fc5f06b9298941f6ed730a9a5f8ff41fcbaa99a5c31448fe67",
            "conversationId": "3ad6efe2f92d00fc5f06b9298941f6ed730a9a5f8ff41fcbaa99a5c31448fe67",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will go to ReadyMED / urgent care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-01-11T22:32:49.951Z",
            "endTime": "2022-01-11T22:36:23.905Z",
            "timeSpent": 214,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgkBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgkBgAAAAAAAA==/",
            "_etag": "\"05013daa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "dea14c9629b01318c65ef7c8b59937e396ad6a0a7e07dbd368af3581a7fcb88a",
            "conversationId": "dea14c9629b01318c65ef7c8b59937e396ad6a0a7e07dbd368af3581a7fcb88a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T22:45:15.137Z",
            "endTime": "2022-01-11T22:49:23.45Z",
            "timeSpent": 248,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIglBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIglBgAAAAAAAA==/",
            "_etag": "\"05013eaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "f5a18a2e731a0452d0a3724178a4f4033586fe2de3efa146580e1f2d1e8e7eb0",
            "conversationId": "f5a18a2e731a0452d0a3724178a4f4033586fe2de3efa146580e1f2d1e8e7eb0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-11T22:18:16.255Z",
            "endTime": "2022-01-11T22:23:27.024Z",
            "timeSpent": 311,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgmBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgmBgAAAAAAAA==/",
            "_etag": "\"05013faa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "cf144415607a61c3e4c65f66f3d11036775cb4a1b05568d15e7914fd0cfc70ff",
            "conversationId": "cf144415607a61c3e4c65f66f3d11036775cb4a1b05568d15e7914fd0cfc70ff",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-11T22:12:29.906Z",
            "endTime": "2022-01-11T22:13:03.785Z",
            "timeSpent": 34,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgnBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgnBgAAAAAAAA==/",
            "_etag": "\"050140aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "683d664addbf890720cda8ab86aa40b7620f38cf95b9110f6407dfdaf09629fd",
            "conversationId": "683d664addbf890720cda8ab86aa40b7620f38cf95b9110f6407dfdaf09629fd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-11T23:10:11.798Z",
            "endTime": "2022-01-11T23:12:28.963Z",
            "timeSpent": 137,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgoBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgoBgAAAAAAAA==/",
            "_etag": "\"050141aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "47b1ae4df35ef09a54cd063360e9a21d07f02150a23f3f6e168427013f1dfbc8",
            "conversationId": "47b1ae4df35ef09a54cd063360e9a21d07f02150a23f3f6e168427013f1dfbc8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-11T23:12:59.004Z",
            "endTime": "2022-01-11T23:14:28.663Z",
            "timeSpent": 90,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 4,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgpBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgpBgAAAAAAAA==/",
            "_etag": "\"050142aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "4ec8e21c0d213357bd3ecb881904180c18b3eb22a86c56e9d3bdc7a86b4ce2d6",
            "conversationId": "4ec8e21c0d213357bd3ecb881904180c18b3eb22a86c56e9d3bdc7a86b4ce2d6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Hair loss"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-01-12T13:24:57.334Z",
            "endTime": "2022-01-12T13:26:14.909Z",
            "timeSpent": 78,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgqBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgqBgAAAAAAAA==/",
            "_etag": "\"050143aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "6a93aaef22fa75cd81cc3e1da356059792628317f8675a57b68876a2f17c0412",
            "conversationId": "6a93aaef22fa75cd81cc3e1da356059792628317f8675a57b68876a2f17c0412",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-12T13:22:29.763Z",
            "endTime": "2022-01-12T13:22:29.763Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgrBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgrBgAAAAAAAA==/",
            "_etag": "\"050144aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "3426ff68643d1fd13c935a91c5086a75a06f45c056807668acdd0159da198e22",
            "conversationId": "3426ff68643d1fd13c935a91c5086a75a06f45c056807668acdd0159da198e22",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-12T14:17:37.954Z",
            "endTime": "2022-01-12T14:17:42.652Z",
            "timeSpent": 5,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgsBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgsBgAAAAAAAA==/",
            "_etag": "\"050145aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "34d80277bbb79dd42cd5f335e1477a31de2f9a811be3c9590c7ca8b798c8b227",
            "conversationId": "34d80277bbb79dd42cd5f335e1477a31de2f9a811be3c9590c7ca8b798c8b227",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-12T14:48:44.339Z",
            "endTime": "2022-01-12T14:48:59.586Z",
            "timeSpent": 15,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgtBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgtBgAAAAAAAA==/",
            "_etag": "\"050146aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "b29b6a53af7dc8cd95de84f016ef7b88908af2f973fdc493c20d0c1ab1d87ff5",
            "conversationId": "b29b6a53af7dc8cd95de84f016ef7b88908af2f973fdc493c20d0c1ab1d87ff5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-12T15:42:08.499Z",
            "endTime": "2022-01-12T15:44:05.372Z",
            "timeSpent": 117,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIguBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIguBgAAAAAAAA==/",
            "_etag": "\"050147aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "f8f48ee1749b65e1f5d7a4e943381d58b6cd6968d374d2504cccfb0644470fb8",
            "conversationId": "f8f48ee1749b65e1f5d7a4e943381d58b6cd6968d374d2504cccfb0644470fb8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Hair loss"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-01-12T15:49:24.909Z",
            "endTime": "2022-01-12T15:51:50.232Z",
            "timeSpent": 145,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgvBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgvBgAAAAAAAA==/",
            "_etag": "\"050148aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "4b4bdae7fe0453dc2cde45e086b9369ab908fc9d316c3c60e4af69288098d9d1",
            "conversationId": "4b4bdae7fe0453dc2cde45e086b9369ab908fc9d316c3c60e4af69288098d9d1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Excessive hair"
            ],
            "riskFactors": [
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-01-12T15:44:30.574Z",
            "endTime": "2022-01-12T15:46:29.998Z",
            "timeSpent": 119,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgwBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgwBgAAAAAAAA==/",
            "_etag": "\"050149aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "25c58f18ef8ef9066b9355b62f7260b02a0bf8274a4a5d697902ce3183233516",
            "conversationId": "25c58f18ef8ef9066b9355b62f7260b02a0bf8274a4a5d697902ce3183233516",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-12T15:49:25.054Z",
            "endTime": "2022-01-12T15:49:25.054Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgxBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgxBgAAAAAAAA==/",
            "_etag": "\"05014aaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "867fc19932880b4b69c95478b4a2dde86d0648063fa7f92858e4cc4777141b74",
            "conversationId": "867fc19932880b4b69c95478b4a2dde86d0648063fa7f92858e4cc4777141b74",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-12T15:44:23.749Z",
            "endTime": "2022-01-12T15:44:23.749Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgyBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgyBgAAAAAAAA==/",
            "_etag": "\"05014baa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "bf1898e44617a9468abace14e07628043fc298b14dbcb94c591c01c0e065fab0",
            "conversationId": "bf1898e44617a9468abace14e07628043fc298b14dbcb94c591c01c0e065fab0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Joint pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-01-12T15:56:23.568Z",
            "endTime": "2022-01-12T16:05:10.948Z",
            "timeSpent": 527,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgzBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgzBgAAAAAAAA==/",
            "_etag": "\"05014caa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "109b2b103b8d9edd3f3f000e0f5c6a23cc2ab5bd0576379932a0b3ba81888368",
            "conversationId": "109b2b103b8d9edd3f3f000e0f5c6a23cc2ab5bd0576379932a0b3ba81888368",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-01-12T16:05:30.577Z",
            "endTime": "2022-01-12T16:08:46.104Z",
            "timeSpent": 196,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg0BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg0BgAAAAAAAA==/",
            "_etag": "\"05014daa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "879ddd4129b73a8b5d948d69b77bd6ead612c066fdb65670d59b98dae8ea653b",
            "conversationId": "879ddd4129b73a8b5d948d69b77bd6ead612c066fdb65670d59b98dae8ea653b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-12T16:49:47.937Z",
            "endTime": "2022-01-12T16:50:00.323Z",
            "timeSpent": 12,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg1BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg1BgAAAAAAAA==/",
            "_etag": "\"05014eaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "85773e9c604b31b603058a72c5a3d49c4f2d084cc448e6e30eb6347a9510c8a7",
            "conversationId": "85773e9c604b31b603058a72c5a3d49c4f2d084cc448e6e30eb6347a9510c8a7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Head pressure"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-01-12T16:50:08.52Z",
            "endTime": "2022-01-12T16:53:59.104Z",
            "timeSpent": 231,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg2BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg2BgAAAAAAAA==/",
            "_etag": "\"05014faa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "44dab36e3ceb5864d48a8ec9f9a2d9e4efd9daab423660ed5b9382522eacb8e5",
            "conversationId": "44dab36e3ceb5864d48a8ec9f9a2d9e4efd9daab423660ed5b9382522eacb8e5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-12T16:54:20.431Z",
            "endTime": "2022-01-12T16:54:20.431Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg3BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg3BgAAAAAAAA==/",
            "_etag": "\"050150aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "6cac754e2d5cd5f20ad72a261d071eda712fee729441cb7b8f1a98a9d3fe99f5",
            "conversationId": "6cac754e2d5cd5f20ad72a261d071eda712fee729441cb7b8f1a98a9d3fe99f5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-12T16:54:19.07Z",
            "endTime": "2022-01-12T16:54:19.07Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg4BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg4BgAAAAAAAA==/",
            "_etag": "\"050151aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "ffe6617b4b9c334e60859c9db33053af51fe388d1b3e5f4098ad3fe2cbb801ab",
            "conversationId": "ffe6617b4b9c334e60859c9db33053af51fe388d1b3e5f4098ad3fe2cbb801ab",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-01-12T17:21:48.9Z",
            "endTime": "2022-01-12T17:42:15.471Z",
            "timeSpent": 1227,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg5BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg5BgAAAAAAAA==/",
            "_etag": "\"050152aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "48d294555d39689eec8fcde8d6642e20b4b6db4ceb1383ce43449c9416669d9e",
            "conversationId": "48d294555d39689eec8fcde8d6642e20b4b6db4ceb1383ce43449c9416669d9e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-01-12T17:43:26.087Z",
            "endTime": "2022-01-12T17:47:16.419Z",
            "timeSpent": 230,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg6BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg6BgAAAAAAAA==/",
            "_etag": "\"050153aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "27f234c1a3d680b288c2c7abd59b416a8959a74cbd9517e8f99e3ac49638a563",
            "conversationId": "27f234c1a3d680b288c2c7abd59b416a8959a74cbd9517e8f99e3ac49638a563",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-12T17:52:53.183Z",
            "endTime": "2022-01-12T17:52:53.183Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg7BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg7BgAAAAAAAA==/",
            "_etag": "\"050154aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "af922a0fe75297a216796c4638a11a2e08553c7da77a92a0f8da4aa3079bf89c",
            "conversationId": "af922a0fe75297a216796c4638a11a2e08553c7da77a92a0f8da4aa3079bf89c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-12T22:56:05.774Z",
            "endTime": "2022-01-12T22:56:05.774Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg8BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg8BgAAAAAAAA==/",
            "_etag": "\"050155aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "e5fe794998c843659858e69fa07dc46e2a23c7f26e1c12809ae1480fbde43b17",
            "conversationId": "e5fe794998c843659858e69fa07dc46e2a23c7f26e1c12809ae1480fbde43b17",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-12T22:47:00.88Z",
            "endTime": "2022-01-12T22:47:00.88Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg9BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg9BgAAAAAAAA==/",
            "_etag": "\"050156aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "fde57682063b911431dc370410cdd9dc30fa4ba03a4ce7307a2ac86e87978e07",
            "conversationId": "fde57682063b911431dc370410cdd9dc30fa4ba03a4ce7307a2ac86e87978e07",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Excessive hair"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-13T14:53:48.099Z",
            "endTime": "2022-01-13T14:54:16.373Z",
            "timeSpent": 28,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg+BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg+BgAAAAAAAA==/",
            "_etag": "\"050157aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "e485f817249ef787125a7714c62d6ae36fa26c19c4ca972cb501d7131d1ba4ed",
            "conversationId": "e485f817249ef787125a7714c62d6ae36fa26c19c4ca972cb501d7131d1ba4ed",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Fever",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-01-13T14:59:20.041Z",
            "endTime": "2022-01-13T15:01:34.922Z",
            "timeSpent": 135,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg-BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg-BgAAAAAAAA==/",
            "_etag": "\"050158aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "18719ba177e25deaa22d1fa3c3861a66688fcae42e5e693f0eb0ff9d55495696",
            "conversationId": "18719ba177e25deaa22d1fa3c3861a66688fcae42e5e693f0eb0ff9d55495696",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Excessive hair"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-13T15:18:47.972Z",
            "endTime": "2022-01-13T15:19:18.309Z",
            "timeSpent": 30,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhABgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhABgAAAAAAAA==/",
            "_etag": "\"050159aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "4d21c077f461a1110f52cbe8e6288b7b821e70c1953548c5270165e3ded68fea",
            "conversationId": "4d21c077f461a1110f52cbe8e6288b7b821e70c1953548c5270165e3ded68fea",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Excessive hair"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-13T15:19:53.767Z",
            "endTime": "2022-01-13T15:20:25.709Z",
            "timeSpent": 32,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhBBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhBBgAAAAAAAA==/",
            "_etag": "\"05015aaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "1ca821f72546ccbdc754af8d482787e1bf14f36b2e5784d25b2e03e962c5154d",
            "conversationId": "1ca821f72546ccbdc754af8d482787e1bf14f36b2e5784d25b2e03e962c5154d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Excessive hair"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-13T16:04:52.582Z",
            "endTime": "2022-01-13T16:05:17.589Z",
            "timeSpent": 25,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhCBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhCBgAAAAAAAA==/",
            "_etag": "\"05015baa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "0815528771be3504f209e143a1b02abe6ff4d19ef50938dd880a026c348852c4",
            "conversationId": "0815528771be3504f209e143a1b02abe6ff4d19ef50938dd880a026c348852c4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Runny nose"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-13T20:24:05.341Z",
            "endTime": "2022-01-13T20:26:35.319Z",
            "timeSpent": 150,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhDBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhDBgAAAAAAAA==/",
            "_etag": "\"0501f7aa-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "87696e4edf653777f3ac40dd8ff32bcc70ecf1016a7ff5b815cb2e41d26060a0",
            "conversationId": "87696e4edf653777f3ac40dd8ff32bcc70ecf1016a7ff5b815cb2e41d26060a0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-14T13:06:38.031Z",
            "endTime": "2022-01-14T13:07:44.529Z",
            "timeSpent": 66,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhEBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhEBgAAAAAAAA==/",
            "_etag": "\"0501f8aa-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "ebb40ab27d449374489a31f7b89c5d9de8a5ff9ff90dc39632e0a6d23c7687b8",
            "conversationId": "ebb40ab27d449374489a31f7b89c5d9de8a5ff9ff90dc39632e0a6d23c7687b8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 29,
            "ageGroup": "26-35",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-14T13:56:53.121Z",
            "endTime": "2022-01-14T13:56:53.121Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhFBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhFBgAAAAAAAA==/",
            "_etag": "\"0501f9aa-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "06080172ef71892051343513a120b6b9d4a575d26d66190eca559f76013ab724",
            "conversationId": "06080172ef71892051343513a120b6b9d4a575d26d66190eca559f76013ab724",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-01-18T20:35:48.912Z",
            "endTime": "2022-01-18T20:40:28.614Z",
            "timeSpent": 280,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhGBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhGBgAAAAAAAA==/",
            "_etag": "\"0501faaa-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "911e8c714b05535a01d479a0037283e028f47093077595f0d58cbb668ece4f72",
            "conversationId": "911e8c714b05535a01d479a0037283e028f47093077595f0d58cbb668ece4f72",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-01-18T20:05:08.822Z",
            "endTime": "2022-01-18T20:12:14.908Z",
            "timeSpent": 426,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhHBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhHBgAAAAAAAA==/",
            "_etag": "\"0501fbaa-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "f89935283495c95e1ccff8d12ab30b4645b950536cf49de86f075f4eeb8d5a54",
            "conversationId": "f89935283495c95e1ccff8d12ab30b4645b950536cf49de86f075f4eeb8d5a54",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-01-18T20:15:01.56Z",
            "endTime": "2022-01-18T20:16:35.173Z",
            "timeSpent": 94,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhIBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhIBgAAAAAAAA==/",
            "_etag": "\"0501fcaa-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "a8bb079561bb43eab3e8d2421e06e32f07d53259e28a6c69411c3077c4002c30",
            "conversationId": "a8bb079561bb43eab3e8d2421e06e32f07d53259e28a6c69411c3077c4002c30",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-19T13:00:00Z",
            "endTime": "2022-01-19T13:49:17.771Z",
            "timeSpent": 2958,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhJBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhJBgAAAAAAAA==/",
            "_etag": "\"0501fdaa-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "970b1389c7fae4a7a59b41d0616aa86fcff5d4c727fff9477ed150b7ec1af536",
            "conversationId": "970b1389c7fae4a7a59b41d0616aa86fcff5d4c727fff9477ed150b7ec1af536",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-19T13:52:46.543Z",
            "endTime": "2022-01-19T13:52:46.543Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhKBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhKBgAAAAAAAA==/",
            "_etag": "\"0501feaa-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "95e8dbc1799f107d33082e743b50e9901f359e3f6bd733b14ce29f744eb8087a",
            "conversationId": "95e8dbc1799f107d33082e743b50e9901f359e3f6bd733b14ce29f744eb8087a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-19T16:00:00Z",
            "endTime": "2022-01-19T16:10:02.747Z",
            "timeSpent": 603,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhLBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhLBgAAAAAAAA==/",
            "_etag": "\"0501ffaa-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "61afcdb8cb7369d0478198edb7cbc0ccc3d549a0925a800cab17f5108c88b54c",
            "conversationId": "61afcdb8cb7369d0478198edb7cbc0ccc3d549a0925a800cab17f5108c88b54c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-19T20:01:50.121Z",
            "endTime": "2022-01-19T20:01:50.121Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhMBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhMBgAAAAAAAA==/",
            "_etag": "\"050100ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "40352406193bf1acc0ad904292af33666f2fef1f5bdf7145f6cd4aa2065a3675",
            "conversationId": "40352406193bf1acc0ad904292af33666f2fef1f5bdf7145f6cd4aa2065a3675",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-19T20:01:52.562Z",
            "endTime": "2022-01-19T20:05:53.888Z",
            "timeSpent": 241,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhNBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhNBgAAAAAAAA==/",
            "_etag": "\"050101ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "621d5878ef1ec1bdf15f18fe67a0a43607d3e8689a3cba48a125884a82e41178",
            "conversationId": "621d5878ef1ec1bdf15f18fe67a0a43607d3e8689a3cba48a125884a82e41178",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Runny nose"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will call 9-1-1",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-01-19T20:14:45.858Z",
            "endTime": "2022-01-19T20:17:09.761Z",
            "timeSpent": 144,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 6,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhOBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhOBgAAAAAAAA==/",
            "_etag": "\"050102ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "5666af8cad7ed4851914ffc3b446eb45e39c1322cd46a1b239a7cc9f85afbd82",
            "conversationId": "5666af8cad7ed4851914ffc3b446eb45e39c1322cd46a1b239a7cc9f85afbd82",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-19T20:23:55.613Z",
            "endTime": "2022-01-19T20:32:30.442Z",
            "timeSpent": 515,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhPBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhPBgAAAAAAAA==/",
            "_etag": "\"050103ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "8d57bd612d02023fb9ace91df668352825e4ec7c71311c4fabb3203e42926e19",
            "conversationId": "8d57bd612d02023fb9ace91df668352825e4ec7c71311c4fabb3203e42926e19",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-19T20:23:54.57Z",
            "endTime": "2022-01-19T20:23:54.57Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhQBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhQBgAAAAAAAA==/",
            "_etag": "\"050104ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "83e4a2d854db6e518e21069476c3b725fc236e6717f72656428805768736d0d0",
            "conversationId": "83e4a2d854db6e518e21069476c3b725fc236e6717f72656428805768736d0d0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-19T20:36:36.549Z",
            "endTime": "2022-01-19T20:36:36.549Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhRBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhRBgAAAAAAAA==/",
            "_etag": "\"050105ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "317c670945b48fd8091c218cafbe56ddea040af2ad36d538b9b85b34124591a3",
            "conversationId": "317c670945b48fd8091c218cafbe56ddea040af2ad36d538b9b85b34124591a3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Excessive gambling"
            ],
            "riskFactors": [
                "History of tonsillectomy",
                "Smoking",
                "History of anaphylactic reactions",
                "Pregnancy"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-01-19T21:22:07.166Z",
            "endTime": "2022-01-19T21:23:28.138Z",
            "timeSpent": 81,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhSBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhSBgAAAAAAAA==/",
            "_etag": "\"050106ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "e3b732dd6d09d7fea1b03f839abeb88df52d84da33fbb2de41e67d382bfc0205",
            "conversationId": "e3b732dd6d09d7fea1b03f839abeb88df52d84da33fbb2de41e67d382bfc0205",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-01-19T21:23:46.949Z",
            "endTime": "2022-01-19T21:26:54.398Z",
            "timeSpent": 187,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhTBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhTBgAAAAAAAA==/",
            "_etag": "\"050107ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
},
    
        {
            "id": "4d6821580229fa332cd280eacaa039e24018230cef62b9f1c133cadea2771157",
            "conversationId": "4d6821580229fa332cd280eacaa039e24018230cef62b9f1c133cadea2771157",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Runny nose"
            ],
            "riskFactors": [
                "History of anaphylactic reactions",
                "Diabetes",
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will go to ReadyMED / urgent care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-01-19T21:27:05.513Z",
            "endTime": "2022-01-19T21:32:29.819Z",
            "timeSpent": 324,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhUBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhUBgAAAAAAAA==/",
            "_etag": "\"050108ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "edbed1e3d8ea50777a4bfc99682add5980584b8b4d58900c62657991b74051e7",
            "conversationId": "edbed1e3d8ea50777a4bfc99682add5980584b8b4d58900c62657991b74051e7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-01-19T21:36:30.468Z",
            "endTime": "2022-01-19T21:38:44.965Z",
            "timeSpent": 134,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhVBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhVBgAAAAAAAA==/",
            "_etag": "\"050109ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "b47ea3e8abc90e712cdc7f8af5a1d0151c54d43594d07bed3f55e5ebe1bd6178",
            "conversationId": "b47ea3e8abc90e712cdc7f8af5a1d0151c54d43594d07bed3f55e5ebe1bd6178",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [
                "Smoking",
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-01-19T22:00:52.505Z",
            "endTime": "2022-01-19T22:03:20.127Z",
            "timeSpent": 148,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhWBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhWBgAAAAAAAA==/",
            "_etag": "\"05010aab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "964bae115c30720f6cfdcfe716f14ef0e6a1c3480e90061d82ea4f1fc204cf14",
            "conversationId": "964bae115c30720f6cfdcfe716f14ef0e6a1c3480e90061d82ea4f1fc204cf14",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Runny nose"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-19T22:54:57.353Z",
            "endTime": "2022-01-19T22:56:47.114Z",
            "timeSpent": 110,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhXBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhXBgAAAAAAAA==/",
            "_etag": "\"05010bab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "4f3bbb2efce139cfc15f785a07a9a9cb327ca4d89fcc947608cb9efcc4f683d2",
            "conversationId": "4f3bbb2efce139cfc15f785a07a9a9cb327ca4d89fcc947608cb9efcc4f683d2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-19T22:59:21.423Z",
            "endTime": "2022-01-19T23:15:15.596Z",
            "timeSpent": 954,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhYBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhYBgAAAAAAAA==/",
            "_etag": "\"05010cab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "336a4b018dbb43d4b893661fdb5d835d8f3ac07ed89a4e0eb962004ea87edc52",
            "conversationId": "336a4b018dbb43d4b893661fdb5d835d8f3ac07ed89a4e0eb962004ea87edc52",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-19T23:20:06.599Z",
            "endTime": "2022-01-19T23:20:06.599Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhZBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhZBgAAAAAAAA==/",
            "_etag": "\"05010dab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "f680f954a6f57863a9c39773bfda2eb8e64cce1fe82302e971e9b632d3483ec2",
            "conversationId": "f680f954a6f57863a9c39773bfda2eb8e64cce1fe82302e971e9b632d3483ec2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will call 9-1-1",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-19T23:20:10.081Z",
            "endTime": "2022-01-19T23:30:26.388Z",
            "timeSpent": 616,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 5,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhaBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhaBgAAAAAAAA==/",
            "_etag": "\"05010eab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "8574943943f4a6666f89d10b6bf0474fbd42e49b44fc02e0aad0494a20aa62f7",
            "conversationId": "8574943943f4a6666f89d10b6bf0474fbd42e49b44fc02e0aad0494a20aa62f7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will call 9-1-1",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-01-19T23:42:10.534Z",
            "endTime": "2022-01-19T23:47:05.958Z",
            "timeSpent": 295,
            "isNoteGenerated": "yes",
            "npsGroup": "Passive",
            "npsScore": 7,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhbBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhbBgAAAAAAAA==/",
            "_etag": "\"05010fab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "91b403ac745fba6c2e590a9ecfb3fa73a5b872de18ff6066820513fd879fd776",
            "conversationId": "91b403ac745fba6c2e590a9ecfb3fa73a5b872de18ff6066820513fd879fd776",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Dry cough"
            ],
            "riskFactors": [
                "Diabetes",
                "Hypertension",
                "Heart disease"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-01-19T23:50:16.618Z",
            "endTime": "2022-01-20T00:01:28.761Z",
            "timeSpent": 672,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhcBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhcBgAAAAAAAA==/",
            "_etag": "\"050110ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "c1ef1926a7483b21e69a85d4ea10f2f548c4d9066b79fa6241898185b1b6ab7e",
            "conversationId": "c1ef1926a7483b21e69a85d4ea10f2f548c4d9066b79fa6241898185b1b6ab7e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Fever"
            ],
            "riskFactors": [
                "Smoking",
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-01-20T02:39:24.544Z",
            "endTime": "2022-01-20T02:46:47.548Z",
            "timeSpent": 443,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhdBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhdBgAAAAAAAA==/",
            "_etag": "\"050111ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "123f001412e7f7df184e3ea02ace4b72f55edca145e1f74cb09a270cc233f922",
            "conversationId": "123f001412e7f7df184e3ea02ace4b72f55edca145e1f74cb09a270cc233f922",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-01-20T02:53:24.399Z",
            "endTime": "2022-01-20T02:58:11.525Z",
            "timeSpent": 287,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIheBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIheBgAAAAAAAA==/",
            "_etag": "\"050112ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "1cd26adabd99a08911434a0d9e7dc08aefca1874d8d7b8324b3e4fa75f19b6a4",
            "conversationId": "1cd26adabd99a08911434a0d9e7dc08aefca1874d8d7b8324b3e4fa75f19b6a4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-20T02:00:00Z",
            "endTime": "2022-01-20T02:20:41.619Z",
            "timeSpent": 1242,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhfBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhfBgAAAAAAAA==/",
            "_etag": "\"050113ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "16bbad75e3a7e79157e6aa142a2e6e02ca7a5d5477f32cc1ec3cfb2ccbc36809",
            "conversationId": "16bbad75e3a7e79157e6aa142a2e6e02ca7a5d5477f32cc1ec3cfb2ccbc36809",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-01-20T02:21:14.853Z",
            "endTime": "2022-01-20T02:36:50.404Z",
            "timeSpent": 936,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhgBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhgBgAAAAAAAA==/",
            "_etag": "\"050114ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "aa5bc128d6e2082dd1d146580fd032333d17e21f09f267770902937bffcf2694",
            "conversationId": "aa5bc128d6e2082dd1d146580fd032333d17e21f09f267770902937bffcf2694",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-20T02:53:23.782Z",
            "endTime": "2022-01-20T02:53:23.782Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhhBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhhBgAAAAAAAA==/",
            "_etag": "\"050115ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "f6c4bdedb18f3b00f5a28270e2a53727f5b04d912d08d710ce4bc3367776768e",
            "conversationId": "f6c4bdedb18f3b00f5a28270e2a53727f5b04d912d08d710ce4bc3367776768e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-20T09:00:00Z",
            "endTime": "2022-01-20T09:14:22.163Z",
            "timeSpent": 862,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhiBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhiBgAAAAAAAA==/",
            "_etag": "\"050116ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "ca10a25235a0346ee6f93b20406fa911924ba41d2827c098d09e82580b5dc114",
            "conversationId": "ca10a25235a0346ee6f93b20406fa911924ba41d2827c098d09e82580b5dc114",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-01-20T09:00:00Z",
            "endTime": "2022-01-20T09:34:25.779Z",
            "timeSpent": 2066,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhjBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhjBgAAAAAAAA==/",
            "_etag": "\"050117ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "ed0b62b0a074af2ddd8cade5fa2a51f7c1c32c5d65ac18a8c70310d15a0b5bf0",
            "conversationId": "ed0b62b0a074af2ddd8cade5fa2a51f7c1c32c5d65ac18a8c70310d15a0b5bf0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-10T13:07:42.797Z",
            "endTime": "2022-02-10T13:08:20.167Z",
            "timeSpent": 37,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhkBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhkBgAAAAAAAA==/",
            "_etag": "\"050118ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "adeed4060bb51966acc9ab954a3e17bff23eb8704fb3b92aef2ef5a7b341665c",
            "conversationId": "adeed4060bb51966acc9ab954a3e17bff23eb8704fb3b92aef2ef5a7b341665c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-10T14:09:20.243Z",
            "endTime": "2022-02-10T14:09:20.243Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhlBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhlBgAAAAAAAA==/",
            "_etag": "\"050119ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "3ab71585f574abbdc5dc53207c5cd5493d8f9ea3437a45c983d019cc0dde5c21",
            "conversationId": "3ab71585f574abbdc5dc53207c5cd5493d8f9ea3437a45c983d019cc0dde5c21",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-10T14:03:16.549Z",
            "endTime": "2022-02-10T14:03:23.95Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhmBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhmBgAAAAAAAA==/",
            "_etag": "\"05011aab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "5d88b4caf0be59119e4babf9da891b86c52005aa5750a1a3770eda35e07d80a2",
            "conversationId": "5d88b4caf0be59119e4babf9da891b86c52005aa5750a1a3770eda35e07d80a2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-02-10T16:24:00.708Z",
            "endTime": "2022-02-10T16:26:01.084Z",
            "timeSpent": 120,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhnBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhnBgAAAAAAAA==/",
            "_etag": "\"05011bab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "523b4838b81db7c032f84ff6ca8dd50b8094e41516e56299788a68cb0122ecb2",
            "conversationId": "523b4838b81db7c032f84ff6ca8dd50b8094e41516e56299788a68cb0122ecb2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-10T20:27:23.383Z",
            "endTime": "2022-02-10T20:27:23.383Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhoBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhoBgAAAAAAAA==/",
            "_etag": "\"05011cab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "8ab6b7d893e6d1ff17cb959c43a763573b29585abb8e8c3a40181279d7ce22a7",
            "conversationId": "8ab6b7d893e6d1ff17cb959c43a763573b29585abb8e8c3a40181279d7ce22a7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Dry eyes",
                "Itching of eyes",
                "Dry mouth"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-10T20:27:23.622Z",
            "endTime": "2022-02-10T20:34:59.132Z",
            "timeSpent": 456,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhpBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhpBgAAAAAAAA==/",
            "_etag": "\"05011dab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "f57d7bd8eea7f37356890d896f9eb165d55ec613a232457991eb06767b6ddce8",
            "conversationId": "f57d7bd8eea7f37356890d896f9eb165d55ec613a232457991eb06767b6ddce8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-10T22:29:19.746Z",
            "endTime": "2022-02-10T22:29:19.746Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhqBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhqBgAAAAAAAA==/",
            "_etag": "\"05011eab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "4ca8a6ff3679c3500109dffa60f11da30b7104f18c76a8350f4d6cb8c9972cb0",
            "conversationId": "4ca8a6ff3679c3500109dffa60f11da30b7104f18c76a8350f4d6cb8c9972cb0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-02-11T13:32:55.905Z",
            "endTime": "2022-02-11T13:36:03.093Z",
            "timeSpent": 187,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhrBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhrBgAAAAAAAA==/",
            "_etag": "\"05011fab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "ca417d1b99a87a41c431094cbdbaa1a204c945f43b10f3481e5f3f8073313580",
            "conversationId": "ca417d1b99a87a41c431094cbdbaa1a204c945f43b10f3481e5f3f8073313580",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-11T16:38:11.705Z",
            "endTime": "2022-02-11T16:40:40.188Z",
            "timeSpent": 148,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhsBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhsBgAAAAAAAA==/",
            "_etag": "\"050120ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "6a1b7b0806821d7301cb30ab318734acc4bb58df1e5e140f5184e272882bb0d1",
            "conversationId": "6a1b7b0806821d7301cb30ab318734acc4bb58df1e5e140f5184e272882bb0d1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-11T16:42:34.066Z",
            "endTime": "2022-02-11T16:42:40.749Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhtBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhtBgAAAAAAAA==/",
            "_etag": "\"050121ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "def2b20c2d5cfca17b0aacc4ee461c3bff667623ea3f523f6f3409d97da889bd",
            "conversationId": "def2b20c2d5cfca17b0aacc4ee461c3bff667623ea3f523f6f3409d97da889bd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-02-11T16:50:39.606Z",
            "endTime": "2022-02-11T16:50:47.635Z",
            "timeSpent": 8,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhuBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhuBgAAAAAAAA==/",
            "_etag": "\"050122ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "7e7ef179227a133b7173b60f1b76ff6bffe2e49f74ec7894fe4701b48a9ce324",
            "conversationId": "7e7ef179227a133b7173b60f1b76ff6bffe2e49f74ec7894fe4701b48a9ce324",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-11T16:50:38.298Z",
            "endTime": "2022-02-11T16:50:38.298Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhvBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhvBgAAAAAAAA==/",
            "_etag": "\"050123ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "e010340e43337e1630d8f715ff14e15c0a3ac24c4cff47097925f9d789bad758",
            "conversationId": "e010340e43337e1630d8f715ff14e15c0a3ac24c4cff47097925f9d789bad758",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-02-11T16:53:34.079Z",
            "endTime": "2022-02-11T16:54:46.095Z",
            "timeSpent": 72,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhwBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhwBgAAAAAAAA==/",
            "_etag": "\"050124ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "c92a247209e17f1091a1e953a5610bff6b692d1676d604ea9475275528d4bc4d",
            "conversationId": "c92a247209e17f1091a1e953a5610bff6b692d1676d604ea9475275528d4bc4d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-11T16:58:58.569Z",
            "endTime": "2022-02-11T16:58:58.569Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhxBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhxBgAAAAAAAA==/",
            "_etag": "\"050125ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "90b15d3070216a2366c383c9b8803a980a1b7223e3d64cc040b31285e7e3d39b",
            "conversationId": "90b15d3070216a2366c383c9b8803a980a1b7223e3d64cc040b31285e7e3d39b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "{\"nps\":\"undefined\",\"comment\":\"OMG\",\"plan\":\"\",\"phone\":\"\",\"canCall\":false}",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-02-11T16:59:00.158Z",
            "endTime": "2022-02-11T17:03:18.984Z",
            "timeSpent": 259,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhyBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhyBgAAAAAAAA==/",
            "_etag": "\"050126ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "13e83408dced89712e625b9693ff52db9518ca7ae9d267b70a7ade8f47220514",
            "conversationId": "13e83408dced89712e625b9693ff52db9518ca7ae9d267b70a7ade8f47220514",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-11T17:24:38.826Z",
            "endTime": "2022-02-11T17:24:38.826Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhzBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhzBgAAAAAAAA==/",
            "_etag": "\"050127ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "3bf289d5a40d519762c48505cf5501822f937072aec9890072b819f0063d7a38",
            "conversationId": "3bf289d5a40d519762c48505cf5501822f937072aec9890072b819f0063d7a38",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will call 9-1-1",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-02-11T17:24:41.289Z",
            "endTime": "2022-02-11T17:25:36.067Z",
            "timeSpent": 55,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh0BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh0BgAAAAAAAA==/",
            "_etag": "\"050128ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "e56ebc702137187295283bd11ed36087dfcededbd951f244365dc3ed13f02a5b",
            "conversationId": "e56ebc702137187295283bd11ed36087dfcededbd951f244365dc3ed13f02a5b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-02-11T17:04:53.953Z",
            "endTime": "2022-02-11T17:08:47.928Z",
            "timeSpent": 234,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh1BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh1BgAAAAAAAA==/",
            "_etag": "\"050129ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "55cb4f1ed382bc3abfb1232393456961f7b83672d6892f6c97a720e0224184ea",
            "conversationId": "55cb4f1ed382bc3abfb1232393456961f7b83672d6892f6c97a720e0224184ea",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "{\"nps\":\"undefined\",\"comment\":\"issue is occurring with 911 as well\",\"plan\":\"\",\"phone\":\"\",\"canCall\":false}",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-02-11T17:25:51.6Z",
            "endTime": "2022-02-11T17:29:22.537Z",
            "timeSpent": 211,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 6,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh2BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh2BgAAAAAAAA==/",
            "_etag": "\"05012aab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "006f8bfa96b840c1ecea397829baa9357ce2bc50ebe514b7687f4ecce5684803",
            "conversationId": "006f8bfa96b840c1ecea397829baa9357ce2bc50ebe514b7687f4ecce5684803",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-02-11T17:04:16.572Z",
            "endTime": "2022-02-11T17:04:28.668Z",
            "timeSpent": 12,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh3BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh3BgAAAAAAAA==/",
            "_etag": "\"05012bab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "f65579a2a0632581b968728406f833163eb93fcb4361c154714eb7554b1aabed",
            "conversationId": "f65579a2a0632581b968728406f833163eb93fcb4361c154714eb7554b1aabed",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe joint pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will go to ReadyMED / urgent care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-02-11T18:56:26.627Z",
            "endTime": "2022-02-11T19:20:01.582Z",
            "timeSpent": 1415,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 6,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh4BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh4BgAAAAAAAA==/",
            "_etag": "\"05012cab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "0eb08e628b026eedc5224cc2579eafcbe2f53561704960b325297e6dfeb67c40",
            "conversationId": "0eb08e628b026eedc5224cc2579eafcbe2f53561704960b325297e6dfeb67c40",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-02-11T20:07:38.877Z",
            "endTime": "2022-02-11T20:12:02.426Z",
            "timeSpent": 264,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 6,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh5BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh5BgAAAAAAAA==/",
            "_etag": "\"05012dab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "e07eeb17b70df1359f300d410e70ed980cc654bc747e1296757106b8a53f9f40",
            "conversationId": "e07eeb17b70df1359f300d410e70ed980cc654bc747e1296757106b8a53f9f40",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-11T20:31:35.304Z",
            "endTime": "2022-02-11T20:49:26.378Z",
            "timeSpent": 1071,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh6BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh6BgAAAAAAAA==/",
            "_etag": "\"05012eab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "df7516de79a2fab80bcd0e3871cfcb52ea1171453a34c250b4cf1588140a9571",
            "conversationId": "df7516de79a2fab80bcd0e3871cfcb52ea1171453a34c250b4cf1588140a9571",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-11T20:07:31.775Z",
            "endTime": "2022-02-11T20:07:31.775Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh7BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh7BgAAAAAAAA==/",
            "_etag": "\"05012fab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "dc85f3cc52aed8ee087cd3162214efd44bb2aa78e62fb831f4b0c72c0a5a97a1",
            "conversationId": "dc85f3cc52aed8ee087cd3162214efd44bb2aa78e62fb831f4b0c72c0a5a97a1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-11T20:07:33.218Z",
            "endTime": "2022-02-11T20:07:33.218Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh8BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh8BgAAAAAAAA==/",
            "_etag": "\"050130ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "de8d7b0fa6e8c893264039b599962c72490ca3021fe1fbfdfd95df2c09008fd7",
            "conversationId": "de8d7b0fa6e8c893264039b599962c72490ca3021fe1fbfdfd95df2c09008fd7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-11T20:07:36.663Z",
            "endTime": "2022-02-11T20:07:36.663Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh9BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh9BgAAAAAAAA==/",
            "_etag": "\"050131ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "d94bc180a03c8942cc4e12bc8ae2a6fe54ee671007aceadaaa3594a130d8dd01",
            "conversationId": "d94bc180a03c8942cc4e12bc8ae2a6fe54ee671007aceadaaa3594a130d8dd01",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-11T20:07:37.199Z",
            "endTime": "2022-02-11T20:07:37.199Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh+BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh+BgAAAAAAAA==/",
            "_etag": "\"050132ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "2a65577464304d6638ecc5a99d3d37a83cda13f8c54b64e0dcec6eaafd69f2ab",
            "conversationId": "2a65577464304d6638ecc5a99d3d37a83cda13f8c54b64e0dcec6eaafd69f2ab",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-11T20:21:50.313Z",
            "endTime": "2022-02-11T20:28:21.878Z",
            "timeSpent": 392,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh-BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh-BgAAAAAAAA==/",
            "_etag": "\"050133ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "6e8d468409cea1cb3ae78b15c8a36fa6cfd37229e7fb398b301722bf22a9c017",
            "conversationId": "6e8d468409cea1cb3ae78b15c8a36fa6cfd37229e7fb398b301722bf22a9c017",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-02-11T20:53:56.251Z",
            "endTime": "2022-02-11T20:55:41.924Z",
            "timeSpent": 106,
            "isNoteGenerated": "no",
            "npsGroup": "Passive",
            "npsScore": 7,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiABgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiABgAAAAAAAA==/",
            "_etag": "\"050134ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "7354fc2d595cb0a71e308da6ccfef90c96758761abe8a34cfa2346fb7a2f3bc3",
            "conversationId": "7354fc2d595cb0a71e308da6ccfef90c96758761abe8a34cfa2346fb7a2f3bc3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-11T20:07:13.743Z",
            "endTime": "2022-02-11T20:07:13.743Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiBBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiBBgAAAAAAAA==/",
            "_etag": "\"050135ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "7e8b28e5436ef09dec726c775bbaf8922e022274f4aeebebf55a373658d7b95b",
            "conversationId": "7e8b28e5436ef09dec726c775bbaf8922e022274f4aeebebf55a373658d7b95b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-11T20:30:40.887Z",
            "endTime": "2022-02-11T20:30:40.887Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiCBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiCBgAAAAAAAA==/",
            "_etag": "\"050136ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "4ae7f8ea1a474e54c9dff1a483cbc950882dfcb06d24aa099d7bceb763a51ed1",
            "conversationId": "4ae7f8ea1a474e54c9dff1a483cbc950882dfcb06d24aa099d7bceb763a51ed1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-02-11T21:14:07.671Z",
            "endTime": "2022-02-11T21:19:36.272Z",
            "timeSpent": 329,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiDBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiDBgAAAAAAAA==/",
            "_etag": "\"050137ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "f38f764a2af8842205b89bc24a70bcbb0fae20b5d80576348929ced4335c9961",
            "conversationId": "f38f764a2af8842205b89bc24a70bcbb0fae20b5d80576348929ced4335c9961",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-11T21:20:06.671Z",
            "endTime": "2022-02-11T21:22:23.091Z",
            "timeSpent": 136,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiEBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiEBgAAAAAAAA==/",
            "_etag": "\"050138ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "0863f5e2b847adcc901e940994201414a817f211bea72ded3b7d2f205bc625db",
            "conversationId": "0863f5e2b847adcc901e940994201414a817f211bea72ded3b7d2f205bc625db",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-11T21:04:48.406Z",
            "endTime": "2022-02-11T21:04:48.406Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiFBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiFBgAAAAAAAA==/",
            "_etag": "\"050139ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "beee45bbe0b47b05566360e61ac823ba630d6f0ca98b0aa236ed5b540ad0cf48",
            "conversationId": "beee45bbe0b47b05566360e61ac823ba630d6f0ca98b0aa236ed5b540ad0cf48",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-11T21:04:52.015Z",
            "endTime": "2022-02-11T21:04:57.983Z",
            "timeSpent": 6,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiGBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiGBgAAAAAAAA==/",
            "_etag": "\"05013aab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "a7b55defd9d8f9d13e970f976d43b49e00caaa8884d0b0c227af351fe7dbcb33",
            "conversationId": "a7b55defd9d8f9d13e970f976d43b49e00caaa8884d0b0c227af351fe7dbcb33",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-02-11T21:05:29.543Z",
            "endTime": "2022-02-11T21:09:55.937Z",
            "timeSpent": 266,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiHBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiHBgAAAAAAAA==/",
            "_etag": "\"05013bab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "204e50b0647af599863a5756ff228b618837b553e94cd94ae3a36b3887709fa0",
            "conversationId": "204e50b0647af599863a5756ff228b618837b553e94cd94ae3a36b3887709fa0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Tooth hurts when chewing",
                "Toothache"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-02-11T21:22:53.616Z",
            "endTime": "2022-02-11T21:24:45.838Z",
            "timeSpent": 112,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiIBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiIBgAAAAAAAA==/",
            "_etag": "\"05013cab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "49eaee945024974b0ce6ba6bd168d371b4206f9fec2345b4907ce4a78060ef23",
            "conversationId": "49eaee945024974b0ce6ba6bd168d371b4206f9fec2345b4907ce4a78060ef23",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-14T13:43:49.497Z",
            "endTime": "2022-02-14T13:44:58.312Z",
            "timeSpent": 69,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiJBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiJBgAAAAAAAA==/",
            "_etag": "\"05013dab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "06239cbb7beb1bc120ad1a297069bd920c26c9ce76e62cdd8f9d1f0a0e1fe41d",
            "conversationId": "06239cbb7beb1bc120ad1a297069bd920c26c9ce76e62cdd8f9d1f0a0e1fe41d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-02-14T15:46:39.39Z",
            "endTime": "2022-02-14T15:58:45.371Z",
            "timeSpent": 726,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 6,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiKBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiKBgAAAAAAAA==/",
            "_etag": "\"05013eab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "620def461bdc29135f89f760290edcc54205999c3f5a42a8cffafc0eb17c207c",
            "conversationId": "620def461bdc29135f89f760290edcc54205999c3f5a42a8cffafc0eb17c207c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Hair loss",
                "Mood swings"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-02-14T15:59:10.638Z",
            "endTime": "2022-02-14T16:05:57.185Z",
            "timeSpent": 407,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 6,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiLBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiLBgAAAAAAAA==/",
            "_etag": "\"05013fab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "9d9c26e4ce1d3c0de3d3cc38d535a2109959d54f45bb3b1aa2a3a3fdf4ca8153",
            "conversationId": "9d9c26e4ce1d3c0de3d3cc38d535a2109959d54f45bb3b1aa2a3a3fdf4ca8153",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-14T14:27:01.247Z",
            "endTime": "2022-02-14T14:27:01.247Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiMBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiMBgAAAAAAAA==/",
            "_etag": "\"050140ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "6e97c139abd84b5a49d246de7d8f3113e36eaa475561b2f03b529fe2cd38c57a",
            "conversationId": "6e97c139abd84b5a49d246de7d8f3113e36eaa475561b2f03b529fe2cd38c57a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-14T14:28:09.295Z",
            "endTime": "2022-02-14T14:28:15.859Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiNBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiNBgAAAAAAAA==/",
            "_etag": "\"050141ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "cf72e62cdd86840f02fc1f164a6f66ee7dbe25a204c145700f920bb3f77fe3cc",
            "conversationId": "cf72e62cdd86840f02fc1f164a6f66ee7dbe25a204c145700f920bb3f77fe3cc",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-02-14T14:27:24.608Z",
            "endTime": "2022-02-14T14:27:45.366Z",
            "timeSpent": 21,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiOBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiOBgAAAAAAAA==/",
            "_etag": "\"050142ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "69ee5843036006741f89690bb73943e3460eabed28279f87d2b42d2db4d3ed7a",
            "conversationId": "69ee5843036006741f89690bb73943e3460eabed28279f87d2b42d2db4d3ed7a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Hair loss",
                "Mood swings"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-02-14T15:01:54.945Z",
            "endTime": "2022-02-14T15:36:19.132Z",
            "timeSpent": 2064,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiPBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiPBgAAAAAAAA==/",
            "_etag": "\"050143ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "152c4e80f104173dff472c30b5e8abb0f1dcaa47e5007ea5a51c8b3f40590caf",
            "conversationId": "152c4e80f104173dff472c30b5e8abb0f1dcaa47e5007ea5a51c8b3f40590caf",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-14T15:45:07.627Z",
            "endTime": "2022-02-14T15:45:07.627Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiQBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiQBgAAAAAAAA==/",
            "_etag": "\"050144ab-0000-0100-0000-6242e45f0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551007
        },
        {
            "id": "299b67eb1f6d746a297a35a7a52869930618bd895ecc8787277b6f4b270557ed",
            "conversationId": "299b67eb1f6d746a297a35a7a52869930618bd895ecc8787277b6f4b270557ed",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-14T15:45:07.9Z",
            "endTime": "2022-02-14T15:45:07.9Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiRBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiRBgAAAAAAAA==/",
            "_etag": "\"05015daa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "3698bb2b15b173075ccc917a2577756ccc17814e6cc9267bfc439601a0d2b8e1",
            "conversationId": "3698bb2b15b173075ccc917a2577756ccc17814e6cc9267bfc439601a0d2b8e1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-02-14T16:07:51.409Z",
            "endTime": "2022-02-14T16:08:40.112Z",
            "timeSpent": 49,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 6,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiSBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiSBgAAAAAAAA==/",
            "_etag": "\"05015eaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "dc597af6c00fe5ab2aeb2a1225de8812aef0d8dcaee52e046cd1e89c81d300c7",
            "conversationId": "dc597af6c00fe5ab2aeb2a1225de8812aef0d8dcaee52e046cd1e89c81d300c7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-14T16:18:43.408Z",
            "endTime": "2022-02-14T16:26:38.396Z",
            "timeSpent": 475,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiTBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiTBgAAAAAAAA==/",
            "_etag": "\"05015faa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "618b81e06d29af9a30c92db9e98098fda74f05a0a6d899bb9691b3cd36b41455",
            "conversationId": "618b81e06d29af9a30c92db9e98098fda74f05a0a6d899bb9691b3cd36b41455",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-14T16:27:54.85Z",
            "endTime": "2022-02-14T16:27:54.85Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiUBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiUBgAAAAAAAA==/",
            "_etag": "\"050160aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "29b22616406e2ced695ddb91f94f146019907c32435966d393ade91015044cfe",
            "conversationId": "29b22616406e2ced695ddb91f94f146019907c32435966d393ade91015044cfe",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-14T16:28:18.147Z",
            "endTime": "2022-02-14T16:28:18.147Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiVBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiVBgAAAAAAAA==/",
            "_etag": "\"050161aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "57823490ceed34b744e0d10a9d0053c4b216a3119b3e1e7e6a54567a99517595",
            "conversationId": "57823490ceed34b744e0d10a9d0053c4b216a3119b3e1e7e6a54567a99517595",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-14T16:28:52.284Z",
            "endTime": "2022-02-14T16:28:52.284Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiWBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiWBgAAAAAAAA==/",
            "_etag": "\"050162aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "9c0da0492a54e3f6d3c6581aa7a5583152a5ef04489856e3f19f9d8787e0b1d5",
            "conversationId": "9c0da0492a54e3f6d3c6581aa7a5583152a5ef04489856e3f19f9d8787e0b1d5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-02-14T16:29:40.047Z",
            "endTime": "2022-02-14T16:30:51.953Z",
            "timeSpent": 72,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiXBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiXBgAAAAAAAA==/",
            "_etag": "\"050163aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "cf48b46edde5718cabb7faa14d2a050e4e83fc2e9cf10b9c697c2965c775214d",
            "conversationId": "cf48b46edde5718cabb7faa14d2a050e4e83fc2e9cf10b9c697c2965c775214d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-14T16:33:30.199Z",
            "endTime": "2022-02-14T16:33:30.199Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiYBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiYBgAAAAAAAA==/",
            "_etag": "\"050164aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "8d5370dc00bb965d9cc40261087286221860667ae6fe7c542b3317d9ab48c8fd",
            "conversationId": "8d5370dc00bb965d9cc40261087286221860667ae6fe7c542b3317d9ab48c8fd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-02-14T16:33:36.862Z",
            "endTime": "2022-02-14T16:34:22.931Z",
            "timeSpent": 46,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiZBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiZBgAAAAAAAA==/",
            "_etag": "\"050165aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "278c766efa182a1cc4ee328b69d2735be14fbfd0efee200308eb1739a05094a7",
            "conversationId": "278c766efa182a1cc4ee328b69d2735be14fbfd0efee200308eb1739a05094a7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-14T16:35:09.156Z",
            "endTime": "2022-02-14T16:36:29.409Z",
            "timeSpent": 80,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiaBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiaBgAAAAAAAA==/",
            "_etag": "\"050166aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "f9466cc92f6cb8f8beae7168ce4e8b9ae4835e6369dca1d56642c7ca18896cc8",
            "conversationId": "f9466cc92f6cb8f8beae7168ce4e8b9ae4835e6369dca1d56642c7ca18896cc8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-14T16:37:01.553Z",
            "endTime": "2022-02-14T16:37:01.553Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIibBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIibBgAAAAAAAA==/",
            "_etag": "\"050167aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "dd9d1cb0b98e6ab9440f105d9f2e4fecc20ec72aaed4e5eca6c58a47754e2ff8",
            "conversationId": "dd9d1cb0b98e6ab9440f105d9f2e4fecc20ec72aaed4e5eca6c58a47754e2ff8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-02-14T16:39:52.344Z",
            "endTime": "2022-02-14T16:41:14.73Z",
            "timeSpent": 82,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIicBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIicBgAAAAAAAA==/",
            "_etag": "\"050168aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "3806ec041c1ab85500ed1ac800f93dc668d0c90f02f14c326caea37dd7c865f3",
            "conversationId": "3806ec041c1ab85500ed1ac800f93dc668d0c90f02f14c326caea37dd7c865f3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-14T16:42:44.115Z",
            "endTime": "2022-02-14T16:42:44.115Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIidBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIidBgAAAAAAAA==/",
            "_etag": "\"050169aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "bb7fbb472afaf345f5928ad45fbef90612e9efd1e88e390ebcbdbc5d717be888",
            "conversationId": "bb7fbb472afaf345f5928ad45fbef90612e9efd1e88e390ebcbdbc5d717be888",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-14T16:42:54.469Z",
            "endTime": "2022-02-14T16:42:54.469Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIieBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIieBgAAAAAAAA==/",
            "_etag": "\"05016aaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "5bc0c612c057df2059cfc774a174f5dc401599aa62734277eb66ff8ea95020aa",
            "conversationId": "5bc0c612c057df2059cfc774a174f5dc401599aa62734277eb66ff8ea95020aa",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-14T16:42:43.329Z",
            "endTime": "2022-02-14T16:42:43.329Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIifBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIifBgAAAAAAAA==/",
            "_etag": "\"05016baa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "7a8ace11000c0c749ad0dc4517d4e91b128abdefc94412a2583a4ef022249863",
            "conversationId": "7a8ace11000c0c749ad0dc4517d4e91b128abdefc94412a2583a4ef022249863",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-14T16:42:54.763Z",
            "endTime": "2022-02-14T16:42:54.763Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIigBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIigBgAAAAAAAA==/",
            "_etag": "\"05016caa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "b4ec5705c7283d30e731126f31f2e476bab6f41317be755f68c6e9149954f200",
            "conversationId": "b4ec5705c7283d30e731126f31f2e476bab6f41317be755f68c6e9149954f200",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Dry skin"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-02-14T17:07:00.878Z",
            "endTime": "2022-02-14T17:21:32.043Z",
            "timeSpent": 871,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 6,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIihBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIihBgAAAAAAAA==/",
            "_etag": "\"05016daa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "0a303c99ffd630fa4a4874a95a7bfbb03b280802aa8a2ac9e589df945b4618af",
            "conversationId": "0a303c99ffd630fa4a4874a95a7bfbb03b280802aa8a2ac9e589df945b4618af",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-02-14T17:46:19.59Z",
            "endTime": "2022-02-14T17:47:44.03Z",
            "timeSpent": 84,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiiBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiiBgAAAAAAAA==/",
            "_etag": "\"05016eaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "5f10057318594dad29ba228916d3d2938ad9f15bf47b540b1059ffc1b3857a09",
            "conversationId": "5f10057318594dad29ba228916d3d2938ad9f15bf47b540b1059ffc1b3857a09",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-14T17:52:45.471Z",
            "endTime": "2022-02-14T17:53:34.316Z",
            "timeSpent": 49,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIijBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIijBgAAAAAAAA==/",
            "_etag": "\"05016faa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "e9ce811f7c52bbb8d0e58cc7a6216d032cbdc2f60d37ffa6d159a8550ca0110b",
            "conversationId": "e9ce811f7c52bbb8d0e58cc7a6216d032cbdc2f60d37ffa6d159a8550ca0110b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-14T17:04:23.549Z",
            "endTime": "2022-02-14T17:04:28.033Z",
            "timeSpent": 4,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIikBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIikBgAAAAAAAA==/",
            "_etag": "\"050170aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "635c43ebfa1f59ebe51cf112f44f3c68dc7f58c3a217f1b493fcc74a5537f4c2",
            "conversationId": "635c43ebfa1f59ebe51cf112f44f3c68dc7f58c3a217f1b493fcc74a5537f4c2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-14T17:06:53.03Z",
            "endTime": "2022-02-14T17:06:53.03Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIilBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIilBgAAAAAAAA==/",
            "_etag": "\"050171aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "62df358b25b237e91d93e308ec5701463c85e7207d96aa464a49a88756eeb9c1",
            "conversationId": "62df358b25b237e91d93e308ec5701463c85e7207d96aa464a49a88756eeb9c1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will go to ReadyMED / urgent care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-02-14T18:57:19.398Z",
            "endTime": "2022-02-14T19:14:46.349Z",
            "timeSpent": 1047,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 5,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIimBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIimBgAAAAAAAA==/",
            "_etag": "\"050172aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "59c8ab5f6377f88b97409cc7aecf4ac69eff088bb6cd399e072117161b2084d6",
            "conversationId": "59c8ab5f6377f88b97409cc7aecf4ac69eff088bb6cd399e072117161b2084d6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-02-14T18:10:15.812Z",
            "endTime": "2022-02-14T18:11:37.496Z",
            "timeSpent": 82,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIinBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIinBgAAAAAAAA==/",
            "_etag": "\"050173aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "2e28192b26b284ab1a2d7f0e254b135e4e86690ab854fd37f37dea24af95dd2b",
            "conversationId": "2e28192b26b284ab1a2d7f0e254b135e4e86690ab854fd37f37dea24af95dd2b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-02-14T18:00:16.406Z",
            "endTime": "2022-02-14T18:06:36.536Z",
            "timeSpent": 380,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIioBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIioBgAAAAAAAA==/",
            "_etag": "\"050174aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "66bbcca711a76910e2af48d62917505a67f08454ebab8c5168bf5d878b2a67c4",
            "conversationId": "66bbcca711a76910e2af48d62917505a67f08454ebab8c5168bf5d878b2a67c4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-14T18:18:48.884Z",
            "endTime": "2022-02-14T18:25:40.78Z",
            "timeSpent": 412,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIipBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIipBgAAAAAAAA==/",
            "_etag": "\"050175aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "481f6d994f089a7f7ab6c8754e9171fd62492a9d0d4e1201694d9eb3aab0ab1f",
            "conversationId": "481f6d994f089a7f7ab6c8754e9171fd62492a9d0d4e1201694d9eb3aab0ab1f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-14T18:18:39.749Z",
            "endTime": "2022-02-14T18:18:39.749Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiqBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiqBgAAAAAAAA==/",
            "_etag": "\"050176aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "8ce41103ca3388333d0dbfd3cb401e2d6af71b59ced66880620a66e8927c3cba",
            "conversationId": "8ce41103ca3388333d0dbfd3cb401e2d6af71b59ced66880620a66e8927c3cba",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Call 9-1-1",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-02-14T19:20:41.459Z",
            "endTime": "2022-02-14T19:28:23.71Z",
            "timeSpent": 462,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIirBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIirBgAAAAAAAA==/",
            "_etag": "\"050178aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "30e2c1d9f8b8d40fe15c44f1c768a7b31086cf27676c58f5724888b1aea483cd",
            "conversationId": "30e2c1d9f8b8d40fe15c44f1c768a7b31086cf27676c58f5724888b1aea483cd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Call 9-1-1",
            "patientPlansTo": "I will call 9-1-1",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-02-14T19:29:26.785Z",
            "endTime": "2022-02-14T19:31:07.34Z",
            "timeSpent": 101,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 5,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIisBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIisBgAAAAAAAA==/",
            "_etag": "\"050179aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "62daa2b163644533976c9443da8ec7b4da16fd9520607197ee9f68e9c45396d1",
            "conversationId": "62daa2b163644533976c9443da8ec7b4da16fd9520607197ee9f68e9c45396d1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-02-14T19:31:24.846Z",
            "endTime": "2022-02-14T19:32:03.896Z",
            "timeSpent": 39,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIitBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIitBgAAAAAAAA==/",
            "_etag": "\"05017aaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "daf19eba32d6d9832c97e713264dc843c2e0608761e9e34541d5b7868cac3206",
            "conversationId": "daf19eba32d6d9832c97e713264dc843c2e0608761e9e34541d5b7868cac3206",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Call 9-1-1",
            "patientPlansTo": "I will call 9-1-1",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-02-15T01:34:47.188Z",
            "endTime": "2022-02-15T01:46:22.494Z",
            "timeSpent": 695,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiuBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiuBgAAAAAAAA==/",
            "_etag": "\"05017baa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "5d6a44146d9e1ce7829efbad2491bbdf0029ddff0f504fe120b9315548d856cd",
            "conversationId": "5d6a44146d9e1ce7829efbad2491bbdf0029ddff0f504fe120b9315548d856cd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-02-15T01:59:18.096Z",
            "endTime": "2022-02-15T02:02:13.807Z",
            "timeSpent": 176,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIivBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIivBgAAAAAAAA==/",
            "_etag": "\"05017caa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "9abbc1444848800a388458ef677cd0faffb309c75eb9cfad6c73fdbf9352c2cc",
            "conversationId": "9abbc1444848800a388458ef677cd0faffb309c75eb9cfad6c73fdbf9352c2cc",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Seizures"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-02-15T01:57:05.726Z",
            "endTime": "2022-02-15T01:57:58.637Z",
            "timeSpent": 53,
            "isNoteGenerated": "no",
            "npsGroup": "Passive",
            "npsScore": 7,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiwBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiwBgAAAAAAAA==/",
            "_etag": "\"05017daa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "044800fee428746440220a24be9c8aa769d86a676bf0dad645e2df20d006649c",
            "conversationId": "044800fee428746440220a24be9c8aa769d86a676bf0dad645e2df20d006649c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "Contact me within two business days, 15555555555, true",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-02-15T02:02:41.432Z",
            "endTime": "2022-02-15T02:10:38.125Z",
            "timeSpent": 477,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 3,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIixBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIixBgAAAAAAAA==/",
            "_etag": "\"05017eaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "4b4a9da6945dc676de135b821c6cf5dba33f386e0fafa361d73fe3451deb9dcf",
            "conversationId": "4b4a9da6945dc676de135b821c6cf5dba33f386e0fafa361d73fe3451deb9dcf",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will go to the emergency room",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-02-15T02:17:57.265Z",
            "endTime": "2022-02-15T02:19:17Z",
            "timeSpent": 80,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 1,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiyBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiyBgAAAAAAAA==/",
            "_etag": "\"05017faa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "b37ca5d697b1c2a36b19e7840be1278188e4f27a759ed6cba581afea2cc139f2",
            "conversationId": "b37ca5d697b1c2a36b19e7840be1278188e4f27a759ed6cba581afea2cc139f2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T02:17:57.628Z",
            "endTime": "2022-02-15T02:17:57.628Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIizBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIizBgAAAAAAAA==/",
            "_etag": "\"050180aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "1acf51fcd9ae32e271f4b42334479d6e7983eb8932873dc2c2286e9ed62a42b5",
            "conversationId": "1acf51fcd9ae32e271f4b42334479d6e7983eb8932873dc2c2286e9ed62a42b5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Fever",
                "Periodic fever",
                "Nausea"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will call 9-1-1",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-02-15T02:48:07.815Z",
            "endTime": "2022-02-15T02:51:56.997Z",
            "timeSpent": 229,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi0BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi0BgAAAAAAAA==/",
            "_etag": "\"050181aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "1411228cf0b31682eb7eb9be5aa1d5a2f501eb5275c3278c120a220cb5ababd7",
            "conversationId": "1411228cf0b31682eb7eb9be5aa1d5a2f501eb5275c3278c120a220cb5ababd7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Fever",
                "Periodic fever",
                "Nausea"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will call 9-1-1",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-02-15T02:55:01.716Z",
            "endTime": "2022-02-15T02:56:48.851Z",
            "timeSpent": 107,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 5,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi1BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi1BgAAAAAAAA==/",
            "_etag": "\"050182aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "aba7214509906bd69c58b4368d873e4a439b369dced1d587b21cb2e7915b1c9a",
            "conversationId": "aba7214509906bd69c58b4368d873e4a439b369dced1d587b21cb2e7915b1c9a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T03:11:05.796Z",
            "endTime": "2022-02-15T03:12:09.064Z",
            "timeSpent": 63,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi2BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi2BgAAAAAAAA==/",
            "_etag": "\"050183aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "daccb8f5e563618067aef9a95ee57d52874ac4963e163546733c3d2693ae26a3",
            "conversationId": "daccb8f5e563618067aef9a95ee57d52874ac4963e163546733c3d2693ae26a3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache",
                "Vomiting for more than a week"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will call 9-1-1",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-02-15T03:00:42.161Z",
            "endTime": "2022-02-15T03:05:47.153Z",
            "timeSpent": 305,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi3BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi3BgAAAAAAAA==/",
            "_etag": "\"050184aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
},
    
        {
            "id": "0b1f48580dff6a06feb66512d166cfe981894d92cd34c2fbac81156e5343b40f",
            "conversationId": "0b1f48580dff6a06feb66512d166cfe981894d92cd34c2fbac81156e5343b40f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-02-15T03:38:02.997Z",
            "endTime": "2022-02-15T03:45:26.419Z",
            "timeSpent": 443,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi4BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi4BgAAAAAAAA==/",
            "_etag": "\"050185aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "4514c1a69fdd7b0e075ee8deb456f2c79878b288a35c5d74cbc4b4797dca70c5",
            "conversationId": "4514c1a69fdd7b0e075ee8deb456f2c79878b288a35c5d74cbc4b4797dca70c5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [
                "History of tonsillectomy",
                "Smoking",
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-02-15T07:32:52.753Z",
            "endTime": "2022-02-15T07:34:32.02Z",
            "timeSpent": 99,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi5BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi5BgAAAAAAAA==/",
            "_etag": "\"050186aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "af40174677567635565a4a92b89ed11c6f2eee00e313b2cf3d304356b9b1f55e",
            "conversationId": "af40174677567635565a4a92b89ed11c6f2eee00e313b2cf3d304356b9b1f55e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T07:34:46.659Z",
            "endTime": "2022-02-15T07:34:46.659Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi6BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi6BgAAAAAAAA==/",
            "_etag": "\"050188aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "bbc4292bbd475b8ac2731bf6bcbb84115e3a0714bd5d341c5b467e8ecc0fc1d6",
            "conversationId": "bbc4292bbd475b8ac2731bf6bcbb84115e3a0714bd5d341c5b467e8ecc0fc1d6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T13:56:31.02Z",
            "endTime": "2022-02-15T13:56:31.02Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi7BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi7BgAAAAAAAA==/",
            "_etag": "\"050189aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "6ae31633cfad790e20180b74139dcf50afbf35380a07e48c72d24d3d2b32f09b",
            "conversationId": "6ae31633cfad790e20180b74139dcf50afbf35380a07e48c72d24d3d2b32f09b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sinus pain or pressure",
                "Stuffed nose"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will call 9-1-1",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-02-15T14:12:46.061Z",
            "endTime": "2022-02-15T14:28:43.166Z",
            "timeSpent": 957,
            "isNoteGenerated": "yes",
            "npsGroup": "Passive",
            "npsScore": 8,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi8BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi8BgAAAAAAAA==/",
            "_etag": "\"05018aaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "0548a3449346da0b32426d91fe1399ec0d88089cd406b01cdf0e5397ef89bb40",
            "conversationId": "0548a3449346da0b32426d91fe1399ec0d88089cd406b01cdf0e5397ef89bb40",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T14:34:30.245Z",
            "endTime": "2022-02-15T14:34:30.245Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi9BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi9BgAAAAAAAA==/",
            "_etag": "\"05018baa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "67351f08fbedaf3be46d2888b64a2d9df6ef31333bf464f2259f2a8c9d205b0f",
            "conversationId": "67351f08fbedaf3be46d2888b64a2d9df6ef31333bf464f2259f2a8c9d205b0f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Red eye"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T14:34:40.412Z",
            "endTime": "2022-02-15T14:35:34.467Z",
            "timeSpent": 54,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi+BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi+BgAAAAAAAA==/",
            "_etag": "\"05018caa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "c545e9ce998095c1058cfbe4fbdb444c56b26bd3bce3955b65e40ce7fdb8f670",
            "conversationId": "c545e9ce998095c1058cfbe4fbdb444c56b26bd3bce3955b65e40ce7fdb8f670",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T14:38:46.555Z",
            "endTime": "2022-02-15T14:38:46.555Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi-BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi-BgAAAAAAAA==/",
            "_etag": "\"05018daa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "1781f3ac91c5e366624b8a003b13be1a2fa8c1c0c1f8f5cd5bde16d0e0a98d31",
            "conversationId": "1781f3ac91c5e366624b8a003b13be1a2fa8c1c0c1f8f5cd5bde16d0e0a98d31",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T14:52:40.539Z",
            "endTime": "2022-02-15T14:56:12.287Z",
            "timeSpent": 212,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjABgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjABgAAAAAAAA==/",
            "_etag": "\"05018eaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "9f056d73ded6d7932afc9fa93d72eaaf4ac15998ea87ce44b3db5352045f3740",
            "conversationId": "9f056d73ded6d7932afc9fa93d72eaaf4ac15998ea87ce44b3db5352045f3740",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sinus pain or pressure",
                "Stuffed nose"
            ],
            "riskFactors": [
                "History of tonsillectomy",
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-02-15T14:56:45.327Z",
            "endTime": "2022-02-15T15:00:42.797Z",
            "timeSpent": 237,
            "isNoteGenerated": "no",
            "npsGroup": "Passive",
            "npsScore": 8,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjBBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjBBgAAAAAAAA==/",
            "_etag": "\"05018faa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "0e3052bb86177c209f80b8b09a6760374779ac32a2d3a5999ac47b6d907f5348",
            "conversationId": "0e3052bb86177c209f80b8b09a6760374779ac32a2d3a5999ac47b6d907f5348",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T15:18:24.004Z",
            "endTime": "2022-02-15T15:25:59.549Z",
            "timeSpent": 456,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjCBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjCBgAAAAAAAA==/",
            "_etag": "\"050190aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "accfca0e428458cd87bc0cc7338a66fcf596c88dbf3b57e0cbc3a82d380491f1",
            "conversationId": "accfca0e428458cd87bc0cc7338a66fcf596c88dbf3b57e0cbc3a82d380491f1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-02-15T15:27:59.774Z",
            "endTime": "2022-02-15T15:31:12.614Z",
            "timeSpent": 193,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjDBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjDBgAAAAAAAA==/",
            "_etag": "\"050191aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "5e47a6e57ce7567fe45e2af31bffdb4737e539cd330bbefbaf70d7c046b9d990",
            "conversationId": "5e47a6e57ce7567fe45e2af31bffdb4737e539cd330bbefbaf70d7c046b9d990",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-02-15T15:52:56.716Z",
            "endTime": "2022-02-15T15:54:24.127Z",
            "timeSpent": 87,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjEBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjEBgAAAAAAAA==/",
            "_etag": "\"050192aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "c9adef1d498fd652e273ad76acfbc4f7eb521ac5db4dce368168a7f0baac485d",
            "conversationId": "c9adef1d498fd652e273ad76acfbc4f7eb521ac5db4dce368168a7f0baac485d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Redness around eyes"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-02-15T15:09:57.262Z",
            "endTime": "2022-02-15T15:14:04.088Z",
            "timeSpent": 247,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjFBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjFBgAAAAAAAA==/",
            "_etag": "\"050193aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "e6900730e43f7bc370e963470b53891273c111b5826700761179e8181c65f62a",
            "conversationId": "e6900730e43f7bc370e963470b53891273c111b5826700761179e8181c65f62a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [
                "History of tonsillectomy",
                "Smoking",
                "History of anaphylactic reactions",
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-02-15T15:20:36.785Z",
            "endTime": "2022-02-15T15:24:32.008Z",
            "timeSpent": 235,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjGBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjGBgAAAAAAAA==/",
            "_etag": "\"050194aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "897c225f15398a4183c7f77141550d5be9aa301ae0d1078b89f4a6a7f6cfc396",
            "conversationId": "897c225f15398a4183c7f77141550d5be9aa301ae0d1078b89f4a6a7f6cfc396",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-02-15T15:01:54.604Z",
            "endTime": "2022-02-15T15:02:20.407Z",
            "timeSpent": 26,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjHBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjHBgAAAAAAAA==/",
            "_etag": "\"050195aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "0f0f5fe313faaba8ae91aabb3a52d518d049456a50b57182679cb0be1c4b5efb",
            "conversationId": "0f0f5fe313faaba8ae91aabb3a52d518d049456a50b57182679cb0be1c4b5efb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [
                "History of tonsillectomy",
                "Smoking",
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-02-15T15:35:45.223Z",
            "endTime": "2022-02-15T15:44:20.248Z",
            "timeSpent": 515,
            "isNoteGenerated": "no",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjIBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjIBgAAAAAAAA==/",
            "_etag": "\"050196aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "d7c8d1c9044ab2e660463a017b0503408efda9493be1dc5dd38f35a48b3701d9",
            "conversationId": "d7c8d1c9044ab2e660463a017b0503408efda9493be1dc5dd38f35a48b3701d9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Hair loss"
            ],
            "riskFactors": [
                "History of tonsillectomy",
                "Smoking",
                "History of anaphylactic reactions",
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-02-15T17:20:23.368Z",
            "endTime": "2022-02-15T17:46:01.217Z",
            "timeSpent": 1538,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 3,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjJBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjJBgAAAAAAAA==/",
            "_etag": "\"050197aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "be0dd02e1b13a513b52c43fcdfe28f5837709e741c5e8321bf7aba777c74b4b9",
            "conversationId": "be0dd02e1b13a513b52c43fcdfe28f5837709e741c5e8321bf7aba777c74b4b9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T17:20:21.307Z",
            "endTime": "2022-02-15T17:20:21.307Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjKBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjKBgAAAAAAAA==/",
            "_etag": "\"050198aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "2b82ce3c91246d43d2be3a1988910bb6165a8d75fc1c1725be02b43c9930ae2d",
            "conversationId": "2b82ce3c91246d43d2be3a1988910bb6165a8d75fc1c1725be02b43c9930ae2d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T17:55:37.496Z",
            "endTime": "2022-02-15T18:03:24.927Z",
            "timeSpent": 467,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjLBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjLBgAAAAAAAA==/",
            "_etag": "\"050199aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "9fbf818df3a1503d9b452be4220188322751c0777b66440d1a61afec5d71fc88",
            "conversationId": "9fbf818df3a1503d9b452be4220188322751c0777b66440d1a61afec5d71fc88",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will call 9-1-1",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-02-15T18:43:04.33Z",
            "endTime": "2022-02-15T18:46:09.278Z",
            "timeSpent": 185,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 4,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjMBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjMBgAAAAAAAA==/",
            "_etag": "\"05019aaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "74b2990d986b9234218eb8a67a1ad92a1b50349e72d62ab0ca0441d96c1b1eb4",
            "conversationId": "74b2990d986b9234218eb8a67a1ad92a1b50349e72d62ab0ca0441d96c1b1eb4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-02-15T18:11:41.688Z",
            "endTime": "2022-02-15T18:13:33.699Z",
            "timeSpent": 112,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjNBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjNBgAAAAAAAA==/",
            "_etag": "\"05019baa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "32424dbe8e5a40420d77ed3856d9b76fbf5fbb4bbfd62dadf2373d7a6e5091bf",
            "conversationId": "32424dbe8e5a40420d77ed3856d9b76fbf5fbb4bbfd62dadf2373d7a6e5091bf",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T18:04:49.804Z",
            "endTime": "2022-02-15T18:04:49.804Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjOBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjOBgAAAAAAAA==/",
            "_etag": "\"05019caa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "a4f5731215992741c0cdb73c9ed824657991e0e5bbbfa3971c029e029c068e05",
            "conversationId": "a4f5731215992741c0cdb73c9ed824657991e0e5bbbfa3971c029e029c068e05",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-02-15T18:14:17.787Z",
            "endTime": "2022-02-15T18:17:13.521Z",
            "timeSpent": 176,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 5,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjPBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjPBgAAAAAAAA==/",
            "_etag": "\"05019daa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "e14a0730892db7b33496536518a7f35579265b283c21696e734cebe3db55944b",
            "conversationId": "e14a0730892db7b33496536518a7f35579265b283c21696e734cebe3db55944b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will go to ReadyMED / urgent care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-02-15T18:30:06.736Z",
            "endTime": "2022-02-15T18:33:27.094Z",
            "timeSpent": 200,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjQBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjQBgAAAAAAAA==/",
            "_etag": "\"05019eaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "3bd2feab3b929f24476365d5c7453d4968e471a317bf7ccaf50208b41bc98531",
            "conversationId": "3bd2feab3b929f24476365d5c7453d4968e471a317bf7ccaf50208b41bc98531",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T18:03:33.568Z",
            "endTime": "2022-02-15T18:04:46.406Z",
            "timeSpent": 73,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjRBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjRBgAAAAAAAA==/",
            "_etag": "\"05019faa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "bc925fd0707205503a26845f6362094723a96b10b09b556950fce44b369ce29b",
            "conversationId": "bc925fd0707205503a26845f6362094723a96b10b09b556950fce44b369ce29b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T18:04:25.976Z",
            "endTime": "2022-02-15T18:04:25.976Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjSBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjSBgAAAAAAAA==/",
            "_etag": "\"0501a0aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "f02e3e337397bf2e87097807ee2e30b3c4f38eecd6b11f952725b358f064568c",
            "conversationId": "f02e3e337397bf2e87097807ee2e30b3c4f38eecd6b11f952725b358f064568c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T18:11:38.437Z",
            "endTime": "2022-02-15T18:11:38.437Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjTBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjTBgAAAAAAAA==/",
            "_etag": "\"0501a1aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "662e8a22ddbba1b45f2131dc18b19f2cebcba708dfede94cd04c938f3e186a7b",
            "conversationId": "662e8a22ddbba1b45f2131dc18b19f2cebcba708dfede94cd04c938f3e186a7b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T18:30:02.793Z",
            "endTime": "2022-02-15T18:30:02.793Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjUBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjUBgAAAAAAAA==/",
            "_etag": "\"0501a2aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "d9b8fd2694b4bfffeef158b8674741dd744cfa8a346f225e58668a0498bb93e0",
            "conversationId": "d9b8fd2694b4bfffeef158b8674741dd744cfa8a346f225e58668a0498bb93e0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Dizzy"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Call 9-1-1",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T19:19:14.816Z",
            "endTime": "2022-02-15T19:19:37.675Z",
            "timeSpent": 23,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjVBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjVBgAAAAAAAA==/",
            "_etag": "\"0501a3aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "01bcfbb4f429929ae9ee7ad7b65e95e4c01b5f61d01f43c4c5ee470a4995856f",
            "conversationId": "01bcfbb4f429929ae9ee7ad7b65e95e4c01b5f61d01f43c4c5ee470a4995856f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Neck pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T19:20:02.368Z",
            "endTime": "2022-02-15T19:22:46.108Z",
            "timeSpent": 164,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjWBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjWBgAAAAAAAA==/",
            "_etag": "\"0501a4aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "01de2509b6de47026a54ec741ebb12b90b5f98bc01f0c3f3259513b2cd8fc9ac",
            "conversationId": "01de2509b6de47026a54ec741ebb12b90b5f98bc01f0c3f3259513b2cd8fc9ac",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Neck pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T19:31:35.254Z",
            "endTime": "2022-02-15T19:33:22.949Z",
            "timeSpent": 108,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjXBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjXBgAAAAAAAA==/",
            "_etag": "\"0501a5aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "88dbdc8994e4f2dbffc9191b7b4358d41b574d4a7df4d1f211e01a2c9f245416",
            "conversationId": "88dbdc8994e4f2dbffc9191b7b4358d41b574d4a7df4d1f211e01a2c9f245416",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Neck pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will call 9-1-1",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T19:41:13.96Z",
            "endTime": "2022-02-15T19:52:01.553Z",
            "timeSpent": 648,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjYBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjYBgAAAAAAAA==/",
            "_etag": "\"0501a6aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "8717f495a40f9fbf4c6c41c2829123f2d0d4aff4ade8fb5cee41d90f27926dbb",
            "conversationId": "8717f495a40f9fbf4c6c41c2829123f2d0d4aff4ade8fb5cee41d90f27926dbb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Neck pain"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will go to ReadyMED / urgent care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-02-15T19:42:05.067Z",
            "endTime": "2022-02-15T19:46:40.504Z",
            "timeSpent": 275,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjZBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjZBgAAAAAAAA==/",
            "_etag": "\"0501a7aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "570530d5c4a9550f7c9628fe2aa4086474f0a1b1c02a8c325927c0ad41ca92e0",
            "conversationId": "570530d5c4a9550f7c9628fe2aa4086474f0a1b1c02a8c325927c0ad41ca92e0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Neck pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T19:58:24.046Z",
            "endTime": "2022-02-15T20:02:40.305Z",
            "timeSpent": 256,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjaBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjaBgAAAAAAAA==/",
            "_etag": "\"0501a8aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "9de9e3be205b64edf24ce2b21cad062f2aa3cfac3e3033f51f46bd9f64fe075a",
            "conversationId": "9de9e3be205b64edf24ce2b21cad062f2aa3cfac3e3033f51f46bd9f64fe075a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Neck pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T19:34:14.097Z",
            "endTime": "2022-02-15T19:35:37.661Z",
            "timeSpent": 84,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjbBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjbBgAAAAAAAA==/",
            "_etag": "\"0501a9aa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "ae3a833f479183df7cb1c77dd57d4ef9addc37428b72ce7df0115f3f233df361",
            "conversationId": "ae3a833f479183df7cb1c77dd57d4ef9addc37428b72ce7df0115f3f233df361",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T20:45:56.747Z",
            "endTime": "2022-02-15T20:48:12.117Z",
            "timeSpent": 135,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjcBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjcBgAAAAAAAA==/",
            "_etag": "\"0501aaaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "96782176e73c0b8811bf6c7b7cdbd7c0767a1040754350e89e075a0d46e6270a",
            "conversationId": "96782176e73c0b8811bf6c7b7cdbd7c0767a1040754350e89e075a0d46e6270a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-02-15T20:52:59.943Z",
            "endTime": "2022-02-15T20:58:52.262Z",
            "timeSpent": 352,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjdBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjdBgAAAAAAAA==/",
            "_etag": "\"0501abaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "f4486d987ae09905a9e0aa74b33be35239b8b658189f063ef26ef11a4deedc52",
            "conversationId": "f4486d987ae09905a9e0aa74b33be35239b8b658189f063ef26ef11a4deedc52",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T20:51:40.346Z",
            "endTime": "2022-02-15T20:51:40.346Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjeBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjeBgAAAAAAAA==/",
            "_etag": "\"0501acaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "3ff0830ecb7e66788568c59ac70b5f1066b38e854e3c1eae64141a2a329baf69",
            "conversationId": "3ff0830ecb7e66788568c59ac70b5f1066b38e854e3c1eae64141a2a329baf69",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-02-15T20:53:29.493Z",
            "endTime": "2022-02-15T20:58:34.142Z",
            "timeSpent": 305,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjfBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjfBgAAAAAAAA==/",
            "_etag": "\"0501adaa-0000-0100-0000-6242e45d0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551005
        },
        {
            "id": "066139fbdf0b2ceedfa33313d46d130e41d4bb9ba8506f687e61be230d96fbdf",
            "conversationId": "066139fbdf0b2ceedfa33313d46d130e41d4bb9ba8506f687e61be230d96fbdf",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Neck pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T20:30:31.77Z",
            "endTime": "2022-02-15T20:32:40.862Z",
            "timeSpent": 129,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjgBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjgBgAAAAAAAA==/",
            "_etag": "\"0501b0aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "2a5043b910d41809c3a3b04eb9e1756b20ad523a83c7690d93a035575093a20d",
            "conversationId": "2a5043b910d41809c3a3b04eb9e1756b20ad523a83c7690d93a035575093a20d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Neck pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T20:42:19.765Z",
            "endTime": "2022-02-15T20:44:40.054Z",
            "timeSpent": 140,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjhBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjhBgAAAAAAAA==/",
            "_etag": "\"0501b1aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "f94ce5ab67f968867dbcecc499bc86a641474b1857dbd702bd6b49bd2681aa4a",
            "conversationId": "f94ce5ab67f968867dbcecc499bc86a641474b1857dbd702bd6b49bd2681aa4a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T20:30:30.025Z",
            "endTime": "2022-02-15T20:30:30.025Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjiBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjiBgAAAAAAAA==/",
            "_etag": "\"0501b2aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "c499bc9cd465b15f847b84fd38721f56268c4eeb22d4c8c1dcee7f47aeb3d884",
            "conversationId": "c499bc9cd465b15f847b84fd38721f56268c4eeb22d4c8c1dcee7f47aeb3d884",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-02-15T21:02:15.753Z",
            "endTime": "2022-02-15T21:06:50.181Z",
            "timeSpent": 274,
            "isNoteGenerated": "no",
            "npsGroup": "Passive",
            "npsScore": 7,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjjBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjjBgAAAAAAAA==/",
            "_etag": "\"0501b3aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "2513530809238f28b73713fc59960d09fd1bb8f220c6aa244093f6e23517c7b4",
            "conversationId": "2513530809238f28b73713fc59960d09fd1bb8f220c6aa244093f6e23517c7b4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-02-15T21:02:54.242Z",
            "endTime": "2022-02-15T21:06:54.749Z",
            "timeSpent": 241,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 1,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjkBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjkBgAAAAAAAA==/",
            "_etag": "\"0501b4aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "879d6328ebeb707ea82b4386cd12057f105dcd2abe91332b34138e76cc137cae",
            "conversationId": "879d6328ebeb707ea82b4386cd12057f105dcd2abe91332b34138e76cc137cae",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T21:50:30.041Z",
            "endTime": "2022-02-15T21:50:30.041Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjlBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjlBgAAAAAAAA==/",
            "_etag": "\"0501b5aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "6b0be06e655acaf095f661bbb7ff38b1287e26fef59cb1cf2ec61ff7783fb65a",
            "conversationId": "6b0be06e655acaf095f661bbb7ff38b1287e26fef59cb1cf2ec61ff7783fb65a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T21:50:59.979Z",
            "endTime": "2022-02-15T21:51:05.877Z",
            "timeSpent": 6,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjmBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjmBgAAAAAAAA==/",
            "_etag": "\"0501b6aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "235456cadde8ef1b2d9f8dabd3bf8b962836273710e86feec9f5b8e9a9472fe6",
            "conversationId": "235456cadde8ef1b2d9f8dabd3bf8b962836273710e86feec9f5b8e9a9472fe6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-15T22:28:31.001Z",
            "endTime": "2022-02-15T22:28:31.001Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjnBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjnBgAAAAAAAA==/",
            "_etag": "\"0501b7aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "7fe5e298ef47d8a548929f891909b3415709e4d04c550ed08e96c9fb6ab5296f",
            "conversationId": "7fe5e298ef47d8a548929f891909b3415709e4d04c550ed08e96c9fb6ab5296f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-16T01:11:43.769Z",
            "endTime": "2022-02-16T01:11:43.769Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjoBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjoBgAAAAAAAA==/",
            "_etag": "\"0501b8aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "2569bd93562fb09d20be247b187b072fdeb715dc0f4842b08a46e92af8ec560e",
            "conversationId": "2569bd93562fb09d20be247b187b072fdeb715dc0f4842b08a46e92af8ec560e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-02-16T04:49:03.804Z",
            "endTime": "2022-02-16T04:49:18.877Z",
            "timeSpent": 15,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjpBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjpBgAAAAAAAA==/",
            "_etag": "\"0501b9aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "3f1f7078a6adc881b7081bfc212d80e465fec831906b8e57d947bff248b4eb36",
            "conversationId": "3f1f7078a6adc881b7081bfc212d80e465fec831906b8e57d947bff248b4eb36",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-02-16T04:36:23.017Z",
            "endTime": "2022-02-16T04:48:07.165Z",
            "timeSpent": 704,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjqBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjqBgAAAAAAAA==/",
            "_etag": "\"0501baaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "51ec00a0e634da2f3a6cd2c620b0726b2d7a5a36951f0d42a157024b6ad4f134",
            "conversationId": "51ec00a0e634da2f3a6cd2c620b0726b2d7a5a36951f0d42a157024b6ad4f134",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-02-16T13:59:54.51Z",
            "endTime": "2022-02-16T14:09:06.363Z",
            "timeSpent": 552,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjrBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjrBgAAAAAAAA==/",
            "_etag": "\"0501bbaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "836fbd74ce7006c8067b9952aa0a15c106149d6279bdb485bd6b4f49f093f39c",
            "conversationId": "836fbd74ce7006c8067b9952aa0a15c106149d6279bdb485bd6b4f49f093f39c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-16T14:35:18.012Z",
            "endTime": "2022-02-16T14:35:18.012Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjsBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjsBgAAAAAAAA==/",
            "_etag": "\"0501bcaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "6baf035bf3b610320d31168ee122cfbfcbcf8d065b783c751302601b0f290217",
            "conversationId": "6baf035bf3b610320d31168ee122cfbfcbcf8d065b783c751302601b0f290217",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Neck pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-16T15:41:20.115Z",
            "endTime": "2022-02-16T15:45:10.729Z",
            "timeSpent": 231,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjtBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjtBgAAAAAAAA==/",
            "_etag": "\"0501bdaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "13788d8fdde6e244e73edccbfdef839777c9955685c1013268c86a87f0bd980e",
            "conversationId": "13788d8fdde6e244e73edccbfdef839777c9955685c1013268c86a87f0bd980e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Neck pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-16T15:49:15.24Z",
            "endTime": "2022-02-16T15:50:46.763Z",
            "timeSpent": 92,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjuBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjuBgAAAAAAAA==/",
            "_etag": "\"0501beaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "7b98cfe34cb946ba2eba7ecdc06840b341ee0f70183e7ff3633805de18b33691",
            "conversationId": "7b98cfe34cb946ba2eba7ecdc06840b341ee0f70183e7ff3633805de18b33691",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Neck pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will call 9-1-1",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-02-16T15:46:23.813Z",
            "endTime": "2022-02-16T15:49:02.717Z",
            "timeSpent": 159,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 5,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjvBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjvBgAAAAAAAA==/",
            "_etag": "\"0501bfaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "304100231606c512d982e8e53b2a2143faf4a19b375a8bb32fa16164bec4ff5b",
            "conversationId": "304100231606c512d982e8e53b2a2143faf4a19b375a8bb32fa16164bec4ff5b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the emergency room",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-02-16T15:11:33.922Z",
            "endTime": "2022-02-16T15:14:19.894Z",
            "timeSpent": 166,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjwBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjwBgAAAAAAAA==/",
            "_etag": "\"0501c0aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "c25fe3ed04475510a4ed3f179562eb8ded6d084e305c703b901e925be63fea60",
            "conversationId": "c25fe3ed04475510a4ed3f179562eb8ded6d084e305c703b901e925be63fea60",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-02-16T15:15:46.262Z",
            "endTime": "2022-02-16T15:17:22.827Z",
            "timeSpent": 97,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjxBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjxBgAAAAAAAA==/",
            "_etag": "\"0501c1aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "222b321aadd9c79631ba11efc45b4bb6a7d24f3fb9ac9095b3819895aa9a3bea",
            "conversationId": "222b321aadd9c79631ba11efc45b4bb6a7d24f3fb9ac9095b3819895aa9a3bea",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Hair loss"
            ],
            "riskFactors": [
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Call 9-1-1",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-02-16T15:31:45.898Z",
            "endTime": "2022-02-16T15:40:25.837Z",
            "timeSpent": 520,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 6,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjyBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjyBgAAAAAAAA==/",
            "_etag": "\"0501c2aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "00a0f27cca26f217ce6c5631301aad0ded47c34836d7f40c93db148926a868af",
            "conversationId": "00a0f27cca26f217ce6c5631301aad0ded47c34836d7f40c93db148926a868af",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-16T15:31:32.21Z",
            "endTime": "2022-02-16T15:31:32.21Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjzBgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjzBgAAAAAAAA==/",
            "_etag": "\"0501c3aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "dde6c87498172fb664d6f7a5327803e6d730b75054c908a41d9984a81d42922d",
            "conversationId": "dde6c87498172fb664d6f7a5327803e6d730b75054c908a41d9984a81d42922d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Lightheaded"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-02-17T13:21:59.861Z",
            "endTime": "2022-02-17T13:29:19.071Z",
            "timeSpent": 439,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj0BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj0BgAAAAAAAA==/",
            "_etag": "\"0501c4aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "f8c09eafe1ea1ccf9707ae617dd130df4d69583642dbc23864265b734bf9799a",
            "conversationId": "f8c09eafe1ea1ccf9707ae617dd130df4d69583642dbc23864265b734bf9799a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-17T13:52:29.791Z",
            "endTime": "2022-02-17T14:00:01.195Z",
            "timeSpent": 451,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj1BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj1BgAAAAAAAA==/",
            "_etag": "\"0501c5aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "6ba4a99d20e3d27a5b7b8ee38b5955562e8786ec42ab844c1a7ade6cb78b74f8",
            "conversationId": "6ba4a99d20e3d27a5b7b8ee38b5955562e8786ec42ab844c1a7ade6cb78b74f8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-17T13:21:26.899Z",
            "endTime": "2022-02-17T13:21:26.899Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj2BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj2BgAAAAAAAA==/",
            "_etag": "\"0501c6aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "a1e77b3448f5a4b5e60675afb619503972fba4451efb5d6c8acfb632f6f06574",
            "conversationId": "a1e77b3448f5a4b5e60675afb619503972fba4451efb5d6c8acfb632f6f06574",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Lightheaded",
                "Pale leg",
                "Pain in upper limb",
                "Leg pain",
                "Pain in lower limb",
                " thigh"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": "I will call 9-1-1",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-17T14:48:39.635Z",
            "endTime": "2022-02-17T14:56:26.387Z",
            "timeSpent": 467,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 6,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj3BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj3BgAAAAAAAA==/",
            "_etag": "\"0501c7aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "c44a702b8d7e44f942da3205af6a3d82be7d04ee17cd7281e990dacfbdf2c866",
            "conversationId": "c44a702b8d7e44f942da3205af6a3d82be7d04ee17cd7281e990dacfbdf2c866",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Leg pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-17T14:32:22.203Z",
            "endTime": "2022-02-17T14:36:33.277Z",
            "timeSpent": 251,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj4BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj4BgAAAAAAAA==/",
            "_etag": "\"0501c8aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "127232edf58e5bc6c7f561d15d2b05831644d1df8a56a5f93bd2564e70e50e91",
            "conversationId": "127232edf58e5bc6c7f561d15d2b05831644d1df8a56a5f93bd2564e70e50e91",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-17T14:32:12.405Z",
            "endTime": "2022-02-17T14:32:12.405Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj5BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj5BgAAAAAAAA==/",
            "_etag": "\"0501c9aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "e65413a1fce40a35fd037ca3567cbde0116c36a3871de25ffb7655231455fc7c",
            "conversationId": "e65413a1fce40a35fd037ca3567cbde0116c36a3871de25ffb7655231455fc7c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Lightheaded",
                "Pale leg",
                "Pain in upper limb",
                "Leg pain",
                "Pain in lower limb",
                " thigh"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-17T14:41:22.347Z",
            "endTime": "2022-02-17T14:45:15.691Z",
            "timeSpent": 233,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj6BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj6BgAAAAAAAA==/",
            "_etag": "\"0501caaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "86599ae3d876f65d7d33e61e433846152c9874190905d09449c842e4b6ccf8d7",
            "conversationId": "86599ae3d876f65d7d33e61e433846152c9874190905d09449c842e4b6ccf8d7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the emergency room",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-02-17T14:56:46.027Z",
            "endTime": "2022-02-17T15:14:38.633Z",
            "timeSpent": 1073,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj7BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj7BgAAAAAAAA==/",
            "_etag": "\"0501cbaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "4994a0d0f5e0fa71b0e536dc6a3264a2ca6cc0b9bbdd093998066f559350d8ad",
            "conversationId": "4994a0d0f5e0fa71b0e536dc6a3264a2ca6cc0b9bbdd093998066f559350d8ad",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-02-17T15:15:26.015Z",
            "endTime": "2022-02-17T15:18:05.455Z",
            "timeSpent": 159,
            "isNoteGenerated": "no",
            "npsGroup": "Detractor",
            "npsScore": 6,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj8BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj8BgAAAAAAAA==/",
            "_etag": "\"0501ccaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "2713c1d0702bde7205c077c0f724342bb03077d572a816c5c3883c577ffcc204",
            "conversationId": "2713c1d0702bde7205c077c0f724342bb03077d572a816c5c3883c577ffcc204",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-17T15:13:44.535Z",
            "endTime": "2022-02-17T15:13:44.535Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj9BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj9BgAAAAAAAA==/",
            "_etag": "\"0501cdaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "f0f03d03fcc4b4f7f5504fc3291b8e8aed38d7464c74868fb0418bb5f102e157",
            "conversationId": "f0f03d03fcc4b4f7f5504fc3291b8e8aed38d7464c74868fb0418bb5f102e157",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [
                "Smoking",
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-02-17T15:24:53.871Z",
            "endTime": "2022-02-17T15:27:14.213Z",
            "timeSpent": 140,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj+BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj+BgAAAAAAAA==/",
            "_etag": "\"0501ceaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "ca0c79f3fab6ae4b4aa3da8934d737dac20ac65105815a39b73fa22d7d590924",
            "conversationId": "ca0c79f3fab6ae4b4aa3da8934d737dac20ac65105815a39b73fa22d7d590924",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-02-17T16:49:39.598Z",
            "endTime": "2022-02-17T16:51:26.916Z",
            "timeSpent": 107,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 6,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj-BgAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj-BgAAAAAAAA==/",
            "_etag": "\"0501cfaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "a72e655386d2a7d888ffc20b57392299e7acbb7777408a803ec6d3028532131c",
            "conversationId": "a72e655386d2a7d888ffc20b57392299e7acbb7777408a803ec6d3028532131c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [
                "History of tonsillectomy",
                "Smoking",
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-02-17T18:36:43.339Z",
            "endTime": "2022-02-17T18:39:14.322Z",
            "timeSpent": 151,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgABwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgABwAAAAAAAA==/",
            "_etag": "\"0501d0aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "03572c7e9e94a8bf9e51b9bafa86f6a256c5f270165d6bf9ef0ad864d14ce129",
            "conversationId": "03572c7e9e94a8bf9e51b9bafa86f6a256c5f270165d6bf9ef0ad864d14ce129",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-02-17T18:44:14.35Z",
            "endTime": "2022-02-17T18:46:03.128Z",
            "timeSpent": 109,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgBBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgBBwAAAAAAAA==/",
            "_etag": "\"0501d1aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "4d721b25105a41563f1fc4750f56a56aea4e9552d6152963d0dcaba28f8dc4f8",
            "conversationId": "4d721b25105a41563f1fc4750f56a56aea4e9552d6152963d0dcaba28f8dc4f8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Hair loss"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-02-17T18:21:04.862Z",
            "endTime": "2022-02-17T18:23:27.465Z",
            "timeSpent": 143,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgCBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgCBwAAAAAAAA==/",
            "_etag": "\"0501d2aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "3775300d10f1ceddc3177c23bd522ba020939eceb6329d290c9361907132c9c3",
            "conversationId": "3775300d10f1ceddc3177c23bd522ba020939eceb6329d290c9361907132c9c3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-17T18:36:43.037Z",
            "endTime": "2022-02-17T18:36:43.037Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgDBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgDBwAAAAAAAA==/",
            "_etag": "\"0501d3aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "dc16e2586476fbc7452d52a278b867f4de75af9577e51d0d8e5dd3cf49f13c79",
            "conversationId": "dc16e2586476fbc7452d52a278b867f4de75af9577e51d0d8e5dd3cf49f13c79",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Hair loss"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-17T18:16:32.661Z",
            "endTime": "2022-02-17T18:18:24.039Z",
            "timeSpent": 111,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgEBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgEBwAAAAAAAA==/",
            "_etag": "\"0501d4aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "349c9996c7ae6702466ff219eb7f3d90e35bcc528814594fd82ce18164ea7a4c",
            "conversationId": "349c9996c7ae6702466ff219eb7f3d90e35bcc528814594fd82ce18164ea7a4c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-17T18:49:12.772Z",
            "endTime": "2022-02-17T18:49:28.356Z",
            "timeSpent": 16,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgFBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgFBwAAAAAAAA==/",
            "_etag": "\"0501d5aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "46cebd785563e7b1e523b5b973cd3dd05ca0f104c33d7c0963931c3716e626a7",
            "conversationId": "46cebd785563e7b1e523b5b973cd3dd05ca0f104c33d7c0963931c3716e626a7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-02-17T18:50:08.505Z",
            "endTime": "2022-02-17T18:59:45.032Z",
            "timeSpent": 577,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgGBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgGBwAAAAAAAA==/",
            "_etag": "\"0501d6aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "cbfd113f5ddd92fbbddaf9371480e0e8e1195c10f4d53a314b4d4fb616696e93",
            "conversationId": "cbfd113f5ddd92fbbddaf9371480e0e8e1195c10f4d53a314b4d4fb616696e93",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-17T18:47:33.312Z",
            "endTime": "2022-02-17T18:47:33.312Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgHBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgHBwAAAAAAAA==/",
            "_etag": "\"0501d7aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "3404e282c3ec9c935a8bff896eb74e5c6dcdbecdae6fcd06701ae1634db503ac",
            "conversationId": "3404e282c3ec9c935a8bff896eb74e5c6dcdbecdae6fcd06701ae1634db503ac",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Leg pain"
            ],
            "riskFactors": [
                "Smoking",
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-02-17T19:22:09.11Z",
            "endTime": "2022-02-17T19:24:22.327Z",
            "timeSpent": 133,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgIBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgIBwAAAAAAAA==/",
            "_etag": "\"0501d8aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "75989e8264ccfaf864b2af0b8de089e56a1adad1cff14c1f96aab595feb1a8c5",
            "conversationId": "75989e8264ccfaf864b2af0b8de089e56a1adad1cff14c1f96aab595feb1a8c5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-17T19:22:09.68Z",
            "endTime": "2022-02-17T19:22:09.68Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgJBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgJBwAAAAAAAA==/",
            "_etag": "\"0501d9aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "1d796c501d236999d0e3abd450f1c7626bdddb85cdc9700b451f3e25c52cdab8",
            "conversationId": "1d796c501d236999d0e3abd450f1c7626bdddb85cdc9700b451f3e25c52cdab8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-02-17T20:39:52.921Z",
            "endTime": "2022-02-17T20:40:08.353Z",
            "timeSpent": 15,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgKBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgKBwAAAAAAAA==/",
            "_etag": "\"0501daaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "cf5d4c5e3b11d11445ad8f1e46b045292c7114321a0faa4a60c5b34418e1ae4c",
            "conversationId": "cf5d4c5e3b11d11445ad8f1e46b045292c7114321a0faa4a60c5b34418e1ae4c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-02-17T20:10:36.671Z",
            "endTime": "2022-02-17T20:24:41.58Z",
            "timeSpent": 845,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgLBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgLBwAAAAAAAA==/",
            "_etag": "\"0501dbaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "558883ac898062bf8c582b9bc503e96b12262998beb9b96d0d28b63f5f6d278d",
            "conversationId": "558883ac898062bf8c582b9bc503e96b12262998beb9b96d0d28b63f5f6d278d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-17T20:45:59.566Z",
            "endTime": "2022-02-17T20:49:08.435Z",
            "timeSpent": 189,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgMBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgMBwAAAAAAAA==/",
            "_etag": "\"0501dcaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "f626da607fce031d8234512abd6e6e564c03331d40abb5c38a3d0a40dad983e5",
            "conversationId": "f626da607fce031d8234512abd6e6e564c03331d40abb5c38a3d0a40dad983e5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will call 9-1-1",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-02-17T20:30:00.882Z",
            "endTime": "2022-02-17T20:38:52.521Z",
            "timeSpent": 532,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgNBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgNBwAAAAAAAA==/",
            "_etag": "\"0501ddaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "3a17536849dc9802ec3fdce87b94e3ecb117040f58d1a8d4447783b6c45e3b88",
            "conversationId": "3a17536849dc9802ec3fdce87b94e3ecb117040f58d1a8d4447783b6c45e3b88",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-17T20:46:09.29Z",
            "endTime": "2022-02-17T20:46:09.29Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgOBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgOBwAAAAAAAA==/",
            "_etag": "\"0501deaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "9daef4e0c82b28f61bc9fe91e0f6c0eeeb1f4609fd8f99bcd5eeca35929737a7",
            "conversationId": "9daef4e0c82b28f61bc9fe91e0f6c0eeeb1f4609fd8f99bcd5eeca35929737a7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-17T20:46:07.705Z",
            "endTime": "2022-02-17T20:46:07.705Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgPBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgPBwAAAAAAAA==/",
            "_etag": "\"0501dfaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "98df4f9f4a690f57b61a30f352c0b94a9353df77d4df569b02700aad9f2136b5",
            "conversationId": "98df4f9f4a690f57b61a30f352c0b94a9353df77d4df569b02700aad9f2136b5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-21T19:54:38.117Z",
            "endTime": "2022-02-21T19:54:38.117Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgQBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgQBwAAAAAAAA==/",
            "_etag": "\"0501e0aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "e88678b1fb6ebe6d344abaeea17c0f1f62015f54657f34f5f78d79289de17765",
            "conversationId": "e88678b1fb6ebe6d344abaeea17c0f1f62015f54657f34f5f78d79289de17765",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-21T19:41:32.464Z",
            "endTime": "2022-02-21T19:41:32.464Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgRBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgRBwAAAAAAAA==/",
            "_etag": "\"0501e1aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "16efeec78775f1d4d342969f8b8f1e504fd48d8c5f262dcce7431783777a7196",
            "conversationId": "16efeec78775f1d4d342969f8b8f1e504fd48d8c5f262dcce7431783777a7196",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-21T19:40:18.678Z",
            "endTime": "2022-02-21T19:40:18.678Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgSBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgSBwAAAAAAAA==/",
            "_etag": "\"0501e2aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "152252b9fcc3b5d5955cbbf3aa0f122adf34f44696a179254aaf9ebe6deecd39",
            "conversationId": "152252b9fcc3b5d5955cbbf3aa0f122adf34f44696a179254aaf9ebe6deecd39",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-23T14:35:41.463Z",
            "endTime": "2022-02-23T14:35:41.463Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgTBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgTBwAAAAAAAA==/",
            "_etag": "\"0501e3aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "85e8903d2de7a43a08e1e58f0f942c0c704d1a22dede7717cbe1ae989ee8118d",
            "conversationId": "85e8903d2de7a43a08e1e58f0f942c0c704d1a22dede7717cbe1ae989ee8118d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-23T14:36:32.717Z",
            "endTime": "2022-02-23T14:36:32.717Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgUBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgUBwAAAAAAAA==/",
            "_etag": "\"0501e4aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "e30529c1ee8f79cd6f139e71ce4c404384408b6610e922b783db5eb461ba48f5",
            "conversationId": "e30529c1ee8f79cd6f139e71ce4c404384408b6610e922b783db5eb461ba48f5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-23T15:35:10.69Z",
            "endTime": "2022-02-23T15:37:52.772Z",
            "timeSpent": 162,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgVBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgVBwAAAAAAAA==/",
            "_etag": "\"0501e5aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "ab247a8de15f796f3268ab97479b6d12873983896a46d57d33ec2701cc6e7864",
            "conversationId": "ab247a8de15f796f3268ab97479b6d12873983896a46d57d33ec2701cc6e7864",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-23T17:52:14.905Z",
            "endTime": "2022-02-23T17:52:14.905Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgWBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgWBwAAAAAAAA==/",
            "_etag": "\"0501e6aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "320026c4499bd1a8d8f501237d7fd5e8a56d3c6a8fc93b3bfa157364f6c9826c",
            "conversationId": "320026c4499bd1a8d8f501237d7fd5e8a56d3c6a8fc93b3bfa157364f6c9826c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-24T19:48:05.316Z",
            "endTime": "2022-02-24T19:48:05.316Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgXBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgXBwAAAAAAAA==/",
            "_etag": "\"0501e7aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "6800f3b809d99d96dd0c0aa463bb82f387a17e9d6bb6e9c4b967c33f7c1f8fe6",
            "conversationId": "6800f3b809d99d96dd0c0aa463bb82f387a17e9d6bb6e9c4b967c33f7c1f8fe6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-24T19:48:59.715Z",
            "endTime": "2022-02-24T19:48:59.715Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgYBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgYBwAAAAAAAA==/",
            "_etag": "\"0501e8aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "3318105e455b355b61d09b5ad950ac0271fbec51ec9007c9282db3251965782d",
            "conversationId": "3318105e455b355b61d09b5ad950ac0271fbec51ec9007c9282db3251965782d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-24T19:49:13.181Z",
            "endTime": "2022-02-24T19:49:13.181Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgZBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgZBwAAAAAAAA==/",
            "_etag": "\"0501e9aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "edda1ec75d21b1403b69e63434378a0d7a795f2d122aacff68b4976857a19283",
            "conversationId": "edda1ec75d21b1403b69e63434378a0d7a795f2d122aacff68b4976857a19283",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Ankle pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-24T21:24:29.178Z",
            "endTime": "2022-02-24T21:25:41.797Z",
            "timeSpent": 73,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgaBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgaBwAAAAAAAA==/",
            "_etag": "\"0501eaaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "25f6eacef63b05137a1f48ca4590b81f97b11300f6099e4b17f8d4acb6cefb6d",
            "conversationId": "25f6eacef63b05137a1f48ca4590b81f97b11300f6099e4b17f8d4acb6cefb6d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Ankle pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-24T21:22:59.03Z",
            "endTime": "2022-02-24T21:24:10.022Z",
            "timeSpent": 71,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgbBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgbBwAAAAAAAA==/",
            "_etag": "\"0501ebaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
},
    
        {
            "id": "acfb348dbf99782741dfa89b44db080ce60c0beefb0f66b1c20feb6f3f00554c",
            "conversationId": "acfb348dbf99782741dfa89b44db080ce60c0beefb0f66b1c20feb6f3f00554c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-02-28T19:14:39.307Z",
            "endTime": "2022-02-28T19:14:39.307Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgcBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgcBwAAAAAAAA==/",
            "_etag": "\"0501ecaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "3eb408ff5e2eb8ee0dd4df8bc1d771729ef64220b9e4710de85553e9cf4f9db4",
            "conversationId": "3eb408ff5e2eb8ee0dd4df8bc1d771729ef64220b9e4710de85553e9cf4f9db4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": "I will go to ReadyMED / urgent care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-03-01T14:43:41.034Z",
            "endTime": "2022-03-01T14:52:55.202Z",
            "timeSpent": 554,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgdBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgdBwAAAAAAAA==/",
            "_etag": "\"0501edaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "7595a3202763ae906a94c9df528e4921aa526a48e4226444c42eaf32e9054abd",
            "conversationId": "7595a3202763ae906a94c9df528e4921aa526a48e4226444c42eaf32e9054abd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-03-01T21:36:54.853Z",
            "endTime": "2022-03-01T21:36:54.853Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgeBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgeBwAAAAAAAA==/",
            "_etag": "\"0501eeaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "5ce5ea2a3ae5ea4a8c33ebd81156401e7598da7ffbcc7e8b1ab72007591ae89e",
            "conversationId": "5ce5ea2a3ae5ea4a8c33ebd81156401e7598da7ffbcc7e8b1ab72007591ae89e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-03-02T13:55:14.079Z",
            "endTime": "2022-03-02T13:55:21.558Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgfBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgfBwAAAAAAAA==/",
            "_etag": "\"0501efaa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "fbe2ea28ccac7003f9521d4c175ea62ab352f46c7796ccfe572b3a347b1fd12a",
            "conversationId": "fbe2ea28ccac7003f9521d4c175ea62ab352f46c7796ccfe572b3a347b1fd12a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Fever between 98 and 100.4° (37 and 38°)"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-03-02T15:32:36.77Z",
            "endTime": "2022-03-02T15:35:31.204Z",
            "timeSpent": 174,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIggBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIggBwAAAAAAAA==/",
            "_etag": "\"0501f0aa-0000-0100-0000-6242e45e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648551006
        },
        {
            "id": "30338ccb1e61155f1c1be19e802ab344f05b5fb3cd0505c40ce80d2b8ce4abfe",
            "conversationId": "30338ccb1e61155f1c1be19e802ab344f05b5fb3cd0505c40ce80d2b8ce4abfe",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-03-07T18:58:58.908Z",
            "endTime": "2022-03-07T18:58:58.908Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIghBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIghBwAAAAAAAA==/",
            "_etag": "\"050148ab-0000-0100-0000-6242e4600000\"",
            "_attachments": "attachments/",
            "_ts": 1648551008
        },
        {
            "id": "f2ba7336b7c51fc19c9f423ea7be41e3d28eb9fc774505fe59b52d7247e80d8c",
            "conversationId": "f2ba7336b7c51fc19c9f423ea7be41e3d28eb9fc774505fe59b52d7247e80d8c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-03-09T06:36:56.824Z",
            "endTime": "2022-03-09T06:36:56.824Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgiBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgiBwAAAAAAAA==/",
            "_etag": "\"050149ab-0000-0100-0000-6242e4600000\"",
            "_attachments": "attachments/",
            "_ts": 1648551008
        },
        {
            "id": "14ec3589ee7000ba99dd1959c87ba2e5cfaf56caf3198e766f7f01ff9e1605d9",
            "conversationId": "14ec3589ee7000ba99dd1959c87ba2e5cfaf56caf3198e766f7f01ff9e1605d9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Neck pain"
            ],
            "riskFactors": [
                "Smoking",
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-03-10T15:39:45.834Z",
            "endTime": "2022-03-10T15:42:59.828Z",
            "timeSpent": 194,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgjBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgjBwAAAAAAAA==/",
            "_etag": "\"05014aab-0000-0100-0000-6242e4600000\"",
            "_attachments": "attachments/",
            "_ts": 1648551008
        },
        {
            "id": "1b54629ca8126ec866241537bd2e0628ee3932696691b4406d8c78fa97e89c74",
            "conversationId": "1b54629ca8126ec866241537bd2e0628ee3932696691b4406d8c78fa97e89c74",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-03-15T18:31:30.017Z",
            "endTime": "2022-03-15T18:31:53.211Z",
            "timeSpent": 23,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgkBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgkBwAAAAAAAA==/",
            "_etag": "\"05014bab-0000-0100-0000-6242e4600000\"",
            "_attachments": "attachments/",
            "_ts": 1648551008
        },
        {
            "id": "06ff226367aca2cbcbfafaf7812216ffce416813a763312cd32f8998d0d328f1",
            "conversationId": "06ff226367aca2cbcbfafaf7812216ffce416813a763312cd32f8998d0d328f1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-03-15T18:53:38.384Z",
            "endTime": "2022-03-15T18:54:16.538Z",
            "timeSpent": 38,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIglBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIglBwAAAAAAAA==/",
            "_etag": "\"05014cab-0000-0100-0000-6242e4600000\"",
            "_attachments": "attachments/",
            "_ts": 1648551008
        },
        {
            "id": "8a6ba02b0e01b29f92892cd9da762925689904add2fa5b07af2a1f8a226cb015",
            "conversationId": "8a6ba02b0e01b29f92892cd9da762925689904add2fa5b07af2a1f8a226cb015",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-03-15T18:31:03.207Z",
            "endTime": "2022-03-15T18:31:11.923Z",
            "timeSpent": 9,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgmBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgmBwAAAAAAAA==/",
            "_etag": "\"05014dab-0000-0100-0000-6242e4600000\"",
            "_attachments": "attachments/",
            "_ts": 1648551008
        },
        {
            "id": "8659e1fae9c2f0dea03868e842e1e4de4d209b3d32c68f8a79d0a02749eedb9f",
            "conversationId": "8659e1fae9c2f0dea03868e842e1e4de4d209b3d32c68f8a79d0a02749eedb9f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-03-15T18:30:44.709Z",
            "endTime": "2022-03-15T18:30:44.709Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgnBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgnBwAAAAAAAA==/",
            "_etag": "\"05014eab-0000-0100-0000-6242e4600000\"",
            "_attachments": "attachments/",
            "_ts": 1648551008
        },
        {
            "id": "ca36e111014b6f5b2765f8eb9075ad01a50d1ed08a68dc11832c60ad620215ec",
            "conversationId": "ca36e111014b6f5b2765f8eb9075ad01a50d1ed08a68dc11832c60ad620215ec",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-03-15T19:24:21.573Z",
            "endTime": "2022-03-15T19:25:01.009Z",
            "timeSpent": 39,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgoBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgoBwAAAAAAAA==/",
            "_etag": "\"05014fab-0000-0100-0000-6242e4600000\"",
            "_attachments": "attachments/",
            "_ts": 1648551008
        },
        {
            "id": "9a73eb75797601a57641acf62add63fe0e95fd31227695ffa5532a32c4d9be84",
            "conversationId": "9a73eb75797601a57641acf62add63fe0e95fd31227695ffa5532a32c4d9be84",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Joint pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-03-15T20:24:33.183Z",
            "endTime": "2022-03-15T20:28:43.698Z",
            "timeSpent": 251,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgpBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgpBwAAAAAAAA==/",
            "_etag": "\"050150ab-0000-0100-0000-6242e4600000\"",
            "_attachments": "attachments/",
            "_ts": 1648551008
        },
        {
            "id": "bc91a71679e96a2cdf2e88c9fcc650310cdae7b743b1772493ec4c8555e97e87",
            "conversationId": "bc91a71679e96a2cdf2e88c9fcc650310cdae7b743b1772493ec4c8555e97e87",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-03-15T20:22:19.83Z",
            "endTime": "2022-03-15T20:22:19.83Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgqBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgqBwAAAAAAAA==/",
            "_etag": "\"050151ab-0000-0100-0000-6242e4600000\"",
            "_attachments": "attachments/",
            "_ts": 1648551008
        },
        {
            "id": "8268aa7f4374c8c434304a3179326922fbf6302a0654d3d92dfa04f2cd402a08",
            "conversationId": "8268aa7f4374c8c434304a3179326922fbf6302a0654d3d92dfa04f2cd402a08",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-03-16T14:54:45.249Z",
            "endTime": "2022-03-16T14:54:45.249Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgrBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgrBwAAAAAAAA==/",
            "_etag": "\"050152ab-0000-0100-0000-6242e4600000\"",
            "_attachments": "attachments/",
            "_ts": 1648551008
        },
        {
            "id": "5020b810af2be921b5092f7d2a2c63fe938a29405cbd8d247d6459876e88b250",
            "conversationId": "5020b810af2be921b5092f7d2a2c63fe938a29405cbd8d247d6459876e88b250",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-03-18T20:13:15.266Z",
            "endTime": "2022-03-18T20:13:15.266Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgsBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgsBwAAAAAAAA==/",
            "_etag": "\"050153ab-0000-0100-0000-6242e4600000\"",
            "_attachments": "attachments/",
            "_ts": 1648551008
        },
        {
            "id": "cfaab74b825e039e17a835d983a6d3b99fadd49394cdfae6497aeffdd2cd68fd",
            "conversationId": "cfaab74b825e039e17a835d983a6d3b99fadd49394cdfae6497aeffdd2cd68fd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-03-18T20:13:38.232Z",
            "endTime": "2022-03-18T20:13:38.232Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgtBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgtBwAAAAAAAA==/",
            "_etag": "\"050154ab-0000-0100-0000-6242e4600000\"",
            "_attachments": "attachments/",
            "_ts": 1648551008
        },
        {
            "id": "47fb2c4315c739ae10de9f88cbb5ce53cb50cec6b643fdf3d72751acb7176cb5",
            "conversationId": "47fb2c4315c739ae10de9f88cbb5ce53cb50cec6b643fdf3d72751acb7176cb5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-03-22T04:00:00Z",
            "endTime": "2022-03-22T04:16:07.29Z",
            "timeSpent": 967,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIguBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIguBwAAAAAAAA==/",
            "_etag": "\"050155ab-0000-0100-0000-6242e4600000\"",
            "_attachments": "attachments/",
            "_ts": 1648551008
        },
        {
            "id": "a3ca2ee24456eff303ad14dc9f01825f8282ba17428544209f93784d8481ec52",
            "conversationId": "a3ca2ee24456eff303ad14dc9f01825f8282ba17428544209f93784d8481ec52",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-03-22T05:05:03.083Z",
            "endTime": "2022-03-22T05:05:03.083Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgvBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgvBwAAAAAAAA==/",
            "_etag": "\"050156ab-0000-0100-0000-6242e4600000\"",
            "_attachments": "attachments/",
            "_ts": 1648551008
        },
        {
            "id": "99b6838211003a77dcbcc9f5f5faa600383ceef509c7212a2eca01e120f85b37",
            "conversationId": "99b6838211003a77dcbcc9f5f5faa600383ceef509c7212a2eca01e120f85b37",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-03-23T08:47:56.309Z",
            "endTime": "2022-03-23T08:47:56.309Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgwBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgwBwAAAAAAAA==/",
            "_etag": "\"050157ab-0000-0100-0000-6242e4600000\"",
            "_attachments": "attachments/",
            "_ts": 1648551008
        },
        {
            "id": "06587614c83a45b235ba1331885c2643e6266d32ff294ceaf89e99ad585779dc",
            "conversationId": "06587614c83a45b235ba1331885c2643e6266d32ff294ceaf89e99ad585779dc",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-03-24T00:37:38.793Z",
            "endTime": "2022-03-24T00:37:38.793Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgxBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgxBwAAAAAAAA==/",
            "_etag": "\"050158ab-0000-0100-0000-6242e4600000\"",
            "_attachments": "attachments/",
            "_ts": 1648551008
        },
        {
            "id": "8808ec0f78a6e27b221386e3eb87435a6038dfb151bda37afac2d63b5792ad71",
            "conversationId": "8808ec0f78a6e27b221386e3eb87435a6038dfb151bda37afac2d63b5792ad71",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache",
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-03-29T14:21:44.988Z",
            "endTime": "2022-03-29T14:27:29.406Z",
            "timeSpent": 344,
            "isNoteGenerated": "yes",
            "npsGroup": "Passive",
            "npsScore": 8,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgyBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgyBwAAAAAAAA==/",
            "_etag": "\"080182d2-0000-0100-0000-62432d020000\"",
            "_attachments": "attachments/",
            "_ts": 1648569602
        },
        {
            "id": "8109b50db7369ed5c9f1e79aba28c0a0334d3778f8b11d8a747fd5060032ec16",
            "conversationId": "8109b50db7369ed5c9f1e79aba28c0a0334d3778f8b11d8a747fd5060032ec16",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-03-29T14:20:50.318Z",
            "endTime": "2022-03-29T14:20:50.318Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgzBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgzBwAAAAAAAA==/",
            "_etag": "\"080183d2-0000-0100-0000-62432d020000\"",
            "_attachments": "attachments/",
            "_ts": 1648569602
        },
        {
            "id": "541f87599c826327c4cbd5543aaf27eb6bf8bcb4d963e910bbc6bffa4b5d3991",
            "conversationId": "541f87599c826327c4cbd5543aaf27eb6bf8bcb4d963e910bbc6bffa4b5d3991",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-03-29T14:21:17.17Z",
            "endTime": "2022-03-29T14:21:17.17Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg0BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg0BwAAAAAAAA==/",
            "_etag": "\"080184d2-0000-0100-0000-62432d020000\"",
            "_attachments": "attachments/",
            "_ts": 1648569602
        },
        {
            "id": "8797d181d2b19efe86a50ec0eeb747fa2168e3f8643a65affa6bb8f9a938fcb4",
            "conversationId": "8797d181d2b19efe86a50ec0eeb747fa2168e3f8643a65affa6bb8f9a938fcb4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-03-29T14:20:09.001Z",
            "endTime": "2022-03-29T14:20:09.001Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg1BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg1BwAAAAAAAA==/",
            "_etag": "\"080185d2-0000-0100-0000-62432d020000\"",
            "_attachments": "attachments/",
            "_ts": 1648569602
        },
        {
            "id": "7695cc28f3da202b094cc645cbb91ac4e956cde8ddf7bf974b24e2fffd9cab82",
            "conversationId": "7695cc28f3da202b094cc645cbb91ac4e956cde8ddf7bf974b24e2fffd9cab82",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-03-30T04:39:36.971Z",
            "endTime": "2022-03-30T04:39:36.971Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg2BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg2BwAAAAAAAA==/",
            "_etag": "\"1001ffdc-0000-0100-0000-6243f1e10000\"",
            "_attachments": "attachments/",
            "_ts": 1648620001
        },
        {
            "id": "0a633aa7368ab2689e80f7b4fb373d7d64336c52efff7ef9e87c9dad3c8aaf5d",
            "conversationId": "0a633aa7368ab2689e80f7b4fb373d7d64336c52efff7ef9e87c9dad3c8aaf5d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-03-30T06:05:38.194Z",
            "endTime": "2022-03-30T06:05:38.194Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg3BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg3BwAAAAAAAA==/",
            "_etag": "\"11017141-0000-0100-0000-62440e3e0000\"",
            "_attachments": "attachments/",
            "_ts": 1648627262
        },
        {
            "id": "87d9520596cb08feac0f99523cabb97c2a5dd29485d1225e48688c644e9b2d53",
            "conversationId": "87d9520596cb08feac0f99523cabb97c2a5dd29485d1225e48688c644e9b2d53",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-03T12:06:18.998Z",
            "endTime": "2022-04-03T12:06:18.998Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg4BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg4BwAAAAAAAA==/",
            "_etag": "\"9e01bba9-0000-0100-0000-6249a8600000\"",
            "_attachments": "attachments/",
            "_ts": 1648994400
        },
        {
            "id": "5cc149700258e9bdc97cd1e58c7880aaeb5ca7f132a07a92e2d9a637634df355",
            "conversationId": "5cc149700258e9bdc97cd1e58c7880aaeb5ca7f132a07a92e2d9a637634df355",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-03T12:04:12.015Z",
            "endTime": "2022-04-03T12:04:12.015Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg5BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg5BwAAAAAAAA==/",
            "_etag": "\"9e01bca9-0000-0100-0000-6249a8600000\"",
            "_attachments": "attachments/",
            "_ts": 1648994400
        },
        {
            "id": "6f656c7b7053feb055b1b2aeb02c38d9259913d68d426a99c4fa34b6083c5a0c",
            "conversationId": "6f656c7b7053feb055b1b2aeb02c38d9259913d68d426a99c4fa34b6083c5a0c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-07T11:56:24.387Z",
            "endTime": "2022-04-07T11:56:24.387Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg6BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg6BwAAAAAAAA==/",
            "_etag": "\"2103e1b0-0000-0100-0000-624ee0900000\"",
            "_attachments": "attachments/",
            "_ts": 1649336464
        },
        {
            "id": "48fbc471aa442969524feb85cdb12adb9a3fdf6f25fb6f57a36525a2835fe704",
            "conversationId": "48fbc471aa442969524feb85cdb12adb9a3fdf6f25fb6f57a36525a2835fe704",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-07T11:39:42.608Z",
            "endTime": "2022-04-07T11:39:42.608Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg7BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg7BwAAAAAAAA==/",
            "_etag": "\"2103e2b0-0000-0100-0000-624ee0900000\"",
            "_attachments": "attachments/",
            "_ts": 1649336464
        },
        {
            "id": "f39557dd2bb6cedbd3264d8a3bd0d324fab485798975deb08b3d1b9a354d3f39",
            "conversationId": "f39557dd2bb6cedbd3264d8a3bd0d324fab485798975deb08b3d1b9a354d3f39",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-08T08:57:38.493Z",
            "endTime": "2022-04-08T08:57:38.493Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg8BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg8BwAAAAAAAA==/",
            "_etag": "\"33030f38-0000-0100-0000-625015b70000\"",
            "_attachments": "attachments/",
            "_ts": 1649415607
        },
        {
            "id": "8091cbe20bd3600abf0b664194a35226a8e58feccc9a71354fceb3fb089d7a3f",
            "conversationId": "8091cbe20bd3600abf0b664194a35226a8e58feccc9a71354fceb3fb089d7a3f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-08T09:09:10.357Z",
            "endTime": "2022-04-08T09:09:10.357Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg9BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg9BwAAAAAAAA==/",
            "_etag": "\"33031038-0000-0100-0000-625015b70000\"",
            "_attachments": "attachments/",
            "_ts": 1649415607
        },
        {
            "id": "cfbaf3b4910e387d525a53fd5f9b92b4888569e0cdc2d5e511dcab02098ba126",
            "conversationId": "cfbaf3b4910e387d525a53fd5f9b92b4888569e0cdc2d5e511dcab02098ba126",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-08T09:09:22.378Z",
            "endTime": "2022-04-08T09:09:22.378Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg+BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg+BwAAAAAAAA==/",
            "_etag": "\"33031138-0000-0100-0000-625015b70000\"",
            "_attachments": "attachments/",
            "_ts": 1649415607
        },
        {
            "id": "6b08eb4a6ac5f8ace243325e5c5c388778a97bd0c7451077dfa8896f5bb441fb",
            "conversationId": "6b08eb4a6ac5f8ace243325e5c5c388778a97bd0c7451077dfa8896f5bb441fb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-08T09:10:05.214Z",
            "endTime": "2022-04-08T09:10:05.214Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg-BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg-BwAAAAAAAA==/",
            "_etag": "\"33031238-0000-0100-0000-625015b70000\"",
            "_attachments": "attachments/",
            "_ts": 1649415607
        },
        {
            "id": "51d1db6830c70a26ff5f95cd31169d8797ccd9116999408fad68218690de24e1",
            "conversationId": "51d1db6830c70a26ff5f95cd31169d8797ccd9116999408fad68218690de24e1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-08T12:47:01.764Z",
            "endTime": "2022-04-08T12:47:01.764Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhABwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhABwAAAAAAAA==/",
            "_etag": "\"3603fd5b-0000-0100-0000-62503fe20000\"",
            "_attachments": "attachments/",
            "_ts": 1649426402
        },
        {
            "id": "bd0f1317f737d328af8bb8f617e6e800323d7f635bb64e90dd71590bbada9a6e",
            "conversationId": "bd0f1317f737d328af8bb8f617e6e800323d7f635bb64e90dd71590bbada9a6e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-08T13:21:15.819Z",
            "endTime": "2022-04-08T13:21:15.819Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhBBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhBBwAAAAAAAA==/",
            "_etag": "\"38033d38-0000-0100-0000-62504df10000\"",
            "_attachments": "attachments/",
            "_ts": 1649430001
        },
        {
            "id": "28c76d6fd2ff9cfe719b01a108dc49433c3e16df2974f1e82cf5003b197e2a18",
            "conversationId": "28c76d6fd2ff9cfe719b01a108dc49433c3e16df2974f1e82cf5003b197e2a18",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Runny nose"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-04-08T14:31:46.536Z",
            "endTime": "2022-04-08T14:35:28.504Z",
            "timeSpent": 222,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhCBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhCBwAAAAAAAA==/",
            "_etag": "\"3a03fb56-0000-0100-0000-62505c010000\"",
            "_attachments": "attachments/",
            "_ts": 1649433601
        },
        {
            "id": "e463875a5671296e1fc67ba3e103a042da68bd20bea098a95641bd7a7b912b92",
            "conversationId": "e463875a5671296e1fc67ba3e103a042da68bd20bea098a95641bd7a7b912b92",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-10T21:17:45.091Z",
            "endTime": "2022-04-10T21:17:45.091Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhDBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhDBwAAAAAAAA==/",
            "_etag": "\"5a039844-0000-0100-0000-625361720000\"",
            "_attachments": "attachments/",
            "_ts": 1649631602
        },
        {
            "id": "89eefcf8545035d1e635d79c63847035f1ba46c77ed3f0815bb8ff4c40d26861",
            "conversationId": "89eefcf8545035d1e635d79c63847035f1ba46c77ed3f0815bb8ff4c40d26861",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-10T22:04:04.286Z",
            "endTime": "2022-04-10T22:04:04.286Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhEBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhEBwAAAAAAAA==/",
            "_etag": "\"5a03e45d-0000-0100-0000-62536f810000\"",
            "_attachments": "attachments/",
            "_ts": 1649635201
        },
        {
            "id": "366ca963d57fb6298c60acbfe6c136d992bc5c3230dc34d00d56f262d1601cc1",
            "conversationId": "366ca963d57fb6298c60acbfe6c136d992bc5c3230dc34d00d56f262d1601cc1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-11T08:40:21.238Z",
            "endTime": "2022-04-11T08:40:21.238Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhFBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhFBwAAAAAAAA==/",
            "_etag": "\"66035374-0000-0100-0000-6253fc210000\"",
            "_attachments": "attachments/",
            "_ts": 1649671201
        },
        {
            "id": "a0953ffa68521b318f1ca1c1150c67ccfe5171f30fe2a5ee253df085920a6b14",
            "conversationId": "a0953ffa68521b318f1ca1c1150c67ccfe5171f30fe2a5ee253df085920a6b14",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-11T08:44:33.626Z",
            "endTime": "2022-04-11T08:44:33.626Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhGBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhGBwAAAAAAAA==/",
            "_etag": "\"660378f1-0000-0100-0000-62540a310000\"",
            "_attachments": "attachments/",
            "_ts": 1649674801
        },
        {
            "id": "2680b4680dbaa85a4065d61c5ddb8eba49f2aa111debf5fdf708044122766143",
            "conversationId": "2680b4680dbaa85a4065d61c5ddb8eba49f2aa111debf5fdf708044122766143",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-11T08:42:17.131Z",
            "endTime": "2022-04-11T08:42:17.131Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhHBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhHBwAAAAAAAA==/",
            "_etag": "\"660377f1-0000-0100-0000-62540a310000\"",
            "_attachments": "attachments/",
            "_ts": 1649674801
        },
        {
            "id": "4f24d58d073d49c9cd9626865ff963a7abdc92e47c02ff8270b5f329095d1306",
            "conversationId": "4f24d58d073d49c9cd9626865ff963a7abdc92e47c02ff8270b5f329095d1306",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-11T08:58:14.924Z",
            "endTime": "2022-04-11T08:58:14.924Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhIBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhIBwAAAAAAAA==/",
            "_etag": "\"66035674-0000-0100-0000-6253fc210000\"",
            "_attachments": "attachments/",
            "_ts": 1649671201
        },
        {
            "id": "718c0ff6533526e098b67a045ff716ad710ea1d5139cddfa58485ef46f83a23b",
            "conversationId": "718c0ff6533526e098b67a045ff716ad710ea1d5139cddfa58485ef46f83a23b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-11T08:55:24.976Z",
            "endTime": "2022-04-11T08:55:24.976Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhJBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhJBwAAAAAAAA==/",
            "_etag": "\"66035774-0000-0100-0000-6253fc210000\"",
            "_attachments": "attachments/",
            "_ts": 1649671201
        },
        {
            "id": "743c3cecb4c42fa9864d4c9ad1858a57dad92b7ef166a02e7f3bf479215f6898",
            "conversationId": "743c3cecb4c42fa9864d4c9ad1858a57dad92b7ef166a02e7f3bf479215f6898",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-11T12:41:07.819Z",
            "endTime": "2022-04-11T12:41:07.819Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhKBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhKBwAAAAAAAA==/",
            "_etag": "\"6b037efa-0000-0100-0000-625442710000\"",
            "_attachments": "attachments/",
            "_ts": 1649689201
        },
        {
            "id": "6400c7bf2d01bb0d615ca2817503e12f29445efd56f654c227a3e6b57b6b9446",
            "conversationId": "6400c7bf2d01bb0d615ca2817503e12f29445efd56f654c227a3e6b57b6b9446",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-11T14:19:28.775Z",
            "endTime": "2022-04-11T14:20:47.821Z",
            "timeSpent": 79,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhLBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhLBwAAAAAAAA==/",
            "_etag": "\"6d036f95-0000-0100-0000-625450820000\"",
            "_attachments": "attachments/",
            "_ts": 1649692802
        },
        {
            "id": "d1257d01ba2e89663359e233762d1ad84fb14f98f0f2f9606cff8312bb1dbfee",
            "conversationId": "d1257d01ba2e89663359e233762d1ad84fb14f98f0f2f9606cff8312bb1dbfee",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-04-11T14:23:42.569Z",
            "endTime": "2022-04-11T14:27:05.933Z",
            "timeSpent": 203,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhMBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhMBwAAAAAAAA==/",
            "_etag": "\"6d037095-0000-0100-0000-625450820000\"",
            "_attachments": "attachments/",
            "_ts": 1649692802
        },
        {
            "id": "df73b78faf6455ee8ae1724f991f5c2ba90fdb2a69299dc5aac04ec5a1acfb84",
            "conversationId": "df73b78faf6455ee8ae1724f991f5c2ba90fdb2a69299dc5aac04ec5a1acfb84",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-11T14:14:11.467Z",
            "endTime": "2022-04-11T14:14:11.467Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhNBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhNBwAAAAAAAA==/",
            "_etag": "\"6d037195-0000-0100-0000-625450820000\"",
            "_attachments": "attachments/",
            "_ts": 1649692802
        },
        {
            "id": "f3cee74c5040fe0f1cfaa38cefc7397fc5f70fb289abb6859bf4872734fe6ac0",
            "conversationId": "f3cee74c5040fe0f1cfaa38cefc7397fc5f70fb289abb6859bf4872734fe6ac0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-04-11T14:15:32.856Z",
            "endTime": "2022-04-11T14:18:14.09Z",
            "timeSpent": 161,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhOBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhOBwAAAAAAAA==/",
            "_etag": "\"6d037295-0000-0100-0000-625450820000\"",
            "_attachments": "attachments/",
            "_ts": 1649692802
        },
        {
            "id": "a54a6ee6adf9a9f19ce71635fce176c665a8a90fae7c30804de6017db1481993",
            "conversationId": "a54a6ee6adf9a9f19ce71635fce176c665a8a90fae7c30804de6017db1481993",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-04-11T14:04:50.739Z",
            "endTime": "2022-04-11T14:07:01.494Z",
            "timeSpent": 131,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhPBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhPBwAAAAAAAA==/",
            "_etag": "\"6d037395-0000-0100-0000-625450820000\"",
            "_attachments": "attachments/",
            "_ts": 1649692802
        },
        {
            "id": "a408939ffdf548dbe60214beaabe513c6cf22cb29ab3173e9c55801d4896e173",
            "conversationId": "a408939ffdf548dbe60214beaabe513c6cf22cb29ab3173e9c55801d4896e173",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-11T14:13:27.918Z",
            "endTime": "2022-04-11T14:13:27.918Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhQBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhQBwAAAAAAAA==/",
            "_etag": "\"6d037495-0000-0100-0000-625450820000\"",
            "_attachments": "attachments/",
            "_ts": 1649692802
        },
        {
            "id": "2ede94acefbcc0750b21a2eee6edd6b47317cbdee444ad72a2d295a868c826c5",
            "conversationId": "2ede94acefbcc0750b21a2eee6edd6b47317cbdee444ad72a2d295a868c826c5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-11T14:18:44.621Z",
            "endTime": "2022-04-11T14:19:10.949Z",
            "timeSpent": 26,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhRBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhRBwAAAAAAAA==/",
            "_etag": "\"6d037595-0000-0100-0000-625450820000\"",
            "_attachments": "attachments/",
            "_ts": 1649692802
        },
        {
            "id": "59db6ee56c1a50e3aadc2ebd1726b455dfd448793c46f8b8aa0a21405a9ffa26",
            "conversationId": "59db6ee56c1a50e3aadc2ebd1726b455dfd448793c46f8b8aa0a21405a9ffa26",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-11T14:13:35.696Z",
            "endTime": "2022-04-11T14:13:35.696Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhSBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhSBwAAAAAAAA==/",
            "_etag": "\"6d037695-0000-0100-0000-625450820000\"",
            "_attachments": "attachments/",
            "_ts": 1649692802
        },
        {
            "id": "da86d7aec638fa8769803ede02298e1bb724817542565da41c1e8d95596e88c9",
            "conversationId": "da86d7aec638fa8769803ede02298e1bb724817542565da41c1e8d95596e88c9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-12T06:38:23.287Z",
            "endTime": "2022-04-12T06:38:23.287Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhTBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhTBwAAAAAAAA==/",
            "_etag": "\"8503aa29-0000-0100-0000-625531bd0000\"",
            "_attachments": "attachments/",
            "_ts": 1649750461
        },
        {
            "id": "afe875e5f101379c0c85b6db4d9df435cdac9372a76c25abe3423051e07ad4e4",
            "conversationId": "afe875e5f101379c0c85b6db4d9df435cdac9372a76c25abe3423051e07ad4e4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-12T12:17:53.193Z",
            "endTime": "2022-04-12T12:17:53.193Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhUBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhUBwAAAAAAAA==/",
            "_etag": "\"8a035f5f-0000-0100-0000-625585e10000\"",
            "_attachments": "attachments/",
            "_ts": 1649772001
        },
        {
            "id": "0699af729086bfeb7e6db2221d48e5c23453d4a7db117ce010cfc315463c2808",
            "conversationId": "0699af729086bfeb7e6db2221d48e5c23453d4a7db117ce010cfc315463c2808",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-12T12:38:46.358Z",
            "endTime": "2022-04-12T12:38:46.358Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhVBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhVBwAAAAAAAA==/",
            "_etag": "\"8a03605f-0000-0100-0000-625585e10000\"",
            "_attachments": "attachments/",
            "_ts": 1649772001
        },
        {
            "id": "c6ddd52e8b67f6bd8255d22228fb952bc45494b05064efcc150421687143248f",
            "conversationId": "c6ddd52e8b67f6bd8255d22228fb952bc45494b05064efcc150421687143248f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-12T13:47:50.26Z",
            "endTime": "2022-04-12T13:47:50.26Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhWBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhWBwAAAAAAAA==/",
            "_etag": "\"8c03a810-0000-0100-0000-625593f10000\"",
            "_attachments": "attachments/",
            "_ts": 1649775601
        },
        {
            "id": "ea617d1a64f827847c6a2b2a52741679f01a84d199fc5f97a1ed5b6c9708397c",
            "conversationId": "ea617d1a64f827847c6a2b2a52741679f01a84d199fc5f97a1ed5b6c9708397c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-12T17:51:21.886Z",
            "endTime": "2022-04-12T17:51:27.622Z",
            "timeSpent": 6,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhXBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhXBwAAAAAAAA==/",
            "_etag": "\"9203be1d-0000-0100-0000-6255cc310000\"",
            "_attachments": "attachments/",
            "_ts": 1649790001
        },
        {
            "id": "08991f59dd7d03aad90df81acea49a52496475499c13226681f3823f3f35fe43",
            "conversationId": "08991f59dd7d03aad90df81acea49a52496475499c13226681f3823f3f35fe43",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-12T17:51:10.835Z",
            "endTime": "2022-04-12T17:51:10.835Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhYBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhYBwAAAAAAAA==/",
            "_etag": "\"9203bf1d-0000-0100-0000-6255cc310000\"",
            "_attachments": "attachments/",
            "_ts": 1649790001
        },
        {
            "id": "1d1c516f8866db924bc0c8943d8745e3cd4539cc6b42c6219335264a300ac75b",
            "conversationId": "1d1c516f8866db924bc0c8943d8745e3cd4539cc6b42c6219335264a300ac75b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-13T09:12:57.219Z",
            "endTime": "2022-04-13T09:12:57.219Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhZBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhZBwAAAAAAAA==/",
            "_etag": "\"9b037b33-0000-0100-0000-6256ad330000\"",
            "_attachments": "attachments/",
            "_ts": 1649847603
        },
        {
            "id": "ce109cb11047affbfe7abaec48f8dc1b8535b95c3471fafb9d247f5777f59712",
            "conversationId": "ce109cb11047affbfe7abaec48f8dc1b8535b95c3471fafb9d247f5777f59712",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-13T09:31:10.854Z",
            "endTime": "2022-04-13T09:31:10.854Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhaBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhaBwAAAAAAAA==/",
            "_etag": "\"9b037c33-0000-0100-0000-6256ad330000\"",
            "_attachments": "attachments/",
            "_ts": 1649847603
        },
        {
            "id": "3178d36bb4cd9cccc97c1efb5bd5b59b3b43408a29c06f000c41b306d5b72cf7",
            "conversationId": "3178d36bb4cd9cccc97c1efb5bd5b59b3b43408a29c06f000c41b306d5b72cf7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-13T09:50:30.07Z",
            "endTime": "2022-04-13T09:50:30.07Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhbBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhbBwAAAAAAAA==/",
            "_etag": "\"9b0323e4-0000-0100-0000-6256bb420000\"",
            "_attachments": "attachments/",
            "_ts": 1649851202
        },
        {
            "id": "5a944478c434d1e027afe070e866d13f28924421191c24f8d73e28f6ba6a7ca8",
            "conversationId": "5a944478c434d1e027afe070e866d13f28924421191c24f8d73e28f6ba6a7ca8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-13T09:07:12.872Z",
            "endTime": "2022-04-13T09:07:12.872Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhcBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhcBwAAAAAAAA==/",
            "_etag": "\"9b037e33-0000-0100-0000-6256ad330000\"",
            "_attachments": "attachments/",
            "_ts": 1649847603
        },
        {
            "id": "34843d4758063321bb6409388841ebe2a52b0770ba7fa38b5b4bdbf175b8a8b5",
            "conversationId": "34843d4758063321bb6409388841ebe2a52b0770ba7fa38b5b4bdbf175b8a8b5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-13T09:33:26.238Z",
            "endTime": "2022-04-13T09:33:26.238Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhdBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhdBwAAAAAAAA==/",
            "_etag": "\"9b037f33-0000-0100-0000-6256ad330000\"",
            "_attachments": "attachments/",
            "_ts": 1649847603
        },
        {
            "id": "67238b06aba24a590329d29ea28657e505cca226847b3aae4369d41b4255504c",
            "conversationId": "67238b06aba24a590329d29ea28657e505cca226847b3aae4369d41b4255504c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-13T10:09:44.167Z",
            "endTime": "2022-04-13T10:09:44.167Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIheBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIheBwAAAAAAAA==/",
            "_etag": "\"9b0322e4-0000-0100-0000-6256bb420000\"",
            "_attachments": "attachments/",
            "_ts": 1649851202
        },
        {
            "id": "6215b6cf2dd353f76b0c2816e143059acf9e239ef6f5b9c449e8f8fd54212fd9",
            "conversationId": "6215b6cf2dd353f76b0c2816e143059acf9e239ef6f5b9c449e8f8fd54212fd9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-13T13:17:45.562Z",
            "endTime": "2022-04-13T13:17:45.562Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhfBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhfBwAAAAAAAA==/",
            "_etag": "\"a1037930-0000-0100-0000-6256e5710000\"",
            "_attachments": "attachments/",
            "_ts": 1649862001
        },
        {
            "id": "fb0c138c826bcd44f6f697ee18661033038ff7a02c93761d02202c84937a2453",
            "conversationId": "fb0c138c826bcd44f6f697ee18661033038ff7a02c93761d02202c84937a2453",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-13T13:25:37.139Z",
            "endTime": "2022-04-13T13:25:37.139Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhgBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhgBwAAAAAAAA==/",
            "_etag": "\"a1037a30-0000-0100-0000-6256e5710000\"",
            "_attachments": "attachments/",
            "_ts": 1649862001
        },
        {
            "id": "efc1d01db00bbfe0cab90e20bb1eb60b40b088ce0506c3177c5a2eb60af6483d",
            "conversationId": "efc1d01db00bbfe0cab90e20bb1eb60b40b088ce0506c3177c5a2eb60af6483d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-13T13:30:38.719Z",
            "endTime": "2022-04-13T13:30:38.719Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhhBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhhBwAAAAAAAA==/",
            "_etag": "\"a3034c09-0000-0100-0000-6256f3820000\"",
            "_attachments": "attachments/",
            "_ts": 1649865602
        },
        {
            "id": "edca136fd46cbfe535b2ebbdb1071c15fa6ec9c9a16cf387826e982d7b071f63",
            "conversationId": "edca136fd46cbfe535b2ebbdb1071c15fa6ec9c9a16cf387826e982d7b071f63",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-13T13:28:01.52Z",
            "endTime": "2022-04-13T13:28:01.52Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhiBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhiBwAAAAAAAA==/",
            "_etag": "\"a1037c30-0000-0100-0000-6256e5710000\"",
            "_attachments": "attachments/",
            "_ts": 1649862001
        },
        {
            "id": "de789315c8828ce69fe5d289991586b968482463a324e6e0607ae7068599ee0f",
            "conversationId": "de789315c8828ce69fe5d289991586b968482463a324e6e0607ae7068599ee0f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-13T13:30:51.516Z",
            "endTime": "2022-04-13T13:30:51.516Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhjBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhjBwAAAAAAAA==/",
            "_etag": "\"a3034d09-0000-0100-0000-6256f3820000\"",
            "_attachments": "attachments/",
            "_ts": 1649865602
        },
        {
            "id": "73da1540f2c5f1f8bff70fdc03ba19c62d60000e9005e712221c21dffd37ad6e",
            "conversationId": "73da1540f2c5f1f8bff70fdc03ba19c62d60000e9005e712221c21dffd37ad6e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-13T13:18:46.683Z",
            "endTime": "2022-04-13T13:18:46.683Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhkBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhkBwAAAAAAAA==/",
            "_etag": "\"a1037e30-0000-0100-0000-6256e5710000\"",
            "_attachments": "attachments/",
            "_ts": 1649862001
        },
        {
            "id": "a3052baa336581143426737be31879d14e12b00755a5083865ca9cdb4c3efb94",
            "conversationId": "a3052baa336581143426737be31879d14e12b00755a5083865ca9cdb4c3efb94",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-13T13:18:05.376Z",
            "endTime": "2022-04-13T13:18:05.376Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhlBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhlBwAAAAAAAA==/",
            "_etag": "\"a1037f30-0000-0100-0000-6256e5710000\"",
            "_attachments": "attachments/",
            "_ts": 1649862001
        },
        {
            "id": "d01f6647f958ccf1d851e37d58f3030bdc8d76055963312421f5c25124b7a8ab",
            "conversationId": "d01f6647f958ccf1d851e37d58f3030bdc8d76055963312421f5c25124b7a8ab",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-13T13:31:22.852Z",
            "endTime": "2022-04-13T13:31:22.852Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhmBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhmBwAAAAAAAA==/",
            "_etag": "\"a3034e09-0000-0100-0000-6256f3820000\"",
            "_attachments": "attachments/",
            "_ts": 1649865602
        },
        {
            "id": "5680546aa2f8172b092feeba773f83c096a5cbbd3926f479508c8d00a87d4fdb",
            "conversationId": "5680546aa2f8172b092feeba773f83c096a5cbbd3926f479508c8d00a87d4fdb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-13T22:11:50.152Z",
            "endTime": "2022-04-13T22:11:50.152Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhnBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhnBwAAAAAAAA==/",
            "_etag": "\"b703fba3-0000-0100-0000-625764010000\"",
            "_attachments": "attachments/",
            "_ts": 1649894401
        },
        {
            "id": "c0db00b25df4c6503b0b68fbeb73017058c81cc3f2e5c3ecc06ccadece1362b8",
            "conversationId": "c0db00b25df4c6503b0b68fbeb73017058c81cc3f2e5c3ecc06ccadece1362b8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-13T22:17:06.207Z",
            "endTime": "2022-04-13T22:17:06.207Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhoBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhoBwAAAAAAAA==/",
            "_etag": "\"b703fca3-0000-0100-0000-625764010000\"",
            "_attachments": "attachments/",
            "_ts": 1649894401
        },
        {
            "id": "b40bc35d51cb56b66c296b6d79885d9664700ab238a9ae16b4f0197a25e259a1",
            "conversationId": "b40bc35d51cb56b66c296b6d79885d9664700ab238a9ae16b4f0197a25e259a1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-04-14T16:21:46.616Z",
            "endTime": "2022-04-14T16:23:58.998Z",
            "timeSpent": 132,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhpBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhpBwAAAAAAAA==/",
            "_etag": "\"c403f375-0000-0100-0000-625861220000\"",
            "_attachments": "attachments/",
            "_ts": 1649959202
        },
        {
            "id": "bbbc307ca6e10603f1f1ff1e69be37da946e7c6426192afd7c2bc35de13b322a",
            "conversationId": "bbbc307ca6e10603f1f1ff1e69be37da946e7c6426192afd7c2bc35de13b322a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Frequent urination"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-04-14T18:25:48.276Z",
            "endTime": "2022-04-14T18:27:28.005Z",
            "timeSpent": 100,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhqBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhqBwAAAAAAAA==/",
            "_etag": "\"c7033a0a-0000-0100-0000-62587d420000\"",
            "_attachments": "attachments/",
            "_ts": 1649966402
        },
        {
            "id": "57f0bc0ee76ed1b3c873b553f95a7a67628bc2882a88faa77719bd7ee25002ed",
            "conversationId": "57f0bc0ee76ed1b3c873b553f95a7a67628bc2882a88faa77719bd7ee25002ed",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Runny nose",
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will go to ReadyMED / urgent care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-04-14T18:23:57.592Z",
            "endTime": "2022-04-14T18:25:19.325Z",
            "timeSpent": 82,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhrBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhrBwAAAAAAAA==/",
            "_etag": "\"c7033b0a-0000-0100-0000-62587d420000\"",
            "_attachments": "attachments/",
            "_ts": 1649966402
        },
        {
            "id": "35c8c96460a5048890de4d2584a2b34bf54e913bb2b9def6e697578d0fb454e0",
            "conversationId": "35c8c96460a5048890de4d2584a2b34bf54e913bb2b9def6e697578d0fb454e0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-14T18:27:52.298Z",
            "endTime": "2022-04-14T18:28:47.549Z",
            "timeSpent": 55,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhsBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhsBwAAAAAAAA==/",
            "_etag": "\"c7033c0a-0000-0100-0000-62587d420000\"",
            "_attachments": "attachments/",
            "_ts": 1649966402
        },
        {
            "id": "8b7a3b6c48121aeda167314ca0b172389b4350e84199a808923a30a774535e63",
            "conversationId": "8b7a3b6c48121aeda167314ca0b172389b4350e84199a808923a30a774535e63",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-14T18:23:47.67Z",
            "endTime": "2022-04-14T18:23:47.67Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhtBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhtBwAAAAAAAA==/",
            "_etag": "\"c7033d0a-0000-0100-0000-62587d420000\"",
            "_attachments": "attachments/",
            "_ts": 1649966402
        },
        {
            "id": "bdc79c825e069773af4c81bb145bffc748020c8b0bbb7a5fd080d661e7e4f0b3",
            "conversationId": "bdc79c825e069773af4c81bb145bffc748020c8b0bbb7a5fd080d661e7e4f0b3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Depressed mood",
                "Elevated mood",
                "Lost will to live"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-04-14T18:45:59.408Z",
            "endTime": "2022-04-14T18:48:20.944Z",
            "timeSpent": 142,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhuBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhuBwAAAAAAAA==/",
            "_etag": "\"c7033e0a-0000-0100-0000-62587d420000\"",
            "_attachments": "attachments/",
            "_ts": 1649966402
        },
        {
            "id": "f501a9ba7c09f7892a6bda3959c413e0ac5a93caa7eb23583d78419cb90479d4",
            "conversationId": "f501a9ba7c09f7892a6bda3959c413e0ac5a93caa7eb23583d78419cb90479d4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Tooth hurts when chewing"
            ],
            "riskFactors": [
                "High cholesterol"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will go to ReadyMED / urgent care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-04-14T18:29:17.086Z",
            "endTime": "2022-04-14T18:32:11.182Z",
            "timeSpent": 174,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhvBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhvBwAAAAAAAA==/",
            "_etag": "\"c8032529-0000-0100-0000-62588b510000\"",
            "_attachments": "attachments/",
            "_ts": 1649970001
        },
        {
            "id": "1f7c3c7a458c273071888eefa43af80e7809b5a12292b4322636555d92861bbc",
            "conversationId": "1f7c3c7a458c273071888eefa43af80e7809b5a12292b4322636555d92861bbc",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-14T18:54:52.505Z",
            "endTime": "2022-04-14T18:57:19.427Z",
            "timeSpent": 147,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhwBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhwBwAAAAAAAA==/",
            "_etag": "\"c703400a-0000-0100-0000-62587d420000\"",
            "_attachments": "attachments/",
            "_ts": 1649966402
        },
        {
            "id": "5109b7d43367969c6edc18c01fec28945a556529c1d6e1b9cd2442b0a32a597d",
            "conversationId": "5109b7d43367969c6edc18c01fec28945a556529c1d6e1b9cd2442b0a32a597d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-04-14T18:42:47.423Z",
            "endTime": "2022-04-14T18:45:18.832Z",
            "timeSpent": 151,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhxBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhxBwAAAAAAAA==/",
            "_etag": "\"c8032829-0000-0100-0000-62588b510000\"",
            "_attachments": "attachments/",
            "_ts": 1649970001
        },
        {
            "id": "bac57f839370baa7f97b4c94234c80dd7d30b7b36db8a32e3dbf86cc882ef349",
            "conversationId": "bac57f839370baa7f97b4c94234c80dd7d30b7b36db8a32e3dbf86cc882ef349",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Lightheaded"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-04-14T18:52:16.336Z",
            "endTime": "2022-04-14T18:54:03.578Z",
            "timeSpent": 107,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhyBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhyBwAAAAAAAA==/",
            "_etag": "\"c8032a29-0000-0100-0000-62588b510000\"",
            "_attachments": "attachments/",
            "_ts": 1649970001
        },
        {
            "id": "fd7056ddd252c759c2d69b4d3b9639d466b096f2ece956d2a8f047b434c865f5",
            "conversationId": "fd7056ddd252c759c2d69b4d3b9639d466b096f2ece956d2a8f047b434c865f5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Hair loss"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-04-14T18:49:09.309Z",
            "endTime": "2022-04-14T18:51:24.208Z",
            "timeSpent": 135,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhzBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhzBwAAAAAAAA==/",
            "_etag": "\"c8032929-0000-0100-0000-62588b510000\"",
            "_attachments": "attachments/",
            "_ts": 1649970001
        },
        {
            "id": "952b9b5af5ceb24f962db5d4d9cf706dd154c7f2d79deffb7c088e6adad25a04",
            "conversationId": "952b9b5af5ceb24f962db5d4d9cf706dd154c7f2d79deffb7c088e6adad25a04",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-14T18:48:59.683Z",
            "endTime": "2022-04-14T18:48:59.683Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh0BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh0BwAAAAAAAA==/",
            "_etag": "\"c703440a-0000-0100-0000-62587d420000\"",
            "_attachments": "attachments/",
            "_ts": 1649966402
        },
        {
            "id": "ba5b72b28100ef3ae3b44aae6fe9e935a1c9c4ce8dba11f7ec7a32da8b2ef59c",
            "conversationId": "ba5b72b28100ef3ae3b44aae6fe9e935a1c9c4ce8dba11f7ec7a32da8b2ef59c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Seizures"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-04-14T18:32:50.507Z",
            "endTime": "2022-04-14T18:35:07.286Z",
            "timeSpent": 137,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh1BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh1BwAAAAAAAA==/",
            "_etag": "\"c8032629-0000-0100-0000-62588b510000\"",
            "_attachments": "attachments/",
            "_ts": 1649970001
        },
        {
            "id": "abcf13e54fbc4fe6fca5fb6a749357cedc584774d54c851cbc68fc0223481ed5",
            "conversationId": "abcf13e54fbc4fe6fca5fb6a749357cedc584774d54c851cbc68fc0223481ed5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-14T18:38:10.94Z",
            "endTime": "2022-04-14T18:38:10.94Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh2BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh2BwAAAAAAAA==/",
            "_etag": "\"c703460a-0000-0100-0000-62587d420000\"",
            "_attachments": "attachments/",
            "_ts": 1649966402
        },
        {
            "id": "7b7cec420d878925ecd0828402303f29782893c5e758288f38f8834d49f62973",
            "conversationId": "7b7cec420d878925ecd0828402303f29782893c5e758288f38f8834d49f62973",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-14T18:38:03.367Z",
            "endTime": "2022-04-14T18:38:03.367Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh3BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh3BwAAAAAAAA==/",
            "_etag": "\"c703470a-0000-0100-0000-62587d420000\"",
            "_attachments": "attachments/",
            "_ts": 1649966402
        },
        {
            "id": "3ac88954cab25c324be368a3648bc10e08bc4de0e738e5ebac6fced9d49f4a3f",
            "conversationId": "3ac88954cab25c324be368a3648bc10e08bc4de0e738e5ebac6fced9d49f4a3f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-14T18:39:23.827Z",
            "endTime": "2022-04-14T18:39:23.827Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh4BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh4BwAAAAAAAA==/",
            "_etag": "\"c8032729-0000-0100-0000-62588b510000\"",
            "_attachments": "attachments/",
            "_ts": 1649970001
        },
        {
            "id": "3f30779d1d71117287cb37078ef23e355fb09f890247efe861dc85d02026de70",
            "conversationId": "3f30779d1d71117287cb37078ef23e355fb09f890247efe861dc85d02026de70",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-14T18:48:44.379Z",
            "endTime": "2022-04-14T18:48:44.379Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh5BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh5BwAAAAAAAA==/",
            "_etag": "\"c703490a-0000-0100-0000-62587d420000\"",
            "_attachments": "attachments/",
            "_ts": 1649966402
        },
        {
            "id": "83ffeadf65aad9b22a6d0289c77ddf8049bd1457b05f85492b9b1e9ef00163d4",
            "conversationId": "83ffeadf65aad9b22a6d0289c77ddf8049bd1457b05f85492b9b1e9ef00163d4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-14T19:28:58.43Z",
            "endTime": "2022-04-14T19:30:20.029Z",
            "timeSpent": 82,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh6BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh6BwAAAAAAAA==/",
            "_etag": "\"c8031e29-0000-0100-0000-62588b510000\"",
            "_attachments": "attachments/",
            "_ts": 1649970001
        },
        {
            "id": "8dacc49637092d9a89037d632621ce2d4d167807370ca76a4e9195383eec6110",
            "conversationId": "8dacc49637092d9a89037d632621ce2d4d167807370ca76a4e9195383eec6110",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-14T19:26:07.527Z",
            "endTime": "2022-04-14T19:27:48.749Z",
            "timeSpent": 101,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh7BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh7BwAAAAAAAA==/",
            "_etag": "\"c8031f29-0000-0100-0000-62588b510000\"",
            "_attachments": "attachments/",
            "_ts": 1649970001
        },
        {
            "id": "bfea14ddfecee3b89e41928d32ea5f47f84e777fd5dfe8e49c286d1444865330",
            "conversationId": "bfea14ddfecee3b89e41928d32ea5f47f84e777fd5dfe8e49c286d1444865330",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-14T19:06:51.766Z",
            "endTime": "2022-04-14T19:08:18.657Z",
            "timeSpent": 87,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh8BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh8BwAAAAAAAA==/",
            "_etag": "\"c8032029-0000-0100-0000-62588b510000\"",
            "_attachments": "attachments/",
            "_ts": 1649970001
        },
        {
            "id": "cd7cee3a0c2ea6c80594df4d85b50984ae367602f4fc59dda26ca73a4d963b60",
            "conversationId": "cd7cee3a0c2ea6c80594df4d85b50984ae367602f4fc59dda26ca73a4d963b60",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-04-14T19:04:20.987Z",
            "endTime": "2022-04-14T19:06:21.284Z",
            "timeSpent": 120,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh9BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh9BwAAAAAAAA==/",
            "_etag": "\"c8032129-0000-0100-0000-62588b510000\"",
            "_attachments": "attachments/",
            "_ts": 1649970001
        },
        {
            "id": "1a43d07fafc0efc2dcfc65fc98eecc3b000fb013dda420f4f05947ab622d48bd",
            "conversationId": "1a43d07fafc0efc2dcfc65fc98eecc3b000fb013dda420f4f05947ab622d48bd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-14T19:25:01.668Z",
            "endTime": "2022-04-14T19:25:01.668Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh+BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh+BwAAAAAAAA==/",
            "_etag": "\"c8032229-0000-0100-0000-62588b510000\"",
            "_attachments": "attachments/",
            "_ts": 1649970001
        },
        {
            "id": "4e5d9d2e80cb90c4f5884b8b15833ee4c46eb5bfc562a06fd39112a2ebef642f",
            "conversationId": "4e5d9d2e80cb90c4f5884b8b15833ee4c46eb5bfc562a06fd39112a2ebef642f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-14T19:03:45.332Z",
            "endTime": "2022-04-14T19:03:45.332Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh-BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh-BwAAAAAAAA==/",
            "_etag": "\"c8032329-0000-0100-0000-62588b510000\"",
            "_attachments": "attachments/",
            "_ts": 1649970001
},
    
        {
            "id": "8d0c29d69ae38bc0e4cc1ec28f8250c572b5a454e8bf776c0edc76cfe815e8d9",
            "conversationId": "8d0c29d69ae38bc0e4cc1ec28f8250c572b5a454e8bf776c0edc76cfe815e8d9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-14T19:04:03.339Z",
            "endTime": "2022-04-14T19:04:03.339Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiABwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiABwAAAAAAAA==/",
            "_etag": "\"c8032429-0000-0100-0000-62588b510000\"",
            "_attachments": "attachments/",
            "_ts": 1649970001
        },
        {
            "id": "d0fa8801b36367354826f8345a53154a6c616835f3de56ae311cf838019304ca",
            "conversationId": "d0fa8801b36367354826f8345a53154a6c616835f3de56ae311cf838019304ca",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-17T23:24:43.967Z",
            "endTime": "2022-04-17T23:24:43.967Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiBBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiBBwAAAAAAAA==/",
            "_etag": "\"ea0376b6-0000-0100-0000-625cb8110000\"",
            "_attachments": "attachments/",
            "_ts": 1650243601
        },
        {
            "id": "080736419a99013ce2249751d2633788a94b51a4bd4b2655bdaa47600b72a238",
            "conversationId": "080736419a99013ce2249751d2633788a94b51a4bd4b2655bdaa47600b72a238",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-18T05:00:00Z",
            "endTime": "2022-04-18T05:12:47.744Z",
            "timeSpent": 768,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiCBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiCBwAAAAAAAA==/",
            "_etag": "\"ec036d0d-0000-0100-0000-625d0c710000\"",
            "_attachments": "attachments/",
            "_ts": 1650265201
        },
        {
            "id": "7532e373caf1033bda8b1fff8dbcd8f959b3a949f58608958483342c17efc575",
            "conversationId": "7532e373caf1033bda8b1fff8dbcd8f959b3a949f58608958483342c17efc575",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-18T13:22:13.943Z",
            "endTime": "2022-04-18T13:22:13.943Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiDBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiDBwAAAAAAAA==/",
            "_etag": "\"f1034ba8-0000-0100-0000-625d7cf10000\"",
            "_attachments": "attachments/",
            "_ts": 1650294001
        },
        {
            "id": "dc4625b6cc2a3912628ad45934cf52e75170c520f132be7cc671f538e2214a54",
            "conversationId": "dc4625b6cc2a3912628ad45934cf52e75170c520f132be7cc671f538e2214a54",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-18T13:24:14.79Z",
            "endTime": "2022-04-18T13:24:14.79Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiEBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiEBwAAAAAAAA==/",
            "_etag": "\"f1034da8-0000-0100-0000-625d7cf10000\"",
            "_attachments": "attachments/",
            "_ts": 1650294001
        },
        {
            "id": "1a195aa11ddf110db513b1054ef481275e6ed747259991ec55e6e22acf530bcd",
            "conversationId": "1a195aa11ddf110db513b1054ef481275e6ed747259991ec55e6e22acf530bcd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-18T17:49:09.393Z",
            "endTime": "2022-04-18T17:49:09.393Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiFBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiFBwAAAAAAAA==/",
            "_etag": "\"f703d164-0000-0100-0000-625db5320000\"",
            "_attachments": "attachments/",
            "_ts": 1650308402
        },
        {
            "id": "68eaccce04c45c75b3a07936ce7fecd67b5dd18fcdcf7c3737ac4e870e7d3578",
            "conversationId": "68eaccce04c45c75b3a07936ce7fecd67b5dd18fcdcf7c3737ac4e870e7d3578",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-18T17:47:50.638Z",
            "endTime": "2022-04-18T17:47:50.638Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiGBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiGBwAAAAAAAA==/",
            "_etag": "\"f703d264-0000-0100-0000-625db5320000\"",
            "_attachments": "attachments/",
            "_ts": 1650308402
        },
        {
            "id": "43dca3866496e429a110397c3a9d0507caafcde3ee3b365426e226ca5f4ad776",
            "conversationId": "43dca3866496e429a110397c3a9d0507caafcde3ee3b365426e226ca5f4ad776",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-19T10:04:33.763Z",
            "endTime": "2022-04-19T10:06:43.847Z",
            "timeSpent": 130,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiHBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiHBwAAAAAAAA==/",
            "_etag": "\"0c040e4b-0000-0100-0000-625ea47e0000\"",
            "_attachments": "attachments/",
            "_ts": 1650369662
        },
        {
            "id": "863156901cbadc6469bf3812857132436f5099c057356a084ba55405716a8a4f",
            "conversationId": "863156901cbadc6469bf3812857132436f5099c057356a084ba55405716a8a4f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-04-19T10:11:16.799Z",
            "endTime": "2022-04-19T10:13:30.823Z",
            "timeSpent": 134,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiIBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiIBwAAAAAAAA==/",
            "_etag": "\"0c040f4b-0000-0100-0000-625ea47e0000\"",
            "_attachments": "attachments/",
            "_ts": 1650369662
        },
        {
            "id": "6acd3248f14dc6716561d76af5497e9f5adca0d48235af9feca1df2c3f4e6ba3",
            "conversationId": "6acd3248f14dc6716561d76af5497e9f5adca0d48235af9feca1df2c3f4e6ba3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-19T10:02:19.623Z",
            "endTime": "2022-04-19T10:02:19.623Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiJBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiJBwAAAAAAAA==/",
            "_etag": "\"0c04104b-0000-0100-0000-625ea47e0000\"",
            "_attachments": "attachments/",
            "_ts": 1650369662
        },
        {
            "id": "1f102c1fcae50217a3678d78fd5ef2a5cb8c6e443f3e4785a1498a5a13ee738c",
            "conversationId": "1f102c1fcae50217a3678d78fd5ef2a5cb8c6e443f3e4785a1498a5a13ee738c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-19T10:51:59.956Z",
            "endTime": "2022-04-19T10:51:59.956Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiKBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiKBwAAAAAAAA==/",
            "_etag": "\"0c04114b-0000-0100-0000-625ea47e0000\"",
            "_attachments": "attachments/",
            "_ts": 1650369662
        },
        {
            "id": "f77c9e4c7dacdc5e6ca23b9035f801092f8f1895f49cf729291c6964e577351e",
            "conversationId": "f77c9e4c7dacdc5e6ca23b9035f801092f8f1895f49cf729291c6964e577351e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-19T12:46:22.446Z",
            "endTime": "2022-04-19T12:46:22.446Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiLBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiLBwAAAAAAAA==/",
            "_etag": "\"0e04d5fb-0000-0100-0000-625ec0620000\"",
            "_attachments": "attachments/",
            "_ts": 1650376802
        },
        {
            "id": "a0ff72ad149d05a093bc3fbddc53ce332d79359030a1751fcf99f71e50f7f486",
            "conversationId": "a0ff72ad149d05a093bc3fbddc53ce332d79359030a1751fcf99f71e50f7f486",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-19T14:00:01.553Z",
            "endTime": "2022-04-19T14:00:08.243Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiMBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiMBwAAAAAAAA==/",
            "_etag": "\"1204965a-0000-0100-0000-625edc810000\"",
            "_attachments": "attachments/",
            "_ts": 1650384001
        },
        {
            "id": "4212f255685d3b0aad6808e22c6d44a59807e91ece147037429cbe67e764057a",
            "conversationId": "4212f255685d3b0aad6808e22c6d44a59807e91ece147037429cbe67e764057a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-19T13:37:44.327Z",
            "endTime": "2022-04-19T13:37:44.327Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiNBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiNBwAAAAAAAA==/",
            "_etag": "\"1204975a-0000-0100-0000-625edc810000\"",
            "_attachments": "attachments/",
            "_ts": 1650384001
        },
        {
            "id": "db6fc36319ffa8a4a768840e28e01d051720172cd18418617f9279b7abf4714c",
            "conversationId": "db6fc36319ffa8a4a768840e28e01d051720172cd18418617f9279b7abf4714c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-19T14:36:30.774Z",
            "endTime": "2022-04-19T14:36:30.774Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiOBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiOBwAAAAAAAA==/",
            "_etag": "\"1204905a-0000-0100-0000-625edc810000\"",
            "_attachments": "attachments/",
            "_ts": 1650384001
        },
        {
            "id": "bf8f97721fac070ee3e8e4e94d8bee811c6e2ef798029516a0c2d90ba2529fc4",
            "conversationId": "bf8f97721fac070ee3e8e4e94d8bee811c6e2ef798029516a0c2d90ba2529fc4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-19T14:05:21.648Z",
            "endTime": "2022-04-19T14:06:05.225Z",
            "timeSpent": 44,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiPBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiPBwAAAAAAAA==/",
            "_etag": "\"1204915a-0000-0100-0000-625edc810000\"",
            "_attachments": "attachments/",
            "_ts": 1650384001
        },
        {
            "id": "685df830b608138ffcd67947fb6cc2b6895acfb9899bdc14d59a3547c190dfb2",
            "conversationId": "685df830b608138ffcd67947fb6cc2b6895acfb9899bdc14d59a3547c190dfb2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-19T15:00:00Z",
            "endTime": "2022-04-19T15:19:16.082Z",
            "timeSpent": 1156,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiQBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiQBwAAAAAAAA==/",
            "_etag": "\"13049be9-0000-0100-0000-625eeacd0000\"",
            "_attachments": "attachments/",
            "_ts": 1650387661
        },
        {
            "id": "adf0a0f14e2697e02736f7b2591ee2aca2f5dcf9161d8ebbba735628e74b7402",
            "conversationId": "adf0a0f14e2697e02736f7b2591ee2aca2f5dcf9161d8ebbba735628e74b7402",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-19T15:00:00Z",
            "endTime": "2022-04-19T15:19:02.692Z",
            "timeSpent": 1143,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiRBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiRBwAAAAAAAA==/",
            "_etag": "\"13049ce9-0000-0100-0000-625eeacd0000\"",
            "_attachments": "attachments/",
            "_ts": 1650387661
        },
        {
            "id": "558507e3c4ee1c59b5aaef27454bfcc116b9d01bae3543a7031a33f9e31bec3e",
            "conversationId": "558507e3c4ee1c59b5aaef27454bfcc116b9d01bae3543a7031a33f9e31bec3e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-19T15:00:00Z",
            "endTime": "2022-04-19T15:18:32.184Z",
            "timeSpent": 1112,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiSBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiSBwAAAAAAAA==/",
            "_etag": "\"13049de9-0000-0100-0000-625eeacd0000\"",
            "_attachments": "attachments/",
            "_ts": 1650387661
        },
        {
            "id": "98f53b4df35a7f8457b56ed408c44c7c1f13409bc977cc8b3b0649cddc55b9c7",
            "conversationId": "98f53b4df35a7f8457b56ed408c44c7c1f13409bc977cc8b3b0649cddc55b9c7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-04-19T14:02:38.957Z",
            "endTime": "2022-04-19T14:04:50.088Z",
            "timeSpent": 131,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiTBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiTBwAAAAAAAA==/",
            "_etag": "\"1204955a-0000-0100-0000-625edc810000\"",
            "_attachments": "attachments/",
            "_ts": 1650384001
        },
        {
            "id": "819d45bd3fce4a4f1f0ddadb62441dfdd338369c7ce33ae4ce0d54951b53056f",
            "conversationId": "819d45bd3fce4a4f1f0ddadb62441dfdd338369c7ce33ae4ce0d54951b53056f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-19T15:58:03.053Z",
            "endTime": "2022-04-19T15:58:03.053Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiUBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiUBwAAAAAAAA==/",
            "_etag": "\"1504ef5d-0000-0100-0000-625ef8a10000\"",
            "_attachments": "attachments/",
            "_ts": 1650391201
        },
        {
            "id": "99ef09f6ce84c9eeeb4568640aeeba2ebf7655e1292ef70424dd7cb119486e64",
            "conversationId": "99ef09f6ce84c9eeeb4568640aeeba2ebf7655e1292ef70424dd7cb119486e64",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-19T16:59:56.178Z",
            "endTime": "2022-04-19T16:59:56.178Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiVBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiVBwAAAAAAAA==/",
            "_etag": "\"1504ee5d-0000-0100-0000-625ef8a10000\"",
            "_attachments": "attachments/",
            "_ts": 1650391201
        },
        {
            "id": "8b0e3a4ccae8a2fabc2e73f270c02acf32ff7b7a7ffa4419a849f72d495db754",
            "conversationId": "8b0e3a4ccae8a2fabc2e73f270c02acf32ff7b7a7ffa4419a849f72d495db754",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Runny nose",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-04-20T19:08:55.363Z",
            "endTime": "2022-04-20T19:24:04.182Z",
            "timeSpent": 909,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiWBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiWBwAAAAAAAA==/",
            "_etag": "\"0000b1f2-0000-0100-0000-626074510000\"",
            "_attachments": "attachments/",
            "_ts": 1650488401
        },
        {
            "id": "64aab66c8997dcb6f303c1dc34126bbb172965aa8eef11458584ead9f7610ce9",
            "conversationId": "64aab66c8997dcb6f303c1dc34126bbb172965aa8eef11458584ead9f7610ce9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-25T09:05:03.769Z",
            "endTime": "2022-04-25T09:05:03.769Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiXBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiXBwAAAAAAAA==/",
            "_etag": "\"04000721-0000-0100-0000-62667f310000\"",
            "_attachments": "attachments/",
            "_ts": 1650884401
        },
        {
            "id": "85637f43104da8a79ee0cb818242f478478bafb181b837bd41382385670a621f",
            "conversationId": "85637f43104da8a79ee0cb818242f478478bafb181b837bd41382385670a621f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-25T15:01:03.438Z",
            "endTime": "2022-04-25T15:01:03.438Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiYBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiYBwAAAAAAAA==/",
            "_etag": "\"0400923a-0000-0100-0000-6266d3910000\"",
            "_attachments": "attachments/",
            "_ts": 1650906001
        },
        {
            "id": "7d4b8a0292a7f86660c23729e517ec9f2b3f3c6460216d6fa82ee2fb68f4d556",
            "conversationId": "7d4b8a0292a7f86660c23729e517ec9f2b3f3c6460216d6fa82ee2fb68f4d556",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-04-25T15:01:17.698Z",
            "endTime": "2022-04-25T15:02:55.274Z",
            "timeSpent": 98,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiZBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiZBwAAAAAAAA==/",
            "_etag": "\"0400933a-0000-0100-0000-6266d3910000\"",
            "_attachments": "attachments/",
            "_ts": 1650906001
        },
        {
            "id": "66bf99b283e9625b7e25621c51f2473a106fd244410bb9de685a9025aa6a8567",
            "conversationId": "66bf99b283e9625b7e25621c51f2473a106fd244410bb9de685a9025aa6a8567",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-25T15:14:36.89Z",
            "endTime": "2022-04-25T15:14:36.89Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiaBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiaBwAAAAAAAA==/",
            "_etag": "\"0400943a-0000-0100-0000-6266d3910000\"",
            "_attachments": "attachments/",
            "_ts": 1650906001
        },
        {
            "id": "c7710c534ce7bae3a70f516df3c3c6dcdc6bc5fbc22cfc8b3ce242eb331f8683",
            "conversationId": "c7710c534ce7bae3a70f516df3c3c6dcdc6bc5fbc22cfc8b3ce242eb331f8683",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-25T15:00:39.155Z",
            "endTime": "2022-04-25T15:00:39.155Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIibBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIibBwAAAAAAAA==/",
            "_etag": "\"0400953a-0000-0100-0000-6266d3910000\"",
            "_attachments": "attachments/",
            "_ts": 1650906001
        },
        {
            "id": "6972b399bf4aa832b38ed7fb27e1fbb27371fd9bc8a0c56756dbfce59b645400",
            "conversationId": "6972b399bf4aa832b38ed7fb27e1fbb27371fd9bc8a0c56756dbfce59b645400",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-25T15:00:50.59Z",
            "endTime": "2022-04-25T15:00:50.59Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIicBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIicBwAAAAAAAA==/",
            "_etag": "\"0400963a-0000-0100-0000-6266d3910000\"",
            "_attachments": "attachments/",
            "_ts": 1650906001
        },
        {
            "id": "1239022a763510133fcb8d04f17f91eda3142edc1ffc1a04051056207f4de578",
            "conversationId": "1239022a763510133fcb8d04f17f91eda3142edc1ffc1a04051056207f4de578",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-04-27T17:16:51.366Z",
            "endTime": "2022-04-27T17:16:51.366Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIidBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIidBwAAAAAAAA==/",
            "_etag": "\"06003f5a-0000-0100-0000-626992b20000\"",
            "_attachments": "attachments/",
            "_ts": 1651086002
        },
        {
            "id": "564a1c51b7429d89e94702edb300097c166ee137d6588dff2dda2659b845159d",
            "conversationId": "564a1c51b7429d89e94702edb300097c166ee137d6588dff2dda2659b845159d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-02T09:24:22.185Z",
            "endTime": "2022-05-02T09:24:22.185Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIieBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIieBwAAAAAAAA==/",
            "_etag": "\"8e00efc5-0000-0100-0000-626fb9b30000\"",
            "_attachments": "attachments/",
            "_ts": 1651489203
        },
        {
            "id": "24188eab6382abc195bf4ff0b7a8ca1ede3a30ba0eb76c0cf29c4d98b42b8c32",
            "conversationId": "24188eab6382abc195bf4ff0b7a8ca1ede3a30ba0eb76c0cf29c4d98b42b8c32",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-02T17:56:29.952Z",
            "endTime": "2022-05-02T17:56:29.952Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIifBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIifBwAAAAAAAA==/",
            "_etag": "\"a900a120-0000-0100-0000-62702a310000\"",
            "_attachments": "attachments/",
            "_ts": 1651518001
        },
        {
            "id": "1e1e769bf99a51511e40ca341c0650779e95b807cc3a0727674ad9fa44fda0b6",
            "conversationId": "1e1e769bf99a51511e40ca341c0650779e95b807cc3a0727674ad9fa44fda0b6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-09T13:55:33.487Z",
            "endTime": "2022-05-09T13:55:33.487Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIigBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIigBwAAAAAAAA==/",
            "_etag": "\"b601d79d-0000-0100-0000-62792c720000\"",
            "_attachments": "attachments/",
            "_ts": 1652108402
        },
        {
            "id": "c0fef5b9cfcc084af4e2c116c97d4c83b35e333a9b57cacf804499fb5778397e",
            "conversationId": "c0fef5b9cfcc084af4e2c116c97d4c83b35e333a9b57cacf804499fb5778397e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-09T18:16:21.412Z",
            "endTime": "2022-05-09T18:16:21.412Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIihBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIihBwAAAAAAAA==/",
            "_etag": "\"c90175eb-0000-0100-0000-627972c20000\"",
            "_attachments": "attachments/",
            "_ts": 1652126402
        },
        {
            "id": "2514e01943d1636d3d1f9a7727cd49848a3cbac13133d8f2c867dfd1207cbb6f",
            "conversationId": "2514e01943d1636d3d1f9a7727cd49848a3cbac13133d8f2c867dfd1207cbb6f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-10T00:37:37.931Z",
            "endTime": "2022-05-10T00:37:37.931Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiiBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiiBwAAAAAAAA==/",
            "_etag": "\"d501d77e-0000-0100-0000-6279c75e0000\"",
            "_attachments": "attachments/",
            "_ts": 1652148062
        },
        {
            "id": "6b1197c8948f767427deb006dc997723d4980a02d07265b989e51d33449e9e19",
            "conversationId": "6b1197c8948f767427deb006dc997723d4980a02d07265b989e51d33449e9e19",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-10T17:49:59.584Z",
            "endTime": "2022-05-10T17:49:59.584Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIijBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIijBwAAAAAAAA==/",
            "_etag": "\"fc01acbf-0000-0100-0000-627ab6320000\"",
            "_attachments": "attachments/",
            "_ts": 1652209202
        },
        {
            "id": "df3434c1d2d88baf1fcd4f11a46eb46d78a4a90bb7953503e0d0335bf9b96639",
            "conversationId": "df3434c1d2d88baf1fcd4f11a46eb46d78a4a90bb7953503e0d0335bf9b96639",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-10T17:49:41.176Z",
            "endTime": "2022-05-10T17:49:41.176Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIikBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIikBwAAAAAAAA==/",
            "_etag": "\"fc01adbf-0000-0100-0000-627ab6320000\"",
            "_attachments": "attachments/",
            "_ts": 1652209202
        },
        {
            "id": "8b4f0934500ba4a77e6c7d1f14255b3a7cbb1ffd90186b01dc0432272c65576b",
            "conversationId": "8b4f0934500ba4a77e6c7d1f14255b3a7cbb1ffd90186b01dc0432272c65576b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [
                "Smoking",
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-10T17:48:31.479Z",
            "endTime": "2022-05-10T17:48:31.479Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIilBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIilBwAAAAAAAA==/",
            "_etag": "\"0002a298-0000-0100-0000-627ac4420000\"",
            "_attachments": "attachments/",
            "_ts": 1652212802
        },
        {
            "id": "6257680db61e4c69bb5c93db80b1b60e132f07ecd0badf8923d0c50a66a8f526",
            "conversationId": "6257680db61e4c69bb5c93db80b1b60e132f07ecd0badf8923d0c50a66a8f526",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-10T17:52:00.317Z",
            "endTime": "2022-05-10T17:52:00.317Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIimBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIimBwAAAAAAAA==/",
            "_etag": "\"0002a398-0000-0100-0000-627ac4420000\"",
            "_attachments": "attachments/",
            "_ts": 1652212802
        },
        {
            "id": "6bd5e6476c5698182c2be97b6c2b67b9c3e55998801fef30444a7757cde3f3c2",
            "conversationId": "6bd5e6476c5698182c2be97b6c2b67b9c3e55998801fef30444a7757cde3f3c2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-05-11T13:51:24.111Z",
            "endTime": "2022-05-11T13:51:24.111Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIinBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIinBwAAAAAAAA==/",
            "_etag": "\"270284f7-0000-0100-0000-627bdd820000\"",
            "_attachments": "attachments/",
            "_ts": 1652284802
        },
        {
            "id": "05a6b3bf14c072155c613093e98a04c582ad5be81d19172997c0034c43d3a42e",
            "conversationId": "05a6b3bf14c072155c613093e98a04c582ad5be81d19172997c0034c43d3a42e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-11T13:57:18.155Z",
            "endTime": "2022-05-11T13:57:18.155Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIioBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIioBwAAAAAAAA==/",
            "_etag": "\"270287f7-0000-0100-0000-627bdd820000\"",
            "_attachments": "attachments/",
            "_ts": 1652284802
        },
        {
            "id": "139458b4becec29b81222527403cb1d621617ffdf0d3bf73cee30c9e432d4593",
            "conversationId": "139458b4becec29b81222527403cb1d621617ffdf0d3bf73cee30c9e432d4593",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-11T13:53:34.965Z",
            "endTime": "2022-05-11T13:53:34.965Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIipBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIipBwAAAAAAAA==/",
            "_etag": "\"270285f7-0000-0100-0000-627bdd820000\"",
            "_attachments": "attachments/",
            "_ts": 1652284802
        },
        {
            "id": "26b3b407ef04c639befdee8960beddfe0688f6c958b042d4a3fdbc0998e55f17",
            "conversationId": "26b3b407ef04c639befdee8960beddfe0688f6c958b042d4a3fdbc0998e55f17",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-11T13:59:01.807Z",
            "endTime": "2022-05-11T13:59:01.807Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiqBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiqBwAAAAAAAA==/",
            "_etag": "\"270288f7-0000-0100-0000-627bdd820000\"",
            "_attachments": "attachments/",
            "_ts": 1652284802
        },
        {
            "id": "9e10f23f29bafd8e2f6c2002cfee4e62e3be880d6d2b7ee34918c4719fcf424f",
            "conversationId": "9e10f23f29bafd8e2f6c2002cfee4e62e3be880d6d2b7ee34918c4719fcf424f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-11T13:54:22.263Z",
            "endTime": "2022-05-11T13:54:22.263Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIirBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIirBwAAAAAAAA==/",
            "_etag": "\"270286f7-0000-0100-0000-627bdd820000\"",
            "_attachments": "attachments/",
            "_ts": 1652284802
        },
        {
            "id": "dedfc185c9fa1f4691e91913654693d3ac4af95ffa4924aea06a35e856ed2590",
            "conversationId": "dedfc185c9fa1f4691e91913654693d3ac4af95ffa4924aea06a35e856ed2590",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-11T15:00:00Z",
            "endTime": "2022-05-11T15:55:38.535Z",
            "timeSpent": 3339,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIisBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIisBwAAAAAAAA==/",
            "_etag": "\"2c026145-0000-0100-0000-627beb940000\"",
            "_attachments": "attachments/",
            "_ts": 1652288404
        },
        {
            "id": "507fb34b0fc9dfe997f1477047e74febc967b1e8875304eb540c2cd1bc13b8ec",
            "conversationId": "507fb34b0fc9dfe997f1477047e74febc967b1e8875304eb540c2cd1bc13b8ec",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-11T14:02:59.601Z",
            "endTime": "2022-05-11T14:02:59.601Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIitBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIitBwAAAAAAAA==/",
            "_etag": "\"270279f7-0000-0100-0000-627bdd820000\"",
            "_attachments": "attachments/",
            "_ts": 1652284802
        },
        {
            "id": "cb8bd7bb8b103a3a0be1ce05ea7b7cb4e34560cd08e06328b6c07e9a423427cb",
            "conversationId": "cb8bd7bb8b103a3a0be1ce05ea7b7cb4e34560cd08e06328b6c07e9a423427cb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [
                "Smoking",
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-11T14:18:13.29Z",
            "endTime": "2022-05-11T14:18:13.29Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiuBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiuBwAAAAAAAA==/",
            "_etag": "\"27027af7-0000-0100-0000-627bdd820000\"",
            "_attachments": "attachments/",
            "_ts": 1652284802
        },
        {
            "id": "bcf1506ac56c24f58f92a61b58df0b6eb8bee546351974bd1f99e196b4eab112",
            "conversationId": "bcf1506ac56c24f58f92a61b58df0b6eb8bee546351974bd1f99e196b4eab112",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-11T15:00:00Z",
            "endTime": "2022-05-11T15:17:06.186Z",
            "timeSpent": 1026,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIivBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIivBwAAAAAAAA==/",
            "_etag": "\"2c026245-0000-0100-0000-627beb940000\"",
            "_attachments": "attachments/",
            "_ts": 1652288404
        },
        {
            "id": "6a9ec055defe67312eb4a999cce022d824b636c760a9c1f4385e421659cc2a7b",
            "conversationId": "6a9ec055defe67312eb4a999cce022d824b636c760a9c1f4385e421659cc2a7b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-11T15:00:00Z",
            "endTime": "2022-05-11T15:16:35.419Z",
            "timeSpent": 995,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiwBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiwBwAAAAAAAA==/",
            "_etag": "\"2c026345-0000-0100-0000-627beb940000\"",
            "_attachments": "attachments/",
            "_ts": 1652288404
        },
        {
            "id": "ece7abd9d64b8332a5abfd5fc5a98c14f1b3394c7341dca5ad028731af3cc825",
            "conversationId": "ece7abd9d64b8332a5abfd5fc5a98c14f1b3394c7341dca5ad028731af3cc825",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-11T14:10:58.736Z",
            "endTime": "2022-05-11T14:10:58.736Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIixBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIixBwAAAAAAAA==/",
            "_etag": "\"27027ef7-0000-0100-0000-627bdd820000\"",
            "_attachments": "attachments/",
            "_ts": 1652284802
        },
        {
            "id": "6f4d191925b61b89dc657519b983c19886392d514dab64db3ab85b83893a65c1",
            "conversationId": "6f4d191925b61b89dc657519b983c19886392d514dab64db3ab85b83893a65c1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-11T14:12:21.488Z",
            "endTime": "2022-05-11T14:12:21.488Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiyBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiyBwAAAAAAAA==/",
            "_etag": "\"27027ff7-0000-0100-0000-627bdd820000\"",
            "_attachments": "attachments/",
            "_ts": 1652284802
        },
        {
            "id": "1f745aeff602bf3bbb3d0d2d4aa8e5ba5a8fbe0a2e5e9f292d5af22328d9a32a",
            "conversationId": "1f745aeff602bf3bbb3d0d2d4aa8e5ba5a8fbe0a2e5e9f292d5af22328d9a32a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-11T14:06:16.955Z",
            "endTime": "2022-05-11T14:06:16.955Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIizBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIizBwAAAAAAAA==/",
            "_etag": "\"270280f7-0000-0100-0000-627bdd820000\"",
            "_attachments": "attachments/",
            "_ts": 1652284802
        },
        {
            "id": "c2d665fff19aab0b69b963c6969b87f49afbf2765cb6785a83f3059dfd0756e1",
            "conversationId": "c2d665fff19aab0b69b963c6969b87f49afbf2765cb6785a83f3059dfd0756e1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-11T14:05:17.109Z",
            "endTime": "2022-05-11T14:05:17.109Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi0BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi0BwAAAAAAAA==/",
            "_etag": "\"270281f7-0000-0100-0000-627bdd820000\"",
            "_attachments": "attachments/",
            "_ts": 1652284802
        },
        {
            "id": "29b588ff65650479b8b7b314beac6ce4d316151eeefb88143666c69c8b6ce89b",
            "conversationId": "29b588ff65650479b8b7b314beac6ce4d316151eeefb88143666c69c8b6ce89b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-11T14:07:51.508Z",
            "endTime": "2022-05-11T14:07:51.508Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi1BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi1BwAAAAAAAA==/",
            "_etag": "\"270282f7-0000-0100-0000-627bdd820000\"",
            "_attachments": "attachments/",
            "_ts": 1652284802
        },
        {
            "id": "581e2e189fde983489e12e7aa3648aac936ced276a88abc1cf742150ebbbbec6",
            "conversationId": "581e2e189fde983489e12e7aa3648aac936ced276a88abc1cf742150ebbbbec6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-11T14:06:31.436Z",
            "endTime": "2022-05-11T14:06:31.436Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi2BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi2BwAAAAAAAA==/",
            "_etag": "\"270283f7-0000-0100-0000-627bdd820000\"",
            "_attachments": "attachments/",
            "_ts": 1652284802
        },
        {
            "id": "294b1bc0efd66aa170a5c9bee33ee53e85573198d0bd3574b603bd24ef0f7441",
            "conversationId": "294b1bc0efd66aa170a5c9bee33ee53e85573198d0bd3574b603bd24ef0f7441",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-05-11T16:08:46.856Z",
            "endTime": "2022-05-11T16:08:46.856Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi3BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi3BwAAAAAAAA==/",
            "_etag": "\"30020f8d-0000-0100-0000-627bf9a30000\"",
            "_attachments": "attachments/",
            "_ts": 1652292003
        },
        {
            "id": "a35d48ac9d36435594c00c81e90739bb42a0c0363a0f0e81780f770fba28f4c0",
            "conversationId": "a35d48ac9d36435594c00c81e90739bb42a0c0363a0f0e81780f770fba28f4c0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-11T16:21:59.372Z",
            "endTime": "2022-05-11T16:21:59.372Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi4BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi4BwAAAAAAAA==/",
            "_etag": "\"3002108d-0000-0100-0000-627bf9a30000\"",
            "_attachments": "attachments/",
            "_ts": 1652292003
        },
        {
            "id": "696e6481f4ce3b40539e8898537becb0119c9372f99ee19adc821befdfbad912",
            "conversationId": "696e6481f4ce3b40539e8898537becb0119c9372f99ee19adc821befdfbad912",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-05-11T20:38:49.993Z",
            "endTime": "2022-05-11T20:38:49.993Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi5BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi5BwAAAAAAAA==/",
            "_etag": "\"4102fa62-0000-0100-0000-627c3ff10000\"",
            "_attachments": "attachments/",
            "_ts": 1652310001
        },
        {
            "id": "474a9430a1076fab2504920980de487f5bfebf055e67d5434591d453e5d163b7",
            "conversationId": "474a9430a1076fab2504920980de487f5bfebf055e67d5434591d453e5d163b7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-05-11T20:27:40.836Z",
            "endTime": "2022-05-11T20:27:40.836Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi6BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi6BwAAAAAAAA==/",
            "_etag": "\"3f023659-0000-0100-0000-627c31e30000\"",
            "_attachments": "attachments/",
            "_ts": 1652306403
        },
        {
            "id": "459587e8ff6aa6f00326cacb93df01e1ec599c4f84c350cd4cfaa2a159f080c4",
            "conversationId": "459587e8ff6aa6f00326cacb93df01e1ec599c4f84c350cd4cfaa2a159f080c4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-11T20:22:44.45Z",
            "endTime": "2022-05-11T20:22:44.45Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi7BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi7BwAAAAAAAA==/",
            "_etag": "\"3f023759-0000-0100-0000-627c31e30000\"",
            "_attachments": "attachments/",
            "_ts": 1652306403
        },
        {
            "id": "8ee111b92aba3be552533e051d68544737905017425434ece0e2ae8ad2a8b175",
            "conversationId": "8ee111b92aba3be552533e051d68544737905017425434ece0e2ae8ad2a8b175",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Runny nose",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-11T20:13:21.788Z",
            "endTime": "2022-05-11T20:13:21.788Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi8BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi8BwAAAAAAAA==/",
            "_etag": "\"3f023859-0000-0100-0000-627c31e30000\"",
            "_attachments": "attachments/",
            "_ts": 1652306403
        },
        {
            "id": "879ace6333dbac664e81f15a05c2c10e70c59fd54d774571b9b788b2f6e6918a",
            "conversationId": "879ace6333dbac664e81f15a05c2c10e70c59fd54d774571b9b788b2f6e6918a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-11T20:08:35.208Z",
            "endTime": "2022-05-11T20:08:35.208Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi9BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi9BwAAAAAAAA==/",
            "_etag": "\"3f023959-0000-0100-0000-627c31e30000\"",
            "_attachments": "attachments/",
            "_ts": 1652306403
        },
        {
            "id": "3221bb68e31b64c13a0e4dfd541e3ea58b9745145f9709ac6935b06b72eb86f6",
            "conversationId": "3221bb68e31b64c13a0e4dfd541e3ea58b9745145f9709ac6935b06b72eb86f6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-11T20:05:42.041Z",
            "endTime": "2022-05-11T20:05:42.041Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi+BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi+BwAAAAAAAA==/",
            "_etag": "\"3f023a59-0000-0100-0000-627c31e30000\"",
            "_attachments": "attachments/",
            "_ts": 1652306403
        },
        {
            "id": "c354a27314320b3f1dc4d2119e0461788374fd847eec6fed69a3edf71781a9cd",
            "conversationId": "c354a27314320b3f1dc4d2119e0461788374fd847eec6fed69a3edf71781a9cd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-11T20:04:18.962Z",
            "endTime": "2022-05-11T20:04:18.962Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi-BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi-BwAAAAAAAA==/",
            "_etag": "\"3f023b59-0000-0100-0000-627c31e30000\"",
            "_attachments": "attachments/",
            "_ts": 1652306403
        },
        {
            "id": "0655c5911c6b978049578d6f6ef5d7d5fb382a4280eb702dfd76208877a48683",
            "conversationId": "0655c5911c6b978049578d6f6ef5d7d5fb382a4280eb702dfd76208877a48683",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-05-12T12:47:10.084Z",
            "endTime": "2022-05-12T12:47:10.084Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjABwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjABwAAAAAAAA==/",
            "_etag": "\"5b02edac-0000-0100-0000-627d20f20000\"",
            "_attachments": "attachments/",
            "_ts": 1652367602
        },
        {
            "id": "6a20e4f15b4f483ce9298176e3d354242885823a97df934a9d29a75f08b38d36",
            "conversationId": "6a20e4f15b4f483ce9298176e3d354242885823a97df934a9d29a75f08b38d36",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-05-12T12:49:55.294Z",
            "endTime": "2022-05-12T12:49:55.294Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjBBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjBBwAAAAAAAA==/",
            "_etag": "\"5b02f0ac-0000-0100-0000-627d20f20000\"",
            "_attachments": "attachments/",
            "_ts": 1652367602
        },
        {
            "id": "75dfaf4c855c367ffac4968110ab3422f9a28baf43c352c91263698ac9ca6f93",
            "conversationId": "75dfaf4c855c367ffac4968110ab3422f9a28baf43c352c91263698ac9ca6f93",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T12:47:29.362Z",
            "endTime": "2022-05-12T12:47:29.362Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjCBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjCBwAAAAAAAA==/",
            "_etag": "\"5b02efac-0000-0100-0000-627d20f20000\"",
            "_attachments": "attachments/",
            "_ts": 1652367602
        },
        {
            "id": "2cd9657bf961f3a4a2833a856888f0f2b866d6c079908ae0532e8a3a2b6d8b14",
            "conversationId": "2cd9657bf961f3a4a2833a856888f0f2b866d6c079908ae0532e8a3a2b6d8b14",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Abdominal pain",
                " unknown character"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T12:52:44.532Z",
            "endTime": "2022-05-12T12:52:44.532Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjDBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjDBwAAAAAAAA==/",
            "_etag": "\"5702af21-0000-0100-0000-627d12e20000\"",
            "_attachments": "attachments/",
            "_ts": 1652364002
        },
        {
            "id": "9efe18e7e10dd9fb479913b6e070575b8e6ef8f1bb42b4a9e2c9fadf3ba6c3df",
            "conversationId": "9efe18e7e10dd9fb479913b6e070575b8e6ef8f1bb42b4a9e2c9fadf3ba6c3df",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Stiffness of spine in the morning",
                "Calf pain",
                "Headache",
                "Heartburn",
                "Dry cough",
                "Shoulder pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-05-12T14:54:58.018Z",
            "endTime": "2022-05-12T14:54:58.018Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": "Detractor",
            "npsScore": 5,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjEBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjEBwAAAAAAAA==/",
            "_etag": "\"60025d26-0000-0100-0000-627d2f030000\"",
            "_attachments": "attachments/",
            "_ts": 1652371203
        },
        {
            "id": "9dddc033663b86b20feafd91b41a0f9d4b6d0ae1cfd4975c6005a5f6857105de",
            "conversationId": "9dddc033663b86b20feafd91b41a0f9d4b6d0ae1cfd4975c6005a5f6857105de",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-05-12T14:41:06.611Z",
            "endTime": "2022-05-12T14:41:06.611Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjFBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjFBwAAAAAAAA==/",
            "_etag": "\"60025e26-0000-0100-0000-627d2f030000\"",
            "_attachments": "attachments/",
            "_ts": 1652371203
        },
        {
            "id": "50dafb7f0b479b16a49c98921fed979b2432e5835ffa7eff79db19174df490ef",
            "conversationId": "50dafb7f0b479b16a49c98921fed979b2432e5835ffa7eff79db19174df490ef",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T14:56:09.034Z",
            "endTime": "2022-05-12T14:56:09.034Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjGBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjGBwAAAAAAAA==/",
            "_etag": "\"60025f26-0000-0100-0000-627d2f030000\"",
            "_attachments": "attachments/",
            "_ts": 1652371203
        },
        {
            "id": "7f2557fd7847b8edf191d14528c549a5613477f89f6919e13028734c1ff8c63b",
            "conversationId": "7f2557fd7847b8edf191d14528c549a5613477f89f6919e13028734c1ff8c63b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T14:56:17.548Z",
            "endTime": "2022-05-12T14:56:17.548Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjHBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjHBwAAAAAAAA==/",
            "_etag": "\"60026026-0000-0100-0000-627d2f030000\"",
            "_attachments": "attachments/",
            "_ts": 1652371203
        },
        {
            "id": "cc1ed9dd2885f61eeae05df49e0fc23258a0444189c0ddf2b221bba4b2f25821",
            "conversationId": "cc1ed9dd2885f61eeae05df49e0fc23258a0444189c0ddf2b221bba4b2f25821",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-05-12T14:56:22.374Z",
            "endTime": "2022-05-12T15:10:02.701Z",
            "timeSpent": 820,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjIBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjIBwAAAAAAAA==/",
            "_etag": "\"6402784a-0000-0100-0000-627d3d120000\"",
            "_attachments": "attachments/",
            "_ts": 1652374802
        },
        {
            "id": "0990e8bccc0482b27d56fa11c430f862867c4a10fd24a08be4330f27b3b2947e",
            "conversationId": "0990e8bccc0482b27d56fa11c430f862867c4a10fd24a08be4330f27b3b2947e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T14:42:00.264Z",
            "endTime": "2022-05-12T14:42:00.264Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjJBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjJBwAAAAAAAA==/",
            "_etag": "\"60026226-0000-0100-0000-627d2f030000\"",
            "_attachments": "attachments/",
            "_ts": 1652371203
        },
        {
            "id": "ac7bf84beacac6e8fed279472e097c14879bdf035f334092cdeafa87e39e1465",
            "conversationId": "ac7bf84beacac6e8fed279472e097c14879bdf035f334092cdeafa87e39e1465",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T15:59:14.147Z",
            "endTime": "2022-05-12T15:59:14.147Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjKBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjKBwAAAAAAAA==/",
            "_etag": "\"6402774a-0000-0100-0000-627d3d120000\"",
            "_attachments": "attachments/",
            "_ts": 1652374802
        },
        {
            "id": "0e53bb8f2be7b80111db84d17095485ad61a79c977dd775ff77c6d8a4756db74",
            "conversationId": "0e53bb8f2be7b80111db84d17095485ad61a79c977dd775ff77c6d8a4756db74",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T15:29:38.562Z",
            "endTime": "2022-05-12T15:29:38.562Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjLBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjLBwAAAAAAAA==/",
            "_etag": "\"6402794a-0000-0100-0000-627d3d120000\"",
            "_attachments": "attachments/",
            "_ts": 1652374802
        },
        {
            "id": "1ff63d335f118200ae58935baeea20d7d9369d7025df607ae64f39e3744f5bee",
            "conversationId": "1ff63d335f118200ae58935baeea20d7d9369d7025df607ae64f39e3744f5bee",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T15:19:52.03Z",
            "endTime": "2022-05-12T15:19:52.03Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjMBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjMBwAAAAAAAA==/",
            "_etag": "\"64027b4a-0000-0100-0000-627d3d120000\"",
            "_attachments": "attachments/",
            "_ts": 1652374802
        },
        {
            "id": "cc59f4910ba5c66e32b564590b8310bb5ba3abed2fe65f565e434a9b2405d38c",
            "conversationId": "cc59f4910ba5c66e32b564590b8310bb5ba3abed2fe65f565e434a9b2405d38c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T15:25:57.244Z",
            "endTime": "2022-05-12T15:25:57.244Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjNBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjNBwAAAAAAAA==/",
            "_etag": "\"64027c4a-0000-0100-0000-627d3d120000\"",
            "_attachments": "attachments/",
            "_ts": 1652374802
        },
        {
            "id": "82432a8c2afbe7abfa04d9345803887975f7398b0daa66d6972c4d7a80d31fcf",
            "conversationId": "82432a8c2afbe7abfa04d9345803887975f7398b0daa66d6972c4d7a80d31fcf",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T15:20:13.778Z",
            "endTime": "2022-05-12T15:20:13.778Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjOBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjOBwAAAAAAAA==/",
            "_etag": "\"64027d4a-0000-0100-0000-627d3d120000\"",
            "_attachments": "attachments/",
            "_ts": 1652374802
        },
        {
            "id": "c62b977f63e2007e08578c6f513e0ff14e3b76e9ec80d0cdcec754ec69305a2b",
            "conversationId": "c62b977f63e2007e08578c6f513e0ff14e3b76e9ec80d0cdcec754ec69305a2b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T15:20:06.058Z",
            "endTime": "2022-05-12T15:20:06.058Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjPBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjPBwAAAAAAAA==/",
            "_etag": "\"64027e4a-0000-0100-0000-627d3d120000\"",
            "_attachments": "attachments/",
            "_ts": 1652374802
        },
        {
            "id": "4993c9810b3414f4447a121641036324682bc7e5358c918919a3ef64426c9654",
            "conversationId": "4993c9810b3414f4447a121641036324682bc7e5358c918919a3ef64426c9654",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T15:20:23.696Z",
            "endTime": "2022-05-12T15:20:23.696Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjQBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjQBwAAAAAAAA==/",
            "_etag": "\"64027f4a-0000-0100-0000-627d3d120000\"",
            "_attachments": "attachments/",
            "_ts": 1652374802
        },
        {
            "id": "7eb717e375b480e7541a9c8592802e4889c0f3907fe438f98b6316d2d3b92ec8",
            "conversationId": "7eb717e375b480e7541a9c8592802e4889c0f3907fe438f98b6316d2d3b92ec8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [
                "Smoking",
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-12T16:06:10.483Z",
            "endTime": "2022-05-12T16:06:10.483Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjRBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjRBwAAAAAAAA==/",
            "_etag": "\"6802ac6e-0000-0100-0000-627d4b230000\"",
            "_attachments": "attachments/",
            "_ts": 1652378403
        },
        {
            "id": "07ca7495c2dc7e2473951f9def39fd8bfc2520ee133a370cb7e6549522f8a840",
            "conversationId": "07ca7495c2dc7e2473951f9def39fd8bfc2520ee133a370cb7e6549522f8a840",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T16:00:52.368Z",
            "endTime": "2022-05-12T16:00:52.368Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjSBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjSBwAAAAAAAA==/",
            "_etag": "\"6802ad6e-0000-0100-0000-627d4b230000\"",
            "_attachments": "attachments/",
            "_ts": 1652378403
        },
        {
            "id": "20d7fc58a0d37210624b374bb58157e9b4877d395a192725f8b900c9a909e6d5",
            "conversationId": "20d7fc58a0d37210624b374bb58157e9b4877d395a192725f8b900c9a909e6d5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Severe joint pain after an injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-05-12T17:57:18.737Z",
            "endTime": "2022-05-12T17:57:18.737Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjTBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjTBwAAAAAAAA==/",
            "_etag": "\"7002d643-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "b6075d43c44f029a9573bc6a3ed7a23770ea464591e1e485a28549e8089ef23e",
            "conversationId": "b6075d43c44f029a9573bc6a3ed7a23770ea464591e1e485a28549e8089ef23e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will go to the emergency room",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-05-12T17:49:56.958Z",
            "endTime": "2022-05-12T17:49:56.958Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjUBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjUBwAAAAAAAA==/",
            "_etag": "\"6c025457-0000-0100-0000-627d59320000\"",
            "_attachments": "attachments/",
            "_ts": 1652382002
        },
        {
            "id": "9cebb0b9d0d6cdba9597ce293f9b97b6b0b48fd7403811fdc4c974b9f3522f2a",
            "conversationId": "9cebb0b9d0d6cdba9597ce293f9b97b6b0b48fd7403811fdc4c974b9f3522f2a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the emergency room",
            "patientPlansTo": "Contact me within two business days, 508-555-5555, false",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-05-12T17:53:19.205Z",
            "endTime": "2022-05-12T17:53:19.205Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjVBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjVBwAAAAAAAA==/",
            "_etag": "\"6c025557-0000-0100-0000-627d59320000\"",
            "_attachments": "attachments/",
            "_ts": 1652382002
        },
        {
            "id": "6f55b332728b17c6d93a48b844486909c972fd3a1e7afba5da9674bcd3d59c46",
            "conversationId": "6f55b332728b17c6d93a48b844486909c972fd3a1e7afba5da9674bcd3d59c46",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-05-12T17:59:58.94Z",
            "endTime": "2022-05-12T18:01:30.172Z",
            "timeSpent": 91,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjWBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjWBwAAAAAAAA==/",
            "_etag": "\"7002c143-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "6aa6a74a976d97d021a9f1e2a7b30dbc69d14096bf8c5a9fc95db8ba3abc628c",
            "conversationId": "6aa6a74a976d97d021a9f1e2a7b30dbc69d14096bf8c5a9fc95db8ba3abc628c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T17:59:22.189Z",
            "endTime": "2022-05-12T17:59:22.189Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjXBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjXBwAAAAAAAA==/",
            "_etag": "\"6c025757-0000-0100-0000-627d59320000\"",
            "_attachments": "attachments/",
            "_ts": 1652382002
        },
        {
            "id": "9c10fb6c4b4eb460652a24ff675078ace53c4b69a4d92fe266f0316dd77424f2",
            "conversationId": "9c10fb6c4b4eb460652a24ff675078ace53c4b69a4d92fe266f0316dd77424f2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T17:53:34.033Z",
            "endTime": "2022-05-12T17:53:34.033Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjYBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjYBwAAAAAAAA==/",
            "_etag": "\"6c025857-0000-0100-0000-627d59320000\"",
            "_attachments": "attachments/",
            "_ts": 1652382002
        },
        {
            "id": "942a146fbed3092c269bd246afd227cd944412c600c6604b8a9596c0f8f79597",
            "conversationId": "942a146fbed3092c269bd246afd227cd944412c600c6604b8a9596c0f8f79597",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T17:53:43.123Z",
            "endTime": "2022-05-12T17:53:43.123Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjZBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjZBwAAAAAAAA==/",
            "_etag": "\"6c025957-0000-0100-0000-627d59320000\"",
            "_attachments": "attachments/",
            "_ts": 1652382002
        },
        {
            "id": "ff7423b4d234a9945638b8d63a64e144c5a216f18325550ca58287adb883753b",
            "conversationId": "ff7423b4d234a9945638b8d63a64e144c5a216f18325550ca58287adb883753b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T17:34:31.831Z",
            "endTime": "2022-05-12T17:34:31.831Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjaBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjaBwAAAAAAAA==/",
            "_etag": "\"7002d543-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "28d12b385439554766a7714f8a0a1d30a69cd5181d82afcf8b89e0b24fc1e1e9",
            "conversationId": "28d12b385439554766a7714f8a0a1d30a69cd5181d82afcf8b89e0b24fc1e1e9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T17:59:13.645Z",
            "endTime": "2022-05-12T17:59:13.645Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjbBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjbBwAAAAAAAA==/",
            "_etag": "\"6c025b57-0000-0100-0000-627d59320000\"",
            "_attachments": "attachments/",
            "_ts": 1652382002
        },
        {
            "id": "43c33cd8a8f47294c63483c9f2e8ad5f6937445bb067318c1a84ce234d180f41",
            "conversationId": "43c33cd8a8f47294c63483c9f2e8ad5f6937445bb067318c1a84ce234d180f41",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T17:59:28.945Z",
            "endTime": "2022-05-12T17:59:28.945Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjcBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjcBwAAAAAAAA==/",
            "_etag": "\"6c025c57-0000-0100-0000-627d59320000\"",
            "_attachments": "attachments/",
            "_ts": 1652382002
        },
        {
            "id": "ab77dea1b1598ba89eb7a6e3c796ebbd0842ad74a097ce668839d58f1b66bd40",
            "conversationId": "ab77dea1b1598ba89eb7a6e3c796ebbd0842ad74a097ce668839d58f1b66bd40",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T17:47:36.937Z",
            "endTime": "2022-05-12T17:47:36.937Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjdBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjdBwAAAAAAAA==/",
            "_etag": "\"6c025d57-0000-0100-0000-627d59320000\"",
            "_attachments": "attachments/",
            "_ts": 1652382002
        },
        {
            "id": "c6607f323a84324ffa5e8c6874128f7b14082a31bec5da045b0c99e75e697ddf",
            "conversationId": "c6607f323a84324ffa5e8c6874128f7b14082a31bec5da045b0c99e75e697ddf",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Wrist pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-05-12T18:24:53.551Z",
            "endTime": "2022-05-12T18:24:53.551Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjeBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjeBwAAAAAAAA==/",
            "_etag": "\"7002c343-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "8ae3f2b2b314fea13894adb3305d7db43f9386dde9ed8db5a1325983959659f5",
            "conversationId": "8ae3f2b2b314fea13894adb3305d7db43f9386dde9ed8db5a1325983959659f5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Fever",
                "Cough",
                "Stuffy Nose",
                "Sore throat"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T18:04:47.963Z",
            "endTime": "2022-05-12T18:04:47.963Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjfBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjfBwAAAAAAAA==/",
            "_etag": "\"7002c443-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "383c1f7bf351f852bb65922d7f2ecb0cae66b522f7ff6a026137afb47bf5b45b",
            "conversationId": "383c1f7bf351f852bb65922d7f2ecb0cae66b522f7ff6a026137afb47bf5b45b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Shortness of breath",
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the emergency room",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-05-12T18:12:43.502Z",
            "endTime": "2022-05-12T18:12:43.502Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjgBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjgBwAAAAAAAA==/",
            "_etag": "\"7002c543-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "2545084321c9889a8ed19b3830392322fbe7ba684ded728a0c450bb7afb1f880",
            "conversationId": "2545084321c9889a8ed19b3830392322fbe7ba684ded728a0c450bb7afb1f880",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-12T18:31:01.009Z",
            "endTime": "2022-05-12T18:31:01.009Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjhBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjhBwAAAAAAAA==/",
            "_etag": "\"7302b1dd-0000-0100-0000-627d75530000\"",
            "_attachments": "attachments/",
            "_ts": 1652389203
        },
        {
            "id": "56aa0d27cfae1487e2dac233512d468d47ca1048bb27c1ed13d37890b8e2c52f",
            "conversationId": "56aa0d27cfae1487e2dac233512d468d47ca1048bb27c1ed13d37890b8e2c52f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Shortness of breath"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Call 9-1-1",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-05-12T18:15:59.451Z",
            "endTime": "2022-05-12T18:15:59.451Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjiBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjiBwAAAAAAAA==/",
            "_etag": "\"7002c743-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "51755509ef5950d6e5fbfc50634f54b9005ac4a8529c9cda4b80b1fe90595ffa",
            "conversationId": "51755509ef5950d6e5fbfc50634f54b9005ac4a8529c9cda4b80b1fe90595ffa",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T18:26:09.794Z",
            "endTime": "2022-05-12T18:26:09.794Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjjBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjjBwAAAAAAAA==/",
            "_etag": "\"7002c843-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
},
    
        {
            "id": "08f769441fea89b7a6158d0c167dc580c5fcf51ee8717a0d664215f7e4dcfb40",
            "conversationId": "08f769441fea89b7a6158d0c167dc580c5fcf51ee8717a0d664215f7e4dcfb40",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T18:16:10.071Z",
            "endTime": "2022-05-12T18:16:10.071Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjkBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjkBwAAAAAAAA==/",
            "_etag": "\"7002c943-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "0652462128d6a8059e4a7dbfe4a01300537bfac2fc294ebd06a07ce653463329",
            "conversationId": "0652462128d6a8059e4a7dbfe4a01300537bfac2fc294ebd06a07ce653463329",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-12T18:21:43.499Z",
            "endTime": "2022-05-12T18:21:43.499Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjlBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjlBwAAAAAAAA==/",
            "_etag": "\"7002ca43-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "2419f89e21183572b9f396b3ffc5927cc630f9f1ec1b47b99a3f60ab6ec069eb",
            "conversationId": "2419f89e21183572b9f396b3ffc5927cc630f9f1ec1b47b99a3f60ab6ec069eb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T18:26:50.38Z",
            "endTime": "2022-05-12T18:26:50.38Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjmBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjmBwAAAAAAAA==/",
            "_etag": "\"7002cb43-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "ad371aa8e6c3267556ce8c2181d64656be75b52fb901e4aa8082acbdc8372db2",
            "conversationId": "ad371aa8e6c3267556ce8c2181d64656be75b52fb901e4aa8082acbdc8372db2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T18:28:01.891Z",
            "endTime": "2022-05-12T18:28:01.891Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjnBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjnBwAAAAAAAA==/",
            "_etag": "\"7002cc43-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "e2d1d439c8c71378e0d013541bb390ae060b4aa55e696ffa19f9bbe6544cf8f8",
            "conversationId": "e2d1d439c8c71378e0d013541bb390ae060b4aa55e696ffa19f9bbe6544cf8f8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T18:21:56.55Z",
            "endTime": "2022-05-12T18:21:56.55Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjoBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjoBwAAAAAAAA==/",
            "_etag": "\"7002cd43-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "952787aa9a4b48966d3a8651b61b9f89730df38f5e4f338b441a3d503db91640",
            "conversationId": "952787aa9a4b48966d3a8651b61b9f89730df38f5e4f338b441a3d503db91640",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T18:27:28.213Z",
            "endTime": "2022-05-12T18:27:28.213Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjpBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjpBwAAAAAAAA==/",
            "_etag": "\"7002ce43-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "0defd8ca3d46c8f001117875a173d96521fbb184e799d254e94c9b3f596151a9",
            "conversationId": "0defd8ca3d46c8f001117875a173d96521fbb184e799d254e94c9b3f596151a9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T18:27:12.205Z",
            "endTime": "2022-05-12T18:27:12.205Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjqBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjqBwAAAAAAAA==/",
            "_etag": "\"7002d043-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "3be15dfc373514b95690b57fc48ccd196366827fbac2c3df6abb42ec6e315f7c",
            "conversationId": "3be15dfc373514b95690b57fc48ccd196366827fbac2c3df6abb42ec6e315f7c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T18:27:12.569Z",
            "endTime": "2022-05-12T18:27:12.569Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjrBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjrBwAAAAAAAA==/",
            "_etag": "\"7002d143-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "c3351c5787f1e0dc462467591c5f022c9c36054e34e1c784b78d1a8ab8f7a542",
            "conversationId": "c3351c5787f1e0dc462467591c5f022c9c36054e34e1c784b78d1a8ab8f7a542",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T18:01:56.773Z",
            "endTime": "2022-05-12T18:01:56.773Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjsBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjsBwAAAAAAAA==/",
            "_etag": "\"7002d243-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "dda70a99a23761e20e443f9646b03a5a65a65ea6735503eb4257c7117fa6ca0b",
            "conversationId": "dda70a99a23761e20e443f9646b03a5a65a65ea6735503eb4257c7117fa6ca0b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T18:27:20.668Z",
            "endTime": "2022-05-12T18:27:20.668Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjtBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjtBwAAAAAAAA==/",
            "_etag": "\"7002d343-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "e1bc1390c1b1501435e5b052bed97cd81c50a46dc0adbfaeef2db1ff3bab2e61",
            "conversationId": "e1bc1390c1b1501435e5b052bed97cd81c50a46dc0adbfaeef2db1ff3bab2e61",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T18:12:49.973Z",
            "endTime": "2022-05-12T18:12:49.973Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjuBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjuBwAAAAAAAA==/",
            "_etag": "\"7002d443-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "76891ae82e69a2059b191ed21f758b84f9f81aef832e16fdfcfccefaad709809",
            "conversationId": "76891ae82e69a2059b191ed21f758b84f9f81aef832e16fdfcfccefaad709809",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Runny nose",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-12T18:34:02.416Z",
            "endTime": "2022-05-12T18:34:02.416Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjvBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjvBwAAAAAAAA==/",
            "_etag": "\"7302b4dd-0000-0100-0000-627d75530000\"",
            "_attachments": "attachments/",
            "_ts": 1652389203
        },
        {
            "id": "405ddcd81cdab6f520bdb32f27240d54f220905b89d754b46f4e042348122571",
            "conversationId": "405ddcd81cdab6f520bdb32f27240d54f220905b89d754b46f4e042348122571",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T18:48:25.594Z",
            "endTime": "2022-05-12T18:48:25.594Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjwBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjwBwAAAAAAAA==/",
            "_etag": "\"7002d843-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "e6afb6adcf18660c85f835c9211b07216d57e49f1ccf1767912972b4fed69328",
            "conversationId": "e6afb6adcf18660c85f835c9211b07216d57e49f1ccf1767912972b4fed69328",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T18:31:09.203Z",
            "endTime": "2022-05-12T18:31:09.203Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjxBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjxBwAAAAAAAA==/",
            "_etag": "\"7302b2dd-0000-0100-0000-627d75530000\"",
            "_attachments": "attachments/",
            "_ts": 1652389203
        },
        {
            "id": "3cfb8782832383193c7609ff34494053e872f5504c233e8f653d8bd180cb9dd8",
            "conversationId": "3cfb8782832383193c7609ff34494053e872f5504c233e8f653d8bd180cb9dd8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [
                "Smoking",
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-12T18:49:59.398Z",
            "endTime": "2022-05-12T18:49:59.398Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjyBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjyBwAAAAAAAA==/",
            "_etag": "\"7302b5dd-0000-0100-0000-627d75530000\"",
            "_attachments": "attachments/",
            "_ts": 1652389203
        },
        {
            "id": "b68d184842b5ebe2bbf1d65d43b601e8bb33910a9fde06de4acf31aface51bf2",
            "conversationId": "b68d184842b5ebe2bbf1d65d43b601e8bb33910a9fde06de4acf31aface51bf2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T18:31:20.655Z",
            "endTime": "2022-05-12T18:31:20.655Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjzBwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjzBwAAAAAAAA==/",
            "_etag": "\"7002db43-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "0c141f4163ac1432dc047aee78873e1ca4c2e63bb1900969303c585173afa22d",
            "conversationId": "0c141f4163ac1432dc047aee78873e1ca4c2e63bb1900969303c585173afa22d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T18:31:46.079Z",
            "endTime": "2022-05-12T18:31:46.079Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj0BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj0BwAAAAAAAA==/",
            "_etag": "\"7302b3dd-0000-0100-0000-627d75530000\"",
            "_attachments": "attachments/",
            "_ts": 1652389203
        },
        {
            "id": "7b0ca1ee817372d22a976cc8bbba87e6da0668666c08bfef34883e0dc49ac214",
            "conversationId": "7b0ca1ee817372d22a976cc8bbba87e6da0668666c08bfef34883e0dc49ac214",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T18:31:01.655Z",
            "endTime": "2022-05-12T18:31:01.655Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj1BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj1BwAAAAAAAA==/",
            "_etag": "\"7002dd43-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "38e379a0929af11b598033033b6da448f90550f000318c1f61d4efab4b32fbd8",
            "conversationId": "38e379a0929af11b598033033b6da448f90550f000318c1f61d4efab4b32fbd8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T18:30:26.679Z",
            "endTime": "2022-05-12T18:30:26.679Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj2BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj2BwAAAAAAAA==/",
            "_etag": "\"7002de43-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "bc46f58d039f611a69a0ed18973077fb5d6bc6191a1acbc981b6bf25248a3542",
            "conversationId": "bc46f58d039f611a69a0ed18973077fb5d6bc6191a1acbc981b6bf25248a3542",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T18:35:31.833Z",
            "endTime": "2022-05-12T18:35:31.833Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj3BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj3BwAAAAAAAA==/",
            "_etag": "\"7002df43-0000-0100-0000-627d67430000\"",
            "_attachments": "attachments/",
            "_ts": 1652385603
        },
        {
            "id": "f9a66cd594adf9cbf8898ba28856cc06039798ab57d53b5edf877072171242eb",
            "conversationId": "f9a66cd594adf9cbf8898ba28856cc06039798ab57d53b5edf877072171242eb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Abdominal pain",
                " unknown character"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T19:14:11.203Z",
            "endTime": "2022-05-12T19:14:11.203Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj4BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj4BwAAAAAAAA==/",
            "_etag": "\"7302addd-0000-0100-0000-627d75530000\"",
            "_attachments": "attachments/",
            "_ts": 1652389203
        },
        {
            "id": "ad0583a9b5e8ad67faf978ad408782235c530c8e1e1ed6e4b0deeaf7ecfbbca4",
            "conversationId": "ad0583a9b5e8ad67faf978ad408782235c530c8e1e1ed6e4b0deeaf7ecfbbca4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Abdominal pain",
                " unknown character"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T19:19:48.584Z",
            "endTime": "2022-05-12T19:19:48.584Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj5BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj5BwAAAAAAAA==/",
            "_etag": "\"7302aedd-0000-0100-0000-627d75530000\"",
            "_attachments": "attachments/",
            "_ts": 1652389203
        },
        {
            "id": "21420de430da3e6d6434d9399c57fdbd7f63c2358f75e3e0ffb836bec3c71db6",
            "conversationId": "21420de430da3e6d6434d9399c57fdbd7f63c2358f75e3e0ffb836bec3c71db6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Abdominal pain",
                " unknown character"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T19:33:01.036Z",
            "endTime": "2022-05-12T19:33:01.036Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj6BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj6BwAAAAAAAA==/",
            "_etag": "\"7302afdd-0000-0100-0000-627d75530000\"",
            "_attachments": "attachments/",
            "_ts": 1652389203
        },
        {
            "id": "da10be0005dffa9f29ff7fa07db2831a96f57a3f6f33719b85cf3e440f35d95e",
            "conversationId": "da10be0005dffa9f29ff7fa07db2831a96f57a3f6f33719b85cf3e440f35d95e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T19:22:02.403Z",
            "endTime": "2022-05-12T19:22:02.403Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj7BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj7BwAAAAAAAA==/",
            "_etag": "\"7302b0dd-0000-0100-0000-627d75530000\"",
            "_attachments": "attachments/",
            "_ts": 1652389203
        },
        {
            "id": "59a876fe080d2b1277c8ba9fcfd8fc63f763d88d36fd69fbe7102dd77f89ca4f",
            "conversationId": "59a876fe080d2b1277c8ba9fcfd8fc63f763d88d36fd69fbe7102dd77f89ca4f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Abdominal pain",
                " unknown character"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T19:45:58.116Z",
            "endTime": "2022-05-12T19:45:58.116Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj8BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj8BwAAAAAAAA==/",
            "_etag": "\"7302b6dd-0000-0100-0000-627d75530000\"",
            "_attachments": "attachments/",
            "_ts": 1652389203
        },
        {
            "id": "36c79af252afe6271bc24ab5ad022aa180ea3c644c28c8a07fd8e3f5ee35c45e",
            "conversationId": "36c79af252afe6271bc24ab5ad022aa180ea3c644c28c8a07fd8e3f5ee35c45e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Abdominal pain",
                " unknown character"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T19:50:40.88Z",
            "endTime": "2022-05-12T19:50:40.88Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj9BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj9BwAAAAAAAA==/",
            "_etag": "\"7302b7dd-0000-0100-0000-627d75530000\"",
            "_attachments": "attachments/",
            "_ts": 1652389203
        },
        {
            "id": "6cce1f4c6e41366600155bee7ebdfcbd4431036fa795ff298d01f3462d004cf3",
            "conversationId": "6cce1f4c6e41366600155bee7ebdfcbd4431036fa795ff298d01f3462d004cf3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Abdominal pain",
                " unknown character"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-12T19:55:30.055Z",
            "endTime": "2022-05-12T19:55:30.055Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj+BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj+BwAAAAAAAA==/",
            "_etag": "\"7302b8dd-0000-0100-0000-627d75530000\"",
            "_attachments": "attachments/",
            "_ts": 1652389203
        },
        {
            "id": "118c42a5552a9cff59210799353045adabb36f9c6f63d060dd375bce4388a3c7",
            "conversationId": "118c42a5552a9cff59210799353045adabb36f9c6f63d060dd375bce4388a3c7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Abdominal pain",
                " unknown character"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-12T21:15:31.595Z",
            "endTime": "2022-05-12T21:15:31.595Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj-BwAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj-BwAAAAAAAA==/",
            "_etag": "\"78026e80-0000-0100-0000-627d91720000\"",
            "_attachments": "attachments/",
            "_ts": 1652396402
        },
        {
            "id": "62b1467766119c190de3e2f28d43501df28a2395f48f4b51375ff06657fe8d72",
            "conversationId": "62b1467766119c190de3e2f28d43501df28a2395f48f4b51375ff06657fe8d72",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-12T21:18:14.013Z",
            "endTime": "2022-05-12T21:18:14.013Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgACAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgACAAAAAAAAA==/",
            "_etag": "\"78026f80-0000-0100-0000-627d91720000\"",
            "_attachments": "attachments/",
            "_ts": 1652396402
        },
        {
            "id": "471f8f19999b6d22ea230179ab2cdc7b82eb29cf55bf645ead517c6f90d4c839",
            "conversationId": "471f8f19999b6d22ea230179ab2cdc7b82eb29cf55bf645ead517c6f90d4c839",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Runny nose",
                "Watery eyes"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-13T15:35:13.537Z",
            "endTime": "2022-05-13T15:35:13.537Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgBCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgBCAAAAAAAAA==/",
            "_etag": "\"9d02ab8a-0000-0100-0000-627e9ca20000\"",
            "_attachments": "attachments/",
            "_ts": 1652464802
        },
        {
            "id": "ed6c7be7931f8c8979b902de304e56eec67b9990d7637dd00e29808726d86f64",
            "conversationId": "ed6c7be7931f8c8979b902de304e56eec67b9990d7637dd00e29808726d86f64",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-05-13T15:59:47.492Z",
            "endTime": "2022-05-13T16:10:40.905Z",
            "timeSpent": 653,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgCCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgCCAAAAAAAAA==/",
            "_etag": "\"9d02aa8a-0000-0100-0000-627e9ca20000\"",
            "_attachments": "attachments/",
            "_ts": 1652464802
        },
        {
            "id": "d3cdfd467bc15589431c0c3238af699223f03a4148445fa63c651159718ce5b2",
            "conversationId": "d3cdfd467bc15589431c0c3238af699223f03a4148445fa63c651159718ce5b2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-13T16:12:39.09Z",
            "endTime": "2022-05-13T16:12:39.09Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgDCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgDCAAAAAAAAA==/",
            "_etag": "\"9d02ac8a-0000-0100-0000-627e9ca20000\"",
            "_attachments": "attachments/",
            "_ts": 1652464802
        },
        {
            "id": "cf0c0524cc48004e7521305368cfe89da5c0fa33408a4b83bbd99ae64c2e4c1c",
            "conversationId": "cf0c0524cc48004e7521305368cfe89da5c0fa33408a4b83bbd99ae64c2e4c1c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Abdominal pain",
                " unknown character"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-13T16:27:40.103Z",
            "endTime": "2022-05-13T16:27:40.103Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgECAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgECAAAAAAAAA==/",
            "_etag": "\"9d02ad8a-0000-0100-0000-627e9ca20000\"",
            "_attachments": "attachments/",
            "_ts": 1652464802
        },
        {
            "id": "e8849ce77a68d19bb409bf8e37f0b897cac87a2f865dbdafef64a5c24eff1ff5",
            "conversationId": "e8849ce77a68d19bb409bf8e37f0b897cac87a2f865dbdafef64a5c24eff1ff5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Foot pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will go to the emergency room",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-05-13T16:14:20.281Z",
            "endTime": "2022-05-13T16:14:20.281Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgFCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgFCAAAAAAAAA==/",
            "_etag": "\"9d02ae8a-0000-0100-0000-627e9ca20000\"",
            "_attachments": "attachments/",
            "_ts": 1652464802
        },
        {
            "id": "e621c86a4bf89fd062759b5799731ef75970cdf067408674729fb5273a888f4d",
            "conversationId": "e621c86a4bf89fd062759b5799731ef75970cdf067408674729fb5273a888f4d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Dry eyes"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-05-13T16:17:36.412Z",
            "endTime": "2022-05-13T16:17:36.412Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgGCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgGCAAAAAAAAA==/",
            "_etag": "\"9d02af8a-0000-0100-0000-627e9ca20000\"",
            "_attachments": "attachments/",
            "_ts": 1652464802
        },
        {
            "id": "e0b40c112d746eea673f678f1cd7f63f52b87dd7393382290c2b69de3790bd2b",
            "conversationId": "e0b40c112d746eea673f678f1cd7f63f52b87dd7393382290c2b69de3790bd2b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Abdominal pain",
                " unknown character"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-13T16:29:32.988Z",
            "endTime": "2022-05-13T16:29:32.988Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgHCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgHCAAAAAAAAA==/",
            "_etag": "\"9d02b08a-0000-0100-0000-627e9ca20000\"",
            "_attachments": "attachments/",
            "_ts": 1652464802
        },
        {
            "id": "8c0ad0bfd3c9e15cc2bb2bdd19888f9047366afb7b3613a4e9cd48557ff672cb",
            "conversationId": "8c0ad0bfd3c9e15cc2bb2bdd19888f9047366afb7b3613a4e9cd48557ff672cb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Abdominal pain",
                " unknown character"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the emergency room",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-13T18:57:44.535Z",
            "endTime": "2022-05-13T19:01:16.69Z",
            "timeSpent": 212,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgICAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgICAAAAAAAAA==/",
            "_etag": "\"a702bbaf-0000-0100-0000-627ec6d30000\"",
            "_attachments": "attachments/",
            "_ts": 1652475603
        },
        {
            "id": "38e6f8045a71e95164204c6f429572a21195ee6aeff192256e85f76a828cb8bf",
            "conversationId": "38e6f8045a71e95164204c6f429572a21195ee6aeff192256e85f76a828cb8bf",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-13T19:25:51.301Z",
            "endTime": "2022-05-13T19:25:51.301Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgJCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgJCAAAAAAAAA==/",
            "_etag": "\"a702b5af-0000-0100-0000-627ec6d30000\"",
            "_attachments": "attachments/",
            "_ts": 1652475603
        },
        {
            "id": "73d7c2f92009f75d2843c8e01e309e8dc07ad665e23879523d1c7ac3918480de",
            "conversationId": "73d7c2f92009f75d2843c8e01e309e8dc07ad665e23879523d1c7ac3918480de",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-13T19:11:40.489Z",
            "endTime": "2022-05-13T19:11:40.489Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgKCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgKCAAAAAAAAA==/",
            "_etag": "\"a702b6af-0000-0100-0000-627ec6d30000\"",
            "_attachments": "attachments/",
            "_ts": 1652475603
        },
        {
            "id": "187576f7bc0ebf1cc36b1610402ebb23e5b1bc5d4bfe1f10b591fce8c43540fc",
            "conversationId": "187576f7bc0ebf1cc36b1610402ebb23e5b1bc5d4bfe1f10b591fce8c43540fc",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-05-13T19:30:16.6Z",
            "endTime": "2022-05-13T19:30:16.6Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgLCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgLCAAAAAAAAA==/",
            "_etag": "\"a702b7af-0000-0100-0000-627ec6d30000\"",
            "_attachments": "attachments/",
            "_ts": 1652475603
        },
        {
            "id": "ce33af4793bab17e940c521b09400088ed2d9b4b60fa59f4ead3b363b4800f96",
            "conversationId": "ce33af4793bab17e940c521b09400088ed2d9b4b60fa59f4ead3b363b4800f96",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-05-13T19:32:55.546Z",
            "endTime": "2022-05-13T19:32:55.546Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgMCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgMCAAAAAAAAA==/",
            "_etag": "\"a90232f9-0000-0100-0000-627ed4e10000\"",
            "_attachments": "attachments/",
            "_ts": 1652479201
        },
        {
            "id": "b7d35a98411572240a5b65a79477d277037121d0c714fd6977497182c8e94e02",
            "conversationId": "b7d35a98411572240a5b65a79477d277037121d0c714fd6977497182c8e94e02",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Abdominal pain",
                "Vomiting"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-05-13T19:34:35.679Z",
            "endTime": "2022-05-13T19:34:35.679Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgNCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgNCAAAAAAAAA==/",
            "_etag": "\"a90233f9-0000-0100-0000-627ed4e10000\"",
            "_attachments": "attachments/",
            "_ts": 1652479201
        },
        {
            "id": "e6893f0878f58d84f4f177e7fd159340d1efccd760960ae7627ac8525023042b",
            "conversationId": "e6893f0878f58d84f4f177e7fd159340d1efccd760960ae7627ac8525023042b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-13T19:21:11.536Z",
            "endTime": "2022-05-13T19:21:11.536Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgOCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgOCAAAAAAAAA==/",
            "_etag": "\"a702baaf-0000-0100-0000-627ec6d30000\"",
            "_attachments": "attachments/",
            "_ts": 1652475603
        },
        {
            "id": "7376fe451a9b6510678ba469f5c5daea743f299eb5b8fb676a888da423f2fa68",
            "conversationId": "7376fe451a9b6510678ba469f5c5daea743f299eb5b8fb676a888da423f2fa68",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Wrist pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-05-13T19:36:53.397Z",
            "endTime": "2022-05-13T19:36:53.397Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgPCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgPCAAAAAAAAA==/",
            "_etag": "\"a90234f9-0000-0100-0000-627ed4e10000\"",
            "_attachments": "attachments/",
            "_ts": 1652479201
        },
        {
            "id": "b5d04202bb97c877bd590a6118e13a0f8dc5fdfdd87fce88f4273071227b7ad2",
            "conversationId": "b5d04202bb97c877bd590a6118e13a0f8dc5fdfdd87fce88f4273071227b7ad2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-05-13T19:38:48.996Z",
            "endTime": "2022-05-13T19:38:48.996Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgQCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgQCAAAAAAAAA==/",
            "_etag": "\"a90235f9-0000-0100-0000-627ed4e10000\"",
            "_attachments": "attachments/",
            "_ts": 1652479201
        },
        {
            "id": "75adf1a0cf4a88ce55f2b31c73ce136c1264100649bbc240c583c7234572ba1a",
            "conversationId": "75adf1a0cf4a88ce55f2b31c73ce136c1264100649bbc240c583c7234572ba1a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-05-13T19:41:36.829Z",
            "endTime": "2022-05-13T19:41:36.829Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgRCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgRCAAAAAAAAA==/",
            "_etag": "\"a702beaf-0000-0100-0000-627ec6d30000\"",
            "_attachments": "attachments/",
            "_ts": 1652475603
        },
        {
            "id": "a0c15cffd0c4aa468f29b9c7f58d1452328d884c78d8da7eb2cb19a669e26116",
            "conversationId": "a0c15cffd0c4aa468f29b9c7f58d1452328d884c78d8da7eb2cb19a669e26116",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Abdominal pain",
                " unknown character"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-13T20:11:54.72Z",
            "endTime": "2022-05-13T20:11:54.72Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgSCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgSCAAAAAAAAA==/",
            "_etag": "\"a90231f9-0000-0100-0000-627ed4e10000\"",
            "_attachments": "attachments/",
            "_ts": 1652479201
        },
        {
            "id": "d8c1743ce02e952c659b84d8e27140fc25b8d351479fed9a74aa4ab9395729d9",
            "conversationId": "d8c1743ce02e952c659b84d8e27140fc25b8d351479fed9a74aa4ab9395729d9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-14T18:42:33.624Z",
            "endTime": "2022-05-14T18:42:33.624Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgTCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgTCAAAAAAAAA==/",
            "_etag": "\"b902990a-0000-0100-0000-62800a410000\"",
            "_attachments": "attachments/",
            "_ts": 1652558401
        },
        {
            "id": "be0f215bff2af5fd0a5a69c383e8177e6bf710ea444221a014bc4d340d86a18b",
            "conversationId": "be0f215bff2af5fd0a5a69c383e8177e6bf710ea444221a014bc4d340d86a18b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-05-14T18:42:54.415Z",
            "endTime": "2022-05-14T18:42:54.415Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgUCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgUCAAAAAAAAA==/",
            "_etag": "\"b9029a0a-0000-0100-0000-62800a410000\"",
            "_attachments": "attachments/",
            "_ts": 1652558401
        },
        {
            "id": "38ab3779267c92af0a4adcfd1f810aeb9fe8a249d3dd0b8cb206bb971b413e01",
            "conversationId": "38ab3779267c92af0a4adcfd1f810aeb9fe8a249d3dd0b8cb206bb971b413e01",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-05-14T18:45:21.343Z",
            "endTime": "2022-05-14T18:45:21.343Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgVCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgVCAAAAAAAAA==/",
            "_etag": "\"b9029b0a-0000-0100-0000-62800a410000\"",
            "_attachments": "attachments/",
            "_ts": 1652558401
        },
        {
            "id": "d3099cf8a885e19eee27d9d2d3454b7c8ca2783108cfefddcaab8e2874ca8bf3",
            "conversationId": "d3099cf8a885e19eee27d9d2d3454b7c8ca2783108cfefddcaab8e2874ca8bf3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-05-14T18:47:21.189Z",
            "endTime": "2022-05-14T18:47:21.189Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgWCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgWCAAAAAAAAA==/",
            "_etag": "\"b9029c0a-0000-0100-0000-62800a410000\"",
            "_attachments": "attachments/",
            "_ts": 1652558401
        },
        {
            "id": "515192c6f9f68d68a43840ce7497e6b3f7f8696b54a283657ae9c2b3ce8fe693",
            "conversationId": "515192c6f9f68d68a43840ce7497e6b3f7f8696b54a283657ae9c2b3ce8fe693",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-14T18:49:16.099Z",
            "endTime": "2022-05-14T18:49:16.099Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgXCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgXCAAAAAAAAA==/",
            "_etag": "\"b90278ab-0000-0100-0000-628018520000\"",
            "_attachments": "attachments/",
            "_ts": 1652562002
        },
        {
            "id": "4c4f651543e1a1f376bb7934872b2feb70011168ea2b6feea4133a6ae99e7055",
            "conversationId": "4c4f651543e1a1f376bb7934872b2feb70011168ea2b6feea4133a6ae99e7055",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-05-14T18:50:33.522Z",
            "endTime": "2022-05-14T18:50:33.522Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgYCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgYCAAAAAAAAA==/",
            "_etag": "\"b9029e0a-0000-0100-0000-62800a410000\"",
            "_attachments": "attachments/",
            "_ts": 1652558401
        },
        {
            "id": "ca2f0b10a7ae8ee246fd010af9bafd574cf5c0b1fe7f4c3dbeab6b96a9b15470",
            "conversationId": "ca2f0b10a7ae8ee246fd010af9bafd574cf5c0b1fe7f4c3dbeab6b96a9b15470",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-14T18:52:53.915Z",
            "endTime": "2022-05-14T18:52:53.915Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgZCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgZCAAAAAAAAA==/",
            "_etag": "\"b90279ab-0000-0100-0000-628018520000\"",
            "_attachments": "attachments/",
            "_ts": 1652562002
        },
        {
            "id": "d5e617909d382eddf355eaa18bac5a6e2e6d26e63db893b49e99b2ccc1952e93",
            "conversationId": "d5e617909d382eddf355eaa18bac5a6e2e6d26e63db893b49e99b2ccc1952e93",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Injury",
                "Leg pain",
                "Foot pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will go to ReadyMED / urgent care",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-05-14T19:21:56.038Z",
            "endTime": "2022-05-14T19:21:56.038Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgaCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgaCAAAAAAAAA==/",
            "_etag": "\"b9026cab-0000-0100-0000-628018520000\"",
            "_attachments": "attachments/",
            "_ts": 1652562002
        },
        {
            "id": "51571a53e3b1753307bb4cbcddbac90c37f6b5189926730f652b463deb231c81",
            "conversationId": "51571a53e3b1753307bb4cbcddbac90c37f6b5189926730f652b463deb231c81",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Watery eyes",
                "Stomach pain",
                "Fatigue"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-14T19:32:47.559Z",
            "endTime": "2022-05-14T19:32:47.559Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgbCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgbCAAAAAAAAA==/",
            "_etag": "\"b9026dab-0000-0100-0000-628018520000\"",
            "_attachments": "attachments/",
            "_ts": 1652562002
        },
        {
            "id": "cbe303da49e6abb6b884031f7f461bb314259b138176ec7ae283d3d6840f2347",
            "conversationId": "cbe303da49e6abb6b884031f7f461bb314259b138176ec7ae283d3d6840f2347",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-05-14T19:05:49.105Z",
            "endTime": "2022-05-14T19:05:49.105Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgcCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgcCAAAAAAAAA==/",
            "_etag": "\"b9026eab-0000-0100-0000-628018520000\"",
            "_attachments": "attachments/",
            "_ts": 1652562002
        },
        {
            "id": "cd46c255aff5675b4c619b90992eafb1cbf401462446ba7be97e42af060fabf1",
            "conversationId": "cd46c255aff5675b4c619b90992eafb1cbf401462446ba7be97e42af060fabf1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Sore nail",
                "Sore throat"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will go to the emergency room",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-05-14T19:08:13.445Z",
            "endTime": "2022-05-14T19:08:13.445Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgdCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgdCAAAAAAAAA==/",
            "_etag": "\"b9026fab-0000-0100-0000-628018520000\"",
            "_attachments": "attachments/",
            "_ts": 1652562002
        },
        {
            "id": "25dceb76f226f5ed7b717774dc872d1232f458e1ed276e0382dadc725e05d7ba",
            "conversationId": "25dceb76f226f5ed7b717774dc872d1232f458e1ed276e0382dadc725e05d7ba",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will go to ReadyMED / urgent care",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-05-14T19:26:31.947Z",
            "endTime": "2022-05-14T19:26:31.947Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgeCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgeCAAAAAAAAA==/",
            "_etag": "\"b90270ab-0000-0100-0000-628018520000\"",
            "_attachments": "attachments/",
            "_ts": 1652562002
        },
        {
            "id": "279d1ae4d84fc6bfd5900ce09800756a2287f6e671d9fb71446e0606535bb850",
            "conversationId": "279d1ae4d84fc6bfd5900ce09800756a2287f6e671d9fb71446e0606535bb850",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-05-14T19:24:00.04Z",
            "endTime": "2022-05-14T19:24:00.04Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgfCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgfCAAAAAAAAA==/",
            "_etag": "\"b90271ab-0000-0100-0000-628018520000\"",
            "_attachments": "attachments/",
            "_ts": 1652562002
        },
        {
            "id": "4d618938803a3fa1fd6109eb417469acdb38e779a83b1f843fca707d538f678d",
            "conversationId": "4d618938803a3fa1fd6109eb417469acdb38e779a83b1f843fca707d538f678d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Runny nose",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-14T19:35:45.655Z",
            "endTime": "2022-05-14T19:35:45.655Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIggCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIggCAAAAAAAAA==/",
            "_etag": "\"b90272ab-0000-0100-0000-628018520000\"",
            "_attachments": "attachments/",
            "_ts": 1652562002
        },
        {
            "id": "d4a055ea70691e7d62c2541d376cea2429a5b8c730c76f7de3e796793124c8ca",
            "conversationId": "d4a055ea70691e7d62c2541d376cea2429a5b8c730c76f7de3e796793124c8ca",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Fatigue",
                "Specific fear"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-14T19:28:45.73Z",
            "endTime": "2022-05-14T19:28:45.73Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIghCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIghCAAAAAAAAA==/",
            "_etag": "\"b90273ab-0000-0100-0000-628018520000\"",
            "_attachments": "attachments/",
            "_ts": 1652562002
        },
        {
            "id": "5f70360cdd902222f321d2a326511f0c1a6f1075da2eef771302c888fcdd5822",
            "conversationId": "5f70360cdd902222f321d2a326511f0c1a6f1075da2eef771302c888fcdd5822",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Wrist pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-14T19:11:51.886Z",
            "endTime": "2022-05-14T19:11:51.886Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgiCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgiCAAAAAAAAA==/",
            "_etag": "\"b90274ab-0000-0100-0000-628018520000\"",
            "_attachments": "attachments/",
            "_ts": 1652562002
        },
        {
            "id": "35f6ec04f7638d8d9b67245e7fcc3c34c0a88358dad7c201b6704ff94c5c311f",
            "conversationId": "35f6ec04f7638d8d9b67245e7fcc3c34c0a88358dad7c201b6704ff94c5c311f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-05-14T19:03:32.863Z",
            "endTime": "2022-05-14T19:03:32.863Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgjCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgjCAAAAAAAAA==/",
            "_etag": "\"b90275ab-0000-0100-0000-628018520000\"",
            "_attachments": "attachments/",
            "_ts": 1652562002
        },
        {
            "id": "6142acd4893121eddd2845c690a11c10e9bb34608a54d251111251944066bf2f",
            "conversationId": "6142acd4893121eddd2845c690a11c10e9bb34608a54d251111251944066bf2f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will go to ReadyMED / urgent care",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-05-14T19:59:57.889Z",
            "endTime": "2022-05-14T20:01:30.138Z",
            "timeSpent": 92,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgkCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgkCAAAAAAAAA==/",
            "_etag": "\"ba02f34e-0000-0100-0000-628026610000\"",
            "_attachments": "attachments/",
            "_ts": 1652565601
        },
        {
            "id": "9727c44767ea914e5a3f0a9ef85e894401862ab01b614dc42868a9746a0dfbaa",
            "conversationId": "9727c44767ea914e5a3f0a9ef85e894401862ab01b614dc42868a9746a0dfbaa",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-05-14T19:59:32.103Z",
            "endTime": "2022-05-14T19:59:32.103Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIglCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIglCAAAAAAAAA==/",
            "_etag": "\"b90277ab-0000-0100-0000-628018520000\"",
            "_attachments": "attachments/",
            "_ts": 1652562002
        },
        {
            "id": "bf3fb4f19320993429a1e36cf704a29a801ffa7dde5e5a58d547aac366c558c0",
            "conversationId": "bf3fb4f19320993429a1e36cf704a29a801ffa7dde5e5a58d547aac366c558c0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "Contact me within two business days, 16526526520, true",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-05-14T20:03:52.354Z",
            "endTime": "2022-05-14T20:03:52.354Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgmCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgmCAAAAAAAAA==/",
            "_etag": "\"ba02f44e-0000-0100-0000-628026610000\"",
            "_attachments": "attachments/",
            "_ts": 1652565601
        },
        {
            "id": "130c8200df9842c27bfbf7f4319fe44c4966d75398be0535887c2fffbbf7398b",
            "conversationId": "130c8200df9842c27bfbf7f4319fe44c4966d75398be0535887c2fffbbf7398b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-14T20:10:47.479Z",
            "endTime": "2022-05-14T20:10:47.479Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgnCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgnCAAAAAAAAA==/",
            "_etag": "\"ba02f54e-0000-0100-0000-628026610000\"",
            "_attachments": "attachments/",
            "_ts": 1652565601
        },
        {
            "id": "a0928b5bf2804b0ced3ad0c62f61bd9d384c108804e029be68b06486d6b5affa",
            "conversationId": "a0928b5bf2804b0ced3ad0c62f61bd9d384c108804e029be68b06486d6b5affa",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-15T15:42:26.194Z",
            "endTime": "2022-05-15T15:42:26.194Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgoCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgoCAAAAAAAAA==/",
            "_etag": "\"c202ccee-0000-0100-0000-6281319a0000\"",
            "_attachments": "attachments/",
            "_ts": 1652634010
        },
        {
            "id": "536a4bdd7e6d100f7d33ab550d43eeda275ff178973cc130d7fbd6a570c7df37",
            "conversationId": "536a4bdd7e6d100f7d33ab550d43eeda275ff178973cc130d7fbd6a570c7df37",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-15T15:42:13.009Z",
            "endTime": "2022-05-15T15:42:13.009Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgpCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgpCAAAAAAAAA==/",
            "_etag": "\"c202cdee-0000-0100-0000-6281319a0000\"",
            "_attachments": "attachments/",
            "_ts": 1652634010
        },
        {
            "id": "031bef2a4125b1fb569ea5df7d48c76d24dcbf064dae17a154b07d255c117505",
            "conversationId": "031bef2a4125b1fb569ea5df7d48c76d24dcbf064dae17a154b07d255c117505",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-15T15:45:26.998Z",
            "endTime": "2022-05-15T15:45:26.998Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgqCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgqCAAAAAAAAA==/",
            "_etag": "\"c202ceee-0000-0100-0000-6281319a0000\"",
            "_attachments": "attachments/",
            "_ts": 1652634010
        },
        {
            "id": "35ddc8ccfb873526b3d24c67d8c06afad69b97f0b8ec26b8491f852e672cfd3d",
            "conversationId": "35ddc8ccfb873526b3d24c67d8c06afad69b97f0b8ec26b8491f852e672cfd3d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-15T15:45:57.361Z",
            "endTime": "2022-05-15T15:45:57.361Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgrCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgrCAAAAAAAAA==/",
            "_etag": "\"c202cfee-0000-0100-0000-6281319a0000\"",
            "_attachments": "attachments/",
            "_ts": 1652634010
        },
        {
            "id": "8ac20840b6604124c337af49327dc71646e81ca0056b191beb6a5814fa8dc28c",
            "conversationId": "8ac20840b6604124c337af49327dc71646e81ca0056b191beb6a5814fa8dc28c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache",
                "Sore throat",
                "Runny nose",
                "Fever",
                "Nausea"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": "I will go to ReadyMED / urgent care",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-15T15:52:49.791Z",
            "endTime": "2022-05-15T15:52:49.791Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgsCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgsCAAAAAAAAA==/",
            "_etag": "\"c202d0ee-0000-0100-0000-6281319a0000\"",
            "_attachments": "attachments/",
            "_ts": 1652634010
        },
        {
            "id": "75e8f7ea7310aaa2a2987de80cf81236d0641d7cfc6be8a2a975acf5e4bb3d12",
            "conversationId": "75e8f7ea7310aaa2a2987de80cf81236d0641d7cfc6be8a2a975acf5e4bb3d12",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-15T15:38:57.973Z",
            "endTime": "2022-05-15T15:38:57.973Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgtCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgtCAAAAAAAAA==/",
            "_etag": "\"c202d1ee-0000-0100-0000-6281319a0000\"",
            "_attachments": "attachments/",
            "_ts": 1652634010
        },
        {
            "id": "542cca5a8918b384f87af2cd516dac4b20f4f73b78a4eedd636814b07c298fba",
            "conversationId": "542cca5a8918b384f87af2cd516dac4b20f4f73b78a4eedd636814b07c298fba",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-15T15:38:45.899Z",
            "endTime": "2022-05-15T15:38:45.899Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIguCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIguCAAAAAAAAA==/",
            "_etag": "\"c202d2ee-0000-0100-0000-6281319a0000\"",
            "_attachments": "attachments/",
            "_ts": 1652634010
        },
        {
            "id": "434155bf90e905f90d4dee6483912ce20ec3b1105587b5ebf67d40d4d9509f84",
            "conversationId": "434155bf90e905f90d4dee6483912ce20ec3b1105587b5ebf67d40d4d9509f84",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-15T15:39:06.516Z",
            "endTime": "2022-05-15T15:39:06.516Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgvCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgvCAAAAAAAAA==/",
            "_etag": "\"c202d3ee-0000-0100-0000-6281319a0000\"",
            "_attachments": "attachments/",
            "_ts": 1652634010
        },
        {
            "id": "557743c507225dbda30a97ad2968e91b6af67bd167e56f011fce9f48917f0636",
            "conversationId": "557743c507225dbda30a97ad2968e91b6af67bd167e56f011fce9f48917f0636",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-05-16T13:59:56.107Z",
            "endTime": "2022-05-16T14:00:24.019Z",
            "timeSpent": 28,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgwCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgwCAAAAAAAAA==/",
            "_etag": "\"de020efa-0000-0100-0000-628275060000\"",
            "_attachments": "attachments/",
            "_ts": 1652716806
        },
        {
            "id": "4d319c9177f40074de871ae9725f6f548c1fcc0642e4254ad25789d1cec88b7f",
            "conversationId": "4d319c9177f40074de871ae9725f6f548c1fcc0642e4254ad25789d1cec88b7f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-16T13:58:50.444Z",
            "endTime": "2022-05-16T13:58:50.444Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgxCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgxCAAAAAAAAA==/",
            "_etag": "\"da02babf-0000-0100-0000-628266f20000\"",
            "_attachments": "attachments/",
            "_ts": 1652713202
        },
        {
            "id": "d9400377eaae5e3cdf93d03ebbf8785a6c9aac63da7b1e59e0589c8522d1cef2",
            "conversationId": "d9400377eaae5e3cdf93d03ebbf8785a6c9aac63da7b1e59e0589c8522d1cef2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-16T13:55:46.622Z",
            "endTime": "2022-05-16T13:55:46.622Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgyCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgyCAAAAAAAAA==/",
            "_etag": "\"da02bbbf-0000-0100-0000-628266f20000\"",
            "_attachments": "attachments/",
            "_ts": 1652713202
        },
        {
            "id": "4c1d7fd1981b94416a6d9d303dbefe7c0dd31edb09ba11a6ac779121b4996c10",
            "conversationId": "4c1d7fd1981b94416a6d9d303dbefe7c0dd31edb09ba11a6ac779121b4996c10",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Watery eyes",
                "Sneezing",
                "Runny nose",
                "Wheezing",
                " audible",
                "Cough lasting 3 to 8 weeks",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-05-16T14:04:16.376Z",
            "endTime": "2022-05-16T14:04:16.376Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgzCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgzCAAAAAAAAA==/",
            "_etag": "\"de020ffa-0000-0100-0000-628275060000\"",
            "_attachments": "attachments/",
            "_ts": 1652716806
        },
        {
            "id": "a4cbfae174d4c1c8b561edc808db1d6b3511cf903b3a8628be2f8f5eb6f16f49",
            "conversationId": "a4cbfae174d4c1c8b561edc808db1d6b3511cf903b3a8628be2f8f5eb6f16f49",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Watery eyes",
                "Runny nose"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-16T14:21:03.627Z",
            "endTime": "2022-05-16T14:21:03.627Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg0CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg0CAAAAAAAAA==/",
            "_etag": "\"de0210fa-0000-0100-0000-628275060000\"",
            "_attachments": "attachments/",
            "_ts": 1652716806
        },
        {
            "id": "b53e902a86442be00e69459c798607da1c9b459a45c830c2da251aff4732e9cd",
            "conversationId": "b53e902a86442be00e69459c798607da1c9b459a45c830c2da251aff4732e9cd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Easy bruising",
                "Fatigue"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-16T14:55:34.22Z",
            "endTime": "2022-05-16T14:55:34.22Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg1CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg1CAAAAAAAAA==/",
            "_etag": "\"e2022ad0-0000-0100-0000-628283120000\"",
            "_attachments": "attachments/",
            "_ts": 1652720402
        },
        {
            "id": "5a789c30d86492cc579272bb6a3966117dafa6bafcfbaff130e03c87341e9495",
            "conversationId": "5a789c30d86492cc579272bb6a3966117dafa6bafcfbaff130e03c87341e9495",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-16T14:52:22.503Z",
            "endTime": "2022-05-16T14:52:22.503Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg2CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg2CAAAAAAAAA==/",
            "_etag": "\"de0212fa-0000-0100-0000-628275060000\"",
            "_attachments": "attachments/",
            "_ts": 1652716806
        },
        {
            "id": "e1382f29b5f1e3019a7311c039aca9889d861f5b818e9b35938ab6a4ed47d885",
            "conversationId": "e1382f29b5f1e3019a7311c039aca9889d861f5b818e9b35938ab6a4ed47d885",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-05-16T14:51:36.85Z",
            "endTime": "2022-05-16T14:51:36.85Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg3CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg3CAAAAAAAAA==/",
            "_etag": "\"e20229d0-0000-0100-0000-628283120000\"",
            "_attachments": "attachments/",
            "_ts": 1652720402
        },
        {
            "id": "0ff0c96d86cea42d9f8d173b7eefd7c03c1031385d40a0d93b029ab3befa9073",
            "conversationId": "0ff0c96d86cea42d9f8d173b7eefd7c03c1031385d40a0d93b029ab3befa9073",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Pain while urinating",
                "Urination in small amounts"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-05-16T14:58:51.853Z",
            "endTime": "2022-05-16T14:58:51.853Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg4CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg4CAAAAAAAAA==/",
            "_etag": "\"e2022bd0-0000-0100-0000-628283120000\"",
            "_attachments": "attachments/",
            "_ts": 1652720402
        },
        {
            "id": "f1f73eda9e6d633a5b55cdde377115de6688ae9b1abb8cdecb9e68818543d789",
            "conversationId": "f1f73eda9e6d633a5b55cdde377115de6688ae9b1abb8cdecb9e68818543d789",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Foot pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-16T14:59:58.407Z",
            "endTime": "2022-05-16T15:02:52.472Z",
            "timeSpent": 174,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg5CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg5CAAAAAAAAA==/",
            "_etag": "\"e2022dd0-0000-0100-0000-628283120000\"",
            "_attachments": "attachments/",
            "_ts": 1652720402
        },
        {
            "id": "d6d7f3320070900ec66704ffa5ee9fa7174b200475742f8166f85f93328284ac",
            "conversationId": "d6d7f3320070900ec66704ffa5ee9fa7174b200475742f8166f85f93328284ac",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-16T14:55:56.046Z",
            "endTime": "2022-05-16T14:55:56.046Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg6CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg6CAAAAAAAAA==/",
            "_etag": "\"de0216fa-0000-0100-0000-628275060000\"",
            "_attachments": "attachments/",
            "_ts": 1652716806
        },
        {
            "id": "3025411822b72f86ce7b31b1a09b418a73e0f9f05d1d3e6993750d9d42a8c692",
            "conversationId": "3025411822b72f86ce7b31b1a09b418a73e0f9f05d1d3e6993750d9d42a8c692",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-16T15:27:43.717Z",
            "endTime": "2022-05-16T15:27:43.717Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg7CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg7CAAAAAAAAA==/",
            "_etag": "\"e20225d0-0000-0100-0000-628283120000\"",
            "_attachments": "attachments/",
            "_ts": 1652720402
        },
        {
            "id": "fb8f12d26262235d1b1da311a237ba1db18cc4e48dc5f6cc74118921d4e2c01b",
            "conversationId": "fb8f12d26262235d1b1da311a237ba1db18cc4e48dc5f6cc74118921d4e2c01b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Red eye"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-05-16T15:21:19.892Z",
            "endTime": "2022-05-16T15:21:19.892Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg8CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg8CAAAAAAAAA==/",
            "_etag": "\"e20226d0-0000-0100-0000-628283120000\"",
            "_attachments": "attachments/",
            "_ts": 1652720402
        },
        {
            "id": "f01d755c64ef1ec56ab4b353f77691a646e5ccbc115e680936b9e8701079580f",
            "conversationId": "f01d755c64ef1ec56ab4b353f77691a646e5ccbc115e680936b9e8701079580f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Runny nose",
                "Stuffy Nose",
                "Sore throat",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-05-16T15:26:28.788Z",
            "endTime": "2022-05-16T15:26:28.788Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg9CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg9CAAAAAAAAA==/",
            "_etag": "\"e20227d0-0000-0100-0000-628283120000\"",
            "_attachments": "attachments/",
            "_ts": 1652720402
        },
        {
            "id": "24e99a0c0172386c7b1c7eb670c8cfde639002c7056eeb9bc9c420f4675a6bb7",
            "conversationId": "24e99a0c0172386c7b1c7eb670c8cfde639002c7056eeb9bc9c420f4675a6bb7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Shoulder pain",
                "Shoulder injury"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-05-16T15:24:04.589Z",
            "endTime": "2022-05-16T15:24:04.589Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg+CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg+CAAAAAAAAA==/",
            "_etag": "\"e20228d0-0000-0100-0000-628283120000\"",
            "_attachments": "attachments/",
            "_ts": 1652720402
        },
        {
            "id": "67d985556758c591c25e789cfd77658be720644504a8fdf8ca433c880fba1b81",
            "conversationId": "67d985556758c591c25e789cfd77658be720644504a8fdf8ca433c880fba1b81",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Vomiting"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-16T15:05:11.055Z",
            "endTime": "2022-05-16T15:05:11.055Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg-CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg-CAAAAAAAAA==/",
            "_etag": "\"e2022cd0-0000-0100-0000-628283120000\"",
            "_attachments": "attachments/",
            "_ts": 1652720402
        },
        {
            "id": "4750e509c277cfdf9d79b348098ca4c617e6fe8886309077925f30f573617107",
            "conversationId": "4750e509c277cfdf9d79b348098ca4c617e6fe8886309077925f30f573617107",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Abdominal pain",
                " unknown character"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-16T15:06:53.872Z",
            "endTime": "2022-05-16T15:06:53.872Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhACAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhACAAAAAAAAA==/",
            "_etag": "\"e2022fd0-0000-0100-0000-628283120000\"",
            "_attachments": "attachments/",
            "_ts": 1652720402
        },
        {
            "id": "9437d723f28aa585ae1c3746ad8e586d9d5ce68a0170816c715a5e68af232d7d",
            "conversationId": "9437d723f28aa585ae1c3746ad8e586d9d5ce68a0170816c715a5e68af232d7d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-16T15:03:04.838Z",
            "endTime": "2022-05-16T15:03:04.838Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhBCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhBCAAAAAAAAA==/",
            "_etag": "\"e20230d0-0000-0100-0000-628283120000\"",
            "_attachments": "attachments/",
            "_ts": 1652720402
        },
        {
            "id": "18da0561a1db8d311558468e9cd9ac390483a5484f459fcc6baa6c5f5bfceaf3",
            "conversationId": "18da0561a1db8d311558468e9cd9ac390483a5484f459fcc6baa6c5f5bfceaf3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Abdominal pain",
                " unknown character"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-16T16:56:42.347Z",
            "endTime": "2022-05-16T16:56:42.347Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhCCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhCCAAAAAAAAA==/",
            "_etag": "\"ea02fd62-0000-0100-0000-62829f340000\"",
            "_attachments": "attachments/",
            "_ts": 1652727604
        },
        {
            "id": "6fd31715ec701de1aae675cf360c9c3513804e2ff515c51edaacc024ec3b2c2f",
            "conversationId": "6fd31715ec701de1aae675cf360c9c3513804e2ff515c51edaacc024ec3b2c2f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Itchy skin"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-16T16:59:58.215Z",
            "endTime": "2022-05-16T17:02:49.089Z",
            "timeSpent": 171,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhDCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhDCAAAAAAAAA==/",
            "_etag": "\"ea02fc62-0000-0100-0000-62829f340000\"",
            "_attachments": "attachments/",
            "_ts": 1652727604
        },
        {
            "id": "2da2802f3ffae44c636bb515333823fee4115a5eb4ca850a4b870e1eb335c7a5",
            "conversationId": "2da2802f3ffae44c636bb515333823fee4115a5eb4ca850a4b870e1eb335c7a5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Abdominal pain",
                " unknown character"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-16T17:55:41.869Z",
            "endTime": "2022-05-16T17:55:41.869Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhECAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhECAAAAAAAAA==/",
            "_etag": "\"ea02f762-0000-0100-0000-62829f340000\"",
            "_attachments": "attachments/",
            "_ts": 1652727604
        },
        {
            "id": "24cbc3e7b80017f68c5cb4f8ec6c7dc3f35168c9b44fa9a15fa94d9c30581e4f",
            "conversationId": "24cbc3e7b80017f68c5cb4f8ec6c7dc3f35168c9b44fa9a15fa94d9c30581e4f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Abdominal pain",
                " unknown character"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-16T17:51:04.488Z",
            "endTime": "2022-05-16T17:51:04.488Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhFCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhFCAAAAAAAAA==/",
            "_etag": "\"ee026a12-0000-0100-0000-6282ad410000\"",
            "_attachments": "attachments/",
            "_ts": 1652731201
        },
        {
            "id": "85bdf721f6d763948b8f1ddc6479a22c09fd44e0a6b225a8db38d8bd3af24dad",
            "conversationId": "85bdf721f6d763948b8f1ddc6479a22c09fd44e0a6b225a8db38d8bd3af24dad",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-16T17:52:08.206Z",
            "endTime": "2022-05-16T17:52:08.206Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhGCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhGCAAAAAAAAA==/",
            "_etag": "\"ee026b12-0000-0100-0000-6282ad410000\"",
            "_attachments": "attachments/",
            "_ts": 1652731201
        },
        {
            "id": "2b90cd83349d7a9b110b861c641903857a45a8961d76642ac6d3de391f3a96c5",
            "conversationId": "2b90cd83349d7a9b110b861c641903857a45a8961d76642ac6d3de391f3a96c5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Itchy skin"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-16T17:24:06.596Z",
            "endTime": "2022-05-16T17:24:06.596Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhHCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhHCAAAAAAAAA==/",
            "_etag": "\"ea02fb62-0000-0100-0000-62829f340000\"",
            "_attachments": "attachments/",
            "_ts": 1652727604
},
    
        {
            "id": "6777b2de945f482054f406e7bf1ab63d5758152fd2ea2f5b945d5461aaa94ae9",
            "conversationId": "6777b2de945f482054f406e7bf1ab63d5758152fd2ea2f5b945d5461aaa94ae9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-16T19:05:28.6Z",
            "endTime": "2022-05-16T19:05:28.6Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhICAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhICAAAAAAAAA==/",
            "_etag": "\"f102e279-0000-0100-0000-6282bb510000\"",
            "_attachments": "attachments/",
            "_ts": 1652734801
        },
        {
            "id": "4f3bba522dcf1cfc1a76a5d04adf9ab25ae92b63405eb7b106fc2754bd373218",
            "conversationId": "4f3bba522dcf1cfc1a76a5d04adf9ab25ae92b63405eb7b106fc2754bd373218",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Nervousness or weepiness",
                "Anxiety"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-16T21:05:38.347Z",
            "endTime": "2022-05-16T21:05:38.347Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhJCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhJCAAAAAAAAA==/",
            "_etag": "\"f602ea1f-0000-0100-0000-6282d7720000\"",
            "_attachments": "attachments/",
            "_ts": 1652742002
        },
        {
            "id": "1cdb27744c57a1c4e17105b8db48fb9904ac2d811e04a0d3d26310a9a2166554",
            "conversationId": "1cdb27744c57a1c4e17105b8db48fb9904ac2d811e04a0d3d26310a9a2166554",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-16T21:01:23.802Z",
            "endTime": "2022-05-16T21:01:23.802Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhKCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhKCAAAAAAAAA==/",
            "_etag": "\"f602eb1f-0000-0100-0000-6282d7720000\"",
            "_attachments": "attachments/",
            "_ts": 1652742002
        },
        {
            "id": "432cb90ab8a584978e88186d2e50412badee58a0d0691c8a8773260c5a1c1fbc",
            "conversationId": "432cb90ab8a584978e88186d2e50412badee58a0d0691c8a8773260c5a1c1fbc",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Itchy skin"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-05-17T14:49:04.125Z",
            "endTime": "2022-05-17T14:49:04.125Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhLCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhLCAAAAAAAAA==/",
            "_etag": "\"190303c0-0000-0100-0000-6283d4920000\"",
            "_attachments": "attachments/",
            "_ts": 1652806802
        },
        {
            "id": "06e5db732066f94dadcb5e295084438e733f69913bdd84b6cc79021738b5f5ad",
            "conversationId": "06e5db732066f94dadcb5e295084438e733f69913bdd84b6cc79021738b5f5ad",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Abdominal pain",
                " unknown character"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-17T14:44:39.941Z",
            "endTime": "2022-05-17T14:44:39.941Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhMCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhMCAAAAAAAAA==/",
            "_etag": "\"190302c0-0000-0100-0000-6283d4920000\"",
            "_attachments": "attachments/",
            "_ts": 1652806802
        },
        {
            "id": "3ee01985c96345d458712bea4b72c93d305d2b8fff02f480d18b65cd214a56de",
            "conversationId": "3ee01985c96345d458712bea4b72c93d305d2b8fff02f480d18b65cd214a56de",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Watery eyes",
                "Runny nose"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-17T14:41:08.816Z",
            "endTime": "2022-05-17T14:41:08.816Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhNCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhNCAAAAAAAAA==/",
            "_etag": "\"190301c0-0000-0100-0000-6283d4920000\"",
            "_attachments": "attachments/",
            "_ts": 1652806802
        },
        {
            "id": "a3f20f260bb0f63bd41a2b7f7414beaf39a0f217539087b8023d129d46220bae",
            "conversationId": "a3f20f260bb0f63bd41a2b7f7414beaf39a0f217539087b8023d129d46220bae",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the emergency room",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "startTime": "2022-05-18T19:09:25.009Z",
            "endTime": "2022-05-18T19:09:25.009Z",
            "timeSpent": 0,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "completedScheduling": "yes",
            "_rid": "qHwxAIkCfIhOCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhOCAAAAAAAAA==/",
            "_etag": "\"33013e01-0000-0100-0000-62c3fc760000\"",
            "_attachments": "attachments/",
            "_ts": 1657011318
        },
        {
            "id": "ab4b1a68bd8a38478057c4d12cd4bf5abd7e0517199658cba5c807e6366cb0d2",
            "conversationId": "ab4b1a68bd8a38478057c4d12cd4bf5abd7e0517199658cba5c807e6366cb0d2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-18T19:07:35.427Z",
            "endTime": "2022-05-18T19:07:35.427Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhPCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhPCAAAAAAAAA==/",
            "_etag": "\"60038588-0000-0100-0000-62855e520000\"",
            "_attachments": "attachments/",
            "_ts": 1652907602
        },
        {
            "id": "8b83f8c520ac45a1a857810f0b90ac90c4a9c9c6311bb87c0229a8f7ad122739",
            "conversationId": "8b83f8c520ac45a1a857810f0b90ac90c4a9c9c6311bb87c0229a8f7ad122739",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-19T14:13:08.366Z",
            "endTime": "2022-05-19T14:13:36.755Z",
            "timeSpent": 28,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhQCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhQCAAAAAAAAA==/",
            "_etag": "\"8403dfaf-0000-0100-0000-628669820000\"",
            "_attachments": "attachments/",
            "_ts": 1652976002
        },
        {
            "id": "f83a01f1ddfad564d44a28fbd01050f2ae865fca48a65310e8346e433f5ed86b",
            "conversationId": "f83a01f1ddfad564d44a28fbd01050f2ae865fca48a65310e8346e433f5ed86b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-19T14:20:38.455Z",
            "endTime": "2022-05-19T14:20:38.455Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhRCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhRCAAAAAAAAA==/",
            "_etag": "\"8403e0af-0000-0100-0000-628669820000\"",
            "_attachments": "attachments/",
            "_ts": 1652976002
        },
        {
            "id": "c9bd5af186c11371cf6fa2066c55bc58cd6dd81e025df46e800cb09402dc7f62",
            "conversationId": "c9bd5af186c11371cf6fa2066c55bc58cd6dd81e025df46e800cb09402dc7f62",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-19T14:20:40.632Z",
            "endTime": "2022-05-19T14:22:13.094Z",
            "timeSpent": 92,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhSCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhSCAAAAAAAAA==/",
            "_etag": "\"8403e1af-0000-0100-0000-628669820000\"",
            "_attachments": "attachments/",
            "_ts": 1652976002
        },
        {
            "id": "3eba9aa1b4eed1b7c51a649ff1b4fe25adf2aedbe22f63224a71db93f48d0c02",
            "conversationId": "3eba9aa1b4eed1b7c51a649ff1b4fe25adf2aedbe22f63224a71db93f48d0c02",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-19T14:24:39.624Z",
            "endTime": "2022-05-19T14:25:24.474Z",
            "timeSpent": 45,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhTCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhTCAAAAAAAAA==/",
            "_etag": "\"8403e2af-0000-0100-0000-628669820000\"",
            "_attachments": "attachments/",
            "_ts": 1652976002
        },
        {
            "id": "8a44e3c1a8960951f163855159360f32898ccd9b2162615d8cddd7b31abe7a1a",
            "conversationId": "8a44e3c1a8960951f163855159360f32898ccd9b2162615d8cddd7b31abe7a1a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-19T14:25:54.57Z",
            "endTime": "2022-05-19T14:26:12.456Z",
            "timeSpent": 18,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhUCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhUCAAAAAAAAA==/",
            "_etag": "\"8403e3af-0000-0100-0000-628669820000\"",
            "_attachments": "attachments/",
            "_ts": 1652976002
        },
        {
            "id": "a7ca43e3bd7f57d3b22d2d0e092a08746d3d07a90103f372b68e58017bc96ba7",
            "conversationId": "a7ca43e3bd7f57d3b22d2d0e092a08746d3d07a90103f372b68e58017bc96ba7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-19T14:28:12.264Z",
            "endTime": "2022-05-19T14:28:42.094Z",
            "timeSpent": 30,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhVCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhVCAAAAAAAAA==/",
            "_etag": "\"8403e4af-0000-0100-0000-628669820000\"",
            "_attachments": "attachments/",
            "_ts": 1652976002
        },
        {
            "id": "01cf8c3b9159772ebf79fa274f602e57800c305426e52bbefcca5c5e8898fed6",
            "conversationId": "01cf8c3b9159772ebf79fa274f602e57800c305426e52bbefcca5c5e8898fed6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-19T14:29:09.877Z",
            "endTime": "2022-05-19T14:29:40.818Z",
            "timeSpent": 31,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhWCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhWCAAAAAAAAA==/",
            "_etag": "\"8403e5af-0000-0100-0000-628669820000\"",
            "_attachments": "attachments/",
            "_ts": 1652976002
        },
        {
            "id": "8b3177cb00ec806d7d1e44746a1302be7fadf0cbec730cd867ce53f2beb0a12a",
            "conversationId": "8b3177cb00ec806d7d1e44746a1302be7fadf0cbec730cd867ce53f2beb0a12a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-19T14:04:21.219Z",
            "endTime": "2022-05-19T14:05:02.466Z",
            "timeSpent": 41,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhXCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhXCAAAAAAAAA==/",
            "_etag": "\"8403e7af-0000-0100-0000-628669820000\"",
            "_attachments": "attachments/",
            "_ts": 1652976002
        },
        {
            "id": "595ccfeac25dcc20938ce3d90d46ee4974f49c00f3bec028ee004b20572250a0",
            "conversationId": "595ccfeac25dcc20938ce3d90d46ee4974f49c00f3bec028ee004b20572250a0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-19T14:08:26.9Z",
            "endTime": "2022-05-19T14:10:04.278Z",
            "timeSpent": 97,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhYCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhYCAAAAAAAAA==/",
            "_etag": "\"8403e8af-0000-0100-0000-628669820000\"",
            "_attachments": "attachments/",
            "_ts": 1652976002
        },
        {
            "id": "d4d1a52feed3b98475013423b54310c14b21f16852a0b8516eb4e1b3b9a2f8c1",
            "conversationId": "d4d1a52feed3b98475013423b54310c14b21f16852a0b8516eb4e1b3b9a2f8c1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-19T19:18:16.531Z",
            "endTime": "2022-05-19T19:20:27.926Z",
            "timeSpent": 131,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhZCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhZCAAAAAAAAA==/",
            "_etag": "\"98035ca7-0000-0100-0000-6286afd10000\"",
            "_attachments": "attachments/",
            "_ts": 1652994001
        },
        {
            "id": "06c2af74a25077b7e4fae5f2a7e2a1975264cbfbd5d9d6297d5beaccde5947ca",
            "conversationId": "06c2af74a25077b7e4fae5f2a7e2a1975264cbfbd5d9d6297d5beaccde5947ca",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [
                "Smoking",
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-19T19:10:21.369Z",
            "endTime": "2022-05-19T19:13:55.756Z",
            "timeSpent": 214,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhaCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhaCAAAAAAAAA==/",
            "_etag": "\"98035da7-0000-0100-0000-6286afd10000\"",
            "_attachments": "attachments/",
            "_ts": 1652994001
        },
        {
            "id": "54973a306898690dbb90d888b3949d3da5f73b5cc5bd91a1d53181b24a71b9d1",
            "conversationId": "54973a306898690dbb90d888b3949d3da5f73b5cc5bd91a1d53181b24a71b9d1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [
                "Smoking",
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-19T19:59:18.208Z",
            "endTime": "2022-05-19T20:01:55.821Z",
            "timeSpent": 158,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhbCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhbCAAAAAAAAA==/",
            "_etag": "\"9b036260-0000-0100-0000-6286bde10000\"",
            "_attachments": "attachments/",
            "_ts": 1652997601
        },
        {
            "id": "3798f8c061ec45f4a8c946a5962102961e9a10aa055bc2bf43ab994d066b611c",
            "conversationId": "3798f8c061ec45f4a8c946a5962102961e9a10aa055bc2bf43ab994d066b611c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-19T19:17:56.942Z",
            "endTime": "2022-05-19T19:17:56.942Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhcCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhcCAAAAAAAAA==/",
            "_etag": "\"98035fa7-0000-0100-0000-6286afd10000\"",
            "_attachments": "attachments/",
            "_ts": 1652994001
        },
        {
            "id": "03b00d30b0595a9de862114e9a599d7c182581a082dbe703324fb7abce01d16a",
            "conversationId": "03b00d30b0595a9de862114e9a599d7c182581a082dbe703324fb7abce01d16a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-05-19T20:02:11.214Z",
            "endTime": "2022-05-19T20:05:04.962Z",
            "timeSpent": 174,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhdCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhdCAAAAAAAAA==/",
            "_etag": "\"9b036360-0000-0100-0000-6286bde10000\"",
            "_attachments": "attachments/",
            "_ts": 1652997601
        },
        {
            "id": "41c0f6e183f57061f2f962092855476d76ba56a4bf692248b1ff745c99fbc46e",
            "conversationId": "41c0f6e183f57061f2f962092855476d76ba56a4bf692248b1ff745c99fbc46e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-19T20:08:45.779Z",
            "endTime": "2022-05-19T20:10:40.576Z",
            "timeSpent": 115,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIheCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIheCAAAAAAAAA==/",
            "_etag": "\"9b036460-0000-0100-0000-6286bde10000\"",
            "_attachments": "attachments/",
            "_ts": 1652997601
        },
        {
            "id": "0840a47b5934b4ec3622f3f0c6928d10bb6e04d9b86b72c793680f36eb529299",
            "conversationId": "0840a47b5934b4ec3622f3f0c6928d10bb6e04d9b86b72c793680f36eb529299",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-19T20:08:19.252Z",
            "endTime": "2022-05-19T20:08:19.252Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhfCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhfCAAAAAAAAA==/",
            "_etag": "\"9b036560-0000-0100-0000-6286bde10000\"",
            "_attachments": "attachments/",
            "_ts": 1652997601
        },
        {
            "id": "4f180ab5ec7a6ffe1b87f89cd342e08a26c07dcf875eea95244ce03fa90098fc",
            "conversationId": "4f180ab5ec7a6ffe1b87f89cd342e08a26c07dcf875eea95244ce03fa90098fc",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Fast heartbeat",
                "Sudden chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will call 9-1-1",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-05-20T06:54:21.114Z",
            "endTime": "2022-05-20T07:00:57.348Z",
            "timeSpent": 396,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhgCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhgCAAAAAAAAA==/",
            "_etag": "\"a70359e1-0000-0100-0000-628758910000\"",
            "_attachments": "attachments/",
            "_ts": 1653037201
        },
        {
            "id": "24cc1d1ecea94b2c6c8107588fecab270d74a274c8b0f07015c0533dace041cd",
            "conversationId": "24cc1d1ecea94b2c6c8107588fecab270d74a274c8b0f07015c0533dace041cd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Fast heartbeat"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the emergency room",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-05-20T06:38:09.106Z",
            "endTime": "2022-05-20T06:40:29.405Z",
            "timeSpent": 140,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhhCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhhCAAAAAAAAA==/",
            "_etag": "\"a6034fee-0000-0100-0000-62874b360000\"",
            "_attachments": "attachments/",
            "_ts": 1653033782
        },
        {
            "id": "154621a509830c2f8c75f32e0cc155e543b2aa56a4e46d7363ac992a9e842625",
            "conversationId": "154621a509830c2f8c75f32e0cc155e543b2aa56a4e46d7363ac992a9e842625",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-20T06:29:34.355Z",
            "endTime": "2022-05-20T06:29:34.355Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhiCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhiCAAAAAAAAA==/",
            "_etag": "\"a60350ee-0000-0100-0000-62874b360000\"",
            "_attachments": "attachments/",
            "_ts": 1653033782
        },
        {
            "id": "a542568b1f59788090dd627ed40a55375bd6a98df4f2b69b8ad625b8d543d7fe",
            "conversationId": "a542568b1f59788090dd627ed40a55375bd6a98df4f2b69b8ad625b8d543d7fe",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-20T06:30:00.855Z",
            "endTime": "2022-05-20T06:30:00.855Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhjCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhjCAAAAAAAAA==/",
            "_etag": "\"a60351ee-0000-0100-0000-62874b360000\"",
            "_attachments": "attachments/",
            "_ts": 1653033782
        },
        {
            "id": "e78284a633931363483caea736b1f7ddfdcd8878842267831e2acd814683415b",
            "conversationId": "e78284a633931363483caea736b1f7ddfdcd8878842267831e2acd814683415b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "startTime": "2022-05-20T09:29:58.779Z",
            "endTime": "2022-05-20T09:30:40.079Z",
            "timeSpent": 41,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhkCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhkCAAAAAAAAA==/",
            "_etag": "\"a903f3fe-0000-0100-0000-628774b10000\"",
            "_attachments": "attachments/",
            "_ts": 1653044401
        },
        {
            "id": "fe3b48ed3a22a02952b49ce48144b43ce47fb0fda66fe8cdebfe0c4e108e8de9",
            "conversationId": "fe3b48ed3a22a02952b49ce48144b43ce47fb0fda66fe8cdebfe0c4e108e8de9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-20T09:30:59.787Z",
            "endTime": "2022-05-20T09:32:28.856Z",
            "timeSpent": 89,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhlCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhlCAAAAAAAAA==/",
            "_etag": "\"a903f4fe-0000-0100-0000-628774b10000\"",
            "_attachments": "attachments/",
            "_ts": 1653044401
        },
        {
            "id": "d31921e2dd0a788b1994707517818cc6006fadbc5445e3ce8277673cd29aca19",
            "conversationId": "d31921e2dd0a788b1994707517818cc6006fadbc5445e3ce8277673cd29aca19",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-20T09:33:03.142Z",
            "endTime": "2022-05-20T09:33:03.142Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhmCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhmCAAAAAAAAA==/",
            "_etag": "\"a903f5fe-0000-0100-0000-628774b10000\"",
            "_attachments": "attachments/",
            "_ts": 1653044401
        },
        {
            "id": "3761289cddf562aeef859eb974e40a05718a186151afc36bf10a163ae33193b9",
            "conversationId": "3761289cddf562aeef859eb974e40a05718a186151afc36bf10a163ae33193b9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-20T09:33:13.355Z",
            "endTime": "2022-05-20T09:33:13.355Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhnCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhnCAAAAAAAAA==/",
            "_etag": "\"a903f6fe-0000-0100-0000-628774b10000\"",
            "_attachments": "attachments/",
            "_ts": 1653044401
        },
        {
            "id": "1df3bb3f30d66da0492d8f866d04178d5e89365359ff4c26fbefb5a61a101757",
            "conversationId": "1df3bb3f30d66da0492d8f866d04178d5e89365359ff4c26fbefb5a61a101757",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-20T09:32:49.478Z",
            "endTime": "2022-05-20T09:32:49.478Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhoCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhoCAAAAAAAAA==/",
            "_etag": "\"a903f7fe-0000-0100-0000-628774b10000\"",
            "_attachments": "attachments/",
            "_ts": 1653044401
        },
        {
            "id": "b801e1155e1fe33ec025dfb28eecf5d620acc68bbf4380445c6adccf3b9a2f3c",
            "conversationId": "b801e1155e1fe33ec025dfb28eecf5d620acc68bbf4380445c6adccf3b9a2f3c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-20T09:30:49.511Z",
            "endTime": "2022-05-20T09:30:49.511Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhpCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhpCAAAAAAAAA==/",
            "_etag": "\"a903f8fe-0000-0100-0000-628774b10000\"",
            "_attachments": "attachments/",
            "_ts": 1653044401
        },
        {
            "id": "c60183416bc9650818331685a64a23c2ec4cbe8d1fba21a2776e8d96dca4eb2d",
            "conversationId": "c60183416bc9650818331685a64a23c2ec4cbe8d1fba21a2776e8d96dca4eb2d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the emergency room",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-21T15:49:50.442Z",
            "endTime": "2022-05-21T15:51:58.919Z",
            "timeSpent": 128,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhqCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhqCAAAAAAAAA==/",
            "_etag": "\"db030a71-0000-0100-0000-62891a910000\"",
            "_attachments": "attachments/",
            "_ts": 1653152401
        },
        {
            "id": "6b3ce269884cd546993b50885994dfc18e2a183013a8519280352e7efb484dd7",
            "conversationId": "6b3ce269884cd546993b50885994dfc18e2a183013a8519280352e7efb484dd7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-21T15:49:40.123Z",
            "endTime": "2022-05-21T15:49:40.123Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhrCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhrCAAAAAAAAA==/",
            "_etag": "\"db030b71-0000-0100-0000-62891a910000\"",
            "_attachments": "attachments/",
            "_ts": 1653152401
        },
        {
            "id": "5e9a12ce269e506f5d3fbf02ef9ef99c55994fa597de0a2e84ab80e22065ebdf",
            "conversationId": "5e9a12ce269e506f5d3fbf02ef9ef99c55994fa597de0a2e84ab80e22065ebdf",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Sneezing",
                "Headache",
                "Cough",
                "Watery eyes"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-05-21T17:10:22.497Z",
            "endTime": "2022-05-21T17:12:14.42Z",
            "timeSpent": 112,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhsCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhsCAAAAAAAAA==/",
            "_etag": "\"dc0394da-0000-0100-0000-628936b10000\"",
            "_attachments": "attachments/",
            "_ts": 1653159601
        },
        {
            "id": "1ea6db3dfc05612d4aa15a13757d5779fd12c660b65d0e23ee54d5fcab1afb7a",
            "conversationId": "1ea6db3dfc05612d4aa15a13757d5779fd12c660b65d0e23ee54d5fcab1afb7a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-23T13:10:53.2Z",
            "endTime": "2022-05-23T13:27:25.52Z",
            "timeSpent": 992,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhtCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhtCAAAAAAAAA==/",
            "_etag": "\"0004a40c-0000-0100-0000-628ba1710000\"",
            "_attachments": "attachments/",
            "_ts": 1653318001
        },
        {
            "id": "7e3f7e29dae7eb9ed3974f0c44b58cbd4c28eeda5e232433cb7b6fbee2962596",
            "conversationId": "7e3f7e29dae7eb9ed3974f0c44b58cbd4c28eeda5e232433cb7b6fbee2962596",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-23T13:10:38.504Z",
            "endTime": "2022-05-23T13:10:38.504Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhuCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhuCAAAAAAAAA==/",
            "_etag": "\"0004a50c-0000-0100-0000-628ba1710000\"",
            "_attachments": "attachments/",
            "_ts": 1653318001
        },
        {
            "id": "7141383c5db83585675956d6e5192941bc9652b532a11bac354a1037f600d803",
            "conversationId": "7141383c5db83585675956d6e5192941bc9652b532a11bac354a1037f600d803",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the emergency room",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-23T20:06:44.189Z",
            "endTime": "2022-05-23T20:09:16.416Z",
            "timeSpent": 152,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhvCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhvCAAAAAAAAA==/",
            "_etag": "\"18049a71-0000-0100-0000-628c03e10000\"",
            "_attachments": "attachments/",
            "_ts": 1653343201
        },
        {
            "id": "6a53b08cd45ea00bf8181cd3d8ce9cf227bbb43db5905a2bbe933be2f2b68453",
            "conversationId": "6a53b08cd45ea00bf8181cd3d8ce9cf227bbb43db5905a2bbe933be2f2b68453",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-05-23T23:25:37.124Z",
            "endTime": "2022-05-23T23:28:30.779Z",
            "timeSpent": 174,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhwCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhwCAAAAAAAAA==/",
            "_etag": "\"1d04da00-0000-0100-0000-628c2e120000\"",
            "_attachments": "attachments/",
            "_ts": 1653354002
        },
        {
            "id": "2095aa68da42907b40fdf089cf7c3e97274e3e68d347e26b3c0acb8148529991",
            "conversationId": "2095aa68da42907b40fdf089cf7c3e97274e3e68d347e26b3c0acb8148529991",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-25T22:29:40.287Z",
            "endTime": "2022-05-25T22:30:18.488Z",
            "timeSpent": 38,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhxCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhxCAAAAAAAAA==/",
            "_etag": "\"8b047b22-0000-0100-0000-628ed1110000\"",
            "_attachments": "attachments/",
            "_ts": 1653526801
        },
        {
            "id": "7e5a2e69591286b4b2fbe37b023a965c7fcab8cff4c962fa25e029e40be5ed76",
            "conversationId": "7e5a2e69591286b4b2fbe37b023a965c7fcab8cff4c962fa25e029e40be5ed76",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-05-25T22:30:30.898Z",
            "endTime": "2022-05-25T22:30:30.898Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhyCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhyCAAAAAAAAA==/",
            "_etag": "\"8b047c22-0000-0100-0000-628ed1110000\"",
            "_attachments": "attachments/",
            "_ts": 1653526801
        },
        {
            "id": "22955a0a153202dd133f9451e2afed5342ee3f2d6b551c8427e4757ff862b37f",
            "conversationId": "22955a0a153202dd133f9451e2afed5342ee3f2d6b551c8427e4757ff862b37f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache",
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-06-02T13:14:09.643Z",
            "endTime": "2022-06-02T13:15:57.662Z",
            "timeSpent": 108,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhzCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhzCAAAAAAAAA==/",
            "_etag": "\"a405716f-0000-0100-0000-6298d0730000\"",
            "_attachments": "attachments/",
            "_ts": 1654182003
        },
        {
            "id": "3ca7cc2037a54c5002110bcfeb37524b43dcd97e127e63307571815911e0eb3b",
            "conversationId": "3ca7cc2037a54c5002110bcfeb37524b43dcd97e127e63307571815911e0eb3b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-06-02T16:26:58.803Z",
            "endTime": "2022-06-02T16:26:58.803Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh0CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh0CAAAAAAAAA==/",
            "_etag": "\"b005e4a2-0000-0100-0000-6298faa10000\"",
            "_attachments": "attachments/",
            "_ts": 1654192801
        },
        {
            "id": "465c26e725b13c430824f048dfd32fd750f1d6c57f8875e2ddf3ad1755ed887e",
            "conversationId": "465c26e725b13c430824f048dfd32fd750f1d6c57f8875e2ddf3ad1755ed887e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache",
                "Cough",
                "Runny nose",
                "Watery eyes",
                "Sneezing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the emergency room",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-06-06T15:32:26.645Z",
            "endTime": "2022-06-06T15:37:34.957Z",
            "timeSpent": 308,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh1CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh1CAAAAAAAAA==/",
            "_etag": "\"2706b18a-0000-0100-0000-629e32910000\"",
            "_attachments": "attachments/",
            "_ts": 1654534801
        },
        {
            "id": "8fa4a6ae242602e7ce693a79909c725f76880d111535d328e091df3c0884d8b2",
            "conversationId": "8fa4a6ae242602e7ce693a79909c725f76880d111535d328e091df3c0884d8b2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 89,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will go to the emergency room",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-06-06T15:46:02.599Z",
            "endTime": "2022-06-06T15:51:49.48Z",
            "timeSpent": 347,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh2CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh2CAAAAAAAAA==/",
            "_etag": "\"2706b28a-0000-0100-0000-629e32910000\"",
            "_attachments": "attachments/",
            "_ts": 1654534801
        },
        {
            "id": "386a53157072cdfba24461015676dbfcce93c61929e5b559b5b9ab16c490a50f",
            "conversationId": "386a53157072cdfba24461015676dbfcce93c61929e5b559b5b9ab16c490a50f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-06-06T15:43:19.155Z",
            "endTime": "2022-06-06T15:43:19.155Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh3CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh3CAAAAAAAAA==/",
            "_etag": "\"2b06705e-0000-0100-0000-629e40a30000\"",
            "_attachments": "attachments/",
            "_ts": 1654538403
        },
        {
            "id": "ff0e4879680aa25c084fd2d96ee772121b14a0fbbc5078fe1ec497122b67cf08",
            "conversationId": "ff0e4879680aa25c084fd2d96ee772121b14a0fbbc5078fe1ec497122b67cf08",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-06-07T20:56:39.442Z",
            "endTime": "2022-06-07T20:56:39.442Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh4CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh4CAAAAAAAAA==/",
            "_etag": "\"7006dafc-0000-0100-0000-629fd8ae0000\"",
            "_attachments": "attachments/",
            "_ts": 1654642862
        },
        {
            "id": "ff32b65d1bfed485aaafe86e737e4b09c1686af8c3fb8985332169ee020ba567",
            "conversationId": "ff32b65d1bfed485aaafe86e737e4b09c1686af8c3fb8985332169ee020ba567",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Earache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-06-21T16:41:12.364Z",
            "endTime": "2022-06-21T16:41:50.143Z",
            "timeSpent": 38,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh5CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh5CAAAAAAAAA==/",
            "_etag": "\"b60052a9-0000-0100-0000-62b215320000\"",
            "_attachments": "attachments/",
            "_ts": 1655838002
        },
        {
            "id": "8971a6d5452ee67c76a453dd8530c19c1c735ce3aacd27d723b55b574be423cf",
            "conversationId": "8971a6d5452ee67c76a453dd8530c19c1c735ce3aacd27d723b55b574be423cf",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Earache",
                "Pain behind ear",
                "Swelling inside the ear"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-06-21T20:15:21.446Z",
            "endTime": "2022-06-21T20:18:04.038Z",
            "timeSpent": 163,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh6CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh6CAAAAAAAAA==/",
            "_etag": "\"bc00018e-0000-0100-0000-62b23f610000\"",
            "_attachments": "attachments/",
            "_ts": 1655848801
        },
        {
            "id": "574bde0c14e465008fea4c1cad677a7b2f5819095c3182dd7da4b33090050406",
            "conversationId": "574bde0c14e465008fea4c1cad677a7b2f5819095c3182dd7da4b33090050406",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Clogged ear",
                "Earache",
                "Swelling inside the ear"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-06-21T20:39:32.021Z",
            "endTime": "2022-06-21T20:41:21.074Z",
            "timeSpent": 109,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh7CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh7CAAAAAAAAA==/",
            "_etag": "\"bd006dcd-0000-0100-0000-62b24dae0000\"",
            "_attachments": "attachments/",
            "_ts": 1655852462
        },
        {
            "id": "c3da40a44cd567a3af74411cffb7395dc205127700a33088923560c2d85c4a66",
            "conversationId": "c3da40a44cd567a3af74411cffb7395dc205127700a33088923560c2d85c4a66",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-06-22T12:23:58.103Z",
            "endTime": "2022-06-22T12:24:01.756Z",
            "timeSpent": 4,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh8CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh8CAAAAAAAAA==/",
            "_etag": "\"cf00f03c-0000-0100-0000-62b320620000\"",
            "_attachments": "attachments/",
            "_ts": 1655906402
        },
        {
            "id": "269f28e7645a49a94a2d5ac2b099f9bb42b686343caf67d4440209d875394d16",
            "conversationId": "269f28e7645a49a94a2d5ac2b099f9bb42b686343caf67d4440209d875394d16",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-06-22T12:24:17.161Z",
            "endTime": "2022-06-22T12:24:17.161Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh9CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh9CAAAAAAAAA==/",
            "_etag": "\"cf00f13c-0000-0100-0000-62b320620000\"",
            "_attachments": "attachments/",
            "_ts": 1655906402
        },
        {
            "id": "4b86e03e514b8ed709a84cce35e7add75fb47afcd01baecd41bf3585d136efe5",
            "conversationId": "4b86e03e514b8ed709a84cce35e7add75fb47afcd01baecd41bf3585d136efe5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Knee pain",
                "Swollen knee",
                "Limping due to pain"
            ],
            "riskFactors": [
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "startTime": "2022-06-22T12:29:07.831Z",
            "endTime": "2022-06-22T12:31:26.99Z",
            "timeSpent": 139,
            "isNoteGenerated": "yes",
            "npsGroup": "Passive",
            "npsScore": 8,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh+CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh+CAAAAAAAAA==/",
            "_etag": "\"cf00f23c-0000-0100-0000-62b320620000\"",
            "_attachments": "attachments/",
            "_ts": 1655906402
        },
        {
            "id": "4ff2d5bb0700f1ee550c575016fdda2b9e79653830ebf944e207005ed613249a",
            "conversationId": "4ff2d5bb0700f1ee550c575016fdda2b9e79653830ebf944e207005ed613249a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "startTime": "2022-06-22T12:26:26.835Z",
            "endTime": "2022-06-22T12:28:35.328Z",
            "timeSpent": 128,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh-CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh-CAAAAAAAAA==/",
            "_etag": "\"cf00f33c-0000-0100-0000-62b320620000\"",
            "_attachments": "attachments/",
            "_ts": 1655906402
        },
        {
            "id": "59275234230672ed037350fa900ba2533290c580bd5b8af5ccb1a17d9a6be04f",
            "conversationId": "59275234230672ed037350fa900ba2533290c580bd5b8af5ccb1a17d9a6be04f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Watery eyes",
                "Runny nose",
                "Itchy throat or nose",
                "Itchy skin"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-06-22T12:35:21.148Z",
            "endTime": "2022-06-22T12:39:35.561Z",
            "timeSpent": 254,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiACAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiACAAAAAAAAA==/",
            "_etag": "\"cf00f43c-0000-0100-0000-62b320620000\"",
            "_attachments": "attachments/",
            "_ts": 1655906402
        },
        {
            "id": "98154a5a1430a4556bb93de63862b5f29c32fb2b26d83dc03840ae34d528036b",
            "conversationId": "98154a5a1430a4556bb93de63862b5f29c32fb2b26d83dc03840ae34d528036b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": "I will go to ReadyMED / urgent care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "startTime": "2022-06-22T12:24:27.102Z",
            "endTime": "2022-06-22T12:25:54.978Z",
            "timeSpent": 88,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiBCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiBCAAAAAAAAA==/",
            "_etag": "\"cf00f53c-0000-0100-0000-62b320620000\"",
            "_attachments": "attachments/",
            "_ts": 1655906402
        },
        {
            "id": "35b8960f6c96704cf5b55aebe0bbe82b485c0b7f32f051efbf415bc67e17f1c8",
            "conversationId": "35b8960f6c96704cf5b55aebe0bbe82b485c0b7f32f051efbf415bc67e17f1c8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Wrist pain"
            ],
            "riskFactors": [
                "Smoking",
                "History of anaphylactic reactions"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-06-27T14:58:49.104Z",
            "endTime": "2022-06-27T15:02:13.632Z",
            "timeSpent": 205,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiCCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiCCAAAAAAAAA==/",
            "_etag": "\"3200b5b5-0000-0100-0000-62b9e2110000\"",
            "_attachments": "attachments/",
            "_ts": 1656349201
        },
        {
            "id": "b908172a754e00dc7562320a152ebbf3626babb4d1cb0a39489d3850e6a95348",
            "conversationId": "b908172a754e00dc7562320a152ebbf3626babb4d1cb0a39489d3850e6a95348",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-06-27T14:00:00Z",
            "endTime": "2022-06-27T14:58:35.706Z",
            "timeSpent": 3516,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiDCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiDCAAAAAAAAA==/",
            "_etag": "\"2f008546-0000-0100-0000-62b9d4010000\"",
            "_attachments": "attachments/",
            "_ts": 1656345601
        },
        {
            "id": "5c17a1d4ec07102c463bf09ff6cf10b9b1cd5b00e226600b0993b2b0e8cc27f5",
            "conversationId": "5c17a1d4ec07102c463bf09ff6cf10b9b1cd5b00e226600b0993b2b0e8cc27f5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "startTime": "2022-06-27T15:24:38.354Z",
            "endTime": "2022-06-27T15:37:46.972Z",
            "timeSpent": 789,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiECAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiECAAAAAAAAA==/",
            "_etag": "\"3200b4b5-0000-0100-0000-62b9e2110000\"",
            "_attachments": "attachments/",
            "_ts": 1656349201
        },
        {
            "id": "971c36373b1e17e4b379102cdb7357c93efbbf2b058013a469b38c782c2c8d0b",
            "conversationId": "971c36373b1e17e4b379102cdb7357c93efbbf2b058013a469b38c782c2c8d0b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-06-27T20:32:56.94Z",
            "endTime": "2022-06-27T20:32:56.94Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiFCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiFCAAAAAAAAA==/",
            "_etag": "\"4400063a-0000-0100-0000-62ba36710000\"",
            "_attachments": "attachments/",
            "_ts": 1656370801
        },
        {
            "id": "7269772a8203f3443ee0b0cac88471a2cc612cf5f4f8f632e9e68f61dc07ad7e",
            "conversationId": "7269772a8203f3443ee0b0cac88471a2cc612cf5f4f8f632e9e68f61dc07ad7e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Wrist pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-06-28T15:57:24.82Z",
            "endTime": "2022-06-28T16:03:21.942Z",
            "timeSpent": 357,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiGCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiGCAAAAAAAAA==/",
            "_etag": "\"6b005f97-0000-0100-0000-62bb41a30000\"",
            "_attachments": "attachments/",
            "_ts": 1656439203
        },
        {
            "id": "e46406df26991fb8adb3705bb5c378bd9cdc5d1208f6cd38e7f1fd517d1cae4d",
            "conversationId": "e46406df26991fb8adb3705bb5c378bd9cdc5d1208f6cd38e7f1fd517d1cae4d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 80,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Wrist pain"
            ],
            "riskFactors": [
                "Diabetes"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-06-28T16:47:01.855Z",
            "endTime": "2022-06-28T16:50:31.864Z",
            "timeSpent": 210,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiHCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiHCAAAAAAAAA==/",
            "_etag": "\"6b006097-0000-0100-0000-62bb41a30000\"",
            "_attachments": "attachments/",
            "_ts": 1656439203
        },
        {
            "id": "c51f5bf5ecb67df3e012e76ef507690d1e3ceed46b919dec4796f0c0bf9c2834",
            "conversationId": "c51f5bf5ecb67df3e012e76ef507690d1e3ceed46b919dec4796f0c0bf9c2834",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Wrist pain"
            ],
            "riskFactors": [
                "Smoking",
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-06-28T16:30:42.207Z",
            "endTime": "2022-06-28T16:33:53.883Z",
            "timeSpent": 192,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiICAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiICAAAAAAAAA==/",
            "_etag": "\"6b006197-0000-0100-0000-62bb41a30000\"",
            "_attachments": "attachments/",
            "_ts": 1656439203
        },
        {
            "id": "cf74a24ba8cc8781c0b086c334ef8a33d14107dccea0ff80d4e4aaca76fe405f",
            "conversationId": "cf74a24ba8cc8781c0b086c334ef8a33d14107dccea0ff80d4e4aaca76fe405f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 55,
            "ageGroup": "56-65",
            "state": "Virginia",
            "chiefComplaint": [
                "Wrist pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-06-28T16:38:05.848Z",
            "endTime": "2022-06-28T16:41:24.705Z",
            "timeSpent": 199,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiJCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiJCAAAAAAAAA==/",
            "_etag": "\"6b006397-0000-0100-0000-62bb41a30000\"",
            "_attachments": "attachments/",
            "_ts": 1656439203
        },
        {
            "id": "f2d1735ad64b2db63b521b647f9f5f2b156f46972b0a3f7672fa9e1d911b430a",
            "conversationId": "f2d1735ad64b2db63b521b647f9f5f2b156f46972b0a3f7672fa9e1d911b430a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 17,
            "ageGroup": "18-25",
            "state": "Virginia",
            "chiefComplaint": [
                "Wrist pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-06-28T16:44:08.58Z",
            "endTime": "2022-06-28T16:44:34.783Z",
            "timeSpent": 26,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiKCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiKCAAAAAAAAA==/",
            "_etag": "\"6f002d38-0000-0100-0000-62bb4fb10000\"",
            "_attachments": "attachments/",
            "_ts": 1656442801
        },
        {
            "id": "f5c975a029e1005d83375bb30c0aee4e5535b8c77629fa4ce31d3276adc0e544",
            "conversationId": "f5c975a029e1005d83375bb30c0aee4e5535b8c77629fa4ce31d3276adc0e544",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Wrist pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "startTime": "2022-06-28T16:12:46.662Z",
            "endTime": "2022-06-28T16:17:12.218Z",
            "timeSpent": 266,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiLCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiLCAAAAAAAAA==/",
            "_etag": "\"6b005e97-0000-0100-0000-62bb41a30000\"",
            "_attachments": "attachments/",
            "_ts": 1656439203
        },
        {
            "id": "862509a145a40e5b38e56d06985c20f70494baa4e38930db31de8ed76afc3c7b",
            "conversationId": "862509a145a40e5b38e56d06985c20f70494baa4e38930db31de8ed76afc3c7b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-06-28T22:12:33.553Z",
            "endTime": "2022-06-28T22:12:33.553Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiMCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiMCAAAAAAAAA==/",
            "_etag": "\"7b008f43-0000-0100-0000-62bb96010000\"",
            "_attachments": "attachments/",
            "_ts": 1656460801
        },
        {
            "id": "3d181ec6c334b4fc47698ae9e9867204ddfbb1ce887229c7a80d9f1db04e102e",
            "conversationId": "3d181ec6c334b4fc47698ae9e9867204ddfbb1ce887229c7a80d9f1db04e102e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "startTime": "2022-07-01T14:57:32.561Z",
            "endTime": "2022-07-01T14:57:32.561Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiNCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiNCAAAAAAAAA==/",
            "_etag": "\"f70039e4-0000-0100-0000-62bf28110000\"",
            "_attachments": "attachments/",
            "_ts": 1656694801
        },
        {
            "id": "2cf05f6239137de40df70088b6fb3a026b64b573776917248dc5c543903a4b63",
            "conversationId": "2cf05f6239137de40df70088b6fb3a026b64b573776917248dc5c543903a4b63",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-05T23:15:11.321Z",
            "endTime": "2022-07-05T23:15:11.321Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiOCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiOCAAAAAAAAA==/",
            "_etag": "\"5701e6fe-0000-0100-0000-62c4de920000\"",
            "_attachments": "attachments/",
            "_ts": 1657069202
        },
        {
            "id": "9eecc0cc5633e5f8970aa0d43a303e3a05efe28751d5df4a4550a87f288f5638",
            "conversationId": "9eecc0cc5633e5f8970aa0d43a303e3a05efe28751d5df4a4550a87f288f5638",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T11:38:57.271Z",
            "endTime": "2022-07-06T11:38:57.271Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiPCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiPCAAAAAAAAA==/",
            "_etag": "\"68013f5c-0000-0100-0000-62c595610000\"",
            "_attachments": "attachments/",
            "_ts": 1657116001
        },
        {
            "id": "7db38134013f78b5c964b4aee454336492647feaa0ae6030f9d5c56f875c2dc2",
            "conversationId": "7db38134013f78b5c964b4aee454336492647feaa0ae6030f9d5c56f875c2dc2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T11:46:02.241Z",
            "endTime": "2022-07-06T11:46:02.241Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiQCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiQCAAAAAAAAA==/",
            "_etag": "\"6801405c-0000-0100-0000-62c595610000\"",
            "_attachments": "attachments/",
            "_ts": 1657116001
        },
        {
            "id": "60f22f65283abb04c151510ee9b2875daf2c2677388f75a13c5027eaf603ff4c",
            "conversationId": "60f22f65283abb04c151510ee9b2875daf2c2677388f75a13c5027eaf603ff4c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T12:05:18.963Z",
            "endTime": "2022-07-06T12:05:18.963Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiRCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiRCAAAAAAAAA==/",
            "_etag": "\"68013e5c-0000-0100-0000-62c595610000\"",
            "_attachments": "attachments/",
            "_ts": 1657116001
        },
        {
            "id": "391029523919d05e2a2987c204828feb6ba14a65d32348042f706425271bb0a9",
            "conversationId": "391029523919d05e2a2987c204828feb6ba14a65d32348042f706425271bb0a9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T17:00:00Z",
            "endTime": "2022-07-06T17:26:01.614Z",
            "timeSpent": 1562,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiSCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiSCAAAAAAAAA==/",
            "_etag": "\"7a011abf-0000-0100-0000-62c5dbb20000\"",
            "_attachments": "attachments/",
            "_ts": 1657134002
        },
        {
            "id": "41082828725ad8c2c05ef4ac1c6e7f717b2bdb4ac55a8847034bed4c82850231",
            "conversationId": "41082828725ad8c2c05ef4ac1c6e7f717b2bdb4ac55a8847034bed4c82850231",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T17:00:00Z",
            "endTime": "2022-07-06T17:19:08.094Z",
            "timeSpent": 1148,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiTCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiTCAAAAAAAAA==/",
            "_etag": "\"7a0118bf-0000-0100-0000-62c5dbb20000\"",
            "_attachments": "attachments/",
            "_ts": 1657134002
        },
        {
            "id": "aa42c90e8b8a205ff6399196f7b6ce73157724c4e2147cbc787a24f141befd59",
            "conversationId": "aa42c90e8b8a205ff6399196f7b6ce73157724c4e2147cbc787a24f141befd59",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T17:00:00Z",
            "endTime": "2022-07-06T17:17:44.845Z",
            "timeSpent": 1065,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiUCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiUCAAAAAAAAA==/",
            "_etag": "\"7a011bbf-0000-0100-0000-62c5dbb20000\"",
            "_attachments": "attachments/",
            "_ts": 1657134002
        },
        {
            "id": "8be250a6ee744b70c4f7a475eaf47c0b171a399d088866c349fc1a7183ed56bd",
            "conversationId": "8be250a6ee744b70c4f7a475eaf47c0b171a399d088866c349fc1a7183ed56bd",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T17:00:00Z",
            "endTime": "2022-07-06T17:32:14.295Z",
            "timeSpent": 1934,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiVCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiVCAAAAAAAAA==/",
            "_etag": "\"7a0122bf-0000-0100-0000-62c5dbb20000\"",
            "_attachments": "attachments/",
            "_ts": 1657134002
        },
        {
            "id": "a23c9aff89e87a49cfaf473504706acf42005e835d7665f700dc90fd7c28f059",
            "conversationId": "a23c9aff89e87a49cfaf473504706acf42005e835d7665f700dc90fd7c28f059",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T17:00:00Z",
            "endTime": "2022-07-06T17:16:46.693Z",
            "timeSpent": 1007,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiWCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiWCAAAAAAAAA==/",
            "_etag": "\"7a011fbf-0000-0100-0000-62c5dbb20000\"",
            "_attachments": "attachments/",
            "_ts": 1657134002
        },
        {
            "id": "34c8d36d0f30b7e3806fe0944380133b8ae162b82932e8d1f9a53e7f15e9c031",
            "conversationId": "34c8d36d0f30b7e3806fe0944380133b8ae162b82932e8d1f9a53e7f15e9c031",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T17:00:00Z",
            "endTime": "2022-07-06T17:27:12.82Z",
            "timeSpent": 1633,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiXCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiXCAAAAAAAAA==/",
            "_etag": "\"7a0117bf-0000-0100-0000-62c5dbb20000\"",
            "_attachments": "attachments/",
            "_ts": 1657134002
        },
        {
            "id": "1a8a9bc0a2bb4f377946c43f43b5440dd7695dc214badfcb1f2f9274c3c8bce8",
            "conversationId": "1a8a9bc0a2bb4f377946c43f43b5440dd7695dc214badfcb1f2f9274c3c8bce8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T17:00:00Z",
            "endTime": "2022-07-06T17:24:46.804Z",
            "timeSpent": 1487,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiYCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiYCAAAAAAAAA==/",
            "_etag": "\"7a011ebf-0000-0100-0000-62c5dbb20000\"",
            "_attachments": "attachments/",
            "_ts": 1657134002
        },
        {
            "id": "08a06c171dbb6e7114ec6e466f80e89d8cea1875dd0761e0fa23a774f9259f34",
            "conversationId": "08a06c171dbb6e7114ec6e466f80e89d8cea1875dd0761e0fa23a774f9259f34",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T17:00:00Z",
            "endTime": "2022-07-06T17:15:42.978Z",
            "timeSpent": 943,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiZCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiZCAAAAAAAAA==/",
            "_etag": "\"7a011dbf-0000-0100-0000-62c5dbb20000\"",
            "_attachments": "attachments/",
            "_ts": 1657134002
        },
        {
            "id": "51761fe1f80ba383970685240968eb99d40f1d3941eaaea07d733bc4f684d168",
            "conversationId": "51761fe1f80ba383970685240968eb99d40f1d3941eaaea07d733bc4f684d168",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T17:00:00Z",
            "endTime": "2022-07-06T17:15:00.212Z",
            "timeSpent": 900,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiaCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiaCAAAAAAAAA==/",
            "_etag": "\"7a0119bf-0000-0100-0000-62c5dbb20000\"",
            "_attachments": "attachments/",
            "_ts": 1657134002
        },
        {
            "id": "b9f1e416d0d7a6df401149ef143af029db250e377a8aac1db05f5ccfd1b4a70b",
            "conversationId": "b9f1e416d0d7a6df401149ef143af029db250e377a8aac1db05f5ccfd1b4a70b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T17:00:00Z",
            "endTime": "2022-07-06T17:17:09.815Z",
            "timeSpent": 1030,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIibCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIibCAAAAAAAAA==/",
            "_etag": "\"7a011cbf-0000-0100-0000-62c5dbb20000\"",
            "_attachments": "attachments/",
            "_ts": 1657134002
        },
        {
            "id": "4f1b980457f3ceb24d50ee09b07afc372d0b47222d8ff0429cabaf2a2e174815",
            "conversationId": "4f1b980457f3ceb24d50ee09b07afc372d0b47222d8ff0429cabaf2a2e174815",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T17:00:00Z",
            "endTime": "2022-07-06T17:20:54.653Z",
            "timeSpent": 1255,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIicCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIicCAAAAAAAAA==/",
            "_etag": "\"7a0120bf-0000-0100-0000-62c5dbb20000\"",
            "_attachments": "attachments/",
            "_ts": 1657134002
        },
        {
            "id": "bea300b74b9732308c2cd48438885e2fc392f12e2431b97ddffd58dd61b8c350",
            "conversationId": "bea300b74b9732308c2cd48438885e2fc392f12e2431b97ddffd58dd61b8c350",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T17:00:00Z",
            "endTime": "2022-07-06T17:15:11.563Z",
            "timeSpent": 912,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIidCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIidCAAAAAAAAA==/",
            "_etag": "\"7a0121bf-0000-0100-0000-62c5dbb20000\"",
            "_attachments": "attachments/",
            "_ts": 1657134002
        },
        {
            "id": "b1a97f3e0e56889326ac1a08bfd30104592132ffe41d5fbe0e174c419d208fa8",
            "conversationId": "b1a97f3e0e56889326ac1a08bfd30104592132ffe41d5fbe0e174c419d208fa8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Runny nose",
                "Watery eyes"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "completedScheduling": null,
            "startTime": "2022-07-06T18:02:07.335Z",
            "endTime": "2022-07-06T18:12:22.59Z",
            "timeSpent": 615,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIieCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIieCAAAAAAAAA==/",
            "_etag": "\"7e015509-0000-0100-0000-62c5e9c30000\"",
            "_attachments": "attachments/",
            "_ts": 1657137603
        },
        {
            "id": "8a4b4035bb30b115f51af2d50faa918c6c37914ebd89b541fe9c22ac9e4e7153",
            "conversationId": "8a4b4035bb30b115f51af2d50faa918c6c37914ebd89b541fe9c22ac9e4e7153",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Earache",
                "Watery eyes",
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T18:00:40.471Z",
            "endTime": "2022-07-06T18:01:41.265Z",
            "timeSpent": 61,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIifCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIifCAAAAAAAAA==/",
            "_etag": "\"7e015409-0000-0100-0000-62c5e9c30000\"",
            "_attachments": "attachments/",
            "_ts": 1657137603
        },
        {
            "id": "852e933d8cd0bb655c827f39c782f51ff56be96711b2b0e5ea377f667807e7d6",
            "conversationId": "852e933d8cd0bb655c827f39c782f51ff56be96711b2b0e5ea377f667807e7d6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T18:00:00Z",
            "endTime": "2022-07-06T18:00:25.801Z",
            "timeSpent": 26,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIigCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIigCAAAAAAAAA==/",
            "_etag": "\"7e015609-0000-0100-0000-62c5e9c30000\"",
            "_attachments": "attachments/",
            "_ts": 1657137603
        },
        {
            "id": "a7cb2e29dfd704ab563062d914f312b62e19fb0d3ea07c04b05711ae1a531119",
            "conversationId": "a7cb2e29dfd704ab563062d914f312b62e19fb0d3ea07c04b05711ae1a531119",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T18:29:34.606Z",
            "endTime": "2022-07-06T18:29:34.606Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIihCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIihCAAAAAAAAA==/",
            "_etag": "\"7e015209-0000-0100-0000-62c5e9c30000\"",
            "_attachments": "attachments/",
            "_ts": 1657137603
        },
        {
            "id": "4a3881ff07e6fd6c10b3689e66d6a4546c7ec7bc35aa22b1428ccbdd5884809b",
            "conversationId": "4a3881ff07e6fd6c10b3689e66d6a4546c7ec7bc35aa22b1428ccbdd5884809b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T18:00:00Z",
            "endTime": "2022-07-06T18:37:28.258Z",
            "timeSpent": 2248,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiiCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiiCAAAAAAAAA==/",
            "_etag": "\"7e014c09-0000-0100-0000-62c5e9c30000\"",
            "_attachments": "attachments/",
            "_ts": 1657137603
        },
        {
            "id": "4d774b0e851668cf77f9b44665f3c42435f0102d76a5cb0c7e3f4eca163eb330",
            "conversationId": "4d774b0e851668cf77f9b44665f3c42435f0102d76a5cb0c7e3f4eca163eb330",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T18:00:00Z",
            "endTime": "2022-07-06T18:37:47.78Z",
            "timeSpent": 2268,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIijCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIijCAAAAAAAAA==/",
            "_etag": "\"7e014f09-0000-0100-0000-62c5e9c30000\"",
            "_attachments": "attachments/",
            "_ts": 1657137603
        },
        {
            "id": "12e54862158017add5f314937bce9b41734bccd22e64b0b03b6018eb1b84cb68",
            "conversationId": "12e54862158017add5f314937bce9b41734bccd22e64b0b03b6018eb1b84cb68",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T18:00:00Z",
            "endTime": "2022-07-06T18:38:38.753Z",
            "timeSpent": 2319,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIikCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIikCAAAAAAAAA==/",
            "_etag": "\"7e014d09-0000-0100-0000-62c5e9c30000\"",
            "_attachments": "attachments/",
            "_ts": 1657137603
        },
        {
            "id": "27003dfc41b48dc2fe6c90a6a7238d6e9cf1afe88ca042a9367348968e096de8",
            "conversationId": "27003dfc41b48dc2fe6c90a6a7238d6e9cf1afe88ca042a9367348968e096de8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T18:00:00Z",
            "endTime": "2022-07-06T18:40:11.025Z",
            "timeSpent": 2411,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIilCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIilCAAAAAAAAA==/",
            "_etag": "\"7e015309-0000-0100-0000-62c5e9c30000\"",
            "_attachments": "attachments/",
            "_ts": 1657137603
        },
        {
            "id": "74b6ce22dcb96415873bea7d8079964a2738c7303d0ea7175bfabce11053043d",
            "conversationId": "74b6ce22dcb96415873bea7d8079964a2738c7303d0ea7175bfabce11053043d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T18:00:00Z",
            "endTime": "2022-07-06T18:41:18.671Z",
            "timeSpent": 2479,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIimCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIimCAAAAAAAAA==/",
            "_etag": "\"7e014e09-0000-0100-0000-62c5e9c30000\"",
            "_attachments": "attachments/",
            "_ts": 1657137603
        },
        {
            "id": "40ba692739f638544672fa8ce0d87779515b6aeb7ac0b5c37ea740d260cad787",
            "conversationId": "40ba692739f638544672fa8ce0d87779515b6aeb7ac0b5c37ea740d260cad787",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T18:00:00Z",
            "endTime": "2022-07-06T18:41:46.409Z",
            "timeSpent": 2506,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIinCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIinCAAAAAAAAA==/",
            "_etag": "\"7e015109-0000-0100-0000-62c5e9c30000\"",
            "_attachments": "attachments/",
            "_ts": 1657137603
        },
        {
            "id": "1767192c7bb2ed503e9f81a7fe9e555e323aeedff7069b8705f5f33417850a69",
            "conversationId": "1767192c7bb2ed503e9f81a7fe9e555e323aeedff7069b8705f5f33417850a69",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T18:00:00Z",
            "endTime": "2022-07-06T18:46:38.641Z",
            "timeSpent": 2799,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIioCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIioCAAAAAAAAA==/",
            "_etag": "\"7e015009-0000-0100-0000-62c5e9c30000\"",
            "_attachments": "attachments/",
            "_ts": 1657137603
        },
        {
            "id": "590de17e84a0275177a8ad3dbb241d7f9f03200c4c489bde38b66955549bbf86",
            "conversationId": "590de17e84a0275177a8ad3dbb241d7f9f03200c4c489bde38b66955549bbf86",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T19:00:00Z",
            "endTime": "2022-07-06T19:26:18.998Z",
            "timeSpent": 1579,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIipCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIipCAAAAAAAAA==/",
            "_etag": "\"8101ab20-0000-0100-0000-62c5f7d10000\"",
            "_attachments": "attachments/",
            "_ts": 1657141201
        },
        {
            "id": "7f91016c8e527b40d786b040a7bca5604112528bbed0eafafacba9661e6cedbe",
            "conversationId": "7f91016c8e527b40d786b040a7bca5604112528bbed0eafafacba9661e6cedbe",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-06T19:00:00Z",
            "endTime": "2022-07-06T19:18:56.111Z",
            "timeSpent": 1136,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiqCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiqCAAAAAAAAA==/",
            "_etag": "\"8101ac20-0000-0100-0000-62c5f7d10000\"",
            "_attachments": "attachments/",
            "_ts": 1657141201
        },
        {
            "id": "7ea9ffe1470fa1b1b42616d7102c5fca1f3bbfd2653d74551b47de0dfa8e79d7",
            "conversationId": "7ea9ffe1470fa1b1b42616d7102c5fca1f3bbfd2653d74551b47de0dfa8e79d7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-07T16:00:00Z",
            "endTime": "2022-07-07T16:09:31.652Z",
            "timeSpent": 572,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIirCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIirCAAAAAAAAA==/",
            "_etag": "\"a6012d6d-0000-0100-0000-62c71f210000\"",
            "_attachments": "attachments/",
            "_ts": 1657216801
},
    
        {
            "id": "a0725ee1610044be2fd89e29129b2e0f680589947a07a54520bac5458b7e0631",
            "conversationId": "a0725ee1610044be2fd89e29129b2e0f680589947a07a54520bac5458b7e0631",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-07T16:00:00Z",
            "endTime": "2022-07-07T16:11:11.886Z",
            "timeSpent": 672,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIisCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIisCAAAAAAAAA==/",
            "_etag": "\"a601286d-0000-0100-0000-62c71f210000\"",
            "_attachments": "attachments/",
            "_ts": 1657216801
        },
        {
            "id": "b227bd96265c92927e10809a79bba147a3118c9f3f8b1d5a5fb000096fd4d8bf",
            "conversationId": "b227bd96265c92927e10809a79bba147a3118c9f3f8b1d5a5fb000096fd4d8bf",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-07T16:00:00Z",
            "endTime": "2022-07-07T16:10:54.717Z",
            "timeSpent": 655,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIitCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIitCAAAAAAAAA==/",
            "_etag": "\"a601296d-0000-0100-0000-62c71f210000\"",
            "_attachments": "attachments/",
            "_ts": 1657216801
        },
        {
            "id": "aaf86abd1224cb2c61628bbb8469aaf2d7ebe1f13b5b186335928fe46c3fbaa8",
            "conversationId": "aaf86abd1224cb2c61628bbb8469aaf2d7ebe1f13b5b186335928fe46c3fbaa8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-07T16:00:00Z",
            "endTime": "2022-07-07T16:09:01.71Z",
            "timeSpent": 542,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiuCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiuCAAAAAAAAA==/",
            "_etag": "\"a6012e6d-0000-0100-0000-62c71f210000\"",
            "_attachments": "attachments/",
            "_ts": 1657216801
        },
        {
            "id": "dbdbc4f25bc4dd2806e17f790c8be8e522b84b1b220c46af35379a573c005833",
            "conversationId": "dbdbc4f25bc4dd2806e17f790c8be8e522b84b1b220c46af35379a573c005833",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-07T16:00:00Z",
            "endTime": "2022-07-07T16:09:36.17Z",
            "timeSpent": 576,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIivCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIivCAAAAAAAAA==/",
            "_etag": "\"a6012f6d-0000-0100-0000-62c71f210000\"",
            "_attachments": "attachments/",
            "_ts": 1657216801
        },
        {
            "id": "1cddb1a2096fcf9e1e83ab17e4f3e23a8e45776781d2f045dd5cfcaa0457a42c",
            "conversationId": "1cddb1a2096fcf9e1e83ab17e4f3e23a8e45776781d2f045dd5cfcaa0457a42c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-07T16:00:00Z",
            "endTime": "2022-07-07T16:09:21.744Z",
            "timeSpent": 562,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiwCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiwCAAAAAAAAA==/",
            "_etag": "\"a601226d-0000-0100-0000-62c71f210000\"",
            "_attachments": "attachments/",
            "_ts": 1657216801
        },
        {
            "id": "c288267e06082e9263d41ab92a431ae53c3ccd1c1a45f365f4289a4111f74fe2",
            "conversationId": "c288267e06082e9263d41ab92a431ae53c3ccd1c1a45f365f4289a4111f74fe2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-07T16:00:00Z",
            "endTime": "2022-07-07T16:10:35.194Z",
            "timeSpent": 635,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIixCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIixCAAAAAAAAA==/",
            "_etag": "\"a601306d-0000-0100-0000-62c71f210000\"",
            "_attachments": "attachments/",
            "_ts": 1657216801
        },
        {
            "id": "a95274dd36d054b7dbedc743f9a189fb0643cf5d0c87531b6299fc54cb89c4d3",
            "conversationId": "a95274dd36d054b7dbedc743f9a189fb0643cf5d0c87531b6299fc54cb89c4d3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-07T16:00:00Z",
            "endTime": "2022-07-07T16:11:07.005Z",
            "timeSpent": 667,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIiyCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIiyCAAAAAAAAA==/",
            "_etag": "\"a6012b6d-0000-0100-0000-62c71f210000\"",
            "_attachments": "attachments/",
            "_ts": 1657216801
        },
        {
            "id": "9d85fe3c0f123ab77a4361a6e6a83b0695d7a91117198f86b716d05f85944fa8",
            "conversationId": "9d85fe3c0f123ab77a4361a6e6a83b0695d7a91117198f86b716d05f85944fa8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-07T16:00:00Z",
            "endTime": "2022-07-07T16:09:27.113Z",
            "timeSpent": 567,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIizCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIizCAAAAAAAAA==/",
            "_etag": "\"a601316d-0000-0100-0000-62c71f210000\"",
            "_attachments": "attachments/",
            "_ts": 1657216801
        },
        {
            "id": "c8259d90d9bbe16f46d3bf0cb70a393d152a43f284156e892928726a94f7241d",
            "conversationId": "c8259d90d9bbe16f46d3bf0cb70a393d152a43f284156e892928726a94f7241d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-07T16:00:00Z",
            "endTime": "2022-07-07T16:08:48.141Z",
            "timeSpent": 528,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi0CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi0CAAAAAAAAA==/",
            "_etag": "\"a601206d-0000-0100-0000-62c71f210000\"",
            "_attachments": "attachments/",
            "_ts": 1657216801
        },
        {
            "id": "dbb196ab95316afe7e16d82b10dc8128aa7d26f7b87bf96072cdf9080959c708",
            "conversationId": "dbb196ab95316afe7e16d82b10dc8128aa7d26f7b87bf96072cdf9080959c708",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-07T16:00:00Z",
            "endTime": "2022-07-07T16:10:59.215Z",
            "timeSpent": 659,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi1CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi1CAAAAAAAAA==/",
            "_etag": "\"a601236d-0000-0100-0000-62c71f210000\"",
            "_attachments": "attachments/",
            "_ts": 1657216801
        },
        {
            "id": "46e18e13acf3755968f75b13b99a50dc12edd888643d529cda30f91d5bf7d794",
            "conversationId": "46e18e13acf3755968f75b13b99a50dc12edd888643d529cda30f91d5bf7d794",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-07T16:00:00Z",
            "endTime": "2022-07-07T16:08:56.209Z",
            "timeSpent": 536,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi2CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi2CAAAAAAAAA==/",
            "_etag": "\"a6012a6d-0000-0100-0000-62c71f210000\"",
            "_attachments": "attachments/",
            "_ts": 1657216801
        },
        {
            "id": "00dff2a77b7c88525096924bd669645da61ffbd7f16516397bc0db2b066c9bfa",
            "conversationId": "00dff2a77b7c88525096924bd669645da61ffbd7f16516397bc0db2b066c9bfa",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-07T16:00:00Z",
            "endTime": "2022-07-07T16:09:43.6Z",
            "timeSpent": 584,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi3CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi3CAAAAAAAAA==/",
            "_etag": "\"a6012c6d-0000-0100-0000-62c71f210000\"",
            "_attachments": "attachments/",
            "_ts": 1657216801
        },
        {
            "id": "d74702f586674d6c4acd87b1cff9a5d44a9cf0c90de7c7c89ec2ed7aab071ece",
            "conversationId": "d74702f586674d6c4acd87b1cff9a5d44a9cf0c90de7c7c89ec2ed7aab071ece",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-07T16:00:00Z",
            "endTime": "2022-07-07T16:24:34.81Z",
            "timeSpent": 1475,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi4CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi4CAAAAAAAAA==/",
            "_etag": "\"a601276d-0000-0100-0000-62c71f210000\"",
            "_attachments": "attachments/",
            "_ts": 1657216801
        },
        {
            "id": "9de2b49115fe45e6bc4e0f1323c6f43cd58c35296afd75799309c77bed2a6b8d",
            "conversationId": "9de2b49115fe45e6bc4e0f1323c6f43cd58c35296afd75799309c77bed2a6b8d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-07T16:00:00Z",
            "endTime": "2022-07-07T16:24:12.186Z",
            "timeSpent": 1452,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi5CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi5CAAAAAAAAA==/",
            "_etag": "\"a601246d-0000-0100-0000-62c71f210000\"",
            "_attachments": "attachments/",
            "_ts": 1657216801
        },
        {
            "id": "5d4f6bdad526159d5fbfbc492ebd1ccbb19b58e3d5493a1adf250cef56b31917",
            "conversationId": "5d4f6bdad526159d5fbfbc492ebd1ccbb19b58e3d5493a1adf250cef56b31917",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-07T16:00:00Z",
            "endTime": "2022-07-07T16:24:26.458Z",
            "timeSpent": 1466,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi6CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi6CAAAAAAAAA==/",
            "_etag": "\"a601266d-0000-0100-0000-62c71f210000\"",
            "_attachments": "attachments/",
            "_ts": 1657216801
        },
        {
            "id": "26b278d11da4e3ea67a5414a258d2b24e9caaf1732c0ab20758790f727b55876",
            "conversationId": "26b278d11da4e3ea67a5414a258d2b24e9caaf1732c0ab20758790f727b55876",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-07T16:00:00Z",
            "endTime": "2022-07-07T16:25:22.591Z",
            "timeSpent": 1523,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi7CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi7CAAAAAAAAA==/",
            "_etag": "\"a601256d-0000-0100-0000-62c71f210000\"",
            "_attachments": "attachments/",
            "_ts": 1657216801
        },
        {
            "id": "c945bde26e42c54911c4b350868fcade842a8b7a73d860ba2024b5ce46c473fc",
            "conversationId": "c945bde26e42c54911c4b350868fcade842a8b7a73d860ba2024b5ce46c473fc",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 75,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "completedScheduling": "yes",
            "startTime": "2022-07-07T17:05:06.51Z",
            "endTime": "2022-07-07T17:17:25.58Z",
            "timeSpent": 739,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi8CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi8CAAAAAAAAA==/",
            "_etag": "\"a9010fe4-0000-0100-0000-62c72d320000\"",
            "_attachments": "attachments/",
            "_ts": 1657220402
        },
        {
            "id": "8971deddfc72cf72e3a42c473981243bb2cafb8d88a4a3e1df68c8a6cd0584be",
            "conversationId": "8971deddfc72cf72e3a42c473981243bb2cafb8d88a4a3e1df68c8a6cd0584be",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-07T17:21:22.637Z",
            "endTime": "2022-07-07T17:21:22.637Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi9CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi9CAAAAAAAAA==/",
            "_etag": "\"a9010ee4-0000-0100-0000-62c72d320000\"",
            "_attachments": "attachments/",
            "_ts": 1657220402
        },
        {
            "id": "898542c3973529595913d23fa9c870560302c101f2cc48491feac26d296009b7",
            "conversationId": "898542c3973529595913d23fa9c870560302c101f2cc48491feac26d296009b7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 40,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-07T18:15:47.347Z",
            "endTime": "2022-07-07T18:15:47.347Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi+CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi+CAAAAAAAAA==/",
            "_etag": "\"ad01772c-0000-0100-0000-62c73b410000\"",
            "_attachments": "attachments/",
            "_ts": 1657224001
        },
        {
            "id": "b6ff664b2ab077240034e89a56ef697b3a2fd3cd1681861f07d4ad528defe74a",
            "conversationId": "b6ff664b2ab077240034e89a56ef697b3a2fd3cd1681861f07d4ad528defe74a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-07-21T13:51:32.691Z",
            "endTime": "2022-07-21T13:52:36.74Z",
            "timeSpent": 64,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIi-CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIi-CAAAAAAAAA==/",
            "_etag": "\"0800c8b0-0000-0100-0000-62d969f90000\"",
            "_attachments": "attachments/",
            "_ts": 1658415609
        },
        {
            "id": "7ac1c9508a427143b43811b96ef80f4d5a6c9149ff0e5ce613c99c915976a4e9",
            "conversationId": "7ac1c9508a427143b43811b96ef80f4d5a6c9149ff0e5ce613c99c915976a4e9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-21T15:11:35.925Z",
            "endTime": "2022-07-21T15:11:35.925Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjACAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjACAAAAAAAAA==/",
            "_etag": "\"0f007da4-0000-0100-0000-62d986130000\"",
            "_attachments": "attachments/",
            "_ts": 1658422803
        },
        {
            "id": "b465e90fda85660b83231961e7504414ab80ee9518efb3b7680084de67e5d070",
            "conversationId": "b465e90fda85660b83231961e7504414ab80ee9518efb3b7680084de67e5d070",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "completedScheduling": null,
            "startTime": "2022-07-21T19:31:44.537Z",
            "endTime": "2022-07-21T19:33:00.65Z",
            "timeSpent": 76,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjBCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjBCAAAAAAAAA==/",
            "_etag": "\"1f0018e7-0000-0100-0000-62d9be510000\"",
            "_attachments": "attachments/",
            "_ts": 1658437201
        },
        {
            "id": "f8f2e1d3479cef79f3c3b5149544cd355d70e7f104cfa65d0eef87c34516c09f",
            "conversationId": "f8f2e1d3479cef79f3c3b5149544cd355d70e7f104cfa65d0eef87c34516c09f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-21T21:57:36.591Z",
            "endTime": "2022-07-21T21:57:36.591Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjCCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjCCAAAAAAAAA==/",
            "_etag": "\"2300a7cb-0000-0100-0000-62d9e8820000\"",
            "_attachments": "attachments/",
            "_ts": 1658448002
        },
        {
            "id": "ce37645a17b71857286bf2bfaa5599cadc287f7c5c7068b27f296a60229de083",
            "conversationId": "ce37645a17b71857286bf2bfaa5599cadc287f7c5c7068b27f296a60229de083",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "completedScheduling": null,
            "startTime": "2022-07-22T05:55:58.221Z",
            "endTime": "2022-07-22T05:59:17.092Z",
            "timeSpent": 199,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjDCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjDCAAAAAAAAA==/",
            "_etag": "\"2600b255-0000-0100-0000-62da4af10000\"",
            "_attachments": "attachments/",
            "_ts": 1658473201
        },
        {
            "id": "f1c477a44f9b435ec3af130d0ad1778ba5dc6baf7a7c1d8f2f9a14ab2609f943",
            "conversationId": "f1c477a44f9b435ec3af130d0ad1778ba5dc6baf7a7c1d8f2f9a14ab2609f943",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-07-22T05:59:23.336Z",
            "endTime": "2022-07-22T06:00:11.473Z",
            "timeSpent": 48,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjECAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjECAAAAAAAAA==/",
            "_etag": "\"260024b1-0000-0100-0000-62da59010000\"",
            "_attachments": "attachments/",
            "_ts": 1658476801
        },
        {
            "id": "afd11c2196b23e9bef12ce15160fc4ceacb78724f97ac1e56d139f10c0c6e3c8",
            "conversationId": "afd11c2196b23e9bef12ce15160fc4ceacb78724f97ac1e56d139f10c0c6e3c8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-22T06:42:50.016Z",
            "endTime": "2022-07-22T06:43:48.645Z",
            "timeSpent": 59,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjFCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjFCAAAAAAAAA==/",
            "_etag": "\"2700fe0c-0000-0100-0000-62da67120000\"",
            "_attachments": "attachments/",
            "_ts": 1658480402
        },
        {
            "id": "ba645e4f87bd997b5de5d07796ab6485fe4365f0801508c99f8c42adfe787478",
            "conversationId": "ba645e4f87bd997b5de5d07796ab6485fe4365f0801508c99f8c42adfe787478",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-07-22T06:00:28.487Z",
            "endTime": "2022-07-22T06:01:48.811Z",
            "timeSpent": 80,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjGCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjGCAAAAAAAAA==/",
            "_etag": "\"260022b1-0000-0100-0000-62da59010000\"",
            "_attachments": "attachments/",
            "_ts": 1658476801
        },
        {
            "id": "d84b3c1d5b43de63b789152cd60f2b68fe08c31ac4f60a7b392c51edd3cdad86",
            "conversationId": "d84b3c1d5b43de63b789152cd60f2b68fe08c31ac4f60a7b392c51edd3cdad86",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-22T06:02:02.81Z",
            "endTime": "2022-07-22T06:02:02.81Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjHCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjHCAAAAAAAAA==/",
            "_etag": "\"260023b1-0000-0100-0000-62da59010000\"",
            "_attachments": "attachments/",
            "_ts": 1658476801
        },
        {
            "id": "a01a1e99e045e1180aea0b0fde1b641e4ed2ad319173381bea0d7812a7dd2bab",
            "conversationId": "a01a1e99e045e1180aea0b0fde1b641e4ed2ad319173381bea0d7812a7dd2bab",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "completedScheduling": null,
            "startTime": "2022-07-22T13:45:49.692Z",
            "endTime": "2022-07-22T13:49:18.223Z",
            "timeSpent": 209,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjICAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjICAAAAAAAAA==/",
            "_etag": "\"29004f4e-0000-0100-0000-62dabb710000\"",
            "_attachments": "attachments/",
            "_ts": 1658502001
        },
        {
            "id": "8be504644a611877008d8acdbe709525e2677a108f51ac4bf7819009a30e4eec",
            "conversationId": "8be504644a611877008d8acdbe709525e2677a108f51ac4bf7819009a30e4eec",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "completedScheduling": null,
            "startTime": "2022-07-22T13:43:01.607Z",
            "endTime": "2022-07-22T13:45:44.83Z",
            "timeSpent": 163,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjJCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjJCAAAAAAAAA==/",
            "_etag": "\"2900504e-0000-0100-0000-62dabb710000\"",
            "_attachments": "attachments/",
            "_ts": 1658502001
        },
        {
            "id": "a0208007f5f1df3d1477f39caf8c2cdce8282309f495f888c8469125046241da",
            "conversationId": "a0208007f5f1df3d1477f39caf8c2cdce8282309f495f888c8469125046241da",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 13,
            "ageGroup": "12-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the emergency room",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-22T20:59:11.524Z",
            "endTime": "2022-07-22T20:59:31.252Z",
            "timeSpent": 20,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjKCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjKCAAAAAAAAA==/",
            "_etag": "\"2f005e88-0000-0100-0000-62db2bf10000\"",
            "_attachments": "attachments/",
            "_ts": 1658530801
        },
        {
            "id": "49f54daac63e40e6d1df84f15c07d197be73a3c6db09b3f7da5d3d2d033d1ca7",
            "conversationId": "49f54daac63e40e6d1df84f15c07d197be73a3c6db09b3f7da5d3d2d033d1ca7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-22T20:59:09.218Z",
            "endTime": "2022-07-22T20:59:09.218Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjLCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjLCAAAAAAAAA==/",
            "_etag": "\"2d0078f5-0000-0100-0000-62db1de10000\"",
            "_attachments": "attachments/",
            "_ts": 1658527201
        },
        {
            "id": "b44ba39d6108bc1c74128a25f0ef0e513bf5a0036d83f8ccb4ff1f96935ca0d4",
            "conversationId": "b44ba39d6108bc1c74128a25f0ef0e513bf5a0036d83f8ccb4ff1f96935ca0d4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:49:45.3Z",
            "endTime": "2022-07-24T22:49:45.3Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjMCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjMCAAAAAAAAA==/",
            "_etag": "\"4100dfe1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "81574981c552cd51ad1efceec52e4e5baa84aae473d010db251305d7cec2038b",
            "conversationId": "81574981c552cd51ad1efceec52e4e5baa84aae473d010db251305d7cec2038b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:49:47.377Z",
            "endTime": "2022-07-24T22:49:47.377Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjNCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjNCAAAAAAAAA==/",
            "_etag": "\"41008da7-0000-0100-0000-62dddd020000\"",
            "_attachments": "attachments/",
            "_ts": 1658707202
        },
        {
            "id": "312fe036aa403adcd59b8dbc36340eebfb7e5d9c9befd234baa3899ec62c9027",
            "conversationId": "312fe036aa403adcd59b8dbc36340eebfb7e5d9c9befd234baa3899ec62c9027",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:50:00.254Z",
            "endTime": "2022-07-24T22:50:00.254Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjOCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjOCAAAAAAAAA==/",
            "_etag": "\"4100e1e1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "18e41712752bd5a68625e4098e659c85c4fc204524544896033dc12491541a0f",
            "conversationId": "18e41712752bd5a68625e4098e659c85c4fc204524544896033dc12491541a0f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:50:24.739Z",
            "endTime": "2022-07-24T22:50:24.739Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjPCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjPCAAAAAAAAA==/",
            "_etag": "\"4100e5e1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "24f5911b47dc30369fa7395ae70e624b2aded479af63ab7b5210f96dea73dfe3",
            "conversationId": "24f5911b47dc30369fa7395ae70e624b2aded479af63ab7b5210f96dea73dfe3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 13,
            "ageGroup": "12-17",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:50:50.158Z",
            "endTime": "2022-07-24T22:50:50.158Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjQCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjQCAAAAAAAAA==/",
            "_etag": "\"4100e8e1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "6d597173dc92387df6657b3d9fdf3a141affa3f86879c94b6bee1ad4dcf76267",
            "conversationId": "6d597173dc92387df6657b3d9fdf3a141affa3f86879c94b6bee1ad4dcf76267",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:51:13.871Z",
            "endTime": "2022-07-24T22:51:14.717Z",
            "timeSpent": 1,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjRCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjRCAAAAAAAAA==/",
            "_etag": "\"4100eae1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "e7cf4692270ef8b11c1245349e34f9d5c075d72301cddf31d66c6644f5111d41",
            "conversationId": "e7cf4692270ef8b11c1245349e34f9d5c075d72301cddf31d66c6644f5111d41",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:51:26.985Z",
            "endTime": "2022-07-24T22:51:26.985Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjSCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjSCAAAAAAAAA==/",
            "_etag": "\"4100ebe1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "c647673cd0f09bae1dad6eb1df5f39ca8046e9719f7ac3ca1f70fbaedff746e0",
            "conversationId": "c647673cd0f09bae1dad6eb1df5f39ca8046e9719f7ac3ca1f70fbaedff746e0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:51:02.297Z",
            "endTime": "2022-07-24T22:51:02.297Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjTCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjTCAAAAAAAAA==/",
            "_etag": "\"41008ba7-0000-0100-0000-62dddd020000\"",
            "_attachments": "attachments/",
            "_ts": 1658707202
        },
        {
            "id": "b417562f44b5a439e842519a055498bcfd2bd7b33c08147fb75a156b85f761df",
            "conversationId": "b417562f44b5a439e842519a055498bcfd2bd7b33c08147fb75a156b85f761df",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 65,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:51:04.579Z",
            "endTime": "2022-07-24T22:51:04.579Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjUCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjUCAAAAAAAAA==/",
            "_etag": "\"4100e9e1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "a27d46c1fb6012f8024058ca87e721b0efff0936bd95fd158ec8b99b5c2b65ae",
            "conversationId": "a27d46c1fb6012f8024058ca87e721b0efff0936bd95fd158ec8b99b5c2b65ae",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:51:29.814Z",
            "endTime": "2022-07-24T22:51:29.814Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjVCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjVCAAAAAAAAA==/",
            "_etag": "\"4100ece1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "1f9e06370ca569566d8df8b4e29b796001050e15c6472eaca8ed242338dfb4df",
            "conversationId": "1f9e06370ca569566d8df8b4e29b796001050e15c6472eaca8ed242338dfb4df",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:52:17.954Z",
            "endTime": "2022-07-24T22:52:17.954Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjWCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjWCAAAAAAAAA==/",
            "_etag": "\"4100f2e1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "6fb1573d8821d61a09efa0e5a801696338ea9d179db0a4485038e19a63ebf3bb",
            "conversationId": "6fb1573d8821d61a09efa0e5a801696338ea9d179db0a4485038e19a63ebf3bb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:52:07.694Z",
            "endTime": "2022-07-24T22:52:07.694Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjXCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjXCAAAAAAAAA==/",
            "_etag": "\"4100f0e1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "957b620b1a650f6ce4dc5b431467c185fa45476523119ebadb1afc5e628aa689",
            "conversationId": "957b620b1a650f6ce4dc5b431467c185fa45476523119ebadb1afc5e628aa689",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:52:39.594Z",
            "endTime": "2022-07-24T22:52:39.594Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjYCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjYCAAAAAAAAA==/",
            "_etag": "\"4100f5e1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "87e465348b74c5389d92b1b9683a8d6bd12ee2db8e7b88df86f1d8c17a488ddc",
            "conversationId": "87e465348b74c5389d92b1b9683a8d6bd12ee2db8e7b88df86f1d8c17a488ddc",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:52:27.606Z",
            "endTime": "2022-07-24T22:52:27.606Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjZCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjZCAAAAAAAAA==/",
            "_etag": "\"4100f3e1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "8454af9759a7da608f3709b53c43eef9e67729c8354b787d743268520038fb0f",
            "conversationId": "8454af9759a7da608f3709b53c43eef9e67729c8354b787d743268520038fb0f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:50:14.669Z",
            "endTime": "2022-07-24T22:50:14.669Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjaCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjaCAAAAAAAAA==/",
            "_etag": "\"4100e3e1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "1ff6c2de3bc5a4453f4948fc4ccd4a9de9182ded74e51169c91681756fd060db",
            "conversationId": "1ff6c2de3bc5a4453f4948fc4ccd4a9de9182ded74e51169c91681756fd060db",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:50:22.545Z",
            "endTime": "2022-07-24T22:50:22.545Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjbCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjbCAAAAAAAAA==/",
            "_etag": "\"4100e4e1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "e362f8ed433fc3b90f7256459e03845b8536f0e194e37722b1968d2c6c3b780d",
            "conversationId": "e362f8ed433fc3b90f7256459e03845b8536f0e194e37722b1968d2c6c3b780d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:50:12.633Z",
            "endTime": "2022-07-24T22:50:12.633Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjcCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjcCAAAAAAAAA==/",
            "_etag": "\"4100e2e1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "b156a41b1863f8066e62c803b96b4b121692bf01a56da107a8456ea8ce865584",
            "conversationId": "b156a41b1863f8066e62c803b96b4b121692bf01a56da107a8456ea8ce865584",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:49:57.126Z",
            "endTime": "2022-07-24T22:49:57.126Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjdCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjdCAAAAAAAAA==/",
            "_etag": "\"4100e0e1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "3f3ccac710c9b774d46217cdff2502f32591236249c8cdf03d72b23a87478f47",
            "conversationId": "3f3ccac710c9b774d46217cdff2502f32591236249c8cdf03d72b23a87478f47",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:50:37.515Z",
            "endTime": "2022-07-24T22:50:37.515Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjeCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjeCAAAAAAAAA==/",
            "_etag": "\"4100e6e1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "3b939927da1df89aaab5a1f7a18444fbf3554cff12068ae0e94327aeda3f5194",
            "conversationId": "3b939927da1df89aaab5a1f7a18444fbf3554cff12068ae0e94327aeda3f5194",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:50:47.235Z",
            "endTime": "2022-07-24T22:50:47.235Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjfCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjfCAAAAAAAAA==/",
            "_etag": "\"4100e7e1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "d1e28902b6251a5373b3c3861ff1ae5b59eb3805f64624346d372ed3ebeeeca9",
            "conversationId": "d1e28902b6251a5373b3c3861ff1ae5b59eb3805f64624346d372ed3ebeeeca9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:51:38.054Z",
            "endTime": "2022-07-24T22:51:38.054Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjgCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjgCAAAAAAAAA==/",
            "_etag": "\"4100ede1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "98b505e6ad872b2629d12e0ea4c79380f3204b751364d72890e0ca4bf04b0054",
            "conversationId": "98b505e6ad872b2629d12e0ea4c79380f3204b751364d72890e0ca4bf04b0054",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:51:48.44Z",
            "endTime": "2022-07-24T22:51:48.44Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjhCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjhCAAAAAAAAA==/",
            "_etag": "\"4100eee1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "948049ef90ab4c9f123d9f3220674b809ad7ff2215bb4a29f9c37b7efff0905f",
            "conversationId": "948049ef90ab4c9f123d9f3220674b809ad7ff2215bb4a29f9c37b7efff0905f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:51:57.328Z",
            "endTime": "2022-07-24T22:51:57.328Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjiCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjiCAAAAAAAAA==/",
            "_etag": "\"4100efe1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "6bd80844755ed5a2eaba54a69b925e23abe87241670f48522ef246df5b21cdb5",
            "conversationId": "6bd80844755ed5a2eaba54a69b925e23abe87241670f48522ef246df5b21cdb5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:52:09.644Z",
            "endTime": "2022-07-24T22:52:09.644Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjjCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjjCAAAAAAAAA==/",
            "_etag": "\"4100f1e1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "2a02eb258421f123ef6fa739bdb505e157cf8b544df7fec5380ae6067e357fe9",
            "conversationId": "2a02eb258421f123ef6fa739bdb505e157cf8b544df7fec5380ae6067e357fe9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-24T22:52:36.382Z",
            "endTime": "2022-07-24T22:52:36.382Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjkCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjkCAAAAAAAAA==/",
            "_etag": "\"4100f4e1-0000-0100-0000-62ddeb500000\"",
            "_attachments": "attachments/",
            "_ts": 1658710864
        },
        {
            "id": "a9401a874f1debb9184de3147d63a09b61df11a1962950520cf7f0896bd98e0f",
            "conversationId": "a9401a874f1debb9184de3147d63a09b61df11a1962950520cf7f0896bd98e0f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-07-25T04:33:32.788Z",
            "endTime": "2022-07-25T04:35:14.744Z",
            "timeSpent": 102,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjlCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjlCAAAAAAAAA==/",
            "_etag": "\"43005402-0000-0100-0000-62de31620000\"",
            "_attachments": "attachments/",
            "_ts": 1658728802
        },
        {
            "id": "efd911f74fced6990479a879a828e7de1045055bea5081459f06ffda74f17cb0",
            "conversationId": "efd911f74fced6990479a879a828e7de1045055bea5081459f06ffda74f17cb0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-07-25T10:36:01.118Z",
            "endTime": "2022-07-25T10:36:53.606Z",
            "timeSpent": 52,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjmCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjmCAAAAAAAAA==/",
            "_etag": "\"4400aea7-0000-0100-0000-62de85c10000\"",
            "_attachments": "attachments/",
            "_ts": 1658750401
        },
        {
            "id": "08959970842aaf30264ada7d062bc4f1b17d394bb36e3db20fb156a384ebd114",
            "conversationId": "08959970842aaf30264ada7d062bc4f1b17d394bb36e3db20fb156a384ebd114",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-07-25T10:56:08.09Z",
            "endTime": "2022-07-25T10:57:11.933Z",
            "timeSpent": 64,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjnCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjnCAAAAAAAAA==/",
            "_etag": "\"4400aba7-0000-0100-0000-62de85c10000\"",
            "_attachments": "attachments/",
            "_ts": 1658750401
        },
        {
            "id": "9cb19239d1da321205133a6a49707bd0d8d515a465f077bb8042897122248d7e",
            "conversationId": "9cb19239d1da321205133a6a49707bd0d8d515a465f077bb8042897122248d7e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-25T10:45:36.974Z",
            "endTime": "2022-07-25T10:46:18.985Z",
            "timeSpent": 42,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjoCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjoCAAAAAAAAA==/",
            "_etag": "\"4400aaa7-0000-0100-0000-62de85c10000\"",
            "_attachments": "attachments/",
            "_ts": 1658750401
        },
        {
            "id": "ca6617413f275996ff4540cdaa9315b1f8d1bf7e43cd6a008a85561b5b38cdbe",
            "conversationId": "ca6617413f275996ff4540cdaa9315b1f8d1bf7e43cd6a008a85561b5b38cdbe",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-25T10:48:39.752Z",
            "endTime": "2022-07-25T10:48:39.752Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjpCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjpCAAAAAAAAA==/",
            "_etag": "\"4400faf0-0000-0100-0000-62de93d20000\"",
            "_attachments": "attachments/",
            "_ts": 1658754002
        },
        {
            "id": "8df2f9ea902edf26faf3800f7ff3d31ec3737e21cffb0697f0b3fca43683cc2c",
            "conversationId": "8df2f9ea902edf26faf3800f7ff3d31ec3737e21cffb0697f0b3fca43683cc2c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-07-25T10:51:29.79Z",
            "endTime": "2022-07-25T10:54:22.527Z",
            "timeSpent": 173,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjqCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjqCAAAAAAAAA==/",
            "_etag": "\"4400aca7-0000-0100-0000-62de85c10000\"",
            "_attachments": "attachments/",
            "_ts": 1658750401
        },
        {
            "id": "975ddd1b60befdfde8231ccaf9f45593b19b3c0c1fdecb1b8643d561f95cceeb",
            "conversationId": "975ddd1b60befdfde8231ccaf9f45593b19b3c0c1fdecb1b8643d561f95cceeb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "completedScheduling": null,
            "startTime": "2022-07-25T10:55:43.197Z",
            "endTime": "2022-07-25T10:55:57.96Z",
            "timeSpent": 15,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjrCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjrCAAAAAAAAA==/",
            "_etag": "\"4400afa7-0000-0100-0000-62de85c10000\"",
            "_attachments": "attachments/",
            "_ts": 1658750401
        },
        {
            "id": "2ce0261bc6a30fb2eb15db270bcc82d13840f5ef2e3b3cf59dc39f2fbbe83004",
            "conversationId": "2ce0261bc6a30fb2eb15db270bcc82d13840f5ef2e3b3cf59dc39f2fbbe83004",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 13,
            "ageGroup": "12-17",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "completedScheduling": null,
            "startTime": "2022-07-25T13:18:58.708Z",
            "endTime": "2022-07-25T13:22:21.174Z",
            "timeSpent": 202,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjsCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjsCAAAAAAAAA==/",
            "_etag": "\"4500c197-0000-0100-0000-62deaff20000\"",
            "_attachments": "attachments/",
            "_ts": 1658761202
        },
        {
            "id": "b80d9cc574bf21a63ee5de42e91c2596888c5438fd56ae1b49a25e13b718d65b",
            "conversationId": "b80d9cc574bf21a63ee5de42e91c2596888c5438fd56ae1b49a25e13b718d65b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-25T13:18:44.004Z",
            "endTime": "2022-07-25T13:18:52.563Z",
            "timeSpent": 9,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjtCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjtCAAAAAAAAA==/",
            "_etag": "\"4500c297-0000-0100-0000-62deaff20000\"",
            "_attachments": "attachments/",
            "_ts": 1658761202
        },
        {
            "id": "ee9511400865ff235bcef2a635df0a6640206f37d5e6577e5753db955fada9d1",
            "conversationId": "ee9511400865ff235bcef2a635df0a6640206f37d5e6577e5753db955fada9d1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-25T14:37:35.535Z",
            "endTime": "2022-07-25T14:37:42.548Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjuCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjuCAAAAAAAAA==/",
            "_etag": "\"4600fe7d-0000-0100-0000-62decc110000\"",
            "_attachments": "attachments/",
            "_ts": 1658768401
        },
        {
            "id": "1eaff215e50337ee8544f1788fe34e69e6abc627bb58f1e1e00c5d689aac52c4",
            "conversationId": "1eaff215e50337ee8544f1788fe34e69e6abc627bb58f1e1e00c5d689aac52c4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 45,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache",
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "completedScheduling": null,
            "startTime": "2022-07-25T14:55:30.958Z",
            "endTime": "2022-07-25T14:59:36.891Z",
            "timeSpent": 246,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjvCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjvCAAAAAAAAA==/",
            "_etag": "\"4500e0ef-0000-0100-0000-62debe3e0000\"",
            "_attachments": "attachments/",
            "_ts": 1658764862
        },
        {
            "id": "4aabf697c53f0ef4ce74ee15f459249e6ca3981786d148b8947cce9d6eff5a11",
            "conversationId": "4aabf697c53f0ef4ce74ee15f459249e6ca3981786d148b8947cce9d6eff5a11",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-25T14:53:37.971Z",
            "endTime": "2022-07-25T14:53:37.971Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjwCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjwCAAAAAAAAA==/",
            "_etag": "\"4600ff7d-0000-0100-0000-62decc110000\"",
            "_attachments": "attachments/",
            "_ts": 1658768401
        },
        {
            "id": "d99e4cdf71d4966b83d88a555d28a8a7c16943ee8d8f9fcf3cef634ed3d40162",
            "conversationId": "d99e4cdf71d4966b83d88a555d28a8a7c16943ee8d8f9fcf3cef634ed3d40162",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache",
                "Runny nose"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the emergency room",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-07-25T20:30:04.915Z",
            "endTime": "2022-07-25T20:46:53.401Z",
            "timeSpent": 1008,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjxCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjxCAAAAAAAAA==/",
            "_etag": "\"4900e1b1-0000-0100-0000-62df12620000\"",
            "_attachments": "attachments/",
            "_ts": 1658786402
        },
        {
            "id": "87865c9db4a80ec0e38b0406f67b81df29ccd794d041464d4328bda17a5d37f6",
            "conversationId": "87865c9db4a80ec0e38b0406f67b81df29ccd794d041464d4328bda17a5d37f6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-25T20:29:27.481Z",
            "endTime": "2022-07-25T20:29:27.481Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjyCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjyCAAAAAAAAA==/",
            "_etag": "\"4a00b52f-0000-0100-0000-62df20710000\"",
            "_attachments": "attachments/",
            "_ts": 1658790001
        },
        {
            "id": "696076359746dce933ca6070e3b0ab0a8fedc6ef0ceb52d2e347c99498f66aca",
            "conversationId": "696076359746dce933ca6070e3b0ab0a8fedc6ef0ceb52d2e347c99498f66aca",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Male",
            "age": 45,
            "ageGroup": "46-55",
            "state": "Virginia",
            "chiefComplaint": [
                "Sudden stomach pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-07-26T03:15:04.401Z",
            "endTime": "2022-07-26T03:18:08.31Z",
            "timeSpent": 184,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIjzCAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIjzCAAAAAAAAA==/",
            "_etag": "\"4d005ecf-0000-0100-0000-62df74d10000\"",
            "_attachments": "attachments/",
            "_ts": 1658811601
        },
        {
            "id": "55b462750cf20ee0ebbbb9c9daa61cb0ac8b0c6a0d52781c8fe702d325ee5cfa",
            "conversationId": "55b462750cf20ee0ebbbb9c9daa61cb0ac8b0c6a0d52781c8fe702d325ee5cfa",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-26T03:14:59.484Z",
            "endTime": "2022-07-26T03:14:59.484Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj0CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj0CAAAAAAAAA==/",
            "_etag": "\"4d005fcf-0000-0100-0000-62df74d10000\"",
            "_attachments": "attachments/",
            "_ts": 1658811601
        },
        {
            "id": "7d228e59b8ba4df7248700b44c7574cfbe7b7825f551bb5efb417ba99a6ac54d",
            "conversationId": "7d228e59b8ba4df7248700b44c7574cfbe7b7825f551bb5efb417ba99a6ac54d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-07-26T11:10:50.507Z",
            "endTime": "2022-07-26T11:13:38.185Z",
            "timeSpent": 168,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj1CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj1CAAAAAAAAA==/",
            "_etag": "\"520086da-0000-0100-0000-62dfe5540000\"",
            "_attachments": "attachments/",
            "_ts": 1658840404
        },
        {
            "id": "a0cf2a701a5d2e00654bfa521951628e903db12c93bb65860345f17166101a9a",
            "conversationId": "a0cf2a701a5d2e00654bfa521951628e903db12c93bb65860345f17166101a9a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Fever"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "completedScheduling": null,
            "startTime": "2022-07-26T11:15:16.839Z",
            "endTime": "2022-07-26T11:17:21.903Z",
            "timeSpent": 125,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj2CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj2CAAAAAAAAA==/",
            "_etag": "\"520087da-0000-0100-0000-62dfe5540000\"",
            "_attachments": "attachments/",
            "_ts": 1658840404
        },
        {
            "id": "4fd3858813ce5d6e0a9e8f7249a2e66e191c9146183859a3cdd548587fcfcda2",
            "conversationId": "4fd3858813ce5d6e0a9e8f7249a2e66e191c9146183859a3cdd548587fcfcda2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-07-26T12:00:28.093Z",
            "endTime": "2022-07-26T12:01:46.079Z",
            "timeSpent": 78,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj3CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj3CAAAAAAAAA==/",
            "_etag": "\"5300f868-0000-0100-0000-62dff3610000\"",
            "_attachments": "attachments/",
            "_ts": 1658844001
        },
        {
            "id": "0f67c8f4314215dd8314bf1dffb5b37e4f9d37012025a3b00955dd8df9fc7885",
            "conversationId": "0f67c8f4314215dd8314bf1dffb5b37e4f9d37012025a3b00955dd8df9fc7885",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Call 9-1-1",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-07-26T12:02:09.724Z",
            "endTime": "2022-07-26T12:03:17.782Z",
            "timeSpent": 68,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj4CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj4CAAAAAAAAA==/",
            "_etag": "\"5300f968-0000-0100-0000-62dff3610000\"",
            "_attachments": "attachments/",
            "_ts": 1658844001
        },
        {
            "id": "44885cd31a09cb4215937c9c720c3e598dadbd74422bfc9d733ca43cdb4b21ed",
            "conversationId": "44885cd31a09cb4215937c9c720c3e598dadbd74422bfc9d733ca43cdb4b21ed",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-27T10:31:31.484Z",
            "endTime": "2022-07-27T10:33:40.586Z",
            "timeSpent": 129,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj5CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj5CAAAAAAAAA==/",
            "_etag": "\"6100fe03-0000-0100-0000-62e128c20000\"",
            "_attachments": "attachments/",
            "_ts": 1658923202
        },
        {
            "id": "98ebb5d1e48837869ac3ef9fe21176231fae3fb87c40728ad1fa78abdfad719e",
            "conversationId": "98ebb5d1e48837869ac3ef9fe21176231fae3fb87c40728ad1fa78abdfad719e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": null,
            "age": 0,
            "ageGroup": null,
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "no",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-27T10:00:00Z",
            "endTime": "2022-07-27T10:21:22.788Z",
            "timeSpent": 1283,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj6CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj6CAAAAAAAAA==/",
            "_etag": "\"61000004-0000-0100-0000-62e128c20000\"",
            "_attachments": "attachments/",
            "_ts": 1658923202
        },
        {
            "id": "43481bff7578431fac7d7b4a8dd34a2209056199486fd9b74b31ce7bf7d1c669",
            "conversationId": "43481bff7578431fac7d7b4a8dd34a2209056199486fd9b74b31ce7bf7d1c669",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-27T10:22:53.803Z",
            "endTime": "2022-07-27T10:25:01.003Z",
            "timeSpent": 127,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj7CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj7CAAAAAAAAA==/",
            "_etag": "\"6100ff03-0000-0100-0000-62e128c20000\"",
            "_attachments": "attachments/",
            "_ts": 1658923202
        },
        {
            "id": "fa975466a91272f82361ae151e6136713901ee4a2a96cf23251681e65c6b624b",
            "conversationId": "fa975466a91272f82361ae151e6136713901ee4a2a96cf23251681e65c6b624b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-27T10:55:10.906Z",
            "endTime": "2022-07-27T10:55:10.906Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj8CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj8CAAAAAAAAA==/",
            "_etag": "\"61008b83-0000-0100-0000-62e1370f0000\"",
            "_attachments": "attachments/",
            "_ts": 1658926863
        },
        {
            "id": "89d282369695fb449b843f90cce807bfa6723b4a121b43f09874f7403510bb69",
            "conversationId": "89d282369695fb449b843f90cce807bfa6723b4a121b43f09874f7403510bb69",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-27T12:49:10.738Z",
            "endTime": "2022-07-27T12:52:44.843Z",
            "timeSpent": 214,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj9CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj9CAAAAAAAAA==/",
            "_etag": "\"61009fe1-0000-0100-0000-62e144e20000\"",
            "_attachments": "attachments/",
            "_ts": 1658930402
        },
        {
            "id": "b41944e21788adb343c1572b3a6984dc714c86d58ad28aa6253dd59d90542372",
            "conversationId": "b41944e21788adb343c1572b3a6984dc714c86d58ad28aa6253dd59d90542372",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-27T12:46:47.757Z",
            "endTime": "2022-07-27T12:48:09.27Z",
            "timeSpent": 82,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj+CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj+CAAAAAAAAA==/",
            "_etag": "\"6200d447-0000-0100-0000-62e152f90000\"",
            "_attachments": "attachments/",
            "_ts": 1658934009
        },
        {
            "id": "10a6d0413ac48f90ad8128f824731063f6b47e6d2ab2c2c73d5a558c862db4c9",
            "conversationId": "10a6d0413ac48f90ad8128f824731063f6b47e6d2ab2c2c73d5a558c862db4c9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Wrist pain"
            ],
            "riskFactors": [
                "Diabetes",
                "Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-07-27T12:53:52.645Z",
            "endTime": "2022-07-27T12:56:42.526Z",
            "timeSpent": 170,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIj-CAAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIj-CAAAAAAAAA==/",
            "_etag": "\"6200d247-0000-0100-0000-62e152f90000\"",
            "_attachments": "attachments/",
            "_ts": 1658934009
        },
        {
            "id": "a0b000693615789e2eac6af22aebae3827e34d4569316c125f0370cee917d7d1",
            "conversationId": "a0b000693615789e2eac6af22aebae3827e34d4569316c125f0370cee917d7d1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Fever"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "completedScheduling": null,
            "startTime": "2022-07-27T13:02:15.91Z",
            "endTime": "2022-07-27T13:05:19.044Z",
            "timeSpent": 183,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgACQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgACQAAAAAAAA==/",
            "_etag": "\"6200d347-0000-0100-0000-62e152f90000\"",
            "_attachments": "attachments/",
            "_ts": 1658934009
        },
        {
            "id": "ff8b1190055fc75b4b4aa5dc1ff6480d08a68ad67a8f185effe70683c66bc5d5",
            "conversationId": "ff8b1190055fc75b4b4aa5dc1ff6480d08a68ad67a8f185effe70683c66bc5d5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-07-27T13:29:50.563Z",
            "endTime": "2022-07-27T13:31:15.688Z",
            "timeSpent": 85,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgBCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgBCQAAAAAAAA==/",
            "_etag": "\"6200d147-0000-0100-0000-62e152f90000\"",
            "_attachments": "attachments/",
            "_ts": 1658934009
        },
        {
            "id": "b988f2578836c542b626adbf78346a69dd9c1c95579aa25bebb0ae5443169a78",
            "conversationId": "b988f2578836c542b626adbf78346a69dd9c1c95579aa25bebb0ae5443169a78",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-27T16:15:02.873Z",
            "endTime": "2022-07-27T16:15:02.873Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgCCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgCCQAAAAAAAA==/",
            "_etag": "\"63000de2-0000-0100-0000-62e17d220000\"",
            "_attachments": "attachments/",
            "_ts": 1658944802
        },
        {
            "id": "0174cbe5d35f24a068442f3b9d42f62249999eb017d989fb76004fc0f5b70fd8",
            "conversationId": "0174cbe5d35f24a068442f3b9d42f62249999eb017d989fb76004fc0f5b70fd8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [
                "Diabetes",
                "Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "completedScheduling": null,
            "startTime": "2022-07-27T16:15:49.663Z",
            "endTime": "2022-07-27T16:23:13.768Z",
            "timeSpent": 444,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgDCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgDCQAAAAAAAA==/",
            "_etag": "\"63000ce2-0000-0100-0000-62e17d220000\"",
            "_attachments": "attachments/",
            "_ts": 1658944802
        },
        {
            "id": "128a9a22fc36c1a80c97c1c1c79e21cea45a565392cfd04c1d28ba012dd9e2fc",
            "conversationId": "128a9a22fc36c1a80c97c1c1c79e21cea45a565392cfd04c1d28ba012dd9e2fc",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-28T05:10:44.11Z",
            "endTime": "2022-07-28T05:11:05.382Z",
            "timeSpent": 21,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgECQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgECQAAAAAAAA==/",
            "_etag": "\"6a00915d-0000-0100-0000-62e233f10000\"",
            "_attachments": "attachments/",
            "_ts": 1658991601
        },
        {
            "id": "21f35af209ff0b50ec99013a4275c5d6e16f8792a68dfacb149df143bfe3536e",
            "conversationId": "21f35af209ff0b50ec99013a4275c5d6e16f8792a68dfacb149df143bfe3536e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-28T05:11:34.263Z",
            "endTime": "2022-07-28T05:21:33.357Z",
            "timeSpent": 599,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgFCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgFCQAAAAAAAA==/",
            "_etag": "\"6a00905d-0000-0100-0000-62e233f10000\"",
            "_attachments": "attachments/",
            "_ts": 1658991601
        },
        {
            "id": "08122c8ce6c5bb37d2709205bc11d2ba2a0cf8db3a6e49f855cb5c0728a98507",
            "conversationId": "08122c8ce6c5bb37d2709205bc11d2ba2a0cf8db3a6e49f855cb5c0728a98507",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-07-28T07:01:30.146Z",
            "endTime": "2022-07-28T07:02:53.805Z",
            "timeSpent": 84,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgGCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgGCQAAAAAAAA==/",
            "_etag": "\"6a00a8ff-0000-0100-0000-62e250110000\"",
            "_attachments": "attachments/",
            "_ts": 1658998801
        },
        {
            "id": "ae56becc7e2d5444722945007eb16ae1e136a86415dfc058084bd96151a098e0",
            "conversationId": "ae56becc7e2d5444722945007eb16ae1e136a86415dfc058084bd96151a098e0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-28T07:00:17.663Z",
            "endTime": "2022-07-28T07:00:17.663Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgHCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgHCQAAAAAAAA==/",
            "_etag": "\"6a00a9ff-0000-0100-0000-62e250110000\"",
            "_attachments": "attachments/",
            "_ts": 1658998801
        },
        {
            "id": "9635d552067f8014df82dfe82a4909e85b7526bcbb10c470cd1f6a56d4a38c5c",
            "conversationId": "9635d552067f8014df82dfe82a4909e85b7526bcbb10c470cd1f6a56d4a38c5c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Fast heartbeat"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-28T08:44:52.732Z",
            "endTime": "2022-07-28T08:51:44.908Z",
            "timeSpent": 412,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgICQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgICQAAAAAAAA==/",
            "_etag": "\"6b00c3a1-0000-0100-0000-62e26c310000\"",
            "_attachments": "attachments/",
            "_ts": 1659006001
        },
        {
            "id": "ca6c2e342010eb964bc6cb05d439047e73aaf9699bdf65d6dfaff745059eacfc",
            "conversationId": "ca6c2e342010eb964bc6cb05d439047e73aaf9699bdf65d6dfaff745059eacfc",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Accelerated breathing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-28T08:50:46.44Z",
            "endTime": "2022-07-28T08:51:44.673Z",
            "timeSpent": 58,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgJCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgJCQAAAAAAAA==/",
            "_etag": "\"6b00c4a1-0000-0100-0000-62e26c310000\"",
            "_attachments": "attachments/",
            "_ts": 1659006001
        },
        {
            "id": "b3b6704dc2acb2b6cc2481334a1deac1336b3450f85b3428bbf79631cc2f8427",
            "conversationId": "b3b6704dc2acb2b6cc2481334a1deac1336b3450f85b3428bbf79631cc2f8427",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Fast heartbeat"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "completedScheduling": null,
            "startTime": "2022-07-28T08:57:41.91Z",
            "endTime": "2022-07-28T09:00:34.789Z",
            "timeSpent": 173,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgKCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgKCQAAAAAAAA==/",
            "_etag": "\"6b00c2a1-0000-0100-0000-62e26c310000\"",
            "_attachments": "attachments/",
            "_ts": 1659006001
        },
        {
            "id": "e4f3e29343d0cb719c0c9401db960ff47a8e25c0602f0d75b1aa9d6943ace002",
            "conversationId": "e4f3e29343d0cb719c0c9401db960ff47a8e25c0602f0d75b1aa9d6943ace002",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "completedScheduling": null,
            "startTime": "2022-07-28T08:40:27.18Z",
            "endTime": "2022-07-28T08:40:54.339Z",
            "timeSpent": 27,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgLCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgLCQAAAAAAAA==/",
            "_etag": "\"6b009345-0000-0100-0000-62e25e210000\"",
            "_attachments": "attachments/",
            "_ts": 1659002401
        },
        {
            "id": "ee0ae28c210a987ee20ea706f60e4dad7aa2d53e044ff122f3d0b7880503fdee",
            "conversationId": "ee0ae28c210a987ee20ea706f60e4dad7aa2d53e044ff122f3d0b7880503fdee",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-28T09:43:37.824Z",
            "endTime": "2022-07-28T09:43:37.824Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgMCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgMCQAAAAAAAA==/",
            "_etag": "\"6b007de8-0000-0100-0000-62e27a430000\"",
            "_attachments": "attachments/",
            "_ts": 1659009603
        },
        {
            "id": "e55827f944bcbdcb013bbc2c80acafba0758c1d421ee67d17c6442177a6a042d",
            "conversationId": "e55827f944bcbdcb013bbc2c80acafba0758c1d421ee67d17c6442177a6a042d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-07-28T09:00:58.375Z",
            "endTime": "2022-07-28T09:09:50.391Z",
            "timeSpent": 532,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgNCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgNCQAAAAAAAA==/",
            "_etag": "\"6b00c0a1-0000-0100-0000-62e26c310000\"",
            "_attachments": "attachments/",
            "_ts": 1659006001
        },
        {
            "id": "230b0f7ec1e90a35024d4e463d8d4e92938e65fe5cd23cce2a7666ac0e327b9a",
            "conversationId": "230b0f7ec1e90a35024d4e463d8d4e92938e65fe5cd23cce2a7666ac0e327b9a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Visible head lice on scalp"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the emergency room",
            "patientPlansTo": "I will go to ReadyMED / urgent care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-07-28T10:19:17.039Z",
            "endTime": "2022-07-28T10:20:26.609Z",
            "timeSpent": 70,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgOCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgOCQAAAAAAAA==/",
            "_etag": "\"6b007ae8-0000-0100-0000-62e27a430000\"",
            "_attachments": "attachments/",
            "_ts": 1659009603
        },
        {
            "id": "daa333abdd9a0cb4a856af1f2abb1be9cb8b1b24754100e2fc30649c1ddd6fa6",
            "conversationId": "daa333abdd9a0cb4a856af1f2abb1be9cb8b1b24754100e2fc30649c1ddd6fa6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Accelerated breathing"
            ],
            "riskFactors": [
                "Diabetes",
                "Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": "I will go to ReadyMED / urgent care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "completedScheduling": null,
            "startTime": "2022-07-28T10:15:50.507Z",
            "endTime": "2022-07-28T10:19:00.876Z",
            "timeSpent": 190,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgPCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgPCQAAAAAAAA==/",
            "_etag": "\"6b007be8-0000-0100-0000-62e27a430000\"",
            "_attachments": "attachments/",
            "_ts": 1659009603
},

    
        {
            "id": "75cff4b541d13285afbd2ef63258fdbd5e4accba4663c41876b9cb022e56dbf0",
            "conversationId": "75cff4b541d13285afbd2ef63258fdbd5e4accba4663c41876b9cb022e56dbf0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Fast heartbeat",
                "Accelerated breathing"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Call 9-1-1",
            "patientPlansTo": "I will go to the emergency room",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "completedScheduling": null,
            "startTime": "2022-07-28T10:20:35.018Z",
            "endTime": "2022-07-28T10:21:28.067Z",
            "timeSpent": 53,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgQCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgQCQAAAAAAAA==/",
            "_etag": "\"6b007ce8-0000-0100-0000-62e27a430000\"",
            "_attachments": "attachments/",
            "_ts": 1659009603
        },
        {
            "id": "41fad6029b7aa166946b28c7977d9157be365fc677fc0259419e9def94e0dbe9",
            "conversationId": "41fad6029b7aa166946b28c7977d9157be365fc677fc0259419e9def94e0dbe9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-28T10:12:46.92Z",
            "endTime": "2022-07-28T10:12:46.92Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgRCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgRCQAAAAAAAA==/",
            "_etag": "\"6b0079e8-0000-0100-0000-62e27a430000\"",
            "_attachments": "attachments/",
            "_ts": 1659009603
        },
        {
            "id": "8e3dd893576cffd67478360998e8652b008a5d629374e64cc57887600fb1471d",
            "conversationId": "8e3dd893576cffd67478360998e8652b008a5d629374e64cc57887600fb1471d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Fast heartbeat"
            ],
            "riskFactors": [
                "Diabetes",
                "Obesity",
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "completedScheduling": null,
            "startTime": "2022-07-28T10:49:57.541Z",
            "endTime": "2022-07-28T10:55:58.648Z",
            "timeSpent": 361,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgSCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgSCQAAAAAAAA==/",
            "_etag": "\"6b0078e8-0000-0100-0000-62e27a430000\"",
            "_attachments": "attachments/",
            "_ts": 1659009603
        },
        {
            "id": "620b718c94933d91a7bc62096ef82bee2f74b94adc9d260cec70a117ef297b2c",
            "conversationId": "620b718c94933d91a7bc62096ef82bee2f74b94adc9d260cec70a117ef297b2c",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [
                "Diabetes",
                "Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will go to ReadyMED / urgent care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "completedScheduling": null,
            "startTime": "2022-07-28T12:54:50.937Z",
            "endTime": "2022-07-28T12:59:14.015Z",
            "timeSpent": 263,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgTCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgTCQAAAAAAAA==/",
            "_etag": "\"6c00877c-0000-0100-0000-62e296650000\"",
            "_attachments": "attachments/",
            "_ts": 1659016805
        },
        {
            "id": "637b251bde34c8b490133c79bca40f79a66a15ded880083e39545f99c95fe6ea",
            "conversationId": "637b251bde34c8b490133c79bca40f79a66a15ded880083e39545f99c95fe6ea",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [
                "Diabetes",
                "Obesity",
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "completedScheduling": null,
            "startTime": "2022-07-28T12:34:26.636Z",
            "endTime": "2022-07-28T12:48:30.499Z",
            "timeSpent": 844,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgUCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgUCQAAAAAAAA==/",
            "_etag": "\"6c00a9c3-0000-0100-0000-62e2a4720000\"",
            "_attachments": "attachments/",
            "_ts": 1659020402
        },
        {
            "id": "dad818174429778263aa20f3ee6904b08b9e2864fbf45606afb3a053480169a9",
            "conversationId": "dad818174429778263aa20f3ee6904b08b9e2864fbf45606afb3a053480169a9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [
                "Diabetes",
                "Obesity",
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "completedScheduling": null,
            "startTime": "2022-07-28T13:20:30.238Z",
            "endTime": "2022-07-28T13:22:39.1Z",
            "timeSpent": 129,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgVCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgVCQAAAAAAAA==/",
            "_etag": "\"6c00a8c3-0000-0100-0000-62e2a4720000\"",
            "_attachments": "attachments/",
            "_ts": 1659020402
        },
        {
            "id": "a5939e07b611425672d28a3772659d321655892aacf623c016ce2f87582886f5",
            "conversationId": "a5939e07b611425672d28a3772659d321655892aacf623c016ce2f87582886f5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [
                "Diabetes",
                "Obesity",
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "completedScheduling": null,
            "startTime": "2022-07-28T13:24:54.452Z",
            "endTime": "2022-07-28T13:27:11.009Z",
            "timeSpent": 137,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgWCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgWCQAAAAAAAA==/",
            "_etag": "\"6c00a3c3-0000-0100-0000-62e2a4720000\"",
            "_attachments": "attachments/",
            "_ts": 1659020402
        },
        {
            "id": "8022b4d3c0080d17aa9a6abdbfe4243c8f5d162f77936c522c5126df2072d016",
            "conversationId": "8022b4d3c0080d17aa9a6abdbfe4243c8f5d162f77936c522c5126df2072d016",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [
                "Diabetes",
                "Obesity",
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "completedScheduling": null,
            "startTime": "2022-07-28T14:05:58.247Z",
            "endTime": "2022-07-28T14:08:02.27Z",
            "timeSpent": 124,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgXCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgXCQAAAAAAAA==/",
            "_etag": "\"6d003012-0000-0100-0000-62e2b2810000\"",
            "_attachments": "attachments/",
            "_ts": 1659024001
        },
        {
            "id": "793c97d18dae2274b2685e7a634cfcc737efafc6745e03628d4aad95b9656338",
            "conversationId": "793c97d18dae2274b2685e7a634cfcc737efafc6745e03628d4aad95b9656338",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-28T14:04:51.29Z",
            "endTime": "2022-07-28T14:04:51.29Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgYCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgYCQAAAAAAAA==/",
            "_etag": "\"6d003112-0000-0100-0000-62e2b2810000\"",
            "_attachments": "attachments/",
            "_ts": 1659024001
        },
        {
            "id": "41e8198343d47bd6a7f703c7fb3da93e634fc56d517536d508c911e378ff16f7",
            "conversationId": "41e8198343d47bd6a7f703c7fb3da93e634fc56d517536d508c911e378ff16f7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-28T14:05:24.363Z",
            "endTime": "2022-07-28T14:05:24.363Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgZCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgZCQAAAAAAAA==/",
            "_etag": "\"6d003212-0000-0100-0000-62e2b2810000\"",
            "_attachments": "attachments/",
            "_ts": 1659024001
        },
        {
            "id": "0ff5c433497fc593bd60e1350c0fd137a73904ee65e62e4f514ecd506b0b3a23",
            "conversationId": "0ff5c433497fc593bd60e1350c0fd137a73904ee65e62e4f514ecd506b0b3a23",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-28T14:19:52.534Z",
            "endTime": "2022-07-28T14:19:52.534Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgaCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgaCQAAAAAAAA==/",
            "_etag": "\"6d003312-0000-0100-0000-62e2b2810000\"",
            "_attachments": "attachments/",
            "_ts": 1659024001
        },
        {
            "id": "0c3ba12d14716fc69472ed75b13c8e62e827cc0c73f44dd2f604b9b1c6a0e790",
            "conversationId": "0c3ba12d14716fc69472ed75b13c8e62e827cc0c73f44dd2f604b9b1c6a0e790",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-28T17:49:04.036Z",
            "endTime": "2022-07-28T17:49:04.036Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgbCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgbCQAAAAAAAA==/",
            "_etag": "\"6e00edfc-0000-0100-0000-62e2eac20000\"",
            "_attachments": "attachments/",
            "_ts": 1659038402
        },
        {
            "id": "f006bb5c2c989d8110c404fd3a9d87c6463e46bb9489eb474134579088899163",
            "conversationId": "f006bb5c2c989d8110c404fd3a9d87c6463e46bb9489eb474134579088899163",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [
                "Diabetes",
                "Obesity",
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "completedScheduling": null,
            "startTime": "2022-07-28T17:23:14.494Z",
            "endTime": "2022-07-28T17:25:25.237Z",
            "timeSpent": 131,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgcCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgcCQAAAAAAAA==/",
            "_etag": "\"6e007fac-0000-0100-0000-62e2dcb10000\"",
            "_attachments": "attachments/",
            "_ts": 1659034801
        },
        {
            "id": "b293835dca014dd77993c015824623896ae39437e7a4015fc2fc929576e642ee",
            "conversationId": "b293835dca014dd77993c015824623896ae39437e7a4015fc2fc929576e642ee",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [
                "Diabetes",
                "Obesity",
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "completedScheduling": null,
            "startTime": "2022-07-28T17:23:20.171Z",
            "endTime": "2022-07-28T17:25:28.142Z",
            "timeSpent": 128,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgdCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgdCQAAAAAAAA==/",
            "_etag": "\"6e0083ac-0000-0100-0000-62e2dcb10000\"",
            "_attachments": "attachments/",
            "_ts": 1659034801
        },
        {
            "id": "207c434082000e54c33c5384f21fb39b60df7988bff7ff94f28a5d9d909b5e35",
            "conversationId": "207c434082000e54c33c5384f21fb39b60df7988bff7ff94f28a5d9d909b5e35",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-28T17:22:53.793Z",
            "endTime": "2022-07-28T17:22:53.793Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgeCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgeCQAAAAAAAA==/",
            "_etag": "\"6e007eac-0000-0100-0000-62e2dcb10000\"",
            "_attachments": "attachments/",
            "_ts": 1659034801
        },
        {
            "id": "d78acd441a077a57f3906e6834142eb79e0097c1d5d510833636ff2ae1af6156",
            "conversationId": "d78acd441a077a57f3906e6834142eb79e0097c1d5d510833636ff2ae1af6156",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Wrist pain"
            ],
            "riskFactors": [
                "Diabetes",
                "Obesity",
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will schedule an in-person appointment",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "completedScheduling": null,
            "startTime": "2022-07-28T18:22:26.37Z",
            "endTime": "2022-07-28T18:24:46.187Z",
            "timeSpent": 140,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgfCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgfCQAAAAAAAA==/",
            "_etag": "\"6e00eafc-0000-0100-0000-62e2eac20000\"",
            "_attachments": "attachments/",
            "_ts": 1659038402
        },
        {
            "id": "f8d29eabfcf56096d483a8503be4f7c5831fe0450df530abf1bcc6d5c0be2f73",
            "conversationId": "f8d29eabfcf56096d483a8503be4f7c5831fe0450df530abf1bcc6d5c0be2f73",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unbearable dizziness"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": "I will go to the emergency room",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "completedScheduling": null,
            "startTime": "2022-07-28T18:33:08.525Z",
            "endTime": "2022-07-28T18:33:33.144Z",
            "timeSpent": 25,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIggCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIggCQAAAAAAAA==/",
            "_etag": "\"6e00ebfc-0000-0100-0000-62e2eac20000\"",
            "_attachments": "attachments/",
            "_ts": 1659038402
        },
        {
            "id": "543ff5cebbe94c722fe4a283fd1a5311791818f2afff01c8a8174876b11c59f2",
            "conversationId": "543ff5cebbe94c722fe4a283fd1a5311791818f2afff01c8a8174876b11c59f2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "completedScheduling": null,
            "startTime": "2022-07-28T18:27:28.829Z",
            "endTime": "2022-07-28T18:27:40.001Z",
            "timeSpent": 11,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIghCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIghCQAAAAAAAA==/",
            "_etag": "\"6e00ecfc-0000-0100-0000-62e2eac20000\"",
            "_attachments": "attachments/",
            "_ts": 1659038402
        },
        {
            "id": "7ed08df61ebce693c809432a970b27bf35ad921873610c63530d3d8c89ce9b08",
            "conversationId": "7ed08df61ebce693c809432a970b27bf35ad921873610c63530d3d8c89ce9b08",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [
                "Diabetes",
                "Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Contact my clinic",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-07-28T18:41:18.463Z",
            "endTime": "2022-07-28T18:43:19.18Z",
            "timeSpent": 121,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgiCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgiCQAAAAAAAA==/",
            "_etag": "\"6f001a5b-0000-0100-0000-62e2f8d10000\"",
            "_attachments": "attachments/",
            "_ts": 1659042001
        },
        {
            "id": "292f627aa3aa31a49fc6d7582e721e2fe254fdcffa05c0427c9d4d18af56a8e5",
            "conversationId": "292f627aa3aa31a49fc6d7582e721e2fe254fdcffa05c0427c9d4d18af56a8e5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Toothache",
                "Toothache",
                " severe"
            ],
            "riskFactors": [
                "Diabetes",
                "Obesity",
                "Hypertension",
                "Smoking"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule a virtual visit",
            "patientPlansTo": "I will go to ReadyMED / urgent care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "completedScheduling": null,
            "startTime": "2022-07-28T19:57:02.757Z",
            "endTime": "2022-07-28T20:00:11.696Z",
            "timeSpent": 189,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgjCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgjCQAAAAAAAA==/",
            "_etag": "\"6f0067a2-0000-0100-0000-62e306e30000\"",
            "_attachments": "attachments/",
            "_ts": 1659045603
        },
        {
            "id": "b6e0bed543f36e9080d24859f3b37b43031f1e23c7fab5a216aa6484908f77cc",
            "conversationId": "b6e0bed543f36e9080d24859f3b37b43031f1e23c7fab5a216aa6484908f77cc",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Depressed mood",
                "Elevated mood",
                "Lost will to live"
            ],
            "riskFactors": [
                "Diabetes",
                "Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "completedScheduling": null,
            "startTime": "2022-07-28T20:05:50.783Z",
            "endTime": "2022-07-28T20:08:34.103Z",
            "timeSpent": 163,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgkCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgkCQAAAAAAAA==/",
            "_etag": "\"6f0064a2-0000-0100-0000-62e306e30000\"",
            "_attachments": "attachments/",
            "_ts": 1659045603
        },
        {
            "id": "09475c6c64883135c7421ba4253af9fc25f6ad8eb05e620b5e2b799c5ecdaa77",
            "conversationId": "09475c6c64883135c7421ba4253af9fc25f6ad8eb05e620b5e2b799c5ecdaa77",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Depressed mood",
                "Elevated mood",
                "Lost will to live"
            ],
            "riskFactors": [
                "Diabetes",
                "Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "completedScheduling": "yes",
            "startTime": "2022-07-28T20:15:04.534Z",
            "endTime": "2022-07-28T20:18:12.256Z",
            "timeSpent": 188,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIglCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIglCQAAAAAAAA==/",
            "_etag": "\"6f0065a2-0000-0100-0000-62e306e30000\"",
            "_attachments": "attachments/",
            "_ts": 1659045603
        },
        {
            "id": "8511abea649ae6c2c8a4059f49fd1d3092885552630cd2ffb5408e5d43e7cb16",
            "conversationId": "8511abea649ae6c2c8a4059f49fd1d3092885552630cd2ffb5408e5d43e7cb16",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unbearable dizziness"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": "I will go to the emergency room",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "completedScheduling": null,
            "startTime": "2022-07-28T20:24:24.648Z",
            "endTime": "2022-07-28T20:24:49.084Z",
            "timeSpent": 24,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgmCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgmCQAAAAAAAA==/",
            "_etag": "\"6f0066a2-0000-0100-0000-62e306e30000\"",
            "_attachments": "attachments/",
            "_ts": 1659045603
        },
        {
            "id": "4d200123ab28a04f7529253b74450e3e5f70c07521971cc0e03a047520ef4443",
            "conversationId": "4d200123ab28a04f7529253b74450e3e5f70c07521971cc0e03a047520ef4443",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Schedule an in-person appointment",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "completedScheduling": null,
            "startTime": "2022-07-29T12:02:26.072Z",
            "endTime": "2022-07-29T12:09:43.272Z",
            "timeSpent": 437,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgnCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgnCQAAAAAAAA==/",
            "_etag": "\"7500fcaf-0000-0100-0000-62e3e7e10000\"",
            "_attachments": "attachments/",
            "_ts": 1659103201
        },
        {
            "id": "e7c14726dbf9e054072bebe27acd8232c3874dfd8493c068bed39fb4fd199f60",
            "conversationId": "e7c14726dbf9e054072bebe27acd8232c3874dfd8493c068bed39fb4fd199f60",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-07-29T13:18:08.051Z",
            "endTime": "2022-07-29T13:18:27.671Z",
            "timeSpent": 20,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgoCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgoCQAAAAAAAA==/",
            "_etag": "\"76009504-0000-0100-0000-62e3f5f00000\"",
            "_attachments": "attachments/",
            "_ts": 1659106801
        },
        {
            "id": "28cf5a83f365e1866cc7703a0d2c43c436d28dcbb333943db207c199015d2ab5",
            "conversationId": "28cf5a83f365e1866cc7703a0d2c43c436d28dcbb333943db207c199015d2ab5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [
                "Diabetes",
                "Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "completedScheduling": null,
            "startTime": "2022-07-29T14:41:42.048Z",
            "endTime": "2022-07-29T14:45:11.407Z",
            "timeSpent": 209,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgpCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgpCQAAAAAAAA==/",
            "_etag": "\"76003cc2-0000-0100-0000-62e412120000\"",
            "_attachments": "attachments/",
            "_ts": 1659114002
        },
        {
            "id": "84a7cfc492222f8c55e4c19798aa7425ae1862532b2762b47b07efeec5c05d5a",
            "conversationId": "84a7cfc492222f8c55e4c19798aa7425ae1862532b2762b47b07efeec5c05d5a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Stomach pain"
            ],
            "riskFactors": [
                "Obesity",
                "Hypertension"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "completedScheduling": null,
            "startTime": "2022-07-31T16:44:23.254Z",
            "endTime": "2022-07-31T16:48:10.519Z",
            "timeSpent": 227,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgqCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgqCQAAAAAAAA==/",
            "_etag": "\"93005403-0000-0100-0000-62e6c3210000\"",
            "_attachments": "attachments/",
            "_ts": 1659290401
        },
        {
            "id": "fac848b995dddf4bb2b8b6a780ccb55d75c0fc9f5fdcb98137d165d68ce678ad",
            "conversationId": "fac848b995dddf4bb2b8b6a780ccb55d75c0fc9f5fdcb98137d165d68ce678ad",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-01T13:34:53.834Z",
            "endTime": "2022-08-01T13:35:04.556Z",
            "timeSpent": 11,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgrCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgrCQAAAAAAAA==/",
            "_etag": "\"9c0085fc-0000-0100-0000-62e7f8820000\"",
            "_attachments": "attachments/",
            "_ts": 1659369602
        },
        {
            "id": "e86845d3847abc0d5a516fb073d633060a6f8fe63c0968186d3c444a5e16d8fa",
            "conversationId": "e86845d3847abc0d5a516fb073d633060a6f8fe63c0968186d3c444a5e16d8fa",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "completedScheduling": null,
            "startTime": "2022-08-01T13:35:53.854Z",
            "endTime": "2022-08-01T13:51:04.338Z",
            "timeSpent": 910,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgsCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgsCQAAAAAAAA==/",
            "_etag": "\"9c00bfa3-0000-0100-0000-62e7ea710000\"",
            "_attachments": "attachments/",
            "_ts": 1659366001
        },
        {
            "id": "f97d47671fcca790d80ae18f61382bae1bd94a81e0533d7d91f1147c009c8a03",
            "conversationId": "f97d47671fcca790d80ae18f61382bae1bd94a81e0533d7d91f1147c009c8a03",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-02T17:05:22.737Z",
            "endTime": "2022-08-02T17:05:51.692Z",
            "timeSpent": 29,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgtCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgtCQAAAAAAAA==/",
            "_etag": "\"01005e2d-0000-0100-0000-62e974310000\"",
            "_attachments": "attachments/",
            "_ts": 1659466801
        },
        {
            "id": "37efb4a9d8b0f1860c09e1302b7288d75b6b56551ab0d38cb09659b642559a0f",
            "conversationId": "37efb4a9d8b0f1860c09e1302b7288d75b6b56551ab0d38cb09659b642559a0f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe chest pain",
                "Excessive sweating"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency_ambulance",
            "completedScheduling": null,
            "startTime": "2022-08-03T13:48:01.324Z",
            "endTime": "2022-08-03T13:48:34.48Z",
            "timeSpent": 33,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIguCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIguCQAAAAAAAA==/",
            "_etag": "\"0100fcff-0000-0100-0000-62ea9b810000\"",
            "_attachments": "attachments/",
            "_ts": 1659542401
        },
        {
            "id": "0d824d60dad81225e109918556bad5cf1f78fd7cf7d7eb6a2084f6cf3a5a386a",
            "conversationId": "0d824d60dad81225e109918556bad5cf1f78fd7cf7d7eb6a2084f6cf3a5a386a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "completedScheduling": null,
            "startTime": "2022-08-03T16:03:31.543Z",
            "endTime": "2022-08-03T16:24:32.269Z",
            "timeSpent": 1261,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgvCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgvCQAAAAAAAA==/",
            "_etag": "\"0200f20d-0000-0100-0000-62eab7a10000\"",
            "_attachments": "attachments/",
            "_ts": 1659549601
        },
        {
            "id": "af89ccc9e2989554a30f41372bd56605220b356df4108ffc59e36f1e7aa9d0cb",
            "conversationId": "af89ccc9e2989554a30f41372bd56605220b356df4108ffc59e36f1e7aa9d0cb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Runny nose",
                "Watery eyes"
            ],
            "riskFactors": [
                "Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-08-03T16:40:21.471Z",
            "endTime": "2022-08-03T16:42:11.924Z",
            "timeSpent": 110,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgwCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgwCQAAAAAAAA==/",
            "_etag": "\"02002914-0000-0100-0000-62eac5b10000\"",
            "_attachments": "attachments/",
            "_ts": 1659553201
        },
        {
            "id": "00d83d04ac74985c08d0631d4c312a5dbae051293b05d9575f405ad87848a0db",
            "conversationId": "00d83d04ac74985c08d0631d4c312a5dbae051293b05d9575f405ad87848a0db",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-03T19:23:28.088Z",
            "endTime": "2022-08-03T19:23:30.372Z",
            "timeSpent": 2,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgxCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgxCQAAAAAAAA==/",
            "_etag": "\"0200061d-0000-0100-0000-62eae20d0000\"",
            "_attachments": "attachments/",
            "_ts": 1659560461
        },
        {
            "id": "d1f66328776cbd0df837ae99903504dfbafc47e9596ffd9980278f9eba01e255",
            "conversationId": "d1f66328776cbd0df837ae99903504dfbafc47e9596ffd9980278f9eba01e255",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-03T19:26:40.27Z",
            "endTime": "2022-08-03T19:26:45.733Z",
            "timeSpent": 5,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgyCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgyCQAAAAAAAA==/",
            "_etag": "\"0200051d-0000-0100-0000-62eae20d0000\"",
            "_attachments": "attachments/",
            "_ts": 1659560461
        },
        {
            "id": "deed8505ba1270c504becf0a0d247263ebc786598cd659365504fa5ee5a3891a",
            "conversationId": "deed8505ba1270c504becf0a0d247263ebc786598cd659365504fa5ee5a3891a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-03T19:28:05.233Z",
            "endTime": "2022-08-03T19:28:07.846Z",
            "timeSpent": 3,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIgzCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIgzCQAAAAAAAA==/",
            "_etag": "\"0200071d-0000-0100-0000-62eae20d0000\"",
            "_attachments": "attachments/",
            "_ts": 1659560461
        },
        {
            "id": "6888047a09eb2866d94f298722993ee2bea8e81da08faa340ea978c8c56650f1",
            "conversationId": "6888047a09eb2866d94f298722993ee2bea8e81da08faa340ea978c8c56650f1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-03T20:27:57.719Z",
            "endTime": "2022-08-03T20:28:01.122Z",
            "timeSpent": 3,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg0CQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg0CQAAAAAAAA==/",
            "_etag": "\"0200d721-0000-0100-0000-62eaefe10000\"",
            "_attachments": "attachments/",
            "_ts": 1659564001
        },
        {
            "id": "f13956ae50b8b82ad07c4f53b03075a5ea9a0732a2a73cf40a1343ba09b4fcc9",
            "conversationId": "f13956ae50b8b82ad07c4f53b03075a5ea9a0732a2a73cf40a1343ba09b4fcc9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-03T20:30:17.525Z",
            "endTime": "2022-08-03T20:30:21.597Z",
            "timeSpent": 4,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg1CQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg1CQAAAAAAAA==/",
            "_etag": "\"02002025-0000-0100-0000-62eafdf10000\"",
            "_attachments": "attachments/",
            "_ts": 1659567601
        },
        {
            "id": "51871bd64a738d8c3b55a30898197c818a343d9eee7c0be5c02df9fdc1f36046",
            "conversationId": "51871bd64a738d8c3b55a30898197c818a343d9eee7c0be5c02df9fdc1f36046",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-04T11:59:46.808Z",
            "endTime": "2022-08-04T11:59:46.808Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg2CQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg2CQAAAAAAAA==/",
            "_etag": "\"0300dd09-0000-0100-0000-62ebd11d0000\"",
            "_attachments": "attachments/",
            "_ts": 1659621661
        },
        {
            "id": "dc9189c2f292dc30040d24d84ee3c0f7041e6c03970dbaeb79218a3e6e7dcce6",
            "conversationId": "dc9189c2f292dc30040d24d84ee3c0f7041e6c03970dbaeb79218a3e6e7dcce6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-04T18:32:58.372Z",
            "endTime": "2022-08-04T18:35:31.28Z",
            "timeSpent": 153,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg3CQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg3CQAAAAAAAA==/",
            "_etag": "\"03008735-0000-0100-0000-62ec33530000\"",
            "_attachments": "attachments/",
            "_ts": 1659646803
        },
        {
            "id": "cc18daf5fa8b04d22dbcefd75742002f6cb5577dc4050dbc44518680a60f5b84",
            "conversationId": "cc18daf5fa8b04d22dbcefd75742002f6cb5577dc4050dbc44518680a60f5b84",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-04T19:56:15.806Z",
            "endTime": "2022-08-04T19:56:15.806Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg4CQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg4CQAAAAAAAA==/",
            "_etag": "\"0300b13a-0000-0100-0000-62ec41620000\"",
            "_attachments": "attachments/",
            "_ts": 1659650402
        },
        {
            "id": "842447a8e8170392b00ea3baa93abd8d34933a6ac3bff842ac0eb8f866f141c9",
            "conversationId": "842447a8e8170392b00ea3baa93abd8d34933a6ac3bff842ac0eb8f866f141c9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Severe chest pain"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "completedScheduling": null,
            "startTime": "2022-08-04T20:55:46.498Z",
            "endTime": "2022-08-04T20:56:07.313Z",
            "timeSpent": 21,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg5CQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg5CQAAAAAAAA==/",
            "_etag": "\"0300133f-0000-0100-0000-62ec4f710000\"",
            "_attachments": "attachments/",
            "_ts": 1659654001
        },
        {
            "id": "4bcde905a262a5f1ef32bb5d83fed16c3c697892292d95a58dec7bb3a38e1939",
            "conversationId": "4bcde905a262a5f1ef32bb5d83fed16c3c697892292d95a58dec7bb3a38e1939",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unbearable dizziness"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "completedScheduling": null,
            "startTime": "2022-08-05T17:05:32.892Z",
            "endTime": "2022-08-05T17:05:54.375Z",
            "timeSpent": 21,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg6CQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg6CQAAAAAAAA==/",
            "_etag": "\"0400cd15-0000-0100-0000-62ed68b10000\"",
            "_attachments": "attachments/",
            "_ts": 1659726001
        },
        {
            "id": "b02e520da1373e5f76363279063aeae0920956d68326c576fd9ab6c2b2ea4dcb",
            "conversationId": "b02e520da1373e5f76363279063aeae0920956d68326c576fd9ab6c2b2ea4dcb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough"
            ],
            "riskFactors": [
                "Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the emergency room",
            "patientPlansTo": "Contact me within two business days",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "completedScheduling": null,
            "startTime": "2022-08-08T12:04:30.585Z",
            "endTime": "2022-08-08T12:06:09.694Z",
            "timeSpent": 99,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg7CQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg7CQAAAAAAAA==/",
            "_etag": "\"05009f83-0000-0100-0000-62f116e20000\"",
            "_attachments": "attachments/",
            "_ts": 1659967202
        },
        {
            "id": "5efbdddc8f5460bf198747d60d569e990081e6b4b9f091b52dee02a118436511",
            "conversationId": "5efbdddc8f5460bf198747d60d569e990081e6b4b9f091b52dee02a118436511",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-08T13:56:32.61Z",
            "endTime": "2022-08-08T13:56:32.61Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg8CQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg8CQAAAAAAAA==/",
            "_etag": "\"0500d189-0000-0100-0000-62f133010000\"",
            "_attachments": "attachments/",
            "_ts": 1659974401
        },
        {
            "id": "b04314484e9a304a3d9c82a11db7c6f7d69770cfbd24feba2ae5d2e8a125e082",
            "conversationId": "b04314484e9a304a3d9c82a11db7c6f7d69770cfbd24feba2ae5d2e8a125e082",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-08T13:58:45.213Z",
            "endTime": "2022-08-08T13:59:07.348Z",
            "timeSpent": 22,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg9CQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg9CQAAAAAAAA==/",
            "_etag": "\"0500d289-0000-0100-0000-62f133010000\"",
            "_attachments": "attachments/",
            "_ts": 1659974401
        },
        {
            "id": "0b7fbf873ad2b15358dbec544611beedfe0e294473d978759dfb18ca7d851fc9",
            "conversationId": "0b7fbf873ad2b15358dbec544611beedfe0e294473d978759dfb18ca7d851fc9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unbearable dizziness"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": "I will go to the emergency room",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "completedScheduling": null,
            "startTime": "2022-08-08T15:58:32.949Z",
            "endTime": "2022-08-08T16:00:26.332Z",
            "timeSpent": 113,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg+CQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg+CQAAAAAAAA==/",
            "_etag": "\"0500028f-0000-0100-0000-62f14f210000\"",
            "_attachments": "attachments/",
            "_ts": 1659981601
        },
        {
            "id": "e802fb7453f9ab06f8fa5380e1d2aae557c6ca47ce33c8c9ff31b7f11827576e",
            "conversationId": "e802fb7453f9ab06f8fa5380e1d2aae557c6ca47ce33c8c9ff31b7f11827576e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 65,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Unbearable dizziness"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": "I will go to the emergency room",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "completedScheduling": null,
            "startTime": "2022-08-08T16:06:02.317Z",
            "endTime": "2022-08-08T16:06:36.245Z",
            "timeSpent": 34,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIg-CQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIg-CQAAAAAAAA==/",
            "_etag": "\"0500038f-0000-0100-0000-62f14f210000\"",
            "_attachments": "attachments/",
            "_ts": 1659981601
        },
        {
            "id": "8ce6fc2d71be4dbf60c03d8feb2936bbaddd0881ff31196731e08a4666fb7d46",
            "conversationId": "8ce6fc2d71be4dbf60c03d8feb2936bbaddd0881ff31196731e08a4666fb7d46",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unbearable dizziness"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": "I will go to the emergency room",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "completedScheduling": null,
            "startTime": "2022-08-08T16:35:10.648Z",
            "endTime": "2022-08-08T16:35:36.996Z",
            "timeSpent": 26,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhACQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhACQAAAAAAAA==/",
            "_etag": "\"0500048f-0000-0100-0000-62f14f210000\"",
            "_attachments": "attachments/",
            "_ts": 1659981601
        },
        {
            "id": "f3c880ac020387df25e25135f1079d1fb2fab68a207daadc9b915b282431905a",
            "conversationId": "f3c880ac020387df25e25135f1079d1fb2fab68a207daadc9b915b282431905a",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-08T19:25:33.378Z",
            "endTime": "2022-08-08T19:25:41.514Z",
            "timeSpent": 8,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhBCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhBCQAAAAAAAA==/",
            "_etag": "\"05003996-0000-0100-0000-62f179510000\"",
            "_attachments": "attachments/",
            "_ts": 1659992401
        },
        {
            "id": "37fc86c325a2fd0572d6ea292eb9dc44a0bdffe54c02c6604d1ca4d71f8108e1",
            "conversationId": "37fc86c325a2fd0572d6ea292eb9dc44a0bdffe54c02c6604d1ca4d71f8108e1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-09T15:47:23.177Z",
            "endTime": "2022-08-09T15:47:23.177Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhCCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhCCQAAAAAAAA==/",
            "_etag": "\"060010da-0000-0100-0000-62f2a0a20000\"",
            "_attachments": "attachments/",
            "_ts": 1660068002
        },
        {
            "id": "ea1cf7e4909c3c78a25c8a23de05a3e7eb4d68128cd69f896790717a639b1da4",
            "conversationId": "ea1cf7e4909c3c78a25c8a23de05a3e7eb4d68128cd69f896790717a639b1da4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Earache",
                "Clogged ear",
                "Dizzy"
            ],
            "riskFactors": [
                "Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the emergency room",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "completedScheduling": null,
            "startTime": "2022-08-09T15:25:20.785Z",
            "endTime": "2022-08-09T15:27:13.126Z",
            "timeSpent": 112,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhDCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhDCQAAAAAAAA==/",
            "_etag": "\"060070d2-0000-0100-0000-62f292920000\"",
            "_attachments": "attachments/",
            "_ts": 1660064402
        },
        {
            "id": "b21883f1d44da7178a2b343231967ce699cdadc7b59ced7e53478daf144850ef",
            "conversationId": "b21883f1d44da7178a2b343231967ce699cdadc7b59ced7e53478daf144850ef",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-09T15:38:40.491Z",
            "endTime": "2022-08-09T15:38:40.491Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhECQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhECQAAAAAAAA==/",
            "_etag": "\"06000fda-0000-0100-0000-62f2a0a20000\"",
            "_attachments": "attachments/",
            "_ts": 1660068002
        },
        {
            "id": "7425b97a72bb7b34130513190da3b3bce94997afff085a2885abb471a4343714",
            "conversationId": "7425b97a72bb7b34130513190da3b3bce94997afff085a2885abb471a4343714",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-09T15:27:38.139Z",
            "endTime": "2022-08-09T15:27:38.139Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhFCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhFCQAAAAAAAA==/",
            "_etag": "\"060072d2-0000-0100-0000-62f292920000\"",
            "_attachments": "attachments/",
            "_ts": 1660064402
        },
        {
            "id": "380ca6bf6d016c554a57538d6aca3e00ef8ada4e6b1e589be79744e34de73090",
            "conversationId": "380ca6bf6d016c554a57538d6aca3e00ef8ada4e6b1e589be79744e34de73090",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-09T15:37:49.433Z",
            "endTime": "2022-08-09T15:37:49.433Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhGCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhGCQAAAAAAAA==/",
            "_etag": "\"06000eda-0000-0100-0000-62f2a0a20000\"",
            "_attachments": "attachments/",
            "_ts": 1660068002
        },
        {
            "id": "d96a7d668ede221998728ff2dce70ebd739c69cec8d44526d8b4d73bb4e44a1d",
            "conversationId": "d96a7d668ede221998728ff2dce70ebd739c69cec8d44526d8b4d73bb4e44a1d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-09T15:34:24.202Z",
            "endTime": "2022-08-09T15:34:24.202Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhHCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhHCQAAAAAAAA==/",
            "_etag": "\"06000dda-0000-0100-0000-62f2a0a20000\"",
            "_attachments": "attachments/",
            "_ts": 1660068002
        },
        {
            "id": "927d678d33edf4019518cbc1eb152b6179d6c5e8606d92fd644e10c57c2c75d3",
            "conversationId": "927d678d33edf4019518cbc1eb152b6179d6c5e8606d92fd644e10c57c2c75d3",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 65,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache",
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the emergency room",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "completedScheduling": null,
            "startTime": "2022-08-09T15:13:58.534Z",
            "endTime": "2022-08-09T15:14:53.068Z",
            "timeSpent": 55,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhICQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhICQAAAAAAAA==/",
            "_etag": "\"060076d2-0000-0100-0000-62f292920000\"",
            "_attachments": "attachments/",
            "_ts": 1660064402
        },
        {
            "id": "df82e10c2be1ee79ad61c6bdd5e3d75c2015a32767fe50c0d4bcf3fa4b744929",
            "conversationId": "df82e10c2be1ee79ad61c6bdd5e3d75c2015a32767fe50c0d4bcf3fa4b744929",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Earache",
                "Clogged ear",
                "Dizzy"
            ],
            "riskFactors": [
                "Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the emergency room",
            "patientPlansTo": "I will go to ReadyMED / urgent care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "completedScheduling": null,
            "startTime": "2022-08-09T15:17:16.574Z",
            "endTime": "2022-08-09T15:19:04.919Z",
            "timeSpent": 108,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhJCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhJCQAAAAAAAA==/",
            "_etag": "\"060079d2-0000-0100-0000-62f292920000\"",
            "_attachments": "attachments/",
            "_ts": 1660064402
        },
        {
            "id": "0da61571b5e7dcaee1871b809e35c243e16917bbf36e4542ad307689e7a44265",
            "conversationId": "0da61571b5e7dcaee1871b809e35c243e16917bbf36e4542ad307689e7a44265",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 65,
            "ageGroup": "65+",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache",
                "Cough"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "urgent_care",
            "completedScheduling": null,
            "startTime": "2022-08-09T15:14:58.371Z",
            "endTime": "2022-08-09T15:16:49.837Z",
            "timeSpent": 111,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhKCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhKCQAAAAAAAA==/",
            "_etag": "\"060078d2-0000-0100-0000-62f292920000\"",
            "_attachments": "attachments/",
            "_ts": 1660064402
        },
        {
            "id": "8ddb3437911578dd295ca97b255ec54e7d1096e48fa5bfe8843b9a3b212fb168",
            "conversationId": "8ddb3437911578dd295ca97b255ec54e7d1096e48fa5bfe8843b9a3b212fb168",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-09T15:14:57.207Z",
            "endTime": "2022-08-09T15:14:57.207Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhLCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhLCQAAAAAAAA==/",
            "_etag": "\"060077d2-0000-0100-0000-62f292920000\"",
            "_attachments": "attachments/",
            "_ts": 1660064402
        },
        {
            "id": "693ec662447d76a951cbb3933a9314cb93cadc7bf66350954b2c0787d00d941b",
            "conversationId": "693ec662447d76a951cbb3933a9314cb93cadc7bf66350954b2c0787d00d941b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-09T15:09:53.333Z",
            "endTime": "2022-08-09T15:40:05.581Z",
            "timeSpent": 1812,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhMCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhMCQAAAAAAAA==/",
            "_etag": "\"060075d2-0000-0100-0000-62f292920000\"",
            "_attachments": "attachments/",
            "_ts": 1660064402
        },
        {
            "id": "385b8dc8ca46a01c30bb889b0ff69df9ef3b897d9760e83be00d79ee21037424",
            "conversationId": "385b8dc8ca46a01c30bb889b0ff69df9ef3b897d9760e83be00d79ee21037424",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-09T16:47:54.265Z",
            "endTime": "2022-08-09T16:48:00.871Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhNCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhNCQAAAAAAAA==/",
            "_etag": "\"0600d7e0-0000-0100-0000-62f2aeb10000\"",
            "_attachments": "attachments/",
            "_ts": 1660071601
        },
        {
            "id": "040f27cfba1bfd0cd081bd40d333eb8b911bc6f61cf143a6bacd08e8b67a0adc",
            "conversationId": "040f27cfba1bfd0cd081bd40d333eb8b911bc6f61cf143a6bacd08e8b67a0adc",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unbearable dizziness"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "completedScheduling": null,
            "startTime": "2022-08-09T20:30:13.227Z",
            "endTime": "2022-08-09T20:30:29.812Z",
            "timeSpent": 17,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhOCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhOCQAAAAAAAA==/",
            "_etag": "\"07002e4d-0000-0100-0000-62f2e72e0000\"",
            "_attachments": "attachments/",
            "_ts": 1660086062
        },
        {
            "id": "b14521000f9b55e0ed4d3cd71001ad399dc9f6b37b4e1c867a9d2920a27f9c41",
            "conversationId": "b14521000f9b55e0ed4d3cd71001ad399dc9f6b37b4e1c867a9d2920a27f9c41",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-10T04:19:28.533Z",
            "endTime": "2022-08-10T04:19:28.533Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhPCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhPCQAAAAAAAA==/",
            "_etag": "\"08002f72-0000-0100-0000-62f349620000\"",
            "_attachments": "attachments/",
            "_ts": 1660111202
        },
        {
            "id": "87e6249327c3c7d565c9b60ef43fd4fd1c49aca91ef928ba824b38edf9567234",
            "conversationId": "87e6249327c3c7d565c9b60ef43fd4fd1c49aca91ef928ba824b38edf9567234",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-10T14:47:30.627Z",
            "endTime": "2022-08-10T14:47:30.627Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhQCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhQCQAAAAAAAA==/",
            "_etag": "\"02001022-0000-0100-0000-62f3e4120000\"",
            "_attachments": "attachments/",
            "_ts": 1660150802
        },
        {
            "id": "112b44fc97cf0cf0f22c99200ae07683210d6d1bc53e76a23894bbc5aad42c46",
            "conversationId": "112b44fc97cf0cf0f22c99200ae07683210d6d1bc53e76a23894bbc5aad42c46",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Cough",
                "Runny nose",
                "Watery eyes"
            ],
            "riskFactors": [
                "Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to the emergency room",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-08-10T14:35:32.314Z",
            "endTime": "2022-08-10T14:38:16.066Z",
            "timeSpent": 164,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 10,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhRCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhRCQAAAAAAAA==/",
            "_etag": "\"010028bb-0000-0100-0000-62f3d6010000\"",
            "_attachments": "attachments/",
            "_ts": 1660147201
        },
        {
            "id": "c82e7c3e1f462af1e5ab17668d42bbb0418edf8326c1ce60f3e5e999ada77b9b",
            "conversationId": "c82e7c3e1f462af1e5ab17668d42bbb0418edf8326c1ce60f3e5e999ada77b9b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-10T14:58:00.595Z",
            "endTime": "2022-08-10T14:58:00.595Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhSCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhSCQAAAAAAAA==/",
            "_etag": "\"02001122-0000-0100-0000-62f3e4120000\"",
            "_attachments": "attachments/",
            "_ts": 1660150802
        },
        {
            "id": "36d0b7e8281ec1911137f7a4ff599faecf0922cdb5ee1286f2cbc38a3954dd0e",
            "conversationId": "36d0b7e8281ec1911137f7a4ff599faecf0922cdb5ee1286f2cbc38a3954dd0e",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Runny nose",
                "Watery eyes",
                "Cough"
            ],
            "riskFactors": [
                "Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Go to urgent care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-08-10T15:15:33.497Z",
            "endTime": "2022-08-10T15:22:02.312Z",
            "timeSpent": 389,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhTCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhTCQAAAAAAAA==/",
            "_etag": "\"02000f22-0000-0100-0000-62f3e4120000\"",
            "_attachments": "attachments/",
            "_ts": 1660150802
        },
        {
            "id": "9ac6c0d13d271136c5192b2f0124c5982ce296f08c98b32ac79984b1ac78fbb9",
            "conversationId": "9ac6c0d13d271136c5192b2f0124c5982ce296f08c98b32ac79984b1ac78fbb9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-10T17:37:46.166Z",
            "endTime": "2022-08-10T17:37:46.166Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhUCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhUCQAAAAAAAA==/",
            "_etag": "\"0400d3bd-0000-0100-0000-62f40e410000\"",
            "_attachments": "attachments/",
            "_ts": 1660161601
        },
        {
            "id": "262d732e51da6f307757c16b023fd862a2772692a323a96c943ad4f95c15068f",
            "conversationId": "262d732e51da6f307757c16b023fd862a2772692a323a96c943ad4f95c15068f",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [
                "Diabetes",
                "Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "completedScheduling": "yes",
            "startTime": "2022-08-11T18:35:13.407Z",
            "endTime": "2022-08-11T18:36:21.556Z",
            "timeSpent": 68,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhVCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhVCQAAAAAAAA==/",
            "_etag": "\"0d00e93c-0000-0100-0000-62f56dd10000\"",
            "_attachments": "attachments/",
            "_ts": 1660251601
        },
        {
            "id": "fa69bb8909bc0023834a323052477696afa7aaf4b01121fceabaa03d45c7e6a1",
            "conversationId": "fa69bb8909bc0023834a323052477696afa7aaf4b01121fceabaa03d45c7e6a1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-11T19:31:22.794Z",
            "endTime": "2022-08-11T19:31:22.794Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhWCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhWCQAAAAAAAA==/",
            "_etag": "\"0d006a68-0000-0100-0000-62f57be30000\"",
            "_attachments": "attachments/",
            "_ts": 1660255203
        },
        {
            "id": "9ece4b042ae330cd4b7d0fbe9b3b5cd6d1202b115c8b819efd62ca4e342a0df7",
            "conversationId": "9ece4b042ae330cd4b7d0fbe9b3b5cd6d1202b115c8b819efd62ca4e342a0df7",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-12T02:16:12.557Z",
            "endTime": "2022-08-12T02:16:12.557Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhXCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhXCQAAAAAAAA==/",
            "_etag": "\"0e0025a4-0000-0100-0000-62f5d0420000\"",
            "_attachments": "attachments/",
            "_ts": 1660276802
        },
        {
            "id": "e52628f3d12ce7bf2a352d6a41f0afe258c9423fc9108a6ba429a30b53985952",
            "conversationId": "e52628f3d12ce7bf2a352d6a41f0afe258c9423fc9108a6ba429a30b53985952",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Lightheaded",
                "Fatigue",
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will try self-care",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "completedScheduling": null,
            "startTime": "2022-08-15T00:43:28.264Z",
            "endTime": "2022-08-15T00:46:14.721Z",
            "timeSpent": 166,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhYCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhYCQAAAAAAAA==/",
            "_etag": "\"230021c9-0000-0100-0000-62f9a8a10000\"",
            "_attachments": "attachments/",
            "_ts": 1660528801
        },
        {
            "id": "6d3e1672485aac2bf0e329e48e3fa4eda9a1dce9633a7a2feca94ae751e03332",
            "conversationId": "6d3e1672485aac2bf0e329e48e3fa4eda9a1dce9633a7a2feca94ae751e03332",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Depressed mood",
                "Elevated mood",
                "Lost will to live"
            ],
            "riskFactors": [
                "Diabetes",
                "Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "completedScheduling": null,
            "startTime": "2022-08-15T12:53:37.199Z",
            "endTime": "2022-08-15T12:55:44.631Z",
            "timeSpent": 127,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhZCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhZCQAAAAAAAA==/",
            "_etag": "\"2a007d69-0000-0100-0000-62fa5f730000\"",
            "_attachments": "attachments/",
            "_ts": 1660575603
        },
        {
            "id": "1b3056a186f17f6ea92b0fd164a9ab07c77393e6039baee5f8fe9120665543c0",
            "conversationId": "1b3056a186f17f6ea92b0fd164a9ab07c77393e6039baee5f8fe9120665543c0",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-15T13:27:35.152Z",
            "endTime": "2022-08-15T13:27:35.152Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhaCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhaCQAAAAAAAA==/",
            "_etag": "\"2a007a69-0000-0100-0000-62fa5f730000\"",
            "_attachments": "attachments/",
            "_ts": 1660575603
        },
        {
            "id": "6dd1e3d51d529fc9c24eae134b5bc5f3c1b5392f5d322e121fbc7accedf33a54",
            "conversationId": "6dd1e3d51d529fc9c24eae134b5bc5f3c1b5392f5d322e121fbc7accedf33a54",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-15T13:51:46.951Z",
            "endTime": "2022-08-15T13:54:30.582Z",
            "timeSpent": 164,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhbCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhbCQAAAAAAAA==/",
            "_etag": "\"2b00ff75-0000-0100-0000-62fa6d810000\"",
            "_attachments": "attachments/",
            "_ts": 1660579201
        },
        {
            "id": "fd7ade81900a2d3d28ab91bdf3d17e84820175b58c752a4ff1e52dfef17a89cb",
            "conversationId": "fd7ade81900a2d3d28ab91bdf3d17e84820175b58c752a4ff1e52dfef17a89cb",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-15T13:53:27.563Z",
            "endTime": "2022-08-15T13:53:35.012Z",
            "timeSpent": 7,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhcCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhcCQAAAAAAAA==/",
            "_etag": "\"2b00fe75-0000-0100-0000-62fa6d810000\"",
            "_attachments": "attachments/",
            "_ts": 1660579201
        },
        {
            "id": "c089d8623cb733dd6766fc31c55ffb9b43c3341384c8bd790f02ce4c61d7313d",
            "conversationId": "c089d8623cb733dd6766fc31c55ffb9b43c3341384c8bd790f02ce4c61d7313d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Depressed mood",
                "Elevated mood",
                "Lost will to live"
            ],
            "riskFactors": [
                "Diabetes",
                "Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "virtual_care",
            "completedScheduling": "yes",
            "startTime": "2022-08-15T14:02:47.405Z",
            "endTime": "2022-08-15T14:07:51.33Z",
            "timeSpent": 304,
            "isNoteGenerated": "yes",
            "npsGroup": "Promoter",
            "npsScore": 9,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhdCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhdCQAAAAAAAA==/",
            "_etag": "\"2b00fd75-0000-0100-0000-62fa6d810000\"",
            "_attachments": "attachments/",
            "_ts": 1660579201
        },
        {
            "id": "d30514bbf7b8cce2a61b61192c88de12187f53164a64d2b9b9ffb421bcde24df",
            "conversationId": "d30514bbf7b8cce2a61b61192c88de12187f53164a64d2b9b9ffb421bcde24df",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "self_care",
            "completedScheduling": null,
            "startTime": "2022-08-15T16:28:08.338Z",
            "endTime": "2022-08-15T16:30:12.228Z",
            "timeSpent": 124,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIheCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIheCQAAAAAAAA==/",
            "_etag": "\"2d002282-0000-0100-0000-62fa97b20000\"",
            "_attachments": "attachments/",
            "_ts": 1660590002
        },
        {
            "id": "2d97d1e1160ed19f9cc53944ad2cee2f9938456b0925d6ba2fb80d266258c2c8",
            "conversationId": "2d97d1e1160ed19f9cc53944ad2cee2f9938456b0925d6ba2fb80d266258c2c8",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-15T16:59:33.643Z",
            "endTime": "2022-08-15T16:59:33.643Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhfCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhfCQAAAAAAAA==/",
            "_etag": "\"2d002182-0000-0100-0000-62fa97b20000\"",
            "_attachments": "attachments/",
            "_ts": 1660590002
        },
        {
            "id": "2926ee19b896c4921e8f358e8cee63f9f702a0d510db9953e9897a3a376e5aec",
            "conversationId": "2926ee19b896c4921e8f358e8cee63f9f702a0d510db9953e9897a3a376e5aec",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": "I will schedule a virtual care visit",
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "completedScheduling": "yes",
            "startTime": "2022-08-16T12:50:14.633Z",
            "endTime": "2022-08-16T12:52:36.395Z",
            "timeSpent": 142,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhgCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhgCQAAAAAAAA==/",
            "_etag": "\"32009b13-0000-0100-0000-62fbb0f20000\"",
            "_attachments": "attachments/",
            "_ts": 1660662002
        },
        {
            "id": "97b235f714dfc73f2777847a1cffaa742819a1f5e699c55b5fac3d521e7abb67",
            "conversationId": "97b235f714dfc73f2777847a1cffaa742819a1f5e699c55b5fac3d521e7abb67",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "primary_care",
            "completedScheduling": null,
            "startTime": "2022-08-16T13:23:26.591Z",
            "endTime": "2022-08-16T13:25:10.473Z",
            "timeSpent": 104,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhhCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhhCQAAAAAAAA==/",
            "_etag": "\"32009913-0000-0100-0000-62fbb0f20000\"",
            "_attachments": "attachments/",
            "_ts": 1660662002
        },
        {
            "id": "62e96c7d9cc07e1ce69ebaab13af5e490c2175e1848ec31fc12a98c7dcf36fa1",
            "conversationId": "62e96c7d9cc07e1ce69ebaab13af5e490c2175e1848ec31fc12a98c7dcf36fa1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-16T13:22:45.144Z",
            "endTime": "2022-08-16T13:23:20.251Z",
            "timeSpent": 35,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhiCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhiCQAAAAAAAA==/",
            "_etag": "\"32009a13-0000-0100-0000-62fbb0f20000\"",
            "_attachments": "attachments/",
            "_ts": 1660662002
        },
        {
            "id": "902187fa1dcf3b5e3c7bd8ae0ef664890c61bae0672995c556c11a7eb1bf98f4",
            "conversationId": "902187fa1dcf3b5e3c7bd8ae0ef664890c61bae0672995c556c11a7eb1bf98f4",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-16T13:28:10.116Z",
            "endTime": "2022-08-16T13:29:58.543Z",
            "timeSpent": 108,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhjCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhjCQAAAAAAAA==/",
            "_etag": "\"32009713-0000-0100-0000-62fbb0f20000\"",
            "_attachments": "attachments/",
            "_ts": 1660662002
        },
        {
            "id": "ce4fe8eca6f18acc799309ce2b0206fbb344190764bd0e27ffcc4395983547b9",
            "conversationId": "ce4fe8eca6f18acc799309ce2b0206fbb344190764bd0e27ffcc4395983547b9",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-16T13:17:55.349Z",
            "endTime": "2022-08-16T13:19:18.489Z",
            "timeSpent": 83,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhkCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhkCQAAAAAAAA==/",
            "_etag": "\"32009813-0000-0100-0000-62fbb0f20000\"",
            "_attachments": "attachments/",
            "_ts": 1660662002
        },
        {
            "id": "90d83d003f254d025e0b1b05669370084a9851eb7b0da9042b6dda9c15dc1dd5",
            "conversationId": "90d83d003f254d025e0b1b05669370084a9851eb7b0da9042b6dda9c15dc1dd5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-16T14:39:40.628Z",
            "endTime": "2022-08-16T14:39:40.628Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhlCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhlCQAAAAAAAA==/",
            "_etag": "\"32008478-0000-0100-0000-62fbcd4d0000\"",
            "_attachments": "attachments/",
            "_ts": 1660669261
        },
        {
            "id": "a1e410ebdd18eb23ab2828aa0bfc4551ea47774cfb667c574af3c57b110c7dc5",
            "conversationId": "a1e410ebdd18eb23ab2828aa0bfc4551ea47774cfb667c574af3c57b110c7dc5",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unknown heart rate"
            ],
            "riskFactors": [
                "Diabetes",
                "Obesity"
            ],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "none",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "eVisit",
            "completedScheduling": null,
            "startTime": "2022-08-16T15:32:00.778Z",
            "endTime": "2022-08-16T15:32:24.609Z",
            "timeSpent": 24,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhmCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhmCQAAAAAAAA==/",
            "_etag": "\"32003ea5-0000-0100-0000-62fbdb210000\"",
            "_attachments": "attachments/",
            "_ts": 1660672801
        },
        {
            "id": "65f58feae8492c2d2b771403e58198258edda717e2c843f769b4ce0a30d7815d",
            "conversationId": "65f58feae8492c2d2b771403e58198258edda717e2c843f769b4ce0a30d7815d",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-16T14:59:55.797Z",
            "endTime": "2022-08-16T15:01:14.151Z",
            "timeSpent": 78,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhnCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhnCQAAAAAAAA==/",
            "_etag": "\"32008178-0000-0100-0000-62fbcd4d0000\"",
            "_attachments": "attachments/",
            "_ts": 1660669261
        },
        {
            "id": "9c5db434d9368532c2420a19cb80e3be14785020ff889d04be983571de876e44",
            "conversationId": "9c5db434d9368532c2420a19cb80e3be14785020ff889d04be983571de876e44",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-16T15:04:15.26Z",
            "endTime": "2022-08-16T15:04:15.26Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhoCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhoCQAAAAAAAA==/",
            "_etag": "\"32008378-0000-0100-0000-62fbcd4d0000\"",
            "_attachments": "attachments/",
            "_ts": 1660669261
        },
        {
            "id": "26a522ff5bf13db18e32a12c57860f84b70987ac7191992b66f90c5a07fd9838",
            "conversationId": "26a522ff5bf13db18e32a12c57860f84b70987ac7191992b66f90c5a07fd9838",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Headache"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": "Self-Care",
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-17T15:54:41.429Z",
            "endTime": "2022-08-17T15:56:07.737Z",
            "timeSpent": 86,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhpCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhpCQAAAAAAAA==/",
            "_etag": "\"37007af3-0000-0100-0000-62fd2ca10000\"",
            "_attachments": "attachments/",
            "_ts": 1660759201
        },
        {
            "id": "5dfd97500c264c66e1418fbf9f90cc530e03763b95b9fa2625616ef3e6e00938",
            "conversationId": "5dfd97500c264c66e1418fbf9f90cc530e03763b95b9fa2625616ef3e6e00938",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-17T15:49:03.044Z",
            "endTime": "2022-08-17T15:49:03.044Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhqCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhqCQAAAAAAAA==/",
            "_etag": "\"370078f3-0000-0100-0000-62fd2ca10000\"",
            "_attachments": "attachments/",
            "_ts": 1660759201
        },
        {
            "id": "f1002f4c404937d695d1269c263d87735dd9de65064fa37456e8c5f86685376b",
            "conversationId": "f1002f4c404937d695d1269c263d87735dd9de65064fa37456e8c5f86685376b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-17T15:54:19.149Z",
            "endTime": "2022-08-17T15:54:19.149Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhrCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhrCQAAAAAAAA==/",
            "_etag": "\"370079f3-0000-0100-0000-62fd2ca10000\"",
            "_attachments": "attachments/",
            "_ts": 1660759201
        },
        {
            "id": "4d6a75d9f87a3fbfbc5ded1b77bb7159a55a23f93f28d3331cbad757ee6de90b",
            "conversationId": "4d6a75d9f87a3fbfbc5ded1b77bb7159a55a23f93f28d3331cbad757ee6de90b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-18T15:44:28.915Z",
            "endTime": "2022-08-18T15:44:28.915Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhsCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhsCQAAAAAAAA==/",
            "_etag": "\"000038a7-0000-0100-0000-62fe7e210000\"",
            "_attachments": "attachments/",
            "_ts": 1660845601
        },
        {
            "id": "02cc0c5f1e5e07bfa742d9a3b104b088bdb685661907eed99c7db09f95ad3944",
            "conversationId": "02cc0c5f1e5e07bfa742d9a3b104b088bdb685661907eed99c7db09f95ad3944",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-18T18:22:37.5Z",
            "endTime": "2022-08-18T18:22:37.5Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhtCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhtCQAAAAAAAA==/",
            "_etag": "\"02008d9e-0000-0100-0000-62fe9a430000\"",
            "_attachments": "attachments/",
            "_ts": 1660852803
        },
        {
            "id": "dc4a0430e638a362f6507c267484fb56e9a9ab9a1b66863527df65ebde4b06b2",
            "conversationId": "dc4a0430e638a362f6507c267484fb56e9a9ab9a1b66863527df65ebde4b06b2",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-18T18:17:17.469Z",
            "endTime": "2022-08-18T18:17:17.469Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhuCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhuCQAAAAAAAA==/",
            "_etag": "\"02008c9e-0000-0100-0000-62fe9a430000\"",
            "_attachments": "attachments/",
            "_ts": 1660852803
        },
        {
            "id": "b94f302f5206cb4ea036a0ad7f59b9aacce1433ea8a1dfa1779ec05f148ee0ef",
            "conversationId": "b94f302f5206cb4ea036a0ad7f59b9aacce1433ea8a1dfa1779ec05f148ee0ef",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-18T18:57:10.575Z",
            "endTime": "2022-08-18T18:57:10.575Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhvCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhvCQAAAAAAAA==/",
            "_etag": "\"03005684-0000-0100-0000-62fea8510000\"",
            "_attachments": "attachments/",
            "_ts": 1660856401
        },
        {
            "id": "35c2c2ad93db2c3be5d78d93d59977276d1e608e31853d98490e4a130ebdee00",
            "conversationId": "35c2c2ad93db2c3be5d78d93d59977276d1e608e31853d98490e4a130ebdee00",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-18T19:00:41.639Z",
            "endTime": "2022-08-18T19:00:41.639Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhwCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhwCQAAAAAAAA==/",
            "_etag": "\"03005584-0000-0100-0000-62fea8510000\"",
            "_attachments": "attachments/",
            "_ts": 1660856401
        },
        {
            "id": "7337ea79597962bce7bd9f0d684502f94f53a364e1d1c14e18d4aeca7cf9c347",
            "conversationId": "7337ea79597962bce7bd9f0d684502f94f53a364e1d1c14e18d4aeca7cf9c347",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-18T19:08:01.796Z",
            "endTime": "2022-08-18T19:08:01.796Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhxCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhxCQAAAAAAAA==/",
            "_etag": "\"03005284-0000-0100-0000-62fea8510000\"",
            "_attachments": "attachments/",
            "_ts": 1660856401
        },
        {
            "id": "12f6c920b8c96fc91f313f7a51ebd318543f55efb6aee760ce7c593f9bc6acd1",
            "conversationId": "12f6c920b8c96fc91f313f7a51ebd318543f55efb6aee760ce7c593f9bc6acd1",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-18T19:21:26.119Z",
            "endTime": "2022-08-18T19:21:26.119Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhyCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhyCQAAAAAAAA==/",
            "_etag": "\"03005484-0000-0100-0000-62fea8510000\"",
            "_attachments": "attachments/",
            "_ts": 1660856401
        },
        {
            "id": "9619057e4ebf5d8a0749328871c9be44e2952551570e5fcda88df7c99acd27d6",
            "conversationId": "9619057e4ebf5d8a0749328871c9be44e2952551570e5fcda88df7c99acd27d6",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "no",
            "isDisclaimerComplete": "no",
            "isChiefComplaintComplete": "no",
            "isRiskFactorsComplete": "no",
            "isRedFlagsComplete": "no",
            "isRelatedSymptomsComplete": "no",
            "isInterviewComplete": "no",
            "isTriageComplete": "no",
            "careLevelRecommendation": null,
            "completedScheduling": null,
            "startTime": "2022-08-18T19:40:58.635Z",
            "endTime": "2022-08-18T19:40:58.635Z",
            "timeSpent": 0,
            "isNoteGenerated": "no",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIhzCQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIhzCQAAAAAAAA==/",
            "_etag": "\"0400f62a-0000-0100-0000-62feb6610000\"",
            "_attachments": "attachments/",
            "_ts": 1660860001
},
    
        {
            "id": "79f751393de0e3fedd69e50bc18f2170eb890e89fd96ac7accd7daf622b5863b",
            "conversationId": "79f751393de0e3fedd69e50bc18f2170eb890e89fd96ac7accd7daf622b5863b",
            "cdoId": null,
            "cdoName": "Reliant",
            "gender": "Female",
            "age": 39,
            "ageGroup": "36-45",
            "state": "Virginia",
            "chiefComplaint": [
                "Unbearable dizziness"
            ],
            "riskFactors": [],
            "redFlags": [],
            "relatedSymptoms": [],
            "careAlternative": null,
            "patientPlansTo": null,
            "isStarted": "yes",
            "openedApp": "yes",
            "isWelcomeComplete": "yes",
            "isDisclaimerComplete": "yes",
            "isChiefComplaintComplete": "yes",
            "isRiskFactorsComplete": "yes",
            "isRedFlagsComplete": "yes",
            "isRelatedSymptomsComplete": "yes",
            "isInterviewComplete": "yes",
            "isTriageComplete": "yes",
            "careLevelRecommendation": "emergency",
            "completedScheduling": null,
            "startTime": "2022-08-19T12:51:18.777Z",
            "endTime": "2022-08-19T12:53:59.074Z",
            "timeSpent": 160,
            "isNoteGenerated": "yes",
            "npsGroup": null,
            "npsScore": 0,
            "hasAdhered": null,
            "actionSelected": null,
            "_rid": "qHwxAIkCfIh0CQAAAAAAAA==",
            "_self": "dbs/qHwxAA==/colls/qHwxAIkCfIg=/docs/qHwxAIkCfIh0CQAAAAAAAA==/",
            "_etag": "\"0b00638d-0000-0100-0000-62ffa5720000\"",
            "_attachments": "attachments/",
            "_ts": 1660921202
        }

    ]