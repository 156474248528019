import React from 'react';
import styled from 'styled-components';

//************************** */

const Guide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 32px 24px 32px;
    background: #FBF9F4;
    border-radius: 8px;
    margin-bottom: 32px;
`

const Heading = styled.div`
    font-family: "Optum Sans", "Helvetica", "Arial", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 16px;
`

const Markdown = styled.div`
    font-family: "Optum Sans", "Helvetica", "Arial", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #4B4D4F;
    width: 652px;
`

//************************** */
const ChangeLog = () => {
    return (
        <>
            <Guide> 
                <Heading style={{fontSize: '23px', lineHeight : '28px' }}>Changelog</Heading>
                <Markdown>View publish history by user and timestamp. </Markdown>
            </Guide>
        </>
    )
}


export default ChangeLog;