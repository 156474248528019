import React from "react";
import styled from 'styled-components';
import backdrop from '../../../assets/landing_background.png';

//******************************************/

const Preview = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    text-align: center;    
    `

const Backdrop = styled.div`
    background-image: url(${backdrop});
    background-size: cover;
    width: 100%;
    height: 100%;
    `

//******************************************/

const NoPreview = () => {

    return (
        <Preview>
            <Backdrop />
        </Preview>
    )

}

export default NoPreview;