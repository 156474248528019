import React from "react";
import DashCard from './DashCard';
// import styled from 'styled-components';

//*****************************************/


//*****************************************/

const ConfigCard = ({ properties }) => {

    return (
        <DashCard properties={properties}>
            <table style={{ width: '100%' }}>
                <thead>
                    <tr style={{ background: '#CCCCCC' }}>
                        <th style={{ width: '33.33%' }}>Design</th>
                        <th style={{ width: '33.33%' }}>Feature</th>
                        <th style={{ width: '33.33%' }}>Content</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>IncubatorEgg</td>
                        <td>YYYNNYY</td>
                        <td>Terms Document</td>
                    </tr>
                    <tr>
                        <td>Stethy</td>
                        <td>YYYYYYYN</td>
                        <td>Privacy Document</td>
                    </tr>
                    <tr>
                        <td>Inter</td>
                        <td>Yes - No</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td style={{ color: "#335E6B", fontWeight: 700 }}>Color</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>No Welcome Image</td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>

            </table>
        </DashCard>
    )
}

export default ConfigCard;