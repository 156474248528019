import React from "react";
import WelcomeCard from '../cards/WelcomeCard';
import styled from 'styled-components';
import BotHeader from "../cards/widgets/BotHeader";
import Bot from "../cards/widgets/Bot";

//******************************************/

const Root = styled.div`
    `

//******************************************/

const WelcomePreview = () => {

    return (
        <Root>
            <Bot>
                <WelcomeCard />
            </Bot>            
            <BotHeader />
        </Root>
    )
}

export default WelcomePreview;