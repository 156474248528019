import React from "react";
import styled from 'styled-components';

//********************************************* */


const Guide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 32px 24px 32px;
    background: #FBF9F4;
    border-radius: 8px;
    margin-bottom: 32px;
`

const Heading = styled.div`
    font-family: "Optum Sans", "Helvetica", "Arial", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 16px;
`

const Markdown = styled.div`
    font-family: "Optum Sans", "Helvetica", "Arial", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #4B4D4F;
    width: 652px;
`

const Detail = styled.div`
    padding-left: 32px;
`

const Started = styled.div`
    margin-bottom: 48px;
`

const Mark = styled.div`
    margin: 16px 0px;
    font-weight: 700;
    font-family: "Optum Sans", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
    line-height: 20px;
`


//******************************** */

const Release = () => {
    return (
        <>
            <Guide> 
                <Heading style={{fontSize: '23px', lineHeight : '28px' }}>Release notes</Heading>
                <Markdown>Stay up to date on our SympleNote release history and notable changes. </Markdown>
            </Guide>
            <Detail>
                <Started>
                    <Heading>April 2023</Heading>
                    <Markdown>

                        <Markdown>This release included accessibility improvements, new contact methods for patients, an improved user interface for the symptom search question and more.</Markdown>
 
                        <Mark>Accessibility improvements:</Mark>
                        <ul style={{paddingLeft: '12px', marginLeft: '10px' }}>
                            <li>The UI will now work with screen readers.</li>
                        </ul>
 
                        <Mark>The ability for patients to select contact method:</Mark>
                        <ul style={{paddingLeft: '12px', marginLeft: '10px' }}>
                            <li>When a patient selects Contact me within 2 business days on the summary card, they can now choose between a staff sending them a MyChart message or a telephone call.</li>
                            <li>If they select a telephone call, they can verify their phone number or enter a new phone number. If the patient requests a phone call, the note will call out explicitly whether the patient is okay with a message being left at the phone number given.</li>
                        </ul>

                        <Mark>Improved user interface for the symptom search avatar:</Mark>
                        <ul style={{paddingLeft: '12px', marginLeft: '10px' }}>
                            <li>When a patient clicks on a body part to select a symptom, the top 5 symptoms for that body area will show first so that it's easier to find the most common symptoms.</li>
                            <li>The body size was also increased to make it easier for the patient to select the desired body part.</li>
                        </ul>

                        <Mark>Explanation behind a possible cause:</Mark>
                        <ul style={{paddingLeft: '12px', marginLeft: '10px' }}>
                            <li>When possible causes are shown, the reasons for and against that possible cause are listed.</li>
                        </ul>

                        <Mark>Displaying alarming symptoms:</Mark>
                        <ul style={{paddingLeft: '12px', marginLeft: '10px' }}>
                            <li>When a patient is triaged to the emergency room or to 911 the alarming symptoms are displayed.</li>
                        </ul>

                        <Mark>Time out limit:</Mark>
                        <ul style={{paddingLeft: '12px', marginLeft: '10px' }}>
                            <li>SympleNote timeout constraints have been removed and will allow MyChart's timeout to be the limit instead.</li>
                        </ul>

                        <Mark>Updated the NPS question:</Mark>
                        <ul style={{paddingLeft: '12px', marginLeft: '10px' }}>
                            <li>The NPS now requires the patient to select an NPS number rather than having the NPS question default to 10.</li>
                        </ul>

                        <Mark>Virtual Care Team update:</Mark>
                        <ul style={{paddingLeft: '12px', marginLeft: '10px' }}>
                            <li>Updating the wording from Virtual Care Team to Virtual ReadyMED.</li>
                        </ul>

                    </Markdown>
                </Started>

                <Started>
                    <Heading>September 2022</Heading>
                    <Markdown>
                        <Markdown> The goal of this release was to move SympleNote off of the Microsoft HealthBot and onto our own core framework. In addition to this back-end update, the following patient-facing features were also released: </Markdown>
                        <Mark> Updated symptom list: </Mark>
                        
                        <ul style={{paddingLeft: '12px', marginLeft: '10px' }}>
                            <li>The symptom search is now leveraging Infermedica's direct API symptom search list, instead of our own database. This provides the patients with a broader and more comprehensive list of symptoms to choose from. </li>
                        </ul>
                        <Mark> Improved list of risk factors: </Mark>
                        <ul style={{paddingLeft: '12px', marginLeft: '10px' }}>
                            <li>The list of risk factors is now leveraging an Infermedica API, instead of coming from an internal, static list. The list of risk factors will change based on a patients gender, age, and risk factors that are already present in their medical record.</li> 
                        </ul>
                    </Markdown>
                </Started>

                <Started>
                    <Heading>May 2022</Heading>
                    <Markdown>
                        <Mark>The following features were released:</Mark>
                        <ul style={{paddingLeft: '12px', marginLeft: '10px' }}>
                            <li>Added direct virtual care scheduling. Patients will be able to schedule directly within the symptom checker flow with the virtual care team. </li>
                            <li>Removed the NPS question from any ER and 911 triage flows. </li>
                            <li>Patients can now open any external links within the self-care advice area on the summary card. </li>
                            <li>We removed the symptom limit from the symptom search field. Patients will be able to view all applicable symptoms from the search field. </li>
                            <li>Patients will be able to select the provider or PCP they want to send their results to from the welcome card. </li>
                        </ul>
                    </Markdown>
                </Started>
            </Detail>
        </>
    )
}

export default Release;