import React from "react";
import Menu from '../widgets/Menu';
import PatientNextStep from './PatientNextStep';
import NPSEditor from './NPSEditor';
import { connect } from 'react-redux';
import { setState } from "../../../../redux/actions/editorActions";
import { useTranslation } from "react-i18next";

//******************************************/

const FeaturesMenu = ({ setState, onChange }) => {
    const { t } = useTranslation();

    const patientNextStep = {
        name: 'PatientNextStep',
        text: t("sidebar.widgets.menu.features.alternatives"),
        panel: PatientNextStep
    }

    const npsEditor = {
        name: 'NPS',
        text: t("sidebar.widgets.menu.features.nps"),
        panel: NPSEditor
    }

    const tabs = [
        patientNextStep,
        npsEditor
    ]

    const onContextChanged = (tab) => {
        setState('context', tab.name);

        if (onChange instanceof Function)
            onChange(tab.name);
    }

    return (
        <Menu
            tabs={tabs}
            onChange={onContextChanged}
        />
    )
}

const mapStateToProps = (state) => {
    return state;
}

export default connect(mapStateToProps, { setState })(FeaturesMenu);