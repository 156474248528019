import DashCard from "./DashCard";
import CertsCard from "./DPCertsCard";
import ConfigCard from "./DPConfigCard";
import CostCard from "./DPCostCard";
import HealthCard from "./DPHealthCard";
import StatsCard from "./DPStatsCard";
import Stats2Card from "./DPStats2Card";
import MessagingCard from "./DPMessagingCard";
import RawDataCard from "./RawDataCard";
import Sessions from './Sessions';
import Completion from './Completion';
import NPS from './NPS';
import Recommendation from './Recommendations'

const makeCard = (properties, data) => {
    const type = properties?.type?.toUpperCase() || '';

    if (properties?.show)
        switch (type) {
            case 'MESSAGE':
                return <MessagingCard key='Messaging' properties={properties} />
            case 'CERTS':
                return <CertsCard key='CERTS' properties={properties} />
            case 'CONFIG':
                return <ConfigCard key='CONFIG' properties={properties} />
            case 'COST':
                return <CostCard key='COST' properties={properties} />
            case 'HEALTH':
                return <HealthCard key='HEALTHCARD' properties={properties} />
            case 'STATS':
                return <StatsCard key='STATS' properties={properties} />
            case 'STATS2':
                return <Stats2Card key='STATS2' properties={properties} />

            case 'RAWDATA':
                return <RawDataCard key='RAWDATA' properties={properties} data={data} />
            case 'SESSIONS':
                return <Sessions key='SESSIONS' properties={properties} data={data} />
            case 'COMPLETION':
                return <Completion key='COMPLETION' properties={properties} data={data} />
            case 'NPS':
                return <NPS key='NPS' properties={properties} data={data} />
            case 'RECOMMENDATION':
                return <Recommendation key='RECOMMENDATIONS' properties={properties} data={data} />
            default:
                return <DashCard key='DASHCARD' properties={properties} ></DashCard>
        }
}

export default makeCard;