import React from "react";
import { connect } from 'react-redux';
import { setState } from "../../../../redux/actions/editorActions";
import RadioButton from '../widgets/RadioButton';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';

//******************************************/

const Root = styled.div`
    margin-bottom: 40px;
    `

const Description = styled.p`
    color: #505050;
    font-size: 16px;
    font-weight: 400;
    margin: 8px 0 24px 0;
    `

const FontLoader = styled.div`
    font-family: ${props => props.font};
    // overflow: hidden;
    position: fixed;
    top: -100px;
    left: -100px;
`

//******************************************/

const FontEditor = ({ setState, fontFamily }) => {

    const { t } = useTranslation();

    const fontFamilies = t("sidebar.font.fontFamilies")
        ? t("sidebar.font.fontFamilies").split("|")
        : [];

    const handleFontChange = (font) => {
        setState('fontFamily', font);
    }

    return (
        <Root>
            <Description>
                Select a font to use in the symptom checker.
            </Description>
            {
                fontFamilies.map((font, i) => {
                    return (
                        <RadioButton
                            key={font + i}
                            label={font}
                            match={font}
                            value={fontFamily}
                            onClick={() => handleFontChange(font)}
                        />
                    )
                })
            }
            {
                fontFamilies.map((font, i) => {
                    return (
                        <FontLoader key={'loader-'+font+i} font={font}>.</FontLoader>
                    )
                })
            }
        </Root >
    )
}

const mapStateToProps = (state) => {
    const fontFamily = state.editorReducer.fontFamily;

    return { fontFamily };
}

export default connect(mapStateToProps, { setState })(FontEditor);