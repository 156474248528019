
import React, {useState} from "react";
import styled from 'styled-components';
import ThatsItPopUp from "./ThatsItPopUp";
import TourStartPopUp from "./Tour/TourStartPopUp";
import TourSavingPopUp from './Tour/TourSavingPopUp';
import TourPublishPopUp from './Tour/TourPublishPopUp';
import { useTranslation } from "react-i18next";

//************************************* */

const Shader = styled.div`
    top: 0px;
    position: fixed;
    bottom : 0px;
    background: black;
    opacity: 0.25;
    left: 0px;
    right: 0px;
    z-index: 1;
`
const PopUp = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 495px;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border-radius: 8px;
    z-index: 1;
`

const Heading = styled.div`
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    justify-content: center;
    color: #000000;
    margin-bottom: 24px;
    position: relative;
`

const MarkDown= styled.div`
    width: 516.88px;
    height: 22px;   
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    position: relative;
    text-align: center;
    margin-bottom: 60px;
`

const StartButton = styled.button`
    padding: 14.5px 60px;
    height: 48px;
    width: 204px;
    cursor: pointer;
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #002677;
    background: #FFFFFF;
    border: 1.5px solid #002677;
    border-radius: 8px;

    &:hover{
        background: #002677;
        color: #FFFFFF;
    }
`

//****************************** */

const WelcomePopUp = ({ save }) => {
    const {t} = useTranslation();

    const [showTourPopUp, setShowTourPopUp] = useState(false);
    const [SaveTourPopUp, setSaveTourPopUp] = useState(false);
    const [publishTourPopUp, setPublishTourPopUp] = useState(false);
    
    const [thatsItPopUp, setThatsItPopUp] = useState(false);

    return (
        <>
            {
                <>
                    <Shader />
                    <PopUp> 
                        <Heading> {t("PopUp.WelcomePopUp.header")} </Heading>
                        <MarkDown> {t("PopUp.WelcomePopUp.markdown")} </MarkDown>
                        <StartButton onClick={ () => setShowTourPopUp(true) }> {t("PopUp.WelcomePopUp.start")} </StartButton>
                    </PopUp>
                </>
            }

            {
                showTourPopUp 
                ?
                    <TourStartPopUp save={save} setShowTourPopUp={setShowTourPopUp} setSaveTourPopUp={setSaveTourPopUp} />
                : 
                    <></>
            }
            {
                SaveTourPopUp 
                ?
                    <TourSavingPopUp save={save} setSaveTourPopUp={setSaveTourPopUp} setPublishTourPopUp = {setPublishTourPopUp} />
                : 
                    <></>
            }
            {
                publishTourPopUp 
                ?
                    <TourPublishPopUp save={save} setPublishTourPopUp={setPublishTourPopUp} setThatsItPopUp={setThatsItPopUp} />
                : 
                    <></>
            }
            {
                thatsItPopUp 
                ?
                    <ThatsItPopUp save={save} setThatsItPopUp={setThatsItPopUp} />
                : 
                    <></>
            }
        </>
    )
}

export default WelcomePopUp;