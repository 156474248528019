import React from "react";
import { connect } from 'react-redux';
import NPSCard from "../cards/NPSCard";
import FeedbackCard from '../cards/FeedbackCard';
import styled from 'styled-components';
import BotHeader from "../cards/widgets/BotHeader";
import Bot from "../cards/widgets/Bot";

//******************************************/

const Root = styled.div`
    `

//******************************************/

const NPSPreview = ({ hasNPS, hasFeedback }) => {

    return (
        <Root>
            <Bot>
                {
                    hasNPS
                        ? <NPSCard />
                        : null
                }

                {
                    (hasNPS && hasFeedback)
                        ? <FeedbackCard />
                        : null
                }
            </Bot>
            <BotHeader progressName="welcome"/>
        </Root>
    )
}

const mapStateToProps = (state) => {
    const hasNPS = state.editorReducer.hasNPS;
    const hasFeedback = state.editorReducer.hasFeedback;

    return { hasNPS, hasFeedback };
}

export default connect(mapStateToProps)(NPSPreview);
