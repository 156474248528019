import React, { useState, useEffect, useRef } from "react";
import ImgIcon from '../../../../assets/sidebar/image-file-icon.svg';
import CheckIcon from '../../../../assets/sidebar/checkmark.svg';
import TrashIcon from '../../../../assets/sidebar/trashcan.svg';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';

/********************************************/

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    border: 1px solid #A7A7A7;
    border-radius: 4px;
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    padding-left: 23px;

    &.disabled {
        opacity: 50%;
    }
    `

const NoFile = styled.span`
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #505050;
    `

const FileName = styled.div`
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-left: 20px;
    width: 250px;    
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #505050;

    `

const LoadButton = styled.button`
    position: absolute;
    right: 4px;
    font-size: 18;
    text-align: center;
    width: 114px;
    height: 40px;
    border: none;
    border-radius: 4px;
    color: white;
    background-color: #0C55B8;
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;

    &:disabled {
        cursor: auto;
    }
    `

const ImageIcon = styled.div`
    position: absolute;
    left: 16px;
    width: 18px;
    height: 18px;
    background-image: url(${ImgIcon});
    background-repeat: no-repeat;
    background-position: center;   
    `

const CheckMark = styled.div`
    position: absolute;
    right: 50px;
    width: 20px;
    height: 20px;
    background-image: url(${CheckIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-color: #21B01E;
    border-radius: 50%;    
    `

const ClearButton = styled.button`
    position: absolute;
    right: 16px;
    width: 18px;
    height: 20px;
    margin: 0px;
    padding: 0px;
    background-image: url(${TrashIcon});
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:disabled {
        cursor: auto;
    }
    `

/********************************************/

const ImageLoader = (props) => {
    const { onImageChanged, styles1, styles2, accept, disabled, image, limitExceedError, setLimitExceedError } = props;

    const [selectedImage, setSelectedImage] = useState(image);

    const { t } = useTranslation();
    
    const inputFile = useRef(null)

    const isDisabled = disabled;

    const onButtonClick = () => {
        inputFile.current.click();
    };

    const getImgSrc = (files) => {
        if (files[0]) {
            setLimitExceedError(false);
            const file_name = files[0].name;
            const file_size = files[0].size;

            const max_size = 2*1024*1024;

            if(file_size<max_size){
                const fileReader = new FileReader();
                fileReader.readAsDataURL(files[0]);
                fileReader.addEventListener("load", function () {
                    const img = { name: file_name, data: this.result };
                    setSelectedImage(img);

                    if (onImageChanged instanceof Function)
                        onImageChanged(img);
                });
                return;
            }
            setLimitExceedError(true);
        }
    }

    const clearImage = () => {
        setSelectedImage(null);

        if (onImageChanged instanceof Function)
            onImageChanged(null);
    }

    useEffect(() => {
        setSelectedImage(image);
    }, [image])

    return (
        <Root
            title={(selectedImage && selectedImage.name)?selectedImage.name:t("sidebar.widgets.imageselector.nofile")}
            className={isDisabled ? 'disabled' : ''}
            style={!limitExceedError ? styles1 : styles2 }
        >
            {
                (selectedImage && selectedImage.name && !limitExceedError)
                ?
                    <>
                        <ImageIcon />
                        <FileName>
                            {selectedImage.name}
                        </FileName>
                        <CheckMark />
                        <ClearButton
                            onClick={clearImage}
                            disabled={isDisabled}
                        />
                    </>
                :
                    <>
                        <NoFile>
                            {t("sidebar.widgets.imageselector.nofile")}
                        </NoFile>
                        <LoadButton
                            onClick={onButtonClick}
                            disabled={isDisabled}
                        >
                            {t("sidebar.widgets.imageselector.browse")}
                        </LoadButton>
                    </>
            }

            <input
                type='file'
                id='file'
                ref={inputFile}
                accept={accept}
                onClick={(event) => {
                    event.target.value = null
                }}
                onChange={(e) => getImgSrc(e.target.files)}
                style={{ display: 'none' }}
            />
        </Root>
    )
}

export default ImageLoader;