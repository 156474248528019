import React from "react";
import { connect } from 'react-redux';
import { setState } from "../../../../redux/actions/editorActions";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { useNavigate } from "react-router";

//******************************************/

const Root = styled.div`
    margin-bottom: 40px;
    `

const Description = styled.p`
    color: #505050;
    font-size: 16px;
    font-weight: 400;
    margin: 8px 0 40px 0;

    & a, a:link, a:visited, a:hover  {
        color: #0C55B8;
    }

    & a:active {
        color: #0058CC;
    }
    `

const Instruction = styled.p`
    color: #505050;
    font-size: 16px;
    font-weight: 400;
    margin: 16px 0 24px 0;
    `

const Swatch = styled.div`
    background-color: ${props => props.backgroundColor};
    position: relative;
    display: inline-block;
    width: 55px;
    height: 55px;
    margin-right: 16px;
    cursor: pointer;

    &.selected div.indicator {
        background-color: #0C55B8;
    }

    &:hover div.indicator {
        background-color: #0C55B8;
    }
    `

const Selection = styled.div`
    position: absolute;
    bottom: -6px;
    width: 55px;
    height: 2px;
    background-color: transparent;   
    `

const ButtonHalf = styled.div`
    background-color: transparent;
    position: relative;
    top: 0;
    left: 0;
    width: 0; 
    height: 0; 
    border-top: 55px solid transparent;

    border-right: 55px solid ${props => props.color}; 
    `

//******************************************/

const ButtonEditor = ({ setState, buttonColor, trimColor, buttonColors, trimColors }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleColorChange = (index) => {
        setState('buttonColor', buttonColors[index]);
        setState('trimColor', trimColors[index]);
    }

    const handlePopUp=()=>{
        navigate('/support');
    }

    return (
        <Root>
            <Description>
                {t("sidebar.colors.description")}
                <span onClick={()=>handlePopUp()} style={{color: "#0C55B8", cursor: 'pointer'}}>
                    {t("sidebar.colors.contactUs")} 
                </span>
            </Description>
            {
                buttonColors.map((color, i) => {
                    return (
                        <Swatch
                            key={'button' + color}
                            className={(buttonColors[i] === buttonColor && trimColors[i] === trimColor) ? 'selected' : ''}
                            onClick={() => handleColorChange(i)}
                            backgroundColor={trimColors[i]} >
                            <ButtonHalf
                                key={'button' + color}
                                color={buttonColors[i]}
                            />
                            <Selection className='indicator' />
                        </Swatch>
                    )
                })
            }
            <Instruction>
                {t("sidebar.colors.instruction")}
            </Instruction>

        </Root>
    )
}

const mapStateToProps = (state) => {
    const buttonColor = state.editorReducer.buttonColor;
    const trimColor = state.editorReducer.trimColor;
    const buttonColors= state.editorReducer.buttonColorArray;
    const trimColors = state.editorReducer.trimColorArray;

    return { buttonColor, trimColor, trimColors, buttonColors };
}

export default connect(mapStateToProps, { setState })(ButtonEditor);