import React from "react";
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

//********************************************* */


const Guide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 32px 24px 32px;
    background: #FBF9F4;
    border-radius: 8px;
    margin-bottom: 32px;
`

const Heading = styled.div`
    font-family: "Optum Sans", "Helvetica", "Arial", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 16px;
`

const Markdown = styled.div`
    font-family: "Optum Sans", "Helvetica", "Arial", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #4B4D4F;
    width: 652px;
`

const Detail = styled.div`
    padding-left: 32px;
`

const Started = styled.div`
    margin-bottom: 48px;
`

const Published = styled.div`
    margin-bottom: 48px;
`

const Reported = styled.div`
    margin-bottom: 48px;
`

const Mark = styled.div`
    margin-bottom: 16px;
`


//******************************** */

const Reference = ({setShowContact, setShowRef}) => {

    const navigate = useNavigate();

    const toolButtonClicked = (location) => {

        if (location)
            navigate(location);
    }

    const { t } = useTranslation();

    return (
        <>
            <Guide> 
                <Heading style={{fontSize: '23px', lineHeight : '28px' }}>{t("Support.reference.Heading")}</Heading>
                <Markdown>{t("Support.reference.Markdown")}</Markdown>
            </Guide>
            <Detail>
                <Started>
                    <Heading>{t("Support.reference.StartedHeading")}</Heading>
                    <Markdown>
                        <Mark>Start customizing your symptom checker experience by using the <span style={{ textDecorationLine: 'underline', color: '#0C55B8', cursor: 'pointer'}} onClick={() => toolButtonClicked('/editor')} > SympleNote</span> tab in the top navigation. 
                        </Mark>
                        <Mark> {t("Support.reference.StartedMark2")} </Mark>
                        <Mark> {t("Support.reference.StartedMark3")}</Mark>
                    </Markdown>
                </Started>

                <Published>
                    <Heading> {t("Support.reference.PublishedHeading")} </Heading>
                    <Markdown>
                        <Mark> {t("Support.reference.PublishedMark1")} </Mark>
                        <Mark> {t("Support.reference.PublishedMark2")} </Mark>
                        <Mark> When you are ready for your changes to be put into a test or production environment, fill out the <span style={{ textDecorationLine: 'underline', color: '#0C55B8', cursor: 'pointer' }} onClick={()=>{setShowContact(true); setShowRef(false)}}> contact us form</span>. The SympleNote team will contact you to create a release schedule. </Mark>
                    </Markdown>
                </Published>

                <Reported>
                    <Heading>{t("Support.reference.ReportedHeading")}</Heading>
                    <Markdown>
                        Explore data collected from your symptom checker by using the  <span style={{ textDecorationLine: 'underline', color: '#0C55B8', cursor: 'pointer' }} onClick={() => toolButtonClicked('/reporting')}> reporting</span> tab in the top navigation. Reports include the top reported symptoms among your users, usage trends, NPS® and more.
                    </Markdown>
                </Reported>
            </Detail>
        </>
    )
}

export default Reference;