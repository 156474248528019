import axios from "axios";

import {
    PROPERTY_CHANGED,
    ADMIN_DATA_LOAD_STATE,
    ADMIN_DATA_LOAD_STATE_SUCCESS,
    ADMIN_DATA_LOAD_STATE_FAIL,
    ADMIN_DATA_SAVE_STATE,
    ADMIN_DATA_SAVE_STATE_SUCCESS,
    ADMIN_DATA_SAVE_STATE_FAIL
 } from '../types';


export const setState = (property, value) => {
    return dispatch => {
        dispatch({
            type: PROPERTY_CHANGED,
            payload: { property, value }
        })
    };
};


export const loadAdminData = (emailId) => {
    return dispatch => {
        if(!emailId) return;

        if(!(typeof emailId === 'string' || emailId instanceof String)) return;
        
        dispatch({ type: ADMIN_DATA_LOAD_STATE, payload: "LOADING" });

        console.log("EmailId: ", emailId)
        axios
            .get(`https://incubator-customer-api-dev.optum.com/api/GetSympleNoteAdminUser?email=${emailId}`)
            .then((response) => {
                dispatch({ type: ADMIN_DATA_LOAD_STATE_SUCCESS, payload: mapResponseToState(response.data) });
            })
            .catch(function (error) {
                dispatch({ type: ADMIN_DATA_LOAD_STATE_FAIL, payload: error });
            });
    };
}

export const saveAdminData = (state, emailId, appIdList) => {
    return dispatch => {
        if(!emailId) return;
        if(!appIdList) return;

        if(!(typeof emailId === 'string' || emailId instanceof String)) return;
        if(!(typeof appIdList === 'object' || appIdList instanceof Object)) return;
        
        dispatch({ type: ADMIN_DATA_SAVE_STATE, payload: "SAVING" });

        console.log("EmailId :", emailId)
        console.log("appIdList : ", appIdList)
        axios
            .put('https://incubator-customer-api-dev.optum.com/api/SaveSympleNoteAdminUser', mapStateToData(emailId, appIdList))
            .then((response) => {
                dispatch({ type: ADMIN_DATA_SAVE_STATE_SUCCESS, payload: response.data });
            })
            .catch(function (error) {
                dispatch({ type: ADMIN_DATA_SAVE_STATE_FAIL, payload: error });
            });
    };
}

function mapResponseToState(response){
    return {
        showSympleNoteHelpAtStartup : response["showSympleNoteHelpAtStartup"]
    }
}

function mapStateToData(emailId, appIdList) {
    return {
        "appId": appIdList,
        "email": emailId,
        "showSympleNoteHelpAtStartup": false
    }
}