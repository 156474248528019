import React, {useState} from "react";
import { connect } from 'react-redux';
import ImageLoader from "../widgets/ImageSelector";
import RadioButton from '../widgets/RadioButton';
import { setState } from "../../../../redux/actions/editorActions";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import errorIcon from '../../../../assets/toolbar/error_header.svg';

/********************************************/

const Root = styled.div`
    margin-top: 8px;
    padding-bottom: 40px;
    `

const Title = styled.div`
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
    `

const Description = styled.p`
    color: #505050;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 24px;
    `

const ErrorBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    gap: 8px;
    position: relative;
    width: 291px;
    height: 27px;
    background: #FCF0F0;
    border-radius: 4px;
    margin-bottom: 40px;
`

const ErrorIcon = styled.div`
    position: relative;
    width: 18px;
    height: 18px;
    background-image: url(${errorIcon});
    background-repeat: no-repeat;
    background-position: center;   
    `

const Text = styled.div`
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #C40000;
    line-height: 19px;
`
/********************************************/

const HeaderEditor = ({ setState, header, logoData, logoName }) => {
    const { t } = useTranslation();

    const [limitExceedError, setLimitExceedError] = useState(false);

    const handleImageChanged = (image) => {

        const name = image ? image.name : '';
        const data = image ? image.data : null;

        setState('logoName', name);
        setState('logoData', data);
    }

    return (
        <Root>
            <Title>
                {t("sidebar.header.title")}
            </Title>
            <Description>
                {t("sidebar.header.description")}
            </Description>
            <ImageLoader
                onImageChanged={handleImageChanged}
                styles1={{ marginTop: '24px', marginBottom: '40px' }}
                styles2={{ marginTop: '24px', marginBottom : "4px", border: "1px solid #C40000", borderRadius: "4px" }}
                limitExceedError={limitExceedError}
                setLimitExceedError={setLimitExceedError}
                image={{name: logoName, data: logoData}} 
                accept="image/png, image/jpg, image/svg+xml"
            />

            {
                (limitExceedError)
                ?
                    <ErrorBox>
                        <ErrorIcon/>
                        <Text>File size exceeded (max size: 2MB)</Text>
                    </ErrorBox>
                :
                    <> </>
            }

            <Title>
                {t("sidebar.header.format-title")}
            </Title>
            <Description>
                {t("sidebar.header.format-description")}
            </Description>

            <RadioButton
                label={t("sidebar.header.logo-label")}
                description={t("sidebar.header.logo-description")}
                match="logo"
                value={header}
                onClick={() => setState('header', "logo")}
            />

            <RadioButton
                label={t("sidebar.header.progressbar-label")}
                description={t("sidebar.header.progressbar-description")}
                match="progressbar"
                value={header}
                onClick={() => setState('header', "progressbar")}
            />

            <RadioButton
                label={t("sidebar.header.both-label")}
                description={t("sidebar.header.both-description")}
                match="both"
                value={header}
                onClick={() => setState('header', "both")}
            />

            <RadioButton
                label={t("sidebar.header.none-label")}
                description={t("sidebar.header.none-description")}
                match="none"
                value={header}
                onClick={() => setState('header', "none")}
            />
        </Root>
    )
}

const mapStateToProps = (state) => {
    const header = state.editorReducer.header;
    const logoData = state.editorReducer.logoData;
    const logoName = state.editorReducer.logoName;

    return { header, logoData, logoName };
}

export default connect(mapStateToProps, { setState })(HeaderEditor);