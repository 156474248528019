import React, { useState } from "react";
import { connect } from 'react-redux';
import styled from 'styled-components';

//******************************************/

const Root = styled.button`
    font-weight: 400;
    font-size: 16px;
    font-family: ${props => props.font};
    background-color: #fff;
    cursor: pointer;
    border-radius: 6px;
    margin: 0;
    padding: 10px 24px;
    color: ${props => props.color};
    border: 1px solid ${props => props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:not(:last-child) {
        margin-right: 10px;
    }

    &.cancel {
        border: none;
    }

    &:disabled {
        opacity: 0.5;
        color: ${props => props.colorDisabled};
        border-color: ${props => props.colorDisabled};
        cursor: not-allowed;
    }

    &.recommended {
        border-top-left-radius: 0;
    }

    &:hover:not(.cancel):not([disabled]), 
    &.selected:not(.cancel) {        
        color: white;
        background-color: ${props => props.color};
        border: 1px solid ${props => props.color};
    }

    &.selected:hover {
        background-color: ${props => props.color2} !important;
        border: 1px solid ${props => props.color2} !important;
    }
    `

//******************************************/

const Button = ({ text, buttonColor, textColor, fontFamily, toggle, className }) => {
    const [selected, setSelected] = useState(false);

    const cancel = text.startsWith('@');
    const disabled = text.startsWith('#');

    className = className || '';

    text = cancel || disabled
        ? text.slice(1)
        : text

    const handleClick = () => {
        if (!toggle) return;

        setSelected(!selected);
    }

    function toHex(c) {
        var hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    }

    function scaleInt(c, magnitude) {
        c = c + magnitude;
        c > 255 && (c = 255);
        c < 0 && (c = 1);

        return c
    }

    const brightenColor = (hexColor, magnitude) => {
        hexColor = hexColor.replace(`#`, ``);

        if (hexColor.length === 6) {

            let rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
            rgb = rgb ? {
                r: parseInt(rgb[1], 16),
                g: parseInt(rgb[2], 16),
                b: parseInt(rgb[3], 16)
            } : null;

            rgb.r = scaleInt(rgb.r, magnitude);
            rgb.g = scaleInt(rgb.g, magnitude);
            rgb.b = scaleInt(rgb.b, magnitude);

            return `#${toHex(rgb.r)}${toHex(rgb.g)}${toHex(rgb.b)}`;

        } else {
            return hexColor;
        }
    };


    return (
        <Root
            font={fontFamily}
            color={cancel ? textColor : buttonColor}
            color2={brightenColor(buttonColor, -30)}
            colorDisabled={textColor}
            onClick={() => handleClick()}
            className={
                ((cancel) ? 'cancel ' : '') +
                ((selected) ? 'selected ' : '') +
                className}

            disabled={disabled}
        >
            {text}
        </Root>
    )
}

const mapStateToProps = (state) => {
    const buttonColor = state.editorReducer.buttonColor;
    const fontFamily = state.editorReducer.fontFamily;
    const textColor = state.editorReducer.textColor;

    return { buttonColor, fontFamily, textColor };
}

export default connect(mapStateToProps)(Button);
