import React from "react";
import SingleSelectCard from "../cards/SingleSelectCard";
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";

//******************************************/

const CareAlternativePreview = ({ hasAlternatives, careAlternatives }) => {
    const { t } = useTranslation();

    careAlternatives = Array.isArray(careAlternatives)
        ? careAlternatives
        : []

    const buttons = careAlternatives
        .filter(alternative => alternative.isDisplayed)
        .map(alternative => {
            return alternative.displayText;
        })

    return (
        <>
            {
                hasAlternatives &&
                <SingleSelectCard
                    header={t("preview.careAlternative.header")}
                    buttons={buttons}
                    disabled={!hasAlternatives}
                />
            }
        </>
    )
}
const mapStateToProps = (state) => {
    const hasAlternatives = state.editorReducer.hasAlternatives;
    const careAlternatives = state.editorReducer.careAlternatives;

    return { hasAlternatives, careAlternatives };
}

export default connect(mapStateToProps)(CareAlternativePreview);
