import React from "react";
import styled from 'styled-components';
import { connect } from 'react-redux';
import Card from './widgets/Card';
import Button from './widgets/Button';
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";

//******************************************/

const Header = styled.div`
    margin-top: 24px;
    border-bottom: 1px solid #e0e0e0;

    & p {
        margin: 1em 0;
    }
    `

const Buttons = styled.div`
    margin-bottom: 24px;

    & button {
        margin: 8px 0;
    }
    `

//******************************************/

const MultiSelectCard = () => {
    const { t } = useTranslation();

    const header =
        <Header underlined>
            <ReactMarkdown>
                {t('preview.multiSelectCard.header')}
            </ReactMarkdown>
        </Header>
    const text = t('preview.multiSelectCard.text')
    const footer = [t('preview.multiSelectCard.next')]

    return (
        <Card header={header} body={text} footer={footer}>
            <Buttons>
                <Button toggle={true} text={t('preview.multiSelectCard.headache')} />
                <Button toggle={true} text={t('preview.multiSelectCard.cough')} />
                <Button toggle={true} text={t('preview.multiSelectCard.runnyNose')} />
                <Button toggle={true} text={t('preview.multiSelectCard.soreThroat')} />
            </Buttons>
        </Card>
    )
}

const mapStateToProps = (state) => {
    const buttonColor = state.editorReducer.buttonColor;
    const trimColor = state.editorReducer.trimColor;

    return { buttonColor, trimColor };
}
export default connect(mapStateToProps)(MultiSelectCard);