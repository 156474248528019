import React from "react";
import DashCard from './DashCard';
// import styled from 'styled-components';

//*****************************************/



//*****************************************/

const MessagingCard = ({ properties }) => {

    return (
        <DashCard properties={properties}>
            <table style={{ width: '100%' }}>
                <thead>
                    <tr style={{ background: '#CCCCCC' }}>
                        <th style={{ width: '60%' }}>Subject</th>
                        <th style={{ width: '25%' }}>From</th>
                        <th style={{ width: '15%' }}>Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Your Certificate is About to Expire</td>
                        <td>SympleNote</td>
                        <td>3/8/2023</td>
                    </tr>
                    <tr>
                        <td>Introducing SympleNote API</td>
                        <td>SympleNote Team</td>
                        <td>3/7/2023</td>
                    </tr>
                    <tr>
                        <td>SympleNote 2.5 Update Coming</td>
                        <td>SympleNote Team</td>
                        <td>3/7/2023</td>
                    </tr>
                </tbody>

            </table>
        </DashCard>
    )
}

export default MessagingCard;