import React from "react";
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Card from './widgets/Card';
import Button from './widgets/Button';

//******************************************/

const Header = styled.div`
    margin-top: 24px;
    border-bottom: 1px solid #e0e0e0;

    & p {
        margin: 1em 0;
    }
    `

const Buttons = styled.div`
    & button {
        margin: 8px;
        margin-left: 0px;

        &:last-child {
            margin-bottom: 0px;
        }
    }
    `

//******************************************/

const SingleSelectCard = ({ header, text, buttons, disabled }) => {

    buttons = Array.isArray(buttons)
        ? buttons
        : []

    header =
        <Header underlined>
            <ReactMarkdown>
                {header || ""}
            </ReactMarkdown>
        </Header>
    text = text || "";

    return (
        <Card header={header} body={text} footer={[]} disabled={disabled}>
            <Buttons>
                {
                    buttons.map((button, index) => {
                        return <Button key={button+index} text={button} />
                    })
                }
            </Buttons>
        </Card>
    )
}

const mapStateToProps = (state) => {
    const buttonColor = state.editorReducer.buttonColor;
    const trimColor = state.editorReducer.trimColor;

    return { buttonColor, trimColor };
}

export default connect(mapStateToProps)(SingleSelectCard);