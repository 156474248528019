import React from "react";
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Card from './widgets/Card';
import Button from './widgets/Button';
import { ReactComponent as ZippyIcon } from '../../../../assets/sidebar/Vector.svg';
import { useTranslation } from "react-i18next";

//******************************************/

const Header = styled.div`
    border-bottom: 2px solid #e0e0e0;
    margin-bottom: -8px;
    `

const Tab = styled.button`
    color: ${props => props.color};
    font-family: ${props => props.font}; 
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    padding: 1em 0px;
    margin-right: 20px;
    border: none;
    background-color: transparent;
    margin-bottom: -2px; 

    &.selected {        
        border-bottom: 2px solid ${props => props.color};
    }
    `

const Zippy = styled.div`
    color: ${props => props.color};
    font-family: ${props => props.font};
    font-size: 16px;
    font-weight: 400;
    border-radius: 6px;
    background: #f7f7f7;    
    margin: 8px 0;    
    padding: 10px;
    padding-left: 16px;

    & .icon {
        margin-right: 10px;
        transform: rotate(180deg);
    }

    & path {
        fill: ${props => props.color};
    }
    `

const Buttons = styled.div`
    margin: -8px 0;
    
    & button {
        margin: 8px 0;
        
        &:nth-child(2) {
            border-top-left-radius: 0px;
        }
    }
    `

const Causes = styled.div`
    margin-top: 40px;

    & div.last {
        margin-bottom: 0px;
    }
    `

const Plans = styled.div`
    margin-top: 40px;
    `

const Recommended = styled.div`
    color: white;
    position: relative;
    top: 8px;
    height: 21px;
    width: 120px;
    border-radius: 6px 6px 0 0;
    background-color: #094;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .09px;
    line-height: 21px;
    text-align: center;    
    `

const ThankYouBox = styled.div`
    position: relative;
    padding : 24px;
    /* background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), rgba(0, 38, 119, 0.13); */
    background: ${props=>props.background};
    border-radius: 6px;
    align-items: flex-start;
`

const HeaderBox = styled.div`
    color: ${props=>props.color};
    margin-bottom: 16px;
    font-weight: 600;
`

const MarkDownBox = styled.div`
    color: ${props=>props.color};
`


//******************************************/

const SummaryCard = ({ buttonColor, fontFamily, hasPlans, plans }) => {
    const { t } = useTranslation();

    const header =
        <Header>
            <Tab color={buttonColor} font={fontFamily} className='selected' >
                {t('preview.summaryCard.careOption')}
            </Tab>
            <Tab color={buttonColor} font={fontFamily}>
                {t('preview.summaryCard.summary')}
            </Tab>
        </Header>

    const text = t('preview.summaryCard.text')
    const footer = []

    return (
        <Card header={header} body={text} footer={footer}>

            <Causes>
                <ReactMarkdown>
                    {t('preview.summaryCard.possibleCauses')}
                </ReactMarkdown>
                <ReactMarkdown>
                    {t('preview.summaryCard.instruction1')}
                </ReactMarkdown>
                <Zippy color={buttonColor} font={fontFamily}>
                    <ZippyIcon
                        className='icon'
                    />
                    {t('preview.summaryCard.cause1')}
                </Zippy>
                <Zippy color={buttonColor} font={fontFamily} className='last'>
                    <ZippyIcon
                        className='icon'
                    />
                    {t('preview.summaryCard.cause2')}
                </Zippy>
            </Causes>

            {
                (hasPlans && plans.length)
                ? 
                    <Plans>
                        <ReactMarkdown>
                            {t('preview.summaryCard.instruction2')}
                        </ReactMarkdown>

                        <Buttons>
                            <Recommended>Recommended</Recommended>
                            {
                                plans.map((plan, i) => {
                                    return <Button key={plan+i} text={plan} />
                                })
                            }
                        </Buttons>
                    </Plans>
                : 
                    <>
                        <Plans>
                            <ThankYouBox background={buttonColor+'13'}>
                                <HeaderBox color={buttonColor}>
                                    Thank you for using SympleNote to check your symptoms.
                                </HeaderBox>
                                <MarkDownBox color={buttonColor}>
                                    If your symptoms worsen or do not improve, contact your doctor.
                                </MarkDownBox>
                            </ThankYouBox>
                        </Plans>
                    </>
            }
        </Card>
    )
}

const mapStateToProps = (state) => {
    const buttonColor = state.editorReducer.buttonColor;
    const fontFamily = state.editorReducer.fontFamily;

    return { buttonColor, fontFamily };
}

export default connect(mapStateToProps)(SummaryCard);