import React, { useEffect, useState, createRef } from 'react';
import styled from 'styled-components';
import zippyIcon from '../../../../assets/sidebar/Vector.svg';

//******************************************/

const Zipper = styled.div`
    display: block;
    border-bottom: solid 1px #F8F8F8;
    `

const Bar = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 60px;
    padding-left: 24px;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: none;
    width: 100%;
    font-size: 18px;
    color: #000000;
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif, "uhc_sans"; 
    font-weight: 700;
    `

const Animator = styled.div`
    transition: max-height 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s, min-height 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    overflow: hidden;
    height: 99999999px;
    `

const Sizer = styled.div`
    border-top: solid 1px transparent;
    border-bottom: solid 1px transparent;
    padding: 0px 24px;
    margin: -1px 0;    
    `

const ZippieIcon = styled.img`
    position: absolute;
    right: 25px;
    `

//******************************************/

const Zippy = ({ text, panel, open, onChange }) => {
    const [opened, setOpened] = useState(open);
    const [maxHeight, setMaxHeight] = useState(0);

    const contentsRef = createRef();

    open = (open !== undefined)
        ? open
        : opened

    const zippyClicked = () => {
        setOpened(!opened);
        if (onChange instanceof Function) onChange();
    }

    const checkSize = () => {
        if (!contentsRef.current) return;

        const contentHeight = contentsRef.current.clientHeight;

        if (maxHeight !== contentHeight)
            setMaxHeight(contentHeight);
    }

    useEffect(() => {
        checkSize();
    }, [open]);

    useEffect(() => {
        if (!MutationObserver) return;
        
        const observer = new MutationObserver(() => checkSize());
        observer.observe(contentsRef.current, { attributes: true, childList: true, subtree: true });

        return () => observer.disconnect();
    }, [contentsRef])

    return (
        <Zipper>
            <Bar
                onClick={zippyClicked}
            >
                {text}
                <ZippieIcon
                    src={zippyIcon}
                    style={open ? {} : { transform: 'rotate(180deg)' }}
                    alt='Tick icon' />
            </Bar>
            <Animator
                style={open ? { minHeight: maxHeight, maxHeight: maxHeight } : { minHeight: 0, maxHeight: 0 }}
            >
                <Sizer ref={contentsRef}>
                    {
                        React.createElement(panel, { onChange: setTimeout(() => checkSize()) })
                    }
                </Sizer>
            </Animator>
        </Zipper>
    )
}

export default Zippy;