import React from "react";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import AtLeastOneSVG from './../../../../assets/toolbar/error_outline.svg';

//************************************* */

const Shader = styled.div`
    top: 0px;
    position: fixed;
    bottom : 0px;
    background: black;
    opacity: 0.25;
    left: 0px;
    right: 0px;
    z-index: 1;
`

const PopUp = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 524px;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border-radius: 8px;
    z-index: 1;
`

const SVG= styled.div`
    position: relative;
    height: 40px;
    width: 40px;
    margin-bottom: 16px;
    z-index: 1;
    background-repeat: no-repeat;
    background-position: center; 
    background-color: white;
    background-image: url(${AtLeastOneSVG});
`

const Heading = styled.div`
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center; 
    justify-content: center;
    color: #000000;
    margin-bottom: 16px;
    position: relative;
`

const MarkDown= styled.div`
    height: 22px;   
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #505050;
    position: relative;
    text-align: center;
    margin-bottom: 60px;
`

const CloseButton = styled.button`
    padding: 14.5px 60px;
    height: 48px;
    width: 204px;
    cursor: pointer;
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #0C55B8;
    background: #FFFFFF;
    border: 1.5px solid #0C55B8;
    border-radius: 8px;

    &:hover{
        background: #0C55B8;
        color: #FFFFFF;
    }
`


//********************************* */

const PatientsPlanAtLeastOnePopUp = ( { setShowPopUp } ) =>{
    const {t} = useTranslation();
    return(
        <>
            <Shader onClick={ () => setShowPopUp(false) } />
            <PopUp>
                <SVG />
                <Heading> {t("PopUp.AtLeastOnePopUp.header")} </Heading>
                <MarkDown> {t("PopUp.AtLeastOnePopUp.markdown")} </MarkDown>
                <CloseButton onClick={ () => setShowPopUp(false) }> {t("PopUp.AtLeastOnePopUp.close")} </CloseButton>
            </PopUp>
        </>
    )
}

export default PatientsPlanAtLeastOnePopUp;