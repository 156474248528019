import React from "react";
import { connect } from 'react-redux';
import ProgressBar from  '../../ProgressBar'
import styled from 'styled-components';

//******************************************/

const Root = styled.div`
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0 2px 6px 0 hsl(0deg 0% 73% / 40%);
    font-family: ${props=>props.font};
    color: ${props=>props.color};
    `

const Header = styled.div`
    margin: auto;
    max-width: 780px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    `

const DivLeft = styled.div`
    height: 80px;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    flex: 1; 
    `

const DivCenter = styled.div`
    height: 80px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1; 
    `

const DivRight = styled.div`
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;    
    line-height: 8px;    
    flex: 1; 
    `

const Logo = styled.img`
    height: 65px;
    `

//******************************************/

const BotHeader = ({ header, logoImage, fontFamily, textColor, progressName }) => {
    return (
        <Root font={fontFamily} color={textColor}>
            {
                header === 'none' ||
                <Header>
                    <DivLeft></DivLeft>
                    <DivCenter>
                        {
                            logoImage &&
                            ['both', 'logo'].includes(header) &&
                            <Logo src={logoImage} alt='Selected Logo' />
                        }
                    </DivCenter>
                    <DivRight>
                        {
                            ['both', 'progressbar'].includes(header) && 
                            <ProgressBar progressName={progressName} />
                        }
                    </DivRight>
                </Header>
            }
        </Root>
    )
}

const mapStateToProps = (state) => {
    const header = state.editorReducer.header;
    const logoImage = state.editorReducer.logoData;
    const fontFamily = state.editorReducer.fontFamily;
    const textColor = state.editorReducer.textColor;

    return { header, logoImage, fontFamily, textColor };
}

export default connect(mapStateToProps)(BotHeader);
