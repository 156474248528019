import React, { useState, useEffect } from "react";
import Toolbar from '../sn-editor/sidebar/widgets/Toolbar';
import makeCard from './cards/CardMap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import mockdata from './mockdata';

//*****************************************/

const Page = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    min-width: 1300px;
    overflow-x: auto;    
`

const Workspace = styled.div`
    position: absolute;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 420px;
    background-color: #F8F8F8;
    min-width: 880px;
    padding: 20px;
`

const Content = styled.div`
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 20px;
    right: 20px;    
`

const Menu = styled.div`
    position: absolute;
    top: 60px;
    bottom: 0;
    right: 0px;
    width: 420px;
    min-width: 420px;    
`

const Row = styled.div`
    line-height: 32px;
    padding: 0 20px;
    cursor: pointer;
    // border-top: solid 1px transparent;
    // border-bottom: solid 1px transparent;

    &:hover {
        // border-top: solid 1px #EFEFEF;
        // border-bottom: solid 1px #EFEFEF;
        background: #EFEFEF;
    }
    `

const Label = styled.div`
    display: inline-block;
    width: 30px;    

    &:hover {
        // background: #EFEFEF;
    }
    `

const Data = styled.div`
    display: inline-block;    

    &:hover {
        // background: #EFEFEF;
    }
    `

const Input = styled.input`
    font-size: 18px;
    margin: 8px;
    `

const Select = styled.select`
    font-size: 18px;
    margin: 8px;
    `

const Notifications = styled.div`
    position: absolute;
    line-height: 32px;
    height: 32px;
    bottom: 0px;
    left: 0;
    right: 0;
    background-color: #EFEFEF;
    text-align: center;
    padding: 10px;
    border: solid 1px #CCCCCC;
    cursor: pointer;
    `

const Notification = styled.div`
    position: absolute;
    line-height: 32px;
    height: 32px;
    bottom: 64px;
    left: 24px;
    right: 24px;
    background-color: #FFEFEF;
    text-align: center;
    padding: 10px;
    border: solid 1px #FFCCCC;
    border-radius: 16px;
    
    `

//*****************************************/

const Dashboard = ({ cards }) => {
    const navigate = useNavigate();
    let [size, setSize] = useState({ width: 0, height: 0 });
    const [data, setData] = useState(mockdata.slice(Math.max(mockdata.length - 180, 1)));
    const [showNotification, setShowNotification] = useState(false);
    const [cardProps, setCardProps] = useState(
        Array.isArray(cards)
            ? cards
            :
            [
                { type: 'SESSIONS', title: 'Sessions', column: 0, row: 0, columns: 1, rows: 2, show: true },
                { type: 'COMPLETION', title: 'Trend', column: 1, row: 0, columns: 3, rows: 1, show: true },
                { type: 'RAWDATA', title: 'Raw Data', column: 0, row: 2, columns: 4, rows: 1, show: true },
                { type: 'NPS', title: 'NPS', column: 1, row: 1, columns: 2, rows: 1, show: true },
                { type: 'RECOMMENDATION', title: 'Triage Level Recommendations', column: 3, row: 1, columns: 1, rows: 1, show: true },

                // { type: 'HEALTH', title: 'Health', column: 3, row: 0, columns: 1, rows: 1, show: true },
                // { type: 'STATS2', title: 'Erik\'s Spreadsheet Data', column: 0, row: 1, columns: 2, rows: 1, show: true },
                // { type: 'CERTS', title: 'Certificates', column: 2, row: 2, columns: 2, rows: 1, show: true },
                // { type: 'CONFIG', title: 'Config', column: 2, row: 1, columns: 2, rows: 1, link: 'editor', show: true },
                // { type: 'STATS', title: 'Power BI Stats', column: 0, row: 2, columns: 2, rows: 1, link: 'usage', show: true },
            ]
    )

    const columns = 4;
    const rows = 3;

    const gotoLink = (card) => {
        if (card.link)
            navigate(`/${card.link}`)
    }

    const toggleCardVisibility = (index) => {
        let newCards = [...cardProps];

        newCards[index].show = !newCards[index].show;
        setCardProps(newCards);
    }

    const getSize = () => {
        let dashboard = document.getElementById('dashboard-content');

        return {
            width: dashboard?.clientWidth || 640,
            height: dashboard?.clientHeight || 480
        }
    }

    const updateData = (event) => {
        setData(mockdata.slice(Math.max(mockdata.length - event.target.value, 1)));
    }

    useEffect(() => {
        const newSize = getSize();
        if ((size.width != newSize.width) || (size.height != newSize.height))
            setSize(newSize);
    })

    useEffect(() => {
        let timeoutId = null;

        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setSize(getSize()), 100);
        };

        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, [])

    return (
        <Page>
            <Toolbar
                isDisabled={false}
                showpublish={false}
            />
            <Workspace >
                <Content id='dashboard-content'>
                    {
                        cardProps.map(props => makeCard({ ...props, columnWidth: (size.width / columns), rowHeight: (size.height / rows) }, data))
                    }
                </Content>
            </Workspace>
            <Menu>
                <br />
                {
                    cardProps.map((prop, i) => {
                        return (
                            <Row
                                key={prop.type}
                                onClick={() => toggleCardVisibility(i)}
                            >
                                <Label>
                                    <input
                                        type='checkbox'
                                        checked={prop.show ? 'checked' : ''}
                                        onChange={() => { }}
                                    />
                                </Label>
                                <Data onClick={() => { gotoLink(prop) }}>
                                    {prop.title}
                                </Data>
                            </Row>
                        )
                    })
                }
                <Row>
                    <hr />
                </Row>
                <Row>
                    <Select onChange={() => { }}>
                        <option>Last</option>
                        <option>This</option>
                        <option>Next</option>
                    </Select>
                    <Input type='number' min='1' max='10' value='1' onChange={() => { }} />
                    <Select
                        defaultValue='100'
                        onChange={(e) => updateData(e)}
                    >
                        <option value='100'>Days</option>
                        <option value='250'>Weeks</option>
                        <option value='500'>Months</option>
                        <option value='2200' >Years</option>
                    </Select>
                </Row>
                <Notifications onClick={() => setShowNotification(!showNotification)}>1 New Notification</Notifications>
                {
                    showNotification
                        ? <Notification>Your certificate is about to die.</Notification>
                        : null
                }
            </Menu>
        </Page >
    )
}

export default Dashboard;