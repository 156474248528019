
const getEditorTheme = (primaryColor) => {
    return {
        palette: {
            primary: {
                main: (primaryColor ? primaryColor : '#0C55B8')
            }
        },
        overrides: {
            MuiSwitch: {
                root: {
                    marginLeft: '-12px',
                    marginTop: '-2px',
                    height: '44px',
                    width: '60px',

                    '& .MuiIconButton-label': {
                        padding: '2px'
                    },

                    '& .MuiButtonBase-root.MuiIconButton-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary': {
                        top: '3px',
                        left: '3px'
                    },

                    '& .MuiButtonBase-root.MuiIconButton-root.MuiSwitch-switchBase.MuiSwitch-colorPrimary.Mui-checked': {
                        top: '3px',
                        left: '-1px'
                    },

                    '& .MuiSwitch-track': {
                        borderRadius: '27px',
                        opacity: '0.14'
                    },

                    '& .MuiSwitch-thumb': {
                        width: '16px',
                        height: '16px'
                    },

                    '& .MuiSwitch-colorPrimary.Mui-checked, .MuiRadio-colorPrimary.Mui-checked': {
                        color: '#FFFFFF !important'
                    },

                    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
                        opacity: 1
                    },

                    '& .MuiSwitch-colorPrimary.Mui-checked:hover': {
                        backgroundColor: 'rgb(12, 85, 184, .04)'
                    },

                    '& .MuiSwitch-switchBase': {
                        color: '#FFFFFF'
                    }
                },

            },

            MuiFormControlLabel: {
                label: {
                    fontSize: '16px',
                    fontFamily: '"Optum Sans", sans-serif, "uhc_sans"'
                }
            },

            MuiCheckbox: {
                root: {
                    padding: '8px',
                    color: '#000000'
                }
            },

            MuiInputLabel: {
                root: {
                    color: '#000000',
                    fontSize: '16px',
                    fontFamily: '"Optum Sans", sans-serif, "uhc_sans"'
                }
            },

            MuiFormLabel: {
                root: {
                    '&.Mui-focused': {
                        color: '#505050'
                    },
                },
            },

            MuiInput: {
                root: {
                    color: '#000000',
                    fontSize: '16px',
                    fontWeight: '400',
                    fontFamily: '"Optum Sans", sans-serif, "uhc_sans"'
                },
                underline: {
                    '&:before': {
                        borderBottom: 'solid 1px rgba(0, 0, 0, 0.75)'
                    },
                    '&:hover:not(.Mui-disabled):before': {
                        borderColor: '#0C55B8'
                    },
                }
            },
            MuiCircularProgress: {
                colorPrimary: {
                    color: '#002677'
                }
            }
        }
    }
}

export default getEditorTheme;