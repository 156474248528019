import React from "react";
import styled from 'styled-components';
import { connect } from 'react-redux';
import NoPreview from './NoPreview';
import { HeaderPreview, FavIconPreview, TextPreview, ColorsPreview, FontPreview, ButtonPreview, WelcomePreview } from './design';
import { NPSPreview, PatientNextStepPreview } from './features';
import { TermsPreview, PolicyPreview } from './content';

//******************************************/

const Root = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f8f8f8;
    `

const Border = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 24px;
    border: 1px solid #0C55B8;
    background-color: #F0F0F0;
    `

//******************************************/

const Preview = ({ editorReducer }) => {
    const previewMap = {
        none: <NoPreview />,
        None: <NoPreview />,
        Header: <HeaderPreview />,
        FavIcon: <FavIconPreview />,
        Text: <TextPreview />,
        Font: <FontPreview />,
        Colors: <ColorsPreview />,
        Button: <ButtonPreview />,
        Welcome: <WelcomePreview />,

        PatientNextStep: <PatientNextStepPreview />,

        NPS: <NPSPreview />,

        Terms: <TermsPreview />,
        Privacy: <PolicyPreview />
    }

    return (
        <Root id='workspace-preview'>
            {
                (editorReducer.context && editorReducer.context.toLowerCase() !== 'none')
                    ? <Border > {previewMap[editorReducer.context]}</Border>
                    : previewMap.None
            }
        </Root>
    )
}

const mapStateToProps = (state) => {
    const editorReducer = state.editorReducer;

    return { editorReducer };
}

export default connect(mapStateToProps)(Preview);