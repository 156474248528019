import React from "react";
import styled from 'styled-components';

/********************************************/

const Root = styled.div`
    margin: 16px 0px;
    cursor: pointer;

    &:hover > div > div:first-child  {
        border-color: #0C55B8;
    }
`

const RadButton = styled.div`
    position: relative;
    cursor: pointer;
    `

const Button = styled.div`
    display: inline-block;
    width: 16px;
    height: 16px;
    border: solid 2px #000000;
    border-radius: 50%;
    margin-right: 8px;
`

const Dot = styled.div`    
    position: absolute;
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #0C55B8;
`

const Label = styled.div`
    position: absolute;
    top: 0;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
`

const Description = styled.p`
    font-size: 16px;
    font-weight: 400;
    color: #505050; 
    margin-left: 28px;
`

/********************************************/


const RadioButton = (props) => {
    const { label, description, match, value, onClick, styles } = props;

    return (
        <Root onClick={onClick} style={styles}>
            <RadButton>
                <Button style={(value === match) ? { borderColor: '#0C55B8' } : {}}></Button>
                {
                    value === match &&
                    <Dot></Dot>
                }
                <Label>{label}</Label>
            </RadButton>

            <Description>{description}</Description>
        </Root>
    )
}

export default RadioButton;