import React, { useState } from "react";
import styled from 'styled-components';
import { connect } from 'react-redux';
import Card from './widgets/Card';
import ReactMarkdown from 'react-markdown';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from "react-i18next";
import { ReactComponent as WelcomeImage} from '../../../../assets/workspace/WelcomeIllustration.svg';

//******************************************/

const Header = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: -8px;
    `

const Logo = styled.svg`
    width: 120px;  
    height: 82px;

    svg path{
        fill: ${props=> props.color};
        stroke: none;
    }
    svg #Path1{
        stroke: ${props=> props.color};
        fill: none;
    }
`

const Body = styled.div`
    &.noimage p:first-child {
        margin-top: 8px;
    }

    & p:last-child {
        margin-bottom: 24px;
    }
    `

const CheckArea = styled.div`
    margin: 24px 0;
    display: flex;

    & span.MuiCheckbox-root {        
        width: 20px;
        height: 20px;
        padding: 0px;
        color: ${props => props.color}
    }

    & input.PrivateSwitchBase-input-7 {
        width: 22px;
        height: 22px;
    }
    `

const Label = styled.div`    
    margin-left: 8px;

    & p {
        margin: 0px;
        color: ${props => props.color};
    }
    `

//******************************************/

const WelcomeCard = ({ showWelcomeImage, buttonColor }) => {
    const [checkbox, setCheckbox] = useState(false);
    const { t } = useTranslation();

    return (
        <Card
            header={
                showWelcomeImage
                    ? <Header>
                        <Logo alt='symplenote logo' color={buttonColor} >
                            <WelcomeImage />
                        </Logo>
                    </Header>
                    : < ></>
            }

            body={
                <Body className={showWelcomeImage ? '' : 'noimage'}>
                    <ReactMarkdown>
                        {t("preview.welcomeCard.text")}
                    </ReactMarkdown>
                </Body>
            }

            footer={[(checkbox ? '' : '#') + t("preview.welcomeCard.next")]}
        >

            <CheckArea color={buttonColor}>
                <Checkbox
                    checked={checkbox}
                    onChange={() => {
                        setCheckbox(!checkbox)
                    }}
                />
                <Label color={buttonColor}>
                    <ReactMarkdown>
                        {t('preview.welcomeCard.checkboxtText')}
                    </ReactMarkdown>
                </Label>
            </CheckArea>

        </Card >
    )
}

const mapStateToProps = (state) => {
    const showWelcomeImage = state.editorReducer.showWelcomeImage;
    const buttonColor = state.editorReducer.buttonColor;

    return { showWelcomeImage, buttonColor };
}

export default connect(mapStateToProps)(WelcomeCard);