import React from "react";
import WelcomeCard from '../cards/WelcomeCard';
import styled from 'styled-components';
import BotHeader from "../cards/widgets/BotHeader";
import Bot from "../cards/widgets/Bot";
import DisclaimerCard from "../cards/DisclaimerCard";

//******************************************/

const Root = styled.div`
    `

//******************************************/

const FontPreview = () => {

    return (
        <Root>
            <Bot>
                <WelcomeCard />
                <DisclaimerCard />
            </Bot>
            <BotHeader progressName="welcome" />
        </Root>
    )
}

export default FontPreview;
