import React, { useState } from "react";
import styled from 'styled-components';

//******************************************/

const Bar = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: none;
    overflow-y: auto;
    `

const BarContents = styled.div`

    `

const Tabs = styled.div`
    position: relative;
    display: Flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    `

const Tab = styled.button`
    position: relative;
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin: 0px 12px;
    padding: 0px;
    padding-top: 22px;
    background-color: transparent;
    border: none; 
    border-bottom: solid 2px transparent;
    cursor: pointer;

    &.selected {
        border-bottom: solid 2px #0C55B8;
    }

    &.selected:hover,
    &:hover {
        border-bottom: solid 2px #002677;
    }
    `

const Panes = styled.div`
    position: relative;
    padding-top: 20px;
    `

const Pane = styled.div`
    position: absolute;
    width: 100%;
`

//******************************************/

const TabBar = ({ tabs, panes, defaultTab, tabChanged }) => {
    const [selectedTab, setSelectedTab] = useState(defaultTab || 0);

    tabs = Array.isArray(tabs)
        ? tabs
        : ['One', 'Two', 'Three']

    panes = Array.isArray(panes)
        ? panes
        : Array(tabs.length).fill().map((_, idx) => 'Pane ' + idx)

    const handleTabClick = (tab) => {
        setSelectedTab(tab);

        if(tabChanged instanceof Function)
            tabChanged(tab);
    }

    return (
        <Bar>
            <BarContents>
            <Tabs>
                {
                    tabs.map((tab, index) => {
                        return (
                            <Tab
                                key={tab+index}
                                className={selectedTab === index?'selected':''}
                                onClick={()=>handleTabClick(index)}
                            >
                                {tab}
                            </Tab>
                        );
                    })
                }
            </Tabs>
            <Panes>
                {
                    panes.map((pane, index) => {
                        return (
                            <Pane
                                key={'TabBarPane' + tabs[index]}
                                style={selectedTab === index ? { visibility: 'visible' } : { visibility: 'hidden' }}
                            >
                                {pane}
                            </Pane>
                        );
                    })
                }
            </Panes>
            </BarContents>
        </Bar>
    )
}

export default TabBar;