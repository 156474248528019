import React, { useEffect, useRef } from "react";
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Card from './widgets/Card';
import { useTranslation } from "react-i18next";

//******************************************/

const Progress = styled.div`
    display: flex;
    gap: 8px;
    padding: 0px;
    margin: 8px 0 16px 0;
    margin-bottom: 16px;
    line-height: 4px;
    height: 4px;
    `

const Bar = styled.div`
    background-color: ${props => props.color};
    padding: 0px;
    margin: 0px;
    width: 32px;
    height: 4px;
    border-radius: 10px;
    `

const Header = styled.div`
    color: ${props => props.color};

    & p {
        margin: 0px;
    }
    `

const Text = styled.div`
    margin-bottom: 24px;
    `

const TextField = styled.div`
    position: relative;
    height: 98px;
    margin-bottom: 24px;
    padding: 8px 16px;
    border: solid 1px rgba(0,0,0,.23);
    border-radius: 6px;
 
    & span {
        opacity: .5;
        color: ${props => props.color}
    }
    `

const Counter = styled.div`
    position: absolute;
    right: 16px;
    bottom: 8px;
    color: #5A5A5A;    
    opacity: .5;
    `

//******************************************/

const FeedbackCard = ({ buttonColor, textColor }) => {
    const { t } = useTranslation();

    const text = useRef(null);

    const footer = [t('preview.feedbackCard.back'), t('preview.feedbackCard.next')]

    useEffect(()=>{
        let html = text.current.innerHTML;

        text.current.innerHTML = html.replace('[=', '<u>').replace('=]', '</u>');
    })

    return (
        <Card footer={footer}>
            <Progress>
                <Bar color={buttonColor} />
                <Bar color={buttonColor} />
            </Progress>
            <Header color={buttonColor}>
                <ReactMarkdown>
                    {
                        t("preview.feedbackCard.header")
                    }
                </ReactMarkdown>
            </Header>
            <Text ref={text}>
                <ReactMarkdown>
                    {
                        t("preview.feedbackCard.text")
                    }
                </ReactMarkdown>
            </Text>
            <TextField color={textColor}>
                <span>
                    {
                        t("preview.feedbackCard.optional")
                    }
                </span>
                <Counter>
                    250
                </Counter>
            </TextField>
        </Card>
    )
}

const mapStateToProps = (state) => {
    const buttonColor = state.editorReducer.buttonColor;
    const textColor = state.editorReducer.textColor;

    return { buttonColor, textColor };
}

export default connect(mapStateToProps)(FeedbackCard);