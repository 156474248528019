import React from "react";
import styled from 'styled-components';
import BotHeader from "../cards/widgets/BotHeader";
import Bot from "../cards/widgets/Bot";
import MultiSelectCard from "../cards/MultiSelectCard";
import SummaryCard from "../cards/SummaryCard";

//******************************************/

const Root = styled.div`
    `

//******************************************/

const ButtonPreview = () => {

    return (
        <Root>
            <Bot>
                <MultiSelectCard />
                <SummaryCard />
            </Bot>
            <BotHeader />
        </Root>
    )
}

export default ButtonPreview;