import React, { useState } from "react";
import styled from 'styled-components';
import Zippy from './Zippy';

//******************************************/

const Root = styled.div`
    position: relative;
    top: 0;
    left: 0;    
    `

//******************************************/

const Menu = ({ tabs, onChange }) => {
    const [openZippy, setOpenZippy] = useState(-1);

    tabs = Array.isArray(tabs)
        ? tabs
        : []

    const handleTabChanged = (tab, index) => {
        setOpenZippy((openZippy === index) ? -1 : index);

        const name = ((openZippy === index) ? 'None' : tab)

        if (onChange instanceof Function)
            onChange(name);
    }

    return (
        <Root className='Menu' >
            {
                tabs.map((tab, index) => {
                    return (
                        <Zippy
                            key={tab.id + tab.text}
                            text={tab.text}
                            panel={tab.panel}
                            open={openZippy === index}
                            onChange={() => handleTabChanged(tab, index)}
                        />
                    );
                })
            }
        </Root>
    )
}

export default Menu;