
import React from 'react';
import styled from 'styled-components';
import SaveGIF from '../../../../../../assets/toolbar/Saving.gif';
import SaveSteps from '../../../../../../assets/toolbar/SaveSteps.svg';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setState } from '../../../../../../redux/actions/adminActions'

//********************************** */

const useStyles = makeStyles({
    Img: {
        width: '495px',
        height: '291px',
    }
});

//*************************** */

const PopUp = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 575px;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border-radius: 8px;
    z-index: 1;
    padding: 40px;
`

const Heading = styled.div`
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 24px;
    text-align: center;
    width: 495px;
    height: 22px;
`

const MarkDown = styled.div`
    width: 100%;
    left: 0px;
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    gap: 24px;
    margin-bottom: 40px;
`

const Frame = styled.div`
    width: 495px;
    height: 291px;
    margin-bottom: 16px;
`

const Steps = styled.div`
    background-image: url(${SaveSteps});
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
    width: 36.38px;
    height: 8px;
    flex: none;
    flex-grow: 0;
`

const SubmitButton = styled.button`
    padding: 14.5px 80px;
    width: 214px;
    height: 48px;
    color: #002677;
    background: #FFFFFF;
    border: 1.5px solid #002677;
    border-radius: 8px;
    cursor: pointer;
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 8px;

    &:hover{
        background: #002677;
        color: #FFFFFF;
    }
`

const SkipButton = styled.button`
    padding: 14.5px 88px;
    cursor: pointer;
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #505050;
    background: #FFFFFF;
    border: none;
`


//********************************* */


const TourSavingPopUp = ( { save, setSaveTourPopUp, setPublishTourPopUp, state, setState } ) => {

    const classes = useStyles();

    const {t} = useTranslation();

    const saveAdminConfig = () => {
        if(save instanceof Function) save();        
    }

    return(
        <>
            <PopUp>
                <Heading> {t("PopUp.TourSave.header")} </Heading>

                <MarkDown> 
                    Any changes you make will be automatically saved as you navigate.<br/><br/>
                    <span style={{fontWeight:'600'}}>Note:</span> You must publish your changes before leaving the website, or your changes will be lost. <br/><br/>
                    Each time you log in, the settings shown will match the settings that were last published to your development environment.
                </MarkDown>

                <Frame>
                    <img className={classes.Img} src={SaveGIF} alt='gif'/> 
                </Frame>

                <Steps/>

                <SubmitButton onClick={ ()=>{ setPublishTourPopUp(true); setSaveTourPopUp(false) } } > {t("PopUp.TourSave.next")} </SubmitButton>

                <SkipButton onClick={ ()=> { setState('showSympleNoteHelpAtStartup', false); saveAdminConfig() } }> {t("PopUp.TourSave.skip")} </SkipButton>

            </PopUp>

        </>

    )
}

const mapStateToProps = (state) => {
    state = state.adminReducer;

    return {
        state
    };
};

const mapDispatchToProps = {
    setState
};

export default connect(mapStateToProps, mapDispatchToProps)(TourSavingPopUp);