import React from "react";
import { connect } from 'react-redux';
import styled from 'styled-components';
import BotHeader from "../cards/widgets/BotHeader";
import Bot from "../cards/widgets/Bot";
import MultiSelectCard from "../cards/MultiSelectCard";
import SummaryCard from "../cards/SummaryCard";

//******************************************/

const Root = styled.div`
    `

//******************************************/

const ColorsPreview = ({ hasPlans, patientPlans }) => {

    patientPlans = Array.isArray(patientPlans)
        ? patientPlans
        : []

    const buttons = patientPlans
        .filter(plan => plan.isDisplayed)
        .map(plan => {
            return plan.displayText || '';
        })

    return (
        <Root>
            <Bot>
                <MultiSelectCard />
                <SummaryCard
                    hasPlans={hasPlans}
                    plans={buttons}
                />
            </Bot>
            <BotHeader />
        </Root>
    )
}

const mapStateToProps = (state) => {
    const hasPlans = state.editorReducer.hasPlans;
    const patientPlans = state.editorReducer.patientPlans;

    return { hasPlans, patientPlans };
}

export default connect(mapStateToProps)(ColorsPreview);