import React from "react";
import { connect } from 'react-redux';
import { setState } from "../../../../redux/actions/editorActions";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';

//******************************************/

const Root = styled.div`
    margin-bottom: 40px;
    `

const Description = styled.p`
    color: #505050;
    font-size: 16px;
    font-weight: 400;
    margin-top: 8px;
    margin-bottom: 24px;
    `

const Swatch = styled.div`
    background-color: ${props => props.backgroundColor};
    position: relative;
    display: inline-block;
    width: 55px;
    height: 55px;
    margin-right: 16px;
    cursor: pointer;

    &.selected div.indicator {
        background-color: #0C55B8;
    }

    &:hover div.indicator {
        background-color: #0C55B8;
    }
    `

const Selection = styled.div`
    position: absolute;
    bottom: -6px;
    width: 55px;
    height: 2px;
    background-color: transparent;   
    `

//******************************************/

const TextEditor = ({ setState, textColor }) => {
    const textColors = ['#5A5A5A', '#282A2E'];

    const { t } = useTranslation();

    const handleColorChange = (color) => {
        setState('textColor', color);
    }

    return (
        <Root>
            <Description>
                {t("sidebar.text.description")}
            </Description>
            {
                textColors.map((color, i) => {
                    return (
                        <Swatch
                            key={'textColor' + i}
                            className={(color === textColor) ? 'selected' : ''}
                            onClick={() => handleColorChange(textColors[i])}
                            backgroundColor={color} >
                            <Selection className='indicator' />
                        </Swatch>
                    )
                })
            }

        </Root>
    )
}
const mapStateToProps = (state) => {
    const textColor = state.editorReducer.textColor;

    return { textColor };
}

export default connect(mapStateToProps, { setState })(TextEditor);