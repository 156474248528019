import React from "react";
import styled from 'styled-components';
import boteditorlogo from '../../assets/BotEditorLogo.svg';

//******************************************/

const Article = styled.article`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    top: 60px;
    bottom: 45%;    
    width: 100%;
    `

const LogoBar = styled.div`
    padding: 16px;
    text-align: center;    
    `

const Greeting = styled.div`
    color: #282A2E;
    font-size: 26px;
    text-align: center;
    `

const Description = styled.div`
    color: #282A2E;
    font-size: 16px;
    font-family: "Roboto Condensed";
    font-weight: lighter;
    text-align: center;
    max-width: 650px;
    `

const Instruct = styled.div`
    color: #282A2E;
    font-size: 18px;
    text-align: center;

    `

//*****************************************/

const WelcomeArticle = ({ greeting, description, instruct }) => {
    return (
        <Article>
            <LogoBar>
                <img src={boteditorlogo} alt='Bot Editor logo' />
            </LogoBar>
            <Greeting>{greeting}</Greeting>
            <Description>{description}</Description>
            <Instruct>{instruct}</Instruct>
        </Article>
    );
}

export default WelcomeArticle;