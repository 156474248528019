import React from "react";
import Menu from '../widgets/Menu';
import FavIconEditor from './FaviconEditor';
import HeaderEditor from "./HeaderEditor";
import TextEditor from "./TextEditor";
import FontEditor from "./FontEditor";
import ColorsEditor from "./ColorsEditor";
import WelcomeEditor from './WelcomeEditor';
import { connect } from 'react-redux';
import { setState } from "../../../../redux/actions/editorActions";
import { useTranslation } from "react-i18next";

//******************************************/

const DesignMenu = ({ setState, onChange }) => {
    const { t } = useTranslation();

    const headerEditor = {
        name: 'Header',
        text: t("sidebar.widgets.menu.design.header"),
        panel: HeaderEditor
    }

    const faviconEditor = {
        name: 'FavIcon',
        text: t("sidebar.widgets.menu.design.favicon"),
        panel: FavIconEditor
    }

    const textEditor = {
        name: 'Text',
        text: t("sidebar.widgets.menu.design.text"),
        panel: TextEditor
    }

    const fontEditor = {
        name: 'Font',
        text: t("sidebar.widgets.menu.design.font"),
        panel: FontEditor
    }

    const colorsEditor = {
        name: 'Colors',
        text: t("sidebar.widgets.menu.design.color"),
        panel: ColorsEditor
    }

    const welcomeEditor = {
        name: 'Welcome',
        text: t("sidebar.widgets.menu.design.welcome"),
        panel: WelcomeEditor
    }

    const tabs = [
        headerEditor,
        faviconEditor,
        textEditor,
        fontEditor,
        colorsEditor,
        welcomeEditor
    ]

    const onContextChanged = (tab) => {
        setState('context', tab.name);

        if (onChange instanceof Function)
            onChange(tab.name);
    }

    return (
        <Menu
            tabs={tabs}
            onChange={onContextChanged}
        />
    )
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = {
    setState
};

export default connect(mapStateToProps, mapDispatchToProps)(DesignMenu);