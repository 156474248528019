import React, { useEffect } from "react";
import { connect } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import { setState } from "../../../../redux/actions/editorActions";

//******************************************/

const Editor = styled.div`
    margin-bottom: 32px;
    `

const Section = styled.div`
    margin-bottom: 40px;

    &.show {
        display: block;        
    }

    &.hide {
        display: none;
    }
    `

const Header = styled.div`
    margin: 8px 0;
    font-size: 16px;
    font-weight: 700;
    color: #000000;
    `

const Description = styled.p`
    font-size: 16px;
    font-weight: 400;
    margin-top: 8px; 
    color: #505050;   
    `

const OnOff = styled.span`
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    `

//******************************************/

const NPSEditor = ({ setState, hasNPS, hasFeedback }) => {
    const { t } = useTranslation();

    useEffect(()=>{
        if(!hasNPS){
            setState('hasFeedback', false)
        }
    }, [hasNPS]);

    return (
        <Editor>
            <Section>
                <Header>
                    {t("sidebar.nps.netPromotorScore")}
                </Header>
                <Switch
                    className='toggle'
                    checked={hasNPS}
                    onChange={(e) => setState('hasNPS', e.target.checked)}
                    color="primary"
                    label="On" />
                <OnOff>
                    {
                        hasNPS ? t("sidebar.widgets.switch.on") : t("sidebar.widgets.switch.off")
                    }
                </OnOff>
                <Description>
                    {t("sidebar.nps.description1")}
                </Description>
            </Section>

            <Section className={hasNPS ? 'show' : 'hide'}>
                <Header>
                    {t("sidebar.nps.feedback")}
                </Header>
                <Switch
                    className='toggle'
                    checked={hasFeedback}
                    onChange={(e) => setState('hasFeedback', e.target.checked)}
                    color="primary"
                    label="On" />
                <OnOff>
                    {
                        hasFeedback ? t("sidebar.widgets.switch.on") : t("sidebar.widgets.switch.off")
                    }
                </OnOff>
                <Description>
                    {t("sidebar.nps.description2")}
                </Description>
            </Section>
        </Editor>
    )
}

const mapStateToProps = (state) => {
    const hasNPS = state.editorReducer.hasNPS;
    const hasFeedback = state.editorReducer.hasFeedback;

    return { hasNPS, hasFeedback };
}

export default connect(mapStateToProps, { setState })(NPSEditor);