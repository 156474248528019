import React from 'react';
import styled from 'styled-components';
import SuccessIcon from '../../../assets/Support/SuccessIcon.svg';
import Close from '../../../assets/Support/Close.svg';
import { useTranslation } from "react-i18next";

//************************** */

const Guide = styled.div`
    display: flex;
    align-items: flex-start;
    padding: 24px 16px;
    position: fixed;
    background: #EFF6EF;
    mix-blend-mode: normal;
    border: 1px solid #007000;
    box-shadow: 0px 2px 6px rgba(25, 25, 26, 0.16);
    border-radius: 8px;
    z-index: 1;
    top: 62px;
    left: calc(50% - 269px );
    transition: ease-out 0.3s;
`

const Markdown = styled.div`
    height: 40px;
    width: 442px;
    font-family: "Optum Sans", "Helvetica", "Arial", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
`

const SuccessSVG = styled.img`
    margin-right: 8px;
`

const CloseSVG = styled.img`
    cursor: pointer;
`

//************************** */
const ThankYou = ({ setShowThankCard }) => {

    setTimeout(()=>{
        setShowThankCard(false);
    }, [5000]);

    const { t } = useTranslation();

    return (
        <>
            <Guide> 
                <SuccessSVG src={SuccessIcon} />
                <Markdown> {t("Support.ThankYou.Markdown")} </Markdown>
                <CloseSVG src={Close} onClick={() => setShowThankCard(false) } />
            </Guide>
        </>
    )
}


export default ThankYou;