import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

//******************************** */

const Page= styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
`

const Heading = styled.div`
    width: 264px;
    height: 32px;
    font-family: "Optum Sans", "Helvetica", "Arial", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #000000;
    padding: 40px 24px 24px 84px;
    cursor: pointer;
`

const Links = styled.div`
    display: flex;
    width: 264px;
    padding-left: 84px;
`

const Line = styled.div`
    width: 4px;
    height: 48px;
    background: #0C55B8;
    border-radius: 2px;
    align-self: stretch;
    flex-grow: 0;
    margin-right: 12px;
`


const Markdown = styled.div`
    width: 236px;
    height: 26px;
    font-family: "Optum Sans", "Helvetica", "Arial", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 1;
    padding: 11px 0px 11px 16px;
    cursor: pointer;

    &:hover{
        color: #0C55B8;
        text-decoration-line: underline;
    }
`

const MarkdownSelected = styled.div`
    width: 236px;
    height: 48px;
    display: flex;
    align-items: center;
    color: #000000;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
    font-family: "Optum Sans", "Helvetica", "Arial", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
`


//******************************** */

const SideBar = ({ showRef, showFAQ, showRelease, showContact, showChange, setShowRef, setShowFAQ, setShowChange, setShowRelease, setShowContact, setShowSupportCenter }) => {

    const handleClick = (show) =>{

        setShowChange(false);
        setShowContact(false);
        setShowFAQ(false);
        setShowRef(false);
        setShowRelease(false);

        if(show=== 'Ref') setShowRef(true);
        if(show === 'Contact') setShowContact(true);
        if(show === 'Release') setShowRelease(true);
        if(show === 'FAQ') setShowFAQ(true);
        if(show === 'Change') setShowChange(true);
    }

    const { t } = useTranslation();

    return(
        <Page>
            <Heading onClick={()=> {setShowSupportCenter(false); handleClick('') }}>Support center</Heading>

            <Links>
                {
                    showRef ? 
                    <>
                        <Line /> 
                        <MarkdownSelected>{t("Support.sidebar.mark1")}</MarkdownSelected>
                    </>
                    :
                        <Markdown onClick={()=>handleClick('Ref')} > {t("Support.sidebar.mark1")} </Markdown>
                }
            </Links>

            <Links>
                {
                    showFAQ ? 
                    <>
                        <Line /> 
                        <MarkdownSelected>{t("Support.sidebar.mark2")}</MarkdownSelected>
                    </>
                    :
                        <Markdown onClick={()=>handleClick('FAQ')}>{t("Support.sidebar.mark2")}</Markdown>
                }
            </Links>

            <Links>
                {
                    showChange ? 
                    <>
                        <Line /> 
                        <MarkdownSelected>{t("Support.sidebar.mark3")}</MarkdownSelected>
                    </>
                    :
                        <Markdown onClick={()=>handleClick('Change')}>{t("Support.sidebar.mark3")}</Markdown>
                }
            </Links>

            <Links>
                {
                    showRelease ? 
                    <>
                        <Line /> 
                        <MarkdownSelected>{t("Support.sidebar.mark4")}</MarkdownSelected>
                    </>
                    :
                        <Markdown onClick={()=>handleClick('Release')}>{t("Support.sidebar.mark4")}</Markdown>
                }
            </Links>

            <Links>
                {
                    showContact ? 
                    <>
                        <Line /> 
                        <MarkdownSelected>{t("Support.sidebar.mark5")}</MarkdownSelected>
                    </>
                    :
                        <Markdown onClick={()=>handleClick('Contact') }>{t("Support.sidebar.mark5")}</Markdown>
                }
            </Links>

        </Page>
    )
}

export default SideBar;