import {
    PROPERTY_CHANGED,
    REGISTER_SAVE_STATE,
    LOGIN_STATE,
    USERS_LOAD_STATE,
    USER_DELETE_STATE,
    PROFILE_LOAD_STATE
} from "../types";

const INITIAL_STATE = {

    appIdList: [],
    appIdValue: '',
    lastappId: '',

    login: {
        email: "",
        password: "",

        emailErrMsg: "",
        passwordErrMsg: "",
    },

    register: {
        username: "",
        email: "",
        password: "",
        confirmPw: "",
        rememberMe: false,

        usernameErrMsg: "",
        emailErrMsg: "",
        passwordErrMsg: "",
        confirmPwErrMsg: "",
    },

    registerSaveState: "",
    loginState: "",
    token: "",

    userLoadState: "",
    userDeleteState: "",

    profileLoadState: "",

    users: []
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PROPERTY_CHANGED:
            return { ...state, [action.payload.property]: action.payload.value };

        case REGISTER_SAVE_STATE:
            return { ...state, registerSaveState: action.payload };

        case LOGIN_STATE:
            return { ...state, loginState: action.payload.state, token: action.payload.value };

        case USERS_LOAD_STATE:
            return { ...state, userLoadState: action.payload.state, users: action.payload.value };

        case USER_DELETE_STATE:
            return { ...state, userDeleteState: action.payload };

        case PROFILE_LOAD_STATE:
            console.log("PROFILE: ", action.payload.value);

            const values = action.payload.value
                ? action.payload.value
                : { appIdList: [] };

            const newState = {
                profileLoadState: action.payload.state,
                appIdList: values.appId || [],
                appIdValue: values.appId?.["0"].id || ''
            };

            return { ...state, ...newState };

        default:
            return state;
    }
};

export default reducer;