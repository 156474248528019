import React, {useState} from "react";
import { connect } from 'react-redux';
import { setState } from '../../../../redux/actions/editorActions'
import { makeStyles } from '@material-ui/core/styles';
import ImageLoader from "../widgets/ImageSelector";
import { DEFAULT_FAVICON_NAME, DEFAULT_FAVICON_DATA } from "../../../../redux/types";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import errorIcon from '../../../../assets/toolbar/error_header.svg';

const useStyles = makeStyles({
    root: {
        marginBottom: '40px'
    },
    headBar2: {
        color: '#000000',
        fontSize: 16,
        margin: '0px 0px 8px 0px'
    },
    textBar: {
        color: '#505050',
        fontSize: 16,
        margin: '8px 0'
    }
})


const ErrorBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    gap: 8px;
    position: relative;
    width: 291px;
    height: 27px;
    background: #FCF0F0;
    border-radius: 4px;
    margin-bottom: 40px;
`

const ErrorIcon = styled.div`
    position: relative;
    width: 18px;
    height: 18px;
    background-image: url(${errorIcon});
    background-repeat: no-repeat;
    background-position: center;   
    `

const Text = styled.div`
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #C40000;
    line-height: 19px;
`

const FavIconEditor = ({ faviconName, faviconData, setState }) => {
    const classes = useStyles();

    const { t } = useTranslation();

    const [limitExceedError, setLimitExceedError] = useState(false);

    const handleImageChanged = (image) => {
        const name = image ? image.name : '';
        const data = image ? image.data : null;

        setState('faviconName', name || DEFAULT_FAVICON_NAME);
        setState('faviconData', data || DEFAULT_FAVICON_DATA);
    }

    return (
        <div className={classes.root}>
            <div className={classes.textBar}>
                <p>
                    {t("sidebar.favIcon.description1")}
                </p>
                <p style={{marginTop: "16px"}}>
                    {t("sidebar.favIcon.description2")}
                </p>
            </div>
            <ImageLoader
                onImageChanged={handleImageChanged}
                accept="image/png, image/x-icon"
                styles1={{ marginTop: '24px', marginBottom: '40px' }}
                styles2={{ marginTop: '24px', marginBottom : "4px", border: "1px solid #C40000", borderRadius: "4px" }}
                limitExceedError={limitExceedError}
                setLimitExceedError={setLimitExceedError}
                image={{ name: faviconName, data: faviconData }}
                disabled={false}
            />
            {
                (limitExceedError)
                ?
                    <ErrorBox>
                        <ErrorIcon/>
                        <Text>File size exceeded (max size: 2MB)</Text>
                    </ErrorBox>
                :
                    <> </>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    const faviconName = state.editorReducer.faviconName;
    const faviconData = state.editorReducer.faviconData;

    return { faviconName, faviconData };
}

export default connect(mapStateToProps, { setState })(FavIconEditor);