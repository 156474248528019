import React from "react";
import DashCard from './DashCard';
import certimage from '../../../assets/dashboard/certificates.png';
// import styled from 'styled-components';

//*****************************************/


//*****************************************/

const CertsCard = ({properties}) => {
    return (
        <DashCard properties={properties}>
            <img src={certimage} />
        </DashCard>
    )
}

export default CertsCard;