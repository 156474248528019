import React from "react";
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Button from '../widgets/Button';

//******************************************/

const Root = styled.div`
    color: ${props => props.textColor};
    position: relative;
    margin: 30px 0;
    padding: 0px 0;
    border-bottom: 1px solid transparent;
    box-shadow: 0px 1px 4px 1px rgba(224, 224, 224, 0.5);
    
    &[disabled] {
        opacity: .5;
    }
    `

const Trim = styled.div`
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: ${props => props.color};
    `

const Content = styled.div`
    padding: 0px 40px;
    `

const Header = styled.div`
    &.underlined {
        border-bottom: 1px solid #e0e0e0;
    }
    `

const Body = styled.div`
    padding: 1em 0;

    & p {
        margin: 1em 0;
    }

    & a, a:link, a:visited, a:hover  {
        color: ${props => props.linkcolor};
        pointer-events: none
    }

    & a:active {
        color: ${props => props.linkcolor};
        pointer-events: none
    }
    `

const Footer = styled.div`    
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    margin-bottom: 24px;
    `

//******************************************/

const Card = ({ children, header, body, footer, trimColor, textColor, buttonColor, disabled }) => {
    header = (typeof header === 'string' || header instanceof String)
        ? <ReactMarkdown>{header}</ReactMarkdown>
        : header

    body = (typeof body === 'string' || body instanceof String)
        ? <ReactMarkdown>{body}</ReactMarkdown>
        : body

    footer = (Array.isArray(footer))
        ? footer.map((text, i) => {            
            return (typeof text === 'string' || text instanceof String)
                ? <Button key={text+i} text={text}  />
                : text;
        })
        : footer

    const headerClass = (typeof header === 'string' || header instanceof String)
        ? 'underlined'
        : 'plain'

    return (
        <Root textColor={textColor} disabled={disabled}>
            <Trim color={trimColor}>
            </Trim>
            <Content>
                <Header className={headerClass}>
                    {header}
                </Header>
                <Body linkcolor={buttonColor}>
                    {body}
                    {children}
                </Body>
                <Footer>{footer}</Footer>
            </Content>
        </Root>
    )
}

const mapStateToProps = (state) => {
    const textColor = state.editorReducer.textColor;
    const trimColor = state.editorReducer.trimColor;
    const buttonColor = state.editorReducer.buttonColor;

    return { textColor, trimColor, buttonColor };
}

export default connect(mapStateToProps)(Card);