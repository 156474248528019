import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { setState } from "../../../../redux/actions/editorActions";
import PatientsPlanAtLeastOnePopUp from '../../sidebar/popUp/PatientsPlanAtLeastOnePopUp';
import { useNavigate } from 'react-router-dom';

//******************************************/

const Root = styled.div`
    margin-bottom: 40px;
    `

const Text = styled.div`
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 2px;
    margin: 8px 0px;
`

const Description = styled.p`
    color: #505050;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 40px;
    margin-top: 8px;
    `

const OnOffCare = styled.span`
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    `
const OnOffPlans = styled.span`
    font-size: 16px;
    font-weight: 400;
    color: #000000;
`

const Plans = styled.div`
    &.shoe {
        display: block;
    }
    &.hide {
        display: none;
    }
    `

//******************************************/


const PatientNextStep = ({ setState, hasPlans, patientPlans, onChange, hasAlternatives, careAlternatives }) => {
    const { t } = useTranslation();

    const [showPopUp, setShowPopUp] = useState(false);
    const [showOne, setShowOne] = useState(false);
    const [careState, setCareState] = useState('');

    const navigate = useNavigate();

    const handlePopUp = () => {
        navigate('/support');
    }

    const handlePlanOnOffChanged = (e) => {
        setState('hasPlans', e.target.checked);
        
        if(onChange instanceof Function)
            onChange();
    }

    const handleCareOnOffChanged = (e) => {
        setState('hasAlternatives', e.target.checked);
        
        if(onChange instanceof Function)
            onChange();
    }


    var count=0;

    useEffect(() => {
        patientPlans.map(plan=>{
            return (plan.isDisplayed===true ? ( count+=1, setCareState(plan.careType) ) : 0);
        })
        if(count===1) setShowOne(true);
    }, [patientPlans]);

    const handlePlanOne = (type)=>{
        if(type === careState){
            setShowPopUp(true);
            return 1;
        }
        else{
            setShowOne(false);
            return 0;
        }
    }

    const handlePlanChanged = (e, type) => {
        if(showOne){
            const val = handlePlanOne(type);
            if(val === 0){
                setCareState(type);

                const newPlans1 = patientPlans.map(plan => {
                    return (plan.careType === type)
                        ? { ...plan, ...{ isDisplayed: e.target.checked } }
                        : { ...plan }
                })

                const newPlans2 = careAlternatives.map(plan => {
                    return (plan.careType === type)
                        ? { ...plan, ...{ isDisplayed: e.target.checked } }
                        : { ...plan }
                })
                setState('patientPlans', newPlans1);
                setState('careAlternatives', newPlans2);
                if (onChange instanceof Function)
                    onChange();
            }
        }
        else{    
            const newPlans1 = patientPlans.map(plan => {
                return (plan.careType === type)
                    ? { ...plan, ...{ isDisplayed: e.target.checked } }
                    : { ...plan }
            })

            const newPlans2 = careAlternatives.map(plan => {
                return (plan.careType === type)
                    ? { ...plan, ...{ isDisplayed: e.target.checked } }
                    : { ...plan }
            })
            setState('patientPlans', newPlans1);
            setState('careAlternatives', newPlans2);
            if (onChange instanceof Function)
                onChange();
        }
    }

    return (
        <Root>
            <Text>Care Alternatives</Text>
            <Switch
                checked={hasAlternatives}
                onChange={handleCareOnOffChanged}
                color="primary"
                label="On"
                className='toggle'
            />
            <OnOffCare>
                {
                    hasAlternatives ? t("sidebar.widgets.switch.on") : t("sidebar.widgets.switch.off")
                }
            </OnOffCare>
            <Description>
                Include a question that asks users what they plan to do to get help for their symptoms.
            </Description>

            <Text>Patient Plans</Text>
            <Switch
                checked={hasPlans}
                onChange={handlePlanOnOffChanged}
                color="primary"
                label="On"
                className='toggle'
            />
            <OnOffPlans>
                {
                    hasPlans ? t("sidebar.widgets.switch.on") : t("sidebar.widgets.switch.off")
                }
            </OnOffPlans>
            <Description>
                Once users get their results, ask them a question to learn what they plan to do next to treat their symptoms. If this question is turned off, a thank you message will appear on the summary card.
            </Description>

            <Text> Care Categories </Text>
            <Description style={{ marginBottom : '23px' }}>
                Select which care categories to display for the care alternatives and patient plans questions. If you'd like to customize the categories further please
                <span onClick={()=>handlePopUp()} style={{color: "#0C55B8", cursor: 'pointer'}}>
                    {t("sidebar.plans.contactUs")} 
                </span>
            </Description>

            <Plans className={(hasPlans || hasAlternatives) ? 'show' : 'hide'}>
                {
                    patientPlans
                        .map(plan => {
                            return (
                                <div key={plan.careType}>
                                    <FormControlLabel 
                                        control={
                                            <Checkbox
                                                color='primary'
                                                checked={plan.isDisplayed}
                                                onChange={ (e) => handlePlanChanged(e, plan.careType) }
                                            />
                                            } 
                                        label={t(`sidebar.plans.${plan.careType}`)} 
                                    />
                                </div>
                            )
                        })
                }
            </Plans>
            {
                showPopUp 
                ?
                    <PatientsPlanAtLeastOnePopUp setShowPopUp={setShowPopUp} />
                :
                    <></>
            }
        </Root >
    )
}

const mapStateToProps = (state) => {
    const hasPlans = state.editorReducer.hasPlans;
    const patientPlans = state.editorReducer.patientPlans;
    const hasAlternatives = state.editorReducer.hasAlternatives;
    const careAlternatives = state.editorReducer.careAlternatives;

    return { hasPlans, patientPlans, hasAlternatives, careAlternatives };
}
export default connect(mapStateToProps, { setState })(PatientNextStep);