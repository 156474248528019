import React, { useEffect, useRef } from 'react';
import * as ProgressBarSVG from "../../../assets/Progressbar";
import styled from 'styled-components';

//******************************************/

const Root = styled.div`
    padding-bottom: 10px;    
    `

const Label = styled.div`
    width: 100%;
    line-height: 14px;
    font-size: 14px;
    font-weight: 400;
    `

//******************************************/

const ProgressBar = ({ progressName, size = 150 }) => {
    const progressMap = {
        start: { completed: .01, display: 'Warming up' },
        welcome: { completed: .05, display: 'Welcome' },
        disclaimer: { completed: .10, display: 'Terms' },
        emergency: { completed: .15, display: 'Emergency' },
        symptoms: { completed: .20, display: 'Symptoms' },
        care_plan: { completed: .25, display: 'Plans for care' },
        red_flag: { completed: .30, display: 'Pre-Existing conditions' },
        underlying_conditions: { completed: .40, display: 'Possible flags' },
        related_symptoms: { completed: .50, display: 'Related symptoms' },
        interview: { completed: .60, display: 'Interview' },
        freetext: { completed: .70, display: 'Comments' },
        triage_complete: { completed: .75, display: 'Plan for treatment' },
        treatmentplan: { completed: 0.75, display: 'Plan for treatment' },
        scheduleappointment: { completed: .80, display: 'Scheduling' },
        provider: { completed: .85, display: 'Choose provider' },
        nps: { completed: .90, display: 'Rate us' },
        comment: { completed: .95, display: 'Rate us' },
        thankyou: { completed: 1, display: 'Thank you' },
        'triage_complete-lifethreatening': { completed: 1, display: 'Thank you' }
    }

    progressName = progressName || "";
    
    const progressData = useRef();
    if (!progressName && !progressData.current)
        progressData.current = progressMap['welcome'];

    if (progressMap[progressName.toLowerCase()])
        progressData.current = progressMap[progressName.toLowerCase()];

    const barLength = size * progressData.current.completed;
    const done = progressData.current.completed === 1;

    useEffect(() => {
        if (!done) {
            document.getElementById("ProgressBarMeter").setAttribute("d", `M0 2C0 0.895431 0.895431 0 2 0H${barLength}V4H2C0.895431 4 0 3.10457 0 2V2Z`)
            document.getElementById("ProgressBarTitle").innerHTML = (progressData.current.completed * 100) + '% Completed';
        }
    })

    return (
        <Root>
            <Label className='label' style={{ width: size }}>
                {progressData.current.display}
            </Label>
            {
                done
                    ? <ProgressBarSVG.progress100 style={{ width: size }} />
                    : <ProgressBarSVG.progress1 style={{ width: size }} />
            }
        </Root>
    );
}

export default ProgressBar;