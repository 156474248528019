import React from "react";
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Card from './widgets/Card';
import Button from './widgets/Button';
import { useTranslation } from "react-i18next";

//******************************************/

const Progress = styled.div`
    display: flex;
    gap: 8px;
    padding: 0px;
    margin: 8px 0 16px 0;
    line-height: 4px;
    height: 4px;
    `

const Bar = styled.div`
    background-color: ${props => props.color};
    padding: 0px;
    margin: 0px;
    width: 32px;
    height: 4px;
    border-radius: 10px;

    &:last-child {
        opacity: .25;
    }
    `

const Header = styled.div`
    color: ${props => props.color};

    & p {
        margin: 8px 0px 0px 0px;
    }
    `

const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 24px 0 8px 0;

    & button {
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0;
        border: solid 2px ${props => props.color};
        color: ${props => props.color};
        font-weight: 600;

        &:hover {                    
            border: solid 2px ${props => props.color};
        }
    }
    `

const Likely = styled.div`
    display: flex;
    justify-content: space-between;
    color: ${props => props.color};
    font-size: 14px;
    margin-bottom: -16px;
    `

//******************************************/

const NPSCard = ({ buttonColor, textColor, hasFeedback }) => {
    const { t } = useTranslation();

    return (
        <Card>
            {
            hasFeedback 
            ? 
                <Progress>
                    <Bar color={buttonColor} />
                    <Bar color={buttonColor} />
                </Progress>
            :
                <></>
            }
            <Header color={buttonColor}>
                <ReactMarkdown>
                    {
                        t("preview.NPSCard.header")
                    }
                </ReactMarkdown>
            </Header>
            <Buttons>
                {
                    [...Array(11)].map((item, i) => <Button key={'numb'+i} text={"" + i} />)
                }
            </Buttons>
            <Likely color={buttonColor}>
                <div>
                    {
                        t("preview.NPSCard.not")
                    }
                </div>
                <div>
                    {
                        t("preview.NPSCard.very")
                    }
                </div>
            </Likely>
        </Card>
    )
}

const mapStateToProps = (state) => {
    const buttonColor = state.editorReducer.buttonColor;
    const textColor = state.editorReducer.textColor;
    const hasFeedback = state.editorReducer.hasFeedback;

    return { buttonColor, textColor, hasFeedback};
}

export default connect(mapStateToProps)(NPSCard);