import {
    PROPERTY_CHANGED,
    ADMIN_DATA_LOAD_STATE_SUCCESS,
    ADMIN_DATA_LOAD_STATE_FAIL,
    ADMIN_DATA_SAVE_STATE_SUCCESS,
    ADMIN_DATA_SAVE_STATE_FAIL
 } from '../types';


const INITIAL_STATE = {
    showSympleNoteHelpAtStartup: false
}

const reducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case PROPERTY_CHANGED:
            return { ...state, [action.payload.property]: action.payload.value }

        case ADMIN_DATA_LOAD_STATE_SUCCESS:
            console.log("Admin data to load:", action.payload)

            return { ...state, ...action.payload }

        case ADMIN_DATA_LOAD_STATE_FAIL:
            console.log("Admin data failed to load", action.payload)
            return state;

        case ADMIN_DATA_SAVE_STATE_SUCCESS:
            console.log("Admin data to saved:", action.payload)

            return { ...state, ...action.payload}

        case ADMIN_DATA_SAVE_STATE_FAIL:
            console.log("Admin data failed to save.", action.payload)
            return state

        default:
            return state;
    }
}


export default reducer;
