import React from "react";
import { connect } from 'react-redux';
import styled from 'styled-components';

//******************************************/

const Root = styled.div`
    display: inline-block;
    color: ${props=>props.color};
    font-family: ${props=>props.font};
    margin-top: 30px;
    padding: 10px 16px;
    background-color: #F1F2F2;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    `

//******************************************/

const TextBubble = ({ fontFamily, textColor, children }) => {
    return (
        <Root font={fontFamily} color={textColor}>
            {children}
        </Root>
    )
}

const mapStateToProps = (state) => {
    const fontFamily = state.editorReducer.fontFamily;
    const textColor = state.editorReducer.textColor;
      
    return { fontFamily, textColor };
}

export default connect(mapStateToProps)(TextBubble);
