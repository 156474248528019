import React from "react";
import DashCard from './DashCard';
import { VictoryPie } from "victory";
import styled from 'styled-components';

//*****************************************/

const Page = styled.div`
    position: absolute;
    top: 24px;
    bottom: 0px;
    left: 0;
    right: 0;
    overflow: auto;    
`

const Row = styled.div`
    position: relative;
    font-size: 18px;
    margin: 10px 0;
    height: ${prop=>prop.height}
    `

const PRow = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: -10px 0; 
    padding: 0;
    height: ${prop=>prop.height}
    `

const Label = styled.div`
    font-weight: bold;
    width: 100%;
    text-align: center;
    `

const Data = styled.div`
    font-weight: 400;
    width: 100%;
    text-align: center;
    line-height: 1.5em;
    `

//*****************************************/

const Sessions = ({ properties, data }) => {
    data = Array.isArray(data)
        ? data
        : []

    const sessions = data.length;

    const completed = data.reduce((total, data, i) => {
        return total + ((data?.isTriageComplete?.toLowerCase() === 'yes') ? 1 : 0);
    }, 0)

    const percent = parseInt((completed / sessions) * 100);

    const totalTime = data.reduce((total, data, i) => {
        const start = new Date(data.startTime);
        const end = new Date(data.endTime);

        return total + (end - start);
    }, 0)

    const avgTime = new Date(totalTime / sessions);
    const avgSeconds = avgTime.getSeconds();

    return (
        <DashCard properties={properties}>
            <Page>
                <Row>
                    <Label>Sessions:</Label>
                    <Data>{sessions}</Data>
                </Row>

                <Row>
                    <Label>Completed:</Label>
                    <Data>{completed}</Data>
                </Row>

                <Row height={'220px'}>
                    <PRow height={'250px'}>
                        {/* <Label>Completed</Label> */}
                        <Label>{percent}%</Label>
                    </PRow>
                    <PRow height={'250px'}>
                        <VictoryPie
                            padAngle={0}
                            // used to hide labels
                            // labelComponent={<span />}
                            innerRadius={100}
                            data={[{ key: 'complete', y: percent, label: ' ' },
                            { key: 'rest', y: (100 - percent), label: ' ' }]}
                            colorScale={['Orange', "#F8F8F8"]}
                        />
                    </PRow>
                </Row>

                <Row>
                    <Label>Avg Time:</Label>
                    <Data>{avgTime.getMinutes()}:{((avgSeconds < 10) ? '0' : '') + avgSeconds}</Data>
                </Row>
            </Page>
        </DashCard >
    )
}

export default Sessions;