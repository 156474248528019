import React from "react";
import Menu from '../widgets/Menu';
import TermsEditor from "./TermsEditor";
import PolicyEditor from "./PolicyEditor";
import { connect } from 'react-redux';
import { setState } from "../../../../redux/actions/editorActions";
import { useTranslation } from "react-i18next";

//******************************************/

const ContentMenu = ({ setState, onChange }) => {
    const { t } = useTranslation();
    
    const termsEditor = {
        name: 'Terms',
        text: t("sidebar.widgets.menu.content.terms"),
        panel: TermsEditor
    }

    const privacyEditor = {
        name: 'Privacy',
        text: t("sidebar.widgets.menu.content.privacy"),
        panel: PolicyEditor
    }

    const tabs = [
        termsEditor,
        privacyEditor
    ]

    const onContextChanged = (tab) => {
        setState('context', tab.name);

        if(onChange instanceof Function)
            onChange(tab.name);
    }

    return (
        <Menu
            tabs={tabs}
            onChange={onContextChanged}
        />
    )
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = {
    setState
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentMenu);