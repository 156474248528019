import React from "react";
import styled from 'styled-components';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

//********************************************* */


const Guide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 32px 24px 32px;
    background: #FBF9F4;
    border-radius: 8px;
    margin-bottom: 32px;
`

const Heading = styled.div`
    font-family: "Optum Sans", "Helvetica", "Arial", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 16px;
    width: 652px;
`

const Markdown = styled.div`
    font-family: "Optum Sans", "Helvetica", "Arial", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #4B4D4F;
    width: 652px;
`

const Detail = styled.div`
    padding-left: 32px;
`

const Publish = styled.div`
    margin-bottom: 48px;
`

const View = styled.div`
    margin-bottom: 48px;
`

const Custom = styled.div`
    margin-bottom: 48px;
`

//******************************** */

const FAQ = ({setShowContact, setShowRef, setShowFAQ, urlFragment }) => {

    const URL = urlFragment.toLowerCase();
    const Dev_URL = `https://${URL}-dev.optum.com`;

    const { t } = useTranslation();

    return (
        <>
            <Guide> 
                <Heading style={{fontSize: '23px', lineHeight : '28px' }}> {t("Support.FAQ.Heading")} </Heading>
                <Markdown> {t("Support.FAQ.Markdown")} </Markdown>
            </Guide>
            <Detail>
                <Publish>
                    <Heading> {t("Support.FAQ.PublishHeading")} </Heading>
                    <Markdown>
                        When you are ready to publish your changes, select “Publish” in the top right corner. Once published, these settings will be visible in your development environment. View the <span style={{ textDecorationLine: 'underline', color: '#0C55B8', cursor: 'pointer' }} onClick={()=>{setShowRef(true); setShowFAQ(false)}}> reference guide</span> for more information. 
                    </Markdown>
                </Publish>

                <View>
                    <Heading> {t("Support.FAQ.ViewHeading")} </Heading>
                    <Markdown>
                        View your development environment here: <a style={{ textDecorationLine: 'underline', color: '#0C55B8', cursor: 'pointer' }} rel="noreferrer" target='_blank' href={`${Dev_URL}`}>{Dev_URL}</a>
                    </Markdown>
                </View>

                <Custom>
                    <Heading> {t("Support.FAQ.CustomHeading")} </Heading>
                    <Markdown>
                        Send us a message using the <span style={{ textDecorationLine: 'underline', color: '#0C55B8', cursor : 'pointer' }} onClick={ ()=>{setShowContact(true); setShowFAQ(false)} }> contact us form</span>. Include as many details as possible with your request and we will follow up to discuss next steps. 
                    </Markdown>
                </Custom>
            </Detail>
        </>
    )
}

const mapStateToProps = (state) => {
    const urlFragment = state.editorReducer.urlFragment;

    return {
        urlFragment
    };
};

export default connect(mapStateToProps)(FAQ);