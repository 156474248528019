import React from "react";
import DashCard from './DashCard';
import styled from 'styled-components';

//*****************************************/

const Page = styled.div`
    position: absolute;
    top: 10px;
    bottom: 0px;
    left: 0;
    right: 0;
    overflow: auto;    
`

const Table = styled.table`
     position: absolute;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;     
     overflow: auto;     
`

const Tbody = styled.tbody`
    position: absolute;

    // bottom: 0;
    // left: 0;
    // right: 0;
    // overflow: auto;    
`

//*****************************************/

const RawDataCard = ({ properties, data }) => {
    data = Array.isArray(data)
        ? data
        : []

    return (
        <DashCard properties={properties}>
            <Page>
                <Table>

                    <Tbody>
                        <tr>
                            {
                                Object.keys(data[0]).map((key, i) => {
                                    return (<th
                                        key={'head' + i}
                                        style={{ maxWidth: 250, lineHeight: '1.5em', overflow: 'hidden', whiteSpace: 'nowrap' }}
                                    >
                                        {key}
                                    </th>);
                                })
                            }
                        </tr>
                        {
                            data.map((obj, i) => {
                                if (i > 1000) return;
                                return (
                                    <tr key={'row' + i}>
                                        {
                                            Object.keys(obj).map((key, index) => {
                                                return (<td
                                                    key={'row' + i + 'col' + index}
                                                    style={{ maxWidth: 250, lineHeight: '1.5em', overflow: 'hidden', whiteSpace: 'nowrap' }}
                                                >
                                                    {obj[key]}
                                                </td>);
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                    </Tbody>
                </Table>
            </Page>
        </DashCard>
    )
}

export default RawDataCard;