import React, { useEffect, useState } from "react";
import DashCard from './DashCard';
import { VictoryChart, VictoryGroup, VictoryArea, VictoryAxis, VictoryLegend } from "victory";
import styled from 'styled-components';

//*****************************************/

const Page = styled.div`
    position: absolute;
    top: 10px;
    bottom: 0px;
    left: 0;
    right: 0;
    overflow: auto;    
`

//*****************************************/

const Completion = ({ properties, data }) => {
    const [chartData1, setChartData1] = useState([{ x: 1, y: 1 }, { x: 2, y: 2 }, { x: 3, y: 5 }]);
    const [chartData2, setChartData2] = useState([{ x: 1, y: 1 }, { x: 2, y: 2 }, { x: 3, y: 5 }]);

    const MONTH = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dev']

    data = Array.isArray(data)
        ? data
        : []

    useEffect(() => {
        const sessions = [];
        const completed = [];
        let i = 0;

        while (i < data.length) {
            let start = data[i]?.startTime;
            let sDate = new Date(start);
            let x = MONTH[sDate.getMonth()] + ' ' + sDate.getDate();
            let y1 = 0;
            let y2 = 0;
            i++;
            while (i < data.length) {
                let newStart = data[i]?.startTime;
                let newSDate = new Date(newStart);
                y1++;
                i++;
                if (data[i]?.isTriageComplete?.toLowerCase() === 'yes') y2++;
                if (sDate.getDate() != newSDate.getDate() | sDate.getMonth() != newSDate.getMonth()) break;
            }

            sessions.push(
                {
                    x: x,
                    y: parseInt(y1),
                }
            )
            completed.push(
                {
                    x: x,
                    y: parseInt(y2)
                }
            )
        };

        setChartData1(sessions);
        setChartData2(completed);
    }, [data])    

    return (
        <DashCard properties={properties}>
            <Page>
                <VictoryChart width={1600} height={300}>
                    <VictoryLegend x={50} y={0}
                        orientation="horizontal"
                        gutter={25}
                        style={{ border: { stroke: "black" }, title: { fontSize: 20 } }}
                        data={[
                            { name: "Sessions", symbol: { fill: "blue"} },
                            { name: "Completed", symbol: { fill: "purple" } }
                        ]}
                    />
                    <VictoryGroup
                        style={{
                            data: { strokeWidth: 3, fillOpacity: 0.4 }
                        }}
                    >
                        <VictoryArea
                            interpolation="natural"
                            style={{
                                data: { fill: "blue", stroke: "blue" }
                            }}
                            data={chartData1}
                        />
                        <VictoryArea
                            interpolation="natural"
                            style={{
                                data: { fill: "purple", stroke: "purple" }
                            }}
                            data={chartData2}
                        />
                    </VictoryGroup>
                </VictoryChart>

            </Page>
        </DashCard>
    )
}

export default Completion;