import React, { useState, useRef, useEffect } from "react";
import { connect } from 'react-redux';
import styled from 'styled-components';
import ImgIcon from '../../../../assets/sidebar/image-file-icon.svg';
import CheckIcon from '../../../../assets/sidebar/checkmark.svg';
import TrashIcon from '../../../../assets/sidebar/trashcan.svg';
import { useTranslation } from "react-i18next";
import { savePrivacyPolicy } from "../../../../redux/actions/helpActions";
import { setState } from "../../../../redux/actions/editorActions";

//******************************************/

const Editor = styled.div`
    margin-top: 8px;
    margin-bottom: 40px;
    `

const Description = styled.p`
    color: #505050;
    font-size: 16px;
    font-weight: 400;    
    margin-bottom: 24px;

    & a, a:link, a:visited, a:hover  {
        color: #0C55B8;
    }

    & a:active {
        color: #0058CC;
    }
    `

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    border: 1px solid #A7A7A7;
    border-radius: 4px;
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    padding-left: 23px;

    &.disabled {
        opacity: 50%;
    }
`

const NoFile = styled.span`
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #505050;
`

const FileName = styled.div`
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin-left: 20px;
    width: 250px;    
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #505050;

`

const LoadButton = styled.button`
    position: absolute;
    right: 4px;
    font-size: 18;
    text-align: center;
    width: 114px;
    height: 40px;
    border: none;
    border-radius: 4px;
    color: white;
    background-color: #0C55B8;
    font-family: "Optum Sans", "Helvetica", Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;

    &:disabled {
        cursor: auto;
    }
`

const ImageIcon = styled.div`
    position: absolute;
    left: 16px;
    width: 18px;
    height: 18px;
    background-image: url(${ImgIcon});
    background-repeat: no-repeat;
    background-position: center;   
`

const CheckMark = styled.div`
    position: absolute;
    right: 50px;
    width: 20px;
    height: 20px;
    background-image: url(${CheckIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-color: #21B01E;
    border-radius: 50%;    
`

const ClearButton = styled.button`
    position: absolute;
    right: 16px;
    width: 18px;
    height: 20px;
    margin: 0px;
    padding: 0px;
    background-image: url(${TrashIcon});
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:disabled {
        cursor: auto;
    }
`

//******************************************/

const PrivacyEditor = ({ setState, savePrivacyPolicy, appId, privacyFileName }) => {
    const { t } = useTranslation();

    const [selectedPDF, setSelectedPDF] = useState(privacyFileName);

    const inputFile = useRef(null);

    const onButtonClick = () => {
        inputFile.current.click();
    };

    const isDisabled = false;

    let formData = new FormData();

    function getImgSrc(files) {
        if(files[0]){
            const file = files[0];
            const filename = files[0].name;

            setState("privacyFileName", filename);

            formData.append('file', file);
            formData.append('appId', appId);

            savePrivacyPolicy(setState, formData);
        }
    }

    const clearImage = () => {
        setSelectedPDF('');
        setState("privacyFileName", '');
        setState("privacyUrl", '');
    }

    useEffect(()=>{
        setSelectedPDF(privacyFileName);
    }, [privacyFileName]);

    return (
        <Editor>
            <Description>
                {t("sidebar.privacy.description")}
            </Description>
            <Root
                title={(selectedPDF!=='') ? selectedPDF : t("sidebar.widgets.imageselector.nofile")}
                className={isDisabled ? 'disabled' : ''}
                styles={{ marginTop: '24px', marginBottom: '40px' }}
            >
                {
                    (selectedPDF !== '')
                        ?
                        <>
                            <ImageIcon />
                            <FileName>
                                {selectedPDF}
                            </FileName>
                            <CheckMark />
                            <ClearButton
                                onClick={clearImage}
                                disabled={isDisabled}
                            />
                        </>
                        :
                        <>
                            <NoFile>
                                {t("sidebar.widgets.imageselector.nofile")}
                            </NoFile>
                            <LoadButton
                                onClick={onButtonClick}
                                disabled={isDisabled}
                            >
                                {t("sidebar.widgets.imageselector.browse")}
                            </LoadButton>
                        </>
                }
                <input
                    type='file'
                    id='file'
                    ref={inputFile}
                    accept="application/pdf"
                    onClick={(event) => {
                        event.target.value = null
                    }}
                    onChange={(e)=>getImgSrc(e.target.files)}
                    style={{ display: 'none' }}
                />
            </Root>
        </Editor>
    )
}

const mapStateToProps = (state) => {
    const appId = state.loginReducer.appIdValue;
    const privacyFileName  = state.editorReducer.privacyFileName;

    return { appId, privacyFileName };
}

export default connect(mapStateToProps, { setState, savePrivacyPolicy })(PrivacyEditor);
