import React from "react";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

//******************************************/

const Button = styled.a`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;;
    width: 30%;
    min-height: 100%;
    border: solid 1px #0C55B8;    
    background-color: rgba(0,110,255,0.05);
    padding: 16px;
    box-sizing: border-box;
    cursor: pointer;
    `

const Title = styled.div`
    color: #282A2E;
    width: 100%;
    margin-top: 32%;   
    font-size: 30px;  
    height: 40px;
    `

const Description = styled.div`
    color: #505050;
    font-size: 16px;
    position: absolute;
    margin-top: 40px;
    top: 35%;    
    `

//*****************************************/

const WelcomeButton = ({ title, description, route }) => {
    const navigate = useNavigate();

    return (
        <Button
            onClick={ ()=>navigate(`/${route}`) }
        >
            <Title>{title}</Title>
            <Description>{description}</Description>
        </Button>
    );
}

export default WelcomeButton;